export interface Reaction {
	id: string;
	uid: string;
	icon: ReactionIcon;
	timestamp: Date;
	relativeTime: number; //seconds into video
	x: number;
	y: number;
	display: boolean;
}

export enum ReactionIcon {
	heart = "heart",
	haha = "haha",
	raiseHands = "raiseHands",
	like = "like",
	clap = "clap",
	pray = "pray",
	candle = "candle",
	wave = "wave",
}
