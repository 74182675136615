import apisauce from "apisauce";
import firebase from "../firebase";
import { keys as _keys, values as _values } from "lodash";

const LOCAL_URL = "http://localhost:5001/brushfire-online/us-central1/api";
const BASE_URL = "https://apigen2-kcj5zjf2ya-uc.a.run.app";

//const STAGING_URL = "https://us-central1-brushfire-online.cloudfunctions.net/stagingApi";

const FASTLY_URL = "https://brushfire-online.global.ssl.fastly.net/api";

const HEADERS = {
	"Content-Type": "application/json",
};

const create = (baseURL = BASE_URL, headers = HEADERS) => {
	const api = apisauce.create({
		baseURL,
		headers,
		timeout: 240000,
	});
	const fastly = apisauce.create({
		baseURL: FASTLY_URL,
		headers,
		timeout: 30000,
	});
	const local = apisauce.create({
		baseURL: LOCAL_URL,
		headers,
		timeout: 240000,
	});
	// const stagingApi = apisauce.create({
	// 	baseURL: STAGING_URL,
	// 	headers,
	// 	timeout: 240000,
	// });

	const fetchClient = clientKey => {
		return fastly.get(`clients/${clientKey}`);
	};

	const fetchChannel = (clientKey, channelKey) => {
		return fastly.get(`channels/${clientKey}/${channelKey}`);
	};

	const fetchUser = (firebaseId, isDashboard = true) => {
		return api.get(`users/me/${firebaseId}?isDashboard=${isDashboard}`);
	};

	const fetchGlobalUsers = async () => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.get(`users/globalUsers`);
	};

	const addGlobalUser = async data => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`users/globalUsers`, data);
	};

	const activateUser = async data => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`users/activate`, data);
	};

	const inviteUser = async data => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`clients/${data.clientId}/invite`, {
			clientId: data.clientId,
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			role: data.role,
			password: data.password,
			whitelistChannelIds: data.whitelistChannelIds,
		});
	};

	const deleteUser = async (clientId, userId) => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.delete(`clients/${clientId}/users/${userId}`);
	};

	const editUser = async user => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`users/edit/${user.id}`, user);
	};

	const nukeUser = async email => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`users/nuke`, { email });
	};

	const requestPasswordReset = async email => {
		return api.post(`users/reset`, { email });
	};

	const setPassword = async (userId, resetCode, password) => {
		return api.post(`users/setPassword`, { userId, resetCode, password });
	};

	const setUserImageTimestamps = async (authUserId, timestamp) => {
		return api.post(`users/setUserImageTimestamps`, { authUserId, timestamp });
	};

	const getTime = async () => {
		return await api.get("/utils/getTime");
	};

	const getContentAnalytics = async (contentId, refresh) => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.timeout = 540000;
		api.setHeader("Authorization", `Bearer ${token}`);
		const res = await api.get(`/contents/${contentId}/analytics/?refresh=${refresh ? "true" : "false"}`);
		api.timeout = 240000;
		return res;
	};

	/**
	 * Gets Brushfire Event data using a given event number
	 * @param {string} eventNumber The event number
	 * @param {boolean?} getTypes optional, will also fetch attendee type data
	 * @param {boolean?} getFields optional, will also fetch field data
	 */
	const getEventData = (eventNumber, getTypes = false, getFields = false) => {
		let queryParams = "";
		if (getTypes && getFields) {
			queryParams = "?types=1&fields=1";
		} else {
			if (getTypes) queryParams = "?types=1";
			else if (getFields) queryParams = "?fields=1";
		}
		return api.get(`/brushfire/events/${eventNumber}${queryParams}`);
	};

	const authorizeAttendeeNumber = (clientId, channelKey, attendeeNumber, analyticsUserId) => {
		return api.get(
			`/brushfire/attendees/${attendeeNumber}/authorize/${channelKey}?clientId=${clientId}&analyticsUserId=${analyticsUserId}`
		);
	};

	const getAttendeeNumberData = attendeeNumber => {
		return api.get(`/brushfire/attendees/${attendeeNumber}`);
	};

	const updateWebhook = () => {
		return api.post(`/brushfire/webhook/`);
	};

	const getBrushfireStandaloneEventDetails = eventNumber => {
		return api.get(`/brushfire/events/${eventNumber}/details`);
	};

	const authorizeStandaloneAttendeeNumber = (eventNumber, attendeeNumber, analyticsUserId) => {
		return api.get(
			`/brushfire/events/${eventNumber}/${attendeeNumber}/authorize/?analyticsUserId=${analyticsUserId}`
		);
	};

	const removeUserFromSeat = (attendeeNumber, analyticsUserId, channelId) => {
		return api.delete(`/brushfire/attendees/${attendeeNumber}/${channelId}/${analyticsUserId}`);
	};

	const getChannelDataByEventNumber = eventNumber => {
		return fastly.get(`/channels/events/${eventNumber}`);
	};

	const generatePersonalAccessToken = async (userId, payload) => {
		const token = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${token}`);
		return api.post(`/users/${userId}/token`, payload);
	};

	const deletePersonalAccessToken = async (userId, token, timestamp) => {
		const jwt = await firebase.auth().currentUser.getIdToken(true);
		api.setHeader("Authorization", `Bearer ${jwt}`);
		return api.delete(`/users/${userId}/token?token=${token}&timestamp=${timestamp}`);
	};

	return {
		fetchClient,
		fetchChannel,
		fetchUser,
		fetchGlobalUsers,
		addGlobalUser,
		activateUser,
		inviteUser,
		editUser,
		deleteUser,
		getTime,
		getContentAnalytics,
		getEventData,
		getChannelDataByEventNumber,
		authorizeAttendeeNumber,
		updateWebhook,
		getBrushfireStandaloneEventDetails,
		authorizeStandaloneAttendeeNumber,
		removeUserFromSeat,
		nukeUser,
		getAttendeeNumberData,
		requestPasswordReset,
		setPassword,
		setUserImageTimestamps,
		generatePersonalAccessToken,
		deletePersonalAccessToken,
	};
};

const api = create();

export default api;
