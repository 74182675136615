import { darken, lighten } from "polished";
import React, { useState } from "react";
import styled from "styled-components";
import isLightOrDark from "../../utils/isLightOrDark";
import { TypedUseSelectorHook } from "../../redux";
import { useSelector } from "react-redux";

interface ChatAvatarProps {
	photoURL: string | null;
	userName: string;
	requestChat?: boolean;
	uid: string;
	initializeChat: (uid: string, userName: string, photoURL: string | null) => void;
}

const style = {
	ChatDefaultAvatarCircle: styled.div`
		background-color: ${({ theme }) => {
			return isLightOrDark(theme.buttonBackgroundColor) === "light"
				? darken(0.05, theme.buttonBackgroundColor)
				: lighten(0.2, theme.buttonBackgroundColor);
		}};
		color: ${({ theme }) => theme.buttonTextColor};
		text-align: center;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		float: left;
	`,
	ChatPlaceholderAvatarCircle: styled.div`
		background-color: ${({ theme }) => {
			return isLightOrDark(theme.buttonBackgroundColor) === "light"
				? darken(0.05, theme.buttonBackgroundColor)
				: lighten(0.2, theme.buttonBackgroundColor);
		}};
		color: ${({ theme }) => theme.buttonTextColor};
		text-align: center;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		float: left;
		i {
			font-size: 20px;
		}
	`,
};

const ChatAvatar: React.FC<ChatAvatarProps> = ({ photoURL, userName, requestChat, uid, initializeChat }) => {
	const [userPicture, setUserPicture] = useState(true);
	const useTypedSelector: TypedUseSelectorHook = useSelector;
	const { images: userImageTimestamps } = useTypedSelector(store => store.channel);

	if (photoURL && userPicture) {
		const timestamp = userImageTimestamps?.[uid];
		photoURL = `${photoURL}${timestamp ? `?ts=${timestamp}` : ""}`;

		return (
			<>
				<img
					className={`img-fluid`}
					onError={() => setUserPicture(false)}
					src={photoURL}
					alt={`${userName}'s Profile Photo`}
				/>
				{requestChat && (
					<div
						className="request-chat"
						title={`Message ${userName}`}
						onClick={() => initializeChat(uid, userName, photoURL)}
					>
						<i className="fal fa-comments"></i>
					</div>
				)}
			</>
		);
	}
	if (userName) {
		return (
			<style.ChatDefaultAvatarCircle>
				<span>
					{userName
						.split(" ")
						.slice(0, 2)
						.map((word: any) => word.substring(0, 1).toUpperCase())}
				</span>
				{requestChat && (
					<div
						className="request-chat"
						title={`Message ${userName}`}
						onClick={() => initializeChat(uid, userName, photoURL)}
					>
						<i className="fal fa-comments"></i>
					</div>
				)}
			</style.ChatDefaultAvatarCircle>
		);
	}
	return (
		<style.ChatPlaceholderAvatarCircle>
			<i className="fas fa-user" />
		</style.ChatPlaceholderAvatarCircle>
	);
};

export default ChatAvatar;
