import React from "react";
import { isEmpty } from "lodash";

type ImageProps = {
	src?: string;
};

const HeaderImage: React.FC<ImageProps> = ({ src }) => {
	if (isEmpty(src)) {
		return null;
	}

	return <div className="header-image-wrapper">{src && <img src={src} alt="Header Banner" className="header-image" />}</div>;
};

export default HeaderImage;
