import { darken, lighten } from "polished";
import styled from "styled-components";
import { colours, fontSizes, fontWeights, getSpacerSize } from "../../config";

const ChatConvoButtonGeneral = styled.button`
	-webkit-appearance: none;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	margin-right: 10px;
	font-size: 11px;
	color: inherit;
`;

interface ConvoProps {
	inputHeight: number;
}

interface AvatarTabsProps {
	numberOfChats: number;
}

function hexToRgbA(hex: string) {
	var c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0)";
	}
	throw new Error("Bad Hex");
}

export const style = {
	ChatConvo: styled.div<ConvoProps>`
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		min-height: 55px;
		overflow-x: hidden;
		overflow-y: scroll;
		color: ${({ theme }) => theme.wellTextColor};
		padding-right: 7.5px;

		/* for the side scrollbar */
		&::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar {
			width: 5px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: ${({ theme }) => theme.wellTextColor};
			transition: all ease-in-out 0.2s;
		}
	`,
	ChatConvoLine: styled.div`
		margin-bottom: 10px;
		&:nth-of-type(1) {
			margin-top: auto;
		}
	`,
	ChatConvoUser: styled.h5`
		${fontWeights.bold}
		font-size: 14px;
		margin-bottom: ${getSpacerSize(0.2)};
		color: inherit;
	`,
	ChatTimeStamp: styled.h6`
	  ${fontWeights.light}
	  font-size: ${fontSizes.xs};
    margin-top: ${getSpacerSize(0.25)};
    color: inherit;
	`,
	ChatConvoMessage: styled.p`
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 4px;
		color: inherit;
	`,
	ChatConvoDelButton: styled(ChatConvoButtonGeneral)`
		color: ${colours.error};
	`,
	ChatConvoBanButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoLikeButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoUnlikeButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoNewMessages: styled.div<ConvoProps>`
		position: absolute;
		bottom: ${props => props.inputHeight + 40}px;
		left: 50%;
		transform: translate(-50%, 20px);
		opacity: 0;
		z-index: 99;
		background-color: ${({ theme }) => theme.wellTextColor};
		color: ${({ theme }) => theme.wellBackgroundColor};
		border-radius: 20px;
		padding: 4px 12px;
		transition: all ease-in-out 0.3s;
		pointer-event: none;
		cursor: pointer;
		&.active {
			transform: translate(-50%, 0);
			opacity: 1;
			pointer-event: all;
		}
	`,
	ChatAvatarTabs: styled.div<AvatarTabsProps>`
		background-color: ${({ theme }) => theme.buttonBackgroundColor};
		color: ${({ theme }) => theme.buttonBackgroundColor};
		position: relative;
		padding-bottom: 5px;
		&:after {
			content: "";
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0;
			right: 0;
			pointer-events: none;
			background: ${({ theme }) => hexToRgbA(theme.buttonBackgroundColor)};
			background: -moz-linear-gradient(
				270deg,
				${({ theme }) => theme.buttonBackgroundColor} 0%,
				${({ theme }) => hexToRgbA(theme.buttonBackgroundColor)} 100%
			);
			background: -webkit-linear-gradient(
				270deg,
				${({ theme }) => theme.buttonBackgroundColor} 0%,
				${({ theme }) => hexToRgbA(theme.buttonBackgroundColor)} 100%
			);
			background: linear-gradient(
				270deg,
				${({ theme }) => theme.buttonBackgroundColor} 0%,
				${({ theme }) => hexToRgbA(theme.buttonBackgroundColor)} 100%
			);
		}
		&::before {
			content: "";
			display: inline-block;
			background: ${({ theme }) => theme.wellBackgroundColor};
			width: ${({ numberOfChats }) => (numberOfChats + 1) * 50}px;
			min-width: 100%;
			height: 5px;
			top: 50px;
			position: absolute;
		}
		/* for the horizontal scrollbar */
		&::-webkit-scrollbar-track {
			border-radius: 5px;
			margin: 3px;
			border-top: solid 5px ${({ theme }) => theme.wellBackgroundColor};
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar {
			height: 5px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: ${({ theme }) => theme.wellTextColor};
			transition: all ease-in-out 0.2s;
		}
	`,
	ChatAvatarTab: styled.div`
		position: relative;
		background-color: ${({ theme }) => theme.buttonBackgroundColor};
		color: ${({ theme }) => theme.buttonBackgroundColor};
		.chat-avatar {
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}
		&::before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background: ${({ theme }) => theme.buttonBackgroundColor};
			position: absolute;
			bottom: 2px;
			border-radius: 20px;
			right: 2px;
			z-index: 1;
		}
		&:hover {
			background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
			color: ${({ theme }) => theme.buttonHoverBackgroundColor};
			&::before {
				background: ${({ theme }) => theme.buttonHoverBackgroundColor};
			}
		}
		&.active {
			background-color: ${({ theme }) => theme.wellBackgroundColor};
			color: ${({ theme }) => theme.wellBackgroundColor};
			.chat-avatar {
				background-color: ${({ theme }) => theme.buttonBackgroundColor};
			}
			&::before {
				background: ${({ theme }) => theme.wellBackgroundColor};
			}
		}
	`,
	ChatConvoCover: styled.div<ConvoProps>`
		position: absolute;
		top: -15px;
		left: -15px;
		width: calc(100% + 22px);
		height: calc(100% + 31px);
		z-index: 9;
		backdrop-filter: blur(3px);
	`,
	ChatConvoButton: styled.div`
		border-radius: 4px;
		padding: 4px 20px;
		width: 100%;
		margin: 10px 0;
		color: ${({ theme }) => theme.buttonTextColor};
		background-color: ${({ theme }) => theme.buttonBackgroundColor};
		transition: all ease-in-out 0.3s;
		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	`,
	ChatConvoDenyButton: styled.a`
		color: ${({ theme }) => theme.wellTextColor};
		width: 50%;
		display: inline-block;
		text-align: center;
		transition: all ease-in-out 0.3s;
		font-size: 14px;
		&:hover {
			color: ${({ theme }) => theme.wellTextColor};
			cursor: pointer;
			opacity: 0.8;
		}
	`,
	ChatConvoBlockButton: styled.a`
		color: #f44c3f;
		width: 50%;
		display: inline-block;
		text-align: center;
		transition: all ease-in-out 0.3s;
		font-size: 14px;
		&:hover {
			color: #f44c3f;
			cursor: pointer;
			opacity: 0.8;
		}
	`,
	ChatConvoOptions: styled.div`
		display: flex;
		padding-right: 7.5px;
	`,
	ChatConvoOptionsButton: styled.div<{ isLightBackground: boolean }>`
		display: inline-block;
		background-color: ${({ theme, isLightBackground }) => {
			return isLightBackground
				? darken(0.15, theme.wellBackgroundColor)
				: lighten(0.45, theme.wellBackgroundColor);
		}};
		flex: 1;
		text-align: center;
		margin: 0 5px 5px 5px;
		font-size: 11px;
		padding: 2px 5px;
		border-radius: 4px;
		transition: all ease-in-out 0.3s;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		&:hover:not(.disabled) {
			background-color: ${({ theme, isLightBackground }) => {
				return isLightBackground
					? darken(0.25, theme.wellBackgroundColor)
					: lighten(0.55, theme.wellBackgroundColor);
			}};
			cursor: pointer;
		}
		&.active {
			background-color: ${({ theme }) => theme.wellTextColor};
			color: ${({ theme }) => theme.wellBackgroundColor};
			&:hover {
				opacity: 0.8;
			}
		}
		&.disabled {
			opacity: 0.65;
			cursor: not-allowed;
		}
	`,
	IconContainer: styled.div`
		align-self: flex-end;
	`,
	IconWrapper: styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #393e45;
		border-radius: 4px;
		width: 30px;
		height: 30px;

		i {
			font-size: 25px;
			color: #f5f5f5;
		}

		&:hover {
			cursor: pointer;
			background-color: #2d2f32;
		}
	`,
	ChatHostPill: styled.span`
		background-color: ${({ theme }) => theme.wellTextColor};
		color: ${({ theme }) => theme.wellBackgroundColor};
		padding: 0px 2px;
		border-radius: 2px;
		margin-left: 5px;
		font-size: 7px;
		text-transform: uppercase;
		transform: translate(0px, -8px);
		display: inline-block;
		> i {
			font-size: 0.7em;
		}
	`,
	ChatAvatarStatus: styled.div`
		position: absolute;
		color: #9b9b9b;
		bottom: -1px;
		right: 4px;
		z-index: 1;
		&.online {
			color: #20bf55;
		}
	`,
};
