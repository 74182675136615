import styled from "styled-components";
import { Button as BSButton } from "react-bootstrap";
import Button from "../../../../../components/Button";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	@media screen and (max-width: 992px) {
		width: 100%;
	}
`;

export const InteractionsList = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 15px;
	overflow: auto;
	position: relative;
	@media screen and (max-width: 992px) {
		width: 100%;
	}
`;

export const PreviewPanelContainer = styled.div`
	height: 100%;
	padding: 15px;
	border-left: 1px solid #5a5a5a;
	width: 50%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	border-radius: 8px;
	@media screen and (max-width: 992px) {
		display: flex;
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		position: absolute;
		width: 100%;
		right: 0;
		&.slide-enter {
			left: 100%;
		}
		&.slide-enter.slide-enter-active {
			left: 0;
			transition: left 0.2s ease;
		}
		&.slide-leave {
			left: 0;
			transition: left 0.2s ease;
		}
		&.slide-leave.slide-leave-active {
			left: 100%;
			transition: left 0.2s ease;
		}
	}
	&.edit-panel {
		overflow-y: hidden;
	}
`;

export const DeletePreviewPanel = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	right: 0;
	z-index: 999;
	top: 0;
	backdrop-filter: blur(10px);
`;

export const DeletePanelButton = styled(BSButton)`
	width: 100%;
	margin: 2px 0 2px 0;
	padding: 5px;
`;

export const DeletePanelButtonContainer = styled.div`
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 15px;
`;

export const ExitButton = styled(Button)`
	top: 0;
	top: 15px;
	font-weight: 100;
	position: absolute;
	right: 20px;
`;
