/* eslint-disable react-hooks/exhaustive-deps */
/* libs */
import React, { useState, useEffect } from "react";
import { usePrevious } from "../../../../hooks";
import { FieldProps } from "formik";
import Autosuggest from "react-autosuggest";
import filter from "lodash/filter";
import ReactSelect from "react-select";

import iconsList from "./fa5IconsList.json";

interface IconPickerProps {
	field: FieldProps["field"];
	form: FieldProps["form"];
}

const IconPicker: React.FC<IconPickerProps> = ({ field, form }) => {
	const [minimizePlaceholder, setMinimizePlaceholder] = useState(false);
	const [iconValue, setIconValue] = useState({ iconName: "", weight: "" });
	const prevVal: any = usePrevious(iconValue);

	const icons: any = iconsList;

	useEffect(() => {
		if (field?.value) {
			const newValue = field.value.split(" ");
			setIconValue({ iconName: newValue[1].split("fa-")[1], weight: reverseWeightMapper(newValue[0]) });
		}
	}, [field.value]);

	useEffect(() => {
		if (prevVal !== iconValue) {
			setField(iconValue);
		}
	}, [field.value, iconValue, prevVal, form]);

	const setField = (value: any) => {
		const weight = weightMapper(value.weight);
		let name = value.iconName;
		let splitName = name.split("-");
		if (splitName[0] === "fa") {
			name = name.split("-")[1];
		}
		let icon = `${weight} fa-${name}`;

		if (value.weight === "" || value.iconName === "") {
			icon = "";
		}

		form.setFieldValue(field.name, icon);
	};

	const onFocus = () => {
		setMinimizePlaceholder(true);
	};

	const onTextChange = (event: any) => {
		const value = event.target.value || event.target.innerText.trim();
		const currentIcon = icons[value];
		const weightOptions = currentIcon
			? currentIcon.weights.map((weight: any, index: number) => {
					return { value: weight, label: weight };
			  })
			: [];
		let selectedWeight = weightOptions.find((w: string) => w === iconValue.weight);
		if (!selectedWeight) {
			selectedWeight = weightOptions[0] || "";
		}
		setIconValue({ iconName: value, weight: selectedWeight.value });
	};

	const getSuggestionValue = (suggestion: any) => {
		return suggestion.id;
	};

	const renderSuggestion = (suggestion: any) => (
		<span>
			<i className={`${weightMapper(suggestion.weights[0])} fa-${suggestion.id}`}></i>
			&nbsp;
			{suggestion.id}
		</span>
	);

	const onBlur = () => {
		setField(iconValue);
	};

	const onWeightChange = (value: any) => {
		setIconValue({ ...iconValue, weight: value.value });
	};

	const weightMapper = (weight: string) => {
		let prefix = "";
		switch (weight) {
			case "solid":
				prefix = "fas";
				break;
			case "regular":
				prefix = "far";
				break;
			case "light":
				prefix = "fal";
				break;
			case "brands":
				prefix = "fab";
				break;
			default:
				prefix = "fa";
				break;
		}
		return prefix;
	};

	const reverseWeightMapper = (weight: string) => {
		let prefix = "";
		switch (weight) {
			case "fas":
				prefix = "solid";
				break;
			case "far":
				prefix = "regular";
				break;
			case "fal":
				prefix = "light";
				break;
			case "fab":
				prefix = "brands";
				break;
			default:
				prefix = "regular";
				break;
		}
		return prefix;
	};

	const error = "";
	const iconName = iconValue.iconName;
	const iconWeight: any = iconValue.weight;
	const currentIcon = icons[iconName];
	const suggestions = filter(iconsList, icon => {
		return icon.id.includes(iconName.toLowerCase());
	});
	const selectOptions =
		currentIcon &&
		currentIcon.weights.map((weight: any, index: number) => {
			return { value: weight, label: weight };
		});
	const isFocused = minimizePlaceholder ? "focus" : "";
	const hasError = error ? "has-error" : "";

	return (
		<div className={`fa5-icon-picker ${isFocused} ${hasError}`}>
			<div className="icon-picker-row" onBlur={onBlur} onFocus={onFocus}>
				<div className="select-container">
					<label>Icon</label>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={() => {}}
						onSuggestionsClearRequested={() => {}}
						getSuggestionValue={getSuggestionValue}
						renderSuggestion={renderSuggestion}
						inputProps={{
							onChange: onTextChange,
							value: iconName,
							id: field.name,
						}}
					/>
				</div>
				<div className="select-container">
					<div className="select-wrapper">
						<label>Weight</label>
						<ReactSelect
							options={selectOptions}
							value={{ value: iconWeight, label: iconWeight }}
							onChange={onWeightChange}
							className="react-select-container"
							classNamePrefix="react-select"
							isSearchable={false}
						/>
					</div>
				</div>
				<div className="preview-box">
					<label>Preview</label>
					<div>
						{currentIcon && <i className={`${weightMapper(iconWeight)} fa-${currentIcon.id}`}></i>}
						{!currentIcon && <i className="fa fa-ban"></i>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default IconPicker;
