import styled from "styled-components";
import Button from "../Button";
import isLightOrDark from "../../utils/isLightOrDark";
import { darken, lighten } from "polished";

interface NumberOfReactions {
	numberOfReactions: number;
}

export const PanelBar = styled.div`
	display: flex;
	padding: 0.3em 0 0.3em 0.8em;
	background-color: ${({ theme }) => {
		return isLightOrDark(theme.wellBackgroundColor) === "light"
			? darken(0.15, theme.wellBackgroundColor)
			: lighten(0.45, theme.wellBackgroundColor);
	}};
`;

export const CurrentPanelTitle = styled.div`
	display: flex;
	align-items: center;
	i {
		margin-right: 7px;
		font-size: 20px;
	}
`;

export const LeftButtonsWrapper = styled.div`
	display: flex;
	right: 0;
	margin-left: auto;
`;

export const LeftButtonsWrapperInner = styled.div`
	display: flex;
	padding-right: 0.5em;
	z-index: 902;
	align-items: center;
	background-color: ${({ theme }) => {
		return isLightOrDark(theme.wellBackgroundColor) === "light"
			? darken(0.15, theme.wellBackgroundColor)
			: lighten(0.45, theme.wellBackgroundColor);
	}};
`;

export const ReactionsThumbButton = styled(Button)`
	position: relative;
	opacity: 1;
	margin-right: 0.5em;
	align-self: center;
	line-height: 1;
	i {
		font-size: 20px;
	}
	&.fade-enter {
		opacity: 0.01;
	}
	&.fade-enter.fade-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	&.fade-leave {
		display: none;
	}
	&.fade-leave.fade-leave-active {
		display: none;
	}
`;

export const ReactionsContainer = styled.div<NumberOfReactions>`
	min-width: ${({ numberOfReactions }) => (numberOfReactions ? `${numberOfReactions * 30 + 10}px` : "0px")};
	position: relative;
	right: -${({ numberOfReactions }) => (numberOfReactions ? `${numberOfReactions * 30 + 10}px` : "0px")};
	top: 1.2em;
	margin-right: 0.5em;
	&.active {
		right: 0px;
	}
	&.slide-enter {
		right: -${({ numberOfReactions }) => (numberOfReactions ? `${numberOfReactions * 30 + 10}px` : "0px")};
	}
	&.slide-enter.slide-enter-active {
		right: 0px;
		transition: right 0.2s ease;
	}
	&.slide-leave {
		right: 0;
		transition: right 0.2s ease;
	}
	&.slide-leave.slide-leave-active {
		right: -${({ numberOfReactions }) => (numberOfReactions ? `${numberOfReactions * 30 + 10}px` : "0px")};
		transition: right 0.2s ease;
	}
`;

export const CloseReactionsButton = styled.div`
	opacity: 0;
	display: flex;
	align-items: center;
	z-index: 902;
	visibility: hidden;
	margin-top: 7px;
	i.fal.fa-times {
		font-size: 20px;
	}
	&.active {
		opacity: 1;
		transition: opacity ease 1s;
		visibility: visible;
	}
`;

export const MenuButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	width: 30px;
	height: 30px;
	i.fal.fa-ellipsis-v {
		font-size: 25px;
		color: #303030;
	}
	&::before {
		content: "";
		display: inline-block;
		background-color: red;
		width: 10px;
		height: 10px;
		border-radius: 10px;
		position: absolute;
		top: 5px;
		right: 5px;
		opacity: 0;
		pointer-events: none;
	}
	&.has-activity {
		&::before {
			opacity: 1;
		}
	}
`;
