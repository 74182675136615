import ReactMomentCountDown from "react-moment-countdown";
import styled from "styled-components";

export const LiveContainer = styled.div`
	text-align: right;
	white-space: nowrap;
	float: right;
	margin-left: 3px;
`;

export const CountdownLabel = styled.div`
	background-color: ${props => props.theme.wellTextColor || "black"};
	color: ${props => props.theme.wellBackgroundColor || "white"};
	font-size: 14px;
	padding: 2px 8px;
	border-radius: 4px;
	display: inline-block;
	line-height: initial;
	&.invertable {
		@media screen and (max-width: 992px) {
			background-color: ${props => props.theme.navLinkColor || "black"};
			color: ${props => props.theme.navBackgroundColor || "white"};
		}
	}
	&.hidden {
		width: 0;
		height: 0;
		overflow: hidden;
		padding: 0;
	}
`;

export const CountdownText = styled(ReactMomentCountDown)`
	min-width: 50px;
	font-size: 14px;
`;

export const LiveLabel = styled.div`
	background-color: #e71d36;
	font-size: 14px;
	color: #fff;
	border-radius: 4px;
	padding: 2px 8px;
	line-height: initial;

	display: flex;
	align-items: center;
`;

export const Live = styled.div<{ showLiveCount: boolean }>`
	padding-right: ${({ showLiveCount }) => (showLiveCount ? "8px" : "0px")};
`;

export const LiveCount = styled.div`
	border-left: 1px solid #ff455c;
	padding-left: 8px;

	span {
		padding: left: 4px;
	}

	.fade-enter {
		opacity: 0.01;
	}

	.fade-enter.fade-enter-active  {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	

	.fade-leave {
		display: none;
	}

	.fade-leave.fade-leave-active {
		display: none;	
	}
	
`;
