import styled from "styled-components";
import Toast from "react-bootstrap/Toast";

export const ToastWrapper = styled(Toast)`
	position: fixed;
	top: 160px;
	right: 15px;
	z-index: 9999;
`;

export const ToastMessageText = styled.strong(props => ({
	color: props.color,
}));

export const ToastHeader = styled(Toast.Header)``;
