import { isEmpty } from "lodash";
import { REGEX } from "../../../../constants";
import { Content } from "../../../../interfaces";
import { stripHtml } from "../../../../utils/stripHtml";

export const validateInteractions = (values: Content) => {
	const errors: any = {};
	if (values.interactions && !isEmpty(values.interactions)) {
		values.interactions.forEach((interaction: any, interactionIndex: number) => {
			const interactionError: any = {};
			const interactionTitle = isEmpty(interaction.interactionTitle)
				? `Interaction #${interactionIndex + 1}`
				: `Interaction: "${interaction.interactionTitle}"`;

			if (isEmpty(interaction.interactionTitle)) {
				interactionError.interactionTitle = `(${interactionTitle}) Interaction Title is required`;
			} else if (
				values.interactions &&
				values.interactions
					.filter((i: any) => i.id !== interaction.id)
					.some((i: any) => i.interactionTitle === interaction.interactionTitle)
			) {
				interactionError.interactionTitle = `(${interactionTitle}) Interaction Title must be unique`;
			}
			if (isEmpty(interaction.interactionText) || stripHtml(interaction.interactionText).trim() === "") {
				interactionError.interactionText = `(${interactionTitle}) Interaction Text is required`;
			}
			if (isEmpty(interaction.urgency)) {
				interactionError.urgency = `(${interactionTitle}) Notification type is required`;
			}
			if (isEmpty(interaction.responses)) {
				interactionError.responses = `(${interactionTitle}) Need at least one response`;
			} else {
				interaction.responses.forEach((response: any, responseIndex: number) => {
					const responseError: any = {};
					const responseTitle = isEmpty(response.responseText)
						? `Response #${responseIndex + 1}`
						: `Response: "${response.responseText}"`;

					if (isEmpty(response.responseText)) {
						responseError.responseText = `(${interactionTitle}, ${responseTitle}) Response Text is required`;
					}
					if (isEmpty(response.actionText) && response.actionType === "textOnly") {
						responseError.actionText = `(${interactionTitle}, ${responseTitle}) After Response Text is required`;
					}
					if (response.actionType === "url") {
						responseError.url = { url: "" };
						if (isEmpty(response.url.url)) {
							responseError.url.url = `(${interactionTitle}, ${responseTitle}) Url is required`;
						} else if (!response.url.url.match(REGEX.URL)) {
							responseError.url.url = `(${interactionTitle}, ${responseTitle}) Url must be a valid URL (ex. https://example.com or mailto:example@mail.com)`;
						}

						if (isEmpty(responseError.url.url)) {
							delete responseError.url;
						}
					}
					if (response.actionType === "runCode" && isEmpty(response.code)) {
						responseError.actionText = `(${interactionTitle}, ${responseTitle}) Code is required`;
					}
					if (response.actionType === "runCode" && isEmpty(response.codeProvider)) {
						responseError.actionText = `(${interactionTitle}, ${responseTitle}) Code Provider is required`;
					}

					if (isEmpty(interactionError.responses)) {
						interactionError.responses = [];
					}
					interactionError.responses.push(responseError);
				});

				const anyResponseErrors = interactionError.responses.some((r: any) => !isEmpty(r));
				if (!anyResponseErrors) {
					delete interactionError.responses;
				}
			}

			if (isEmpty(errors.interactions)) {
				errors.interactions = [];
			}
			errors.interactions.push(interactionError);
		});

		const anyInteractionErrors = errors.interactions.some((i: any) => !isEmpty(i));
		if (!anyInteractionErrors) {
			delete errors.interactions;
		}
	}
	return errors;
};
