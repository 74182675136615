import React, { useContext, useState, useRef, useEffect } from "react";

import { Interaction, Response, ResponseActions } from "../../../interfaces";

import { Context, Dismiss } from "../InteractionResponse";
import * as Styled from "../InteractionResponse.styled";

interface AskInteractionProps {
	interaction: Interaction;
	responseClick: (response: Response) => void;
}

export const AskQuestion: React.FC<AskInteractionProps> = ({ interaction, responseClick }) => {
	const [responsesHeight, setResponsesHeight] = useState(0);
	const { isPreview, isModal } = useContext(Context);
	const responsesRef = useRef(null);

	useEffect(() => {
		if (responsesRef.current) {
			setResponsesHeight((responsesRef.current as any).offsetHeight + 11 + 18);
		}
	}, [responsesRef.current]);

	return (
		<Styled.InnerContentContainer>
			<Styled.RealContent responsesHeight={responsesHeight} isModal={isModal}>
				<div id="content" dangerouslySetInnerHTML={{ __html: interaction.interactionText }} />
			</Styled.RealContent>
			<Styled.InteractionContainer isPreview={isPreview}>
				<Styled.HR isPreview={isPreview} />
				<div ref={responsesRef} style={{ width: "100%" }}>
					{interaction.responses.map((response, index) => {
						if (response.actionType === "url" && response.url.target === "newTab") {
							return (
								<a href={response.url.url} target="_blank">
									<Styled.ResponseButton
										key={index}
										isPreview={isPreview}
										onClick={() => responseClick(response)}
									>
										{response.responseText}
									</Styled.ResponseButton>
								</a>
							);
						}
						return (
							<Styled.ResponseButton
								key={index}
								isPreview={isPreview}
								onClick={() => responseClick(response)}
							>
								{response.responseText}
							</Styled.ResponseButton>
						);
					})}
				</div>
				<Dismiss />
			</Styled.InteractionContainer>
		</Styled.InnerContentContainer>
	);
};
