import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Context } from "../SectionedPageContainer/SectionedPageContainer";

interface FormSubmitButtonProps {
	disabled: boolean;
	isSubmitting: boolean;
	isAddForm: boolean;
	onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({ isSubmitting, disabled, isAddForm, onClick }) => {
	const { activeSection, nextSection, setActiveSection, sections } = useContext(Context);

	let text = "Save";

	let history = useHistory();
	const match = useRouteMatch();

	if (isAddForm) {
		const index = sections.findIndex(sec => sec.key === activeSection);
		if (index !== sections.length - 1) {
			text = "Next";
		}
	}
	if (isSubmitting) {
		text = "Saving...";
	}

	const nextStep = (evt: React.MouseEvent<HTMLElement>) => {
		let submit = true;
		if (isAddForm) {
			const index = sections.findIndex(sec => sec.key === activeSection);
			if (index !== sections.length - 1) {
				submit = false;
				evt.preventDefault();
				evt.stopPropagation();
				history.push(`${match.url}/${sections[index + 1].key.toLocaleLowerCase()}`);
			}
		}
		if (submit && onClick) {
			onClick(evt);
		}
	};

	return (
		<Button
			variant="primary"
			type="submit"
			className="btn-block btn-lg-font"
			disabled={disabled}
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				nextStep(e);
			}}
		>
			{isSubmitting && <Spinner className="form-button-spinner" animation="border" />} {text}
		</Button>
	);
};

export default FormSubmitButton;
