import React, { useState, useEffect } from "react";
import { PageContainer, Well, PageTitle } from "../../UI";
import firebaseApp from "../../../../firebase";
import { useSelector } from "react-redux";

import { TypedUseSelectorHook } from "../../../../redux";
import { useRouteMatch } from "react-router-dom";
import { Message } from "../../../../components/Chat/Chat.service";
import moment from "moment";
import asyncForEach from "../../../../utils/asyncForEach";
import { Spinner } from "react-bootstrap";
import GlobalLoader from "../../../../components/GlobalLoader";
import { AnalyticsUser, PaywallAccess } from "../../../../interfaces";

const db = firebaseApp.firestore();

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
}

//interface PaywallData{ attendeeNumber: string, analyticsUserId: string, attendeeName: string, typeName: string}

const ChatExport: React.FC = () => {
	const [chats, setChats] = useState<Message[]>();
	const [analyticUsers, setAanalyticUsers] = useState<AnalyticsUser[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const match: MatchParams = useRouteMatch<MatchParams>().params;

	const { contentId } = match;

	const { contentData } = useTypedSelector(store => store.dashboard);

	useEffect(() => {
		db.collection("analytics")
			.doc("users")
			.collection(contentId)
			.get()
			.then(users => {
				const userData: AnalyticsUser[] = [];
				users.forEach(user => {
					userData.push(user.data() as AnalyticsUser);
				});
				setAanalyticUsers(userData);
				db.collection("chat")
					.doc("contents")
					.collection(contentId)
					.orderBy("timestamp", "asc")
					.get()
					.then(async docs => {
						const chatData: Message[] = [];
						//@ts-ignore
						await asyncForEach(docs.docs, async doc => {
							const data = (await doc.data()) as Message;
							const thisUser = userData.find(u => u.firebaseId === data.uid);
							if (thisUser) {
								data.email = thisUser.email || "";
							}
							chatData.push(data);
						});
						setChats(chatData);
						setIsLoading(false);
					});
			});
	}, [contentId]);

	let csvTranscript = "Name,Email,Message,Time,Host\n";
	let csv = "Name,Email,Number of Messages\n";

	let chatData: any = {};
	analyticUsers.forEach(analyticUser => {
		if (analyticUser.name) {
			chatData[analyticUser.firebaseId] = {
				count: 0,
				userName: analyticUser.name,
				email: analyticUser.email,
			};
		}
	});
	chats?.forEach(chat => {
		csvTranscript = `${csvTranscript}${chat.userName},${chat.email},"${chat.message}",${moment
			.unix(
				//@ts-ignore
				chat.timestamp.seconds
			)
			.toLocaleString()},${chat.isHost}\n`;
		if (!chatData[chat.uid]) {
			chatData[chat.uid] = {
				count: 1,
				userName: chat.userName,
				email: chat.email,
			};
		} else {
			chatData[chat.uid].count = chatData[chat.uid].count + 1;
		}
	});

	Object.keys(chatData).forEach(key => {
		const data = chatData[key];
		csv = `${csv}${data.userName},${data.email},${data.count}\n`;
	});
	const csvContent = `data:text/csv;charset=utf-8,${csv}`;
	const encodedUri = encodeURI(csvContent);

	const csvTranscriptContent = `data:text/csv;charset=utf-8,${csvTranscript}`;
	const encodedTranscriptUri = encodeURI(csvTranscriptContent);

	return (
		<PageContainer size="md" htmlPageTitle="Chat Export">
			<GlobalLoader isActive={isLoading} />
			<PageTitle>Public Chat Export</PageTitle>
			{!isLoading && (
				<Well>
					<h4 className="well-subtitle">
						Export Chat Transcript
						<a
							href={encodedTranscriptUri}
							download={`${contentId}-Transcript.csv`}
							className="float-right cursor-pointer btn-icon"
							title="Export Chat Transcript"
						>
							<i className="far fa-file-export"></i>
						</a>
					</h4>
					<h4 className="well-subtitle">
						Export Chat Data
						<a
							href={encodedUri}
							download={`${contentId}.csv`}
							className="float-right cursor-pointer btn-icon"
							title="Export Chat Data"
						>
							<i className="far fa-file-export"></i>
						</a>
					</h4>

					{chats?.map(chat => (
						<div>
							<h2>{`${chat.userName}${chat.isHost ? " (HOST)" : ""}`}</h2>
							<h4>{chat.email}</h4>
							<p dangerouslySetInnerHTML={{ __html: chat.message }}></p>
							<p>&nbsp;</p>
						</div>
					))}
				</Well>
			)}
		</PageContainer>
	);
};

export default ChatExport;
