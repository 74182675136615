import styled from "styled-components";
import Button from "../Button";
import isLightOrDark from "../../utils/isLightOrDark";
import { darken, lighten } from "polished";

interface PanelBarHeight {
	panelBarHeight: number;
}

export const Menu = styled.div<PanelBarHeight>`
	height: 0;
	width: 100%;
	top: ${({ panelBarHeight }) => `${panelBarHeight - 1}px;`}
	background: ${({ theme }) => theme.wellBackgroundColor};
	position: absolute;
	z-index: 100;
	overflow-y: scroll;
	transition: 0.2s;
	&.open {
		height: ${({ panelBarHeight }) => `calc(100% - ${panelBarHeight}px);`};
	}
`;

export const MenuItemWrapper = styled.div`
	padding: 0.8em;
`;

export const PanelButton = styled(Button)`
	display: flex;
	align-items: center;
	padding: 0.5em 0;
	i {
		margin-right: 0.5em;
		font-size: 20px;
	}
	.icon-wrapper {
		position: relative;
		&::before {
			content: "";
			display: inline-block;
			background-color: red;
			width: 10px;
			height: 10px;
			border-radius: 10px;
			position: absolute;
			right: 5px;
			opacity: 0;
			pointer-events: none;
			.transition(opacity ease-in-out 0.2s);
			transition-delay: 0s;
		}
		&.has-activity {
			&::before {
				opacity: 1;
			}
		}
	}
`;

export const PanelContainer = styled.div`
	padding-bottom: 1em;
`;

export const LinkContainer = styled.div`
    padding-top: 1em;
	border-top: solid 1px ${({ theme }) =>
		isLightOrDark(theme.wellBackgroundColor) === "light"
			? darken(0.15, theme.wellBackgroundColor)
			: lighten(0.25, theme.wellBackgroundColor)}
}};
`;
