export enum Panels {
	chat,
	notes,
	bible,
	videos,
	info,
	directChat,
	interactions,
	schedule,
	custom,
}
