import ReactMomentCountDown from "react-moment-countdown";
import styled from "styled-components";

interface NumberOfReactions {
	numberOfReactions: number;
}

export const VideoInfo = styled.div`
	background-color: ${({ theme }) => theme.wellBackgroundColor};
	color: ${({ theme }) => theme.wellTextColor};

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.9em;
	position: relative;

	@media screen and (max-width: 992px) {
		padding: 0.7em;
	}

	@media screen and (max-width: 600px) {
		padding: 0.1em;
		padding-left: 10px;
		height: 32px;
	}
`;

export const TopRowContainer = styled.div`
	display: block;
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 3px 0px 0px 0px;
	position: relative;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	z-index: 99;
`;

export const TopRowSwiper = styled.div<NumberOfReactions>`
	display: inline-block;
	width: 100%;
	@media screen and (max-width: 992px) {
		width: calc(
			100% +
				${({ numberOfReactions }) =>
		numberOfReactions && numberOfReactions > 2 ? `${numberOfReactions * 30 - 85}px` : "0px"}
		);
	}
`;

export const TopRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BottomRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Title = styled.h2`
	font-weight: 300;
	margin-bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (max-width: 600px) {
		font-size: 16px;
		margin-bottom: 12px;
	}
`;

export const Description = styled.div`
	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	small {
		margin-bottom: 0px;
	}

	margin-top: 5px;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const TimeLabel = styled.small`
	display: block;
	font-weight: 600;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const ValidUntilLabel = styled.small`
	display: block;
	font-weight: 600;
	opacity: 0.8;
`;

export const ReactionsContainer = styled.div<NumberOfReactions>`
	min-width: ${({ numberOfReactions }) => (numberOfReactions ? `${numberOfReactions * 30 + 10}px` : "0px")};

	@media screen and (max-width: 992px) {
		white-space: nowrap;
	}
`;

export const LiveContainer = styled.div`
	text-align: right;
`;

export const CountdownLabel = styled.div`
	background-color: ${props => props.theme.wellTextColor};
	color: ${props => props.theme.wellBackgroundColor};
	font-size: 14px;
	padding: 2px 8px;
	border-radius: 4px;
	align-self: flex-end;
`;

export const CountdownText = styled(ReactMomentCountDown)`
	min-width: 50px;
	font-size: 14px;
`;

export const LiveLabel = styled.div`
	background-color: red;
	font-size: 14px;
	color: #fff;
	border-radius: 4px;
	padding: 2px 8px;
	display: inline-block;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
