import firebase from "firebase";
import { isEmpty, isEqual, orderBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { RouteComponentProps, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import firebaseAuthApp from "../../authFirebase";
import Button from "../../components/Button";
import ContentView, { ChannelThumbImageContainer } from "../../components/ContentView";
import Footer from "../../components/Footer/Dashboard/Footer";
import FooterNavigation from "../../components/Footer/FooterNavigation";
import GlobalStyle from "../../components/GlobalStyle";
import Header from "../../components/Header";
import Paywall from "../../components/Paywall";
import { VideoList } from "../../components/Video/VideoList/VideoList";
import firebaseApp from "../../firebase";
import { usePrevious } from "../../hooks";
import {
	AttendeeData,
	ChannelData,
	ClientData,
	Content,
	ContentType,
	Panel,
	CustomPanel,
	Theme,
} from "../../interfaces";
import { CustomDomainContext } from "../../navigation/PublicRoutes";
import { TypedUseSelectorHook } from "../../redux";
import { setIsLoggingOut, showLogoutModal } from "../../redux/User/actions";
import { Dimension, setAnalytics, setDimensions } from "../../services/analytics";
import api from "../../services/api";
import attendeeHasContentAccess from "../../services/attendeeHasContentAccess";
import { updateAnalyticsUserDoc } from "../../services/dataHelpers";
import { initChannelPresence } from "../../services/firebasePresence";
import userHasRole from "../../services/userHasRole";
import { isChurchX } from "../../utils/churchx";
import isLightOrDark from "../../utils/isLightOrDark";
import {
	checkAvailability,
	filterContents,
	getBrowserTime,
	getCurrentTime,
	isOnDemand,
	shouldUpdateContents,
	sortContent,
	sortContentBuckets,
} from "./utils";
import { useCustomPanel } from "../../hooks/useCustomPanel";
import { updateChannelProfileImages } from "../../redux/Channel/actions";

const db = firebaseApp.firestore();

const useTypedSelector: TypedUseSelectorHook = useSelector;

const BumperVideoContainer = styled.div`
	width: 100%;
`;

const BumperVideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%;

	.player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const VideoListContainer = styled.div`
	display: block;
`;

interface Params {
	clientKey: string;
	channelKey: string;
	contentKey?: string;
}

interface ChannelProps extends RouteComponentProps { }

const Channel: React.FC<ChannelProps> = props => {
	const customDomainData = useContext(CustomDomainContext);
	const { history } = props;
	let { clientKey, channelKey, contentKey } = useParams<Params>();
	clientKey = customDomainData ? customDomainData.key : clientKey?.toLocaleLowerCase();
	channelKey = channelKey.toLocaleLowerCase();
	contentKey = contentKey?.toLocaleLowerCase();
	const [loading, setLoading] = useState(true);
	const [channel, setChannel] = useState<ChannelData>();
	const [content, setContent] = useState<Content>();
	const [contentOnDemand, setContentOnDemand] = useState<Content[]>();
	const [liveContents, setLiveContents] = useState<Content[]>();
	const [isOnDemandChannel, setIsOnDemandChannel] = useState<boolean>();
	const [client, setClient] = useState<ClientData>();
	const [currentUTCTime, setCurrentUTCTime] = useState("");
	const [update, setUpdate] = useState(0);
	const [didStartPsListener, setDidStartPsListener] = useState(false);
	const [creatingAnonymousUser, setCreatingAnonymousUser] = useState(false);
	const [didInitializePresence, setDidInitializePresence] = useState(false);
	const [contentId, setContentId] = useState("");
	const [contentType, setContentType] = useState<ContentType>();
	const [muted, setMuted] = useState(true);
	const [timeoutCounter, setTimeoutCounter] = useState(0);
	const [showPermissionModal, setShowPermissionModal] = useState(false);
	const [paywallAttendeeData, setPaywallAttendeeData] = useState<AttendeeData>();
	const customPanel = useCustomPanel();

	const prevContentId = usePrevious(contentId);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const dispatch = useDispatch();

	const user = useTypedSelector(store => store.user);
	const { analyticsUserId } = useTypedSelector(store => store.analytics);

	//Helper method to sort and filter content
	const updateChannelData = (channelData: ChannelData) => {
		const { contents } = channelData;
		const onDemandContents = orderBy(
			contents.filter(x => isOnDemand(x)),
			["originalAirDate", "name"],
			["desc", "asc"]
		);
		const liveContents = orderBy(
			contents.filter(x => x.contentType !== ContentType.onDemand),
			["opensAt", "name"],
			["asc", "asc"]
		);
		setLiveContents(liveContents);
		setContentOnDemand(onDemandContents);
		setChannel(channelData);
	};

	//Get the channel and client data when the page loads
	const initializeData = () => {
		const fetchChannelData = async () => {
			const channelResponse = await api.fetchChannel(clientKey, channelKey);

			if (channelResponse.ok) {
				updateChannelData(channelResponse.data as ChannelData);
				setLoading(false);
			} else {
				history.push("/");
			}
		};

		const fetchClientData = async () => {
			const clientResponse = await api.fetchClient(clientKey);

			if (clientResponse.ok) {
				const clientData: ClientData = clientResponse.data;
				setClient(clientData);
			}
		};

		fetchClientData();

		fetchChannelData();
	};

	//Refresh the channel data (after the new pubsub snapshot)
	const refreshData = () => {
		api.fetchChannel(clientKey, channelKey).then(channelResponse => {
			if (channelResponse.ok && channel?.contents) {
				const contentsDiff = shouldUpdateContents(channel.contents, channelResponse.data.contents);
				const channelDiff = !isEqual(channel, channelResponse.data);
				if (channelDiff || contentsDiff) {
					const channelData: ChannelData = channelResponse.data;
					updateChannelData(channelData);
				}
			}
		});
	};

	const availableOnDemandContent = contentOnDemand?.filter(
		content => !content.isPrivate && checkAvailability(content.showAt, content.hideAt) === "available"
	);
	//Sort live/simlive channel content into buckets
	let now = moment(currentUTCTime);
	let { contentHappeningNow, contentHappeningNowButNotStarted, contentScheduledForFuture, contentHasHappened } =
		channel && liveContents
			? sortContentBuckets(filterContents(now, liveContents))
			: {
				contentHappeningNow: [],
				contentHappeningNowButNotStarted: [],
				contentScheduledForFuture: [],
				contentHasHappened: [],
			};

	let otherContents = [
		...contentHappeningNow,
		...contentHappeningNowButNotStarted,
		...contentScheduledForFuture,
		...contentHasHappened,
		...(availableOnDemandContent ?? []),
	];

	if (contentKey) {
		otherContents = otherContents.filter(content => content.key !== contentKey);
	}

	let upcomingContents = channel
		? otherContents?.filter(
			(oc: Content) =>
				!!oc.closesAt &&
				new Date(oc.closesAt).getTime() > Date.now() &&
				((oc.channelIds ? oc.channelIds?.includes(channel.id) : oc.channelId === channel.id) ||
					(oc.relatedContentIds &&
						oc.relatedContentIds.some(ocRelatedId => content?.id === ocRelatedId) &&
						oc.contentType !== ContentType.onDemand))
		)
		: [];

	upcomingContents = orderBy(upcomingContents, ["startsAt", "name"]);

	const explicitlyRelatedContent: Content[] = !!content
		? otherContents
			.filter(
				oc =>
					oc.relatedContentIds &&
					oc.relatedContentIds.some(ocRelatedId => content?.id === ocRelatedId) &&
					oc.contentType == ContentType.onDemand
			)
			.sort(sortContent)
		: [];

	// const taggedRelatedContent: Content[] = !!content
	// 	? otherContents
	// 			.filter(
	// 				oc =>
	// 					oc.tags &&
	// 					oc.tags.some(ocTag => content?.tags?.includes(ocTag)) &&
	// 					(oc.contentType !== ContentType.onDemand
	// 						? (oc.channelIds
	// 								? !oc.channelIds?.includes(content?.channelId || "")
	// 								: oc.channelId !== content?.channelId) &&
	// 						  !!oc.closesAt &&
	// 						  new Date(oc.closesAt).getTime() > Date.now()
	// 						: true)
	// 			)
	// 			.sort(sortContent)
	// 	: [];

	let relatedOnDemandContent = [...(availableOnDemandContent ?? [])];
	if (contentKey) {
		relatedOnDemandContent = relatedOnDemandContent.filter(content => content.key !== contentKey);
	}

	const relatedContents = uniqBy(
		[...explicitlyRelatedContent, ...(relatedOnDemandContent.sort(sortContent) ?? [])],
		"id"
	);

	//Determine which content should be playing
	useEffect(() => {
		if (channel && contentOnDemand) {
			if (contentKey) {
				const possibleContent = channel.contents.find((content: Content) => content.key === contentKey);
				if (possibleContent) {
					setContent(possibleContent);
				} else {
					if (!customDomainData) {
						history.push(`/${clientKey}/${channelKey}`);
					} else {
						history.push(`/${channelKey}`);
					}
				}
			} else {
				setContent(undefined);
				if (channel.brushfireEventIds?.length) {
					if (paywallAttendeeData) {
						let redirectContent: Content | undefined;
						if (
							!isEmpty(
								contentHappeningNow.filter(
									c =>
										c.channelIds?.includes(channel.id) &&
										attendeeHasContentAccess(c, paywallAttendeeData) &&
										checkAvailability(c.showAt, c.hideAt) === "available"
								)
							)
						) {
							redirectContent = contentHappeningNow.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									attendeeHasContentAccess(c, paywallAttendeeData) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)[0];
						} else if (
							!isEmpty(
								contentHappeningNowButNotStarted.filter(
									c =>
										c.channelIds?.includes(channel.id) &&
										attendeeHasContentAccess(c, paywallAttendeeData) &&
										checkAvailability(c.showAt, c.hideAt) === "available"
								)
							)
						) {
							redirectContent = contentHappeningNowButNotStarted.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									attendeeHasContentAccess(c, paywallAttendeeData) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)[0];
						} else if (
							!isEmpty(
								contentScheduledForFuture.filter(
									c =>
										c.channelIds?.includes(channel.id) &&
										attendeeHasContentAccess(c, paywallAttendeeData) &&
										checkAvailability(c.showAt, c.hideAt) === "available"
								)
							)
						) {
							redirectContent = contentScheduledForFuture.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									attendeeHasContentAccess(c, paywallAttendeeData) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)[0];
						} else if (
							!channel.defaultVideo &&
							!isEmpty(
								contentOnDemand.filter(
									c =>
										c.channelIds?.includes(channel.id) &&
										attendeeHasContentAccess(c, paywallAttendeeData) &&
										checkAvailability(c.showAt, c.hideAt) === "available"
								)
							)
						) {
							redirectContent = contentOnDemand.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									attendeeHasContentAccess(c, paywallAttendeeData) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)[0];
						}
						if (redirectContent) {
							setLoading(true);
							if (!customDomainData) {
								window.location.href = `/${clientKey}/${channelKey}/${redirectContent.key}`;
							} else {
								window.location.href = `/${channelKey}/${redirectContent.key}`;
							}
						}
					}
				} else {
					let redirectContent: Content | undefined;
					if (
						!isEmpty(
							contentHappeningNow.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)
						)
					) {
						redirectContent = contentHappeningNow.filter(
							c =>
								c.channelIds?.includes(channel.id) &&
								checkAvailability(c.showAt, c.hideAt) === "available"
						)[0];
					} else if (
						!isEmpty(
							contentHappeningNowButNotStarted.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)
						)
					) {
						redirectContent = contentHappeningNowButNotStarted.filter(
							c =>
								c.channelIds?.includes(channel.id) &&
								checkAvailability(c.showAt, c.hideAt) === "available"
						)[0];
					} else if (
						!isEmpty(
							contentScheduledForFuture.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)
						)
					) {
						redirectContent = contentScheduledForFuture.filter(
							c =>
								c.channelIds?.includes(channel.id) &&
								checkAvailability(c.showAt, c.hideAt) === "available"
						)[0];
					} else if (
						!channel.defaultVideo &&
						!isEmpty(
							contentOnDemand.filter(
								c =>
									c.channelIds?.includes(channel.id) &&
									checkAvailability(c.showAt, c.hideAt) === "available"
							)
						)
					) {
						redirectContent = contentOnDemand.filter(
							c =>
								c.channelIds?.includes(channel.id) &&
								checkAvailability(c.showAt, c.hideAt) === "available"
						)[0];
					}
					if (redirectContent) {
						setLoading(true);
						if (!customDomainData) {
							window.location.href = `/${clientKey}/${channelKey}/${redirectContent.key}`;
						} else {
							window.location.href = `/${channelKey}/${redirectContent.key}`;
						}
					}
				}
			}
		}
	}, [channel, contentKey, paywallAttendeeData]);

	//Get and set Browser time
	useEffect(() => {
		const getTime = async () => {
			const time = await getCurrentTime();
			if (time) {
				const browserMoment = getBrowserTime();
				setCurrentUTCTime(browserMoment.toISOString());
			}
		};
		getTime();
	}, []);

	//Fetch the data and start setting everything up
	useEffect(() => {
		initializeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientKey, channelKey, history]);

	//MOVE?
	//Initialize user presence for the current content
	useEffect(() => {
		if (
			(didInitializePresence === false || prevContentId !== contentId) &&
			user.frontEndIsLoading === false &&
			contentId !== "" &&
			!creatingAnonymousUser &&
			contentType
		) {
			if (!user.frontEndUser) {
				setCreatingAnonymousUser(true);
				const auth = firebaseAuthApp.auth();
				auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
					auth.signInAnonymously().then(res => {
						if (res.user) {
							updateAnalyticsUserDoc(
								res.user,
								contentId,
								{ analyticsUserId, authorized: true },
								contentType
							);
						}
					});
				});
			} else {
				updateAnalyticsUserDoc(
					user.frontEndUser,
					contentId,
					{ analyticsUserId, authorized: true },
					contentType
				);
			}
			setDidInitializePresence(true);
		}
	}, [contentId, prevContentId, creatingAnonymousUser, user, didInitializePresence]);

	//Initialize the presence for the channel - used for paywall seats
	useEffect(() => {
		if (channel && analyticsUserId) {
			initChannelPresence(channel?.id, analyticsUserId);
		}
	}, [channel?.id, analyticsUserId, user.frontEndUser]);

	//Update the browser time
	useEffect(() => {
		const browserMoment = getBrowserTime();
		setCurrentUTCTime(browserMoment.toISOString());
	}, [update]);

	//Set up timers to reassess which contents are active/done
	useEffect(() => {
		let timeouts: any[] = [];
		if (channel) {
			channel.contents.forEach(c => {
				const now = moment.utc();
				const show = moment.utc(c.showAt);
				const hide = moment.utc(c.hideAt);

				if (checkAvailability(c.showAt, c.hideAt) === "early") {
					const timeout = show.diff(now, "milliseconds");
					let newTimeout = setTimeout(
						() => {
							let counter = timeoutCounter + 1;
							timeouts.push(newTimeout);
							setTimeoutCounter(counter);
						},
						timeout <= 43200000 ? timeout : 43200000
					);
				} else if (checkAvailability(c.showAt, c.hideAt) === "available" && c.hideAt) {
					const timeout = hide.diff(now, "milliseconds");
					let newTimeout = setTimeout(
						() => {
							let counter = timeoutCounter + 1;
							timeouts.push(newTimeout);
							setTimeoutCounter(counter);
						},
						timeout <= 43200000 ? timeout : 43200000
					);
				}
			});
		}

		if (timeouts.length > 0) {
			return () => {
				timeouts.forEach(timeout => {
					clearTimeout(timeout);
				});
			};
		}
	}, [channel, timeoutCounter]);

	useEffect(() => {
		if (client && user.frontEndUser && user.frontEndUser.displayName) {
			const authRecorded = localStorage.getItem(`auth-${client.id}`);
			if (authRecorded !== "1") {
				setShowPermissionModal(true);
			}
		}
	}, [client, user.frontEndUser?.uid]);

	useEffect(() => {
		let unsubscribe = () => { };
		if (channel) {
			const pubsub = db.collection("channel-pubsubs").doc(`ps_${channel.id}`);
			unsubscribe = pubsub.onSnapshot(
				doc => {
					setTimeout(() => {
						refreshData();
					}, 3000);
					const data = doc.data();
					if (data) {
						dispatch(updateChannelProfileImages(data.images));
					}
				},
				error => {
					console.log(`Encountered error: ${error}`);
				}
			);
			setDidStartPsListener(true);
		}
		return () => {
			unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel]);

	//Log Google Analytics pageview for channel and optional content
	useEffect(() => {
		if (channel && contentOnDemand && user.isLoadingAppUser === false) {
			const dimensions: { [key: string]: string | null } = {
				[Dimension.clientId]: channel.clientId,
				[Dimension.clientKey]: channel.clientKey,
				[Dimension.channelId]: channel.id,
				[Dimension.channelKey]: channel.key,
			};
			if (content) {
				dimensions[Dimension.contentId] = content.id;
				dimensions[Dimension.contentKey] = content.key;
			}
			const isAdmin =
				!!user.appUser &&
				userHasRole(channel.clientId || "", user.appUser, ["owner", "admin", "host", "superhost"]);
			if (isAdmin) {
				dimensions[Dimension.isGlobalUser] = "true";
				localStorage.setItem(`gaAdmin-${channel.clientId}`, moment.utc().toISOString());
			} else {
				const gaAdmin = localStorage.getItem(`gaAdmin-${channel.clientId}`);
				if (gaAdmin && moment().diff(moment(gaAdmin), "minutes") < 30) {
					dimensions[Dimension.isGlobalUser] = "true";
				} else {
					dimensions[Dimension.isGlobalUser] = "(not set)";
				}
			}
			setDimensions(dimensions);

			if (channel.analytics?.google && channel.analytics?.google.length > 0) {
				const googleAnalytics = channel.analytics.google;
				setAnalytics(googleAnalytics);
			} else {
				setAnalytics();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel?.clientId, channel?.id, contentKey, user.isLoadingAppUser]);

	if (loading) {
		return null;
	}

	const showBumper = isEmpty(contentHappeningNow) && !!channel?.defaultVideo?.src && !contentKey;
	const showPlaceholder = !!content?.convertedToOnDemandId;
	const sidePanelRendered = contentKey && !showBumper && !showPlaceholder;

	if (channel && contentOnDemand) {
		let now = moment(currentUTCTime);

		if (content && contentId !== content.id) {
			if (
				content.contentType === ContentType.onDemand ||
				(content.closesAt && new Date(content.closesAt).getTime() > Date.now())
			) {
				setContentType(content.contentType);
				setContentId(content?.id);
			}
		}

		const powerByLink = `http://www.brushfire.com/features/online-experience?utm_source=${clientKey}&utm_campaign=${channelKey}&utm_content=${content?.key}`;

		const theme: Theme = channel.theme as Theme;
		const isLightBackground = isLightOrDark(theme.backgroundColor) === "light";

		const checkAvailability = (showAt: any, hideAt: any) => {
			let availability;
			if (!showAt && !hideAt) {
				availability = "available";
			} else if (showAt && !hideAt) {
				if (moment().isBefore(showAt)) {
					availability = "early";
				} else {
					availability = "available";
				}
			} else if (!showAt && hideAt) {
				if (moment().isAfter(hideAt)) {
					availability = "late";
				} else {
					availability = "available";
				}
			} else if (showAt && hideAt) {
				if (moment().isBetween(showAt, hideAt, undefined, "[]")) {
					availability = "available";
				} else if (moment().isAfter(hideAt)) {
					availability = "late";
				} else if (moment().isBefore(showAt)) {
					availability = "early";
				}
			}

			return availability;
		};

		const channelIsAvailable = checkAvailability(channel.showAt, channel.hideAt) === "available";

		const renderValidUntil = () => {
			let validUntil = "";
			const orderRelativeDays = channel.brushfireEventIds?.find((bfEvt) => bfEvt.eventId === paywallAttendeeData?.EventId)?.attendeeTypes[paywallAttendeeData?.TypeId ?? ""]?.orderRelativeDays;
			if (!!orderRelativeDays) {
				validUntil = moment(paywallAttendeeData?.OrderedAt).add(orderRelativeDays, "days").local().format("LL");
			}

			if (validUntil) {
				return `Available until ${validUntil}`
			}
			return null;
		}

		const renderContent = (attendeeData: AttendeeData | undefined, paywallEnabled: boolean) => {
			let userHasAccess = false;

			if (!!paywallEnabled && content?.videoAccessRuleEnabled) {
				if (attendeeData) {
					userHasAccess = attendeeHasContentAccess(content, attendeeData);
				} else {
					return null;
				}
			} else {
				userHasAccess = true;
			}
			if (
				(!userHasAccess || (content?.closesAt && new Date(content.closesAt).getTime() < Date.now())) &&
				contentKey
			) {
				const url = customDomainData ? `/${channelKey}` : `/${clientKey}/${channelKey}`;
				window.location.href = url;
				return null;
			}

			if (!channelIsAvailable) {
				return (
					<>
						{!!channel.thumbImage && <ChannelThumbImageContainer image={channel.thumbImage} />}
						<VideoListContainer className={`video-panel-sidebar empty-channel`}>
							<div style={{ textAlign: "center", padding: "10px" }}>
								<i className="fal fa-tv-retro fa-3x m-bottom" />
								<h2>
									{checkAvailability(channel.showAt, channel.hideAt) === "early" &&
										`This channel will be available beginning ${moment(channel.showAt).format(
											"dddd, MMMM Do [at] LT"
										)}`}
									{checkAvailability(channel.showAt, channel.hideAt) === "late" &&
										`This channel is no longer available`}
								</h2>
							</div>
						</VideoListContainer>
					</>
				);
			} else if (content && checkAvailability(channel.showAt, channel.hideAt) === "available") {
				if (checkAvailability(content.showAt, content.hideAt) === "available") {
					return (
						<ContentView
							channelData={channel}
							contentData={content}
							customPanel={customPanel}
							contentHappeningNow={contentHappeningNow}
							contentHappeningNowButNotStarted={contentHappeningNowButNotStarted}
							contentScheduledForFuture={contentScheduledForFuture}
							contentHasHappened={contentHasHappened}
							contentOnDemand={contentOnDemand}
							upcomingContents={upcomingContents}
							relatedContents={relatedContents}
							showBumper={showBumper}
							showPlaceholder={showPlaceholder}
							currentTime={now.toISOString()}
							onVideoEnd={() => {
								setUpdate(v => {
									return v + 1;
								});
							}}
							powerByLink={powerByLink}
							clientKey={clientKey}
							channelKey={channelKey}
							contentKey={contentKey}
							contentId={contentId}
							client={client}
							contentValidUntil={renderValidUntil() ?? ""}
						/>
					);
				} else {
					return (
						<>
							{!!channel.thumbImage && <ChannelThumbImageContainer image={channel.thumbImage} />}

							<VideoListContainer className={`video-panel-sidebar empty-channel`}>
								<div style={{ textAlign: "center", padding: "10px" }}>
									<i className="fal fa-tv-retro fa-3x m-bottom" />
									<h2>
										{checkAvailability(content.showAt, content.hideAt) === "early" &&
											`This content will be available beginning ${moment(content.showAt).format(
												"dddd, MMMM Do [at] LT"
											)}`}
										{checkAvailability(content.showAt, content.hideAt) === "late" &&
											`This content is no longer available`}
									</h2>
								</div>
							</VideoListContainer>
						</>
					);
				}
			} else {
				return (
					<>
						{channel.defaultVideo?.src ? (
							<BumperVideoContainer>
								<BumperVideoWrapper>
									{muted && (
										<button className="unmute-button" onClick={() => setMuted(false)}>
											<i className="fas fa-volume-up"></i> Unmute
										</button>
									)}
									<ReactPlayer
										className="player"
										url={channel.defaultVideo?.src}
										playsinline
										width="100%"
										height="100%"
										playing={true}
										muted={muted}
										loop={true}
										controls={true}
										config={{
											youtube: {
												playerVars: {
													disablekb: 1,
													modestbranding: 1,
												},
											},
										}}
									/>
								</BumperVideoWrapper>
							</BumperVideoContainer>
						) : (
							<>{channel.thumbImage && <ChannelThumbImageContainer image={channel.thumbImage} />}</>
						)}
					</>
				);
			}
		};

		const renderVideoList = () => {
			return (
				<>
					<VideoListContainer
						className={`video-panel-sidebar ${relatedContents.length === 0 && upcomingContents.length === 0 ? "empty-channel" : ""
							}`}
					>
						<div className={`on-demand-container ${sidePanelRendered ? "" : "show"}`}>
							<VideoList
								clientKey={clientKey}
								channelKey={channelKey}
								onVideoEnd={() => {
									setUpdate(v => {
										return v + 1;
									});
								}}
								upcomingContents={upcomingContents}
								showRelatedTitle={upcomingContents.length > 0}
								relatedContents={relatedContents}
								contentKey={sidePanelRendered ? contentKey : undefined}
							/>
						</div>
					</VideoListContainer>
				</>
			);
		};

		const renderFooter = (
			contentKey: string | undefined,
			attendeeData?: AttendeeData | undefined,
			paywallEnabled?: boolean,
			logout?: () => void
		) => {
			const showEditControls =
				content?.contentType === ContentType.onDemand ||
				(content?.closesAt && new Date(content.closesAt).getTime() > Date.now());

			const color = isLightBackground ? "#202020" : "white";

			return (
				<div className="footer-container">
					<a
						href={powerByLink}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: "none" }}
						className="footer-image-container"
					>
						<div className="footer-logo-wrapper">
							<h6
								style={{
									...styles.footerTextStyle,
									color: isLightBackground ? "#202020" : "white",
								}}
							>
								A Product of
							</h6>
							<img
								alt="Brushfire Logo"
								style={styles.imageStyles}
								src={`/content/img/bf-logo-full-${isLightBackground ? "dark" : "light"}-1400.png`}
							/>
						</div>
					</a>
					{((paywallEnabled && attendeeData && logout) || user.frontEndUser?.displayName) && (
						<span>
							{paywallEnabled && attendeeData && logout && (
								<a
									href="#"
									style={{ color }}
									className={`footer-attendee-number ${user.frontEndUser ? "m-right" : ""}`}
									onClick={evt => {
										evt.preventDefault();
										evt.stopPropagation();
										if (window.confirm("Do you want to switch Attendee Numbers?")) {
											logout();
										}
									}}
								>
									Attendee {attendeeData.AttendeeNumber}
								</a>
							)}
							{user.frontEndUser?.displayName && (
								<>
									<span
										style={{
											color,
											backgroundColor: isLightBackground
												? "rgba(255,255,255,0.2)"
												: "rgba(0,0,0,0.5)",
										}}
										className="footer-logout"
									>
										{user.frontEndUser.displayName} -
										<a
											href="#"
											style={{
												color,
											}}
											onClick={evt => {
												evt.preventDefault();
												evt.stopPropagation();
												dispatch(showLogoutModal(true));
											}}
										>
											{" "}
											Sign Out
										</a>
									</span>
								</>
							)}
						</span>
					)}

					<FooterNavigation
						isLightBackground={isLightBackground}
						appUser={user.appUser}
						content={showEditControls ? content : undefined}
						client={client}
					/>
				</div>
			);
		};

		return (
			<div className={`content-wrapper ${channel.headerImage ? "with-header-image" : ""}`}>
				<Helmet>
					<title>{`${channel?.clientName}${content ? ` | ${content.name}` : ``}`}</title>
					{contentKey && content ? (
						<>
							<meta property="og:title" content={`${content.name} | ${channel.name} | ${client?.name}`} />
							<meta property="og:type" content="video.other" />
							<meta property="og:image" content={content.thumbImage} />
							<meta property="og:url" content={window.location.href} />
							{content.description && <meta property="og:description" content={content.description} />}
							<meta property="og:site_name" content={isChurchX ? "Church X" : "Brushfire Online"} />
						</>
					) : (
						<>
							<meta property="og:title" content={`${channel.name} | ${client?.name}`} />
							<meta property="og:type" content="website" />
							<meta
								property="og:image"
								content={channel.thumbImage || "/content/img/channel-default@2x.png"}
							/>
							<meta property="og:url" content={window.location.href} />
							<meta property="og:site_name" content={isChurchX ? "Church X" : "Brushfire Online"} />
						</>
					)}
					{content && <meta name="description" content={content.description} />}

					{(channel && channel.isPrivate) || (content && contentKey && content.isPrivate) ? (
						<meta name="robots" content="noindex,nofollow" />
					) : null}
					{channel.chatWidget?.enabled && channel.chatWidget?.provider === "zendesk" && !isMobile && (
						<script
							id="ze-snippet"
							src={`https://static.zdassets.com/ekr/snippet.js?key=${channel.chatWidget?.embedId}`}
						>
							{" "}
						</script>
					)}
					{channel &&
						channel.chatWidget?.enabled &&
						channel.chatWidget?.provider === "helpscout" &&
						(window as any).Beacon && (
							<script type="text/javascript">
								{`Beacon("init", "${channel.chatWidget?.embedId}"); Beacon("navigate", "/ask/chat/");`}
							</script>
						)}
				</Helmet>
				<ThemeProvider theme={theme}>
					<GlobalStyle />
					{channel.brushfireEventIds?.length ? (
						<Paywall
							channel={channel}
							title={channel.name}
							analyticsUserId={analyticsUserId}
							content={content}
							header={
								<Header content={content} channel={channel} authProviders={client?.authProviders} />
							}
							footer={
								<div className="paywall-footer">
									<Footer isLightBackground={isLightBackground} client={client} />
								</div>
							}
						>
							{(data: any) => {
								if (paywallAttendeeData?.AttendeeNumber !== data?.attendeeData?.AttendeeNumber) {
									setPaywallAttendeeData(data.attendeeData);
								}
								return (
									<>
										<Header
											content={content}
											channel={channel}
											authProviders={client?.authProviders}
										/>
										{renderContent(data.attendeeData, data.paywallEnabled)}
										{channelIsAvailable && renderVideoList()}
										{renderFooter(contentKey, data.attendeeData, data.paywallEnabled, data.logout)}
									</>
								);
							}}
						</Paywall>
					) : (
						<>
							<Header
								content={content}
								channel={channel}
								authProviders={client?.authProviders}
								customPanel={customPanel}
							/>
							{renderContent(undefined, false)}
							{channelIsAvailable && renderVideoList()}
							{renderFooter(contentKey)}
						</>
					)}
				</ThemeProvider>
				<Modal show={showPermissionModal} centered onHide={() => { }} className="default-modal">
					<Modal.Body>
						{user.frontEndUser?.photoURL && (
							<div className="big-avatar">
								<img
									className="img-fluid"
									src={user.frontEndUser?.photoURL}
									alt={`${user.frontEndUser?.displayName}'s Profile Photo`}
								/>
							</div>
						)}
						<div className="ta-center">
							<h2>Welcome Back!</h2>
							<div>
								You're currently signed in as <b>{user.frontEndUser?.displayName}</b>.
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								const auth = firebaseAuthApp.auth();

								dispatch(setIsLoggingOut(true));

								auth.signOut().then(() => {
									setShowPermissionModal(false);
									setTimeout(() => {
										auth.signInAnonymously()
											.then(user => { })
											.finally(() => {
												dispatch(setIsLoggingOut(false));
											});
									}, 2000);
								});
							}}
							className="modal-button cancel-button"
						>
							Sign Out
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								if (client) {
									localStorage.setItem(`auth-${client.id}`, "1");
								}
								if (content && user.frontEndUser) {
									updateAnalyticsUserDoc(
										user.frontEndUser,
										content.id,
										{ authorized: true, analyticsUserId },
										content.contentType || ContentType.simulatedLive,
										false
									);
								}
								setShowPermissionModal(false);
							}}
							className="modal-button accept-button"
						>
							Continue
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}

	if (!customDomainData) {
		history.push(`/${clientKey}`);
	} else {
		history.push("/");
	}
	return null;
};

const styles = {
	powerByContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	footerTextStyle: {
		marginRight: "10px",
		marginBottom: "0px",
		fontWeight: 500,
	},
	imageStyles: {
		maxWidth: 100,
	},
};

export default Channel;
