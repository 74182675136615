import "firebase/auth";
import { FormikContextType } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../components/Form";
import { useToast } from "../../components/Toast/ToastProvider";
import fb from "../../firebase";
import { User } from "../../interfaces";
import api from "../../services/api";
import { PageContainer, PageTitle, Well } from "./UI";

interface LoginData {
	password: string;
	passwordVerification: string;
}

interface MatchParams {
	userId: string;
	resetPasswordCode: string;
}

interface Error {
	code: string;
	message: string;
}

const db = fb.firestore();

const PasswordResetForm: React.FC = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [email, setEmail] = useState<string | null>(null);
	const [signInError, setSignInError] = useState<string>();
	const { addToast } = useToast();

	const [validCode, setValidCode] = useState<boolean | null>(null);

	const { userId, resetPasswordCode } = useRouteMatch<MatchParams>().params;

	const history = useHistory();

	const resetSchema = Yup.object().shape({
		password: Yup.string()
			.required("Password Required")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&=~*#])[A-Za-z\d@$!%*?&=~*#]{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@,$,!,%,*,?,&,=,~,*,#)"
			),
		passwordVerification: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords Must Match")
			.required("Password Verification Required"),
	});

	useEffect(() => {
		db.collection("users")
			.where("id", "==", userId)
			.where("resetPasswordCode", "==", resetPasswordCode)
			.get()
			.then(docs => {
				if (docs.size === 1) {
					const doc = docs.docs[0];
					const data: User | undefined = doc.data() ? (doc.data() as User) : undefined;
					if (data && data.resetPasswordCode !== null && data.resetPasswordCode === resetPasswordCode) {
						setEmail(data.email);
						setValidCode(true);
					} else {
						setValidCode(false);
					}
				} else {
					setValidCode(false);
				}
			})
			.catch(err => {
				setValidCode(false);
			});
	}, [userId, setValidCode]);

	const onSubmit = (values: LoginData) => {
		setIsSubmitting(true);
		setSignInError(undefined);
		api.setPassword(userId, resetPasswordCode, values.password).then(data => {
			if (data.data.status) {
				if (email) {
					fb.auth()
						.signInWithEmailAndPassword(email, values.password)
						.then(() => {
							window.location.href = "/dashboard";
						});
				} else {
					addToast("success", "Your password was reset! Redirecting to the dashboard...");
					setTimeout(() => {
						window.location.href = "/dashboard";
					}, 3000);
				}
			} else {
				setIsSubmitting(false);
				setSignInError("We could not update your password at this time. Please contact support.");
			}
		});
	};

	if (validCode === null) {
		return (
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Loading...">
				<PageTitle>Loading</PageTitle>
			</PageContainer>
		);
	} else if (validCode === false) {
		return (
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Oops">
				<PageTitle>Oops!</PageTitle>
				<Well>
					<h2>Looks like that link is no longer valid</h2>
				</Well>
			</PageContainer>
		);
	}

	if (isCreating) {
		return (
			<div className="ta-center">
				<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Reset Password">
					<PageTitle>Updating Password...</PageTitle>
				</PageContainer>
			</div>
		);
	}

	return (
		<div className="ta-center">
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Reset Password">
				<PageTitle>Reset Your Password</PageTitle>
				<Well>
					<Form
						initialValues={{ password: "", passwordVerification: "" }}
						validationSchema={resetSchema}
						onSubmit={onSubmit}
					>
						{({ formikContext }: { formikContext: FormikContextType<LoginData> }) => {
							return (
								<fieldset disabled={isSubmitting}>
									{signInError && <Alert variant="danger">{signInError}</Alert>}

									<Form.Field label="Password" id="password" name="password" type="password" />
									<Form.Field
										label="Verify Password"
										id="passwordVerification"
										name="passwordVerification"
										type="password"
										className="field m-bottom"
									/>
									<Button variant="primary" className="btn-block" type="submit">
										{isSubmitting && <Spinner animation="border" className="form-button-spinner" />}{" "}
										Reset{isSubmitting ? "ting" : ""} Password
									</Button>
								</fieldset>
							);
						}}
					</Form>
				</Well>
			</PageContainer>
		</div>
	);
};

export default PasswordResetForm;
