import React, { useEffect, useLayoutEffect, useState } from "react";
import { usePrevious } from "../../hooks";
import calculateBoundingBoxes from "../../services/calculateBoundingBoxes";

interface AnimateBubblesProps {}

const AnimateBubbles: React.FC<AnimateBubblesProps> = ({ children }) => {
	const [boundingBox, setBoundingBox] = useState({});
	const [prevBoundingBox, setPrevBoundingBox] = useState({});
	const prevChildren = usePrevious(children);

	useLayoutEffect(() => {
		const newBoundingBox = calculateBoundingBoxes(children);
		setBoundingBox(newBoundingBox);
	}, [children]);

	useLayoutEffect(() => {
		const prevBoundingBox = calculateBoundingBoxes(prevChildren);
		setPrevBoundingBox(prevBoundingBox);
	}, [prevChildren]);

	useEffect(() => {
		const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;

		if (hasPrevBoundingBox) {
			if (Object.keys(prevBoundingBox).length < Object.keys(boundingBox).length) {
				//Bubble has been added
				let offset = 0;
				React.Children.forEach(children, (child, index) => {
					if (index === 0) {
						//@ts-ignore
						const domNode = child?.ref?.current;
						offset = domNode.offsetWidth;
						//@ts-ignore
						const firstBox = prevBoundingBox[child.key];
						//@ts-ignore
						const lastBox = boundingBox[child.key];
						const changeInX = (firstBox?.left ?? 0) - (lastBox?.left ?? 0);

						if (changeInX) {
							requestAnimationFrame(() => {
								// Before the DOM paints, invert child to old position
								domNode.style.transform = `translateX(0px)`;
								domNode.style.transition = "transform 0s";

								requestAnimationFrame(() => {
									// After the previous frame, remove
									// the transistion to play the animation
									domNode.style.transform = "";
									domNode.style.transition = "transform 500ms";
								});
							});
						}
					} else {
						//@ts-ignore
						const domNode = child?.ref?.current;
						//@ts-ignore
						const firstBox = prevBoundingBox[child.key];
						//@ts-ignore
						const lastBox = boundingBox[child.key];
						const changeInX = (firstBox?.left ?? 0) - (lastBox?.left ?? 0) + offset;

						if (changeInX) {
							requestAnimationFrame(() => {
								// Before the DOM paints, invert child to old position
								domNode.style.transform = `translateX(${changeInX}px)`;
								domNode.style.transition = "transform 0s";

								requestAnimationFrame(() => {
									// After the previous frame, remove
									// the transistion to play the animation
									domNode.style.transform = "";
									domNode.style.transition = "transform 500ms";
								});
							});
						}
					}
				});
			} else {
				React.Children.forEach(children, child => {
					//@ts-ignore
					const domNode = child?.ref?.current;
					//@ts-ignore
					const firstBox = prevBoundingBox[child.key];
					//@ts-ignore
					const lastBox = boundingBox[child.key];
					const changeInX = (firstBox?.left ?? 0) - (lastBox?.left ?? 0);

					if (changeInX) {
						requestAnimationFrame(() => {
							// Before the DOM paints, invert child to old position
							domNode.style.transform = `translateX(${changeInX}px)`;
							domNode.style.transition = "transform 0s";

							requestAnimationFrame(() => {
								// After the previous frame, remove
								// the transistion to play the animation
								domNode.style.transform = "";
								domNode.style.transition = "transform 500ms";
							});
						});
					}
				});
			}
		}
	}, [boundingBox, prevBoundingBox, children]);

	return <>{children}</>;
};

export default AnimateBubbles;
