import React, { useContext } from "react";
import * as Styled from "./ScheduleItem.styled";
import { Content } from "../../interfaces";
import attendeeHasContentAccess from "../../services/attendeeHasContentAccess";
import { CustomDomainContext } from "../../navigation/PublicRoutes";
import { AttendeeDataContext } from "../Paywall";
import moment from "moment";

interface ScheduleItemProps {
	content: Content;
	channelKey?: string;
	clientKey?: string;
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({ content, channelKey, clientKey }) => {
	const customDomainData = useContext(CustomDomainContext);
	const { attendeeData, paywallEnabled } = useContext(AttendeeDataContext);

	let userHasAccess = false;

	if (!!paywallEnabled && attendeeData && content.videoAccessRuleEnabled) {
		userHasAccess = attendeeHasContentAccess(content, attendeeData);
	} else {
		userHasAccess = true;
	}

	const url = userHasAccess
		? customDomainData
			? `/${channelKey}/${content.key}`
			: `/${clientKey}/${channelKey}/${content.key}`
		: "#";

	return (
		<Styled.ScheduleRow to={url} className={`schedule-item-wrapper ${!userHasAccess ? "disabled" : ""}`}>
			<Styled.TitleWrapper>
				{!userHasAccess && <i className="fal fa-lock"></i>}
				<Styled.Title>{content.name}</Styled.Title>
				<small className="text-nowrap">{moment(content.startsAt).format("h:mm A")}</small>
			</Styled.TitleWrapper>
		</Styled.ScheduleRow>
	);
};

export default ScheduleItem;
