import React, { useEffect } from "react";
import { useLocalStorage } from "../../hooks";

import { validate } from "../../pages/Dashboard/Contents/Form/validate";

import Form, { FormContext } from "../../components/Form";
import Editor from "../../components/Form/Editor";

import { Content } from "../../interfaces";

interface NotesProps {
	content: string | undefined;
	contentId: string;
}

const Notes: React.FC<NotesProps> = ({ content, contentId }) => {
	const [notes, setNotes] = useLocalStorage(`notes-${contentId}`, "");
	let formValues = {
		notes: {
			defaultContent: content,
		},
	};

	useEffect(() => {
		if (window.localStorage.getItem(`notes-${contentId}`) === null) {
			setNotes(content);
		}
	}, [content]);

	const onSubmit = () => {
		console.log("submitting");
	};

	return (
		<div className="notes-inner-container">
			<Form initialValues={formValues} validate={validate} onSubmit={onSubmit} validateOnChange={false}>
				{({ formikContext, extraData }: FormContext<Content>) => {
					return (
						<div className="notes-wrapper">
							<Form.Field
								value={notes}
								component={Editor}
								label="Notes "
								id="notes.defaultContent"
								name="notes.defaultContent"
								placeholder="Enter your notes here"
								hasHtmlMenu
								disabled={false}
								initialValue={content && content.length > 0 ? content : ""}
								onEditorChange={(content: string) => {
									formikContext.setFieldValue("notes.defaultContent", content);
									setNotes(content);
								}}
							/>
						</div>
					);
				}}
			</Form>
		</div>
	);
};

export default Notes;
