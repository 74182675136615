import { User } from "../interfaces";

const userHasRole = (clientId: string, appUser: User, roles: string[], channelId?: string) => {
	if (clientId && appUser) {
		if (
			(appUser.globalRole && roles.includes(appUser.globalRole)) ||
			(appUser.clients && roles.includes(appUser.clients[clientId]))
		) {
			if (!channelId || !appUser.whitelist) {
				return true;
			} else {
				return (
					appUser.whitelist[clientId]?.includes(channelId) ||
					appUser.whitelist[clientId] === null ||
					appUser.whitelist[clientId] === undefined
				);
			}
		}
	}
	return false;
};

export default userHasRole;
