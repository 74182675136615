import React, { useState, useEffect, useContext } from "react";
import { Well, PageContainer } from "./Dashboard/UI";

const Unauthorized: React.FC = () => {
	return (
		<div className="absolute-center">
			<Well className="ta-center">
				<i className="fal fa-lock fa-4x m-bottom"></i>
				<h2>Unauthorized</h2>
			</Well>
		</div>
	);
};

export default Unauthorized;
