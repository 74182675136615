import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import { Mode, useLightSwitch } from "use-light-switch";
import GlobalLoader from "../components/GlobalLoader";
import ToastProvider from "../components/Toast/ToastProvider";
import PasswordResetForm from "../pages/Dashboard/PasswordResetForm";
import UserSignup from "../pages/Dashboard/UserSignup";
import DashboardRoutes from "./DashboardRoutes";
import PublicRoutes from "./PublicRoutes";

const AppRoutes: React.FC = () => {
	const mode = useLightSwitch();
	const isChurchX = window.location && window.location.hostname.includes("churchx.online");

	const checkBrowserMode = () => {
		if (mode === Mode.Dark) {
			return "dark";
		} else if (mode === Mode.Light) {
			return "light";
		} else if (mode === Mode.Unset) {
			return "unset";
		} else {
			return "unset";
		}
	};

	const icon = () => {
		if (checkBrowserMode() === "dark") {
			return isChurchX ? "churchX-favi-light.png" : "favicon-dark.png";
		} else if (checkBrowserMode() === "light") {
			return isChurchX ? "churchX-favi-dark.png" : "favicon.png";
		} else {
			return isChurchX ? "churchX-favi-grey.png" : "favicon-grey.png";
		}
	};

	return (
		<>
			<Helmet>
				<link rel="icon" href={`/content/img/${icon()}`} />
				<link rel="apple-touch-icon" href={`/content/img/${icon()}`} />
				<link rel="shortcut icon" href={`/content/img/${icon()}`} />
			</Helmet>
			<GlobalLoader />
			<Switch>
				<Route path="/signup/:userId/:verificationCode/:clientId" exact component={UserSignup} />
				<Route path="/signup/:userId/:verificationCode" exact component={UserSignup} />
				<Route path="/reset/:userId/:resetPasswordCode" exact component={PasswordResetForm} />
				<Route
					path="/dashboard"
					component={() => (
						<ToastProvider>
							<DashboardRoutes />
						</ToastProvider>
					)}
				/>
				<Route path="*" component={PublicRoutes} />
			</Switch>
		</>
	);
};

export default AppRoutes;
