import { isMatch } from "lodash";

export interface ThemeProps {
	id: string;
	name: string;
	theme: {
		textColor: string;
		backgroundColor: string;
		backgroundImage?: string;
		buttonTextColor: string;
		buttonBackgroundColor: string;
		buttonHoverBackgroundColor: string;
		wellTextColor: string;
		wellBackgroundColor: string;
		navBackgroundColor: string;
		navLinkColor: string;
		navLinkHoverColor: string;
	};
}

export const themes = [
	{
		id: "analog",
		name: "Analog",
		theme: {
			textColor: "#FFFFFF",
			backgroundColor: "#404040",
			buttonTextColor: "#FFFFFF",
			buttonBackgroundColor: "#1C1C1D",
			buttonHoverBackgroundColor: "#454545",
			wellTextColor: "#1C1C1D",
			wellBackgroundColor: "#FFFFFF",
			navBackgroundColor: "#1C1C1D",
			navLinkColor: "#FFFFFF",
			navLinkHoverColor: "#C6C6C6",
		},
	},
	{
		id: "generosity",
		name: "Generosity",
		theme: {
			textColor: "#FFFDED",
			backgroundColor: "#272727",
			buttonTextColor: "#FFFDED",
			buttonBackgroundColor: "#3E4232",
			buttonHoverBackgroundColor: "#6A6B53",
			wellTextColor: "#2D2D2D",
			wellBackgroundColor: "#FFFDED",
			navBackgroundColor: "#C9C49C",
			navLinkColor: "#2D2D2D",
			navLinkHoverColor: "#535248",
		},
	},
	{
		id: "seacoast",
		name: "Seacoast",
		theme: {
			textColor: "#FFFFFF",
			backgroundColor: "#212121",
			buttonTextColor: "#1B1B1C",
			buttonBackgroundColor: "#5DB09D",
			buttonHoverBackgroundColor: "#4E9484",
			wellTextColor: "#212121",
			wellBackgroundColor: "#F5F5F5",
			navBackgroundColor: "#5DB09D",
			navLinkColor: "#FFFFFF",
			navLinkHoverColor: "#D6EBE6",
		},
	},
	{
		id: "lite-soda",
		name: "Lite Soda",
		theme: {
			textColor: "#1B1B1C",
			backgroundColor: "#FFFFFF",
			buttonTextColor: "#1B1B1C",
			buttonBackgroundColor: "#E6E6E6",
			buttonHoverBackgroundColor: "#CCCCCC",
			wellTextColor: "#1B1B1C",
			wellBackgroundColor: "#F5F5F5",
			navBackgroundColor: "#E6E6E6",
			navLinkColor: "#1B1B1C",
			navLinkHoverColor: "#4C4C4D",
		},
	},
	{
		id: "pop-punk",
		name: "Pop Punk",
		theme: {
			textColor: "#FFFFFF",
			backgroundColor: "#04D9C4",
			buttonTextColor: "#FFFFFF",
			buttonBackgroundColor: "#BF349A",
			buttonHoverBackgroundColor: "#3F1B8C",
			wellTextColor: "#1C1C1D",
			wellBackgroundColor: "#F5F5F5",
			navBackgroundColor: "#3F1B8C",
			navLinkColor: "#FFFFFF",
			navLinkHoverColor: "#CEC5E2",
		},
	},
	{
		id: "cerulean",
		name: "Cerulean",
		theme: {
			textColor: "#1C1C1D",
			backgroundColor: "#FFFFFF",
			buttonTextColor: "#FFFFFF",
			buttonBackgroundColor: "#0876E8",
			buttonHoverBackgroundColor: "#0033A1",
			wellTextColor: "#1C1C1D",
			wellBackgroundColor: "#EBEBEB",
			navBackgroundColor: "#004BED",
			navLinkColor: "#FFFFFF",
			navLinkHoverColor: "#BACEFA",
		},
	},
];

export const isMatchedTheme = (activeTheme: any) => {
	let isMatchedTheme = false;
	themes.forEach(theme => {
		let themeCopy = { ...theme };
		let activeThemeCopy = { ...activeTheme };
		if (isMatch(themeCopy.theme, activeThemeCopy)) {
			isMatchedTheme = true;
		}
	});

	return isMatchedTheme;
};
