import { isMobileOnly } from "react-device-detect";
import ReactMomentCountDown from "react-moment-countdown";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CardWrapper = styled.div`
	background-color: ${({ theme }) => theme.wellBackgroundColor};
`;

export const CardContainer = styled.div`
	background-color: ${({ theme }) => theme.wellBackgroundColor};
	display: block;
	line-height: 1;
`;

export const LinkContainer = styled(Link)`
	color: ${({ theme }) => theme.wellTextColor};
	i.fal.fa-lock {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		transition: all ease-in-out 0.3s;
		color: ${({ theme }) => theme.wellTextColor};
	}
	.lock-bg {
		position: absolute;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		background: #ffffff;
		display: inline-block;
		z-index: 9;
		top: 50%;
		left: 50%;
		transform: translate(-20px, -20px);
		transition: all ease-in-out 0.2s;
		opacity: 1;
		color: ${({ theme }) => theme.wellTextColor};
		background-color: ${({ theme }) => theme.wellBackgroundColor};
	}
	.not-available-label {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 20px);
		color: #24272b;
		opacity: 0;
		transition: all ease-in-out 0.3s;
		z-index: 10;
		display: inline-block;
		width: 100%;
		text-align: center;
		font-size: 20px;
		text-transform: uppercase;
		color: ${({ theme }) => theme.wellTextColor};
	}
	&:hover {
		color: ${({ theme }) => theme.wellTextColor};
		text-decoration: none;
		i.fal.fa-lock {
			transform: translate(-50%, -20px);
			transition-delay: 0.1s;
		}
		.lock-bg {
			width: 110%;
			height: 110%;
			border-radius: 0px;
			top: -5%;
			left: -5%;
			transform: translate(0%, 0%);
			opacity: 0.8;
		}
		.not-available-label {
			opacity: 1;
			transition-delay: 0.1s;
		}
	}
	&.disabled {
		cursor: not-allowed;
		img {
			opacity: 0.6;
		}
	}
`;

export const VideoImageWrapper = styled.div`
	position: relative;

	&:after {
		display: block;
		content: "";
		padding-bottom: 56.25%;
	}
`;

export const VideoImage = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.8rem;

	h4 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const TitleRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const DurationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Duration = styled.div`
	margin-top: 5px;
	font-size: ${isMobileOnly ? `14px` : `16px`};
	width: 100%;
`;

export const Date = styled.div`
	margin-top: 5px;
	font-size: 12px;
`;

export const CountdownLabel = styled.div`
	white-space: nowrap;
	background-color: ${props => props.theme.wellTextColor};
	color: ${props => props.theme.wellBackgroundColor};
	font-size: 12px;
	padding: 2px 8px;
	border-radius: 4px;
	align-self: flex-end;
`;

export const CountdownText = styled(ReactMomentCountDown)`
	min-width: 50px;
	font-size: 12px;
`;

export const LiveText = styled.div`
	background-color: red;
	color: #fff;
	border-radius: 4px;
	padding: 2px 8px;
	font-size: 12px;
	align-self: flex-end;
`;

export const Title = styled.h2`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media screen and (max-width: 992px) {
		font-size: 16px;
	}
`;

export const LockIcon = styled.div`
	position: absolute;
	top: 6.4px;
	right: 6.4px;
	background: ${({ theme }) => theme.wellBackgroundColor};
	color: ${({ theme }) => theme.wellTextColor};
	padding: 10px;
	border-radius: 12px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const NoAccess = styled.div`
	position: absolute;
	top: 6.4px;
	right: 6.4px;
	background: ${({ theme }) => theme.wellBackgroundColor};
	padding: 10px;
	border-radius: 12px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;
