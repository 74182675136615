import { ContentType, VideoProvider } from "../../../../../interfaces";

export const getSrcLabel = (videoProvider: VideoProvider, contentType: ContentType) => {
	switch (videoProvider) {
		case VideoProvider.youtube:
		case VideoProvider.vimeo:
		case VideoProvider.facebook:
			if (contentType === ContentType.live) {
				return "Embed Code";
			} else {
				return "Video Url";
			}
		case VideoProvider.livestream:
			return "Livestream Embed Code";
		case VideoProvider.livingAsOne:
		case VideoProvider.livingAsOneSingle:
			return "Embed Id";
		case VideoProvider.other:
		default:
			return "Embed Code";
	}
};

export const getSrcDescription = (videoProvider: VideoProvider, contentType: ContentType) => {
	switch (videoProvider) {
		case VideoProvider.youtube:
		case VideoProvider.vimeo:
		case VideoProvider.facebook:
			if (contentType === ContentType.live) {
				return "";
			} else {
				return "The web address where your video can be viewed";
			}
		case VideoProvider.livestream:
			return "The embed code provided from Livestream";
		case VideoProvider.livingAsOne:
			return "Your Resi (Living as One) Web Event Profile Embed Id";
		case VideoProvider.livingAsOneSingle:
			return "Your Resi (Living as One) Embed Id";
		case VideoProvider.other:
		default:
			return "Your Video Provider's Embed Code";
	}
};
