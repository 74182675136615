import React from "react";
import { ToastWrapper, ToastHeader } from "../Toast.styled";
import Toast from "../Toast";

interface ToastProps {
	toast?: ToastMessage;
	showToast?: boolean;
	removeToast?: () => void;
}

interface ToastMessage {
	type?: string;
	text?: string;
}

const ToastContainer: React.FC<ToastProps> = ({ toast, showToast, removeToast }) => {
	if (!toast) return null;
	const { type, text } = toast;

	const remove = () => {
		if (removeToast) {
			return removeToast();
		}
		return null;
	};

	return (
		<ToastWrapper onClose={() => remove()} show={showToast} delay={3000} autohide>
			<ToastHeader>
				<Toast type={type} text={text} />
			</ToastHeader>
		</ToastWrapper>
	);
};

export default ToastContainer;
