import React, { useState, useEffect } from "react";
import { Well, PageTitle, PageContainer } from "../UI";
// import { FieldArray, FormikContextType } from "formik";
import { Client, Content } from "../../../interfaces";
import moment from "moment";
import firebase from "../../../firebase";

const db = firebase.firestore();

const UpcomingContent: React.FC = () => {
	const [contents, setContents] = useState<Content[]>();
	const [clients, setClients] = useState<Client[]>();

	useEffect(() => {
		const contentsDocRef = db.collection("clients");
		contentsDocRef.get().then(querySnapshot => {
			let tempClientsList: Client[] = [];
			querySnapshot.forEach(doc => tempClientsList.push(doc.data() as Client));
			setClients(tempClientsList);
		});
		db.collection("contents")
			.where("startsAt", ">", moment().toISOString())
			.where(
				"startsAt",
				"<",
				moment()
					.add(2, "days")
					.toISOString()
			)
			.orderBy("startsAt", "asc")
			.get()
			.then(docs => {
				let tempContentsList: Content[] = [];
				docs.forEach(doc => {
					tempContentsList.push(doc.data() as Content);
				});
				setContents(tempContentsList);
			});
	}, []);

	if (!contents || !clients) {
		return null;
	}
	return (
		<PageContainer size="md" className="no-sidebar" htmlPageTitle="Upcoming Contents">
			<PageTitle>Upcoming Contents</PageTitle>
			<Well>
				{contents?.map(content => {
					const client = clients.find(client => client.id === content.clientId);
					return (
						<a
							href={`/dashboard/${client?.id}/contents/${content.id}`}
							key={content.id}
							className="btn-row"
							style={{ color: "#FFFFFF" }}
						>
							<small>{client?.name}</small>
							<div>{content.name}</div>
							<div style={{ opacity: 0.6 }}>{moment(content.startsAt).format("lll")}</div>
						</a>
					);
				})}
			</Well>
		</PageContainer>
	);
};

export default UpcomingContent;
