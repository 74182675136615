import styled from "styled-components";
import { PageTitle } from "../../";

interface ButtonContainerProps {
	isExpanded: boolean;
}

export const SectionHeader = styled(PageTitle)`
	text-align: left;
`;

export const CustomWell = styled.div`
	background-color: #24272b;
	color: #fffefd;
	margin: 10px 0;
	border-radius: 8px;
	text-align: left;
	display: flex;
`;

export const CardContents = styled.div`
	width: calc(99% - 50px);
	padding: 40px;
	&.host-manager-interactions {
		width: 100%;
		padding: 0px;
	}
`;

export const ActionsContainer = styled.div`
	width: 60px;
	border-left: solid 1px #5a5a5a;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

export const ActionButton = styled.button`
	margin: 5px 0px;
	background-color: #24272b;
	color: #fffefd;
	border: none;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
	position: absolute;
	right: 0;
	display: flex;
	width: ${({ isExpanded }) => (isExpanded ? "auto" : "50px")};
	max-width: 100%;
	height: 45px;
	border-radius: 5px;
	transition: all 0.5s;
	overflow: hidden;
`;

export const Button = styled.div<ButtonContainerProps>`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: ${({ isExpanded }) => (isExpanded ? "#4a525a" : "transparent")};
	margin: 0;
	border-left-width: 2px;
	border-color: #576069;
	cursor: pointer;
	color: white;
	transition: all 0.25s;

	div {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		margin-left: 15px;
		margin-right: 15px;
	}

	&:hover {
		background-color: #1f2327;
	}
`;

export const NoLinks = styled.h3`
	color: #fffefd;
	margin: 20px;
`;
