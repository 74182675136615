import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { animateScroll } from "react-scroll";
import { Modal, Popover, OverlayTrigger } from "react-bootstrap";
import {
	subscribe,
	emit,
	CHAT_REFRESHCONVO,
	CHAT_SENTMESSAGE,
	CHAT_CONVMOUNTED,
	CHAT_BANUSER,
	CHAT_UNBANUSER,
	CHAT_DELCONVO,
	CHAT_TOGGLECOMMENTLIKE,
	CHAT_TOGGLECOMMENTFLAG,
	CHAT_SENDMESSAGE,
	CHAT_PINCONVO,
} from "./Chat.service";
import Button from "../Button";

import { style } from "./ChatConversation.styled";

import ChatBlock from "./ChatBlock";
import { toggleChatRequestMute } from "../../services/dataHelpers";

class ChatConversation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chatId: props.chatId,
			clientId: props.clientId,
			isModerator: props.isModerator,
			convo: [],
			uid: props.uid,
			videoId: props.videoId,
			loading: true,
			useChatProcessor: props.useChatProcessor,
			showBanModal: false,
			showEndModal: false,
			showInlineButtons: false,
			isEndingChat: false,
		};
		subscribe(CHAT_REFRESHCONVO, this.refresh.bind(this), props.chatId);
		subscribe(CHAT_SENTMESSAGE, this.scrollToBottom.bind(this), props.chatId);
	}

	componentDidMount() {
		emit(
			CHAT_CONVMOUNTED,
			{ chatId: this.state.chatId, useChatProcessor: this.state.useChatProcessor },
			this.state.chatId
		);
		this.scrollToBottom(true);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (this?.props?.inputHeight !== nextProps.inputHeight) {
			setTimeout(() => {
				if (this && this.scrollToBottom instanceof Function) {
					this.scrollToBottom();
				}
			}, 50);
		}
		return {
			videoId: nextProps.videoId,
			chatId: nextProps.chatId,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.videoId !== this.state.videoId) {
			emit(CHAT_CONVMOUNTED, { chatId: this.state.chatId }, this.state.chatId);
			this.scrollToBottom(true);
		}
	}

	refresh({ chatId, convo }) {
		if (chatId !== this.state.chatId) return;
		this.setState({ convo, loading: false });

		this.props.setHasPinnedChat(convo.filter(x => x.isPinned === true).length > 0);
		this.scrollToBottom();
	}

	scrollToBottom = (bypass = false) => {
		const { chatId } = this.state;
		const shouldScroll =
			this.chatContainer &&
			(this.chatContainer.scrollTop === 0 ||
				this.chatContainer.scrollHeight - this.chatContainer.offsetHeight - this.chatContainer.scrollTop < 150);
		if (shouldScroll || bypass) {
			animateScroll.scrollToBottom({
				containerId: `chatContainer-${chatId}`,
			});
		}
	};

	delConvo = data => {
		emit(CHAT_DELCONVO, data, this.state.chatId);
	};

	banUser = data => {
		emit(CHAT_BANUSER, data, this.state.chatId);
	};

	unbanUser = data => {
		emit(CHAT_UNBANUSER, data, this.state.chatId);
	};

	toggleCommentLike = data => {
		emit(CHAT_TOGGLECOMMENTLIKE, data, this.state.chatId);
	};

	toggleCommentFlag = data => {
		emit(CHAT_TOGGLECOMMENTFLAG, data, this.state.chatId);
	};

	startChat = () => {
		if (this.props.acceptChat) {
			this.props.acceptChat();
		}
	};

	endChat = () => {
		if (this.state.isEndingChat) {
			return;
		}
		this.setState({ isEndingChat: true });
		this.handleCloseEndModal();
		if (this.props.request.hostChat) {
			this.sendLeavingMessage();
			setTimeout(() => {
				this.props.endChat();
				this.setState({ isEndingChat: false });
			}, 5000);
		} else {
			this.props.endChat();
			this.setState({ isEndingChat: false });
		}
	};

	sendLeavingMessage = () => {
		// send message
		const chatId = this.props.chatId;
		const userName = this.props.user?.displayName || this.props.userName;
		const uid = this.props.user?.uid;
		const photoURL = this.props.user?.photoURL;
		const message = `***** ${userName} Closed The Chat *****`;
		const user = this.props.user;
		// send message
		emit(
			CHAT_SENDMESSAGE,
			{
				chatId,
				uid,
				userName,
				message,
				photoURL,
				email: user?.email ? user.email : this.props.email,
				isHost: this.props.isModerator,
				useChatProcessor: this.props.useChatProcessor,
			},
			this.props.chatId
		);
	};

	blockChat = () => {
		this.handleCloseBanModal();
		if (this.props.blockChat) {
			this.props.blockChat(this.props.request);
		}
	};

	handleShowEndModal = () => {
		this.setState({
			showEndModal: true,
		});
	};

	handleCloseEndModal = () => {
		this.setState({
			showEndModal: false,
		});
	};

	handleShowBanModal = () => {
		this.setState({
			showBanModal: true,
		});
	};

	handleCloseBanModal = () => {
		this.setState({
			showBanModal: false,
		});
	};

	toggleMute = () => {
		toggleChatRequestMute(this.props.contentId, this.props.chatId, this.props.uid);
	};

	render() {
		let lastUID = null;
		const { chatId, clientId, convo, showBanModal, showEndModal } = this.state;
		// const convoOrder = [...convo];

		const isHostChat = convo.some(x => x.isHost);

		const banModal = () => {
			return (
				<Modal show={showBanModal} onHide={this.handleCloseBanModal} className="default-modal">
					<Modal.Header closeButton>
						<Modal.Title>Block this person?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Are you sure you want to block this person for the remainder of this content?
					</Modal.Body>

					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={this.handleCloseBanModal}
							className="modal-button cancel-button"
						>
							Cancel
						</Button>
						<Button color="#e71d36" onClick={this.blockChat} className="modal-button confirm-button">
							Block
						</Button>
					</Modal.Footer>
				</Modal>
			);
		};

		if (this.props.request?.createdBy !== this.props.uid && this.props.request?.status === 3) {
			return (
				<style.ChatConvo
					id={`chatContainer-${chatId}`}
					ref={ref => (this.chatContainer = ref)}
					inputHeight={this.props.inputHeight}
				>
					<style.ChatConvoCover inputHeight={this.props.inputHeight}></style.ChatConvoCover>
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center",
							fontSize: 20,
							position: "relative",
							zIndex: 10,
						}}
					>
						<span>
							{this.props.request.userData[this.props.request.createdBy].name}
							{this.props.request.hosts.includes(this.props.request.createdBy) && (
								<style.ChatHostPill>
									<i className="fas fa-user"></i> Host
								</style.ChatHostPill>
							)}{" "}
							would like to message you directly.
							<br />
							<br />
							What would you like to do?
							<br />
							<style.ChatConvoButton onClick={this.startChat}>Accept</style.ChatConvoButton>
							<div>
								<style.ChatConvoDenyButton href="#" onClick={this.endChat}>
									{this.state.isEndingChat ? "Closing..." : "Deny"}
								</style.ChatConvoDenyButton>
								{!this.props.request.hosts.includes(this.props.request.createdBy) && (
									<style.ChatConvoBlockButton href="#" onClick={this.handleShowBanModal}>
										Block
									</style.ChatConvoBlockButton>
								)}
							</div>
						</span>
					</div>
					{banModal()}
				</style.ChatConvo>
			);
		}

		const inlineButtons = this.props.isDirect && !this.props.isHostManager;
		const hostButtons = this.props.isDirect && this.props.isHostManager;

		const popover = (
			<Popover id={`chat-popover-${this.props.chatId}`} className={`chat-popover`}>
				<Popover.Content>
					<div className="popover-item" onClick={this.toggleMute}>
						<div className="icon-wrapper">
							<i
								className={`fa${
									this.props.request?.userData[this.props.uid]?.muted ? "s" : "l"
								} fa-moon`}
							/>
						</div>
						<p>{this.props.request?.userData[this.props.uid]?.muted ? "Unmute" : "Mute"}</p>
					</div>
					<div
						className="popover-item"
						onClick={() => {
							this.handleShowBanModal();
							this.setState({ showInlineButtons: false });
						}}
					>
						<div className="icon-wrapper">
							<i className="fal fa-ban"></i>
						</div>
						<p>Block</p>
					</div>
					<div
						className="popover-item"
						onClick={() => {
							this.props.setShowTransferOptions(true);
							this.setState({ showInlineButtons: false });
						}}
					>
						<div className="icon-wrapper">
							<i className="fal fa-exchange"></i>
						</div>
						<p>Transfer</p>
					</div>
					<div
						className="popover-item"
						onClick={() => {
							this.handleShowEndModal();
							this.setState({ showInlineButtons: false });
						}}
					>
						<div className="icon-wrapper">
							<i className="fal fa-times"></i>
						</div>
						<p>Close</p>
					</div>
				</Popover.Content>
			</Popover>
		);

		const image =
			this.props.request?.userData &&
			this.props.request?.userData[Object.keys(this.props.request?.userData).filter(u => u !== this.props.uid)[0]]
				?.image;
		const startedByMe = this.props.request?.createdBy === this.props.uid;
		const initials = (startedByMe
			? this.props.request?.recipientUserName || ""
			: this.props.request?.userName || ""
		)
			.split(" ")
			.slice(0, 2)
			.map(word => word.substring(0, 1).toUpperCase());
		return (
			<>
				{inlineButtons && (
					<style.ChatConvoOptions>
						{![0, 1, 2].includes(this.props.request.status) && !isHostChat && (
							<>
								<style.ChatConvoOptionsButton
									isLightBackground={this.props.isLightBackground}
									onClick={this.props.enableDirectMessaging ? this.toggleMute : null}
									className={`${this.props.request?.userData[this.props.uid]?.muted ? "active" : ""}${
										this.props.enableDirectMessaging ? "" : " disabled"
									}`}
								>
									<i
										className={`fa${
											this.props.request?.userData[this.props.uid]?.muted ? "s" : "l"
										} fa-moon`}
									></i>{" "}
									Mute
								</style.ChatConvoOptionsButton>
								<style.ChatConvoOptionsButton
									isLightBackground={this.props.isLightBackground}
									onClick={this.props.enableDirectMessaging ? this.handleShowBanModal : null}
									className={this.props.enableDirectMessaging ? "" : "disabled"}
								>
									<i className="fal fa-ban"></i> Block
								</style.ChatConvoOptionsButton>
							</>
						)}

						<style.ChatConvoOptionsButton
							isLightBackground={this.props.isLightBackground}
							onClick={
								this.props.enableDirectMessaging || this.props.request?.hostChat
									? this.handleShowEndModal
									: null
							}
							className={
								this.props.enableDirectMessaging || this.props.request?.hostChat ? "" : "disabled"
							}
						>
							<i className="fal fa-times"></i> Close
						</style.ChatConvoOptionsButton>
					</style.ChatConvoOptions>
				)}

				{hostButtons && (
					<div>
						<div className={`coverall ${this.state.showInlineButtons ? "active" : ""}`}></div>
						<div className="d-flex host-chat-header">
							<div className="chat-avatar">
								{initials[0] === "" ? <i className="fal fa-user"></i> : <>{initials}</>}
								{image && (
									<img
										src={
											image.includes("googleusercontent")
												? image
												: `https://media.online.brushfire.com/profile_images/${
														startedByMe
															? Object.keys(this.props.request.userData).filter(
																	u => u !== this.props.uid
															  )[0]
															: this.props.request.createdBy
												  }/profile.jpg`
										}
										alt={`${
											startedByMe
												? this.props.request.recipientUserName
												: this.props.request.userName
										}'s User Image`}
									/>
								)}
							</div>
							<h3 className="ellipsis">
								{
									this.props.request?.userData[
										Object.keys(this.props.request?.userData).filter(x => x !== this.props.uid)[0]
									]?.name
								}
							</h3>
							{[1, 4, 5].includes(this.props.request?.userData[this.props.uid]?.status) && (
								<OverlayTrigger
									key={`chat-popover-${this.props.chatId}`}
									trigger="click"
									placement="left-start"
									overlay={popover}
									rootClose
									onToggle={show => {
										this.setState({ showInlineButtons: show });
									}}
									show={this.state.showInlineButtons}
								>
									<style.IconWrapper>
										<i className="fal fa-ellipsis-v" />
									</style.IconWrapper>
								</OverlayTrigger>
							)}
						</div>

						<style.IconContainer className="ta-right"></style.IconContainer>
					</div>
				)}
				<style.ChatConvo
					id={`chatContainer-${chatId}`}
					ref={ref => (this.chatContainer = ref)}
					inputHeight={this.props.inputHeight + (inlineButtons ? 25 : hostButtons ? 40 : 0)}
				>
					{convo.map((c, i) => {
						let ret = null;
						if (!this.props.bannedUsers.includes(c.uid) && !c.isDeleted) {
							ret = (
								<ChatBlock
									convo={c}
									toggleConvoLike={this.toggleCommentLike}
									toggleConvoFlag={this.toggleCommentFlag}
									delConvo={this.delConvo}
									banUser={this.banUser}
									unbanUser={this.unbanUser}
									chatId={chatId}
									clientId={clientId}
									uid={this.props.uid}
									lastUID={lastUID}
									isModerator={this.props.isModerator}
									bannedUsers={this.props.bannedUsers}
									isLightBackground={this.props.isLightBackground}
									isHostManager={this.props.isHostManager}
									chatClosed={this.props.chatClosed}
									useChatProcessor={this.props.useChatProcessor}
									userLoggedIn={this.props.userLoggedIn}
									photoURL={this.props.photoURL}
									isDirect={this.props.isDirect}
									setCurrentChat={this.props.setCurrentChat}
								/>
							);
						}

						lastUID = c.uid;
						return ret ? <style.ChatConvoLine key={c.id}>{ret}</style.ChatConvoLine> : null;
					})}
					{!this.state.loading && this.state.convo.length === 0 && (
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								textAlign: "center",
								fontSize: 20,
							}}
						>
							{this.props.isDirect && !this.props.isHostManager ? (
								<>
									{this.props.request.status === 3 || this.props.request.status === 4 ? (
										<>
											<span>
												Connecting with{" "}
												{
													this.props.request.userData[
														this.props.request.users
															.filter(x => x !== this.props.uid)
															.concat(
																this.props.request.hosts.filter(
																	x => x !== this.props.uid
																)
															)[0]
													].name
												}
												. Send a message to get started!
											</span>
										</>
									) : (
										<span>Send a message to connect with a host.</span>
									)}
								</>
							) : (
								<span>
									<span role="img" aria-label="Hand wave emoji">
										👋{" "}
									</span>
									{this.props.isHostManager &&
									this.props.isDirect &&
									[0, 3].includes(this.props.request.status)
										? this.props.request.createdBy === this.props.uid
											? "Send a message to get started!"
											: "Accept the chat request to get started!"
										: "Send a message to get started!"}
								</span>
							)}
						</div>
					)}
					<Modal show={showEndModal} onHide={this.handleCloseEndModal} className="default-modal">
						<Modal.Header closeButton>
							<Modal.Title>Close Chat?</Modal.Title>
						</Modal.Header>
						<Modal.Body>Are you sure you want to close the chat?</Modal.Body>

						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={this.handleCloseEndModal}
								className="modal-button cancel-button"
							>
								Cancel
							</Button>
							<Button color="#e71d36" onClick={this.endChat} className="modal-button confirm-button">
								Close
							</Button>
						</Modal.Footer>
					</Modal>
					{banModal()}
				</style.ChatConvo>
			</>
		);
	}
}

ChatConversation.propTypes = {
	chatId: PropTypes.string.isRequired,
	isModerator: PropTypes.bool.isRequired,
};

export default withTheme(ChatConversation);
