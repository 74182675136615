import React from "react";
import { Helmet } from "react-helmet-async";

import { Link, AvailableCodeProviders } from "../../interfaces";
import { LinkButton } from "./Links.styled";
import { logEvent } from "../../services/analytics";

export interface LinkElement {
	index: number;
	element: any;
}

interface CodeProviderHelper {
	codeLinkHeaders: any[];
	codeLinkElements: LinkElement[];
}

interface LinkButtonInfo {
	index: number;
	icon: string;
	isTablet: boolean;
}

export const whichProvider = (link: Link) => {
	let provider: string | boolean = "";
	if (link.hasOwnProperty("codeProvider")) {
		provider = AvailableCodeProviders[link.codeProvider as number] || "notfound";
	} else {
		return false;
	}
	return provider;
};

export const codeProviderHelper = (links: Link[]): CodeProviderHelper => {
	const codeLinkHeaders: any[] = [];
	const codeLinkElements: LinkElement[] = [];

	links.forEach((link, index) => {
		if (link.hasOwnProperty("codeProvider")) {
			const provider = whichProvider(link);
			switch (provider) {
				case "planningCenterGiving": {
					codeLinkHeaders.push(<script src="https://js.churchcenter.com/modal/v1" />);
					codeLinkElements.push({
						index,
						element: (
							<a key={`link-code-${index}`} href={`https://${link.code}.churchcenter.com/giving?open-in-church-center-modal=true`}>
								{link.text}
							</a>
						),
					});
					break;
				}
				case "tithely": {
					codeLinkElements.push({
						index,
						element: (
							<a key={`link-code-${index}`} href={link.code}>
								{link.text}
							</a>
						),
					});
					break;
				}
				default:
					break;
			}
		}
	});

	return {
		codeLinkHeaders,
		codeLinkElements,
	};
};

export const elementRender = (provider: string, elementInfo: LinkElement, linkButtonInfo: LinkButtonInfo) => {
	// use this to visually render the button
	const { index, icon, isTablet } = linkButtonInfo;
	switch (provider) {
		case "planningCenterGiving": {
			const { href, children } = elementInfo.element.props;
			return (
				<LinkButton
					key={`link-${index + 1}`}
					style={{ marginRight: 5 }}
					href={href}
					onClick={() => logEvent("click_content_link", children)}
				>
					{icon ? <i className={icon} style={{ marginRight: 5 }} /> : null}
					<p className={`${isTablet ? "isTablet" : ""}`} style={{ marginTop: 0, marginBottom: 0 }}>
						{(children as string).toUpperCase()}
					</p>
				</LinkButton>
			);
		}
		case "tithely": {
			const { href, children } = elementInfo.element.props;
			return (
				<>
					<Helmet>
						<script>var tw = create_tithely_widget();</script>
					</Helmet>
					<LinkButton
						className="tithely-give-btn"
						key={`link-${index + 1}`}
						style={{ marginRight: 5 }}
						data-church-id={href}
						onClick={() => logEvent("click_content_link", children)}
					>
						{icon ? <i className={icon} style={{ marginRight: 5 }} /> : null}
						<p className={`${isTablet ? "isTablet" : ""}`} style={{ marginTop: 0, marginBottom: 0 }}>
							{(children as string).toUpperCase()}
						</p>
					</LinkButton>
				</>
			);
		}
		default:
			break;
	}
};
