import "firebase/auth";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ChannelCard from "../../../components/Dashboard/Channels/ChannelCard";
import { useToast } from "../../../components/Toast/ToastProvider";
import firebase from "../../../firebase";
import { Content } from "../../../interfaces";
import { Channel } from "../../../interfaces/Channel";
import { TypedUseSelectorHook } from "../../../redux";
import api from "../../../services/api";
import asyncForEach from "../../../utils/asyncForEach";
import { generateRandomString } from "../../../utils/generateRandom";
import { PageContainer, Well } from "../UI";
import { AddButton, SmallTitle, StyledChannelContainer, Title } from "./ChannelsList.styled";
import userHasRole from "../../../services/userHasRole";

const useTypedSelector: TypedUseSelectorHook = useSelector;
interface ModalData {
	id: string;
	name: string;
}

const ChannelsList: React.FC = () => {
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const history = useHistory();
	const { addToast } = useToast();
	const db = firebase.firestore();
	const clientKey = dashboard?.clientData?.key;
	const [channelList, setChannelList] = useState<Channel[]>([]);
	const [loading, setLoading] = useState(true);

	const [showModal, setShowModal] = useState(false);
	const [inputValidation, setInputValidation] = useState(false);
	const [inputModalVal, setInputModalVal] = useState("");
	const [modalData, setModalData] = useState<ModalData>();
	const [channelIdForOrphanedContent, setChannelIdForOrphanedContent] = useState<string>();

	const handleCloseModal = () => {
		setShowModal(false);
		setInputModalVal("");
	};
	const handleShowModal = () => setShowModal(true);

	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;

	if (loading && dashboard.clientId) {
		const channelDocRef = db.collection("channels").where("clientId", "==", dashboard.clientId);
		channelDocRef
			.get()
			.then(querySnapshot => {
				let channels: Channel[] = [];

				querySnapshot.forEach(doc => {
					channels = [...channels, doc.data() as Channel];
				});

				channels = channels.filter(channel => !channel.versionCode);

				setChannelList([...channels.sort((a, b) => a.displayOrder - b.displayOrder)]);
			})
			.catch(error => {
				console.log("ChannelsList:React.FC -> error", error);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const deleteChannel = (id: string, name: string) => {
		handleShowModal();

		setModalData({
			id,
			name,
		});
	};

	const updateModalInputVal = (e: any) => {
		setInputModalVal(e.target.value);
	};

	const handleConfirmDelete = (id: string, name: string, confirmedName: string) => {
		if (name.toLowerCase() === confirmedName.toLowerCase()) {
			setInputValidation(false);
			const channel = db.collection("channels").doc(id);
			const versionCode = generateRandomString(8, false, true);
			channel
				.get()
				.then(async doc => {
					if (doc.exists) {
						try {
							const contentsQuery = await db
								.collection("contents")
								.where("channelId", "==", id)
								.where("versionCode", "==", null)
								.get();
							let contents: any[] = [];
							contentsQuery.forEach(c => contents.push({ ...c.data() }));

							const multipleContentsQuery = await db
								.collection("contents")
								.where("channelIds", "array-contains", id)
								.where("versionCode", "==", null)
								.get();

							multipleContentsQuery.forEach(c => {
								if (!contents.find(con => con.id === c.data().id)) {
									contents.push({ ...c.data() });
								}
							});
							if (channelIdForOrphanedContent) {
								await asyncForEach(contents, async (content: Content) => {
									const ref = db.collection("contents").doc(content.id);
									if (!content.channelIds) {
										content.channelIds = [content.channelId];
									}
									if (
										content.channelIds &&
										!content.channelIds.includes(channelIdForOrphanedContent)
									) {
										content.channelIds.push(channelIdForOrphanedContent);
									}
									//Remove the channel Id
									const index = content.channelIds.indexOf(id);
									if (index > -1) {
										content.channelIds.splice(index, 1);
									}
									await ref.set({ ...content }).then(() => console.log(`modified ${content.id}`));
								});
							} else {
								await asyncForEach(contents, async (content: Content) => {
									const vc = generateRandomString(8, false, true);
									const ref = db.collection("contents").doc(content.id);
									if (
										(content?.channelIds?.length === 1 && content.channelIds[0] === id) ||
										(!content.channelIds && content.channelId === id)
									) {
										await ref
											.set({ ...content, versionCode: vc })
											.then(() => console.log(`deleted ${content.id}`));
									} else if (content?.channelIds?.length > 1) {
										//Remove the channel Id
										const index = content.channelIds.indexOf(id);
										if (index > -1) {
											content.channelIds.splice(index, 1);
										}
										await ref.set({ ...content }).then(() => console.log(`modified ${content.id}`));
									}
								});
							}

							channel
								.update({ versionCode })
								.then(() => {
									const newChannelList = channelList.filter(channel => channel.id !== id);
									setChannelList(newChannelList);

									setInputModalVal("");
									const data = doc.data() as Channel;
									if (data.brushfireEventIds?.length || 0 > 0) {
										const channelEventRef = db
											.collection("channel_events")
											.doc(data.createdAt ? moment(data.createdAt).format("YYYYMM") : "older");
										channelEventRef.get().then(channelDoc => {
											const channelEventData = channelDoc.data() as {
												[channelIdString: string]: string[];
											};
											delete channelEventData[data.id];
											channelEventRef.set(channelEventData).then(() => {
												api.updateWebhook().then(() => {
													handleCloseModal();
												});
											});
										});
									} else {
										handleCloseModal();
									}
								})
								.catch(error => {
									console.warn("Error deleting channel -", error);
									addToast("error", "Error deleting channel");
								});
						} catch (error) {
							console.warn("Error deleting channel -", error);
							addToast("error", "Error deleting channel");
						}
					}
				})
				.catch(error => {
					console.warn("Error deleting channel -", error);
					addToast("error", "Error deleting channel");
				});
		} else {
			setInputValidation(true);
		}
	};

	const onChannelsReOrder = (action: string, index: number) => {
		const oldDisplayOrder: number = index;
		let newDisplayOrder: number = oldDisplayOrder;
		const currentChannelList = [...channelList];

		if (action === "up") {
			newDisplayOrder = index -= 1;
		} else if (action === "down") {
			newDisplayOrder = index += 1;
		}

		const moveArrayItemToNewIndex = (arr: any, oldIndex: number, newIndex: number) => {
			if (newIndex >= arr.length) {
				var k = newIndex - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
			return arr;
		};

		let newChannelOrder = moveArrayItemToNewIndex(currentChannelList, oldDisplayOrder, newDisplayOrder);

		newChannelOrder.forEach((channel: Channel, index: number) => {
			const channelRef = db.collection("channels").doc(channel.id);
			channelRef.get().then(doc => {
				if (doc.exists) {
					channelRef.update({ displayOrder: index }).catch(error => {
						console.warn("Error Re Ordering Channels -", error);
						addToast("error", "Error Re Ordering Channels");
					});
				}
			});
		});
		setChannelList(newChannelOrder);
	};

	if (loading || dashboard.clientDataLoading) {
		return null;
	}

	const hasChannelAccess = (channelId: string) => {
		return dashboard.clientId && user.appUser
			? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin", "superhost", "host"], channelId)
			: false;
	};

	return dashboard.clientData ? (
		<PageContainer size="full" htmlPageTitle="Channels">
			<SmallTitle>{dashboard.clientData?.name}</SmallTitle>
			<div className="list-header">
				<Title>CHANNELS</Title>
				{isAdmin && (
					<AddButton
						className="fal fa-plus"
						onClick={() => history.push(`/dashboard/${dashboard.clientId}/channels/add`)}
					/>
				)}
			</div>
			<Well>
				<p className="py-2">
					Channels allow you to organize your content and provide a unique experience to match.{" "}
					<span>
						<a
							href="https://support.brushfire.com/hc/en-us/articles/22237801361165-Adding-Channels-to-Brushfire-Online"
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn more <i className="fal fa-long-arrow-right" />
						</a>
					</span>
				</p>
			</Well>
			{!isEmpty(channelList) && (
				<StyledChannelContainer>
					{channelList?.map((channel, index) => {
						return hasChannelAccess(channel.id) ? (
							<ChannelCard
								clientId={dashboard.clientId}
								clientKey={clientKey}
								channel={channel}
								reOrder={onChannelsReOrder}
								onDelete={deleteChannel}
								channelCount={channelList.length}
								index={index}
								key={index}
							/>
						) : null;
					})}
				</StyledChannelContainer>
			)}

			{modalData && (
				<Modal show={showModal} onHide={handleCloseModal} className="default-modal">
					<Modal.Header closeButton>
						<Modal.Title>Delete Channel?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						This action cannot be undone.
						<br />
						To delete this channel please type <strong>{modalData.name}</strong> below
					</Modal.Body>
					<Col>
						<Form.Control
							value={inputModalVal}
							onChange={updateModalInputVal}
							size="sm"
							type="text"
							placeholder={`Enter "${modalData.name}" to confirm`}
							isInvalid={inputValidation}
						/>
					</Col>
					<Col>
						<p className="m-top">What should we do with the content in this channel?</p>
						<DropdownButton
							id="dropdown-basic-button"
							className="btn-block"
							title={
								dashboard?.channelsData?.find(chan => chan.id === channelIdForOrphanedContent)
									? `Move to ${dashboard?.channelsData?.find(
										chan => chan.id === channelIdForOrphanedContent
									)?.name
									}`
									: "Delete Content"
							}
						>
							<Dropdown.Item
								onClick={() => {
									setChannelIdForOrphanedContent(undefined);
								}}
							>
								Delete Content
							</Dropdown.Item>
							<Dropdown.Divider />
							{dashboard.channelsData &&
								dashboard.channelsData
									.sort((a, b) => {
										return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
									})
									.map(channel => {
										if (channel.id !== modalData.id) {
											return (
												<Dropdown.Item
													key={channel.id}
													onClick={() => {
														setChannelIdForOrphanedContent(channel.id);
													}}
												>
													Move to {channel.name}
												</Dropdown.Item>
											);
										}
									})}
						</DropdownButton>
					</Col>

					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal} className="modal-button cancel-button">
							Close
						</Button>
						<Button
							variant="danger"
							disabled={modalData.name.toLowerCase() !== inputModalVal.toLowerCase()}
							onClick={() => handleConfirmDelete(modalData?.id, modalData?.name, inputModalVal)}
							className="modal-button confirm-button"
						>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<div style={{ marginBottom: 200 }} />
		</PageContainer>
	) : (
		<PageContainer htmlPageTitle="Channels">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>Channels not found.</div>
		</PageContainer>
	);
};

export default ChannelsList;
