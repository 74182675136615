import React, { useContext } from "react";

import { Response } from "../../../interfaces";

import { CloseLink, Context, Dismiss } from "../InteractionResponse";
import * as Styled from "../InteractionResponse.styled";

const iframeRegex = RegExp(/<iframe[^>]*?(?:\/>|>[^<]*?<\/iframe>)/gm);

interface CustomContentProps {
	response: Response;
}

const innerIframeStyles = `
	<style>
	html, body {
		margin: 0;
		padding: 0;
	}
	body {
		display: flex;
    align-items: center;
    justify-content: center;
	}
	iframe {
		width:100%;
		height: 100%;
		border: 0;
	}
	</style>
`;

/**
 * For "customContent" responses
 */
export const CustomContent: React.FC<CustomContentProps> = ({ response }) => {
	const { isPreview, isModal } = useContext(Context);
	const firstIframe = (response.actionText.match(iframeRegex) || [])[0];
	const content = `data:text/html;charset=utf-8,${escape(innerIframeStyles)}${escape(
		firstIframe ? firstIframe : `<div>${response.actionText}</div>`
	)}`;
	return (
		<>
			<Styled.CustomContentFrame sandbox="allow-scripts" src={content} />
			<Styled.InteractionContainer isPreview={isPreview}>
				<Styled.HR isPreview={isPreview} />
				<CloseLink padded={isModal} />
			</Styled.InteractionContainer>
		</>
	);
};
