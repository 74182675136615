import { includes, isEmpty } from "lodash";
import { clientKeyBlacklist, REGEX } from "../../../../constants";
import firebase from "../../../../firebase";
import { Client, Link } from "../../../../interfaces";
const db = firebase.firestore();

export const validate = async (values: Client) => {
	const errors: any = {};

	// name
	if (isEmpty(values.name)) {
		errors.name = "Name is required";
	}

	// key
	if (isEmpty(values.key)) {
		errors.key = "URL is required";
	} else if (!isEmpty(values.key)) {
		if (values.key.length < 2) {
			errors.key = "URL needs to be at least 2 characters";
		} else if (values.key.length > 50) {
			errors.key = "URL cannot be more than 50 characters";
		}

		if (values.key.match(/[^a-zA-Z0-9-]/g)) {
			errors.key = "URL can only include letters, numbers, and dashes";
		}

		const checkDb = async () => {
			return new Promise(async (resolve, reject) => {
				try {
					if (values.key && !clientKeyBlacklist.includes(values.key.toLowerCase())) {
						await db
							.collection("clients")
							.where("key", "==", values.key.toLowerCase())
							.get()
							.then(async docs => {
								if (docs.docs.filter(doc => doc.id !== values.id).length !== 0) {
									resolve(false);
								}
								resolve(true);
							})
							.catch(() => {
								resolve(true);
							});
					} else {
						resolve(false);
					}
				} catch (err) {
					console.log(err);
					errors.key = "Something went wrong. Please try again";
					resolve(false);
				}
			});
		};
		const res = await checkDb();
		if (!res) {
			errors.key = "URL is already taken";
		}
	}

	// customDomain
	if (!!values.customDomain) {
		if (values.customDomain.match(/[^a-zA-Z0-9.-]+/g)) {
			errors.customDomain = "Custom Domain can only include letters, numbers, dashes, and periods";
		}
	}

	// logoImage
	if (isEmpty(values.logoImage)) {
		errors.logoImage = "Logo is required";
	}

	// links
	if (!isEmpty(values.links)) {
		values.links.forEach((link: Link, index: number) => {
			const linkError: any = {};
			if (isEmpty(link.text)) {
				linkError.text = `(Link ${index + 1}) Link Name is required`;
			}
			if (link.url) {
				const linkUrlError: any = {};
				if (isEmpty(link.url.url)) {
					linkUrlError.url = `(Link ${index + 1}) External Link is required`;
				}
				if (isEmpty(link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Target is required`;
				} else if (!includes(["newTab", "sameTab", "iframeModal", "sideBar"], link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Unknown URL target`;
				}

				if (!isEmpty(linkUrlError)) {
					linkError.url = linkUrlError;
				}
			}
			if (link.hasOwnProperty("codeProvider")) {
				if (isEmpty(link.codeProvider)) {
					linkError.codeProvider = `(Link ${index + 1}) A code provider must be chosen`;
				}
				if (isEmpty(link.code)) {
					linkError.code = `(Link ${index + 1}) A code provider key must be provided`;
				}
			}
			if (link.hasOwnProperty("html")) {
				if (isEmpty(link.html)) {
					linkError.html = `$(Link ${index + 1}) HTML content is required`;
				}
			}

			if (isEmpty(errors.links)) {
				errors.links = [];
			}
			errors.links.push(linkError);
		});

		const anyLinkErrors = errors.links.some((l: any) => !isEmpty(l));
		if (!anyLinkErrors) {
			delete errors.links;
		}
	}

	Object.keys(values.theme).forEach(key => {
		if (
			key.includes("Color") &&
			//@ts-ignore
			values.theme[key] &&
			//@ts-ignore
			values.theme[key] !== "" &&
			//@ts-ignore
			!values.theme[key].match(REGEX.HEX_COLOR)
		) {
			if (!errors.theme) {
				errors.theme = {};
			}
			errors.theme[key] = "Please use a valid hexadecimal color code";
		}
	});

	// timezone
	if (isEmpty(values.timeZoneName)) {
		errors.timeZoneName = "Must select a default time zone";
	}

	// HomeUrl
	if (values.homeUrl && values.homeUrl !== "" && !values.homeUrl.match(REGEX.URL)) {
		errors.homeUrl = "Please include a valid URL";
	}

	return errors;
};
