import { User } from "../../interfaces";

export interface AnalyticsState {
	analyticsUserId: string;
}

export const SET_ANALYTICS_USER_ID: string = "SET_ANALYTICS_USER_ID";

export interface ISET_ANALYTICS_USER_ID {
	type: typeof SET_ANALYTICS_USER_ID;
	payload?: string;
}

export type AnalyticsActionTypes = ISET_ANALYTICS_USER_ID;
