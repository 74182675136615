import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReactMomentCountdown from "react-moment-countdown";
import moment from "moment";
import { isEmpty } from "lodash";

import InteractionResponse from "../../../../../components/InteractionResponse";
import firebase from "../../../../../firebase";
import { Interaction, Response, Answer, Content } from "../../../../../interfaces";

import * as Styled from "./PreviewPanel.styled";

const db = firebase.firestore();

interface ResponseText {
	id: string | null;
	text: string;
	count: number;
}

interface Count {
	id: string | null;
	[key: string]: string | number | null;
}

interface PreviewPanelProps {
	interactionId: string;
	content: Content;
	onInteractionSend: (interaction: Interaction) => void;
	setCurrentPreview: (s: string | undefined) => void;
	setEditPanelOpen: (setOpen: boolean) => void;
	setDeletePanelOpen: (setOpen: boolean) => void;
	hasInteractionsEditAccess: boolean;
}

export const PreviewPanel: React.FC<PreviewPanelProps> = ({
	interactionId,
	content,
	onInteractionSend,
	setCurrentPreview,
	setEditPanelOpen,
	setDeletePanelOpen,
	hasInteractionsEditAccess,
}) => {
	const [update, setUpdate] = useState(0);
	const [interaction, setInteraction] = useState<Interaction>();
	const [answers, setAnswers] = useState<Answer[]>([]);
	const [showPreviewContent, setShowPreviewContent] = useState(true);

	const { id: contentId } = content;

	useEffect(() => {
		const unsubscribe = db
			.collection("interactions")
			.doc("contents")
			.collection(contentId)
			.doc(interactionId)
			.onSnapshot(doc => {
				if (doc.exists) {
					setInteraction(doc.data() as Interaction);
				}
			});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const unsubscribe = db
			.collection("answers")
			.doc("interactions")
			.collection(interactionId)
			.onSnapshot((docs: any) => {
				const tempAnswers: Answer[] = [];
				docs.forEach((a: any) => tempAnswers.push({ ...(a.data() as Answer) }));
				setAnswers(tempAnswers);
			});

		return () => {
			unsubscribe();
		};
	}, []);

	const onSend = () => {
		interaction && onInteractionSend(interaction);
	};

	const showAtText = interaction?.showAt ? moment(interaction.showAt).format("MM/DD/YYYY hh:mm A") : "";

	const simpleResponseTexts: string[] = [];
	const responseTexts: ResponseText[] = [];
	const counts: Count[] = [];

	if (interaction) {
		interaction?.responses.forEach(response => {
			simpleResponseTexts.push(response.responseText);
			responseTexts.push({
				id: response.id,
				text: response.responseText,
				count: 0,
			});
		});
		responseTexts.push({
			id: "0",
			text: "dismiss",
			count: 0,
		});

		responseTexts.forEach(rObj => {
			const countOfTextInAnswers = answers.filter(a => {
				if (rObj.id === "0") {
					return a.action === "dismiss" && !a.responseId;
				} else {
					return a.responseId === rObj.id;
				}
			}).length;
			counts.push({ id: rObj.id, [rObj.text]: countOfTextInAnswers });
		});
	}

	const isLive =
		content.startsAt && content.closesAt
			? moment.utc().isBetween(content.startsAt, content.closesAt, undefined, "[]")
			: false;

	const isOpen =
		content.opensAt && content.closesAt
			? moment.utc().isBetween(content.opensAt, content.closesAt, undefined, "[]")
			: false;

	return interaction ? (
		<Styled.Container>
			<Styled.LiveContainer>
				{content.startsAt && !isLive && (
					<div style={{ display: "none" }}>
						<ReactMomentCountdown
							toDate={moment(content.startsAt)}
							onCountdownEnd={() => {
								setUpdate(v => {
									return v + 1;
								});
							}}
						/>
					</div>
				)}
				{content.closesAt && isLive && (
					<div style={{ display: "none" }}>
						<ReactMomentCountdown
							toDate={moment(content.closesAt)}
							onCountdownEnd={() => {
								setUpdate(v => {
									return v + 1;
								});
							}}
						/>
					</div>
				)}
				<div className="show-mobile m-bottom" onClick={() => setCurrentPreview(undefined)}>
					<h3 className="ellipsis cursor-pointer">
						<i className="fas fa-chevron-left m-right"></i>
						{interaction.interactionTitle}
					</h3>
				</div>
				<div className="hide-mobile m-bottom">
					<h3 className="ellipsis">{interaction.interactionTitle}</h3>
				</div>
				{interaction.showAt ? (
					<Button href="#" variant="secondary" disabled>
						Sent {showAtText}
					</Button>
				) : isOpen ? (
					<Button onClick={onSend}>Send</Button>
				) : (
					<Button href="#" variant="secondary" disabled>
						Video Not Live
					</Button>
				)}
				<Styled.ResponsesContainer>
					{counts.map((count, index) => {
						const response = interaction.responses.find(r => r.id === count.id);

						if (!response) {
							// autofilled dismiss count
							return (
								<Styled.ResponseRow key={index}>
									<div>Dismiss</div>
									<div>{count["dismiss"]}</div>
								</Styled.ResponseRow>
							);
						} else {
							return (
								<Styled.ResponseRow key={response.id}>
									<div>{capitalize(response.responseText)}</div>
									<div>{count[response.responseText]}</div>
								</Styled.ResponseRow>
							);
						}
					})}
				</Styled.ResponsesContainer>
			</Styled.LiveContainer>
			<Styled.PreviewContainer className="hide-mobile-border">
				<Styled.PreviewTitleContainer>
					<h2>Preview</h2>
					<Styled.PreviewReset
						onClick={() => {
							setShowPreviewContent(false);
							setTimeout(() => setShowPreviewContent(true), 0);
						}}
					>
						<i className="fas fa-sync-alt"></i>
					</Styled.PreviewReset>
				</Styled.PreviewTitleContainer>
				{showPreviewContent && (
					<InteractionResponse
						interaction={interaction}
						respondToInteraction={() => console.log("respond")}
						isPreview
					/>
				)}
			</Styled.PreviewContainer>
			{hasInteractionsEditAccess && !interaction?.showAt && (
				<div className="d-flex">
					<Styled.EditButton variant={"secondary"} onClick={() => setEditPanelOpen(true)}>
						Edit
					</Styled.EditButton>
					<Styled.DeleteButton variant={"danger"} onClick={() => setDeletePanelOpen(true)}>
						Delete
					</Styled.DeleteButton>
				</div>
			)}
		</Styled.Container>
	) : null;
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
