import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactPlayer, { Config } from "react-player";
import { Content, ContentType, VideoProvider } from "../../interfaces";
import { FormikContextType } from "formik";
import { REGEX } from "../../constants";
import { isEmpty } from "lodash";

interface VideoProps {
	content: Content;
	isPreview?: boolean;
	formikContext?: FormikContextType<Content>;
	updatePlaceholderStatus?: (shouldShow: boolean) => void;
}

export const Video: React.FC<VideoProps> = ({ content, isPreview, formikContext, updatePlaceholderStatus }) => {
	const videoPlayer = useRef<ReactPlayer | null>(null);

	let { src, videoProvider, opensAt, startsAt, closesAt, contentType } = content;

	const [contentId, setContentId] = useState(content.id);
	const [resetVideo, setResetVideo] = useState(false);
	const [isPlaying, setIsPlaying] = useState(true);
	const [isSeeking, setIsSeeking] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [muted, setMuted] = useState(true);
	const [isBuffering, setIsBuffering] = useState(false);
	const [livingAsOneShouldMount, setLivingAsOneShouldMount] = useState(true);
	const [embedIdIsStandard, setEmbedIdIsStandard] = useState(true);

	useEffect(() => {
		if (content.id !== contentId) {
			setResetVideo(true);
			setContentId(content.id);

			setTimeout(() => {
				setResetVideo(false);
			}, 100);
		}
	}, [content.id]);

	useEffect(() => {
		//re-initializes the resi script so that a valid new src is recognized.
		if (
			(isPreview && videoProvider === VideoProvider.livingAsOne) ||
			videoProvider === VideoProvider.livingAsOneSingle
		) {
			if (src.match(REGEX.GUID)) {
				setEmbedIdIsStandard(true);
			} else {
				setEmbedIdIsStandard(false);
			}
			setLivingAsOneShouldMount(false);
			setTimeout(() => {
				setLivingAsOneShouldMount(true);
			}, 100);
		}
	}, [src]);

	useEffect(() => {
		if (isPreview) return;
		if (opensAt && closesAt) {
			const now = moment();
			const opensAtMoment = moment(opensAt);
			const closesAtMoment = moment(closesAt);
			const diffSeconds = now.diff(opensAtMoment) / 1000;
			if (diffSeconds > 0) {
				const videoLength = videoPlayer.current?.getDuration();
				if (videoLength) {
					if (diffSeconds < videoLength || now.isBetween(opensAtMoment, closesAtMoment)) {
						setIsReady(true);
					} else {
						//Live is over
						setIsOver(true);
					}
				} else {
					setIsReady(true);
				}
			} else {
				const timeout = diffSeconds * -1;
				setTimeout(() => {
					setIsReady(true);
				}, timeout * 1000);
			}
		} else {
			setIsReady(true);
		}
	}, [opensAt, startsAt, contentId]);

	useEffect(() => {
		if (isSeeking && isPlaying) {
			goToLiveTime();
		}
	}, [isSeeking, isPlaying]);

	const videoSrc = () => {
		if (src?.startsWith("<iframe")) {
			// let iframeURL = src.match("<iframe.+?src=[\"'](.+?)[\"'].*?>") || [];
			// const extraParams = `responsive=yes`;
			// if (iframeURL[1].includes("?")) {
			//  return `${iframeURL[1]}&${extraParams}`;
			// } else {
			//  return `${iframeURL[1]}?${extraParams}`;
			// }
			const widthRegex = /(width=(\"\d+\"))/;
			const heightRegex = /(height=(\"\d+\"))/;

			let widthMatch = src.match(widthRegex);
			let heightMatch = src.match(heightRegex);

			const insert = (src: string, insertAt: number, str: string) => {
				return [src.slice(0, insertAt), str, src.slice(insertAt)].join("");
			};

			let insertAt = src.indexOf("iframe") + "iframe".length;
			let newSrc = src;
			if (widthMatch) {
				newSrc = newSrc.replace(widthRegex, 'width="100%"');
			} else {
				newSrc = insert(newSrc, insertAt, ' width="100%"');
			}

			if (heightMatch) {
				newSrc = newSrc.replace(heightRegex, 'height="100%"');
			} else {
				newSrc = insert(newSrc, insertAt, ' height="100%"');
			}
			if (newSrc?.includes("youtu.be") || newSrc?.includes("youtube.com")) {
				let iframeURL = newSrc.match("<iframe.+?src=[\"'](.+?)[\"'].*?>") || [];
				if (!iframeURL[1]) {
					return "";
				}
				if (!iframeURL[1].includes("autoplay")) {
					const extraParams = `autoplay=1`;
					if (iframeURL[1].includes("?")) {
						return `${iframeURL[0]}${iframeURL[1]}&${extraParams}${iframeURL[2]}`;
					} else {
						return `${iframeURL[0]}${iframeURL[1]}?${extraParams}${iframeURL[2]}`;
					}
				}
			}
			return newSrc;
		}
		if (src && src.charAt(src.length - 1) === "/") {
			src = src.substring(0, src.length - 1);
		}
		if (src?.includes("youtu.be") || src?.includes("youtube.com")) {
			const videoUrlArray = src.split("/");
			const videoId = videoUrlArray[videoUrlArray.length - 1];
			return `https://www.youtube.com/embed/${videoId}`;
		}
		if (src?.includes("vimeo.com")) {
			const videoUrlArray = src.split("/");
			const videoId = videoUrlArray[videoUrlArray.length - 1];
			return `https://player.vimeo.com/video/${videoId}`;
		}
		if (src?.includes("facebook.com")) {
			return src; //`https://www.facebook.com/plugins/video.php?href=${src}`;
		}

		if (src?.includes("livestream.com")) {
			//<iframe id="ls_embed_1585926116" src="https://livestream.com/accounts/22300508/events/6675945/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false
			return `${src}/player`;
		}
		if (!livingAsOneShouldMount) {
			return "";
		}
		return src || "";
	};

	const livingAsOneId = () => {
		if (src?.startsWith("<div")) {
			let srcMatches = src.match(/data-embed-id\=\"([A-Za-z0-9- _]*)\"/);

			return srcMatches ? srcMatches[1] : "";
		}
		return src || "";
	};

	const livingAsOneType = () => {
		if (src?.startsWith("<div")) {
			let srcMatches = src.match(/data-type\=\"([A-Za-z0-9- _]*)\"/);

			return srcMatches ? srcMatches[1] : null;
		}
		if (videoProvider === VideoProvider.livingAsOneSingle) {
			return "event";
		} else {
			return null;
		}
	};

	const goToSeconds = (seconds: number) => {
		setTimeout(() => {
			videoPlayer.current?.seekTo(seconds, "seconds");
			setTimeout(() => {
				setIsSeeking(false);
			}, 500);
		}, 100);
	};

	const goToLiveTime = () => {
		if (isPreview) return;
		if (contentType === ContentType.simulatedLive && !isBuffering) {
			setIsSeeking(true);
			const now = moment();
			const opensAtMoment = moment(opensAt);
			const diffSeconds = now.diff(opensAtMoment) / 1000;
			if (diffSeconds > 0) {
				const videoLength = videoPlayer.current?.getDuration();
				if (videoLength) {
					if (diffSeconds < videoLength) {
						goToSeconds(diffSeconds);
					} else {
						//Live is over
						//TODO handle this
						//goToSeconds(videoLength);
					}
				} else {
					goToSeconds(diffSeconds);
				}
			}
		}
	};

	const onStart = () => {
		goToLiveTime();
	};

	const onPlay = () => {
		if (!isPlaying || videoProvider === VideoProvider.facebook) {
			setTimeout(() => {
				setIsPlaying(true);
				goToLiveTime();
			}, 10);
		}
	};

	const onPause = () => {
		if (isPlaying) {
			setIsPlaying(false);
		}
	};

	const onSeek = () => {
		if (isPlaying && !isSeeking) {
			setIsSeeking(true);
		}
	};

	const onBuffer = () => {
		setIsBuffering(true);
	};

	const onBufferEnd = () => {
		setIsBuffering(false);
	};

	const onReady = () => {
		if (isPreview && updatePlaceholderStatus) {
			formikContext?.setFieldValue("videoHasError", false);
			updatePlaceholderStatus(false);
		}
		goToLiveTime();
		if (videoProvider === VideoProvider.facebook) {
			// console.log(videoPlayer.current);
			// console.log(videoPlayer.current?.getInternalPlayer());
			// console.log("Play?");
			// //@ts-ignore
			// //videoPlayer.current?.getInternalPlayer().setAttribute("autoplay", "autoplay");
			// //@ts-ignore
			// videoPlayer.current?.getInternalPlayer().play();
			// //@ts-ignore
			// window.FB.Event.subscribe("xfbml.ready", function(msg) {
			//  if (msg.type === "video") {
			//      goToLiveTime();
			//      //@ts-ignore
			//      msg.instance.play();
			//  }
			// });
		}
	};

	const onError = () => {
		if (isPreview && updatePlaceholderStatus) {
			formikContext?.setFieldValue("videoHasError", true);
			updatePlaceholderStatus(true);
		}
	};

	const playerConfig: Config = {
		youtube: {
			playerVars: {
				disablekb: 0,
				modestbranding: 0,
				controls: 1,
			},
		},
		vimeo: {
			playerOptions: {
				autoplay: true,
				portrait: false,
				title: false,
			},
		},
	};

	if (!!content.ccLanguage) {
		//@ts-ignore
		playerConfig.youtube.playerVars.hl = content.ccLanguage;
		//@ts-ignore
		playerConfig.youtube.playerVars.cc_lang_pref = content.ccLanguage;
		//@ts-ignore
		playerConfig.youtube.playerVars.cc_load_policy = 1;
	}

	const livingAsOneBase64Id = () => {
		return `https://control.resi.io/webplayer/video.html?id=${src}&type=library&autoplay=true`;
	};

	return (
		<>
			<div
				style={styles.videoWrapper}
				className={`video-wrapper provider-${VideoProvider[videoProvider]} content-type-${
					ContentType[contentType || ContentType.live]
				}`}
			>
				<div style={styles.aspectRatio}>
					{(((isReady && !isOver) || !opensAt) && !resetVideo) || isPreview ? (
						<>
							{videoProvider &&
							isEmpty(formikContext?.errors.src) &&
							livingAsOneShouldMount &&
							(videoProvider === VideoProvider.livingAsOne ||
								videoProvider === VideoProvider.livingAsOneSingle) ? (
								<>
									{contentType === ContentType.onDemand && !embedIdIsStandard ? (
										<iframe
											allow="autoplay; fullscreen"
											src={livingAsOneBase64Id()}
											style={styles.video}
											//@ts-ignore
											webkitallowfullscreen=""
											//@ts-ignore
											mozallowfullscreen=""
											allowfullscreen="true"
										/>
									) : (
										<>
											<div
												id="resi-video-player"
												style={styles.video}
												data-embed-id={livingAsOneId()}
												data-type={livingAsOneType()}
											></div>
											<Helmet>
												<script
													type="application/javascript"
													src="https://control.resi.io/webplayer/loader.min.js"
												></script>
											</Helmet>
										</>
									)}
								</>
							) : videoProvider && videoProvider === VideoProvider.livestream ? (
								<>
									<iframe
										src={videoSrc()}
										style={styles.video}
										//@ts-ignore
										webkitallowfullscreen=""
										//@ts-ignore
										mozallowfullscreen=""
										allowfullscreen=""
									/>
								</>
							) : contentType === ContentType.live ||
							  (videoProvider && videoProvider === VideoProvider.other) ? (
								<div
									className="player-wrapper"
									style={styles.video}
									dangerouslySetInnerHTML={{ __html: videoSrc() }}
								/>
							) : (
								<div className="player-wrapper" style={styles.video}>
									{muted && videoProvider !== VideoProvider.facebook && !isPreview && (
										<button className="unmute-button" onClick={() => setMuted(false)}>
											<i className="fas fa-volume-up"></i> Unmute
										</button>
									)}
									<ReactPlayer
										url={videoSrc()}
										playsinline
										width="100%"
										height="100%"
										ref={videoPlayer}
										onStart={onStart}
										onPlay={onPlay}
										onPause={onPause}
										onSeek={onSeek}
										onBuffer={onBuffer}
										onBufferEnd={onBufferEnd}
										onReady={onReady}
										onError={onError}
										playing={isPreview ? false : isPlaying}
										volume={0.5}
										muted={isPreview ? true : muted} //This is just default muted and makes Facebook autoplay
										controls={true}
										config={playerConfig}
									/>
								</div>
							)}
						</>
					) : (
						<div
							className="player-wrapper"
							style={{ ...styles.video, backgroundImage: `url('${content.thumbImage}')` }}
						/>
					)}
				</div>
			</div>
		</>
	);
};

const styles = {
	videoWrapper: {
		display: "block",
		position: "relative" as "relative",
		overflow: "hidden",
		margin: "auto",
	},
	video: {
		top: 0,
		left: 0,
		position: "absolute" as "absolute",
		width: "100%",
		height: "100%",
		border: "none",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	aspectRatio: {
		paddingBottom: "56.25%",
	},
};

export default Video;
