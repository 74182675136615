import { createLogic } from "redux-logic";
import { LOGOUT_USER } from "../types";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "../../../firebase";

const logoutUserLogic = createLogic({
	type: LOGOUT_USER,
	latest: true,
	// eslint-disable-next-line no-unused-vars
	process({ getState, action }, dispatch, done) {
		firebase
			.auth()
			.signOut()
			.then(() => {
				if (window.location?.pathname?.includes("/dashboard")) {
					firebaseApp.auth().signInAnonymously();
				}
			});
		//TODO -> Send message and reroute
		// dispatch(ControlPanelActions.displayQuickMessage(action.message, "success", "user-lock"));
		done();
	},
});

export default [logoutUserLogic];
