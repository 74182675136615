export const REGEX = {
	EMAIL: new RegExp("^([0-9a-zA-Z]+[-._+&])*[0-9a-zA-Z_]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,63}$"),
	PHONE: new RegExp("^((?:[\\.\\*\\(\\)\\-\\/\\s\\+]*\\d){10,})(.*)$"),
	INVALID_HTML_TAGS: new RegExp(
		/<+(applet|body|embed|frame|frameset|html|head[^er]|ilayer|layer|link|meta|object|script)|javascript:|expressions*\(/
	),
	VALID_URL_KEY: new RegExp(/^[a-zA-Z0-9-_]+$/),
	URL: new RegExp(
		/^((http|https|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$|mailto:(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+(?:\.(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i
	),
	GUID: new RegExp("^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$"),
	HEX_COLOR: new RegExp("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"),
	GOOGLE_UA_ID: new RegExp("^((UA|G)-[-0-9A-Za-z]{4,50})+$"),
	DOUBLE_QUOTE: new RegExp(/[\u201C\u201D]/g),
	SINGLE_QUOTE: new RegExp(/[\u2018\u2019]/g),
	NEW_LINES: new RegExp(/\r\n|\r|\n/gm),
};

export const REACTION_SHARD_COUNT = 20;

export const BFO_TRACKING_CODE = "UA-40514847-27";
export const BFO_GA_TRACKING_CODE = "G-Q8N0W7HNWL";

export const GA4_LAUNCH = new Date(2023, 2, 15); //March 15, 2023

export const clientKeyBlacklist = ["dashboard", "events"];

export const badWords = [
	"4r5e",
	"5h1t",
	"5hit",
	"a55",
	"anal",
	"anus",
	"ar5e",
	"arrse",
	"arse",
	"ass",
	"ass-fucker",
	"asses",
	"assfucker",
	"assfukka",
	"asshole",
	"assholes",
	"asswhole",
	"a_s_s",
	"b!tch",
	"b00bs",
	"b17ch",
	"b1tch",
	"ballbag",
	"ballsack",
	"bastard",
	"beastial",
	"beastiality",
	"bellend",
	"bestial",
	"bestiality",
	"biatch",
	"bitch",
	"bitcher",
	"bitchers",
	"bitches",
	"bitchin",
	"bitching",
	"blow job",
	"blowjob",
	"blowjobs",
	"boiolas",
	"bollock",
	"bollok",
	"boner",
	"boob",
	"boobs",
	"booobs",
	"boooobs",
	"booooobs",
	"booooooobs",
	"buceta",
	"bugger",
	"bum",
	"bunny fucker",
	"butthole",
	"buttmuch",
	"buttplug",
	"c0ck",
	"c0cksucker",
	"carpet muncher",
	"cawk",
	"chink",
	"cipa",
	"cl1t",
	"clit",
	"clitoris",
	"clits",
	"cnut",
	"cock",
	"cock-sucker",
	"cockface",
	"cockhead",
	"cockmunch",
	"cockmuncher",
	"cocks",
	"cocksuck",
	"cocksucked",
	"cocksucker",
	"cocksucking",
	"cocksucks",
	"cocksuka",
	"cocksukka",
	"cok",
	"cokmuncher",
	"coksucka",
	"coon",
	"cum",
	"cummer",
	"cumming",
	"cums",
	"cumshot",
	"cunilingus",
	"cunillingus",
	"cunnilingus",
	"cunt",
	"cuntlick",
	"cuntlicker",
	"cuntlicking",
	"cunts",
	"cyalis",
	"cyberfuc",
	"cyberfuck",
	"cyberfucked",
	"cyberfucker",
	"cyberfuckers",
	"cyberfucking",
	"d1ck",
	"damn",
	"dick",
	"dickhead",
	"dildo",
	"dildos",
	"dink",
	"dinks",
	"dirsa",
	"dlck",
	"dog-fucker",
	"doggin",
	"dogging",
	"donkeyribber",
	"doosh",
	"duche",
	"dyke",
	"ejaculate",
	"ejaculated",
	"ejaculates",
	"ejaculating",
	"ejaculatings",
	"ejaculation",
	"ejakulate",
	"f u c k",
	"f u c k e r",
	"f4nny",
	"fag",
	"fagging",
	"faggitt",
	"faggot",
	"faggs",
	"fagot",
	"fagots",
	"fags",
	"fanny",
	"fannyflaps",
	"fannyfucker",
	"fanyy",
	"fatass",
	"fcuk",
	"fcuker",
	"fcuking",
	"feck",
	"fecker",
	"felching",
	"fellate",
	"fellatio",
	"fingerfuck",
	"fingerfucked",
	"fingerfucker",
	"fingerfuckers",
	"fingerfucking",
	"fingerfucks",
	"fistfuck",
	"fistfucked",
	"fistfucker",
	"fistfuckers",
	"fistfucking",
	"fistfuckings",
	"fistfucks",
	"flange",
	"fook",
	"fooker",
	"fuck",
	"fucka",
	"fucked",
	"fucker",
	"fuckers",
	"fuckhead",
	"fuckheads",
	"fuckin",
	"fucking",
	"fuckings",
	"fuckingshitmotherfucker",
	"fuckme",
	"fucks",
	"fuckwhit",
	"fuckwit",
	"fudge packer",
	"fudgepacker",
	"fuk",
	"fuker",
	"fukker",
	"fukkin",
	"fuks",
	"fukwhit",
	"fukwit",
	"fux",
	"fux0r",
	"f_u_c_k",
	"gangbang",
	"gangbanged",
	"gangbangs",
	"gaylord",
	"gaysex",
	"goatse",
	"god-dam",
	"god-damned",
	"goddamn",
	"goddamned",
	"hardcoresex",
	"heshe",
	"hoar",
	"hoare",
	"hoer",
	"homo",
	"hore",
	"horniest",
	"horny",
	"hotsex",
	"jack-off",
	"jackoff",
	"jerk-off",
	"jism",
	"jiz",
	"jizm",
	"jizz",
	"kawk",
	"knobead",
	"knobed",
	"knobend",
	"knobhead",
	"knobjocky",
	"knobjokey",
	"kock",
	"kondum",
	"kondums",
	"kum",
	"kummer",
	"kumming",
	"kums",
	"kunilingus",
	"l3itch",
	"labia",
	"lmfao",
	"m0f0",
	"m0fo",
	"m45terbate",
	"ma5terb8",
	"ma5terbate",
	"masochist",
	"mo-fo",
	"mof0",
	"mofo",
	"mothafuck",
	"mothafucka",
	"mothafuckas",
	"mothafuckaz",
	"mothafucked",
	"mothafucker",
	"mothafuckers",
	"mothafuckin",
	"mothafucking",
	"mothafuckings",
	"mothafucks",
	"mother fucker",
	"motherfuck",
	"motherfucked",
	"motherfucker",
	"motherfuckers",
	"motherfuckin",
	"motherfucking",
	"motherfuckings",
	"motherfuckka",
	"motherfucks",
	"mutha",
	"muthafecker",
	"muthafuckker",
	"muther",
	"mutherfucker",
	"n1gga",
	"n1gger",
	"nazi",
	"nigg3r",
	"nigg4h",
	"nigga",
	"niggah",
	"niggas",
	"niggaz",
	"nigger",
	"niggers",
	"nob jokey",
	"nobhead",
	"nobjocky",
	"nobjokey",
	"numbnuts",
	"nutsack",
	"orgasim",
	"orgasims",
	"orgasm",
	"orgasms",
	"p0rn",
	"pawn",
	"pecker",
	"penis",
	"penisfucker",
	"phonesex",
	"phuck",
	"phuk",
	"phuked",
	"phuking",
	"phukked",
	"phukking",
	"phuks",
	"phuq",
	"pigfucker",
	"pimpis",
	"piss",
	"pissed",
	"pisser",
	"pissers",
	"pisses",
	"pissflaps",
	"pissin",
	"pissing",
	"pissoff",
	"poop",
	"porn",
	"porno",
	"pornos",
	"prick",
	"pricks",
	"pron",
	"pube",
	"pusse",
	"pussi",
	"pussies",
	"pussy",
	"pussys",
	"rectum",
	"retard",
	"rimjaw",
	"rimming",
	"s hit",
	"s.o.b.",
	"sadist",
	"schlong",
	"screwing",
	"scroat",
	"scrote",
	"semen",
	"sh!t",
	"sh1t",
	"shag",
	"shagger",
	"shaggin",
	"shagging",
	"shemale",
	"shit",
	"shitdick",
	"shite",
	"shited",
	"shitey",
	"shitfuck",
	"shitfull",
	"shithead",
	"shithole",
	"shiting",
	"shitings",
	"shits",
	"shitted",
	"shitter",
	"shitters",
	"shitting",
	"shittings",
	"shitty",
	"skank",
	"slut",
	"sluts",
	"smegma",
	"smut",
	"son-of-a-bitch",
	"spac",
	"spunk",
	"s_h_i_t",
	"t1tt1e5",
	"t1tties",
	"teets",
	"teez",
	"testical",
	"testicle",
	"tit",
	"titfuck",
	"tits",
	"titt",
	"tittie5",
	"tittiefucker",
	"titties",
	"tittyfuck",
	"tittywank",
	"titwank",
	"turd",
	"tw4t",
	"twat",
	"twathead",
	"twatty",
	"twunt",
	"twunter",
	"v14gra",
	"v1gra",
	"vagina",
	"viagra",
	"vulva",
	"wang",
	"wank",
	"wanker",
	"wanky",
	"whoar",
	"whore",
];

export const ccLanguages = [
	{ label: "Afar", value: "aa" },
	{ label: "Abkhazian", value: "ab" },
	{ label: "Avestan", value: "ae" },
	{ label: "Afrikaans", value: "af" },
	{ label: "Akan", value: "ak" },
	{ label: "Amharic", value: "am" },
	{ label: "Aragonese", value: "an" },
	{ label: "Arabic", value: "ar" },
	{ label: "Assamese", value: "as" },
	{ label: "Avaric", value: "av" },
	{ label: "Aymara", value: "ay" },
	{ label: "Azerbaijani", value: "az" },
	{ label: "Bashkir", value: "ba" },
	{ label: "Belarusian", value: "be" },
	{ label: "Bulgarian", value: "bg" },
	{ label: "Bislama", value: "bi" },
	{ label: "Bambara", value: "bm" },
	{ label: "Bengali", value: "bn" },
	{ label: "Tibetan", value: "bo" },
	{ label: "Breton", value: "br" },
	{ label: "Bosnian", value: "bs" },
	{ label: "Catalan", value: "ca" },
	{ label: "Valencian", value: "ca" },
	{ label: "Chechen", value: "ce" },
	{ label: "Chamorro", value: "ch" },
	{ label: "Corsican", value: "co" },
	{ label: "Cree", value: "cr" },
	{ label: "Czech", value: "cs" },
	{ label: "Church Slavic", value: "cu" },
	{ label: "Old Slavonic", value: "cu" },
	{ label: "Church Slavonic", value: "cu" },
	{ label: "Old Bulgarian", value: "cu" },
	{ label: "Old Church Slavonic", value: "cu" },
	{ label: "Chuvash", value: "cv" },
	{ label: "Welsh", value: "cy" },
	{ label: "Danish", value: "da" },
	{ label: "German", value: "de" },
	{ label: "Divehi", value: "dv" },
	{ label: "Dhivehi", value: "dv" },
	{ label: "Maldivian", value: "dv" },
	{ label: "Dzongkha", value: "dz" },
	{ label: "Ewe", value: "ee" },
	{ label: "Greek, Modern (1453-)", value: "el" },
	{ label: "English", value: "en" },
	{ label: "Esperanto", value: "eo" },
	{ label: "Spanish", value: "es" },
	{ label: "Castilian", value: "es" },
	{ label: "Estonian", value: "et" },
	{ label: "Basque", value: "eu" },
	{ label: "Persian", value: "fa" },
	{ label: "Fulah", value: "ff" },
	{ label: "Finnish", value: "fi" },
	{ label: "Fijian", value: "fj" },
	{ label: "Faroese", value: "fo" },
	{ label: "French", value: "fr" },
	{ label: "Western Frisian", value: "fy" },
	{ label: "Irish", value: "ga" },
	{ label: "Gaelic", value: "gd" },
	{ label: "Scottish Gaelic", value: "gd" },
	{ label: "Galician", value: "gl" },
	{ label: "Guarani", value: "gn" },
	{ label: "Gujarati", value: "gu" },
	{ label: "Manx", value: "gv" },
	{ label: "Hausa", value: "ha" },
	{ label: "Hebrew", value: "he" },
	{ label: "Hindi", value: "hi" },
	{ label: "Hiri Motu", value: "ho" },
	{ label: "Croatian", value: "hr" },
	{ label: "Haitian", value: "ht" },
	{ label: "Haitian Creole", value: "ht" },
	{ label: "Hungarian", value: "hu" },
	{ label: "Armenian", value: "hy" },
	{ label: "Herero", value: "hz" },
	{ label: "Interlingua (International Auxiliary Language Association)", value: "ia" },
	{ label: "Indonesian", value: "id" },
	{ label: "Interlingue", value: "ie" },
	{ label: "Occidental", value: "ie" },
	{ label: "Igbo", value: "ig" },
	{ label: "Sichuan Yi", value: "ii" },
	{ label: "Nuosu", value: "ii" },
	{ label: "Inupiaq", value: "ik" },
	{ label: "Ido", value: "io" },
	{ label: "Icelandic", value: "is" },
	{ label: "Italian", value: "it" },
	{ label: "Inuktitut", value: "iu" },
	{ label: "Japanese", value: "ja" },
	{ label: "Javanese", value: "jv" },
	{ label: "Georgian", value: "ka" },
	{ label: "Kongo", value: "kg" },
	{ label: "Kikuyu", value: "ki" },
	{ label: "Gikuyu", value: "ki" },
	{ label: "Kuanyama", value: "kj" },
	{ label: "Kwanyama", value: "kj" },
	{ label: "Kazakh", value: "kk" },
	{ label: "Kalaallisut", value: "kl" },
	{ label: "Greenlandic", value: "kl" },
	{ label: "Central Khmer", value: "km" },
	{ label: "Kannada", value: "kn" },
	{ label: "Korean", value: "ko" },
	{ label: "Kanuri", value: "kr" },
	{ label: "Kashmiri", value: "ks" },
	{ label: "Kurdish", value: "ku" },
	{ label: "Komi", value: "kv" },
	{ label: "Cornish", value: "kw" },
	{ label: "Kirghiz", value: "ky" },
	{ label: "Kyrgyz", value: "ky" },
	{ label: "Latin", value: "la" },
	{ label: "Luxembourgish", value: "lb" },
	{ label: "Letzeburgesch", value: "lb" },
	{ label: "Ganda", value: "lg" },
	{ label: "Limburgan", value: "li" },
	{ label: "Limburger", value: "li" },
	{ label: "Limburgish", value: "li" },
	{ label: "Lingala", value: "ln" },
	{ label: "Lao", value: "lo" },
	{ label: "Lithuanian", value: "lt" },
	{ label: "Luba-Katanga", value: "lu" },
	{ label: "Latvian", value: "lv" },
	{ label: "Malagasy", value: "mg" },
	{ label: "Marshallese", value: "mh" },
	{ label: "Maori", value: "mi" },
	{ label: "Macedonian", value: "mk" },
	{ label: "Malayalam", value: "ml" },
	{ label: "Mongolian", value: "mn" },
	{ label: "Marathi", value: "mr" },
	{ label: "Malay", value: "ms" },
	{ label: "Maltese", value: "mt" },
	{ label: "Burmese", value: "my" },
	{ label: "Nauru", value: "na" },
	{ label: "Bokmål, Norwegian", value: "nb" },
	{ label: "Norwegian Bokmål", value: "nb" },
	{ label: "Ndebele, North", value: "nd" },
	{ label: "North Ndebele", value: "nd" },
	{ label: "Nepali", value: "ne" },
	{ label: "Ndonga", value: "ng" },
	{ label: "Dutch; Flemish", value: "nl" },
	{ label: "Norwegian Nynorsk", value: "nn" },
	{ label: "Nynorsk, Norwegian", value: "nn" },
	{ label: "Norwegian", value: "no" },
	{ label: "Ndebele, South", value: "nr" },
	{ label: "South Ndebele", value: "nr" },
	{ label: "Navajo; Navaho", value: "nv" },
	{ label: "Chichewa; Chewa", value: "ny" },
	{ label: "Nyanja", value: "ny" },
	{ label: "Occitan (post 1500)", value: "oc" },
	{ label: "Ojibwa", value: "oj" },
	{ label: "Oromo", value: "om" },
	{ label: "Oriya", value: "or" },
	{ label: "Ossetian", value: "os" },
	{ label: "Ossetic", value: "os" },
	{ label: "Panjabi", value: "pa" },
	{ label: "Punjabi", value: "pa" },
	{ label: "Pali", value: "pi" },
	{ label: "Polish", value: "pl" },
	{ label: "Pushto", value: "ps" },
	{ label: "Pashto", value: "ps" },
	{ label: "Portuguese", value: "pt" },
	{ label: "Quechua", value: "qu" },
	{ label: "Romansh", value: "rm" },
	{ label: "Rundi", value: "rn" },
	{ label: "Romanian", value: "ro" },
	{ label: "Moldavian", value: "ro" },
	{ label: "Moldovan", value: "ro" },
	{ label: "Russian", value: "ru" },
	{ label: "Kinyarwanda", value: "rw" },
	{ label: "Sanskrit", value: "sa" },
	{ label: "Sardinian", value: "sc" },
	{ label: "Sindhi", value: "sd" },
	{ label: "Northern Sami", value: "se" },
	{ label: "Sango", value: "sg" },
	{ label: "Sinhala", value: "si" },
	{ label: "Sinhalese", value: "si" },
	{ label: "Slovak", value: "sk" },
	{ label: "Slovenian", value: "sl" },
	{ label: "Samoan", value: "sm" },
	{ label: "Shona", value: "sn" },
	{ label: "Somali", value: "so" },
	{ label: "Albanian", value: "sq" },
	{ label: "Serbian", value: "sr" },
	{ label: "Swati", value: "ss" },
	{ label: "Sotho, Southern", value: "st" },
	{ label: "Sundanese", value: "su" },
	{ label: "Swedish", value: "sv" },
	{ label: "Swahili", value: "sw" },
	{ label: "Tamil", value: "ta" },
	{ label: "Telugu", value: "te" },
	{ label: "Tajik", value: "tg" },
	{ label: "Thai", value: "th" },
	{ label: "Tigrinya", value: "ti" },
	{ label: "Turkmen", value: "tk" },
	{ label: "Tagalog", value: "tl" },
	{ label: "Tswana", value: "tn" },
	{ label: "Tonga (Tonga Islands)", value: "to" },
	{ label: "Turkish", value: "tr" },
	{ label: "Tsonga", value: "ts" },
	{ label: "Tatar", value: "tt" },
	{ label: "Twi", value: "tw" },
	{ label: "Tahitian", value: "ty" },
	{ label: "Uighur; Uyghur", value: "ug" },
	{ label: "Ukrainian", value: "uk" },
	{ label: "Urdu", value: "ur" },
	{ label: "Uzbek", value: "uz" },
	{ label: "Venda", value: "ve" },
	{ label: "Vietnamese", value: "vi" },
	{ label: "Volapük", value: "vo" },
	{ label: "Walloon", value: "wa" },
	{ label: "Wolof", value: "wo" },
	{ label: "Xhosa", value: "xh" },
	{ label: "Yiddish", value: "yi" },
	{ label: "Yoruba", value: "yo" },
	{ label: "Zhuang", value: "za" },
	{ label: "Chuang", value: "za" },
	{ label: "Chinese", value: "zh" },
	{ label: "Zulu", value: "zu" },
];
