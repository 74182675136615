import React from "react";
import { FormikContextType } from "formik";

import firebase from "../../../../../firebase";
import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";
import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";
import { TagInput, Tag } from "../../../../../interfaces";

import * as Styled from "../ContentFormSections.styled";

const db = firebase.firestore();

export interface RelationsSectionProps {
	formikContext: FormikContextType<ContentFormContent>;
	clientId: string;
	tags: TagInput[];
	allTags: Tag[];
	relatedContents: TagInput[];
	allContents: Tag[];
	onRelatedContentAdd: (tag: TagInput) => void;
	onRelatedContentDelete: (index: number, deletedTag: TagInput) => void;
}

const RelationsSection: React.FC<RelationsSectionProps> = ({
	formikContext,
	clientId,
	tags,
	allTags = [],
	relatedContents,
	allContents = [],
	onRelatedContentAdd,
	onRelatedContentDelete,
}) => {
	const onTagAdd = (tag: TagInput, isNew: boolean) => {
		if (isNew) {
			const newTag: Tag = {
				id: tag.id as string,
				name: tag.name,
				clientId,
			};
			db.collection("tags")
				.doc("clientTags")
				.collection(clientId)
				.doc(newTag.id)
				.set(newTag);
		}
	};

	const {
		values: { id: thisContentId },
	} = formikContext;

	const otherTags: TagInput[] = [];
	allTags.forEach(tag => {
		if (!tags.some(t => t.id === tag.id)) {
			otherTags.push({ id: tag.id, name: tag.name });
		}
	});
	const otherRelatedContents: TagInput[] = [];
	allContents.forEach(content => {
		if (content.id !== thisContentId) {
			if (!relatedContents.some(c => c.id === content.id)) {
				otherRelatedContents.push({ id: content.id, name: content.name });
			}
		}
	});

	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>Associated Content</PageTitle>
			</Styled.SectionHeader>
			<Well message="With Associated Content, you can add related content that will appear below your stream. You can also add a Tag for sermon series, class courses, or specific locations.">
				<Form.Field
					component={Form.Input.Tags}
					label="Related Content"
					id="relatedContentIds"
					name="relatedContentIds"
					suggestions={otherRelatedContents}
					onAddition={onRelatedContentAdd}
					onDelete={onRelatedContentDelete}
					allowNew={false}
					placeholder="Add Related Content"
				/>
				<Form.Field
					component={Form.Input.Tags}
					label="Tags"
					id="tags"
					name="tags"
					suggestions={otherTags}
					onAddition={onTagAdd}
					placeholder="Add Tag"
				/>
			</Well>
		</>
	);
};

export default RelationsSection;
