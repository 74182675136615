import { Channel } from "../../../interfaces";

export const channelFormInitialValues = {
	name: "",
	key: "",
	description: "",
	headerImage: "",
	logoImage: "",
	homeUrl: "",
	thumbImage: "",
	defaultVideo: {
		enabled: false,
		videoProvider: "",
		src: "",
	},
	showAt: null,
	hideAt: null,
	publish: 0,
	isPrivate: false,
	links: [],
	brushfireEventIds: [],
	chatWidget: {
		enabled: false,
		provider: "zendesk",
	},
};
