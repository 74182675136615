export interface Link {
	text: string;
	icon: string;
	url?: Url;
	html?: string;
	codeProvider?: AvailableCodeProviders;
	code?: string;
	enabled?: boolean;
}

export interface Url {
	url: string;
	target: Target;
}

export enum AvailableCodeProviders {
	planningCenterGiving,
	tithely,
	onlineGiving,
	paperlessTransactions,
	givelify,
	chat,
}

export type Target = "newTab" | "sameTab" | "iframeModal" | "sideBar";
