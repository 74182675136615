import "firebase/auth";
import { FormikContextType } from "formik";
import React, { useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../components/Form";
import Error from "../../components/Form/Error";
import { TypedUseSelectorHook } from "../../redux";
import api from "../../services/api";
import { PageContainer, PageTitle, Well } from "./UI";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const eventTitleSchema = Yup.object().shape({
	email: Yup.string()
		.required("Email Required")
		.email("Email must be in the correct format."),
});

interface PasswordResetData {
	email: string;
}

interface Error {
	code: string;
	message: string;
}

const alertContents = (message: string): any => {
	if (message.includes("https")) {
		return <span dangerouslySetInnerHTML={{ __html: message }} />;
	} else {
		return <span>{message}</span>;
	}
};

const PasswordReset: React.FC = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [resetText, setResetText] = useState<string>();
	const [resetHeaderText, setResetHeaderText] = useState<string>();
	const [errorText, setErrorText] = useState<string>();

	const onSubmit = (values: PasswordResetData) => {
		setIsSubmitting(true);
		setErrorText(undefined);
		requestReset(values);
	};

	const user = useTypedSelector(store => store.user);

	const requestReset = (values: PasswordResetData) => {
		api.requestPasswordReset(values.email)
			.then(data => {
				setResetText(data.data.data);
				setResetHeaderText(data.data.status === "error" ? "Sorry!" : "Reset Requested");
			})
			.catch(err => {
				setResetText("An error occured while trying to reset your password. Please contact support.");
				setResetHeaderText("Sorry!");
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<PageContainer className="no-sidebar" size="sm" htmlPageTitle="Password Reset">
			{resetText ? (
				<>
					<PageTitle>{resetHeaderText ? resetHeaderText : "Reset Requested"}</PageTitle>
					<Well>
						<p>{resetText}</p>
					</Well>
				</>
			) : (
				<>
					<PageTitle>Forgot your password?</PageTitle>
					<Well>
						<Form initialValues={{ email: "" }} validationSchema={eventTitleSchema} onSubmit={onSubmit}>
							{({ formikContext }: { formikContext: FormikContextType<PasswordResetData> }) => {
								const { errors, touched } = formikContext;
								return (
									<fieldset disabled={isSubmitting}>
										{user.errorMessage && (
											<Alert variant="danger">{alertContents(user.errorMessage)}</Alert>
										)}
										<Form.Field label="Email" id="email" name="email" />

										<Button type="submit" variant="primary" className="btn-block">
											{isSubmitting && (
												<Spinner animation="border" className="form-button-spinner" />
											)}{" "}
											{isSubmitting ? "Sending Reset Link..." : "Submit"}
										</Button>
										{errorText && <Error>{errorText}</Error>}
									</fieldset>
								);
							}}
						</Form>
					</Well>
				</>
			)}
		</PageContainer>
	);
};

export default PasswordReset;
