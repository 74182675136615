import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { VideoSectionProps } from "../";
import { Well } from "../../../";
import Form from "../../../../../../components/Form";
import { ccLanguages } from "../../../../../../constants";
import { ContentType, Content } from "../../../../../../interfaces";
import { timeZoneData } from "../../../../../../utils/timezones";
import * as Styled from "../../ContentFormSections.styled";
import { getSrcDescription, getSrcLabel } from "../getLabels";
import { videoProviderData } from "../videoData";
import { VideoPreview } from "../../../../../../components/Video/VideoPreview";

export interface SimliveVideoSectionProps extends VideoSectionProps { }

const SimliveVideoSection: React.FC<SimliveVideoSectionProps> = ({
	formikContext,
	isEmbed,
	timeZoneName,
	clientId,
}) => {
	const { values: formValues } = formikContext;
	const { contentType, videoProvider } = formValues;

	const [useCustomTimeZone, setUseCustomTimeZone] = useState(!!formValues.timeZoneName);
	const [previewShouldUpdate, setPreviewShouldUpdate] = useState<boolean>(true);

	const label = timeZoneName ? `(${timeZoneName})` : "";

	return (
		<>
			<Styled.SectionHeader>SIMULATED LIVE VIDEO</Styled.SectionHeader>
			<Well
				message="Release content on-demand, live, or simulated live. Simulated live content auto-plays and keeps viewers in sync throughout."
				learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238545802253-How-to-Add-Videos-in-Brushfire-Online"
			>
				<VideoPreview
					content={formValues as Content}
					formikContext={formikContext}
					previewShouldUpdate={previewShouldUpdate}
				/>

				<Form.Field
					component={Form.Input.Select}
					label="Video Provider *"
					id="videoProvider"
					name="videoProvider"
					data={
						contentType
							? videoProviderData[ContentType[contentType]]
							: videoProviderData[ContentType[ContentType.simulatedLive]]
					}
					isSearchable={false}
				/>

				<Form.Field
					component={isEmbed ? Form.Input.TextArea : Form.Input.Text}
					label={`${getSrcLabel(videoProvider, contentType || ContentType.live)} *`}
					id="src"
					name="src"
					description={getSrcDescription(videoProvider, contentType || ContentType.live)}
					onBlur={() => setPreviewShouldUpdate(true)}
					onInput={() => {
						setPreviewShouldUpdate(false);
					}}
				/>

				<Row>
					<Col>
						<Form.Field
							component={Form.Input.DateTime}
							label={`Start Date & Time ${label} *`}
							id="startsAt"
							name="startsAt"
							displayTimeZone={timeZoneName}
						/>
					</Col>
					<Col>
						<Form.Field
							label="Video Duration (in minutes) *"
							id="duration"
							name="duration"
							type="number"
							min={0}
						/>
					</Col>
				</Row>
				<Form.Field
					label="Video Pre-Play (in minutes) *"
					id="prePlayDuration"
					name="prePlayDuration"
					type="number"
					min={0}
					description="Auto-play video before Start Date & Time"
				/>

				<Form.Field
					component={Form.Input.Uploader}
					clientId={clientId}
					label="Thumbnail Image *"
					id="thumbImage"
					name="thumbImage"
					helperText="Recommended size 1920px by 1080px at 144 DPI and a file size less than 1MB."
				/>

				<Form.Field
					component={Form.Input.Checkbox}
					label="Display live counter to visitors"
					id="showLiveStats"
					name="showLiveStats"
				/>

				<Form.Field
					component={Form.Input.Checkbox}
					label="Disable the countdown timer"
					id="disableCountdown"
					name="disableCountdown"
				/>

				<Form.Input.Checkbox
					label="Use custom time zone"
					id="customTimeZone"
					name="customTimeZone"
					value={useCustomTimeZone}
					onChecked={() => setUseCustomTimeZone(true)}
					onUnchecked={() => {
						setUseCustomTimeZone(false);
						const newFormValues = { ...formikContext.values };
						delete newFormValues.timeZoneName;
						formikContext.setValues(newFormValues);
					}}
				/>
				{useCustomTimeZone && (
					<Form.Field
						component={Form.Input.Select}
						label="Timezone *"
						id="timeZoneName"
						name="timeZoneName"
						data={timeZoneData}
						isSearchable={true}
					/>
				)}

				{((videoProvider as unknown) as string) === "youtube" && (
					<Form.Field
						component={Form.Input.Select}
						label="CC Translation"
						id="ccLanguage"
						name="ccLanguage"
						data={ccLanguages}
						isClearable
						description="Select a language to enable closed captioning by default"
					/>
				)}
			</Well>
		</>
	);
};

export default SimliveVideoSection;
