/* will change once mockups are through */
const colours = {
	primary: "#428DFF",
	secondary: "#D4E1F4",
	tertiary: "yellow",
	light: "#fff",
	dark: "#110641",
	gray: "#f2f2f2",
	grayOne: "#C8C8C8",
	grayTwo: "#777",
	grayThree: "#333",
	error: "#e71d36",
};

const defaultSpacer = "1rem";

const getSpacerSize = (multiplier: number) => {
	/* ensuring that all multiplier values are multiples of 0.25 */
	const roundedMultiplier = (Math.round(multiplier * 4) / 4).toFixed(2);
	return `calc(${roundedMultiplier} * ${defaultSpacer})`;
};

const defaultPreviewSize = "260px";
const defaultPagePadding = getSpacerSize(3.75);
const mobilePagePadding = getSpacerSize(1.5);

const fontSizeBase = "1rem";

const fontWeights = {
	bold: `
	font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
    `,
	normal: "font-family: ff-basic-gothic-pro, sans-serif;",
	light: `
	font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 300;
    `,
};

const fontSizes = {
	massive: `calc(${fontSizeBase} * 2.25)`,
	xl: `calc(${fontSizeBase} * 1.75)`,
	l: `calc(${fontSizeBase} * 1.5)`,
	m: `calc(${fontSizeBase} * 1.25)`,
	s: `calc(${fontSizeBase} * 0.875)`,
	xs: `calc(${fontSizeBase} * 0.75)`,
	tiny: `calc(${fontSizeBase} * 0.65)`,
	default: fontSizeBase,
};

const sidebarWidth = "200px";

export {
	colours,
	fontSizes,
	sidebarWidth,
	fontWeights,
	getSpacerSize,
	defaultPagePadding,
	mobilePagePadding,
	defaultPreviewSize,
};
