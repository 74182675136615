import { PayloadAction } from "typesafe-actions";
import {
	DELETE_CHAT_MESSAGE,
	FLAG_CHAT_MESSAGE,
	HIDE_MODAL_ALERT,
	IDeleteChat,
	ILikeChat,
	ILimitMessages,
	IPinChat,
	ISetChat,
	IShowModalAlert,
	IUserStatusChanged,
	LIKE_CHAT_MESSAGE,
	PIN_CHAT_MESSAGE,
	SET_CHAT_DATA,
	SET_LIMIT_MESSAGES,
	SET_SCROLLED_UP,
	SHOW_MODAL_ALERT,
	USER_STATUS_CHANGED,
} from "./types";

export function setChatData(data: ISetChat): PayloadAction<string, ISetChat> {
	return {
		type: SET_CHAT_DATA,
		payload: data,
	};
}

export function likeMessage(data: ILikeChat): PayloadAction<string, ILikeChat> {
	return {
		type: LIKE_CHAT_MESSAGE,
		payload: data,
	};
}

export function flagMessage(data: ILikeChat): PayloadAction<string, ILikeChat> {
	return {
		type: FLAG_CHAT_MESSAGE,
		payload: data,
	};
}

export function deleteMessage(data: IDeleteChat): PayloadAction<string, IDeleteChat> {
	return {
		type: DELETE_CHAT_MESSAGE,
		payload: data,
	};
}

export function pinMessage(data: IPinChat): PayloadAction<string, IPinChat> {
	return {
		type: PIN_CHAT_MESSAGE,
		payload: data,
	};
}

export function setLimitMessages(data: ILimitMessages): PayloadAction<string, ILimitMessages> {
	return {
		type: SET_LIMIT_MESSAGES,
		payload: data,
	};
}

export function setScrolledUp(data: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_SCROLLED_UP,
		payload: data,
	};
}

export function userStatusChanged(data: IUserStatusChanged): PayloadAction<string, IUserStatusChanged> {
	return {
		type: USER_STATUS_CHANGED,
		payload: data,
	};
}

export function showModalAlert(data: IShowModalAlert): PayloadAction<string, IShowModalAlert> {
	return {
		type: SHOW_MODAL_ALERT,
		payload: data,
	};
}

export function hideModalAlert(clearModalData: boolean = false): PayloadAction<string, boolean> {
	return {
		type: HIDE_MODAL_ALERT,
		payload: clearModalData,
	};
}
