import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { emit } from "./Chat.service";

const style = {
	ChatEmail: styled.input``,
	ChatPassword: styled.input``,
	ChatConfirmPassword: styled.input``,
	ChatSendButton: styled.button``,
	ChatMsgBlock: styled.div``,
	ChatMsgRow: styled.div``,
};

export default class ChatRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordMismatch: false,
			registerFailed: false,
		};
		this.onAction = this.props.onAction;
	}

	matchPassword(e) {
		const p1 = this.passwordRef.value;
		const p2 = this.confirmPasswordRef.value;
		console.log(`password checky ${p1} <-> ${p2}`);
		if (p2.length <= 0 || p1.length <= 0 || p1 === p2) {
			if (this.state.passwordMismatch === true) {
				this.setState({ passwordMismatch: false });
			}
			return;
		}
		if (p1 !== p2) {
			console.log(`password no matchy!!!!`);
			this.setState({ passwordMismatch: true });
		}
	}

	onClick() {
		if (this.state.passwordMismatch) return;
		const email = this.emailRef.value;
		const password = this.passwordRef.value;
		this.passwordRef.value = "";
		this.confirmPasswordRef.value = "";
		const data = { email, password, status: { success: true, code: "", message: "" } };
		emit("CHAT_REGISTERUSER", data).then(() => {
			if (data.status.success === false) {
				// there is an error
				this.registerStatus = data.status;
				this.setState({ registerFailed: true });
			} else {
				this.setState({ registerFailed: false });
				// success
				this.onAction("success");
			}
		});
	}

	render() {
		const emailProps = {
			type: "text",
			ref: this.setupEmailRef.bind(this),
		};
		const passwordProps = {
			type: "password",
			onKeyUp: this.matchPassword.bind(this),
			ref: this.setupPasswordRef.bind(this),
		};
		const confirmPasswordProps = {
			type: "password",
			onKeyUp: e => {
				this.matchPassword.bind(this)(e);
				if (e.key === "Enter") {
					this.onClick();
				}
			},
			ref: this.setupConfirmPasswordRef.bind(this),
		};
		const clickProps = {
			onClick: this.onClick.bind(this),
		};

		return (
			<React.Fragment>
				{this.renderMessageIfAny.bind(this)()}
				<style.ChatEmail {...emailProps} />
				<style.ChatPassword {...passwordProps} />
				<style.ChatConfirmPassword {...confirmPasswordProps} />
				<style.ChatSendButton {...clickProps}>send</style.ChatSendButton>
			</React.Fragment>
		);
	}

	renderMessageIfAny() {
		const message = [];
		if (this.state.passwordMismatch) {
			message.push("Your passwords are mismatched.");
		}
		if (this.state.registerFailed) {
			message.push(this.registerStatus.message);
		}
		return (
			<style.ChatMsgBlock>
				{message.map((m, i) => (
					<style.ChatMsgRow key={i}>{m}</style.ChatMsgRow>
				))}
			</style.ChatMsgBlock>
		);
	}

	setupEmailRef(element) {
		this.emailRef = element;
	}

	setupPasswordRef(element) {
		this.passwordRef = element;
	}

	setupConfirmPasswordRef(element) {
		this.confirmPasswordRef = element;
	}
}

ChatRegister.propTypes = {
	onAction: PropTypes.func.isRequired,
};
