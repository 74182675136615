import {
	AUTH_STATUS_CHANGED,
	FRONT_END_LOGIN_ERROR,
	FRONT_END_LOGOUT_USER,
	FRONT_END_SET_USER,
	LOGIN_ERROR,
	LOGOUT_USER,
	SET_APP_USER,
	SET_IS_LOGGING_OUT,
	SHOW_LOGIN_MODAL,
	SHOW_LOGOUT_MODAL,
	UserActionTypes,
	UserState,
} from "./types";

const initialState: UserState = {
	user: undefined,
	isLoading: true,
	errorMessage: "",
	appUser: undefined,
	isLoadingAppUser: true,
	frontEndUser: undefined,
	frontEndIsLoading: true,
	frontEndErrorMessage: "",
	showLoginModal: false,
	showLogoutModal: false,
	isLoggingOut: false,
};

type firebaseUserPayload = { user: firebase.User | undefined };

export function userReducer(state = initialState, action: UserActionTypes): UserState {
	switch (action.type) {
		case AUTH_STATUS_CHANGED:
			return Object.assign({}, state, {
				user: (action.payload as { user: firebase.User | undefined }).user,
				isLoading: false,
			});
		case SET_APP_USER:
			return Object.assign({}, state, {
				appUser: action.payload,
				isLoading: false,
				isLoadingAppUser: false,
			});
		case LOGOUT_USER:
			return Object.assign({}, state, {
				user: undefined,
				appUser: undefined,
				errorMessage: action.payload,
			});
		case LOGIN_ERROR:
			return Object.assign({}, state, {
				errorMessage: action.payload,
				isLoading: false,
			});
		case FRONT_END_SET_USER:
			return Object.assign({}, state, {
				frontEndUser: action.payload as firebase.User | undefined,
				frontEndIsLoading: false,
			});
		case FRONT_END_LOGOUT_USER:
			return Object.assign({}, state, {
				frontEndUser: undefined,
				frontEndErrorMessage: action.payload,
			});
		case FRONT_END_LOGIN_ERROR:
			return Object.assign({}, state, {
				errorMessage: action.payload,
				frontEndErrorMessage: false,
			});
		case SHOW_LOGIN_MODAL:
			return Object.assign({}, state, {
				showLoginModal: action.payload,
			});
		case SHOW_LOGOUT_MODAL:
			return Object.assign({}, state, {
				showLogoutModal: action.payload,
			});
		case SET_IS_LOGGING_OUT:
			return Object.assign({}, state, {
				isLoggingOut: action.payload,
			});
		default:
			return state;
	}
}
