import React from "react";
import { Formik, FormikErrors, FormikContextType, FormikHelpers, FormikValues } from "formik";
import { isFunction, isEmpty } from "lodash";

import Description from "./Description";
import Field from "./Field";
import Input from "./Input";
import Summary from "./Summary";
import Color from "./Color";
import Prompt from "./Prompt";
// needed for Yup to work with old browsers
import "core-js/es/promise";
import "core-js/es/set";
import "core-js/es/map";

export type OnSubmit<T = object> =
	| ((values: any) => void)
	| ((values: any, formikActions: FormikHelpers<FormikValues>) => void);

export interface FormProps {
	children: React.ReactNode;
	handleBlur?: (e: any) => void;
	initialValues: { [key: string]: any };
	isInitialValid?: boolean;
	onSubmit?: OnSubmit;
	validate?: (values: any, props?: any) => void | object | Promise<FormikErrors<any>>;
	validateOnBlur?: boolean;
	validateOnChange?: boolean;
	validateOnMount?: boolean;
	validationSchema?: any;
	style?: React.CSSProperties;
}

export interface FormState {
	showSummary: boolean;
}

export interface FormContext<T = any> {
	formikContext: FormikContextType<T>;
	extraData: FormState;
}

class Form extends React.Component<FormProps, FormState> {
	static Description = Description;
	static Field = Field;
	static Input = Input;
	static Summary = Summary;
	static Color = Color;
	static Prompt = Prompt;

	state = {
		showSummary: false,
	};

	render() {
		const {
			children,
			handleBlur,
			initialValues,
			isInitialValid = false,
			onSubmit,
			validate,
			validateOnBlur = true,
			validateOnChange = true,
			validateOnMount = false,
			validationSchema,
			...otherProps
		} = this.props;
		const { showSummary } = this.state;
		return (
			<Formik
				initialValues={initialValues}
				isInitialValid={isInitialValid || (validationSchema && validationSchema.isValid(initialValues))}
				onSubmit={onSubmit || ((e: any) => e.preventDefault())}
				validate={validate}
				validateOnBlur={validateOnBlur}
				validateOnChange={validateOnChange}
				validateOnMount={validateOnMount}
				validationSchema={validationSchema}
				enableReinitialize={true}
			>
				{(formikContext: FormikContextType<any>) => {
					// only show the summary if we tried to submit and there are errors to display
					if (formikContext.isSubmitting && !showSummary && !isEmpty(formikContext.errors)) {
						this.setState({ showSummary: true });
					}
					if (!formikContext.isSubmitting && isEmpty(formikContext.errors) && showSummary) {
						this.setState({ showSummary: false });
					}
					const extraData: FormState = {
						showSummary,
					};

					formikContext.validationSchema = validationSchema;
					return (
						<form
							{...otherProps}
							noValidate={true}
							onSubmit={formikContext.handleSubmit}
							onKeyDown={(event: React.KeyboardEvent<HTMLFormElement>) => {
								// preventing submitting a form when pressing enter into any of the children fields
								if (event.which === 13) {
									event.preventDefault();
								}
							}}
						>
							{// allows us to give formikContext to the parent component if they use render props
							isFunction(children) ? children({ formikContext, extraData }) : children}
						</form>
					);
				}}
			</Formik>
		);
	}
}

export default Form;
