import Checkbox, { CheckboxProps } from "./Checkbox";
import Radio, { RadioProps } from "./Radio";
import DateTime, { DateTimePickerProps } from "./DateTime";
import ReactionPicker, { ReactionPickerProps } from "./ReactionPicker";
import Select, { SelectProps } from "./Select";
import Text, { TextInputProps } from "./Text";
import TextArea, { TextAreaProps } from "./TextArea";
import Uploader, { IUploaderProps } from "./Uploader";
import Profile, { ProfileProps } from "./Profile";
import Tags, { TagsInputProps } from "./Tags";

export interface InputComponents {
	Checkbox: React.FC<CheckboxProps>;
	Radio: React.FC<RadioProps>;
	DateTime: React.FC<DateTimePickerProps>;
	ReactionPicker: React.FC<ReactionPickerProps>;
	Select: React.FC<SelectProps>;
	Tags: React.FC<TagsInputProps>;
	Text: React.FC<TextInputProps>;
	TextArea: React.FC<TextAreaProps>;
	Uploader: React.FC<IUploaderProps>;
	Profile: React.FC<ProfileProps>;
}

const Input: InputComponents = {
	Checkbox,
	Radio,
	DateTime,
	ReactionPicker,
	Select,
	Tags,
	Text,
	TextArea,
	Uploader,
	Profile,
};

export default Input;
