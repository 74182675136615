import { User } from "../../interfaces";

export interface UserState {
	user?: firebase.User;
	isLoading: boolean;
	errorMessage: string;
	appUser?: User;
	isLoadingAppUser?: boolean;
	frontEndUser?: firebase.User;
	frontEndIsLoading: boolean;
	frontEndErrorMessage: string;
	showLoginModal?: boolean;
	showLogoutModal?: boolean;
	isLoggingOut: boolean;
}

export const TRACK_AUTH_STATUS: string = "TRACK_AUTH_STATUS";
export const SET_APP_USER: string = "SET_APP_USER";
export const AUTH_STATUS_CHANGED: string = "AUTH_STATUS_CHANGED";
export const LOGOUT_USER: string = "LOGOUT_USER";
export const LOGIN_SUCCESS: string = "LOGIN_SUCCESS";
export const LOGIN_ERROR: string = "LOGIN_ERROR";
export const SHOW_LOGIN_MODAL: string = "SHOW_LOGIN_MODAL";
export const SHOW_LOGOUT_MODAL: string = "SHOW_LOGOUT_MODAL";
export const SET_IS_LOGGING_OUT: string = "SET_IS_LOGGING_OUT";

//Front End Uers
export const FRONT_END_SET_USER: string = "FRONT_END_SET_USER";
export const FRONT_END_AUTH_STATUS_CHANGED: string = "FRONT_END_AUTH_STATUS_CHANGED";
export const FRONT_END_LOGOUT_USER: string = "FRONT_END_LOGOUT_USER";
export const FRONT_END_LOGIN_SUCCESS: string = "FRONT_END_LOGIN_SUCCESS";
export const FRONT_END_LOGIN_ERROR: string = "FRONT_END_LOGIN_ERROR";

export interface ITRACK_AUTH_STATUS {
	type: typeof TRACK_AUTH_STATUS;
	payload?: undefined;
}

export interface ISET_APP_USER {
	type: typeof SET_APP_USER;
	payload?: User | undefined;
}

interface IAUTH_STATUS_CHANGED {
	type: typeof AUTH_STATUS_CHANGED;
	payload: { user: firebase.User | null };
}

interface ILOGIN_SUCCESS {
	type: typeof AUTH_STATUS_CHANGED;
	payload: firebase.User | null;
}

interface ILOGOUT_USER {
	type: typeof LOGOUT_USER;
	payload: string;
}

interface ILOGIN_ERROR {
	type: typeof LOGIN_ERROR;
	payload: string;
}

export interface IFRONT_END_SET_USER {
	type: typeof FRONT_END_SET_USER;
	payload?: User | undefined;
}

interface IFRONT_END_AUTH_STATUS_CHANGED {
	type: typeof FRONT_END_AUTH_STATUS_CHANGED;
	payload: { user: firebase.User | null };
}

interface IFRONT_END_LOGIN_SUCCESS {
	type: typeof FRONT_END_AUTH_STATUS_CHANGED;
	payload: firebase.User | null;
}

interface IFRONT_END_LOGOUT_USER {
	type: typeof FRONT_END_LOGOUT_USER;
	payload: string;
}

interface IFRONT_END_LOGIN_ERROR {
	type: typeof FRONT_END_LOGIN_ERROR;
	payload: string;
}

interface ISHOW_LOGIN_MODAL {
	type: typeof SHOW_LOGIN_MODAL;
	payload: boolean;
}

interface ISHOW_LOGOUT_MODAL {
	type: typeof SHOW_LOGOUT_MODAL;
	payload: boolean;
}

interface ISET_IS_LOGGING_OUT {
	type: typeof SET_IS_LOGGING_OUT;
	payload: boolean;
}

export type AUTH_STATUS_CHANGED_ACTION = IAUTH_STATUS_CHANGED;

export type LOGIN_SUCCESS_ACTION = ILOGIN_SUCCESS;

export type FRONT_END_LOGIN_SUCCESS_ACTION = IFRONT_END_LOGIN_SUCCESS;

export type UserActionTypes =
	| IAUTH_STATUS_CHANGED
	| ILOGOUT_USER
	| ILOGIN_ERROR
	| ITRACK_AUTH_STATUS
	| IFRONT_END_AUTH_STATUS_CHANGED
	| IFRONT_END_LOGIN_SUCCESS
	| IFRONT_END_LOGIN_SUCCESS
	| IFRONT_END_LOGOUT_USER
	| IFRONT_END_LOGIN_ERROR
	| ISHOW_LOGIN_MODAL
	| ISHOW_LOGOUT_MODAL
	| ISET_IS_LOGGING_OUT;
