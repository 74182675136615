import React from "react";
import styled from "styled-components";
import { darkTheme, lightTheme } from "../Chat/styles";

interface IconLinkProps {
	url: string;
	icon: string;
	weight?: string;
	onClick?: () => void;
}

const Icon = styled.a`
	color: ${({ theme }) => theme.wellTextColor};
`;

const IconChatMode = styled.a`
	color: ${({ theme }) => theme.wellTextColor};
`;

export const IconLink: React.FC<IconLinkProps> = ({ url, icon, weight = "fab", onClick }) => {
	return (
		<Icon href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
			<i className={`${weight} fa-${icon}`} />
		</Icon>
	);
};

export const IconLinkChat: React.FC<IconLinkProps> = ({ url, icon, weight }) => {
	return (
		<div>
			<IconChatMode href={url} target="_blank" rel="noopener noreferrer">
				<i className={`${weight} fa-${icon}`} />
			</IconChatMode>
		</div>
	);
};
