import moment from "moment-timezone";

export const timeZoneData = moment.tz
	.names()
	.map((timeZone: any) => ({
		value: timeZone,
		label: `(${moment()
			.tz(timeZone)
			.format("Z")}) ${timeZone}`,
		offset: parseInt(
			moment()
				.tz(timeZone)
				.format("Z")
		),
	}))
	.sort((a, b) => {
		let aValue = a.value;
		let bValue = b.value;
		let aOffset = a.offset;
		let bOffset = b.offset;

		if (aOffset === bOffset) {
			return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
		} else {
			return aOffset < bOffset ? -1 : 1;
		}
	});

export const guessTimezone = () => moment.tz.guess();

/**
 * Gives you a time zone abbreviation for a given time.
 * @param time required. A ISO8601 formatted string of the time you need an abbreviation for.
 * @param timezone optional. The timezone name from the IANA Time Zone Database (or, using "timeZoneData".)
 * 	If nothing is passed in, guesses the time zone from the browser.
 */
export const timeZoneAbbreviation = (time: string, timezone?: string): string => {
	if (timezone) {
		return moment.tz(time, timezone).zoneAbbr();
	} else {
		return moment.tz(time, guessTimezone()).zoneAbbr();
	}
};
