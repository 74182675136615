import firebase from "firebase";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { REACTION_SHARD_COUNT } from "../../constants";
import firebaseApp from "../../firebase";
import { Reaction, ReactionIcon } from "../../interfaces";
import { style } from "./styles";

interface ReactionIconsProps {
	addMyReactions: Function;
	id: string;
	uid: string;
	icons: { [key: string]: boolean };
	muted: boolean;
	setMuted: (muted: boolean) => void;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

const db = firebaseApp.firestore();

const ReactionIcons: React.FC<ReactionIconsProps> = ({ addMyReactions, id, icons, uid, muted, setMuted }) => {
	const [batches, setBatches] = useState<Reaction[]>([]);

	const onClick = (icon: ReactionIcon) => {
		const newReaction: Reaction = {
			id: uuidv4(),
			uid,
			icon,
			timestamp: new Date(),
			relativeTime: 0,
			x: Math.random(),
			y: Math.random(),
			display: false,
		};
		addMyReactions([newReaction]);
		const newBatches = batches.concat([newReaction]);
		setBatches(newBatches);
		if (timeout) {
			clearTimeout(timeout);
		}
		const batch = db.batch();
		timeout = setTimeout(() => {
			//Change last batched one to be the displayed one
			const reactionCollectionRef = db
				.collection("reactions")
				.doc("contents")
				.collection(id);
			const reactionShardCollectionRef = db
				.collection("reactions")
				.doc("counts")
				.collection(id);
			let counts: { [k: string]: number } = {};

			const reaction = newBatches[newBatches.length - 1];
			const newReactionRef = reactionCollectionRef.doc(reaction.id);
			batch.set(newReactionRef, { ...reaction, display: true });

			newBatches.forEach(reaction => {
				counts[reaction.icon.toString()] = counts[reaction.icon.toString()]
					? counts[reaction.icon.toString()] + 1
					: 1;
			});
			//Update shards
			Object.keys(counts).forEach(key => {
				const increment = firebase.firestore.FieldValue.increment(counts[key]);
				const i = Math.round(Math.random() * (REACTION_SHARD_COUNT - 1));
				batch.update(reactionShardCollectionRef.doc(`${key}_${i}`), { count: increment });
			});
			batch.commit();
			setBatches([]);
		}, 1000);
	};

	return (
		<style.ReactionsIcons className="reaction-icons">
			{Object.values(ReactionIcon).map(icon => {
				if (icons[icon]) {
					return (
						<div className="reaction-icon" onClick={() => onClick(icon)} key={icon}>
							<img src={`/content/img/reactions/bfo-${ReactionIcon[icon]}.svg`} />
						</div>
					);
				}
			})}
			<style.ReactionMuteIcon>
				<div className="reaction-icon relative" onClick={() => setMuted(!muted)}>
					<img
						src={`/content/img/reactions/bfo-${muted ? "muted" : "unmuted"}.svg`}
						alt={muted ? "Tap to unmute Reactions" : "Tap to mute Reactions"}
						title={muted ? "Tap to unmute Reactions" : "Tap to mute Reactions"}
					/>
				</div>
			</style.ReactionMuteIcon>
		</style.ReactionsIcons>
	);
};

export default ReactionIcons;
