import React, { useContext, useState } from "react";
import { FieldArray, FormikContextType, FieldArrayRenderProps } from "formik";
import { isEmpty, cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { Header } from "./Header";
import { ResponseSection } from "./Responses";
import { Well } from "../../";
import Form from "../../../../../components/Form";
import Editor from "../../../../../components/Form/Editor";
import { Interaction, InteractionUrgency } from "../../../../../interfaces";
import { InteractionButton } from "../../../Contents/HostManager/InteractionManager/InteractionButton";

import * as LinksStyled from "../Links/LinksSection.styled";

interface ContextInterface {
	name: string;
	disabled: boolean;
	formikContext: any;
	timeZone?: string;
	onInteractionDelete: (id: string) => void;
}

export interface InteractionsSectionProps {
	name: string;
	disabled: boolean;
	formikContext: FormikContextType<any>;
	timeZone?: string;
	wellMessage: string;
	learnMoreLink?: string;
	onInteractionDelete: (id: string) => void;
}

interface InteractionCardProps {
	arrayHelpers?: FieldArrayRenderProps;
	interactionIndex: number;
	interaction: Interaction;
	length: number;
	formikContext: FormikContextType<any>;
	name: string;
	isHostManager?: boolean;
}

interface UrgencyList {
	value: InteractionUrgency;
	label: string;
}

export const Context = React.createContext<ContextInterface>({
	name: "",
	disabled: false,
	formikContext: {},
	timeZone: "",
	onInteractionDelete: (id: string) => null,
});

export const InteractionCard: React.FC<InteractionCardProps> = ({
	arrayHelpers,
	interactionIndex,
	interaction,
	length,
	formikContext,
	name,
	isHostManager,
}) => {
	const { onInteractionDelete } = useContext(Context);
	const indexLength = length - 1;

	const urgencyValues: UrgencyList[] = [
		{ value: "notify", label: "Notify - Least intrusive, only updates the icon" },
		{ value: "focus", label: "Focus - Default, switches to this panel" },
		{ value: "modal", label: "Modal - Most intrusive, displays popup" },
	];

	const interactionName = `${name}[${interactionIndex}]`;

	return (
		<LinksStyled.CustomWell style={{ margin: "0px 0px 10px 0px", borderRadius: "0px 0px 8px 8px" }}>
			<LinksStyled.CardContents className={isHostManager ? "host-manager-interactions" : ""}>
				<Form.Field
					label="Title"
					id={`${interactionName}.interactionTitle`}
					name={`${interactionName}.interactionTitle`}
				/>
				<Form.Field
					component={Editor}
					label="Question"
					id={`${interactionName}.interactionText`}
					name={`${interactionName}.interactionText`}
					darkMode={true}
					hasHtmlMenu
					disabled={!!interaction.showAt}
					initialValue={interaction.interactionText}
					init={{ toolbar: "bold italic link" }}
					onEditorChange={(content: string) => {
						formikContext.setFieldValue(`${interactionName}.interactionText`, content);
					}}
					className="darkmode-field field"
				/>
				<Form.Field
					component={Form.Input.Select}
					label="Notification"
					id={`${interactionName}.urgency`}
					name={`${interactionName}.urgency`}
					data={urgencyValues}
				/>
				<ResponseSection
					interactionIndex={interactionIndex}
					name={name}
					formikContext={formikContext}
					isHostManager={isHostManager}
				/>
			</LinksStyled.CardContents>
			{arrayHelpers && (
				<LinksStyled.ActionsContainer>
					{interactionIndex > 0 && (
						<LinksStyled.ActionButton
							type="button"
							onClick={() => arrayHelpers.move(interactionIndex, interactionIndex - 1)}
						>
							<i className="fal fa-arrow-up" />
						</LinksStyled.ActionButton>
					)}
					{interactionIndex < indexLength && (
						<LinksStyled.ActionButton
							type="button"
							onClick={() => arrayHelpers.move(interactionIndex, interactionIndex + 1)}
						>
							<i className="fal fa-arrow-down" />
						</LinksStyled.ActionButton>
					)}
					<LinksStyled.ActionButton
						type="button"
						onClick={() => {
							const newInteraction = cloneDeep(interaction);
							newInteraction.id = uuidv4();
							newInteraction.interactionTitle = `Copy of ${interaction.interactionTitle}`;
							newInteraction.showAt = null;
							arrayHelpers.push(newInteraction);
						}}
					>
						<i className="fal fa-copy" />
					</LinksStyled.ActionButton>
					<LinksStyled.ActionButton
						type="button"
						onClick={() => {
							arrayHelpers.remove(interactionIndex);
							onInteractionDelete(interaction.id);
						}}
					>
						<i className="fal fa-trash" />
					</LinksStyled.ActionButton>
				</LinksStyled.ActionsContainer>
			)}
		</LinksStyled.CustomWell>
	);
};

const InteractionsSection: React.FC<InteractionsSectionProps> = ({
	name,
	disabled,
	formikContext,
	timeZone,
	wellMessage,
	learnMoreLink,
	onInteractionDelete,
}) => {
	const [openInteraction, setOpenInteraction] = useState<string>();
	return (
		<Context.Provider value={{ name, disabled, formikContext, timeZone, onInteractionDelete }}>
			<FieldArray
				name={name}
				render={arrayHelpers => {
					return (
						<>
							<Header
								addInteractionClick={() => {
									const newId = uuidv4();
									arrayHelpers.push({
										id: newId,
										interactionTitle: "",
										interactionText: "",
										showAt: null,
										hideAt: null,
										urgency: "focus",
										responses: [],
									});
									setOpenInteraction(newId);
								}}
							/>
							<Well>
								<p>
									{wellMessage}{" "}
									{learnMoreLink && (
										<>
											<br />
											<span>
												<a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
													Learn more <i className="fal fa-long-arrow-right" />
												</a>
											</span>
										</>
									)}
								</p>
							</Well>
							{!isEmpty(formikContext.values[name]) &&
								formikContext.values[name].map((interaction: any, interactionIndex: number) => {
									return (
										<>
											<InteractionButton
												key={interaction.id}
												interaction={interaction}
												active={interaction.id === openInteraction}
												onClick={() => {
													if (openInteraction === interaction.id) {
														setOpenInteraction(undefined);
													} else {
														setOpenInteraction(interaction.id);
													}
												}}
												isDashboard={true}
											/>
											{interaction.id === openInteraction && (
												<fieldset disabled={!!interaction.showAt}>
													<InteractionCard
														key={interactionIndex}
														arrayHelpers={arrayHelpers}
														interaction={interaction}
														interactionIndex={interactionIndex}
														length={formikContext.values[name].length}
														formikContext={formikContext}
														name={name}
													/>
												</fieldset>
											)}
										</>
									);
								})}
						</>
					);
				}}
			/>
		</Context.Provider>
	);
};

export default InteractionsSection;
