import { FormikContextType } from "formik";

import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";

import LiveSection, { LiveVideoSectionProps } from "./Live";
import SimliveSection, { SimliveVideoSectionProps } from "./Simlive";
import OnDemandSection, { OnDemandVideoSectionProps } from "./OnDemand";

export interface VideoSectionProps {
	formikContext: FormikContextType<ContentFormContent>;
	isEmbed: boolean;
	timeZoneName?: string;
	clientId?: string;
}

export interface VideoTypes {
	Live: React.FC<LiveVideoSectionProps>;
	Simlive: React.FC<SimliveVideoSectionProps>;
	OnDemand: React.FC<OnDemandVideoSectionProps>;
}

const Video: VideoTypes = { Live: LiveSection, Simlive: SimliveSection, OnDemand: OnDemandSection };

export default Video;
