import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";
import { fetchContent } from "../../../services/dataHelpers";
import {
	CardContainer,
	ThumbImage,
	CardInfoBlock,
	CardInfoContainer,
	CardTitleWrapper,
	CardTitle,
	IconContainer,
	IconWrapper,
	CardSubTitle,
	ChannelList,
	ChannelIconWrapper,
	ChannelPill,
	TagsList,
	TagsIconWrapper,
	TagPill,
	GradientOverlay,
	NoTagsWrapper,
} from "./ContentCard.styled";
import { Content, Tag, ChannelData } from "../../../interfaces";
import LiveBadge from "../../LiveBadge";
import userHasRole from "../../../services/userHasRole";
import { TypedUseSelectorHook } from "../../../redux";

interface EditableCardProps {
	content: Content;
	clientKey: string;
	channels: ChannelData[];
	tags?: Tag[];
	onDelete?: (id: string, name: string) => void;
	onVideoEnd?: () => void;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const EditableCard: React.FC<EditableCardProps> = ({ content, clientKey, channels, tags, onDelete, onVideoEnd }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);

	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;

	const openNewWindow = () => {
		let url = "//" + window.location.host;
		if (channels[0]) {
			const channelKey = channels[0].key;
			window.open(`${url}/${clientKey}/${channelKey}/${content.key}`, "_blank");
		}
	};

	const openSameWindow = (params: string) => {
		let url = "//" + window.location.host;
		window.open(`${url}/${params}`, "_self");
	};

	const onClick = (type: string) => {
		switch (type) {
			case "visit":
				openNewWindow();
				break;

			case "host":
				openSameWindow(`dashboard/${content.clientId}/contents/${content.id}/host`);
				break;

			case "analytics":
				openSameWindow(`dashboard/${content.clientId}/contents/${content.id}/analytics`);
				break;

			case "edit":
				fetchContent(content.id, dispatch);
				history.push(`contents/${content.id}`);
				break;

			case "copy":
				fetchContent(content.id, dispatch);
				history.push(`contents/copy/${content.id}`);
				break;

			case "delete":
				if (onDelete) onDelete(content.id, content.name);
				break;

			default:
				return null;
		}
	};

	const popover = (
		<Popover id={`channel-popover-${content.id}`} className={`channel-popover ${isAdmin ? "" : "host"}`}>
			<Popover.Content>
				{isAdmin && (
					<>
						<div
							className="popover-item"
							onClick={() => onClick("edit")}
							title={
								!content.closesAt || moment(content.closesAt).isAfter(moment())
									? `Edit ${content.name}`
									: "This content has ended and cannot be edited now"
							}
						>
							<div className="icon-wrapper">
								<i className="fal fa-pencil" />
							</div>
							<p>Edit</p>
						</div>
						<div className="popover-item" onClick={() => onClick("copy")}>
							<div className="icon-wrapper">
								<i className="fal fa-copy" />
							</div>
							<p>Copy</p>
						</div>
						<div className="popover-item" onClick={() => onClick("delete")}>
							<div className="icon-wrapper">
								<i className="fal fa-trash" />
							</div>
							<p>Delete</p>
						</div>
						<div className="border" />
					</>
				)}

				<div className="popover-item" onClick={() => onClick("analytics")}>
					<div className="icon-wrapper">
						<i className="fal fa-analytics" />
					</div>
					<p>Analytics</p>
				</div>
				<div className="popover-item" onClick={() => onClick("host")}>
					<div className="icon-wrapper">
						<i className="fal fa-comments" />
					</div>
					<p>Host Panel</p>
				</div>
				<div className="popover-item" onClick={() => onClick("visit")}>
					<div className="icon-wrapper">
						<i className="fal fa-globe" />
					</div>
					<p>Preview</p>
				</div>
			</Popover.Content>
		</Popover>
	);

	return (
		<CardContainer>
			<ThumbImage
				style={{
					backgroundImage: `url('${content.thumbImage}')`,
				}}
			/>
			<CardInfoBlock>
				<CardInfoContainer className="card-info-container">
					<CardTitleWrapper>
						<LiveBadge contentData={content} showLiveNumber={false} onVideoEnd={onVideoEnd} />
						<CardTitle>
							{content.name}
							{content.isPrivate && (
								<span className="content-private-pill">
									<i className="fal fa-lock" />
									Private
								</span>
							)}
						</CardTitle>
						{content.startsAt && (
							<CardSubTitle>{moment(content.startsAt).format("ddd, MMM D, h:mm A")}</CardSubTitle>
						)}
					</CardTitleWrapper>
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
						<div style={{ width: "calc(100% - 35px)", position: "relative" }}>
							<ChannelList>
								<ChannelIconWrapper>
									<i className="fa fa-list" />
								</ChannelIconWrapper>
								{channels.map(channel => (
									<ChannelPill key={channel.id}>{channel.name}</ChannelPill>
								))}
							</ChannelList>
							<TagsList>
								<TagsIconWrapper>
									<i className="fa fa-tags" />
								</TagsIconWrapper>
								{tags && tags.length > 0 ? (
									tags.map(tag => <TagPill key={tag.id}>{tag.name}</TagPill>)
								) : (
									<NoTagsWrapper>No Tags</NoTagsWrapper>
								)}
							</TagsList>
							<GradientOverlay />
						</div>
						<IconContainer>
							<OverlayTrigger
								key={`channel-popover-${content.id}`}
								trigger="click"
								placement="left"
								overlay={popover}
								rootClose
							>
								<IconWrapper>
									<i className="fal fa-ellipsis-v" />
								</IconWrapper>
							</OverlayTrigger>
						</IconContainer>
					</div>
				</CardInfoContainer>
			</CardInfoBlock>
		</CardContainer>
	);
};

export default EditableCard;
