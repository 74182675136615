/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import { showLogoutModal } from "../../redux/User/actions";
import isLightOrDark from "../../utils/isLightOrDark";
import FooterNavigation from "../Footer/FooterNavigation";
import { AttendeeDataContext } from "../Paywall";

interface DrawerProps {
	toggleDrawer: () => void;
	isOpen: boolean;
	renderLinks: () => (JSX.Element | undefined | null)[];
	clientKey?: string;
	channelKey?: string;
	contentKey?: string;
	theme: Theme;
	logoUrl: string;
	logoImage: string;
}
interface LinkStyleProps {
	theme: Theme;
}

const LinkButton = styled.a<LinkStyleProps>`
	/* display: flex;
	flex-direction: row;
	align-items: center; */
	cursor: pointer;
	color: ${({ theme }) => theme.navLinkColor};

	i,
	span {
		color: ${({ theme }) => theme.navLinkColor};
		text-decoration: none;
		cursor: pointer;
		transition: all 0.25s;
	}

	&:hover {
		color: ${({ theme }) => theme.navLinkHoverColor};
		text-decoration: none;
		cursor: pointer;

		i,
		span {
			color: ${({ theme }) => theme.navLinkHoverColor};
			text-decoration: none;
			cursor: pointer;
		}
	}
`;

const useTypedSelector: TypedUseSelectorHook = useSelector;

const Drawer: React.FC<DrawerProps> = ({
	toggleDrawer,
	isOpen,
	renderLinks,
	theme,
	clientKey,
	channelKey,
	contentKey,
	logoUrl,
	logoImage,
}) => {
	const powerByLink = `http://www.brushfire.com/features/online-experience?utm_source=${clientKey ||
		""}&utm_campaign=${channelKey || ""}&utm_content=${contentKey || ""}`;
	const isLightBackground = isLightOrDark(theme.navBackgroundColor) === "light";
	const [isVisible, setIsVisible] = useState(isOpen);
	const color = isLightBackground ? "#202020" : "white";

	const { attendeeData, paywallEnabled, logout } = useContext(AttendeeDataContext);

	const user = useTypedSelector(store => store.user);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isVisible !== isOpen) {
			setIsVisible(isOpen);
			document.body.style.overflow = isOpen ? "hidden" : "scroll";
		}
	}, [isOpen]);

	const openBrushfire = () => {
		window.open(powerByLink);
	};

	return (
		<div
			id="Drawer"
			className={isVisible ? "drawer-show" : ""}
			style={{
				backgroundColor: theme.navBackgroundColor,
			}}
		>
			<div className="drawer-header">
				<a className="logo" href={logoUrl}>
					<img alt="Logo" src={logoImage} />
				</a>
				<LinkButton onClick={toggleDrawer}>
					<i className="fal fa-times drawer-menu-icon" />
				</LinkButton>
			</div>
			<div className="drawer-menu">{renderLinks()}</div>
			<div className="drawer-footer footer-container">
				{paywallEnabled && attendeeData && (
					<>
						{" "}
						<a
							href="#"
							style={{ color }}
							className={`footer-attendee-number${user.frontEndUser?.displayName ? " m-0" : ""}`}
							onClick={evt => {
								evt.preventDefault();
								evt.stopPropagation();
								if (window.confirm("Do you want to switch Attendee Numbers?")) {
									logout();
								}
							}}
						>
							Attendee {attendeeData.AttendeeNumber}
						</a>
					</>
				)}
				{user.frontEndUser?.displayName && (
					<>
						<span
							style={{
								color,
							}}
							className="footer-attendee-number"
						>
							{user.frontEndUser.displayName} -
							<a
								href="#"
								style={{
									color,
								}}
								onClick={evt => {
									evt.preventDefault();
									evt.stopPropagation();
									dispatch(showLogoutModal(true));
									toggleDrawer();
								}}
							>
								{" "}
								Sign Out
							</a>
						</span>
					</>
				)}
				<FooterNavigation isLightBackground={isLightBackground} />
				<div className="drawer-footer-inner-wrapper footer-image-container" onClick={() => openBrushfire()}>
					<h6
						style={{
							color: isLightBackground ? "#202020" : "white",
						}}
					>
						A Product of
					</h6>
					<img
						alt="Brushfire Logo"
						src={`/content/img/bf-logo-full-${isLightBackground ? "dark" : "light"}-1400.png`}
					/>
				</div>
			</div>
		</div>
	);
};

export default Drawer;
