import styled from "styled-components";
import { lighten, darken } from "polished";
import { Dismiss } from "./InteractionResponse";

import Field from "../Form/Field";

interface IsPreview {
	isPreview: boolean;
}

interface DismissProps extends IsPreview {
	padded?: boolean;
}

interface PanelContainerProps extends IsPreview {
	isModal: boolean;
	isFullContent: boolean;
}

export const PanelContainer = styled.div<PanelContainerProps>`
	width: ${({ isModal }) => (isModal ? "100%" : "auto")};
	padding: ${({ isPreview, isModal, isFullContent }) => {
		if (isFullContent) {
			return "0px";
		} else if (isPreview) {
			return "10px";
		} else {
			if (isModal) {
				return "20px";
			} else {
				return "0px";
			}
		}
	}};
	height: 100%;
	border-radius: 4px;
	color: #272323;
	background-color: ${({ theme, isPreview }) => {
		if (isPreview) {
			return "#fff";
		} else {
			return theme.wellBackgroundColor;
		}
	}};
`;

interface RealContentProps {
	responsesHeight: number;
	isModal: boolean;
}

export const RealContent = styled.div<RealContentProps>`
	height: ${({ responsesHeight }) => `calc(100% - ${responsesHeight}px)`} !important;
	overflow: auto;
	padding: ${({ isModal }) => (isModal ? "16px 10px" : "19px 15px")};
	display: flex;
	flex-direction: column;
	text-align: center;
	&::before,
	&::after {
		content: "";
		flex: 1;
	}
`;

export const ContentContainer = styled.div<IsPreview>`
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	#content {
		p {
			margin-bottom: 1rem;
			font-size: 24px;
			color: ${({ theme, isPreview }) => {
				if (isPreview) {
					return "#272323";
				} else {
					return theme.wellTextColor;
				}
			}};
		}
	}
`;

export const InnerContentContainer = styled.div<any>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

export const InteractionContainer = styled.div<IsPreview>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: ${({ theme, isPreview }) => {
		if (isPreview) {
			return "#fff";
		} else {
			return theme.wellBackgroundColor;
		}
	}};
`;

export const HR = styled.hr<IsPreview>`
	border-top: 1px solid
		${({ theme, isPreview }) => {
			if (isPreview) {
				return "#AFAFAF";
			} else {
				return theme.wellBackgroundColor;
			}
		}};
	border-radius: 2px;
	width: 100%;
	margin: 5px 0;
`;

export const ResponseButton = styled.div<IsPreview>`
	width: 100%;
	padding: 5px;
	margin-bottom: 5px;
	text-align: center;
	background-color: ${({ theme, isPreview }) => {
		if (isPreview) {
			return "#ededed";
		} else {
			return theme.buttonBackgroundColor;
		}
	}};
	color: ${({ theme, isPreview }) => {
		if (isPreview) {
			return "#6d7071";
		} else {
			return theme.buttonTextColor;
		}
	}};
	border-radius: 4px;
	cursor: pointer;

	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: ${({ theme, isPreview }) => {
			if (isPreview) {
				return darken(0.1, "#ededed");
			} else {
				return theme.buttonHoverBackgroundColor;
			}
		}};
		color: ${({ theme, isPreview }) => {
			if (isPreview) {
				return darken(0.1, "#6d7071");
			} else {
				return theme.buttonTextColor;
			}
		}};
	}
`;

export const DismissContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;

export const DismissLink = styled.div<DismissProps>`
	font-size: 12px;
	cursor: pointer;
	text-decoration: underline;
	text-align: center;
	padding-bottom: ${props => (props.padded ? "10px" : "0")};
	color: ${({ theme, isPreview }) => {
		if (isPreview) {
			return "#6D7071";
		} else {
			return theme.wellTextColor;
		}
	}};
`;

export const ResponseModalContainer = styled.div`
	height: calc(100% - 80px);
	max-height: 600px;
`;

export const FormField = styled(Field)`
	label {
		color: ${(props: IsPreview) => {
			if (props.isPreview) {
				return "#272323";
			} else {
				return "#e7e7e7";
			}
		}};
	}
`;

export const ShowAnswerContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ShowAnswerFooterContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;

	small {
		font-size: 12px;
		color: #6d7071;
	}
`;

export const CustomContentFrame = styled.iframe`
	height: 100%;
	width: 100%;
	border: 0;
`;
