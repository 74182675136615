import { Editor as TinyMCE, IAllProps } from "@tinymce/tinymce-react";
import React from "react";
import * as config from "./initialConfig";
import { sanitizeValue } from "../Field/Field";

interface MenuOptions {
	title: string;
	items: string;
}
type Menus = "file" | "edit" | "view" | "insert" | "format" | "tools" | "table" | "help";
type MenuType = Partial<Record<Menus, MenuOptions>>;
type EventHandler<A> = (a: A, editor?: any) => any;

export interface EditorProps extends IAllProps {
	disabled?: boolean;
	init?: Record<string, any>;
	darkMode?: any;
	hasImageMenu?: boolean;
	hasHtmlMenu?: boolean;
	initialValue?: string;
	innerRef?: any;
	menu?: MenuType;
	onEditorChange: EventHandler<any>;
}

const handleLinkClick = (event: MouseEvent) => {
	// @ts-ignore
	const target = event.target?.target;
	// @ts-ignore
	const href = event.target?.href;
	if (target && href) {
		window.open(href, target);
	}
};

class Editor extends React.Component<EditorProps> {
	componentDidUpdate() {
		if ((window as any).tinymce && (window as any).tinymce.activeEditor) {
			const editorBody = (window as any).tinymce.activeEditor.getBody();
			if (editorBody) {
				if (this.props.disabled) {
					editorBody.style.backgroundColor = "#4a525a";
					editorBody.style.opacity = "0.5";
					editorBody.setAttribute("contenteditable", false);
				} else if (!this.props.darkMode) {
					editorBody.style.backgroundColor = "#fff";
					editorBody.style.opacity = "1";
					editorBody.setAttribute("contenteditable", true);
				}
			}
		}
	}

	render() {
		const { init, darkMode, hasImageMenu, hasHtmlMenu, ...otherProps } = this.props;

		//@ts-ignore
		config.defaultConfig.document_base_url = `${window.location.protocol}//${window.location.hostname}${
			window.location.port !== "" ? `:${window.location.port}` : ""
		}/`;
		if (darkMode) {
			config.defaultConfig.skin = "oxide-dark";
		}

		if (hasImageMenu) {
			config.defaultConfig.toolbar = !config.defaultConfig.toolbar.includes("image")
				? config.defaultConfig.toolbar + " image"
				: config.defaultConfig.toolbar;
		}

		if (hasHtmlMenu) {
			config.defaultConfig.toolbar = !config.defaultConfig.toolbar.includes("code")
				? config.defaultConfig.toolbar + " code"
				: config.defaultConfig.toolbar;
		}

		const newInit = Object.assign({}, config.defaultConfig, { menubar: false, statusbar: false }, init);
		const contentStyle = `
			body {
				${darkMode ? "color: white;" : ""}
				padding-left: 15px;
				padding-right: 15px;
				margin: 0;
			}
			a {
				cursor: pointer !important;
			}
		`;
		return (
			<TinyMCE
				tinymceScriptSrc={"https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"}
				init={{ ...newInit, content_style: contentStyle }}
				{...otherProps}
				onClick={e => handleLinkClick(e)}
				onEditorChange={content => {
					if (typeof this.props.onEditorChange === "function") {
						const sanitizedContent = content
							.replace(/&lsquo;|&rsquo;/g, '"')
							.replace(/&ldquo;|&rdquo;/g, '"');
						this.props.onEditorChange(sanitizedContent);
					}
				}}
			/>
		);
	}
}

export default Editor;
