import { Interaction, Link, SocialLink, Panels } from "./";

export interface Content {
	id: string;
	clientId: string;
	channelId: string;
	channelIds: string[];
	name: string;
	key: string;
	description?: string;
	opensAt?: Date;
	closesAt?: Date;
	startsAt?: Date;
	duration?: number;
	thumbImage: string;
	src: string;
	chat: Chat;
	bible: Bible;
	notes: Notes;
	schedule: Schedule;
	share?: Share;
	links: Link[];
	socialLinks: SocialLink[];
	videoProvider: VideoProvider;
	versionCode?: string;
	interactions?: Interaction[];
	contentType?: ContentType;
	showLiveStats?: boolean;
	reactions: ContentReactions;
	convertToOnDemand?: Conversion;
	hasConvertedToOnDemand?: boolean;
	convertedToOnDemandId?: string;
	convertedFromId?: string;
	tags: string[];
	createdAt?: string;
	category?: string;
	relatedContentIds: string[];
	originalAirDate?: Date;
	timeZoneName?: string;
	videoAccessRuleEnabled: boolean;
	rules: Rule[];
	useChatProcessor?: boolean;
	disableCountdown?: boolean;
	ccLanguage?: string;
	showAt?: Date;
	hideAt?: Date;
	isPrivate?: boolean;
	analyticsProcessed: AnalyticsProcessed | undefined;
	initialSelectedPanel?: Panels;
	runAnalytics?: boolean;
}

interface EnabledFlag {
	enabled: boolean;
}

export interface ContentReactions extends EnabledFlag {
	icons: { [key: string]: boolean };
}
export interface Chat extends EnabledFlag {
	startsAt?: Date;
	endsAt?: Date;
	enableAnonymous?: boolean;
	enablePreview?: boolean;
	enableDirectMessaging?: boolean;
}

export interface Bible extends EnabledFlag {
	url?: string;
}

interface Notes extends EnabledFlag {
	defaultContent?: string;
}

interface Schedule extends EnabledFlag {}

interface Share extends EnabledFlag {
	defaultMessage?: string;
}

export interface Conversion extends EnabledFlag {
	name?: string;
	description?: string;
	channelIds: string[];
}

export interface Rule {
	attendeeTypeIds: string[];
	fields: Field;
}

export interface Field {
	[fieldOptionId: string]: boolean;
}

//Don't reorder these, ever!
export enum VideoProvider {
	youtube,
	vimeo,
	facebook,
	livestream,
	livingAsOne,
	livingAsOneSingle,
	other,
}

export enum ContentType {
	onDemand = 1,
	simulatedLive = 2,
	live = 3,
}

export enum AnalyticsProcessed {
	unprocessed = 0,
	processing = 1,
	processed = 2,
}
