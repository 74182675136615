import { firestore } from "firebase";
import React from "react";
import firebaseApp from "../../../../firebase";

interface RequestButtonProps {
	onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
	timestamp?: firestore.Timestamp;
	uid?: string;
	title: string;
	message?: string;
	status?: boolean;
	key: string;
	active?: boolean;
	count?: number;
	contentId: string;
	icon?: string;
}

const db = firebaseApp.firestore();

const RequestButton: React.FC<RequestButtonProps> = ({
	active,
	count,
	timestamp,
	onClick,
	uid,
	title,
	message,
	status,
	contentId,
	icon,
	...props
}) => {
	//const [isOnline, setIsOnline] = useState(false);
	// useEffect(() => {
	// 	if (uid) {
	// 		db.collection("analytics")
	// 			.doc("users")
	// 			.collection(contentId)
	// 			.doc(`ua_${uid}`)
	// 			.onSnapshot(doc => {
	// 				if (doc.exists) {
	// 					const data = doc.data() as AnalyticsUser;
	// 					setIsOnline(data.isOnline);
	// 				}
	// 			});
	// 	}
	// }, [uid]);
	return (
		<span className={`request-button ${active ? "active" : ""}`} {...props} onClick={onClick}>
			{uid && (
				<div className="chat-image">
					<span>
						{title === "Unknown User" ? (
							<i className="fal fa-user"></i>
						) : (
							<>
								{title
									.split(" ")
									.slice(0, 2)
									.map((word: any) => word.substring(0, 1).toUpperCase())}
							</>
						)}
					</span>
					<div
						className="request-image"
						style={{
							backgroundImage: `url("https://media.online.brushfire.com/profile_images/${uid}/profile.jpg")`,
						}}
					></div>
				</div>
			)}
			{title === "Host Chat" && (
				<div className="chat-image">
					<span>
						<i className="fal fa-comments"></i>
					</span>
				</div>
			)}
			{/* {count !== undefined && count > 0 && <div className="count-bubble">{count}</div>} */}
			<div className="request-button-info">
				<h2>{title}</h2>
				{timestamp && (
					<p>
						{timestamp.toDate().toLocaleDateString()} {timestamp.toDate().toLocaleTimeString()}
					</p>
				)}
			</div>

			{count !== undefined && count > 0 && (
				<div
					className={`status-indicator active`}
					title={`${count} new chat message${count === 1 ? "" : "s"}`}
				></div>
			)}
			{icon && <i className={icon}></i>}
		</span>
	);
};

export default RequestButton;
