import styled from "styled-components";
import { Button } from "react-bootstrap";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	@media screen and (max-width: 992px) {
		height: auto;
		width: 100%;
	}
`;

export const LiveContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SentButton = styled(Button)`
	cursor: auto;
`;

export const ResponsesContainer = styled.div`
	background-color: #4a525a;
	margin: 10px 0;
	padding: 10px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
`;

export const ResponseRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const PreviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	flex: 1;
`;

export const PreviewTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const PreviewReset = styled.small`
	&:hover {
		cursor: pointer;
	}
`;

export const EditButton = styled(Button)`
	width: 100%;
	padding: 0px;
	margin: 10px 2px 0 0;
`;

export const DeleteButton = styled(Button)`
	width: 100%;
	padding: 0px;
	margin: 10px 0 0 2px;
`;

export const SaveButton = styled(Button)`
	display: block;
	position: relative;
	bottom: 20px;
	left: 0;
	z-index: 2;
	@media screen and (max-width: 992px) {
		bottom: 30px;
	}
`;

export const InnerFormContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	margin-bottom: 1em;
	@media screen and (min-width: 992px) {
		padding-right: 1em;
	}
`;
