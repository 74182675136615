import React from "react";
import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";

import * as Styled from "../ContentFormSections.styled";

export interface ScheduleSectionProps {
	isEnabled: boolean;
}

const ScheduleSection: React.FC<ScheduleSectionProps> = ({ isEnabled }) => {
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>SCHEDULE</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="schedule.enabled"
					name="schedule.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			<Well>
				<p>Show viewers a schedule of upcoming live or simulated live content within this channel.</p>
			</Well>
		</>
	);
};

export default ScheduleSection;
