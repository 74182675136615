import { createGlobalStyle } from "styled-components";
import { Theme } from "../interfaces";

const black = "#24272b";
const darkerGray = "#393e45";
const white = "#FFFFFF";

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
	html, body {
		
		background-image: url('${({ theme }) => {
			return theme.backgroundImage;
		}}');
		background-color: ${({ theme }) => theme.backgroundColor};
		background-size: cover;
		background-attachment: fixed;
		color: ${({ theme }) => theme.textColor};
		background-position: top center;
	}
	.btn {
		background-color: ${({ theme }) => theme.buttonBackgroundColor};
		border-color: ${({ theme }) => theme.buttonBackgroundColor};
		color: ${({ theme }) => theme.buttonTextColor};
		

		&:active {
			background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
			border-color: ${({ theme }) => theme.buttonHoverBackgroundColor};

		}
		&:hover {
			background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
			border-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
		}
		&.btn-primary{
			background-color: ${({ theme }) => theme.buttonBackgroundColor};
			border-color: ${({ theme }) => theme.buttonBackgroundColor};
			color: ${({ theme }) => theme.buttonTextColor};
			&:active {
				background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
				border-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
	
			}
			&:hover {
				background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
				border-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
			}
		}
	}

	.modal-content{
		background-color: ${white};
		color: ${black};
		.modal-header{
			border-bottom: none;
			padding-bottom: 0;
			.close{
				text-shadow: none;
				color: ${black};
			}
			h4{
				font-size: 1.25em;
			}
		}
		.btn {
			background-color: ${black};
			border-color: ${black};
			color: ${({ theme }) => theme.buttonTextColor};
			
	
			&:active {
				background-color: ${darkerGray};
				border-color: ${darkerGray};
	
			}
			&:hover {
				background-color: ${darkerGray};
				border-color: ${darkerGray};
			}
			&.btn-primary{
				background-color: ${black};
				border-color: ${black};
				color: ${white};
				&:active {
					background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
					border-color: ${darkerGray};
		
				}
				&:hover {
					background-color: ${darkerGray};
					border-color: ${darkerGray};
				}
			}
		}
	}

	.chat-container { 
		.btn {
			background-color: inherit;
			border-color: inherit;
			color: inherit;

			&:active {
				background-color: inherit;
				border-color: inherit;

			}
			&:hover {
				background-color: inherit;
				border-color: inherit;
			}
		}
	}
`;

export default GlobalStyle;
