import React from "react";

import Form from "../../../../../components/Form";
import { AvailableCodeProviders } from "../../../../../interfaces";

interface CodeProviderProps {
	name: string;
	label?: string;
	codeProvider?: AvailableCodeProviders;
	isContentForm?: boolean;
}

export const CodeProvider: React.FC<CodeProviderProps> = ({ name, label, codeProvider, isContentForm = false }) => {
	const givingProviderLabels: Record<string, string> = {
		planningCenterGiving: "ChurchCenter Domain",
		tithely: "Church ID",
		onlineGiving: "Church Code",
		paperlessTransactions: "Subdomain",
		givelify: "URL",
		chat: "URL",
	};

	const providers = [
		{ value: "planningCenterGiving", label: "Planning Center Giving" },
		{ value: "tithely", label: "Tithe.ly" },
		{ value: "onlineGiving", label: "Online Giving" },
		{ value: "paperlessTransactions", label: "Paperless Transactions" },
		{ value: "givelify", label: "Givelify" },
	];
	if (isContentForm) {
		providers.push({ value: "chat", label: "Chat" });
	}

	return (
		<>
			<Form.Field
				component={Form.Input.Select}
				label="Provider *"
				id={`${name}.codeProvider`}
				name={`${name}.codeProvider`}
				data={providers}
				isSearchable={false}
			/>

			{codeProvider &&
				//@ts-ignore
				codeProvider !== "chat" && (
					<Form.Field
						label={codeProvider ? `${givingProviderLabels[codeProvider]} *` : "Key *"}
						id={`${name}.code`}
						name={`${name}.code`}
					/>
				)}
		</>
	);
};
