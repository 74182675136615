//This is the firebase project used only for the front end auth - It should never be used in the dashboard
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

import "firebase/auth";

var firebaseConfig = {
	apiKey: "AIzaSyB9hJd_PfnDFFILVFLCbjmi6dRoOUZYCU4",
	authDomain: "brushfire-online-auth.firebaseapp.com",
	databaseURL: "https://brushfire-online-auth.firebaseio.com",
	projectId: "brushfire-online-auth",
	storageBucket: "brushfire-online-auth.appspot.com",
	messagingSenderId: "1050937765229",
	appId: "1:1050937765229:web:3ea7e476407c3df54abe64",
	measurementId: "G-WTCSDSFWD1",
};

const fb = firebase.initializeApp(firebaseConfig, "firebaseAuth");

export default fb;
