import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogicMiddleware } from "redux-logic";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { StateType } from "typesafe-actions";
import { analyticsReducer } from "./Analytics/reducers";
import { channelReducer } from "./Channel/reducers";
import { chatReducer } from "./Chat/reducers";
import { dashboardReducer } from "./Dashboard/reducers";
import arrLogic from "./logic";
import { userReducer } from "./User/reducers";

const createRootReducer = (history: History) =>
	combineReducers({
		analytics: analyticsReducer,
		channel: channelReducer,
		chat: chatReducer,
		dashboard: dashboardReducer,
		router: connectRouter(history),
		user: userReducer,
	});

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["dashboard", "user"],
};

const persistedReducer = (history: History) => persistReducer(persistConfig, createRootReducer(history));

const logicDeps = {};

const logicMiddleware = createLogicMiddleware(arrLogic, logicDeps);

export type AppState = ReturnType<typeof persistedReducer>;

export type RootState = StateType<ReturnType<typeof persistedReducer>>;
export interface TypedUseSelectorHook {
	<TSelected>(
		selector: (state: RootState) => TSelected,
		equalityFn?: (left: TSelected, right: TSelected) => boolean
	): TSelected;
}

export default function configureStore(history: History) {
	const middlewares = [logicMiddleware, routerMiddleware(history)];
	const middleWareEnhancer = applyMiddleware(...middlewares);

	const store = createStore(persistedReducer(history), composeWithDevTools(middleWareEnhancer));
	let persistor = persistStore(store);

	return { store, persistor };
}
