import { useState, useLayoutEffect } from "react";

function getStyle(el: any, styleName: any) {
	return getComputedStyle(el)[styleName];
}

function getOffset(el: any) {
	if (!el) {
		return { top: 0, bottom: 0 };
	}
	const rect = el.getBoundingClientRect();
	const doc = el.ownerDocument;
	if (!doc) throw new Error("Unexpectedly missing <document>.");
	const win = doc.defaultView || doc.parentWindow;

	const winX =
		win.pageXOffset !== undefined
			? win.pageXOffset
			: (doc.documentElement || doc.body.parentNode || doc.body).scrollBottom;
	const winY =
		win.pageYOffset !== undefined
			? win.pageYOffset
			: (doc.documentElement || doc.body.parentNode || doc.body).scrollTop;

	return {
		top: rect.top + winX,
		bottom: rect.bottom + winY,
	};
}

function getPosition(el: any) {
	if (!el) {
		return { top: 0, bottom: 0 };
	}
	let offset = getOffset(el);
	let parentOffset = { top: 0, bottom: 0 };
	const marginTop = parseInt(getStyle(el, "marginTop")) || 0;
	const marginBottom = parseInt(getStyle(el, "marginBottom")) || 0;

	if (getStyle(el, "position") === "fixed") {
		offset = el.getBoundingClientRect();
	} else {
		const doc = el.ownerDocument;

		let offsetParent = el.offsetParent || doc.documentElement;

		while (offsetParent && (offsetParent === doc.body || offsetParent === doc.documentElement)) {
			offsetParent = offsetParent.parentNode;
		}

		if (offsetParent && offsetParent !== el && offsetParent.nodeType === 1) {
			parentOffset = getOffset(offsetParent);
			parentOffset.top += parseInt(getStyle(offsetParent, "borderTopWidth")) || 0;
			parentOffset.bottom += parseInt(getStyle(offsetParent, "borderBottomWidth")) || 0;
		}
	}

	return {
		top: offset.top - parentOffset.top - marginTop,
		bottom: offset.bottom - parentOffset.bottom - marginBottom,
	};
}

function usePosition(ref: any) {
	let { top, bottom } = getPosition(ref.current);
	let [ElementPosition, setElementPosition] = useState({
		top: top,
		bottom: bottom,
	});

	function handleChangePosition() {
		if (ref && ref.current) {
			setElementPosition(getPosition(ref.current));
		}
	}

	useLayoutEffect(() => {
		handleChangePosition();
		window.addEventListener("resize", handleChangePosition);

		return () => {
			window.removeEventListener("resize", handleChangePosition);
		};
	}, [ref]);

	return ElementPosition;
}

export default usePosition;
