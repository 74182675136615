import { ChatMessage, UserStatus } from "../../interfaces";

export interface ChatState {
	messages: { [key: string]: ChatMessage };
	chatId?: string;
	latestMessage?: firebase.firestore.Timestamp;
	limitMessages: boolean;
	newMessages: boolean;
	scrolledUp: boolean;
	userStatus: UserStatus;
	showModal: boolean;
	modalData: ModalData;
}

export interface ModalData {
	title: string;
	message: string;
	closeButtonText: string;
}

export const SET_CHAT_DATA: string = "SET_CHAT_DATA";
export const LIKE_CHAT_MESSAGE: string = "LIKE_CHAT_MESSAGE";
export const FLAG_CHAT_MESSAGE: string = "FLAG_CHAT_MESSAGE";
export const DELETE_CHAT_MESSAGE: string = "DELETE_CHAT_MESSAGE";
export const SET_LIMIT_MESSAGES: string = "SET_LIMIT_MESSAGES";
export const SET_SCROLLED_UP: string = "SET_SCROLLED_UP";
export const PIN_CHAT_MESSAGE: string = "PIN_CHAT_MESSAGE";
export const USER_STATUS_CHANGED: string = "USER_STATUS_CHANGED";
export const SHOW_MODAL_ALERT: string = "SHOW_MODAL_ALERT";
export const HIDE_MODAL_ALERT: string = "HIDE_MODAL_ALERT";

export interface ISetChat {
	messages: { [key: string]: ChatMessage };
	chatId: string;
	latestMessage?: firebase.firestore.Timestamp;
	removeOldMessages: boolean;
}

export interface ILikeChat {
	messageId: string;
	uid: string;
}

export interface IDeleteChat {
	messageId: string;
}

export interface IPinChat {
	messageId: string;
	newDupeChatId?: string;
}
export interface ILimitMessages {
	limitMessages: boolean;
}

export interface IUserStatusChanged {
	status: boolean;
	uid: string;
}

export interface IShowModalAlert {
	showModal: boolean;
	modalData: ModalData;
}

export interface SetChatData {
	type: typeof SET_CHAT_DATA;
	payload: ISetChat;
}

export interface LikeChatData {
	type: typeof LIKE_CHAT_MESSAGE;
	payload: ILikeChat;
}

export interface FlagChatData {
	type: typeof FLAG_CHAT_MESSAGE;
	payload: ILikeChat;
}

export interface DeleteChatData {
	type: typeof DELETE_CHAT_MESSAGE;
	payload: IDeleteChat;
}

export interface PinChatData {
	type: typeof PIN_CHAT_MESSAGE;
	payload: IPinChat;
}

export interface SetLimitMessages {
	type: typeof SET_LIMIT_MESSAGES;
	payload: ILimitMessages;
}

export interface SetScrolledUp {
	type: typeof SET_SCROLLED_UP;
	payload: boolean;
}

export interface UserStatusChanged {
	type: typeof USER_STATUS_CHANGED;
	payload: IUserStatusChanged;
}

export interface ShowModalAlert {
	type: typeof SHOW_MODAL_ALERT;
	payload: IShowModalAlert;
}

export interface HideModalAlert {
	type: typeof HIDE_MODAL_ALERT;
	payload: boolean;
}

export type ChatActionTypes =
	| SetChatData
	| LikeChatData
	| FlagChatData
	| DeleteChatData
	| SetLimitMessages
	| SetScrolledUp
	| PinChatData
	| UserStatusChanged
	| ShowModalAlert
	| HideModalAlert;
