import { chunk } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import firebaseApp from "../../../../firebase";
import { Channel, Content, ContentAnalyticsResults, ContentType, PaywallGlobalAccess } from "../../../../interfaces";
import { UserAnalytics, UserPresenceAnalytics } from "../../../../interfaces/UserAnalytics";
import { UserAnalyticsSummary } from "../../../../interfaces/UserAnalyticsSummary";
import { TypedUseSelectorHook } from "../../../../redux";
import asyncForEach from "../../../../utils/asyncForEach";
import { Well } from "../../UI";

const db = firebaseApp.firestore();
const firebaseStorage = firebaseApp.storage();

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
}

enum Status {
	Loading,
	Ready,
	Started,
	Error,
	Done,
}

const defaultTime = 300;

interface UserAnalyticsResult extends UserAnalytics {
	eventNumber?: string;
	typeName?: string;
	firstName?: string;
	lastName?: string;
	channelId?: string;
	watchTime: number;
}

const ProcessBigAnalytics: React.FC = () => {
	const [data, setData] = useState<ContentAnalyticsResults>();
	const [error, setError] = useState<string>();
	const [contentData, setContentData] = useState<Content>();
	const [status, setStatus] = useState(Status.Loading);

	const match: MatchParams = useRouteMatch<MatchParams>().params;

	const { contentId } = match;

	const user = useTypedSelector(store => store.user);

	const isGlobalAdmin = user.appUser?.globalRole === "admin" || user.appUser?.globalRole === "owner";

	const getViewers = async (contentId: string) => {
		try {
			console.log(`Processing viewers for ${contentId}`);

			const content = await db
				.collection("contents")
				.doc(contentId)
				.get();

			const contentData = content.data() as Content;

			const contentNameStringified = contentData.name.replace(/\s/g, "").replace(/[\W]+/g, "");

			const userNames: { [id: string]: string } = {};

			//Viewership
			const viewers: UserAnalyticsResult[] = [];
			let hasPaywall = false;
			let uniqueViewers = 0;
			let totalWatchTime = 0;

			const viewershipDocs = await db
				.collection("analytics")
				.doc("users")
				.collection(contentId)
				.get();

			if (!!viewershipDocs && !viewershipDocs.empty) {
				uniqueViewers = viewershipDocs.size;
				viewershipDocs.forEach(doc => {
					if (doc && !doc.id.includes("ua_")) {
						const d = doc.data() as UserAnalyticsResult;
						if (!hasPaywall && d.attendeeNumber) {
							hasPaywall = true;
						}
						d.firstName = d.name ? d.name.split(" ")[0] : undefined;
						d.lastName = d.name
							? d.name.split(" ").length > 1
								? d.name
										.split(" ")
										.slice(1)
										.join(" ")
								: undefined
							: undefined;
						viewers.push(d);
					}
				});
			}
			//Get Paywall Data
			const paywallAccess: { [channelId: string]: { [attendeeNumber: string]: PaywallGlobalAccess } } = {};
			if (hasPaywall) {
				await asyncForEach(contentData.channelIds, async (channelId: string) => {
					const paywallDocs = await db
						.collection("paywall_access")
						.where("channelIds", "array-contains", channelId)
						.get();
					paywallDocs.forEach(doc => {
						const d = doc.data() as PaywallGlobalAccess;
						if (!paywallAccess[channelId]) {
							paywallAccess[channelId] = {};
						}
						paywallAccess[channelId][doc.id] = d;
					});
				});
			}
			const channels = await db
				.collection("channels")
				.where("id", "in", contentData.channelIds)
				.get();
			const channelData: Channel[] = [];
			channels.forEach(doc => {
				channelData.push(doc.data() as Channel);
			});
			const viewerChunks = chunk(viewers, 300);
			await asyncForEach(viewerChunks, async (viewerDatum: UserAnalyticsResult[], index) => {
				console.log(`Process Viewer Chunk ${index + 1}/${viewerChunks.length}`);
				await Promise.all(
					viewerDatum.map(async data => {
						const promise = await new Promise(async (resolve, reject) => {
							try {
								if (data.analyticsUserId) {
									let watchTime = 0;
									const userPresenceDocs = await db
										.collection("analytics")
										.doc("users")
										.collection(contentId)
										.doc(data.analyticsUserId)
										.collection("user-presence")
										.orderBy("timestamp", "asc")
										.limit(240)
										.get();
									let presence: UserPresenceAnalytics[] = data.presence || [];

									if (!userPresenceDocs.empty) {
										userPresenceDocs.forEach(userPresenceDoc => {
											presence.push(userPresenceDoc.data() as UserPresenceAnalytics);
										});
									} else {
										data.watchTime = 0;
									}

									if (presence.length > 0) {
										userNames[data.analyticsUserId] = data.name || "Unidentified User";
										presence.forEach((pres, index) => {
											if (pres.activity === "enter") {
												if (presence[index + 1]) {
													const enterTime = moment(pres.timestamp);
													if (presence[index + 1].activity === "exit") {
														const exitTime = moment(presence[index + 1].timestamp);
														watchTime += exitTime.diff(enterTime, "seconds");
													} else {
														const exitTime = moment(presence[index + 1].timestamp);
														const diff = exitTime.diff(enterTime, "seconds");
														if (diff < defaultTime) {
															watchTime += diff;
														} else {
															watchTime += defaultTime;
														}
													}
												} else {
													watchTime += defaultTime; //Default to 5 minutes (like the Googles does)
												}
											} else if (index === 0) {
												if (contentData.opensAt) {
													const exitTime = moment(presence[index].timestamp);
													const opensTime = moment(contentData.opensAt);
													const openDiff = exitTime.diff(opensTime, "seconds");
													if (openDiff > defaultTime) {
														watchTime += defaultTime;
													} else if (openDiff > 0) {
														watchTime += openDiff;
													}
												}
											}
										});
										totalWatchTime += watchTime;
										data.watchTime = watchTime;
									} else {
										console.log(data.analyticsUserId, "Presence Empty");
									}
									resolve(true);
								} else {
									resolve(false);
								}
							} catch (err) {
								resolve(false);
							}
						});
						return promise;
					})
				);
			});
			const payload: Partial<ContentAnalyticsResults> = {};
			payload.uniqueViewers = uniqueViewers;
			payload.totalWatchTime = totalWatchTime;
			payload.averageWatchTime = totalWatchTime / uniqueViewers;

			//Paywall Results Export
			let paywallCsv: string | null = null;
			if (hasPaywall) {
				console.log("Generating Paywall CSV");
				paywallCsv =
					"Event Number,Attendee Number,Device,Attendee Name,Signin Name,Type Name,Viewing Time (Minutes)\n";
				Object.keys(paywallAccess).forEach(channelId => {
					if (contentData.channelIds.length > 1) {
						paywallCsv = `${paywallCsv}${channelData.find(ch => ch.id === channelId)?.name}\n`;
					}
					Object.values(paywallAccess[channelId]).forEach(access => {
						if (access.attendeeData?.EventNumber) {
							let devices = 0;
							if (access.seats[channelId]) {
								access.seats[channelId].forEach(seat => {
									const viewer = viewers.find(
										u => u.analyticsUserId === seat.analyticsUserId && u.authorized === true
									);
									if (viewer) {
										devices++;
										viewer.eventNumber = access.attendeeData?.EventNumber || "";
										viewer.typeName = access.attendeeData?.TypeName || "";
										viewer.channelId = channelId;
										paywallCsv = `${paywallCsv}${access.attendeeData?.EventNumber || ""},${access
											.attendeeData?.AttendeeNumber || ""},Device ${devices},"${access
											.attendeeData?.FirstName || ""} ${access.attendeeData?.LastName ||
											""}","${viewer.firstName || ""}${
											viewer.lastName ? " " : ""
										}${viewer.lastName || ""}","${access.attendeeData?.TypeName || ""}",${(
											viewer.watchTime / 60
										).toFixed(2)}\n`;
										viewer.firstName = access.attendeeData?.FirstName || viewer.firstName || "";
										viewer.lastName = access.attendeeData?.LastName || viewer.lastName || "";
									}
								});
							}
						}
					});
				});
				console.log("Finished Generating Paywall CSV");
				const paywallFileName = `${contentData.clientId}/csvs/${contentId}/${contentNameStringified}-paywall.csv`;
				const ref = firebaseStorage.ref(paywallFileName);
				let paywallRes = await ref.putString(paywallCsv);
				payload.paywallCsv = await paywallRes.ref.getDownloadURL();
			}

			const batch = db.batch();

			const resultsRef = db
				.collection("analytics")
				.doc("results")
				.collection(contentId)
				.doc("cache");

			await batch.set(resultsRef, payload, { merge: true });

			const viewerSaveChunks = chunk(viewers, 1000);
			await asyncForEach(viewerSaveChunks, async (viewerDatum: UserAnalyticsResult[], index) => {
				console.log(`Saving Viewer Chunk ${index + 1}/${viewerSaveChunks.length}`);
				const viewerPayload: { [analyticsUserId: string]: UserAnalyticsSummary } = {};
				viewerDatum.forEach(viewer => {
					//If you add any properties to this, make sure you reduce the chunk size!
					// The formula is 20,000/# of property keys (parent + children keys = 11 currently)
					viewerPayload[viewer.analyticsUserId] = {
						firebaseId: viewer.firebaseId,
						firstName: viewer.firstName || null,
						lastName: viewer.lastName || null,
						email: viewer.email || null,
						attendeeNumber: viewer.attendeeNumber || null,
						eventNumber: viewer.eventNumber || null,
						channelId: viewer.channelId || null,
						typeName: viewer.typeName || null,
						watchTime: viewer.watchTime,
						name: viewer.name || null,
					};
				});
				const viewersRef = db
					.collection("analytics")
					.doc("viewers")
					.collection(contentId)
					.doc(`${index}`);

				await viewersRef.set(viewerPayload);
			});
			console.log("Committing Batch.");
			const doneRef = db
				.collection("analytics")
				.doc("process")
				.collection(contentId)
				.doc("viewers");

			await batch.set(doneRef, {
				createdAt: moment.utc().toISOString(),
			});

			await batch.commit();
			console.log("Processing Done!");
			console.log(`Finished processing ${viewers.length} viewers for ${contentId}`);
			setStatus(Status.Done);
		} catch (error) {
			console.log(error);
			setStatus(Status.Error);
		}
	};

	useEffect(() => {
		if (isGlobalAdmin) {
			db.collection("contents")
				.doc(contentId)
				.get()
				.then(data => {
					if (data.exists) {
						const content = data.data() as Content;
						setContentData(content);
						if (content.contentType !== ContentType.onDemand) {
							setStatus(Status.Ready);
							getViewers(content.id);
						}
					}
				});
		}
	}, [contentId]);

	return (
		<>
			<h2>Process Big Analytics</h2>
			<Well>
				{status === Status.Loading && <>Loading...</>}
				{status >= Status.Ready && <>Processing Analytics. Open your console log to see progress</>}
				{status === Status.Done && <>All Done!</>}
				{status === Status.Error && <>Dang... Something went wrong.</>}
			</Well>
		</>
	);
};

export default ProcessBigAnalytics;
