import firebase from "firebase";
import { FormikContextType } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import authFirebase from "../../authFirebase";
import { REGEX } from "../../constants";
import { AuthProviders, Client, Content, ContentType } from "../../interfaces";
import { frontEndLoginSucess } from "../../redux/User/actions";
import { updateAnalyticsUserDoc, updateChatRequest } from "../../services/dataHelpers";
import { IconButton } from "../Buttons/IconButton";
import addOrReplaceProfileImage from "../Chat/services/addOrReplaceProfileImg";
import Form from "../Form";

interface FrontEndLoginProps {
	enableAnonymous: boolean;
	title?: string;
	onSignIn?: (user: firebase.User | null) => void;
	analyticsUserId: string;
	contentId: string;
	content: Content;
	client?: Client;
	authProviders?: AuthProviders;
}

interface AnonValues {
	email?: string;
	name: string;
}

function fadedColorFromHex(hex: string) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		//@ts-ignore
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.7)";
	}
	throw new Error("Bad Hex");
}

const style = {
	ChatSignIn: styled.div`
		label {
			color: ${({ theme }) => theme.wellTextColor};
		}
		input {
			pointer-events: all;
			-webkit-appearance: none;
			color: ${({ theme }) => theme.wellTextColor} !important;
			background-color: ${({ theme }) => theme.wellBackgroundColor} !important;

			::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
				opacity: 1; /* Firefox */
			}

			:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
			}

			::-ms-input-placeholder {
				/* Microsoft Edge */
				color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
			}
		}
	`,
};

interface SignInDataPayload {
	data: SignInData;
}
interface SignInData {
	providerName: "google" | "facebook" | "anonymous";
	success?: boolean;
	user?: firebase.User | null;
	name?: string;
	email?: string;
	accessToken?: string;
	contentId: string;
	analyticsUserId: string;
	content: Content;
}

interface FirebaseAuthProviders {
	google: () => firebase.auth.GoogleAuthProvider;
	facebook: () => firebase.auth.FacebookAuthProvider;
}

const FrontEndLogin: React.FC<FrontEndLoginProps> = ({
	analyticsUserId,
	contentId,
	enableAnonymous,
	title,
	onSignIn,
	content,
	client,
	authProviders,
}) => {
	const showGoogle = authProviders?.google ? authProviders.google.enabled !== false : true;
	const showFacebook = authProviders?.facebook ? authProviders.facebook.enabled !== false : true;

	const [showGuest, setShowGuest] = useState(!showGoogle && !showFacebook ? true : false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const dispatch = useDispatch();

	const signIn = async (dataPayload: SignInDataPayload) => {
		const { data } = dataPayload;
		const signInPromise = new Promise<void | SignInData>(async (resolve, reject) => {
			if (data.providerName === "anonymous") {
				let currentUser = authFirebase.auth().currentUser;
				if (currentUser) {
					currentUser
						.updateProfile({
							displayName: data.name,
						})
						.then(() => {
							data["user"] = currentUser;
							dispatch(frontEndLoginSucess(currentUser as firebase.User));
							currentUser?.getIdToken(true);

							updateAnalyticsUserDoc(
								currentUser as firebase.User,
								data.contentId,
								{
									email: data.email,
									analyticsUserId: data.analyticsUserId,
								},
								data.content.contentType || ContentType.onDemand
							);
							if (currentUser) {
								updateChatRequest(data.contentId, currentUser.uid, currentUser.uid, data.name || "");
							}
							if (onSignIn) {
								onSignIn(currentUser);
							}
						})
						.then(() => {
							resolve(data);
						});
				} else {
					authFirebase
						.auth()
						.signInAnonymously()
						.then(result => {
							data["success"] = true;
							if (result.user) {
								result.user
									.updateProfile({
										displayName: data.name,
									})
									.then(() => {
										data["user"] = result.user;
										updateAnalyticsUserDoc(
											result.user as firebase.User,
											data.contentId,
											{
												email: data.email,
												analyticsUserId: data.analyticsUserId,
											},
											data.content.contentType || ContentType.onDemand
										);
										if (onSignIn) {
											onSignIn(result.user);
										}
									})
									.then(() => {
										resolve(data);
									});
							}
						})
						.then(() => {});
				}
			} else {
				let currentUser = authFirebase.auth().currentUser;
				const oldUid = currentUser?.uid;
				const providers: FirebaseAuthProviders = {
					google: () => new firebase.auth.GoogleAuthProvider(),
					facebook: () => new firebase.auth.FacebookAuthProvider(),
				};
				const provider = providers[data.providerName]();

				authFirebase
					.auth()
					.signInWithPopup(provider)
					.then(async result => {
						const userPhoto = await addOrReplaceProfileImage(authFirebase.auth());
						data["user"] = result.user;
						// google, facebook, twitter
						// @ts-ignore
						data["accessToken"] = result.credential?.accessToken;
						data["success"] = true;
						updateAnalyticsUserDoc(
							result.user as firebase.User,
							data.contentId,
							{
								analyticsUserId: data.analyticsUserId,
							},
							data.content.contentType || ContentType.onDemand
						);
						if (oldUid) {
							updateChatRequest(
								data.contentId,
								oldUid,
								result?.user?.uid || oldUid,
								result?.user?.displayName || "Anonymous User"
							);
						}
						if (onSignIn) {
							onSignIn(result.user);
						}
					})
					.then(() => {
						resolve();
					})
					.catch(error => {
						if (error.code === "auth/web-storage-unsupported") {
							window.alert(
								`Third Party Cookies must be enabled to log in with ${data.providerName}. Please enable them in your browser's settings, refresh the page, and try again.`
							);
						}
						// Handle Errors here.
						const errorCode = error.code;
						const errorMessage = error.message;
						// The email of the user's account used.
						const email = error.email;
						// The firebaseApp.auth.AuthCredential type that was used.
						const credential = error.credential;
						data["success"] = false;
					});
			}
		});
		return await signInPromise;
	};

	const onClick = (providerName: "google" | "facebook") => {
		signIn({
			data: {
				providerName,
				contentId,
				analyticsUserId,
				content,
			},
		});
	};

	const onSubmit = (values: AnonValues) => {
		signIn({
			data: {
				providerName: "anonymous",
				name: values.name,
				email: values.email || "",
				contentId,
				analyticsUserId,
				content,
			},
		});
	};

	let anonymousSchema = Yup.object().shape({
		email: Yup.string().matches(REGEX.EMAIL, "Invalid Email"),
		name: Yup.string().required("Name Required"),
	});

	if (!enableAnonymous) {
		anonymousSchema = Yup.object().shape({
			email: Yup.string()
				.required("Email Required")
				.matches(REGEX.EMAIL, "Invalid Email"),
			name: Yup.string().required("Name Required"),
		});
	}

	return (
		<div className="chat-login">
			<div className={`area-reveal md ${showGuest && (showFacebook || showGoogle) ? "" : "active m-bottom"}`}>
				{title && <h2 style={{ textAlign: "center", margin: "10px 0" }}>{title}</h2>}
				{showGoogle && (
					<IconButton
						variant={"primary"}
						className="btn-block btn-google"
						icon="google"
						iconWeight="fab"
						onClick={() => onClick("google")}
					>
						Join With Google
					</IconButton>
				)}
				{showFacebook && (
					<IconButton
						variant={"primary"}
						className="btn-block btn-fb"
						icon="facebook-f"
						iconWeight="fab"
						onClick={() => onClick("facebook")}
					>
						Join With Facebook
					</IconButton>
				)}
			</div>
			{(showGoogle || showFacebook) && (
				<IconButton
					variant={"primary"}
					className="btn-block"
					icon={showGuest ? "chevron-left" : "comment-lines"}
					iconWeight="fas"
					onClick={() => setShowGuest(!showGuest)}
				>
					{showGuest ? "Join Another Way" : "Join As Guest"}
				</IconButton>
			)}

			<div className={`area-reveal light-form md ${showGuest ? "active" : ""}`}>
				<Form
					initialValues={{ email: "", password: "" }}
					validationSchema={anonymousSchema}
					onSubmit={onSubmit}
				>
					{({ formikContext }: { formikContext: FormikContextType<AnonValues> }) => {
						return (
							<fieldset className="m-1" disabled={isSubmitting}>
								<Form.Field label="Email" id="email" name="email" />
								<Form.Field label="Name" id="name" name="name" />
								<button type="submit" className={`btn btn-block btn-${"dark"}`}>
									Join
								</button>
							</fieldset>
						);
					}}
				</Form>
			</div>
		</div>
	);
};

export default FrontEndLogin;
