import React, { useState, useEffect } from "react";
import { Well, PageTitle, PageContainer } from "../UI";
// import { FieldArray, FormikContextType } from "formik";
import { Client, Content } from "../../../interfaces";
import moment from "moment";
import firebase from "../../../firebase";

const db = firebase.firestore();

interface ContentReults extends Content {
	maxLive: number;
}

const ClientReport: React.FC = () => {
	const [contents, setContents] = useState<ContentReults[]>();
	const [clients, setClients] = useState<Client[]>();

	useEffect(() => {
		const contentsDocRef = db.collection("clients");
		contentsDocRef
			.orderBy("name")
			.get()
			.then(querySnapshot => {
				let tempClientsList: Client[] = [];
				querySnapshot.forEach(doc => {
					const data = doc.data() as Client;
					if (!data.isArchived && !data.isDemo) {
						tempClientsList.push(data);
					}
				});
				setClients(tempClientsList);
			});
		db.collection("contents")
			.where(
				"startsAt",
				">",
				moment()
					.subtract(7, "days")
					.startOf("day")
					.toISOString()
			)
			.where(
				"startsAt",
				"<",
				moment()
					.endOf("day")
					.toISOString()
			)
			.orderBy("startsAt", "asc")
			.get()
			.then(docs => {
				let tempContentsList: ContentReults[] = [];
				docs.forEach(doc => {
					tempContentsList.push(doc.data() as ContentReults);
				});
				setContents(tempContentsList);
			});
	}, []);

	let clientsWithContentInLastMonth: string[] = [];
	let clientsWithContentInLast3Months: string[] = [];
	let clientsWithContentInLastHalfYear: string[] = [];
	let clientsWithContentInLastYear: string[] = [];

	clients?.forEach(client => {
		if (client.mostRecentContent) {
			if (moment.utc(client.mostRecentContent).isAfter(moment().subtract(1, "month"))) {
				clientsWithContentInLastMonth.push(client.id);
			} else if (moment.utc(client.mostRecentContent).isAfter(moment().subtract(3, "months"))) {
				clientsWithContentInLast3Months.push(client.id);
			} else if (moment.utc(client.mostRecentContent).isAfter(moment().subtract(6, "months"))) {
				clientsWithContentInLastHalfYear.push(client.id);
			} else if (moment.utc(client.mostRecentContent).isAfter(moment().subtract(1, "year"))) {
				clientsWithContentInLastYear.push(client.id);
			}
		}
	});

	const markArchived = (clientId: string) => {
		if (clients) {
			db.collection("clients")
				.doc(clientId)
				.set({ isArchived: true }, { merge: true })
				.then(() => {
					const index = clients?.findIndex(c => c.id === clientId);
					if (index) {
						const newClients = clients.concat();
						newClients.splice(index, 1);
						setClients(newClients);
					}
				});
		}
	};
	const setClientNumber = (clientId: string, bfClientNumber: string) => {
		if (clients) {
			db.collection("clients")
				.doc(clientId)
				.set({ bfClientNumber }, { merge: true })
				.then(() => {
					const index = clients?.findIndex(c => c.id === clientId);
					if (index) {
						const newClients = clients.concat();
						newClients[index].bfClientNumber = bfClientNumber;
						setClients(newClients);
					}
				});
		}
	};

	if (!contents || !clients) {
		return null;
	}

	return (
		<PageContainer size="lg" className="no-sidebar" htmlPageTitle="Organization Report">
			<PageTitle>Client Report</PageTitle>
			<Well>
				<table className="bfo-table">
					<tr>
						<th>Client Name</th>
						<th>Location</th>
						<th>Contents in last week</th>
						<th>Client since</th>
						<th>Activity</th>
						<th></th>
					</tr>
					{clients?.map(client => {
						const filteredContents = contents.filter(content => content.clientId === client.id);
						return (
							<tr>
								<td>
									<a href={`/dashboard/${client.id}`} style={{ color: "#FFFFFF" }} target="_blank">
										{client.name}
									</a>
									<br />
									<small
										className={`cursor-pointer ${!client.bfClientNumber ? "error" : ""}`}
										onClick={() => {
											const bfClientNumber = window.prompt(
												"Brushfire Client Number",
												client.bfClientNumber ?? ""
											);
											if (bfClientNumber) {
												setClientNumber(client.id, bfClientNumber.trim());
											}
										}}
									>
										{client.bfClientNumber
											? `Client: ${client.bfClientNumber}`
											: "Add Client Number"}
									</small>
								</td>
								<td>
									{client.city}, {client.region ? client.region : client.country}
								</td>
								<td>{filteredContents.length}</td>
								<td>
									{client.createdAt ? moment(client.createdAt).format("MMM Do YYYY") : "Beginning"}
								</td>
								<td>
									<i
										className={`fal fa-${
											clientsWithContentInLastMonth.includes(client.id)
												? "tachometer-alt-fastest"
												: clientsWithContentInLast3Months.includes(client.id)
												? "tachometer-alt-fast"
												: clientsWithContentInLastHalfYear.includes(client.id)
												? "tachometer-alt-average"
												: clientsWithContentInLastYear.includes(client.id)
												? "tachometer-alt-slow"
												: "tachometer-alt-slowest"
										} ${!client.mostRecentContent ? `error` : ""}`}
									></i>
								</td>
								<td>
									<div
										className="btn btn-primary btn-sm"
										onClick={() => {
											markArchived(client.id);
										}}
									>
										Archive
									</div>
								</td>
							</tr>
						);
					})}
					<tr>
						<td>Total: {clients.length}</td>
						<td></td>
						<td>{contents.length}</td>
						<td></td>
						<td></td>
					</tr>
				</table>
				<div>Clients with new content in last month: {clientsWithContentInLastMonth.length}</div>
				<div>
					Clients with new content in last 3 months:{" "}
					{clientsWithContentInLastMonth.length + clientsWithContentInLast3Months.length}
				</div>
				<div>
					Clients with new content in last 6 months:{" "}
					{clientsWithContentInLastMonth.length +
						clientsWithContentInLast3Months.length +
						clientsWithContentInLastHalfYear.length}
				</div>
				<div>
					Clients with new content in last 12 months:{" "}
					{clientsWithContentInLastMonth.length +
						clientsWithContentInLast3Months.length +
						clientsWithContentInLastHalfYear.length +
						clientsWithContentInLastYear.length}
				</div>
			</Well>
		</PageContainer>
	);
};

export default ClientReport;
