import { GoogleAnalytics } from "../interfaces";

export enum Dimension {
	clientId = "dimension1",
	clientKey = "dimension2",
	channelId = "dimension3",
	channelKey = "dimension4",
	contentId = "dimension5",
	contentKey = "dimension6",
	isGlobalUser = "dimension7",
}

type DimensionName =
	| Dimension.clientId
	| Dimension.clientKey
	| Dimension.channelId
	| Dimension.channelKey
	| Dimension.contentId
	| Dimension.contentKey
	| Dimension.isGlobalUser;

let dimensions: { [key in DimensionName]?: string | null } = {};
let clientTrackingIds: Array<string> = [];

export const setDimensions = (params: { [key in DimensionName]?: string | null }) => {
	dimensions = { ...dimensions, ...params };

	//Send dimensions data with every event
	gtag("set", dimensions);
};

export const setAnalytics = (clientGoogleAnalytics: Array<GoogleAnalytics> = []) => {
	clientTrackingIds = [];
	setTimeout(() => {
		// this guarantees it runs after the JavaScript creates the <Title />
		if (gtag) {
			clientGoogleAnalytics.forEach(({ trackingId }) => {
				//Allow clients to view their data in Analytics dashboard
				if (!clientTrackingIds.includes(trackingId)) {
					gtag("config", trackingId);
					gtag("event", "page_view", {
						send_to: trackingId,
					});
					clientTrackingIds.push(trackingId);
				}
			});
		}
	}, 0);
};

export const logEvent = (
	event:
		| "click_client_link"
		| "click_content_link"
		| "close_panel"
		| "open_bible"
		| "open_chat"
		| "open_direct_chat"
		| "open_notes"
		| "open_schedule"
		| "open_panel"
		| "open_questions"
		| "view_content",
	value?: string
) => {
	let eventCategory: string | null = null;
	let eventAction: string | null = null;
	let data = {};
	if (event === "click_client_link") {
		eventCategory = "client_link";
		eventAction = "click";
		data = { ...dimensions, metric1: 1 };
	} else if (event === "click_content_link") {
		eventCategory = "content_link";
		eventAction = "click";
		data = { ...dimensions, metric2: 1 };
	} else if (event === "close_panel") {
		eventCategory = "panel";
		eventAction = "close";
		data = { ...dimensions, metric3: 1 };
	} else if (event === "open_bible") {
		eventCategory = "bible";
		eventAction = "open";
		data = { ...dimensions, metric4: 1 };
	} else if (event === "open_chat") {
		eventCategory = "chat";
		eventAction = "open";
		data = { ...dimensions, metric5: 1 };
	} else if (event === "open_direct_chat") {
		eventCategory = "bible";
		eventAction = "open_direct";
		data = { ...dimensions, metric6: 1 };
	} else if (event === "open_notes") {
		eventCategory = "notes";
		eventAction = "open";
		data = { ...dimensions, metric7: 1 };
	} else if (event === "open_panel") {
		eventCategory = "panel";
		eventAction = "open";
		data = { ...dimensions, metric8: 1 };
	} else if (event === "open_questions") {
		eventCategory = "questions";
		eventAction = "open";
		data = { ...dimensions, metric9: 1 };
	} else if (event === "view_content") {
		eventCategory = "content";
		eventAction = "view";
		data = { ...dimensions, metric10: 1 };
	} else if (event === "open_schedule") {
		eventCategory = "schedule";
		eventAction = "open";
		data = { ...dimensions, metric11: 1 };
	}

	if (gtag && eventCategory && eventAction) {
		gtag("event", value ?? event, {
			event_category: eventCategory,
			content_id: dimensions.dimension5,
			event_action: eventAction,
			...data,
		});

		//Allow clients to view their data in Analytics dashboard
		clientTrackingIds.forEach(trackingId => {
			gtag("event", value ?? event, {
				send_to: trackingId,
				event_category: eventCategory,
				content_id: dimensions.dimension5,
				event_action: eventAction,
				data,
			});
		});
	}
};
