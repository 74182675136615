import React from "react";

import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";
import { ReactionIcon, ContentReactions, Conversion, ChannelData, Content } from "../../../../../interfaces";

import * as Styled from "../ContentFormSections.styled";
import Editor from "../../../../../components/Form/Editor";
import { FormikContextType } from "formik";

export interface OnDemandAvailabilitySectionProps {
	convertToOnDemand?: Conversion;
	channelsData: ChannelData[];
	formikContext: FormikContextType<Content>;
	isSubmitting: boolean;
	formValues: Content;
}

const OnDemandAvailabilitySection: React.FC<OnDemandAvailabilitySectionProps> = ({
	convertToOnDemand,
	channelsData,
	formikContext,
	isSubmitting,
	formValues,
}) => {
	const headerMessage = "Automatically convert this video to On-demand after it has ended";
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>On-Demand Availability</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="convertToOnDemand.enabled"
					name="convertToOnDemand.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{convertToOnDemand?.enabled ? (
				<Well message={headerMessage}>
					<h5 className="m-top p-top">DETAILS</h5>
					<Form.Field label="Name *" id="convertToOnDemand.name" name="convertToOnDemand.name" />
					{formValues && (
						<Form.Field
							component={Editor}
							label="Description *"
							id="convertToOnDemand.description"
							name="convertToOnDemand.description"
							darkMode={true}
							hasHtmlMenu
							disabled={isSubmitting}
							initialValue={formValues?.convertToOnDemand?.description || ""}
							onEditorChange={(content: string) => {
								formikContext.setFieldValue("convertToOnDemand.description", content);
							}}
							className="darkmode-field"
						/>
					)}
					<h5 className="m-top p-top">CHANNELS</h5>
					<div id="convertToOnDemand.channelIds"></div>
					{channelsData?.map(channel => {
						return (
							<div className="channel-checkbox" key={channel.id}>
								<Form.Input.Checkbox
									label={channel.name}
									id={`convertToOnDemand.channelIds[${channel.id}]`}
									name={`convertToOnDemand.channelIds[${channel.id}]`}
									value={convertToOnDemand.channelIds?.includes(channel.id) || false}
									onChecked={() => {
										let data = convertToOnDemand.channelIds?.concat() || [];
										data.push(channel.id);
										formikContext.setFieldValue("convertToOnDemand.channelIds", data);
									}}
									onUnchecked={() => {
										let data = convertToOnDemand.channelIds?.concat() || [];
										const index = data.indexOf(channel.id);
										if (index > -1) {
											data.splice(index, 1);
										}
										formikContext.setFieldValue("convertToOnDemand.channelIds", data);
									}}
								/>
							</div>
						);
					})}
				</Well>
			) : (
				<Well>
					<p>{headerMessage}</p>
				</Well>
			)}
		</>
	);
};

export default OnDemandAvailabilitySection;
