import firebase from "firebase";
import moment from "moment";
import { Dispatch } from "redux";
import { badWords } from "../../constants";
import firebaseApp from "../../firebase";
import { ChatMessage, ChatRequest, ChatRequestStatus } from "../../interfaces";
import { deleteMessage, flagMessage, likeMessage, pinMessage, setChatData } from "../../redux/Chat/actions";

const EVENTS: any = {};

export const CHAT_SENDMESSAGE = "CHAT_SENDMESSAGE";
export const CHAT_SIGNINPASSWORD = "CHAT_SIGNINPASSWORD";
export const CHAT_CONVMOUNTED = "CHAT_CONVOMOUNTED";
export const CHAT_REFRESHCONVO = "CHAT_REFRESHCONVO";
export const CHAT_SENTMESSAGE = "CHAT_SENTMESSAGE";
export const CHAT_DELCONVO = "CHAT_DELCONVO";
export const CHAT_BANUSER = "CHAT_BANUSER";
export const CHAT_UNBANUSER = "CHAT_UNBANUSER";
export const CHAT_REGISTERUSER = "CHAT_REGISTERUSER";
export const CHAT_TOGGLECOMMENTLIKE = "CHAT_TOGGLECOMMENTLIKE";
export const CHAT_TOGGLECOMMENTFLAG = "CHAT_TOGGLECOMMENTFLAG";
export const CHAT_NEWMESSAGES = "CHAT_NEWMESSAGES";
export const CHAT_UNMOUNT = "CHAT_UNMOUNT";
export const CHAT_PINCONVO = "CHAT_PINCONVO";

export interface Message {
	id: string;
	chatId: string;
	userName: string;
	uid: string;
	message: string;
	photoURL: string;
	email: string;
	isHost: boolean;
	likesCount: number;
	likes: string[];
	timestamp: number | firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;
	isDeleted: boolean;
}

let chatSnapshot: { [chatId: string]: () => void } = {};

let localDispatch: Dispatch<any> | null = null;

export const emit = (eventName: string, data: any, chatId: string) => {
	if (!EVENTS[chatId]) {
		return;
	}
	if (!EVENTS[chatId][eventName]) {
		return;
	}
	return new Promise<void>((resolve, reject) => {
		EVENTS[chatId][eventName].map(async (handler: any) => {
			await handler(data);
			resolve();
		});
	});
};

export const subscribe = (eventName: string, listener: any, chatId: string) => {
	if (EVENTS[chatId]) {
		if (EVENTS[chatId][eventName]) {
			EVENTS[chatId][eventName].push(listener);
		} else {
			EVENTS[chatId][eventName] = [listener];
		}
	} else {
		EVENTS[chatId] = { [eventName]: [listener] };
	}
};

export const resubscribe = (eventName: string, listener: any, chatId: string) => {
	if (EVENTS[chatId]) {
		if (EVENTS[chatId][eventName]) {
			EVENTS[chatId][eventName] = [listener];
		} else {
			EVENTS[chatId][eventName] = [listener];
		}
	} else {
		EVENTS[chatId] = { [eventName]: [listener] };
	}
};

const hasScriptTag = (message: string) => {
	const scriptTagRegex = /<[sS][cC][rR][iI][pP][tT][\s\S]*?>[\s\S]*?(?:<\/[sS][cC][rR][iI][pP][tT]>)?/;
	if (message.match(scriptTagRegex)) {
		return true;
	}
	return false;
};

const hasIFrame = (message: string) => {
	const iFrameTagRegex = /<[iI][fF][rR][aA][mM][eE][\s\S]*?>[\s\S]*?(?:<\/[iI][fF][rR][aA][mM][eE]>)?/;
	if (message.match(iFrameTagRegex)) {
		return true;
	}
	return false;
};

const createTextLinks = (message: string) => {
	return (message || "").replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, function(match, space, url: string) {
		var hyperlink = url.replace(/([.,\/#!$%\^&\*;:{}=\-_`~()\]\[])+$/g, "");
		if (!hyperlink.match("^https?://")) {
			hyperlink = "http://" + hyperlink;
		}
		return space + '<a href="' + hyperlink + '" target="_blank">' + url + "</a>";
	});
};

const removeTextLinks = (message: string) => {
	return (message || "").replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, function(match, space, url) {
		return "";
	});
};

const profanityFilter = (message: string) => {
	message = message || "";
	const regexp = new RegExp(`\\b(${badWords.join("|")})\\b`, "gi");
	return message.replace(regexp, function(str) {
		let replace_str = "";
		for (var i = 0; i < str.length; i++) {
			replace_str = replace_str + "*";
		}
		return replace_str;
	});
};

interface IMessagePayload extends Message {
	useChatProcessor: boolean;
	request: ChatRequest;
	contentId: string;
}

const sendMessage = async (messagePayload: IMessagePayload) => {
	const {
		chatId,
		userName,
		uid,
		message: incomingMessage,
		photoURL,
		email,
		isHost,
		useChatProcessor,
		request,
		contentId,
	} = messagePayload;

	if (hasScriptTag(incomingMessage) || hasIFrame(incomingMessage)) {
		emit(CHAT_SENTMESSAGE, false, chatId);
	} else {
		let message = incomingMessage.replace(/(<([^>]+)>)/gi, "");
		if (message.match(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi)) {
			if (isHost) {
				message = createTextLinks(message).replace(/(?:\r\n|\r|\n)/g, "<br>");
			} else {
				message = removeTextLinks(message).replace(/(?:\r\n|\r|\n)/g, "<br>");
			}
		} else {
			message = message.replace(/(?:\r\n|\r|\n)/g, "<br>");
		}
		const filteredMessage = profanityFilter(message);
		const db = firebaseApp.firestore();
		if (filteredMessage !== "") {
			const newMessage = {
				userName,
				uid,
				message: filteredMessage,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				photoURL: photoURL ? `https://media.online.brushfire.com/profile_images/${uid}/profile.jpg` : null,
				//email, // Taking this out because we have it on the userAnalytics doc which isn't publicly readable
				isHost,
				flags: filteredMessage !== message ? ["profanity"] : [],
				isDeleted: false,
			};
			await db
				.collection("chat")
				.doc(useChatProcessor ? "processed-contents" : "contents")
				.collection(chatId)
				.add(newMessage)
				.then(doc => {
					if (useChatProcessor && localDispatch) {
						localDispatch(
							setChatData({
								messages: {
									[doc.id]: Object.assign({}, newMessage, {
										id: doc.id,
										timestamp: undefined,
										updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
										likes: [],
										likesCount: 0,
										isPinned: false,
									}),
								},
								chatId,
								latestMessage: undefined,
								removeOldMessages: false,
							})
						);
					}
				})
				.catch(error => console.log(`error `, error));
			if (!!request) {
				const payload = Object.assign({}, request, { updatedAt: moment.utc().valueOf() });
				Object.keys(request.userData).forEach(key => {
					if (request.userData[key].status === ChatRequestStatus.directMessageEnded) {
						payload.userData[key].status = ChatRequestStatus.directMessageActive;
					}
				});
				payload.showRequest = true;
				db.collection("chat")
					.doc("direct")
					.collection(contentId)
					.doc(chatId)
					.set(payload, { merge: true });
			}
			emit(CHAT_SENTMESSAGE, true, chatId);
		}
	}
};

interface ISetupSnapshotListener {
	chatId: string;
	useChatProcessor: boolean;
}

interface ChatActivity {
	messages: ChatMessage[];
	createdAt: string;
	latestMessage: firebase.firestore.Timestamp;
}

export const clearOldSnapshot = (chatId: string) => {
	if (chatSnapshot[chatId]) {
		chatSnapshot[chatId]();
	}
};

const setupSnapshotListener = (messagePayload: ISetupSnapshotListener) => {
	const { chatId, useChatProcessor } = messagePayload;
	if (chatId) {
		if (useChatProcessor) {
			const db = firebaseApp.firestore();

			if (chatSnapshot[chatId]) {
				chatSnapshot[chatId]();
			}
			//Check for cached chats

			//Get cached chats

			//Fetch activity since

			//subscribe to activity
			chatSnapshot[chatId] = db
				.collection("chat")
				.doc("processor")
				.collection(chatId)
				.doc("activity")
				.onSnapshot(async snapshot => {
					const data = snapshot.data() as ChatActivity;
					if (data) {
						const messages: { [key: string]: ChatMessage } = {};
						data.messages.forEach(message => {
							messages[message.id] = message;
						});
						// emit(CHAT_REFRESHCONVO, { chatId, convo }, chatId);
						emit(CHAT_NEWMESSAGES, Object.values(messages), chatId);
						if (localDispatch) {
							localDispatch(
								setChatData({
									latestMessage: data.latestMessage,
									chatId,
									messages,
									removeOldMessages: true,
								})
							);
						}
					}
				});
		} else {
			const db = firebaseApp.firestore();
			if (chatSnapshot[chatId]) {
				chatSnapshot[chatId]();
			}
			chatSnapshot[chatId] = db
				.collection("chat")
				.doc("contents")
				.collection(chatId)
				.orderBy("timestamp", "desc")
				.limit(100)
				.onSnapshot(async snapshot => {
					let convo: { id: string; timestamp: any }[] = [];
					// last is first
					snapshot.docs.forEach(doc => {
						//@ts-ignore Timestamp is there, I promise
						const data: { id: string; timestamp: any } = { id: doc.id, ...doc.data() };
						if (data.timestamp) {
							if (data.timestamp.seconds) {
								data.timestamp = parseInt(
									`${data.timestamp.seconds}${data.timestamp.seconds / 10000000}`
								);
							}
							convo.unshift(data);
						}
					});
					convo = convo.sort((el1, el2) => {
						return el1.timestamp - el2.timestamp;
					});
					emit(CHAT_REFRESHCONVO, { chatId, convo }, chatId);
					emit(CHAT_NEWMESSAGES, convo, chatId);
				});
		}
	}
};

const unmountListener = (params: any) => {
	if (chatSnapshot[params.chatId]) {
		chatSnapshot[params.chatId]();
	}
};

interface DelConvoParams {
	id: string;
	chatId: string;
	useChatProcessor: boolean;
	convo: ChatMessage;
}

const delConvo = async (params: DelConvoParams) => {
	const { id, chatId, useChatProcessor, convo } = params;
	const db = firebaseApp.firestore();
	const matchingPinnedChatId = convo.dupePinnedChatId ?? convo.originalId;

	await db
		.collection("chat")
		.doc(useChatProcessor ? "processed-contents" : "contents")
		.collection(chatId)
		.doc(id)
		.update({ isDeleted: true, updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
		.then(() => {
			if (useChatProcessor && localDispatch) {
				localDispatch(deleteMessage({ messageId: id }));
			}
		});
	if (matchingPinnedChatId) {
		await db
			.collection("chat")
			.doc(useChatProcessor ? "processed-contents" : "contents")
			.collection(chatId)
			.doc(matchingPinnedChatId)
			.update({
				isPinned: false,
				isDeleted: true,
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then(() => {
				if (useChatProcessor && localDispatch) {
					localDispatch(deleteMessage({ messageId: matchingPinnedChatId ?? "" }));
				}
			});
	}
};

export interface PinConvoParams {
	id: string;
	chatId: string;
	useChatProcessor: boolean;
	pin: boolean;
	convo: ChatMessage;
}

const pinConvo = async (params: PinConvoParams) => {
	const { id, chatId, useChatProcessor, pin, convo } = params;
	const db = firebaseApp.firestore();
	const chatDocs = db
		.collection("chat")
		.doc(useChatProcessor ? "processed-contents" : "contents")
		.collection(chatId);
	let dupeChatId = "";
	if (useChatProcessor && localDispatch) {
		localDispatch(pinMessage({ messageId: id }));
	}
	if (pin) {
		await chatDocs
			.add({
				...convo,
				...{ originalId: id, isPinned: true, updatedAt: firebase.firestore.FieldValue.serverTimestamp() },
			})
			.then(async doc => {
				dupeChatId = doc.id;
				if (useChatProcessor && localDispatch) {
					localDispatch(pinMessage({ messageId: id, newDupeChatId: doc.id }));
				}
				await chatDocs.doc(id).update({ dupePinnedChatId: doc.id });
			});
	}

	const oldPinnedChats = await chatDocs.where("isPinned", "==", true).get();
	oldPinnedChats.forEach(async oldPinnedChat => {
		if (oldPinnedChat.id != dupeChatId) {
			await chatDocs.doc(oldPinnedChat.id).set(
				{
					isPinned: false,
					isDeleted: true,
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
		}
		if (oldPinnedChat.data().dupePinnedChatId && !pin) {
			await chatDocs.doc(oldPinnedChat.data().dupePinnedChatId).set(
				{
					dupePinnedChatId: null,
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
		}
	});
};

interface BanParams {
	chatId: string;
	uid: string;
	userName: string;
}

const banUser = async (params: BanParams) => {
	const { uid, userName, chatId } = params;
	const db = firebaseApp.firestore();
	const bannedPath = db
		.collection("chat")
		.doc("banned")
		.collection(chatId);

	const oldPinnedChats = await db
		.collection("chat")
		.doc("processed-contents")
		.collection(chatId)
		.where("isPinned", "==", true)
		.get();
	oldPinnedChats.forEach(async oldPinnedChat => {
		if ((oldPinnedChat.data() as ChatMessage).uid === uid) {
			await db
				.collection("chat")
				.doc("processed-contents")
				.collection(chatId)
				.doc(oldPinnedChat.id)
				.set({ isPinned: false, updatedAt: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
		}
	});

	return bannedPath
		.doc(uid)
		.set({ userName })
		.then(() => {});
};

const unbanUser = async (params: BanParams) => {
	const { uid, userName, chatId } = params;
	const db = firebaseApp.firestore();
	const bannedPath = db
		.collection("chat")
		.doc("banned")
		.collection(chatId);

	return bannedPath.doc(uid).delete();
};

interface ToggleLikeParams {
	action: "like" | "unlike";
	chatId: string;
	id: string;
	uid: string;
	useChatProcessor: boolean;
	convo: ChatMessage;
}

const toggleCommentLike = async (params: ToggleLikeParams) => {
	const { id, chatId, uid, action, useChatProcessor, convo } = params;
	const db = firebaseApp.firestore();
	const matchingPinnedChatId = convo.dupePinnedChatId ?? convo.originalId;
	const commentRef = db
		.collection("chat")
		.doc(useChatProcessor ? "processed-contents" : "contents")
		.collection(chatId)
		.doc(id);

	const comment = await commentRef
		.get()
		.then((doc: any) => {
			if (doc.exists) {
				let data = doc.data();
				return data;
			}
			return null;
		})
		.catch(() => {
			return null;
		});

	let likesCount = comment.likesCount ? comment.likesCount : 0;
	let likes = comment.likes && comment.likes.length > 0 ? comment.likes : [];

	switch (action) {
		case "like":
			likesCount++;
			likes.push(uid);
			break;
		case "unlike":
			likesCount--;
			likes = likes.filter((likeUID: string) => likeUID !== uid);
			break;
		default:
			break;
	}

	await commentRef
		.update({ likesCount: likesCount, likes: likes, updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
		.then(() => {
			if (useChatProcessor && localDispatch) {
				localDispatch(likeMessage({ messageId: id, uid }));
			}
		})
		.catch(error => console.log("error updating comment", error));

	if (matchingPinnedChatId) {
		await db
			.collection("chat")
			.doc(useChatProcessor ? "processed-contents" : "contents")
			.collection(chatId)
			.doc(matchingPinnedChatId)
			.update({
				likesCount: likesCount,
				likes: likes,
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then(() => {
				if (useChatProcessor && localDispatch) {
					localDispatch(likeMessage({ messageId: matchingPinnedChatId ?? "", uid }));
				}
			})
			.catch(error => console.log("error updating comment", error));
	}
};

interface ToggleFlagParams {
	action: "flag" | "unflag";
	chatId: string;
	id: string;
	uid: string;
	useChatProcessor: boolean;
	convo: ChatMessage;
}

const toggleCommentFlag = async (params: ToggleFlagParams) => {
	const { id, chatId, uid, action, useChatProcessor, convo } = params;
	const db = firebaseApp.firestore();
	const matchingPinnedChatId = convo.dupePinnedChatId ?? convo.originalId;
	const commentRef = await db
		.collection("chat")
		.doc(useChatProcessor ? "processed-contents" : "contents")
		.collection(chatId)
		.doc(id);

	const comment = await commentRef
		.get()
		.then((doc: any) => {
			if (doc.exists) {
				let data = doc.data();
				return data;
			}
			return null;
		})
		.catch(() => {
			return null;
		});
	let flagsCount = comment.flagsCount ? comment.flagsCount : 0;
	let flags = comment.flags && comment.flags.length > 0 ? comment.flags : [];

	switch (action) {
		case "flag":
			flags.push(uid);
			break;
		case "unflag":
			flags = flags.filter((flagUID: string) => flagUID !== uid);
			break;
		default:
			break;
	}

	await commentRef
		.update({ flagsCount, flags, updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
		.then(() => {
			if (useChatProcessor && localDispatch) {
				localDispatch(flagMessage({ messageId: id, uid }));
			}
		})
		.catch(error => console.log("error updating comment", error));

	if (matchingPinnedChatId) {
		await db
			.collection("chat")
			.doc(useChatProcessor ? "processed-contents" : "contents")
			.collection(chatId)
			.doc(matchingPinnedChatId)
			.update({ flagsCount, flags, updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
			.then(() => {
				if (useChatProcessor && localDispatch) {
					localDispatch(flagMessage({ messageId: matchingPinnedChatId ?? "", uid }));
				}
			})
			.catch(error => console.log("error updating comment", error));
	}
};

export const initChat = (chatId: string, handleNewMessageCount: Function, dispatch: Dispatch<any>) => {
	EVENTS[chatId] = {};
	localDispatch = dispatch;
	subscribe(CHAT_SENDMESSAGE, sendMessage, chatId);
	//subscribe(CHAT_SIGNINPASSWORD, signInPassword, chatId);
	subscribe(
		CHAT_CONVMOUNTED,
		(data: any) => {
			setupSnapshotListener(data);
		},
		chatId
	);

	subscribe(CHAT_UNMOUNT, unmountListener, chatId);
	subscribe(CHAT_DELCONVO, delConvo, chatId);
	subscribe(CHAT_PINCONVO, pinConvo, chatId);
	subscribe(CHAT_TOGGLECOMMENTLIKE, toggleCommentLike, chatId);
	subscribe(CHAT_TOGGLECOMMENTFLAG, toggleCommentFlag, chatId);
	subscribe(CHAT_BANUSER, banUser, chatId);
	subscribe(CHAT_UNBANUSER, unbanUser, chatId);
	subscribe(CHAT_NEWMESSAGES, handleNewMessageCount, chatId);
	//subscribe(CHAT_REGISTERUSER, registerUser);
};

export const resubscribeToMessageHandler = (chatId: string, handleNewMessageCount: Function) => {
	resubscribe(CHAT_NEWMESSAGES, handleNewMessageCount, chatId);
};
