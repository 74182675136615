import authFirebase from "../authFirebase";
import firebase from "../firebase";
import { ContentType } from "../interfaces";

let userStatusDatabaseRef: firebase.database.Reference | null = null;
let channelStatusDatabaseRef: firebase.database.Reference | null = null;

export const initFirebasePresence = async (
	contentId: string,
	analyticsUserId: string,
	uid: string,
	contentType: ContentType
) => {
	const currentUser = authFirebase?.auth()?.currentUser;
	if (uid && analyticsUserId) {
		// Warn if the browser doesn't support addEventListener or the Page Visibility API
		//updateHidden();

		const provider = currentUser?.isAnonymous
			? currentUser.displayName
				? "anonymous"
				: "unidentified"
			: currentUser?.providerId || "";

		// We'll create a constant which we will write to
		// the Realtime database when this device is offline
		const isOfflineForDatabase = {
			state: "offline",
			isListening: false,
			firebaseId: uid,
			provider,
			action: "Disconnected",
			contentType,
			userAgent: navigator.userAgent,
		};

		if (userStatusDatabaseRef !== null) {
			//If the user already had a connection to other content, remove it
			await userStatusDatabaseRef?.set({ ...isOfflineForDatabase, skipQueue: false });
			//Remove the onDisconnect handler
			await userStatusDatabaseRef?.onDisconnect().cancel();
		}

		// Create a reference to this user's specific status node.
		// This is where we will store data about being online/offline.
		userStatusDatabaseRef = firebase.database().ref(`/analytics/user-presence/${contentId}/${analyticsUserId}`);

		// Create a reference to the special '.info/connected' path in
		// Realtime Database. This path returns `true` when connected
		// and `false` when disconnected.
		firebase
			.database()
			.ref(".info/connected")
			.on("value", async snapshot => {
				// If we're not currently connected, don't do anything.
				if (snapshot.val() === false) {
					return;
				}

				// If we are currently connected, then use the 'onDisconnect()'
				// method to add a set which will only trigger once this
				// client has disconnected by closing the app,
				// losing internet, or any other means.
				await userStatusDatabaseRef
					?.onDisconnect()
					.set(isOfflineForDatabase)
					.then(async () => {
						// The promise returned from .onDisconnect().set() will
						// resolve as soon as the server acknowledges the onDisconnect()
						// request, NOT once we've actually disconnected:
						// https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

						// We can now safely set ourselves as 'online' knowing that the
						// server will mark us as offline once we lose connection.

						await userStatusDatabaseRef?.set({
							state: "online",
							isListening: true,
							firebaseId: uid,
							provider,
							action: "Connected",
							contentType,
							userAgent: navigator.userAgent,
						});
					});
			});
	}
};

export const initChannelPresence = (channelId: string, analyticsUserId: string, attendeeNumber: string = "") => {
	if (channelId && analyticsUserId) {
		const isOfflineForDatabase = {
			isOnline: false,
			attendeeNumber,
			displayName: authFirebase?.auth()?.currentUser?.displayName || "",
			uid: authFirebase?.auth()?.currentUser?.uid || "",
		};

		if (channelStatusDatabaseRef !== null) {
			//If the user already had a connection to other content, remove it
			channelStatusDatabaseRef?.set({ ...isOfflineForDatabase });
			//Remove the onDisconnect handler
			channelStatusDatabaseRef?.onDisconnect().cancel();
		}
		channelStatusDatabaseRef = firebase.database().ref(`/channel-presence/${channelId}/${analyticsUserId}`);

		channelStatusDatabaseRef.on("value", snap => {
			const val = snap.val();
			if (val?.isOnline === false) {
				channelStatusDatabaseRef?.set({
					isOnline: true,
					attendeeNumber,
					displayName: firebase?.auth()?.currentUser?.displayName || "",
					uid: firebase?.auth()?.currentUser?.uid || "",
				});
			}
		});
		firebase
			.database()
			.ref(".info/connected")
			.on("value", snapshot => {
				// If we're not currently connected, don't do anything.
				if (snapshot.val() === false) {
					return;
				}
				channelStatusDatabaseRef
					?.onDisconnect()
					.set(isOfflineForDatabase)
					.then(() => {
						channelStatusDatabaseRef?.set({
							isOnline: true,
							attendeeNumber,
							displayName: authFirebase?.auth()?.currentUser?.displayName || "",
							uid: authFirebase?.auth()?.currentUser?.uid || "",
						});
					});
			});
	}
};

export const initEventPresence = (eventNumber: string, analyticsUserId: string, attendeeNumber: string = "") => {
	if (eventNumber && analyticsUserId) {
		const isOfflineForDatabase = {
			isOnline: false,
			attendeeNumber,
			displayName: authFirebase?.auth()?.currentUser?.displayName || "",
			uid: authFirebase?.auth()?.currentUser?.uid || "",
		};

		if (channelStatusDatabaseRef !== null) {
			//If the user already had a connection to other content, remove it
			channelStatusDatabaseRef?.set({ ...isOfflineForDatabase });
			//Remove the onDisconnect handler
			channelStatusDatabaseRef?.onDisconnect().cancel();
		}
		channelStatusDatabaseRef = firebase.database().ref(`/event-presence/${eventNumber}/${analyticsUserId}`);

		firebase
			.database()
			.ref(".info/connected")
			.on("value", snapshot => {
				// If we're not currently connected, don't do anything.
				if (snapshot.val() === false) {
					return;
				}
				channelStatusDatabaseRef
					?.onDisconnect()
					.set(isOfflineForDatabase)
					.then(() => {
						channelStatusDatabaseRef?.set({
							isOnline: true,
							attendeeNumber,
							displayName: authFirebase?.auth()?.currentUser?.displayName || "",
							uid: authFirebase?.auth()?.currentUser?.uid || "",
						});
					});
			});
	}
};
