import { isEmpty } from "lodash";
import React from "react";
import { Content } from "../../../interfaces";
import { VideoCard } from "./VideoCard";
import * as Styled from "./VideoList.styled";

interface VideoListProps {
	clientKey?: string;
	channelKey?: string;
	onVideoEnd: () => void;
	relatedContents: Content[];
	upcomingContents: Content[];
	showRelatedTitle?: boolean;
	contentKey?: string;
}

export const VideoList: React.FC<VideoListProps> = ({
	clientKey,
	channelKey,
	contentKey,
	onVideoEnd,
	relatedContents,
	upcomingContents,
	showRelatedTitle = true,
}) => {
	return (
		<>
			{!isEmpty(upcomingContents) && (
				<Styled.Section>
					<h2>Upcoming</h2>
					<div className={`card-list ${!isEmpty(relatedContents) ? "horizontal-card-list" : ""}`}>
						{upcomingContents.map((content: Content) => (
							<VideoCard
								key={content.id}
								video={content}
								clientKey={clientKey}
								channelKey={channelKey}
								onVideoEnd={onVideoEnd}
								contentKey={contentKey}
							/>
						))}
					</div>
				</Styled.Section>
			)}

			{!isEmpty(relatedContents) && (
				<Styled.Section>
					{showRelatedTitle && <h2>On Demand</h2>}
					<div className={`card-list ${!isEmpty(upcomingContents) ? "horizontal-card-list" : ""}`}>
						{relatedContents.map((content: Content) => (
							<VideoCard
								key={content.id}
								video={content}
								clientKey={clientKey}
								channelKey={channelKey}
								onVideoEnd={onVideoEnd}
								contentKey={contentKey}
							/>
						))}
					</div>
				</Styled.Section>
			)}
			{isEmpty(upcomingContents) && isEmpty(relatedContents) && !contentKey && (
				<Styled.Section className="no-results" style={{ textAlign: "center" }}>
					<i className="fal fa-tv-retro fa-3x m-bottom" />
					<h2>This channel contains no content</h2>
				</Styled.Section>
			)}
		</>
	);
};
