import styled from "styled-components";
import { PageTitle } from "../../";

interface ButtonProps {
	isExpanded: boolean;
}

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin-top: 40px;
`;

export const SectionHeader = styled(PageTitle)`
	text-align: left;
	margin: 0;
`;

export const ButtonContainer = styled.div<ButtonProps>`
	position: absolute;
	right: 0;
	display: flex;
	width: ${({ isExpanded }) => (isExpanded ? "auto" : "50px")};
	max-width: 100%;
	height: 45px;
	border-radius: 5px;
	transition: all 0.5s;
	overflow: hidden;
`;

export const Button = styled.div<ButtonProps>`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	min-width: 120px;
	background-color: ${({ isExpanded }) => (isExpanded ? "#4a525a" : "transparent")};
	margin: 0;
	border-left-width: 2px;
	border-color: #576069;
	cursor: pointer;
	color: white;
	transition: all 0.25s;

	&:hover {
		background-color: #1f2327;
	}
`;
