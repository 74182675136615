import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { TypedUseSelectorHook } from "../redux";
import { isChurchX } from "../utils/churchx";

import ChurchXLogo from "../pages/Dashboard/UI/DashboardHeader/church-x.svg";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const Splash = () => {
	const user = useTypedSelector(store => store.user);

	const [showLogo, setShowLogo] = useState(false);

	document.body.style.background = "linear-gradient(107deg,#333 0%,#111 100%)";
	document.body.style.color = "#eee";

	useEffect(() => {
		if (window.location?.pathname === "/") {
			window.open(`//${window.location.host}/dashboard`, "_self");
		}
		setTimeout(() => {
			setShowLogo(true);
		}, 3000);
	}, []);

	if ((user.isLoading || user.isLoadingAppUser || user.appUser) && !showLogo) {
		return null;
	}

	return (
		<div className="splash" style={styles.containerStyles}>
			<div style={styles.contentStyles}>
				{isChurchX ? (
					<img alt="Brushfire Logo" style={styles.imageStyles} src={ChurchXLogo} />
				) : (
					<img
						alt="Brushfire Logo"
						style={styles.imageStyles}
						src="/content/img/bf-logo-full-light-1400.png"
					/>
				)}
			</div>
			<div style={styles.footerStyles}>
				<div>
					&copy; {new Date().getFullYear()}{" "}
					<a style={{ fontWeight: "bold" }} href="https://brushfire.com/">
						brushfire.com
					</a>
				</div>
				<div>
					<a style={{ fontWeight: "bold" }} href="https://brushfire.com/about/streaming">
						About
					</a>
				</div>
			</div>
		</div>
	);
};

const styles = {
	containerStyles: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		height: "100vh",
		paddingLeft: 30,
		paddingRight: 30,
	} as React.CSSProperties,
	contentStyles: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	} as React.CSSProperties,
	imageStyles: {
		width: "100%",
		maxWidth: 500,
	} as React.CSSProperties,
	footerStyles: {
		display: "flex",
		height: 50,
		width: "100%",
		maxWidth: 600,
		alignSelf: "center",
		alignItems: "center",
		justifyContent: "space-between",
	} as React.CSSProperties,
};

export default Splash;
