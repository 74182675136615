import firebase, { firestore } from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button as BSButton, Col, Modal as BSModal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Chat from "../../../../components/Chat";
import { Message } from "../../../../components/Chat/Chat.service";
import LiveBadge from "../../../../components/LiveBadge";
import Video from "../../../../components/Video/Video";
import firebaseApp from "../../../../firebase";
import { useWindowWidth } from "../../../../hooks";
import { ChatRequest, ChatRequestStatus, Content, ReactionIcon, Theme } from "../../../../interfaces";
import { TypedUseSelectorHook } from "../../../../redux";
import { hideModalAlert } from "../../../../redux/Chat/actions";
import { HorizontalRule, SectionedPageContainer, Well } from "../../UI";
import InteractionManager from "./InteractionManager";
import RequestButton from "./RequestButton";
import userHasRole from "../../../../services/userHasRole";

const db = firebaseApp.firestore();

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
}

const theme: Theme = {
	textColor: "#24272b",
	buttonTextColor: "#FFFFFF",
	buttonBackgroundColor: "#004bed",
	buttonHoverBackgroundColor: "#153d97",
	backgroundColor: "#b1bac5",
	backgroundImage: "",
	wellTextColor: "#FFFFFF",
	wellBackgroundColor: "#24272b",
	navBackgroundColor: "#24272b",
	navLinkColor: "#FFFFFF",
	navLinkHoverColor: "#004bed",
};

interface MessageCounts {
	[chatId: string]: {
		count: number;
		lastId: string | null;
	};
}

const unsubscribes: (() => void)[] = [];

const HostManager: React.FC = () => {
	const [mountedTime, setMountedTime] = useState<number>(moment.now);
	const [contentData, setContentData] = useState<Content | null>(null);
	const [myChats, setMyChats] = useState<ChatRequest[] | null>(null);
	const [myEndedChats, setEndedChats] = useState<ChatRequest[] | null>(null);
	const [messageCounts, setMessageCounts] = useState<MessageCounts>({});
	const [messagesChanged, setMessagesChanged] = useState(false);
	const [requests, setRequests] = useState<ChatRequest[] | null>(null);
	const match: MatchParams = useRouteMatch<MatchParams>().params;

	const [selectedChat, setSelectedChat] = useState<string | null>(`hc_${match.contentId}`);
	const [stats, setStats] = useState({ maxLive: null, currentLive: null });
	const [reactionStats, setReactionStats] = useState<{ [key: string]: number }>({});
	const [currentPanel, setCurrentPanel] = useState("chat");
	const [chatPreview, setChatPreview] = useState<ChatRequest | undefined>(undefined);
	const [expandMyChats, setExpandMyChats] = useState(true);
	const [expandRequests, setExpandRequests] = useState(false);
	const [showMobileChatMenu, setShowMobileChatMenu] = useState(false);

	const isMobileAndTablet = useMediaQuery({ query: "(max-width: 992px)" });

	const user = useTypedSelector(state => state.user);
	const dashboard = useTypedSelector(store => store.dashboard);
	const { showModal, modalData } = useTypedSelector(store => store.chat);
	const { appUser, user: dashboardUser } = user;

	const dispatch = useDispatch();

	const width = useWindowWidth();

	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;

	useEffect(() => {
		db.collection("contents")
			.doc(match.contentId)
			.get()
			.then(data => {
				if (data.exists) {
					const content = data.data() as Content;
					setContentData(content);
				}
			});
	}, [setContentData, match]);

	useEffect(() => {
		const docRef = db
			.collection("analytics")
			.doc("contents")
			.collection("content-analytics")
			.doc(`ca_${match.contentId}`);
		docRef.onSnapshot(async () => {
			const doc = await docRef.get();
			const data = await doc.data();
			if (data) {
				setStats({ maxLive: data.maxLive, currentLive: data.currentLive });
			}
		});
	}, [db, match]);

	useEffect(() => {
		if (width >= 992 && currentPanel === "video") {
			setCurrentPanel("chat");
		}
	}, [width]);

	const updateChatList = () => {
		if (dashboardUser) {
			db.collection("chat")
				.doc("direct")
				.collection(match.contentId)
				.where("users", "array-contains", dashboardUser.uid)
				.onSnapshot(snapshot => {
					let payload: ChatRequest[] = [];
					let endedChats: ChatRequest[] = [];
					snapshot.forEach(doc => {
						const data = doc.data() as ChatRequest;
						const myStatus = data.userData[dashboardUser.uid].status;
						if (
							myStatus !== undefined &&
							(myStatus === ChatRequestStatus.active ||
								myStatus === ChatRequestStatus.directMessageRequest ||
								myStatus === ChatRequestStatus.directMessageActive)
						) {
							if (!data.acceptedTimestamp) {
								data.acceptedTimestamp = firestore.Timestamp.fromMillis(0);
							}
							if (data.createdBy === dashboardUser.uid || data.showRequest) {
								payload.push(data);
							}
						} else if (
							myStatus === ChatRequestStatus.ended ||
							myStatus === ChatRequestStatus.directMessageEnded
						) {
							if (!data.acceptedTimestamp) {
								data.acceptedTimestamp = firestore.Timestamp.fromMillis(0);
							}
							endedChats.push(data);
						}
					});

					// payload.sort((a, b) => {
					// 	//@ts-ignore
					// 	const diff = a.acceptedTimestamp.seconds - b.acceptedTimestamp.seconds;
					// 	if (diff < 0) {
					// 		return -1;
					// 	}
					// 	return 1;
					// });
					// endedChats.sort((a, b) => {
					// 	//@ts-ignore
					// 	const diff = a.acceptedTimestamp.seconds - b.acceptedTimestamp.seconds;
					// 	if (diff < 0) {
					// 		return -1;
					// 	}
					// 	return 1;
					// });
					payload = payload.sort((a, b) => {
						return (b.updatedAt ?? 0) - (a.updatedAt ?? 0);
					});
					endedChats = endedChats.sort((a, b) => {
						return (b.updatedAt ?? 0) - (a.updatedAt ?? 0);
					});
					setMyChats(payload);
					setEndedChats(endedChats);
					if (!selectedChat && payload.length > 0) {
						setSelectedChat(payload[0].id);
					}
				});
		}
	};

	const selectChat = (chatId: string) => {
		if (messageCounts[chatId]) {
			messageCounts[chatId].count = 0;
		}
		if (selectedChat && messageCounts[selectedChat]) {
			messageCounts[selectedChat].count = 0;
		}
		setSelectedChat(chatId);
	};

	const startChat = (request: ChatRequest) => {
		if (contentData && appUser) {
			const currentUser = firebaseApp.auth().currentUser;
			if (currentUser) {
				const docRef = db
					.collection("chat")
					.doc("direct")
					.collection(contentData.id)
					.doc(request.id);
				if (!request.hosts.includes(currentUser.uid)) {
					request.hosts.push(currentUser.uid);
				}
				if (!request.users.includes(currentUser.uid)) {
					request.users.push(currentUser.uid);
				}
				request.status = ChatRequestStatus.active;
				request.acceptedTimestamp = firebase.firestore.Timestamp.now();
				request.recipientUserName = `${appUser.firstName} ${appUser.lastName}`;
				request.userData[currentUser.uid] = {
					name: `${appUser.firstName} ${appUser.lastName}`,
					image: currentUser?.photoURL || null,
					muted: false,
					status: ChatRequestStatus.active,
				};
				Object.values(request.userData).forEach(x => {
					x.status = ChatRequestStatus.active;
				});
				docRef.set(request);

				selectChat(request.id);
			}
		}
	};

	const setCurrentChat = (chatRequest: ChatRequest) => {
		setCurrentPanel("chat");
		setSelectedChat(chatRequest.id);
	};

	const previewRequest = (request: ChatRequest) => {
		setChatPreview(request);
		setSelectedChat(request.id);
	};

	const selectCurrentChat = (id: string) => {
		const unreadMessages = { ...messageCounts };
		if (id !== "none" && unreadMessages[id]) {
			unreadMessages[id].count = 0;
			setMessageCounts(unreadMessages);
		}
		setChatPreview(undefined);
		setSelectedChat(id);
	};

	useEffect(() => {
		updateChatList();
	}, [appUser]);

	useEffect(() => {
		let unsubscribe: () => void = () => {};
		let requestUnsubscribe: () => void = () => {};
		if (contentData && contentData?.reactions?.enabled) {
			unsubscribe = db
				.collection("reactions")
				.doc("counts")
				.collection(contentData.id)
				.onSnapshot(docs => {
					const newReactionStats: { [key: string]: number } = {};
					docs.forEach(doc => {
						newReactionStats[doc.id] = doc.data().count;
					});
					setReactionStats(newReactionStats);
				});
		}
		if (contentData) {
			requestUnsubscribe = db
				.collection("chat")
				.doc("direct")
				.collection(contentData.id)
				.where("status", "==", 0)
				.onSnapshot(snapshot => {
					let payload: ChatRequest[] = [];
					snapshot.forEach(doc => {
						const d = doc.data() as ChatRequest;
						if (d.userName !== "Anonymous" && d.showRequest) {
							payload.push(d);
						}
					});
					payload.sort((a, b) => {
						const diff = a.timestamp.seconds - b.timestamp.seconds;
						if (diff < 0) {
							return -1;
						}
						return 1;
					});
					setRequests(payload);
				});
		}

		return () => {
			unsubscribe();
			requestUnsubscribe();
		};
	}, [contentData]);

	useEffect(() => {
		if (selectedChat !== "none" && messagesChanged) {
			setMessagesChanged(false);
			let newMessageCounts = Object.assign({}, messageCounts);
			if (dashboardUser) {
				myChats?.forEach(directChat => {
					if (directChat.userData[dashboardUser?.uid || ""]?.muted && newMessageCounts[directChat.id]) {
						newMessageCounts[directChat.id].count = 0;
					}
				});
			}
			if (selectedChat && newMessageCounts[selectedChat]) {
				newMessageCounts[selectedChat].count = 0;
				setMessageCounts(newMessageCounts);
			}
		}
	}, [messageCounts]);

	const onMessages = (chatId: string, messages: Message[]) => {
		let newMessageCounts = Object.assign({}, messageCounts);
		let found = newMessageCounts[chatId]?.lastId ? false : true;
		let count = 0;
		messages.forEach(message => {
			if (found && message.timestamp > mountedTime) {
				count++;
			}
			if (!found && newMessageCounts[chatId]?.lastId === message.id) {
				found = true;
			}
		});
		if (!newMessageCounts[chatId]) {
			newMessageCounts[chatId] = {
				count: 0,
				lastId: null,
			};
		}
		newMessageCounts[chatId].count += count;
		newMessageCounts[chatId].lastId = messages.length > 0 ? messages[messages.length - 1].id : null;
		setMessagesChanged(true);
		setMessageCounts(newMessageCounts);
	};

	const resetToChatPanel = () => {
		if (contentData) {
			if (selectedChat === "none") {
				setSelectedChat(`hc_${contentData.id}`);
			}
			setCurrentPanel("chat");
		}
	};

	if (!contentData || !appUser) {
		return <div>Loading</div>;
	}

	const reactionNumbers: { [key: string]: number } = {};
	Object.keys(reactionStats).forEach(key => {
		const arr = key.split("_");
		if (!reactionNumbers[arr[0]]) {
			reactionNumbers[arr[0]] = 0;
		}
		reactionNumbers[arr[0]] += reactionStats[key];
	});

	const allChats = myChats ? myChats.concat(requests ? requests : []) : requests ? requests : [];

	const isModerator =
		appUser?.globalRole === "owner" ||
		appUser?.globalRole === "admin" ||
		appUser?.globalRole === "superhost" ||
		appUser?.globalRole === "host" ||
		appUser?.clients[contentData.clientId] === "owner" ||
		appUser?.clients[contentData.clientId] === "admin" ||
		appUser?.clients[contentData.clientId] === "superhost" ||
		appUser?.clients[contentData.clientId] === "host";

	const sections = [
		{
			label: "Chat",
			key: "chat",
		},
		{
			label: "Interactions",
			key: "interactions",
		},
	];

	const footerLinks = [];
	if (isAdmin) {
		footerLinks.push({
			label: "Edit",
			href: `//${window.location.host}/dashboard/${contentData.clientId}/contents/${contentData.id}`,
			icon: "fal fa-pencil",
			key: "edit",
		});
	}
	footerLinks.push({
		label: "Analytics",
		href: `//${window.location.host}/dashboard/${contentData.clientId}/contents/${contentData.id}/analytics`,
		icon: "fal fa-analytics",
		key: "analytics",
	});
	footerLinks.push({
		label: "Host Panel",
		href: `//${window.location.host}/dashboard/${contentData.clientId}/contents/${contentData.id}/host`,
		icon: "fal fa-comments",
		key: "host",
	});
	const channels = dashboard.channelsData;
	if (channels && channels.length > 0) {
		const channel = channels.find((c) => dashboard.contentData?.channelIds.includes(c.id));
		if (channel) {
			footerLinks.push({
				label: "Preview",
				href: `//${window.location.host}/${dashboard.clientData?.key}/${channel.key}/${dashboard.contentData?.key}`,
				icon: "fal fa-globe",
				key: "preview",
			});
		}
	}

	if (isMobileAndTablet) {
		sections.unshift({
			label: "Video",
			key: "video",
		});
	}

	return (
		<SectionedPageContainer
			size="full"
			sections={sections}
			defaultSection={"chat"}
			footerLinks={footerLinks}
			pageSupertitle="Host Panel"
			pageTitle={contentData.name}
			htmlPageTitle="Content Host Panel"
			toggleSectionPanel={setCurrentPanel}
		>
			<ThemeProvider theme={theme}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end",
						padding: "0px 15px",
						marginBottom: "10px",
					}}
				>
					<div style={{ textAlign: "left", color: "white" }}>
						<p
							className="ellipsis"
							style={{
								margin: 0,
								fontSize: "20px",
								fontWeight: 100,
								width: "calc(100vw - 30px)",
								textTransform: "uppercase",
							}}
						>
							{currentPanel === "chat" ? (
								<div onClick={() => setShowMobileChatMenu(true)}>
									Chat <i className="fal fa-exchange show-mobile"></i>
								</div>
							) : currentPanel === "interactions" ? (
								"Interactions"
							) : (
								""
							)}
						</p>
					</div>
					<div className="host-live-badge">
						<LiveBadge contentData={contentData} showLiveNumber={true} />
					</div>
				</div>
				<Row>
					<Col
						xs={12}
						lg={9}
						xl={8}
						className={`dashboard-height scroll host-sidebar-wrapper ${
							currentPanel !== "video" ? "active" : ""
						}`}
					>
						<Well className="p-0 dashboard-height-with-title relative host-switch-panel m-0">
							<div
								className={`host-panel ${selectedChat !== "none" ? "active" : ""} ${
									currentPanel === "chat" ? "show" : ""
								}`}
							>
								{currentPanel === "chat" && (
									<Row>
										<Col
											xs={5}
											className={`host-chat-moderator-section border-right chats-column ${
												showMobileChatMenu ? "active" : ""
											}`}
										>
											<div className="chats-host">
												<h3>HOST CHAT</h3>
												<RequestButton
													key={`hc_${contentData.id}`}
													active={selectedChat === `hc_${contentData.id}`}
													onClick={() => {
														selectCurrentChat(`hc_${contentData.id}`);
														setShowMobileChatMenu(false);
													}}
													title="Host Chat"
													count={
														messageCounts[`hc_${contentData.id}`]
															? messageCounts[`hc_${contentData.id}`].count
															: 0
													}
													contentId={contentData.id}
												/>
											</div>

											{((myChats && myChats.length > 0) ||
												(myEndedChats && myEndedChats.length > 0)) && (
												<>
													<h3
														className="ellipsis chat-manager-heading"
														onClick={() => setExpandMyChats(!expandMyChats)}
													>
														MY CHATS{" "}
														<span className="show-mobile">
															{myChats && myChats?.length > 0 && (
																<div className="count-bubble">{myChats?.length}</div>
															)}
														</span>
														<i
															className={`show-mobile far fa-chevron-${
																expandMyChats ? "up" : "down"
															}`}
														></i>
													</h3>
													<div className={`chats-mine ${expandMyChats ? "active" : ""}`}>
														{myChats &&
															myChats.map(chat => {
																return (
																	<RequestButton
																		uid={
																			chat.users.filter(
																				x => x !== dashboardUser?.uid
																			)[0]
																		}
																		key={chat.id}
																		active={selectedChat === chat.id}
																		onClick={() => {
																			selectCurrentChat(chat.id);
																			setShowMobileChatMenu(false);
																		}}
																		title={
																			chat.createdBy === dashboardUser?.uid
																				? chat.recipientUserName || ""
																				: chat.userName || ""
																		}
																		count={
																			messageCounts[chat.id]
																				? messageCounts[chat.id].count
																				: 0
																		}
																		contentId={contentData.id}
																	/>
																);
															})}
														{myEndedChats && myEndedChats.length > 0 && (
															<>
																<HorizontalRule>Closed Chats</HorizontalRule>
																{myEndedChats.map(chat => {
																	return (
																		<RequestButton
																			uid={
																				chat.users.filter(
																					x => x !== dashboardUser?.uid
																				)[0]
																			}
																			key={chat.id}
																			active={selectedChat === chat.id}
																			onClick={() => {
																				selectCurrentChat(chat.id);
																				setShowMobileChatMenu(false);
																			}}
																			title={
																				chat.createdBy === dashboardUser?.uid
																					? chat.recipientUserName || ""
																					: chat.userName || ""
																			}
																			count={
																				messageCounts[chat.id]
																					? messageCounts[chat.id].count
																					: 0
																			}
																			contentId={contentData.id}
																		/>
																	);
																})}
															</>
														)}
													</div>
												</>
											)}

											<h3
												className="ellipsis chat-manager-heading"
												onClick={() => setExpandRequests(!expandRequests)}
											>
												Chat Requests{" "}
												<span className="show-mobile">
													{requests && requests?.length > 0 && (
														<div className="count-bubble">{requests?.length}</div>
													)}
												</span>
												<i
													className={`show-mobile far fa-chevron-${
														expandRequests ? "up" : "down"
													}`}
												></i>
											</h3>
											<div className={`chats-requests ${expandRequests ? "active" : ""}`}>
												{!requests || requests.length === 0 ? (
													<div>No Chat Requests</div>
												) : (
													<>
														{requests.map(request => (
															<RequestButton
																uid={request.users[0]}
																timestamp={request.timestamp}
																key={request.id}
																onClick={() => previewRequest(request)}
																title={request.userName}
																message={request.message}
																contentId={contentData.id}
																count={
																	messageCounts[request.id]
																		? messageCounts[request.id].count
																		: 0
																}
															/>
														))}
													</>
												)}
											</div>
										</Col>
										<Col xs={7} className="host-chat-section overflow-hidden">
											{myChats && (
												<>
													{/* <div
														className="show-mobile"
														onClick={() => selectCurrentChat("none")}
													>
														<h3 className="ellipsis cursor-pointer">
															<i className="fas fa-chevron-left m-right"></i>
															{allChats.find(c => c.id === selectedChat)?.userName ||
																"Host Chat"}
														</h3>
													</div>
													<div className="hide-mobile">
														<h3 className="ellipsis">
															{allChats.find(c => c.id === selectedChat)?.userName ||
																"Host Chat"}
														</h3>
													</div> */}
													<div className="chat-wrapper">
														<div
															key={`host-chat-${contentData.id}`}
															className={`chat-container ${
																selectedChat === `hc_${contentData.id}` ? "" : "hidden"
															}`}
														>
															{contentData && contentData.id && !user.isLoading && (
																<Chat
																	clientId={contentData.clientId}
																	id={`hc_${contentData.id}`}
																	isModerator={isModerator}
																	enableAnonymous={false}
																	onMessages={onMessages}
																	contentId={contentData.id}
																	isHostManager
																	content={contentData}
																	useDashboardAuth
																/>
															)}
														</div>
														{myChats.map(chat => {
															return (
																<div
																	key={`chat-${chat.id}`}
																	className={`chat-container ${
																		selectedChat === chat.id ? "" : "hidden"
																	}`}
																>
																	{contentData &&
																		contentData.id &&
																		!user.isLoading && (
																			<Chat
																				clientId={contentData.clientId}
																				id={chat.id}
																				isModerator={isModerator}
																				enableAnonymous={false}
																				onMessages={onMessages}
																				isDirect
																				contentId={contentData.id}
																				isHostManager
																				content={contentData}
																				useDashboardAuth
																				request={chat}
																			/>
																		)}
																</div>
															);
														})}
														{myEndedChats &&
															myEndedChats.map(chat => {
																return (
																	<div
																		key={`chat-${chat.id}`}
																		className={`chat-container ${
																			selectedChat === chat.id ? "" : "hidden"
																		}`}
																	>
																		{contentData &&
																			contentData.id &&
																			!user.isLoading && (
																				<Chat
																					clientId={contentData.clientId}
																					id={chat.id}
																					isModerator={isModerator}
																					enableAnonymous={false}
																					onMessages={onMessages}
																					isHostManager
																					chatClosed
																					contentId={contentData.id}
																					content={contentData}
																					useDashboardAuth
																					request={chat}
																					startChat={startChat}
																				/>
																			)}
																	</div>
																);
															})}
														{requests &&
															requests.map(request => {
																return (
																	<div
																		key={`chat-${request.id}`}
																		className={`chat-container ${
																			selectedChat === request.id ? "" : "hidden"
																		}`}
																	>
																		{contentData &&
																			contentData.id &&
																			!user.isLoading && (
																				<Chat
																					clientId={contentData.clientId}
																					id={request.id}
																					isModerator={isModerator}
																					enableAnonymous={false}
																					onMessages={onMessages}
																					startChat={startChat}
																					request={request}
																					isHostManager
																					isDirect
																					contentId={contentData.id}
																					content={contentData}
																					useDashboardAuth
																				/>
																			)}
																	</div>
																);
															})}
													</div>
												</>
											)}
										</Col>
									</Row>
								)}
							</div>
							{currentPanel === "interactions" && (
								<InteractionManager
									content={contentData}
									appUser={appUser}
									show={currentPanel === "interactions"}
								/>
							)}
						</Well>
						<Col md={4} className="dashboard-height"></Col>
					</Col>

					<Col
						xs={12}
						lg={3}
						xl={4}
						className={`dashboard-height scroll host-video-wrapper ${
							currentPanel === "video" ? "active" : ""
						}`}
					>
						<section className="well m-0">
							<div className="host-video-inner-wrapper">
								<div className="host-video-columns">
									<Video content={contentData} />
									{contentData.reactions?.enabled && (
										<div className="well-header">
											<div className="reaction-counts">
												<>
													{Object.values(ReactionIcon).map(icon => {
														if (contentData.reactions.icons[icon]) {
															return (
																<div
																	className="reaction-count"
																	key={ReactionIcon[icon]}
																>
																	<img
																		src={`/content/img/reactions/bfo-${ReactionIcon[icon]}.svg`}
																	/>
																	<span>{reactionNumbers[icon]}</span>
																</div>
															);
														}
													})}
												</>
											</div>
										</div>
									)}
								</div>
								<div className="well-content overflow-hidden">
									{/* <BannedUsers contentData={contentData} /> */}
									<Chat
										clientId={contentData.clientId}
										id={contentData.id}
										useChatProcessor={contentData.useChatProcessor}
										isModerator={isModerator}
										enableAnonymous={contentData.chat.enableAnonymous !== false}
										onMessages={onMessages}
										isHostManager
										contentId={contentData.id}
										content={contentData}
										useDashboardAuth
										canUseChatProcessor
										chatBlacklist={myChats
											?.filter(c => c.status === ChatRequestStatus.blocked)
											.flatMap(m => m.users)}
										setCurrentChat={setCurrentChat}
									/>
								</div>
							</div>
						</section>

						{/* <ChatRequests contentData={contentData} appUser={appUser} /> */}
					</Col>
				</Row>
				{!!modalData && !!showModal && (
					<BSModal show={showModal} onHide={() => dispatch(hideModalAlert(false))} className="default-modal">
						<BSModal.Header closeButton>
							<BSModal.Title>{modalData.title}</BSModal.Title>
						</BSModal.Header>
						<BSModal.Body>
							<p>{modalData.message}</p>
						</BSModal.Body>

						<BSModal.Footer>
							<BSButton
								style={{ width: "100%" }}
								variant="primary"
								onClick={() => {
									dispatch(hideModalAlert(false));
								}}
							>
								{modalData.closeButtonText}
							</BSButton>
						</BSModal.Footer>
					</BSModal>
				)}
			</ThemeProvider>
		</SectionedPageContainer>
	);
};

export default HostManager;
