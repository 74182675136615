import styled from "styled-components";
import {
	CustomWell as LinksCustomWell,
	CardContents as LinksCardContents,
	ActionsContainer as LinksActionsContainer,
} from "../Links/LinksSection.styled";
import { PageTitle } from "../../";

export const SectionHeader = styled(PageTitle)`
	text-align: left;
	margin: 0;
	font-size: 20px;
	text-transform: unset;
`;

export const CustomWell = styled(LinksCustomWell)`
	border: solid 1px #5a5a5a;
`;

export const CardContents = styled(LinksCardContents)`
	padding: 15px;
`;

export const ActionsContainer = styled(LinksActionsContainer)`
	width: 56px;
	padding-top: 10px;
`;

export const ResponseButton = styled.div<any>`
	padding: 10px 20px 10px 10px;
	margin-bottom: 10px;
	border-radius: 4px;

	background-color: ${({ isActive }) => (isActive ? "#dadada" : "#4a525a")};
	color: ${({ hasBeenSent, isActive, isDashboard }) => {
		if (isActive) {
			return "#24272b";
		} else if (isDashboard) {
			return "#fff";
		} else {
			return hasBeenSent ? "#676e75" : "#fff";
		}
	}};

	display: flex;
	align-items: center;
	justify-content: space-between;

	transition: all 0.2s ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: #dadada;
		color: #24272b;
	}
`;

export const ResponseTitle = styled.div``;
export const ResponseType = styled.div``;
