import React from "react";
import { useFormikContext } from "formik";
import { Prompt, useRouteMatch } from "react-router-dom";
import { isEmpty } from "lodash";

export interface PromptProps {
	isSaving: boolean;
}

const FormPrompt: React.FC<PromptProps> = ({ isSaving }) => {
	const formik = useFormikContext();
	const match = useRouteMatch();
	React.useEffect(() => {
		if (!isEmpty(formik.touched) && !isSaving) {
			window.onbeforeunload = () => "Are you sure you want to leave? You have unsaved changes.";
		}

		return () => {
			window.onbeforeunload = null;
		};
	}, [formik.touched, isSaving]);

	return (
		<Prompt
			message={location => {
				if (!location.pathname.includes(match.url) && !isEmpty(formik.touched) && !isSaving) {
					return "Are you sure you want to leave? You have unsaved changes.";
				} else {
					return true;
				}
			}}
		/>
	);
};

export default FormPrompt;
