import React, { useEffect, useRef, useContext } from "react";
import { map, isObject, isEmpty } from "lodash";
import { FormikErrors } from "formik";
import { isArray } from "util";
import { Context } from "../../../pages/Dashboard/UI/SectionedPageContainer/SectionedPageContainer";

export interface SummaryProps<T = any> {
	errors: FormikErrors<T>;
	forceFocus?: boolean;
	handleFormSections?: boolean;
}

interface ErrorObject {
	fieldName: string;
	fieldError: string;
}

const getErrorsFromObject = (error: any, parentKey: string): ErrorObject[] => {
	const allErrors: ErrorObject[] = [];

	if (error && isObject(error) && !isArray(error)) {
		for (let [key, value] of Object.entries(error)) {
			let nestedErrors: ErrorObject[] = [];

			if (isArray(value)) {
				nestedErrors = getErrorsFromArrayOfObjects(value, `${parentKey}.${key}`);
			} else if (isObject(value) && !isArray(value)) {
				nestedErrors = getErrorsFromObject(value, `${parentKey}.${key}`);
			} else {
				allErrors.push({ fieldName: `${parentKey}.${key}`, fieldError: value });
			}
			nestedErrors.forEach((e: ErrorObject) => {
				allErrors.push(e);
			});
		}
	}

	return allErrors;
};

const getErrorsFromArrayOfObjects = (errors: any[], parentKey: string): ErrorObject[] => {
	const allErrors: ErrorObject[] = [];
	errors.forEach((error: any, index: number) => {
		allErrors.push(...getErrorsFromObject(error, `${parentKey}[${index}]`));
	});

	return allErrors;
};

const Summary: React.FC<SummaryProps> = ({ errors, forceFocus = false, handleFormSections }) => {
	const summaryRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		summaryRef.current?.focus();
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	useEffect(() => {
		summaryRef.current?.focus();
	}, [forceFocus]);

	const { setNextSection, activeSection } = useContext(Context);

	const errorClick = (e: any) => {
		e.preventDefault();
		if (handleFormSections) {
			const { href } = e.target;
			const id = href.substring((href.indexOf("#") as number) + 1, href.length);
			const el = document.getElementById(id);
			const closest = el?.closest(".form-section-wrapper");
			const next = closest?.getAttribute("data-key");
			if (next && next !== activeSection) {
				setNextSection(next);
				setTimeout(() => {
					document.getElementById(id)?.focus();
				}, 350);
			} else {
				document.getElementById(id)?.focus();
			}
		} else {
			const { href } = e.target;
			const id = href.substring((href.indexOf("#") as number) + 1, href.length);
			document.getElementById(id)?.focus();
		}
	};

	return (
		<div className="summary-box" ref={summaryRef} role="group" tabIndex={-1}>
			<div className="summary-title-wrapper">
				<i className="far fa-info-circle" />
				<h3>There were some problems with the form</h3>
			</div>
			<ul className="ta-left">
				{map(errors, (error, key) => {
					let errorElements: ErrorObject[] = [];
					if (isObject(error) && isArray(error)) {
						errorElements = getErrorsFromArrayOfObjects(error, key);
					} else if (isObject(error)) {
						errorElements = getErrorsFromObject(error, key);
					}

					if (!isEmpty(errorElements)) {
						return errorElements.map((obj: ErrorObject) => {
							return (
								<li key={obj.fieldName}>
									<a href={`#${obj.fieldName}`} onClick={errorClick}>
										{obj.fieldError} <i className="fal fa-long-arrow-right" />
									</a>
								</li>
							);
						});
					} else {
						return (
							<li key={key}>
								<a href={`#${key}`} onClick={errorClick}>
									{error} <i className="fal fa-long-arrow-right" />
								</a>
							</li>
						);
					}
				})}
			</ul>
		</div>
	);
};

export default Summary;
