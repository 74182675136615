import React, { useContext } from "react";
import { isEmpty } from "lodash";
import Links from "../Links";
import { logEvent } from "../../services/analytics";
import { Content, Panels, ChatRequest, CustomPanel } from "../../interfaces";
import { MobilePanelsContext } from "./MobilePanelBar";
import * as Styled from "./MobilePanelsMenu.styled";
import ShareButton from "../ShareButton";

interface MobilePanelsMenuProps {
	content: Content;
	showVideoList: boolean;
	openPanel: () => void;
	setSelectedPanel: (panel: Panels) => void;
	newInteraction: boolean;
	directChats: boolean;
	selectExistingHostChat: () => void;
	newDirectChats: boolean;
	newChats: boolean;
	customPanel: CustomPanel | undefined;
}

const MobilePanelsMenu: React.FC<MobilePanelsMenuProps> = ({
	content,
	showVideoList,
	openPanel,
	setSelectedPanel,
	newInteraction,
	directChats,
	selectExistingHostChat,
	newDirectChats,
	newChats,
	customPanel,
}) => {
	const { menuOpen, setMenuOpen, interactionViewed, setInteractionViewed, panelBarHeight } = useContext(
		MobilePanelsContext
	);

	const logEventTogglePanel = () => {
		if (menuOpen) {
			logEvent("close_panel");
		} else {
			logEvent("open_panel");
		}
	};

	const shareUrl = `${document.location.origin}/share${document.location.pathname}`;

	return (
		<Styled.Menu className={menuOpen ? "open" : ""} panelBarHeight={panelBarHeight}>
			<Styled.MenuItemWrapper>
				<Styled.PanelContainer>
					<Styled.PanelButton
						onClick={() => {
							openPanel();
							setSelectedPanel(Panels.info);
							setMenuOpen(false);
							logEventTogglePanel();
						}}
					>
						<i className={`fal fa-info-circle`} />
						{content.name}
					</Styled.PanelButton>
					{content?.share?.enabled && (
						<ShareButton url={shareUrl} defaultMessage={content?.share.defaultMessage}>
							<Styled.PanelButton
								onClick={() => {
									setMenuOpen(false);
								}}
							>
								<span className="icon-wrapper">
									<i className="fal fa-share" />
								</span>
								Share
							</Styled.PanelButton>
						</ShareButton>
					)}
					{content?.chat.enabled && !!content?.startsAt && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_chat");
								openPanel();
								setSelectedPanel(Panels.chat);
								setMenuOpen(false);
							}}
						>
							<span className={newChats ? "icon-wrapper has-activity" : "icon-wrapper"}>
								<i className={`fal fa-comments`} />
							</span>
							Chat
						</Styled.PanelButton>
					)}
					{directChats && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_direct_chat");
								openPanel();
								setSelectedPanel(Panels.directChat);
								setMenuOpen(false);
							}}
						>
							<span className={newDirectChats ? "icon-wrapper has-activity" : "icon-wrapper"}>
								<i className={`fal fa-comment-lines`} />
							</span>
							Direct Chat
						</Styled.PanelButton>
					)}
					{content?.bible.enabled && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_bible");
								openPanel();
								setSelectedPanel(Panels.bible);
								setMenuOpen(false);
							}}
						>
							<i className={`fal fa-bible`} />
							Bible
						</Styled.PanelButton>
					)}
					{content?.notes.enabled && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_notes");
								openPanel();
								setSelectedPanel(Panels.notes);
								setMenuOpen(false);
							}}
						>
							<i className={`fal fa-sticky-note`} /> Notes
						</Styled.PanelButton>
					)}
					{!!(content?.schedule && content?.schedule.enabled) && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_schedule");
								openPanel();
								setSelectedPanel(Panels.schedule);
								setMenuOpen(false);
							}}
						>
							<i className={`fal fa-calendar-alt`} /> Schedule
						</Styled.PanelButton>
					)}
					{showVideoList && (
						<Styled.PanelButton
							onClick={() => {
								openPanel();
								setSelectedPanel(Panels.videos);
								setMenuOpen(false);
							}}
						>
							<i className={`fal fa-film`} />
							Videos
						</Styled.PanelButton>
					)}
					{customPanel?.panel && (
						<Styled.PanelButton
							onClick={() => {
								openPanel();
								setSelectedPanel(Panels.custom);
								setMenuOpen(false);
							}}
						>
							<i className={`fal ${customPanel.panel.icon}`} />
							{customPanel.panel.title}
						</Styled.PanelButton>
					)}
					{newInteraction && (
						<Styled.PanelButton
							onClick={() => {
								logEvent("open_questions");
								openPanel();
								setSelectedPanel(Panels.interactions);
								setMenuOpen(false);
								setInteractionViewed(true);
							}}
						>
							<span className={interactionViewed ? "icon-wrapper" : "icon-wrapper has-activity"}>
								<i className={`fal fa-comment-alt-exclamation`} />
							</span>
							Interactions
						</Styled.PanelButton>
					)}
				</Styled.PanelContainer>
				{!isEmpty(content.links) && (
					<Styled.LinkContainer>
						{
							<Links
								mobile
								content={content}
								selectExistingHostChat={selectExistingHostChat}
								customPanel={customPanel}
							/>
						}
					</Styled.LinkContainer>
				)}
			</Styled.MenuItemWrapper>
		</Styled.Menu>
	);
};

export default MobilePanelsMenu;
