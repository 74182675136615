import styled from "styled-components";
import { Tooltip } from "react-bootstrap";
import isLightOrDark from "../../utils/isLightOrDark";
import { darken, lighten } from "polished";

export const style = {
	ReactionsWrapper: styled.div`
		position: relative;
	`,
	ReactionsIcons: styled.div`
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		transform: translate(0, -7px);
		&::before {
			content: "";
			display: inline-block;
			width: 100%;
			height: 8px;
			position: absolute;
			left: 0;
			top: -8px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}
		@media screen and (max-width: 992px) {
			background-color: ${({ theme }) => {
				return isLightOrDark(theme.wellBackgroundColor) === "light"
					? darken(0.15, theme.wellBackgroundColor)
					: lighten(0.45, theme.wellBackgroundColor);
			}};
			&::before {
				content: "";
				display: inline-block;
				width: 100%;
				height: 5px;
				position: absolute;
				left: 0;
				top: -5px;
				background-color: ${({ theme }) => {
					return isLightOrDark(theme.wellBackgroundColor) === "light"
						? darken(0.15, theme.wellBackgroundColor)
						: lighten(0.45, theme.wellBackgroundColor);
				}};
			}
		}
	`,
	ReactionFlowIcons: styled.div`
		margin: -14px;
		&::before {
			content: "";
			width: 100%;
			height: 15px;
			position: absolute;
			top: 5px;
			left: 0;
			background: ${({ theme }) => theme.wellBackgroundColor};
			z-index: 99;
		}
		@media screen and (max-width: 992px) {
			margin: -11px;
			&::before {
				background-color: ${({ theme }) => {
					return isLightOrDark(theme.wellBackgroundColor) === "light"
						? darken(0.15, theme.wellBackgroundColor)
						: lighten(0.45, theme.wellBackgroundColor);
				}};
			}
		}
	`,
	ReactionMuteIcon: styled.div`
	display: inline-block;
	width: 30px;
	margin-left: 5px;
	padding-left: 5px;
	border-left: solid 2px ${({ theme }) =>
		isLightOrDark(theme.wellBackgroundColor) === "light"
			? darken(0.15, theme.wellBackgroundColor)
			: lighten(0.25, theme.wellBackgroundColor)}
	}};
	@media screen and (max-width: 992px) {
		border-left: solid 2px ${({ theme }) =>
			isLightOrDark(theme.wellBackgroundColor) === "light"
				? darken(0.2, theme.wellBackgroundColor)
				: lighten(0.3, theme.wellBackgroundColor)}
	}};
	}
	`,
};
