import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { BIBLE_COM_REGEX } from "../../../../components/Bible";
import { Content } from "../../../../interfaces";

interface Passage {
	language: string;
	book: string;
	chapter: string;
	translation: string;
}

export interface ContentFormInterface extends Content {
	prePlayDuration: number;
	bible: {
		enabled: boolean;
		passage: {
			language: string;
			book: string;
			chapter: string;
			translation: string;
		};
	};
	chat: {
		enabled: boolean;
		enableAnonymous: boolean;
		requireSignin: boolean;
		enablePreview: boolean;
		enableDirectMessaging: boolean;
	};
}

export const splitPassageUrl = (url = ""): Passage => {
	const defaultObj = {
		language: "en",
		book: "GEN",
		chapter: "1",
		translation: "111",
	};

	if (url) {
		const match = url.match(BIBLE_COM_REGEX);

		if (match) {
			return {
				language: match[3] ? match[3].slice(1) : "en",
				book: match[7],
				chapter: match[8],
				translation: match[5].slice(1),
			};
		} else {
			return defaultObj;
		}
	} else {
		return defaultObj;
	}
};

export const buildPassageUrl = (passage: Passage): string => {
	return `https://www.bible.com/${passage.language}/bible/${passage.translation}/${passage.book}.${passage.chapter}`;
};

export const initialFormValues: ContentFormInterface = {
	id: uuidv4(),
	clientId: "",
	channelId: "",
	channelIds: [],
	name: "",
	key: "",
	description: "",
	startsAt: moment()
		.seconds(0)
		.milliseconds(0)
		.toDate(),
	// opensAt: new Date(),
	// closesAt: new Date(),
	originalAirDate: new Date(),
	prePlayDuration: 0,
	duration: 0,
	thumbImage: "",
	src: "",
	chat: {
		enabled: true,
		enableAnonymous: true,
		enablePreview: true,
		requireSignin: false,
		enableDirectMessaging: true,
	},
	bible: {
		enabled: true,
		passage: {
			language: "en",
			book: "GEN",
			chapter: "1",
			translation: "111",
		},
	},
	notes: {
		enabled: false,
		defaultContent: "",
	},
	links: [],
	socialLinks: [],
	videoProvider: 0,
	interactions: [],
	showLiveStats: false,
	reactions: {
		enabled: true,
		icons: { clap: true, heart: true, pray: true, raiseHands: true },
	},
	tags: [],
	relatedContentIds: [],
	convertToOnDemand: {
		enabled: false,
		name: "",
		description: "",
		channelIds: [],
	},
	hasConvertedToOnDemand: false,
	videoAccessRuleEnabled: false,
	rules: [
		{
			attendeeTypeIds: [],
			fields: {},
		},
	],
	schedule: {
		enabled: true,
	},
	share: {
		enabled: true,
		defaultMessage: "",
	},
	initialSelectedPanel: 0,
	analyticsProcessed: 0,
};
