import * as DashboardTypes from "./types";

const initialState: DashboardTypes.DashboardState = {
	clientId: undefined,
	channelId: undefined,
	contentId: undefined,
};

export function dashboardReducer(
	state = initialState,
	action: DashboardTypes.DashboardActionTypes
): DashboardTypes.DashboardState {
	switch (action.type) {
		case DashboardTypes.SET_CLIENT_DATA:
			return Object.assign({}, state, {
				clientData: action.payload,
			});
		case DashboardTypes.SET_CLIENT_DATA_LOADING:
			return Object.assign({}, state, {
				clientDataLoading: action.payload,
			});
		case DashboardTypes.SET_CLIENT_ID:
			return Object.assign({}, state, {
				clientId: action.payload,
			});

		case DashboardTypes.SET_CHANNEL_DATA:
			return Object.assign({}, state, {
				channelData: action.payload,
			});
		case DashboardTypes.SET_CHANNEL_DATA_LOADING:
			return Object.assign({}, state, {
				channelDataLoading: action.payload,
			});
		case DashboardTypes.SET_CHANNEL_ID:
			return Object.assign({}, state, {
				channelId: action.payload,
			});
		case DashboardTypes.SET_CHANNELS_DATA:
			return Object.assign({}, state, {
				channelsData: action.payload,
			});
		case DashboardTypes.SET_CHANNELS_DATA_LOADING:
			return Object.assign({}, state, {
				channelsDataLoading: action.payload,
			});

		case DashboardTypes.SET_CONTENT_DATA:
			return Object.assign({}, state, {
				contentData: action.payload,
			});
		case DashboardTypes.SET_CONTENT_DATA_LOADING:
			return Object.assign({}, state, {
				contentDataLoading: action.payload,
			});
		case DashboardTypes.SET_CONTENT_ID:
			return Object.assign({}, state, {
				contentId: action.payload,
			});
		case DashboardTypes.SET_CONTENTS_DATA:
			return Object.assign({}, state, {
				contentsData: action.payload,
			});
		case DashboardTypes.SET_CONTENTS_DATA_LOADING:
			return Object.assign({}, state, {
				contentsDataLoading: action.payload,
			});

		case DashboardTypes.SET_TAGS_DATA:
			return Object.assign({}, state, { tagsData: action.payload });
		case DashboardTypes.SET_TAGS_DATA_LOADING:
			return Object.assign({}, state, { tagsDataLoading: action.payload });

		default:
			return state;
	}
}
