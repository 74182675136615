export const generateRandomString = (
	length: number,
	includeSymbols: boolean = false,
	mixCase: boolean = false
): string => {
	// prettier-ignore
	const symbols = ["A", "E", "I", "L", "O", "S", "U", "Z", "1", "0", "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "[", "]", "{", "}", "_", "-", "+", "=", "|", '\\', "/", "<", ">", ",", ".", "?", ":", ";", "'"];
	// prettier-ignore
	const letters = ["B", "C", "D", "F", "G", "H", "J", "K", "M", "N", "P", "Q", "R", "T", "V", "W", "X", "Y"];
	// prettier-ignore
	const numbers = ["2", "3", "4", "5", "6", "7", "8", "9"];

	let str: string = "";

	for (let intCount = 0; intCount <= length - 1; intCount++) {
		let mod = intCount % 3;

		if (mod === 0 && includeSymbols) {
			let symbol = symbols[generateRandomNumber(0, 40)];
			str += mixCase && intCount % 2 == 0 ? symbol : symbol.toLowerCase();
		} else if (mod === 0 || mod === 1) {
			let letter = letters[generateRandomNumber(0, 17)];
			str += mixCase && intCount % 2 == 0 ? letter : letter.toLowerCase();
		} else {
			let number = numbers[generateRandomNumber(0, 7)];
			str += number;
		}
	}

	return str;
};

export const generateRandomNumber = (min: number, max: number): number => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};
