import { isEqual, orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "../../components/Footer/Dashboard/Footer";
import { hideLoader, showLoader } from "../../components/GlobalLoader";
import GlobalStyle from "../../components/GlobalStyle";
import Header from "../../components/Header";
import { PaywallContainer, PaywallFormWrapper } from "../../components/Paywall/Paywall.styled";
import firebaseApp from "../../firebase";
import { Channel, Client, PaywallGlobalAccess, Theme } from "../../interfaces";
import { CustomDomainContext } from "../../navigation/PublicRoutes";
import { TypedUseSelectorHook } from "../../redux";
import api from "../../services/api";
import { clientFormInitialValues } from "../Dashboard/Clients/ClientForm/helpers";
import { checkAvailability } from "../../pages/Channel/utils";

interface AttendeeLandingPageProps {}

interface RouteParams {
	eventNumber: string;
	attendeeNumber?: string;
}

interface ChannelDataPayload {
	channels: Channel[];
	clients: Client[];
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const db = firebaseApp.firestore();

const AttendeeLandingPage: React.FC<AttendeeLandingPageProps> = () => {
	const user = useTypedSelector(store => store.user);
	const customDomainData = useContext(CustomDomainContext);
	let { eventNumber, attendeeNumber } = useParams<RouteParams>();
	const [theme, setTheme] = useState<Theme>(
		Object.assign({}, clientFormInitialValues.theme, { backgroundColor: "#24272b" }) as Theme
	);
	const [client, setClient] = useState<Client>();
	const [clients, setClients] = useState<Client[]>();
	const [channels, setChannels] = useState<Channel[]>();
	const [error, setError] = useState<string>();
	const [errorIcon, setErrorIcon] = useState<string>("fa-user-clock");

	useEffect(() => {
		showLoader();
	}, []);

	useEffect(() => {
		if (
			(channels?.length ?? 0) > 0 &&
			channels?.every(channel => checkAvailability(channel.showAt, channel.hideAt) !== "available")
		) {
			setErrorIcon("fa-tv-retro");

			if (channels?.every(channel => checkAvailability(channel.showAt, channel.hideAt) === "late")) {
				setError("This content has ended");
			} else {
				setError("This content will be available at a later date");
			}
		}
	}, [channels]);

	const handleAttendeeData = (attendeeData: PaywallGlobalAccess, filteredChannels: Channel[], clients: Client[]) => {
		const myChannels = filteredChannels.filter(
			chan =>
				chan.brushfireEventIds?.find(bf => bf.eventNumber === eventNumber)?.attendeeTypes[
					attendeeData.attendeeData.TypeId
				]?.numberOfScreens
		);
		if (myChannels.length === 1) {
			const contentRoute = customDomainData
				? `/${myChannels[0].key}${attendeeNumber ? `?a=${attendeeNumber}` : ""}`
				: `/${clients.find(x => x.id === myChannels[0].clientId)?.key}/${myChannels[0].key}${
						attendeeNumber ? `?a=${attendeeNumber}` : ""
				  }`;
			window.location.href = contentRoute;
		} else {
			if (!myChannels.length) {
				setError("This attendee number does not belong to this content.");
				setErrorIcon("fa-user-times");
			}
			setClients(clients);
			setChannels(myChannels);
		}
	};

	useEffect(() => {
		api.getChannelDataByEventNumber(eventNumber)
			.then(data => {
				const { clients, channels } = (data as { data: ChannelDataPayload }).data;
				channels.forEach(c => {
					c.theme = Object.assign({}, clients.find(cl => cl.id === c.clientId)?.theme, c.theme);
				});
				let filteredChannels = orderBy(channels, ["displayOrder", "name"], ["asc", "asc"]);

				if (clients.length === 1) {
					if (clients[0].customDomain !== customDomainData?.customDomain) {
						//If there is a custom domain, redirect to it
						window.location.href = `https://${clients[0].customDomain}/attend/${eventNumber}${
							attendeeNumber ? `/${attendeeNumber}` : ""
						}`;
					}
					setClient(clients[0]);
					if (filteredChannels.length !== 1) {
						setTheme(clients[0].theme);
					}
					if (attendeeNumber) {
						localStorage.setItem(`paywall-attendee-number-${clients[0].id}`, attendeeNumber);
					}
				} else if (clients.length === 0) {
					//This should never happen if it's a valid eventNumber
					setError("Event Not Found!");
					setErrorIcon("fa-calendar-times");
				} else {
					clients.forEach(client => {
						if (attendeeNumber) {
							localStorage.setItem(`paywall-attendee-number-${client.id}`, attendeeNumber);
						}
					});
				}

				if (filteredChannels.length === 1) {
					if (!attendeeNumber) {
						const contentRoute = customDomainData
							? `/${filteredChannels[0].key}${attendeeNumber ? `?a=${attendeeNumber}` : ""}`
							: `/${clients.find(x => x.id === filteredChannels[0].clientId)?.key}/${
									filteredChannels[0].key
							  }${attendeeNumber ? `?a=${attendeeNumber}` : ""}`;
						window.location.href = contentRoute;
					}
					setTheme(filteredChannels[0].theme as Theme);
				} else if (filteredChannels.length > 1) {
					if (
						filteredChannels[0].theme &&
						filteredChannels.every(
							x => x.theme && filteredChannels[0].theme && isEqual(x.theme, filteredChannels[0].theme)
						) &&
						Object.keys(filteredChannels[0].theme).length > 0
					) {
						setTheme(filteredChannels[0].theme as Theme);
					}
				} else if (channels.length === 1) {
					setTheme(channels[0].theme as Theme);
				}

				//Check if attendee data exists
				if (attendeeNumber && isNaN(+attendeeNumber) === false) {
					db.collection("paywall_access")
						.doc(attendeeNumber)
						.get()
						.then(doc => {
							if (doc.exists) {
								const attendeeData = doc.data() as PaywallGlobalAccess;
								handleAttendeeData(attendeeData, channels, clients);
							} else {
								//We could try and get the bf details here, but we don't want to authorize them in every channel

								if (attendeeNumber && attendeeNumber.length >= 8 && !isNaN(parseInt(attendeeNumber))) {
									api.getAttendeeNumberData(attendeeNumber)
										.then(data => {
											const attendeeData = data.data.paywallAccess as PaywallGlobalAccess;
											if (attendeeData) {
												handleAttendeeData(attendeeData, channels, clients);
											} else {
												window.location.href = `/attend/${eventNumber}/`;
												setClients(clients);
												setChannels(filteredChannels);
											}
										})
										.catch(() => {
											setClients(clients);
											setChannels(filteredChannels);
										});
								} else {
									window.location.href = `/attend/${eventNumber}/`;
									setClients(clients);
									setChannels(filteredChannels);
								}
							}
						});
				} else {
					setClients(clients);
					setChannels(filteredChannels);
				}
			})
			.finally(() => {
				hideLoader();
			});
	}, [eventNumber]);

	if (!clients || !channels) {
		return null;
	}

	let header = null;
	if (client) {
		header = <Header client={client} authProviders={client.authProviders} />;
	}

	return (
		<div className={`content-wrapper client-page${header ? "" : " headerless"}`}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				{header}
				<div className="cards footer-shim">
					{/* <h1
						style={{
							fontSize: isMobileOnly ? "1.5rem" : "2.5rem",
							padding: isMobileOnly ? "20px 10px 5px 10px" : "10px",
						}}
					>
						{client.name}
					</h1> */}
					<div className="card-list">
						{channels.length === 0 || !!error ? (
							<PaywallContainer>
								<PaywallFormWrapper
									hasThumbImage={false}
									className="absolute-center"
									style={{ zIndex: 900 }}
								>
									<div className="paywall-content-container ta-center">
										<i className={`fal ${errorIcon} fa-4x m-bottom-medium`}></i>
										<br />
										<h4>
											{error
												? error
												: "This isn’t quite ready yet! Check back closer to the event!"}
										</h4>
									</div>
								</PaywallFormWrapper>
							</PaywallContainer>
						) : (
							<>
								{channels.map(channel => {
									const contentRoute = customDomainData
										? `/${channel.key}${attendeeNumber ? `?a=${attendeeNumber}` : ""}`
										: `/${clients.find(x => x.id === channel.clientId)?.key}/${channel.key}${
												attendeeNumber ? `?a=${attendeeNumber}` : ""
										  }`;
									return checkAvailability(channel.showAt, channel.hideAt) === "available" ? (
										<a
											href={contentRoute}
											key={channel.id}
											className="card"
											style={{
												backgroundColor: theme.wellBackgroundColor,
												color: theme.wellTextColor,
											}}
										>
											<div
												className="card-image-mobile"
												style={{
													backgroundImage: `url('${channel.thumbImage ||
														"/content/img/channel-default@2x.png"}')`,
												}}
											/>
											<div className="card-info">
												<h2>{channel.name}</h2>
											</div>
										</a>
									) : null;
								})}
							</>
						)}
					</div>
				</div>
				<Footer appUser={user?.appUser} client={client} />
			</ThemeProvider>
		</div>
	);
};

export default AttendeeLandingPage;
