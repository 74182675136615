import React from "react";
import moment from "moment";

import { Interaction } from "../../../../../interfaces";

import * as Styled from "./InteractionButton.styled";

interface InteractionButtonProps {
	active?: boolean;
	interaction: Interaction;
	onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
	isDashboard?: boolean;
}

export const InteractionButton: React.FC<InteractionButtonProps> = ({
	active = false,
	interaction,
	onClick,
	isDashboard = false,
}) => {
	const hasBeenSent = !!interaction.showAt;
	const showAtText = interaction?.showAt ? moment(interaction.showAt).format("MM/DD/YYYY hh:mm A") : "";

	return (
		<Styled.Container hasBeenSent={hasBeenSent} isActive={active} onClick={onClick} isDashboard={isDashboard}>
			<Styled.InteractionDetails hasBeenSent={hasBeenSent} isDashboard={isDashboard}>
				<Styled.InteractionTitle>
					{isDashboard && !interaction.interactionTitle ? "New Interaction" : interaction.interactionTitle}
				</Styled.InteractionTitle>
				{!isDashboard && (
					<Styled.InteractionQuestion>{stripHTML(interaction.interactionText)}</Styled.InteractionQuestion>
				)}
			</Styled.InteractionDetails>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
				{hasBeenSent && (
					<Styled.InteractionSent>
						<i className="fas fa-paper-plane" title="Sent!"></i>
					</Styled.InteractionSent>
				)}
				{isDashboard && hasBeenSent && (
					<div>
						<small>Sent at {showAtText}</small>
					</div>
				)}
			</div>
		</Styled.Container>
	);
};

const stripHTML = (html: string) => {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};
