import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import uuidValidate from "uuid-validate";
import { useEffect } from "react";

import { TypedUseSelectorHook } from "../../redux";
import {
	fetchClient,
	fetchChannel,
	fetchContent,
	fetchChannels,
	fetchContents,
	fetchTags,
} from "../../services/dataHelpers";

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const DashboardLogic: React.FC = () => {
	const dispatch = useDispatch();
	const match: MatchParams = useRouteMatch<MatchParams>().params;
	const { clientId, channelId, contentId } = match;

	const dashboard = useTypedSelector(store => store.dashboard);
	const appUser = useTypedSelector(store => store.user.appUser);

	useEffect(() => {
		if (clientId && clientId !== dashboard.clientId && uuidValidate(clientId)) {
			fetchClient(clientId, dispatch, appUser);
			fetchChannels(clientId, dispatch);
			fetchContents(clientId, dispatch);
			fetchTags(clientId, dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId]);
	useEffect(() => {
		if (channelId && channelId !== dashboard.channelId && uuidValidate(channelId)) {
			fetchChannel(channelId, dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channelId]);
	useEffect(() => {
		if (contentId && contentId !== dashboard.contentId && uuidValidate(contentId)) {
			fetchContent(contentId, dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	return null;
};

export default DashboardLogic;
