export interface ChatRequest {
	id: string;
	hosts: string[];
	users: string[];
	createdBy: string;
	userName: string;
	recipientUserName?: string;
	status: ChatRequestStatus;
	userData: ChatUserData;
	message: string;
	timestamp: firebase.firestore.Timestamp;
	acceptedTimestamp?: firebase.firestore.Timestamp;
	showRequest: boolean;
	updatedAt: number;
	hostChat: boolean;
}

export interface ChatUserData {
	[uid: string]: {
		name: string;
		image: string | null;
		muted: boolean;
		status: ChatRequestStatus;
	};
}

export enum ChatRequestStatus {
	request,
	active,
	ended,
	directMessageRequest,
	directMessageActive,
	directMessageEnded,
	blocked,
}
