import React, { useState } from "react";
import { VideoSectionProps } from "../";
import { Well } from "../../../";
import Form from "../../../../../../components/Form";
import { ccLanguages } from "../../../../../../constants";
import { ContentType, Content, VideoProvider } from "../../../../../../interfaces";
import * as Styled from "../../ContentFormSections.styled";
import { getSrcDescription, getSrcLabel } from "../getLabels";
import { videoProviderData } from "../videoData";
import { VideoPreview } from "../../../../../../components/Video/VideoPreview";

export interface OnDemandVideoSectionProps extends VideoSectionProps { }

const OnDemandVideoSection: React.FC<OnDemandVideoSectionProps> = ({
	formikContext,
	isEmbed,
	clientId,
	timeZoneName,
}) => {
	const { values: formValues } = formikContext;
	const { contentType, videoProvider } = formValues;
	const [previewShouldUpdate, setPreviewShouldUpdate] = useState<boolean>(true);

	const label = timeZoneName ? `(${timeZoneName})` : "";

	return (
		<>
			<Styled.SectionHeader>ON-DEMAND VIDEO</Styled.SectionHeader>
			<Well
				message="Release content on-demand, live, or simulated live. Simulated live content auto-plays and keeps viewers in sync throughout."
				learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238545802253-How-to-Add-Videos-in-Brushfire-Online"
			>
				<VideoPreview
					content={formValues as Content}
					formikContext={formikContext}
					previewShouldUpdate={previewShouldUpdate}
				/>
				<Form.Field
					component={Form.Input.Select}
					label="Video Provider *"
					id="videoProvider"
					name="videoProvider"
					data={
						contentType
							? videoProviderData[ContentType[contentType]]
							: videoProviderData[ContentType[ContentType.simulatedLive]]
					}
					isSearchable={false}
				/>

				<Form.Field
					component={isEmbed ? Form.Input.TextArea : Form.Input.Text}
					label={`${getSrcLabel(videoProvider, 1)} *`}
					id="src"
					name="src"
					description={getSrcDescription(videoProvider, contentType || ContentType.live)}
					onBlur={() => setPreviewShouldUpdate(true)}
					onInput={() => {
						setPreviewShouldUpdate(false);
					}}
				/>

				<Form.Field
					component={Form.Input.DateTime}
					label={`Original Air Date ${label} *`}
					id="originalAirDate"
					name="originalAirDate"
					displayTimeZone={timeZoneName}
				/>

				<Form.Field label="Video Duration (in minutes) *" id="duration" name="duration" type="number" min={0} />

				<Form.Field
					component={Form.Input.Uploader}
					clientId={clientId}
					label="Thumbnail Image *"
					id="thumbImage"
					name="thumbImage"
					helperText="Recommended size 1920px by 1080px at 144 DPI and a file size less than 1MB."
				/>
				{VideoProvider[videoProvider] === "youtube" && (
					<Form.Field
						component={Form.Input.Select}
						label="CC Translation"
						id="ccLanguage"
						name="ccLanguage"
						data={ccLanguages}
						isClearable
						description="Select a language to enable closed captioning by default"
					/>
				)}
			</Well>
		</>
	);
};

export default OnDemandVideoSection;
