import {
	SET_CLIENT_ID,
	SET_CHANNEL_ID,
	SET_CONTENT_ID,
	SET_CLIENT_DATA,
	SET_CLIENT_DATA_LOADING,
	SET_CHANNEL_DATA_LOADING,
	SET_CONTENT_DATA,
	SET_CONTENT_DATA_LOADING,
	SET_CHANNEL_DATA,
	SET_CHANNELS_DATA,
	SET_CHANNELS_DATA_LOADING,
	SET_CONTENTS_DATA,
	SET_CONTENTS_DATA_LOADING,
	SET_TAGS_DATA,
	SET_TAGS_DATA_LOADING,
} from "./types";
import { PayloadAction } from "typesafe-actions";
import { ClientData, ChannelData, Content, Tag } from "../../interfaces";

export function setClientData(data: ClientData | undefined): PayloadAction<string, ClientData | undefined> {
	return {
		type: SET_CLIENT_DATA,
		payload: data,
	};
}
export function setClientDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_CLIENT_DATA_LOADING,
		payload: isLoading,
	};
}
export function setClientId(clientId: string): PayloadAction<string, string> {
	return {
		type: SET_CLIENT_ID,
		payload: clientId,
	};
}

export function setChannelData(data: ChannelData | undefined): PayloadAction<string, ChannelData | undefined> {
	return {
		type: SET_CHANNEL_DATA,
		payload: data,
	};
}
export function setChannelDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_CHANNEL_DATA_LOADING,
		payload: isLoading,
	};
}
export function setChannelId(channelId: string): PayloadAction<string, string> {
	return {
		type: SET_CHANNEL_ID,
		payload: channelId,
	};
}
export function setChannelsData(data: ChannelData[] | undefined): PayloadAction<string, ChannelData[] | undefined> {
	return {
		type: SET_CHANNELS_DATA,
		payload: data,
	};
}
export function setChannelsDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_CHANNELS_DATA_LOADING,
		payload: isLoading,
	};
}

export function setContentData(data: Content | undefined): PayloadAction<string, Content | undefined> {
	return {
		type: SET_CONTENT_DATA,
		payload: data,
	};
}
export function setContentDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_CONTENT_DATA_LOADING,
		payload: isLoading,
	};
}
export function setContentsData(data: Content[] | undefined): PayloadAction<string, Content[] | undefined> {
	return {
		type: SET_CONTENTS_DATA,
		payload: data,
	};
}
export function setContentsDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_CONTENTS_DATA_LOADING,
		payload: isLoading,
	};
}
export function setContentId(contentId: string): PayloadAction<string, string> {
	return {
		type: SET_CONTENT_ID,
		payload: contentId,
	};
}

export function setTagsData(data: Tag[] | undefined): PayloadAction<string, Tag[] | undefined> {
	return {
		type: SET_TAGS_DATA,
		payload: data,
	};
}
export function setTagsDataLoading(isLoading: boolean): PayloadAction<string, boolean> {
	return {
		type: SET_TAGS_DATA_LOADING,
		payload: isLoading,
	};
}
