import firebase from "firebase";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import authFirebase from "../../authFirebase";
import firebaseApp from "../../firebase";
import { ChatMessage, ChatRequest, ChatRequestStatus, Client, Content, User, UserStatus } from "../../interfaces";
import { usePrevious } from "../../pages/Channel/utils";
import RequestButton from "../../pages/Dashboard/Contents/HostManager/RequestButton";
import { TypedUseSelectorHook } from "../../redux";
import { setChatData } from "../../redux/Chat/actions";
import getMilliseconds from "../../utils/getMilliseconds";
import { CHAT_UNMOUNT, clearOldSnapshot, emit, initChat, Message, resubscribeToMessageHandler } from "./Chat.service";
import ChatConversation from "./ChatConversation";
import ChatInput from "./ChatInput";
import ChatLogin from "./ChatLogin";
import ProcessedChatConversation from "./ProcessedChatConversation";
import { style } from "./styles";

interface ChatProps {
	id: string;
	isModerator: boolean;
	enableAnonymous: boolean;
	clientId: string;
	onMessages?: (chatId: string, messages: Message[]) => void;
	isDirect?: boolean;
	contentId: string;
	chatClosed?: boolean;
	isLightBackground?: boolean;
	isLoggingOut?: boolean;
	startChat?: (request: ChatRequest) => void;
	request?: ChatRequest;
	isHostManager?: boolean;
	content: Content;
	useDashboardAuth?: boolean;
	client?: Client;
	canUseChatProcessor?: boolean;
	useChatProcessor?: boolean;
	enablePreview?: boolean;
	chatBlacklist?: string[];
	userStatus?: UserStatus;
	setCurrentChat?: (chatRequest: ChatRequest) => void;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const unsubscribes = [];

const db = firebaseApp.firestore();

const Chat: React.FC<ChatProps> = ({
	clientId,
	id,
	isModerator,
	enableAnonymous,
	onMessages,
	isDirect = false,
	chatClosed = false,
	contentId,
	isLightBackground,
	isLoggingOut = false,
	startChat,
	request,
	isHostManager,
	content,
	useDashboardAuth = false,
	client,
	useChatProcessor = false,
	canUseChatProcessor = false,
	enablePreview = false,
	chatBlacklist,
	userStatus,
	setCurrentChat,
}) => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search.replace("?", ""));
	// const videoId = searchParams.get("videoId");

	const auth = useDashboardAuth ? firebaseApp.auth() : authFirebase.auth();

	const [isReady, setIsReady] = useState(false);
	const [userName, setUserName] = useState(auth.currentUser ? auth.currentUser?.displayName : null);
	const [uid, setUid] = useState(auth.currentUser ? auth.currentUser?.uid : undefined);
	const [photoURL, setPhotoURL] = useState(auth.currentUser ? auth.currentUser?.photoURL : null);
	const [email, setEmail] = useState(auth.currentUser ? auth.currentUser?.email : null);
	const [bannedUsers, setBannedUsers] = useState<string[]>([]);
	const [inputHeight, setInputHeight] = useState(24);
	const [hasPinnedChats, setHasPinnedChat] = useState(false);
	const [showTranferOptions, setShowTransferOptions] = useState(false);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [userList, setUserList] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<User>();

	const dispatch = useDispatch();

	const onLogin = (loginDetail: any) => {
		// check who'd logged in
		setUid(loginDetail.userName.uid);
		setPhotoURL(loginDetail.userName.photoURL);
		setUserName(loginDetail.userName.displayName);
		setEmail(loginDetail.email);
	};
	const { user, appUser } = useTypedSelector(store => store.user);
	const analytics = useTypedSelector(store => store.analytics);
	const chat = useTypedSelector(store => store.chat);
	const dashboard = useTypedSelector(store => store.dashboard);

	const oldId = usePrevious(id) as any;

	const acceptChat = () => {
		if (contentId) {
			const docRef = db
				.collection("chat")
				.doc("direct")
				.collection(contentId)
				.doc(id);
			docRef.get().then(doc => {
				const request = doc.data() as ChatRequest;
				if (request.status === ChatRequestStatus.request) {
					request.status = ChatRequestStatus.active;
				} else if (request.status === ChatRequestStatus.directMessageRequest) {
					request.status = ChatRequestStatus.directMessageActive;
				}
				docRef.set(request);
			});
		}
	};

	const endChat = () => {
		if (contentId && uid) {
			const docRef = db
				.collection("chat")
				.doc("direct")
				.collection(contentId)
				.doc(id);
			docRef.get().then(doc => {
				const request = doc.data() as ChatRequest;
				if (
					request.userData[uid].status === ChatRequestStatus.request ||
					request.userData[uid].status === ChatRequestStatus.active
				) {
					request.userData[uid].status = ChatRequestStatus.ended;
					if (Object.values(request.userData).every(x => ChatRequestStatus.ended === x.status)) {
						request.status = ChatRequestStatus.ended;
					}
				} else if (
					request.userData[uid].status === ChatRequestStatus.directMessageActive ||
					request.userData[uid].status === ChatRequestStatus.directMessageRequest
				) {
					request.userData[uid].status = ChatRequestStatus.directMessageEnded;
					if (Object.values(request.userData).every(x => ChatRequestStatus.directMessageEnded === x.status)) {
						request.status = ChatRequestStatus.directMessageEnded;
					}
				}

				docRef.set(request);
			});
		}
	};

	const blockChat = () => {
		if (contentId) {
			const docRef = db
				.collection("chat")
				.doc("direct")
				.collection(contentId)
				.doc(id);
			docRef.get().then(doc => {
				const request = doc.data() as ChatRequest;
				request.status = ChatRequestStatus.blocked;
				Object.values(request.userData).forEach(d => {
					d.status = ChatRequestStatus.blocked;
				});
				docRef.set(request);
			});
		}
	};

	const handleNewMessageCount = (messages: Message[]) => {
		if (onMessages) {
			onMessages(id, messages);
		}
	};

	const getBannedUsers = () => {
		if (id) {
			let bannedUsersArr: string[] = [];
			const docRef = db
				.collection("chat")
				.doc("banned")
				.collection(id);

			docRef.get().then(querySnapshot => {
				querySnapshot.forEach(doc => {
					bannedUsersArr.push(doc.id);
				});
			});
			setBannedUsers(bannedUsersArr);
		}
	};

	const getPreprocessedChats = (since?: firebase.firestore.Timestamp) => {
		let query = db
			.collection("chat")
			.doc("processed-contents")
			.collection(id)
			.orderBy(since ? "updatedAt" : "timestamp", "desc")
			.limit(since ? 500 : 100);
		if (since) {
			query = query.where("updatedAt", ">=", since);
		}
		query.get().then(messageDocs => {
			const messages: { [key: string]: ChatMessage } = {};
			let latestMessage: firebase.firestore.Timestamp | undefined = undefined;
			messageDocs.forEach(message => {
				const md = message.data() as ChatMessage;
				md.id = message.id;
				if (latestMessage === undefined || latestMessage.toMillis() < md.updatedAt.toMillis()) {
					latestMessage = md.updatedAt;
				}
				messages[message.id] = md;
			});

			db.collection("chat")
				.doc("processed-contents")
				.collection(id)
				.where("isPinned", "==", true)
				.get()
				.then(pinnedChats => {
					pinnedChats.forEach(pinnedChat => {
						const md = pinnedChat.data() as ChatMessage;
						messages[pinnedChat.id] = md;
					});
				});

			dispatch(
				setChatData({
					messages,
					chatId: id,
					latestMessage,
					removeOldMessages: false,
				})
			);
		});
	};

	const getUsers = async () => {
		if (dashboard.clientData) {
			if (!isEmpty(dashboard.clientData) && isEmpty(dashboard.clientData?.users)) {
				setLoadingUsers(false);
			} else {
				try {
					let allUsers: User[] = [];
					const querySnapshot = await db
						.collection("users")
						.where(`clients.${dashboard.clientId}`, "in", ["owner", "admin", "host", "superhost"])
						.get();
					querySnapshot.forEach(async doc => await allUsers.push({ ...(doc.data() as User) }));
					allUsers.sort((a: any, b: any) => {
						return a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0;
					});
					setUserList(allUsers);
				} catch (error) {
					console.warn(`Error getting users`, error);
				}

				setLoadingUsers(false);
			}
		}
	};

	useEffect(() => {
		if (oldId) {
			clearOldSnapshot(oldId);
		}
		initChat(id, handleNewMessageCount, dispatch);
		if (chat.chatId !== id && useChatProcessor) {
			//get the prexisting chats
			getPreprocessedChats();
		} else if (useChatProcessor) {
			//get the change since the last time you were here
			getPreprocessedChats(
				chat.latestMessage
					? firebase.firestore.Timestamp.fromMillis(getMilliseconds(chat.latestMessage))
					: undefined
			);
		}
		setIsReady(true);
		const unsubscribe = db
			.collection("chat")
			.doc("banned")
			.collection(id)
			.onSnapshot(docs => {
				const bannedUsers: string[] = [];
				docs.forEach(doc => {
					bannedUsers.push(doc.id);
				});
				setBannedUsers(bannedUsers);
			});

		return () => {
			unsubscribe();
			emit(CHAT_UNMOUNT, { chatId: id }, id);
		};
	}, [id]);

	useEffect(() => {
		setIsReady(false);
		getBannedUsers();
		setTimeout(() => {
			initChat(id, handleNewMessageCount, dispatch);
			setIsReady(true);
		}, 1);
		return () => {
			emit(CHAT_UNMOUNT, { chatId: id }, id);
		};
	}, [id]);

	useEffect(() => {
		resubscribeToMessageHandler(id, handleNewMessageCount);
	}, [onMessages]);

	useEffect(() => {
		if (showTranferOptions) {
			setLoadingUsers(true);
			getUsers();
		}
	}, [showTranferOptions]);

	useEffect(() => {
		if (auth.currentUser) {
			setUid(auth.currentUser.uid);
			setPhotoURL(auth.currentUser.photoURL);
			setUserName(auth.currentUser.displayName);
			setEmail(auth.currentUser.email);
		} else {
			setUid(undefined);
			setPhotoURL(null);
			setUserName(null);
			setEmail(null);
		}
	}, [auth.currentUser?.uid, auth.currentUser?.displayName]);

	const swapHost = (user: User) => {
		if (uid) {
			const docRef = db
				.collection("chat")
				.doc("direct")
				.collection(contentId)
				.doc(id);

			docRef.get().then(doc => {
				const data = doc.data() as ChatRequest;
				if (data.userData) {
					data.userData[user.firebaseId] = {
						name: `${user.firstName} ${user.lastName}`,
						muted: false,
						image: user.avatarImage,
						status: data.userData[uid].status,
					};
					delete data.userData[uid];
				}
				data.hosts = [user.firebaseId];
				data.users = data.users.filter(u => u !== uid);
				data.users.push(user.firebaseId);
				if (data.createdBy === uid) {
					docRef.set(
						Object.assign({}, data, {
							userName: `${user.firstName} ${user.lastName}`,
							createdBy: user.firebaseId,
						})
					);
				} else {
					docRef
						.set(Object.assign({}, data, { recipientUserName: `${user.firstName} ${user.lastName}` }))
						.catch(err => {});
				}
				const newMessage = {
					userName,
					uid,
					message: `***** ${renderUserName(userName, appUser)} has transferred this chat to ${
						user.firstName
					} ${user.lastName} *****`,
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
					photoURL: photoURL ? `https://media.online.brushfire.com/profile_images/${uid}/profile.jpg` : null,
					isHost: true,
					flags: [],
					isDeleted: false,
				};
				db.collection("chat")
					.doc("contents")
					.collection(id)
					.add(newMessage);
			});
		}
	};

	// useEffect(() => {
	// 	unsubscribes.push(setupConvoListener({ id, onReadConvo: onNewConvoEvent, deleteMessage }));
	// 	// componentWillUnmount
	// 	return () => {
	// 	  flushConvo();
	// 	  unsubscribes.map((u) => u());
	// 	};
	//   }, [chatId]);

	const renderUserName = (userName: string | null, appUser: any) => {
		if (useDashboardAuth && appUser && appUser.firstName && appUser.lastName) {
			return `${appUser?.firstName} ${appUser?.lastName}`;
		} else if (userName) {
			return userName;
		} else {
			return "Anonymous User";
		}
	};

	if (!isReady) {
		return null;
	}

	if (isLoggingOut) {
		return (
			<style.ChatWrapper>
				<div className="absolute-center ta-center">
					<i className="fal fa-spinner-third fa-spin fa-2x m-bottom"></i>
					<br />
					Logging Out
				</div>
			</style.ChatWrapper>
		);
	}

	const showLoginButton = enablePreview && !user?.displayName;

	if (((userName || (user && useDashboardAuth && appUser?.firstName)) && uid) || enablePreview) {
		return (
			<style.ChatWrapper className={`${hasPinnedChats ? "has-pinned-chats" : ""}`}>
				{isHostManager && (
					<div className={`host-transfer-overlay ${showTranferOptions ? "active" : ""}`}>
						<div
							className={`cancel`}
							onClick={() => {
								setShowTransferOptions(false);
							}}
						>
							<i className="fal fa-times"></i>
						</div>
						<div className="host-transfer-options">
							{userList.map(user => {
								if (user.firebaseId && user.firebaseId !== uid) {
									return (
										<RequestButton
											uid={user.firebaseId}
											key={user.id}
											onClick={() => setSelectedUser(user)}
											title={`${user.firstName} ${user.lastName}`}
											contentId={contentId}
											active={selectedUser?.id === user.id}
										/>
									);
								}
							})}
							<div
								className={`btn btn-primary btn-block btn-sm m-top ${selectedUser ? "" : "disabled"}`}
								onClick={() => {
									if (selectedUser) {
										swapHost(selectedUser);
									}
								}}
							>
								Transfer
							</div>
						</div>
					</div>
				)}
				{useChatProcessor ? (
					<ProcessedChatConversation
						chatId={id}
						clientId={clientId}
						isModerator={isModerator}
						uid={uid ?? ""}
						isDirect={isDirect}
						bannedUsers={bannedUsers}
						isLightBackground={isLightBackground || false}
						isHostManager={isHostManager || false}
						chatClosed={chatClosed}
						useChatProcessor={useChatProcessor}
						inputHeight={showLoginButton ? 11 : inputHeight}
						userLoggedIn={useDashboardAuth ? !!auth.currentUser : !!auth.currentUser?.displayName}
						userName={renderUserName(userName, appUser)}
						photoURL={photoURL}
						chatBlacklist={chatBlacklist}
						setHasPinnedChat={setHasPinnedChat}
						enableDirectMessaging={content.chat.enableDirectMessaging ?? false}
						setCurrentChat={setCurrentChat}
					/>
				) : (
					<>
						{canUseChatProcessor && <style.OldChatCorner isLightBackground={isLightBackground} />}
						<ChatConversation
							chatId={id}
							clientId={clientId}
							isModerator={isModerator}
							uid={uid}
							videoId={id}
							isDirect={isDirect}
							request={request}
							bannedUsers={bannedUsers}
							isLightBackground={isLightBackground}
							isHostManager={isHostManager}
							chatClosed={chatClosed}
							useChatProcessor={useChatProcessor}
							inputHeight={showLoginButton ? 11 : inputHeight}
							userLoggedIn={useDashboardAuth ? !!auth.currentUser : !!auth.currentUser?.displayName}
							photoURL={photoURL}
							acceptChat={acceptChat}
							endChat={endChat}
							blockChat={blockChat}
							user={auth.currentUser}
							contentId={contentId}
							setHasPinnedChat={setHasPinnedChat}
							setShowTransferOptions={setShowTransferOptions}
							enableDirectMessaging={content.chat.enableDirectMessaging}
						/>
					</>
				)}

				<ChatInput
					chatId={id}
					isModerator={isModerator}
					userName={renderUserName(userName, appUser)}
					email={email}
					uid={uid}
					photoURL={photoURL}
					videoId={id}
					user={auth.currentUser}
					isDirect={isDirect}
					endChat={endChat}
					chatClosed={chatClosed || (!!request && !content.chat.enableDirectMessaging && !request.hostChat)}
					bannedUsers={bannedUsers}
					startChat={startChat}
					request={request}
					isHostManager={isHostManager}
					useDashboardAuth={useDashboardAuth}
					useChatProcessor={useChatProcessor}
					inputHeight={inputHeight}
					setInputHeight={setInputHeight}
					enablePreview
					dispatch={dispatch}
					contentId={contentId}
					userStatus={userStatus}
				/>
			</style.ChatWrapper>
		);
	} else {
		return (
			<>
				<ChatLogin
					content={content}
					dispatch={dispatch}
					chatId={id}
					isModerator={isModerator}
					onLogin={onLogin}
					enableAnonymous={enableAnonymous}
					setUserName={setUserName}
					isDirect={isDirect}
					analyticsUserId={analytics.analyticsUserId}
					contentId={contentId}
					useDashboardAuth={useDashboardAuth}
					client={client}
				/>
			</>
		);
	}
};

export default Chat;
