import firebase from "firebase/app";
import "firebase/auth";
import { FormikContextType } from "formik";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { IconButton } from "../../components/Buttons/IconButton";
import Form from "../../components/Form";
import Error from "../../components/Form/Error";
import { TypedUseSelectorHook } from "../../redux";
import { HorizontalRule, PageContainer, PageTitle, Well } from "./UI";
import addOrReplaceProfileImage from "../../components/Chat/services/addOrReplaceProfileImg";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const eventTitleSchema = Yup.object().shape({
	email: Yup.string().required("Email Required"),
	password: Yup.string().required("Password Required"),
});

interface LoginData {
	email: string;
	password: string;
}

interface Error {
	code: string;
	message: string;
}

const alertContents = (message: string): any => {
	if (message.includes("https")) {
		return <span dangerouslySetInnerHTML={{ __html: message }} />;
	} else {
		return <span>{message}</span>;
	}
};

const DashboardLogin: React.FC = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [signInError, setSignInError] = useState<Error>();

	const onSubmit = (values: LoginData) => {
		setIsSubmitting(true);
		setSignInError(undefined);
		logInWithEmail(values);
	};

	const user = useTypedSelector(store => store.user);

	const logInWithGoogle = () => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(() => {
				addOrReplaceProfileImage(firebase.auth());
			})
			.catch(err => {
				setSignInError(err);
			});
	};

	const logInWithFacebook = () => {
		var provider = new firebase.auth.FacebookAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(() => {
				addOrReplaceProfileImage(firebase.auth());
			})
			.catch(err => {
				setSignInError(err);
			});
	};

	const logInWithEmail = (values: LoginData) => {
		firebase
			.auth()
			.signInWithEmailAndPassword(values.email, values.password)
			.then(() => {})
			.catch(error => {
				let err = error;
				if (error.code !== "auth/too-many-requests") {
					firebase
						.auth()
						.fetchSignInMethodsForEmail(values.email)
						.then(data => {
							if (!isEmpty(data)) {
								// the user exists, [data] will be the allowable sign in methods (password or link)
								let extraMessage = ``;
								if (data.length === 1) {
									if (data[0] === "password") {
										extraMessage = "Please sign in with the correct password";
									}
								} else if (data.length === 2) {
									extraMessage = "Please sign in with the correct password or your login link";
								}

								err.message = `${err.message} ${extraMessage}.`;
							}
						})
						.finally(() => {
							if (err.code === "auth/user-not-found") {
								firebase
									.firestore()
									.collection("users")
									.where("email", "==", values.email)
									.get()
									.then(docs => {
										if (!docs.empty) {
											err.message =
												"Unverified User. Please check your email for your verifcation link to finish setting up your account.";
										}
									})
									.finally(() => {
										setSignInError(err);
									});
							} else {
								setSignInError(err);
							}
						});
				} else {
					setSignInError(err);
				}
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	let errorText = "";
	if (signInError) {
		errorText = signInError.message;
	}

	return (
		<PageContainer className="no-sidebar" size="sm" htmlPageTitle="Sign In">
			<PageTitle>SIGN IN</PageTitle>
			<Well>
				<Form
					initialValues={{ email: "", password: "" }}
					validationSchema={eventTitleSchema}
					onSubmit={onSubmit}
				>
					{({ formikContext }: { formikContext: FormikContextType<LoginData> }) => {
						const { errors, touched } = formikContext;
						return (
							<fieldset disabled={isSubmitting}>
								{user.errorMessage && (
									<Alert variant="danger">{alertContents(user.errorMessage)}</Alert>
								)}
								<Form.Field label="Email" id="email" name="email" />
								<Form.Field label="Password" id="password" name="password" type="password" />
								<Button type="submit" variant="primary" className="btn-block">
									{isSubmitting && <Spinner animation="border" className="form-button-spinner" />}{" "}
									Sign{isSubmitting ? "ing" : ""} In
								</Button>
								{errorText && <Error>{errorText}</Error>}
								<HorizontalRule>or</HorizontalRule>
								<IconButton
									variant="dark"
									className="btn-block"
									icon="google"
									iconWeight="fab"
									onClick={logInWithGoogle}
								>
									Sign In With Google
								</IconButton>
								<IconButton
									variant="dark"
									className="btn-block"
									icon="facebook"
									iconWeight="fab"
									onClick={logInWithFacebook}
								>
									Sign In With Facebook
								</IconButton>
							</fieldset>
						);
					}}
				</Form>
			</Well>
			<div className="ta-center">
				<a href="/dashboard/forgot" className="white">
					Forgot your password?
				</a>
			</div>
		</PageContainer>
	);
};

export default DashboardLogin;
