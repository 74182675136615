import React from "react";
import sizeMe from "react-sizeme";

interface SizedComponentProps {
	element: any;
}

const SizedComponent: React.FC<SizedComponentProps> = ({ element }) => {
	return element;
};

export default sizeMe()(SizedComponent);
