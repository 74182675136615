import React, { useState } from "react";
import { Reaction, ReactionIcon } from "../../interfaces";
import styled, { keyframes } from "styled-components";
import { style } from "./styles";

interface ReactionFlowProps {
	myReactions: Reaction[];
	reactions: Reaction[];
	numberOfReactions: number;
}

const float = (y: number) => {
	return keyframes`
  from {
	transform: translate(0,0);
	opacity: 1;
  }

  to {
	transform: translate(0,-${100 * y + 100}px);
	opacity: 0
  }
`;
};

const FlowIcon = styled.div<{ x: number; y: number; className: string; numberOfReactions: number }>`
	right: ${props => props.x * 30 * (props.numberOfReactions - 1) - 10}px;
	bottom: -24px;
	z-index: 98;
	animation: ${props => float(props.y)} 2.5s forwards;
`;

const myFloat = (y: number) => {
	return keyframes`
  from {
	transform: translate(0,0);
	opacity: 1;
  }

  to {
	transform: translate(0,-${100 * y + 200}px);
	opacity: 0
  }
`;
};

const MyFlowIcon = styled.div<{ x: number; y: number; className: string; numberOfReactions: number }>`
	right: ${props => props.x * 30 * (props.numberOfReactions - 1)}px;
	bottom: -24px;
	z-index: 98;
	animation: ${props => myFloat(props.y)} 3s forwards;
`;

const ReactionFlow: React.FC<ReactionFlowProps> = ({ myReactions, reactions, numberOfReactions }) => {
	return (
		<style.ReactionFlowIcons className="reaction-flow-icons">
			{myReactions.map(reaction => {
				if (reaction.icon) {
					return (
						<MyFlowIcon
							className="reaction-icon"
							x={reaction.x}
							y={reaction.y}
							numberOfReactions={numberOfReactions}
							key={reaction.id}
						>
							<img src={`/content/img/reactions/bfo-${ReactionIcon[reaction.icon]}.svg`} />
						</MyFlowIcon>
					);
				}
			})}
			{reactions.map(reaction => {
				if (reaction.icon) {
					return (
						<FlowIcon
							className="reaction-icon"
							x={reaction.x}
							y={reaction.y}
							numberOfReactions={numberOfReactions}
							key={reaction.id}
						>
							<img src={`/content/img/reactions/bfo-${ReactionIcon[reaction.icon]}.svg`} />
						</FlowIcon>
					);
				}
			})}
		</style.ReactionFlowIcons>
	);
};

export default ReactionFlow;
