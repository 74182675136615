import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from "../../../components/Toast/ToastProvider";

import * as Yup from "yup";
import { Well, PageTitle, PageContainer } from "../UI";
// import { FieldArray, FormikContextType } from "formik";
import { Channel, User } from "../../../interfaces";
import Form from "../../../components/Form";
import api from "../../../services/api";

// http://localhost:3000/dashboard/:clientId/channels/:channelId

const GlobalUsers: React.FC = () => {
	const history = useHistory();
	const routeParams: any = useParams();
	const { addToast } = useToast();
	const isCurrentUser = routeParams && routeParams.userId;

	const [clientChannelData, setClientChannelData] = useState<Channel>(); //TODO get real data
	const [isSaving, setIsSaving] = useState(false);
	const [serverError, setServerError] = useState<null | string>(null);
	const [globalUsers, setGlobalUsers] = useState<null | User[]>();

	useEffect(() => {
		api.fetchGlobalUsers()
			.then(data => {
				setGlobalUsers(data.data);
			})
			.catch(err => {});
	}, [setGlobalUsers]);

	const onSubmit = (values: any) => {
		if (!isSaving) {
			setIsSaving(true);
			setServerError(null);

			api.addGlobalUser(values)
				.then(data => {
					if (data.ok) {
						const user = data.data as User;
						if (user.verificationCode) {
							addToast("success", "User invited");
						} else {
							addToast("success", "User promoted");
						}
						window.location.reload();
					}
				})
				.catch(error => {
					console.warn("Something went wrong -", error);
					addToast("error", "Something went wrong");
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	const initialValues = isEmpty(clientChannelData)
		? {
				firstName: "",
				lastName: "",
				email: "",
				role: "owner",
		  }
		: {
				firstName: clientChannelData?.name,
				headerImage: clientChannelData?.headerImage,
		  };

	const userSchema = Yup.object().shape({
		firstName: Yup.string().required("First Name Required"),
		lastName: Yup.string().required("Last Name Required"),
		email: Yup.string().required("Email Required"),
		role: Yup.string().required("Global Role Required"),
	});

	return (
		<PageContainer size="md" htmlPageTitle="Global Users">
			<Row>
				<Col md={6}>
					<Form initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
						{({ formikContext }: { formikContext: any }) => {
							const { errors } = formikContext;
							return (
								<fieldset disabled={isSaving}>
									<div className="text-center">
										<PageTitle>{isCurrentUser ? "EDIT USER" : "ADD GLOBAL USER"}</PageTitle>
									</div>
									<Well>
										<Form.Field label="First Name" id="firstName" name="firstName" />
										<Form.Field label="Last Name" id="lastName" name="lastName" />
										<Form.Field label="Email" id="email" name="email" />
										<Form.Field label="Global Role" id="role" name="role" />
									</Well>

									<Button
										variant={!formikContext.isValid ? `danger` : `primary`}
										className="btn-block text-center"
										type="submit"
										disabled={!formikContext.isValid || isSaving}
									>
										{isCurrentUser ? "SAVE" : "ADD"}
									</Button>
									{isSaving && <small>Hold Please...</small>}
								</fieldset>
							);
						}}
					</Form>
				</Col>
				<Col md={6}>
					<PageTitle>Current Users</PageTitle>
					<Well>
						{globalUsers?.map(globalUser => {
							return (
								<>
									<div>
										{globalUser.firstName} {globalUser.lastName}
									</div>
									<div style={{ opacity: 0.6 }}>{globalUser.email}</div>
									{globalUser.verificationCode && (
										<small>
											<a
												href={`https://online.brushfire.com/signup/${globalUser.id}/${globalUser.verificationCode}/`}
											>
												https://online.brushfire.com/signup/{globalUser.id}/
												{globalUser.verificationCode}/
											</a>
										</small>
									)}
								</>
							);
						})}
					</Well>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default GlobalUsers;
