import styled from "styled-components";
import { Link } from "react-router-dom";
import isLightOrDark from "../../utils/isLightOrDark";
import { darken, lighten } from "polished";

export const ScheduleRow = styled(Link)`
	color: ${({ theme }) => theme.wellTextColor};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => {
		return isLightOrDark(theme.wellBackgroundColor) === "light"
			? darken(0.05, theme.wellBackgroundColor)
			: lighten(0.2, theme.wellBackgroundColor);
	}};
	padding: 0.5em;
	margin-bottom: 5px;
	border-radius: 6px;
	&.disabled {
		cursor: not-allowed;
	}
	&:hover {
		color: ${({ theme }) => theme.wellTextColor};
		text-decoration: none;
		&:not(.disabled) {
			background-color: ${({ theme }) => {
				return isLightOrDark(theme.wellBackgroundColor) === "light"
					? darken(0.15, theme.wellBackgroundColor)
					: lighten(0.45, theme.wellBackgroundColor);
			}};
		}
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	i.fal.fa-lock {
		margin-right: 0.5em;
	}
`;

export const Title = styled.div`
	margin-right: 2em;
	color: ${({ theme }) => theme.wellTextColor};
`;

export const NoScheduledContent = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ScheduleItemsGroupDate = styled.div`
	display: flex;
	justify-content: space-between;
	p {
		font-weight: 600;
		font-size: smaller;
		margin-bottom: 0.5em;
	}
`;
