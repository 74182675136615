import styled from "styled-components";
import { Theme } from "../../interfaces";

interface LinkStyleProps {
	theme: Theme;
}

export const LinkButton = styled.a<LinkStyleProps>`
	cursor: pointer;
	color: ${({ theme }) => theme.navLinkColor};

	i,
	span {
		cursor: pointer;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 18px;
		line-height: 13.8px;
		transition: all 0.25s;
		text-decoration: none;
		color: ${({ theme }) => theme.navLinkColor};
	}

	&:hover {
		color: ${({ theme }) => theme.navLinkHoverColor};
		text-decoration: none;
		cursor: pointer;

		i,
		span {
			color: ${({ theme }) => theme.navLinkHoverColor};
			text-decoration: none;
			cursor: pointer;
		}
	}
`;

export const DrawerLinkButton = styled.a<LinkStyleProps>`
	cursor: pointer;
	color: ${({ theme }) => theme.navLinkColor};
	transition: all 0.25s;

	h2 {
		color: ${({ theme }) => theme.navLinkColor};
		transition: all 0.25s;
	}

	&:hover {
		color: ${({ theme }) => theme.navLinkHoverColor};
		text-decoration: none;
		cursor: pointer;

		h2 {
			color: ${({ theme }) => theme.navLinkHoverColor};
		}
	}
`;