import React, { useEffect, useState } from "react";
import { FormikContextType } from "formik";
import moment from "moment";
import { size, keys, values, isEqual } from "lodash";
import { Spinner } from "react-bootstrap";

import { PageTitle, Well } from "../..";
import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";
import Form from "../../../../../components/Form";
import { Channel, BrushfireEventCondensed, Rule } from "../../../../../interfaces";

import * as Styled from "../ContentFormSections.styled";
import { EventSection } from "./ViewerAccessSection.styled";
import api from "../../../../../services/api";
import { BrushfireEvent, BrushfireFieldOutput } from "../../../../../interfaces/Event";
import { usePrevious } from "../../../../../hooks/usePrevious";
import asyncForEach from "../../../../../utils/asyncForEach";

export interface ViewerAccessSectionProps {
	isEnabled: boolean;
	channels: Channel[] | null | undefined;
	formikContext: FormikContextType<ContentFormContent>;
	rules: Rule[];
}

const getEventIds = (channels: Channel[] | null | undefined) => {
	let eventIds: BrushfireEventCondensed[] = [];
	channels?.forEach(channel => {
		channel.brushfireEventIds?.forEach(bfEvent => {
			if (!eventIds.find(evt => evt.eventId === bfEvent.eventId)) {
				eventIds.push(bfEvent);
			} else if (eventIds.find(evt => evt.eventId === bfEvent.eventId)) {
				let eventIndex = eventIds.findIndex(evt => evt.eventId === bfEvent.eventId);
				let foundEvent = eventIds[eventIndex];

				let foundEventAttendeeTypes = 0;
				let bfEventAttendeeTypes = 0;

				if (size(foundEvent.attendeeTypes) > 0) {
					let foundEventValues = values(foundEvent.attendeeTypes);

					foundEventValues?.forEach(val => {
						if (val.numberOfScreens !== null) {
							foundEventAttendeeTypes += 1;
						}
					});
				}

				if (size(bfEvent.attendeeTypes) > 0) {
					let bfEventValues = values(bfEvent.attendeeTypes);

					bfEventValues?.forEach(val => {
						if (val.numberOfScreens !== null) {
							bfEventAttendeeTypes += 1;
						}
					});
				}

				if (bfEventAttendeeTypes > foundEventAttendeeTypes) {
					eventIds[eventIndex] = bfEvent;
				}
			}
		});
	});
	return eventIds;
};

const ViewerAccessSection: React.FC<ViewerAccessSectionProps> = ({ isEnabled, formikContext, channels, rules }) => {
	const [events, setEvents] = useState(getEventIds(channels));
	const [eventsLoading, setEventsLoading] = useState<string[]>([]);

	useEffect(() => {
		if (events && events.length > 0) {
			const eventIds = getEventIds(channels);
			searchForEvents(eventIds);
		}
	}, []);

	useEffect(() => {
		const eventIds = getEventIds(channels);
		if (
			!isEqual(
				eventIds.map(evt => evt.eventId).sort((a: string, b: string) => (a > b ? -1 : 1)),
				events.map(evt => evt.eventId).sort((a: string, b: string) => (a > b ? -1 : 1))
			)
		) {
			searchForEvents(eventIds);
		}
	}, [channels]);

	const searchForEvents = async (events: any) => {
		let newEvents: BrushfireEventCondensed[] = [];
		//@ts-ignore
		await asyncForEach(events, async (event: BrushfireEventCondensed) => {
			let idString = event.eventId.toString();
			let eventsLoadingArr: string[] = [...eventsLoading];

			eventsLoadingArr.push(idString);
			setEventsLoading(eventsLoadingArr);

			await api
				.getEventData(event.eventId, false, true)
				.then(async (data: any) => {
					if (data.ok) {
						const fieldData = [...data.data.fields];
						const parsedFieldData = fieldData.map((field: BrushfireFieldOutput) => {
							return { id: field.Id, name: field.Label, options: field.Options };
						});

						newEvents.push({ ...event, fields: parsedFieldData || [] });
					} else {
						removeLoadingEvents(event);
					}
				})
				.catch(async (error: any) => {
					console.warn("Error getting event", error);
					removeLoadingEvents(event);
				})
				.finally(async () => {
					removeLoadingEvents(event);
				});
		});
		setEvents(newEvents);
	};

	const removeLoadingEvents = (event: any) => {
		let idString = event.eventId.toString();
		let eventsLoadingArr: string[] = [...eventsLoading];

		eventsLoadingArr.push(event.eventId.toString());
		const idIndex = eventsLoadingArr.indexOf(idString);

		if (idIndex > -1) {
			eventsLoadingArr.splice(idIndex, 1);
		}
		setEventsLoading(eventsLoadingArr);
	};

	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>RESTRICT VIEWER ACCESS</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="videoAccessRuleEnabled"
					name="videoAccessRuleEnabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{isEnabled ? (
				<Well message="Control who can access this content using the options below. Attendees matching any of the selected options will be able to view the content.">
					<>
						{events &&
							events.length > 0 &&
							events
								.sort((a: any, b: any) => {
									let eventA = a.eventName.toUpperCase();
									let eventB = b.eventName.toUpperCase();
									return eventA < eventB ? -1 : eventA > eventB ? 1 : 0;
								})
								.map((event: BrushfireEventCondensed) => {
									const eventKeys = size(event.attendeeTypes) > 0 ? keys(event.attendeeTypes) : [];
									return (
										<EventSection key={event.eventId}>
											<div className="section-header-wrapper">
												<h5>
													{event.eventName} ({event.eventNumber})
												</h5>
												{event.eventDate && <p>Starts on {event.eventDate}</p>}
											</div>

											{event.attendeeTypes && size(event.attendeeTypes) > 0 && (
												<div className="section-container">
													<h6>Attendee Types</h6>
													<div className="section-checkbox-container">
														{values(event.attendeeTypes).map((type, index) => {
															if (type.numberOfScreens !== null) {
																return (
																	<Form.Input.Checkbox
																		label={type.name}
																		id={`rules[0].attendeeTypeIds[${eventKeys[index]}]`}
																		name={`rules[0].attendeeTypeIds[${eventKeys[index]}]`}
																		value={
																			formikContext?.values?.rules[0]?.attendeeTypeIds?.includes(
																				eventKeys[index]
																			) || false
																		}
																		onChecked={() => {
																			let data =
																				rules[0]?.attendeeTypeIds.concat() ||
																				[];
																			data.push(eventKeys[index]);
																			formikContext.setFieldValue(
																				`rules[0].attendeeTypeIds`,
																				data
																			);
																		}}
																		onUnchecked={() => {
																			let data =
																				rules[0]?.attendeeTypeIds.concat() ||
																				[];
																			const typeIndex = data.indexOf(
																				eventKeys[index]
																			);
																			if (typeIndex > -1) {
																				data.splice(typeIndex, 1);
																			}
																			formikContext.setFieldValue(
																				`rules[0].attendeeTypeIds`,
																				data
																			);
																		}}
																	/>
																);
															}
															return null;
														})}
													</div>
												</div>
											)}
											{eventsLoading.includes(event.eventId.toString()) && (
												<div className="loader-container">
													<Spinner animation="border" className="form-button-spinner" />
												</div>
											)}
											{event.fields && event.fields.length > 0 && (
												<div className="section-container">
													<h6>Fields / Options</h6>
													<div className="section-checkbox-container">
														{event.fields.map((field: any) => {
															return (
																<>
																	{field.options && field.options.length > 0 && (
																		<i>{field.name}</i>
																	)}

																	{field.options.map((option: any) => (
																		<Form.Input.Checkbox
																			key={option.Id}
																			label={option.Label}
																			id={`rules[0].fields.${option.Id}`}
																			name={`rules[0].fields.${option.Id}`}
																			value={
																				formikContext.values.rules[0].fields[
																					option.Id
																				] === true || false
																			}
																			onChecked={() => {
																				let data: any = rules[0].fields || {};

																				data[option.Id] = true;
																				formikContext.setFieldValue(
																					`rules[0].fields`,
																					data
																				);
																			}}
																			onUnchecked={() => {
																				let data = rules[0].fields || [];
																				delete data[option.Id];
																				formikContext.setFieldValue(
																					`rules[0].fields`,
																					data
																				);
																			}}
																		/>
																	))}
																</>
															);
														})}
													</div>
												</div>
											)}
										</EventSection>
									);
								})}
					</>
				</Well>
			) : (
				<Well>
					<p>
						Control who can access this content using the options below. Attendees matching any of the
						selected options will be able to view the content.{" "}
						{/* <span>
							<a
								href="https://brushfire.zendesk.com/hc/en-us/articles/360013222999"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <i className="fal fa-long-arrow-right" />
							</a>
						</span> */}
					</p>
				</Well>
			)}
		</>
	);
};

export default ViewerAccessSection;
