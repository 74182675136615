import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, AvailableCodeProviders } from "../../interfaces";
import { LinkButton, DrawerLinkButton } from "./Links.styled";
import { logEvent } from "../../services/analytics";

export interface LinkElement {
	index: number;
	element: any;
}

interface CodeProviderHelper {
	codeLinkHeaders: any[];
	codeLinkElements: LinkElement[];
}

interface LinkButtonInfo {
	index: number;
	icon: string;
    isTablet: boolean;
    isDrawerItem: boolean;
}

export const whichProvider = (link: Link) => {
	let provider: string | boolean = "";
	if (link.hasOwnProperty("codeProvider")) {
		provider = AvailableCodeProviders[link.codeProvider as number] || "notfound";
	} else {
		return false;
	}
	return provider;
};

export const codeProviderHelper = (links: Link[]): CodeProviderHelper => {
	const codeLinkHeaders: any[] = [];
	const codeLinkElements: LinkElement[] = [];

	links.forEach((link, index) => {
		if (link.hasOwnProperty("codeProvider")) {
			const provider = whichProvider(link);
			switch (provider) {
				case "planningCenterGiving": {
					codeLinkHeaders.push(<script src="https://js.churchcenter.com/modal/v1" />);
					codeLinkElements.push({
						index,
						element: (
							<a href={`https://${link.code}.churchcenter.com/giving?open-in-church-center-modal=true`}>
								{link.text}
							</a>
						),
					});
					break;
				}
				case "tithely": {
					codeLinkElements.push({
						index,
						element: <a href={link.code}>{link.text}</a>,
					});
					break;
				}
				default:
					break;
			}
		}
	});

	return {
		codeLinkHeaders,
		codeLinkElements,
	};
};

export const elementRender = (provider: string, elementInfo: LinkElement, linkButtonInfo: LinkButtonInfo) => {
	// use this to visually render the button
	const { index, icon, isDrawerItem } = linkButtonInfo;
	switch (provider) {
		case "planningCenterGiving": {
			const { href, children } = elementInfo.element.props;
			return isDrawerItem ? (
				<DrawerLinkButton
					key={`link-${index + 1}`}
					href={href}
					onClick={() => logEvent("click_client_link", children)}
				>
					<h2>{children as string}</h2>
				</DrawerLinkButton>
			) : (
				<LinkButton
					key={`link-${index + 1}`}
					href={href}
					onClick={() => logEvent("click_client_link", children)}
				>
					{icon ? <i className={icon} style={styles.iconStyle} /> : null}
					<span>{children as string}</span>
				</LinkButton>
			);
		}
		case "tithely": {
			const { href, children } = elementInfo.element.props;
			return (
				<>
					<Helmet>
						<script>var tw = create_tithely_widget();</script>
					</Helmet>
					{isDrawerItem ? (
                        <DrawerLinkButton
                            className="tithely-give-btn"
                            key={`link-${index + 1}`}
                            data-church-id={href}
							onClick={() => logEvent("click_client_link", children)}
                        >
                            <h2>{children as string}</h2>
                        </DrawerLinkButton>
                    ) : (
                        <LinkButton
                            className="tithely-give-btn"
                            key={`link-${index + 1}`}
                            data-church-id={href}
							onClick={() => logEvent("click_client_link", children)}
                        >
                            {icon ? <i className={icon} style={styles.iconStyle} /> : null}
                            <span>{children as string}</span>
                        </LinkButton>
                    )}
				</>
			);
		}
		default:
			break;
	}
};

const styles = {
	container: {
		width: "100%",
		height: "100%",
	},
	iconStyle: {
		marginRight: 5,
	},
};
