import React, { useState, useEffect } from "react";
import { Well, PageTitle } from "../../UI";
import RequestButton from "../HostManager/RequestButton";
import { ChatRequest, Content, ChatMessage, Theme } from "../../../../interfaces";
import ChatBlock from "../../../../components/Chat/ChatBlock";
import { Row, Col, Button } from "react-bootstrap";
import { TypedUseSelectorHook } from "../../../../redux";
import { useSelector } from "react-redux";
import firebase from "../../../../firebase";
import { ThemeProvider } from "styled-components";
import isLightOrDark from "../../../../utils/isLightOrDark";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { isEmpty } from "lodash";

const theme: Theme = {
	textColor: "#24272b",
	buttonTextColor: "#FFFFFF",
	buttonBackgroundColor: "#004bed",
	buttonHoverBackgroundColor: "#153d97",
	backgroundColor: "#b1bac5",
	backgroundImage: "",
	wellTextColor: "#FFFFFF",
	wellBackgroundColor: "#24272b",
	navBackgroundColor: "#24272b",
	navLinkColor: "#FFFFFF",
	navLinkHoverColor: "#004bed",
};

const MessagesContainer = styled.div`
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
	.chat-block-wrapper {
		margin: 0.5em 0;
	}
	@media screen and (max-width: 768px) {
		display: none;
		&.active {
			padding: 0;
			display: block;
			height: 400px;
			padding-top: 10px;
		}
	}
`;

const ChatContainer = styled.div`
	padding: 0 15px;
	overflow-x: hidden;
	overflow-y: auto;
	height: 500px;
	border-right: solid 2px #2d2f32;
	.hostchat-home-btn {
		color: white;
	}
	@media screen and (max-width: 768px) {
		border-right: none;
		&.active {
			height: auto;
		}
	}
`;

const ExportButton = styled(Button)`
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #4a525a !important;
	border: none;
	width: 100%;
	@media screen and (min-width: 768px) {
		padding: 20px;
		margin-bottom: 1em;
	}
`;

const MobileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1em;
`;

const db = firebase.firestore();
const useTypedSelector: TypedUseSelectorHook = useSelector;

interface Messages {
	[key: string]: ChatMessage[];
}

interface HostChatProps {
	hostChats: ChatRequest[];
	contentId: string;
	contentData: Content;
}

interface ViewerDoc {
	[analyticsUserId: string]: UserAnalytics;
}

export interface UserAnalytics {
	firebaseId: string;
	analyticsId: string;
	analyticsUserId: string;
	name?: string;
	email?: string;
	isOnline: boolean;
	attendeeNumber?: string;
	authorized?: boolean;
	eventNumber?: string;
	typeName?: string;
	firstName?: string;
	lastName?: string;
}

const HostChats: React.FC<HostChatProps> = ({ hostChats, contentId, contentData }) => {
	const [activeChat, setActiveChat] = useState<ChatRequest | null>();
	const [messages, setMessages] = useState<Messages>({});
	const [viewerData, setViewerData] = useState<ViewerDoc>({});
	const user = useTypedSelector(state => state.user);
	const { user: dashboardUser } = user;
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const isLightBackground = isLightOrDark(theme.wellBackgroundColor) === "light";

	const fetchHostChatMessages = async () => {
		if (activeChat) {
			await db
				.collection("chat")
				.doc("contents")
				.collection(activeChat.id)
				.orderBy("timestamp", "asc")
				.get()
				.then(messageData => {
					let hostMessages: ChatMessage[] = [];
					messageData.forEach(message => {
						hostMessages.push(message.data() as ChatMessage);
					});
					if (hostMessages.length) {
						setMessages(prevState => ({
							...prevState,
							[activeChat.id]: hostMessages,
						}));
					}
				});
		}
	};

	useEffect(() => {
		if (activeChat && !messages[activeChat.id]) {
			fetchHostChatMessages();
		}
	}, [activeChat]);

	const fetchViewerData = async () => {
		let viewers: ViewerDoc = {};
		const viewerDocs = await db
			.collection("analytics")
			.doc("viewers")
			.collection(contentId)
			.get();
		viewerDocs.forEach(viewerDoc => {
			const viewerData = viewerDoc.data() as ViewerDoc;
			viewers = { ...viewers, ...viewerData };
			setViewerData(viewers);
		});
		return viewers;
	};

	const generateTranscriptCsv = async (chats: ChatMessage[]) => {
		let viewers = isEmpty(viewerData) ? await fetchViewerData() : viewerData;
		let hasPaywall = Object.values(viewers).some(v => v.eventNumber !== null);
		let csvTranscript = hasPaywall
			? "Event Number,Attendee Number,Attendee Name,Type Name,(Signin) Name,(Signin) Email,Message Content,Timestamp,Like Count,Flag Count,Host\n"
			: "(Signin) Name,(Signin) Email,Message Content,Timestamp,Like Count,Flag Count,Host\n";
		let regexp = new RegExp("#", "g");

		let csvUserName = `${chats
			.find(chat => chat.isHost === false)
			?.userName.toLowerCase()
			.split(" ")
			.join("-")}-host-chat-data`;

		chats.forEach(chat => {
			let userData = Object.values(viewers).find(v => v.firebaseId === chat.uid);
			csvTranscript = hasPaywall
				? `${csvTranscript}${userData?.eventNumber || ""},${userData?.attendeeNumber ||
						""},"${userData?.firstName || ""} ${userData?.lastName || ""}",${userData?.typeName || ""},${
						chat.userName
				  },${chat?.email || chat?.email || ""},"${chat.message.replace(regexp, "_HASHTAG_")}",${moment
						.unix(
							//@ts-ignore
							chat.timestamp.seconds
						)
						.toLocaleString()},${chat.likes?.length || 0},${chat.flags?.length || 0},${chat.isHost}\n`
				: `${csvTranscript}${chat.userName},${chat?.email || chat?.email || ""},"${chat.message.replace(
						regexp,
						"_HASHTAG_"
				  )}",${moment
						.unix(
							//@ts-ignore
							chat.timestamp.seconds
						)
						.toLocaleString()},${chat.likes?.length || 0},${chat.flags?.length || 0},${chat.isHost}\n`;
		});
		let hiddenElement = document.createElement("a");
		hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvTranscript);
		hiddenElement.target = "_blank";
		hiddenElement.download = csvUserName ?? "host-chat-transcript.csv";
		hiddenElement.click();
	};

	return (
		<>
			<PageTitle>Host Chats</PageTitle>
			<ThemeProvider theme={theme}>
				<Well>
					<Row>
						<Col className="p-0" md={12} lg={6}>
							<ChatContainer className={activeChat ? "active" : ""}>
								{(!isMobile || (isMobile && !activeChat)) &&
									hostChats.map(chat => {
										return (
											<RequestButton
												uid={chat.users.filter(x => x !== dashboardUser?.uid)[0]}
												key={chat.id}
												active={(activeChat && activeChat.id === chat.id) ?? false}
												onClick={() => setActiveChat(chat)}
												title={
													chat.createdBy === dashboardUser?.uid
														? chat.recipientUserName || ""
														: chat.userName || ""
												}
												contentId={contentId}
											/>
										);
									})}
							</ChatContainer>
						</Col>
						{activeChat && messages && messages[activeChat.id] && (
							<Col md={12} lg={6} style={{ marginTop: "10px" }}>
								{isMobile && activeChat && (
									<MobileHeader>
										<h4>
											{activeChat.createdBy === dashboardUser?.uid
												? activeChat.recipientUserName || ""
												: activeChat.userName || ""}
										</h4>
										<div className="hostchat-home-btn" onClick={() => setActiveChat(null)}>
											Chat <i className="fal fa-exchange show-mobile"></i>
										</div>
									</MobileHeader>
								)}
								<ExportButton onClick={() => generateTranscriptCsv(messages[activeChat.id])}>
									<span>Export </span>
									<i className="far fa-file-export"></i>
								</ExportButton>
								<MessagesContainer className={activeChat ? "active" : ""}>
									{messages[activeChat.id].map(m => {
										return (
											<ChatBlock
												key={m.id}
												convo={m}
												toggleConvoLike={() => null}
												toggleConvoFlag={() => null}
												delConvo={() => null}
												banUser={() => null}
												unbanUser={() => null}
												chatId={m.id}
												clientId={contentData.clientId}
												uid={m.uid}
												lastUID={""}
												isModerator={true}
												bannedUsers={[""]}
												isLightBackground={isLightBackground}
												isHostManager={false}
												chatClosed={true}
												useChatProcessor={contentData?.useChatProcessor || false}
												userLoggedIn={true}
												photoURL={m.photoURL}
												userName={m.userName}
												isDirect={true}
												setCurrentChat={() => null}
											/>
										);
									})}
								</MessagesContainer>
							</Col>
						)}
					</Row>
				</Well>
			</ThemeProvider>
		</>
	);
};

export default HostChats;
