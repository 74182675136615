import React from "react";
import { ToastMessageText } from "../Toast.styled";

interface ToastProps {
	type?: string;
	text?: string;
}

const Toast: React.FC<ToastProps> = ({ type, text }) => {
	let color, message;

	if (type === "validating") {
		color = "gray";
		message = text;
	} else if (type === "success") {
		color = "green";
		message = text;
	} else {
		color = "red";
		message = text;
	}

	return (
		<ToastMessageText color={color} className="mr-auto">
			{message}
		</ToastMessageText>
	);
};

export default Toast;
