// all 3 video components should be split up

import Bible, { BibleSectionProps } from "./Bible";
import BrushfireEvent, { BrushfireEventSectionProps } from "./BrushfireEvent";
import Chat, { ChatSectionProps } from "./Chat";
import Integrations, { IntegrationsSectionProps } from "./Integrations";
import Interactions, { InteractionsSectionProps } from "./Interactions";
import Links, { LinkCardWrapperProps } from "./Links";
import Notes, { NotesSectionProps } from "./Notes";
import OnDemandAvailability, { OnDemandAvailabilitySectionProps } from "./OnDemandAvailability";
import Reactions, { ReactionsSectionProps } from "./Reactions";
import Relations, { RelationsSectionProps } from "./Relations";
import Schedule, { ScheduleSectionProps } from "./Schedule";
import Share from "./Share";
import DefaultPanel from "./DefaultPanel";
import Video, { VideoTypes } from "./Video";
import ViewerAccess, { ViewerAccessSectionProps } from "./ViewerAccess";

interface SectionsTypes {
	Bible: React.FC<BibleSectionProps>;
	BrushfireEvent: React.FC<BrushfireEventSectionProps>;
	Chat: React.FC<ChatSectionProps>;
	DefaultPanel: React.FC;
	Integrations: React.FC<IntegrationsSectionProps>;
	Interactions: React.FC<InteractionsSectionProps>;
	Links: React.FC<LinkCardWrapperProps>;
	Notes: React.FC<NotesSectionProps>;
	OnDemandAvailability: React.FC<OnDemandAvailabilitySectionProps>;
	Reactions: React.FC<ReactionsSectionProps>;
	Relations: React.FC<RelationsSectionProps>;
	Schedule: React.FC<ScheduleSectionProps>;
	Share: React.FC;
	Video: VideoTypes;
	ViewerAccess: React.FC<ViewerAccessSectionProps>;
}

export const Sections: SectionsTypes = {
	Bible,
	BrushfireEvent,
	Chat,
	DefaultPanel,
	Integrations,
	Interactions,
	Links,
	Notes,
	OnDemandAvailability,
	Reactions,
	Relations,
	Schedule,
	Share,
	Video,
	ViewerAccess,
};
