import React from "react";
import { FieldProps } from "formik";
import {sanitizeField} from "../../Field/Field";

export interface TextAreaProps<T = any> {
	field?: FieldProps["field"];
	fieldref?: React.RefObject<T>;
	form?: FieldProps["form"];
	id: string;
	name: string;
	onKeyPress?: (e: React.KeyboardEvent) => void;
	onBlur?: (e: React.FocusEvent) => void;
	ref?: React.RefObject<any>;
	rows?: number;
	value?: string;
	[key: string]: any;
}

const TextArea: React.FC<TextAreaProps> = ({ field, form, fieldref, ref, onBlur, ...props }) => {
	return (
		<textarea
			className="textarea"
			{...props}
			{...field}
			name={(field && field.name) || props.name}
			value={(field && field.value) || props.value}
			ref={fieldref || ref}
			onBlur={e => {
				form?.handleBlur(e);
				if (onBlur) onBlur(e);
			}}
			onChange={e => {
				sanitizeField(e, form, field)
			}}
		/>
	);
};

export default TextArea;
