import React, { useState } from "react";
import { DashboardHeader } from "..";
import Footer from "../../../../components/Footer/Dashboard/Footer";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

interface PageContainerProps {
	size?: "sm" | "md" | "lg" | "full";
	showHeader?: boolean;
	className?: string;
	htmlPageTitle: string;
}

const PageContainer: React.FC<PageContainerProps> = ({
	children,
	size = "lg",
	showHeader = true,
	className = "",
	htmlPageTitle,
}) => {
	const [expanded, setExpanded] = useState(
		localStorage.getItem("sideMenuExpanded") ? localStorage.getItem("sideMenuExpanded") === "true" : true
	);
	const toggleSideMenu = () => {
		localStorage.setItem("sideMenuExpanded", expanded ? "false" : "true");
		setExpanded(!expanded);
	};
	const product =
		window.location && window.location.hostname.includes("churchx.online") ? "Church X" : "Brushfire Online";

	return (
		<>
			<Helmet>
				<title>{`${htmlPageTitle} | ${product}`}</title>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			{showHeader && <DashboardHeader expanded={expanded} toggleSideMenu={toggleSideMenu} />}

			<main className={`page-container ${size} ${expanded ? "expanded" : ""} ${className}`}>
				{children}
				<Footer />
			</main>
		</>
	);
};

export default PageContainer;
