import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Content, ContentType } from "../../../interfaces";
import { CustomDomainContext } from "../../../navigation/PublicRoutes";
import attendeeHasContentAccess from "../../../services/attendeeHasContentAccess";
import { timeZoneAbbreviation } from "../../../utils/timezones";
import LiveBadge from "../../LiveBadge";
import { AttendeeDataContext } from "../../Paywall";
import * as Styled from "./VideoCard.styled";

interface VideoCardProps {
	video: Content;
	clientKey?: string;
	channelKey?: string;
	contentKey?: string;
	onVideoEnd: () => void;
}

export const VideoCard: React.FC<VideoCardProps> = ({ video, clientKey, channelKey, contentKey, onVideoEnd }) => {
	const { name, thumbImage, duration, contentType, startsAt } = video;
	const customDomainData = useContext(CustomDomainContext);
	const { attendeeData, paywallEnabled } = useContext(AttendeeDataContext);
	const history = useHistory();

	let userHasAccess = false;

	if (!!paywallEnabled && attendeeData && video.videoAccessRuleEnabled) {
		userHasAccess = attendeeHasContentAccess(video, attendeeData);
	} else {
		userHasAccess = true;
	}

	const url = userHasAccess
		? customDomainData
			? `/${channelKey}/${video.key}`
			: `/${clientKey}/${channelKey}/${video.key}`
		: "#";

	const setAsLive = !contentKey
		? () => {
			if (!contentKey) {
				history.push(url);
			}
		}
		: undefined;

	const scrollToTop = () => {
		window.scrollTo(
			{
				top: 200,
				left: 0,
				behavior: 'smooth'
			});
	}


	return (
		<Styled.CardWrapper className="card">
			<Styled.LinkContainer onClick={scrollToTop} to={url} className={!userHasAccess ? "disabled" : ""}>
				<Styled.CardContainer>
					<Styled.VideoImageWrapper>
						{!userHasAccess && (
							<>
								<div className="lock-bg"></div>
								<i className="fal fa-lock"></i>
								<div className="not-available-label">
									Not Available
									<br />
									<small>With Your Registration</small>
								</div>
							</>
						)}
						<Styled.VideoImage src={thumbImage} alt="placeholder" />
					</Styled.VideoImageWrapper>

					<Styled.InfoContainer>
						<Styled.TitleRow>
							<Styled.Title>{name}</Styled.Title>
							<LiveBadge contentData={video} onVideoEnd={onVideoEnd} setAsLive={setAsLive} isFrontEnd />
						</Styled.TitleRow>
						{startsAt && contentType !== ContentType.onDemand && (
							<Styled.Date>
								{moment(startsAt).format("dddd, MMMM D, YYYY h:mm A")} (
								{timeZoneAbbreviation(moment(startsAt).toISOString())})
							</Styled.Date>
						)}
						<Styled.DurationWrapper>
							{duration && contentType === ContentType.onDemand && (
								<Styled.Duration>
									<i className="fal fa-clock"></i> {duration} minutes
								</Styled.Duration>
							)}
						</Styled.DurationWrapper>
					</Styled.InfoContainer>
				</Styled.CardContainer>
			</Styled.LinkContainer>
		</Styled.CardWrapper>
	);
};
