/**
 * Strips HTML tags from a string (simply) by creating an element in the document and returning .textContent or .innerText
 * @param {string} html
 * @returns string
 */
export const stripHtml = (html: string): string => {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};
