import React from "react";
import IFrame from "react-iframe";

interface BibleProps {
	url?: string;
	showBible?: boolean;
}

export const BIBLE_COM_REGEX = /^(https:\/\/(chop.|www.)?bible\.com)(\/[A-Za-z]*)?(\/bible)(\/\d+)(\/(\w{3})\.(\d{1,3})(\.(\w{2,}))?)$/;
const DEFAULT_PASSAGE = "https://www.bible.com/en/bible/1/GEN.1.KJV";

const Bible: React.FC<BibleProps> = ({ url, showBible }) => {
	let realPassage: string;
	if (url) {
		const match = url.match(BIBLE_COM_REGEX);
		if (match) {
			if (!match[3]) {
				realPassage = url.replace(BIBLE_COM_REGEX, `$1/en$4$5$6`);
			} else {
				realPassage = url;
			}
		} else {
			realPassage = DEFAULT_PASSAGE;
		}
	} else {
		realPassage = DEFAULT_PASSAGE;
	}

	realPassage = realPassage.replace("chop.", "www.");

	return (
		<div style={{ height: "100%", width: "100%", display: showBible ? "block" : "none" }}>
			<IFrame
				url={realPassage}
				id="bible-iframe"
				className="bible-iframe"
				width="100%"
				height="100%"
				frameBorder={0}
			/>
		</div>
	);
};

export default Bible;
