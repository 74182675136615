import React, { useState, useEffect } from "react";
import { FieldProps } from "formik";
//@ts-ignore
import FileUploader from "react-firebase-file-uploader";
import { TypedUseSelectorHook } from "../../../../redux";
import fb from "../../../../firebase";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const MAX_FILE_SIZE = 5242880; // 5MB

type UploadInputTypes = "text" | "email" | "number";

export interface IUploaderProps {
	field: FieldProps["field"];
	form: FieldProps["form"];
	id: string;
	ref?: React.RefObject<any>;
	[key: string]: any;
	clientId: string;
	helperText?: string;
	error?: boolean;
	isSaving?: boolean;
	square?: boolean;
}

export interface FileData {
	fileName: string;
	url: string;
	cdnUrl: string;
	uses: number;
	createdAt: number;
}

export interface FileRecords {
	data: FileData[];
}

interface ImageSize {
	width: number;
	height: number;
}

const UploadInput: React.FC<IUploaderProps> = ({
	ield,
	field,
	form,
	clientId,
	helperText,
	error,
	isSaving,
	square,
	...props
}) => {
	const { value } = field;

	const [existingFiles, setExistingFiles] = useState<FileData[]>([]);
	const [progress, setProgress] = useState<number>(0);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [showFileManager, setShowFileManager] = useState<boolean>(false);
	const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
	const [localError, setLocalError] = useState<string | null>(null);
	//const [fileName, setFileName] = useState<string | undefined>(value?.split("/")[value?.split("/").length - 1]);

	const getExistingFiles = async () => {
		let db = fb.firestore();
		var docRef = db.collection("image-data").doc(clientId);
		let doc = await docRef.get();
		if (doc.exists) {
			let existingFiles = doc.data()?.data.concat();
			setExistingFiles(existingFiles);
		}
	};

	useEffect(() => {
		if ((form.isSubmitting || isSaving) && !!localError) {
			setLocalError(null);
		}
	}, [form.isSubmitting, localError, isSaving]);

	useEffect(() => {
		getExistingFiles();
	}, []);

	let displayName = value?.split("/")[value?.split("/").length - 1].split("?")[0];

	const dashboard = useTypedSelector(store => store.dashboard);

	const getFile = (name: string) => {
		let existing = existingFiles.find(f => name === f.fileName);
		return existing;
	};

	const handleUploadStart = async (file: File, task: firebase.storage.UploadTask) => {
		setLocalError(null);
		if (file.size > MAX_FILE_SIZE) {
			task.cancel();
			setLocalError("File is too big. Max file size is 5MB.");
		} else if (square && !(await isSquare(file))) {
			task.cancel();
			setLocalError("File has to be square");
		} else {
			setIsUploading(true);
			setProgress(0);
		}
	};

	const isSquare = async (file: File) => {
		const { width, height }: ImageSize = await getImageSize(file);
		return width - height === 0;
	};

	const getImageSize = (file: File): Promise<ImageSize> =>
		new Promise(resolve => {
			const img = new Image();
			const url = URL.createObjectURL(file);
			img.onload = () => {
				resolve({ width: img.width, height: img.height });
			};
			img.src = url;
		});

	const handleProgress = (currentProgress: number) => {
		setProgress(currentProgress);
	};

	const handleUploadError = (error: string) => {
		setIsUploading(false);
		if (props.uploadError) {
			props.uploadError(error);
		}
	};

	const handleUploadSuccess = (fileName: string) => {
		//setFileName(fileName);
		setProgress(100);
		setIsUploading(false);
		fb.storage()
			.ref(clientId)
			.child(fileName)
			.getDownloadURL()
			.then(url => {
				let cdnUrl = `https://media.online.brushfire.com/${clientId}/${fileName}`;
				let db = fb.firestore();
				let docRef = db.collection("image-data").doc(clientId);
				let objectToUpsert: FileRecords = {
					data: [],
				};
				docRef
					.get()
					.then(doc => {
						if (doc.exists) {
							objectToUpsert.data = doc.data() ? doc.data()?.data.concat() : [];
						}
						let object: FileData = {
							fileName,
							url,
							cdnUrl,
							uses: 1,
							createdAt: new Date().getTime(),
						};
						objectToUpsert.data.push(object);
						docRef.set(objectToUpsert);
					})
					.catch(function(error) {
						console.warn("File not added to Firebase records:", error);
					});
				form.setFieldValue(field.name, cdnUrl);
				form.validateForm({ ...form.values, [field.name]: cdnUrl });
				setTimeout(() => {
					setProgress(0);
				}, 2000);
				// if (this.props.uploadSuccess) {
				// 	this.props.uploadSuccess(cdnUrl);
				// }
			});
	};

	const fileNamer = (file: File) => {
		let name = file.name;
		name = name.replace(/\s+/g, "-").toLowerCase();
		let nameExists = true;
		let index = 0;
		if (existingFiles) {
			while (nameExists) {
				let existing = getFile(name);
				if (existing) {
					//Warn user
					let parts = file.name.split(".");
					parts[0] = `${parts[0]}-${index}`;
					name = parts.join(".");
					name = name.replace(/\s+/g, "-").toLowerCase();
					index++;
				} else {
					nameExists = false;
				}
			}
		}

		return name;
	};

	const chooseExisting = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		setShowFileManager(true);
	};

	const getRealUrl = (file: any) => {
		return "cdnUrl" in file ? file.cdnUrl : file.url;
	};

	const selectExisting = () => {
		setShowFileManager(false);
		setLocalError(null);
		form.setFieldValue(field.name, selectedUrl);
		form.validateForm({ ...form.values, [field.name]: selectedUrl });
	};

	const renderFileList = () => {
		if (!existingFiles) {
			return (
				<div className="file-manager-window relative">
					<div className="absolute-center ta-center">
						<h2>No Files</h2>
					</div>
				</div>
			);
		}
		existingFiles.sort(function(a, b) {
			var nameA = a.fileName.toLowerCase(),
				nameB = b.fileName.toLowerCase();
			if (nameA < nameB)
				//sort string ascending
				return -1;
			if (nameA > nameB) return 1;
			return 0; //default return value (no sorting)
		});
		let foundFile: FileData | undefined = undefined;
		if (selectedUrl) {
			foundFile = existingFiles.find(file => getRealUrl(file) === selectedUrl);
		}
		return (
			<div className="file-manager-window">
				<div className="file-list">
					{existingFiles.map(file => {
						let failsafeUrl = getRealUrl(file);
						return (
							<div
								className={`${selectedUrl === failsafeUrl ? "on" : ""}`}
								key={file.createdAt}
								onClick={() => setSelectedUrl(failsafeUrl)}
							>
								{file.fileName}
							</div>
						);
					})}
				</div>
				<div className="file-confirmation">
					{selectedUrl && (
						<div>
							<div className="file-confirmation-preview">
								<img src={selectedUrl} alt="selected url" />
							</div>
							<div className="p-top p-bottom">
								<div>Added</div>
								<h4 className="m-0">{moment(foundFile?.createdAt).format("LLL")}</h4>
							</div>
							<div className="btn btn-primary wide select-file" onClick={selectExisting}>
								Select
							</div>
						</div>
					)}
				</div>
			</div>
		);
	};

	let imagePreviewStyle = {};

	if (value) {
		imagePreviewStyle = { backgroundImage: `url('${value.replace(/\(/g, "%28").replace(/\)/g, "%29")}')` };
	}

	return (
		<div className="relative">
			<div
				className={`coverall global-coverall more ${showFileManager ? "active" : ""}`}
				onClick={() => {
					setShowFileManager(false);
				}}
			></div>
			<div className={`file-picker-modal ${showFileManager ? "on" : "off"}`}>
				{showFileManager && renderFileList()}
			</div>
			<label className="upload-label">
				<div className={`input-wrap upload-wrap ${error ? "upload-errors" : ""}`}>
					<div className="row m-0">
						<div className="col-sm-3 p-0">
							<div
								className={`upload-image-preview m-right ${value ? "on" : ""}`}
								style={imagePreviewStyle}
							>
								<div className="upload-icon">
									<i className="fal fa-file-upload"></i>
								</div>
								<div
									className="image-remove"
									onClick={event => {
										event.preventDefault();
										event.stopPropagation();
										form.setFieldValue(field.name, null);
									}}
								>
									<i className="fal fa-times-circle"></i>
								</div>
							</div>
						</div>
						<div className="col-sm-9 p-0">
							<div className={`btn btn-primary btn-block btn-sm ${isUploading ? "disabled" : ""}`}>
								Upload
							</div>
							<div
								className={`btn btn-secondary btn-block btn-sm m-top ${isUploading ? "disabled" : ""}`}
								onClick={chooseExisting}
							>
								Choose Existing
							</div>
						</div>
					</div>
					<FileUploader
						hidden
						accept="image/*"
						name={field.name}
						filename={fileNamer}
						storageRef={fb.storage().ref(clientId)}
						onUploadStart={handleUploadStart}
						onUploadError={handleUploadError}
						onUploadSuccess={handleUploadSuccess}
						onProgress={handleProgress}
						onBlur={field.onBlur}
						{...props}
					/>
					<div className={`progress ${progress !== 0 ? "active" : ""}`}>
						<div className="progress-bar" style={{ width: `${progress}%` }}></div>
					</div>
				</div>
			</label>

			<small>{helperText ? helperText : displayName}</small>

			{localError && <div className="error">{localError}</div>}
		</div>
	);
};

export default UploadInput;
