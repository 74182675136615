import React from "react";
import { User, Content, Client } from "../../interfaces";
import userHasRole from "../../services/userHasRole";

interface FooterNavigationProps {
	isLightBackground?: boolean;
	appUser?: User;
	content?: Content;
	client?: Client;
}

const FooterNavigation: React.FC<FooterNavigationProps> = ({ appUser, content, isLightBackground, client }) => {
	const color = isLightBackground ? "#202020" : "white";
	const hasDashboardAccess =
		!!appUser && userHasRole(client?.id || "", appUser, ["owner", "admin", "host", "superhost"]);
	const isHost = !!appUser && userHasRole(client?.id || "", appUser, ["host", "superhost"]);
	const isDashboard = window.location.toString().includes("/dashboard/");

	return (
		<div id="footer-navigation">
			<a
				href="https://get.brushfire.help/hc/en-us/articles/360011675720-Terms-of-Use"
				style={{ color }}
				target="_blank"
				rel="noopener noreferrer"
			>
				Terms of Use
			</a>

			<a
				href="https://get.brushfire.help/hc/en-us/articles/360011675740-Privacy-Policy"
				style={{ color }}
				target="_blank"
				rel="noopener noreferrer"
			>
				Privacy Policy
			</a>

			<a
				href="https://www.brushfire.com/support/"
				style={{ color }}
				target="_blank"
				rel="noopener noreferrer"
			>
				Support
			</a>
			{content && appUser && (
				<>
					<a href="/dashboard" style={{ color }}>
						{`${appUser.firstName} ${appUser.lastName}`}
					</a>{" "}
					{!isHost && (
						<a href={`/dashboard/${content.clientId}/contents/${content.id}`} style={{ color }}>
							Edit
						</a>
					)}
					<a href={`/dashboard/${content.clientId}/contents/${content.id}/host`} style={{ color }}>
						Host Panel
					</a>
				</>
			)}
			{hasDashboardAccess && !isDashboard && (
				<div className="footer-admin-links">
					<a href="/dashboard" style={{ color }} target="_blank" rel="noopener noreferrer">
						Back to Dashboard
					</a>
				</div>
			)}
		</div>
	);
};

export default FooterNavigation;
