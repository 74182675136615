import React, { useEffect, useState } from "react";
import firebase from "../../../../../firebase";
import { Interaction, Content } from "../../../../../interfaces";
import Form, { FormContext } from "../../../../../components/Form";
import { validateInteractions } from "../../../../../pages/Dashboard/Contents/Form/validateInteractions";
import { InteractionCard } from "../../../UI/FormSections/Interactions/InteractionsSection";
import { v4 as uuidv4 } from "uuid";
import { findIndex, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { TypedUseSelectorHook } from "../../../../../redux";
import { useToast } from "../../../../../components/Toast/ToastProvider";
import * as Styled from "./PreviewPanel.styled";

const db = firebase.firestore();

interface CreateInteractionPanelProps {
	initialInteractions: Interaction[];
	interactionId?: string;
	setCurrentPreview: (id: string) => void;
	setEditPanelOpen: (setOpen: boolean) => void;
}

export const CreateInteractionPanel: React.FC<CreateInteractionPanelProps> = ({
	initialInteractions,
	interactionId,
	setCurrentPreview,
	setEditPanelOpen,
}) => {
	const [isSaving, setIsSaving] = useState(false);
	const [interactions, setInteractions] = useState<any[]>([]);
	const [interactionIndex, setInteractionIndex] = useState<number | undefined>(undefined);
	const { addToast } = useToast();

	const useTypedSelector: TypedUseSelectorHook = useSelector;
	const dashboard = useTypedSelector(store => store.dashboard);

	const onSubmit = async (values: any) => {
		if (interactionIndex !== undefined) {
			let interaction = values.interactions[interactionIndex];
			if (!isEmpty(interaction) && dashboard.clientId && dashboard.contentId) {
				addToast("validating", "Interaction saving...");
				setIsSaving(true);
				interaction.versionCode = null;
				interaction.clientId = dashboard.clientId;
				interaction.contentId = dashboard.contentId;
				if (!interaction.displayOrder) {
					interaction.displayOrder = interactions.length - 1;
				}
				if (!isEmpty(interaction.responses)) {
					interaction.responses.forEach((response: any) => {
						if (isEmpty(response.id)) {
							response.id = uuidv4();
						}
					});
				}
			}
			try {
				if (dashboard.contentId) {
					await db
						.collection("interactions")
						.doc("contents")
						.collection(dashboard.contentId)
						.doc(interaction.id)
						.set(interaction)
						.then(() => {
							setEditPanelOpen(false);
							setCurrentPreview(interaction.id);
							addToast("success", "Interaction saved!");
						});
				}
			} catch (error) {
				console.warn("Error submitting interaction", error);
				addToast("error", "Error adding interaction");
			} finally {
				setIsSaving(false);
			}
		}
	};

	useEffect(() => {
		const newId = uuidv4();
		let interactionData: any[] = [...initialInteractions];
		if (!interactionId) {
			interactionData.push({
				id: newId,
				interactionTitle: "",
				interactionText: "",
				showAt: null,
				hideAt: null,
				urgency: "focus",
				responses: [],
				versionCode: "",
			});
		}
		setInteractions(interactionData);
		let index = interactionId ? findIndex(interactionData, { id: interactionId }) : interactionData.length - 1;
		setInteractionIndex(index);
	}, [interactionId]);
	return (
		<div className="d-flex h-100 w-100 flex-column">
			<div className="show-mobile m-bottom" onClick={() => setEditPanelOpen(false)}>
				<h3 className="ellipsis cursor-pointer">
					<i className="fas fa-chevron-left m-right"></i>
					New Interaction
				</h3>
			</div>
			<h3 className="pb-1 mt-0 hide-mobile">New Interaction</h3>
			{interactions && interactionIndex !== undefined && (
				<Form
					style={{ height: "100%", display: "flex", flexDirection: "column" }}
					initialValues={{ interactions: [...interactions] }}
					validate={validateInteractions}
					onSubmit={onSubmit}
				>
					{({ formikContext }: FormContext<Content>) => {
						return (
							<>
								<Styled.InnerFormContainer>
									<InteractionCard
										interactionIndex={interactionIndex}
										interaction={
											formikContext.values["interactions"]?.[interactionIndex] as Interaction
										}
										length={interactions.length}
										name={"interactions"}
										formikContext={formikContext}
										isHostManager={true}
									/>
								</Styled.InnerFormContainer>
								<Styled.SaveButton
									className="btn-block text-center"
									type="submit"
									disabled={!formikContext.isValid}
								>
									{isSaving ? "SAVING..." : "SAVE"}
								</Styled.SaveButton>
							</>
						);
					}}
				</Form>
			)}
		</div>
	);
};
