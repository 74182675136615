import React, { useState } from "react";

import Size from "../../Size";

import * as Styled from "./StickyTextInput.styled";

interface StickyTextInputProps {
	prefix: string;
	style?: React.CSSProperties;
}

/** Only pass in a Form.Field component that uses a plain text input. This isn't built to handle anything else. */
const StickyTextInput: React.FC<StickyTextInputProps> = ({ prefix, style, children }) => {
	const [prefixSize, setPrefixSize] = useState(0);
	return (
		<Styled.StickyInput prefixSize={prefixSize} style={style}>
			<Size element={<p>{prefix}</p>} onSize={size => setPrefixSize(size.width as number)}></Size>
			{children}
		</Styled.StickyInput>
	);
};

export default StickyTextInput;
