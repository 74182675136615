import { intersection } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GlobalLoader from "../../../components/GlobalLoader";
import firebase from "../../../firebase";
// import { FieldArray, FormikContextType } from "formik";
import { Channel, Client, Content } from "../../../interfaces";
import api from "../../../services/api";
import asyncForEach from "../../../utils/asyncForEach";
import { HorizontalRule, PageContainer, PageTitle, Well } from "../UI";

const db = firebase.firestore();

interface EventData {
	[eventNumber: string]: {
		spotsSynced: number;
		spotsTaken: number;
		spotsRemaining: number;
		titleInfo: string;
	};
}

const UpcomingPaywallContent: React.FC = () => {
	const [contents, setContents] = useState<Content[]>();
	const [channels, setChannels] = useState<Channel[]>();
	const [eventData, setEventData] = useState<EventData>();
	const [clients, setClients] = useState<Client[]>();
	const [eventsToFetch, setEventsToFetch] = useState<number>();
	const [eventsFetched, setEventsFetched] = useState(0);

	const getPaywallData = async (contents: Content[]) => {
		const channelIds: string[] = [];
		const eventData: EventData = {};
		contents.forEach(content => {
			content.channelIds?.forEach(channelId => {
				if (!channelIds.includes(channelId)) {
					channelIds.push(channelId);
				}
			});
		});

		const paywallChannels: Channel[] = [];

		await asyncForEach(channelIds, async (channelId: string) => {
			const channel = await db
				.collection("channels")
				.doc(channelId)
				.get();
			const channelData = channel.data() as Channel;
			if (channelData.brushfireEventIds?.length || 0 > 0) {
				paywallChannels.push(channelData);
				const eventNumbers = channelData.brushfireEventIds?.map(evt => evt.eventNumber);
				if (eventNumbers) {
					setEventsToFetch(eventNumbers.length);
					await asyncForEach(eventNumbers, async (eventNumber: string, index: number) => {
						if (!eventData[eventNumber]) {
							const results = await api.getEventData(eventNumber, false, false);
							const { Id, SpotsTaken, SpotsRemaining, TitleInfo } = results.data.event;
							const paywallDocs = await db
								.collection("paywall_access")
								.where("eventId", "==", Id)
								.get();
							eventData[eventNumber] = {
								spotsSynced: paywallDocs.size,
								spotsTaken: SpotsTaken,
								spotsRemaining: SpotsRemaining,
								titleInfo: TitleInfo,
							};
							setEventsFetched(index + 1);
						}
					});
				}
			}
		});
		setContents(contents);
		setChannels(paywallChannels);
		setEventData(eventData);
	};

	useEffect(() => {
		const contentsDocRef = db.collection("clients");
		contentsDocRef.get().then(querySnapshot => {
			let tempClientsList: Client[] = [];
			querySnapshot.forEach(doc => tempClientsList.push(doc.data() as Client));
			setClients(tempClientsList);
		});
		db.collection("contents")
			.where("startsAt", ">", moment().toISOString())
			.where(
				"startsAt",
				"<",
				moment()
					.add(14, "days")
					.toISOString()
			)
			.orderBy("startsAt", "asc")
			.get()
			.then(docs => {
				let tempContentsList: Content[] = [];
				docs.forEach(doc => {
					tempContentsList.push(doc.data() as Content);
				});
				getPaywallData(tempContentsList);
				//setContents(tempContentsList);
			});
	}, []);

	if (!contents || !clients || !channels || !eventData) {
		return (
			<>
				<GlobalLoader isActive />
				{!!eventsToFetch && (
					<h2>
						{eventsFetched}/{eventsToFetch} Events Fetched
					</h2>
				)}
			</>
		);
	}
	return (
		<PageContainer size="md" className="no-sidebar" htmlPageTitle="Upcoming Paywall Contents">
			<PageTitle>Upcoming Paywall Contents</PageTitle>

			{Object.keys(eventData).map(eventNumber => {
				const matchedChannels = channels.filter(channel =>
					channel.brushfireEventIds?.map(bf => bf.eventNumber).includes(eventNumber)
				);
				const channelIds = matchedChannels.map(chan => chan.id);
				const matchedContents = contents.filter(
					content => intersection(content.channelIds, channelIds).length > 0
				);

				const contentsWithChat = matchedContents.filter(content => content.chat.enabled);
				const contentsWithReactions = matchedContents.filter(content => content.chat.enabled);

				return (
					<Well>
						<a href={`https://brushfire.com/${eventNumber}`} target="_blank" className="white">
							<small>{eventData[eventNumber].titleInfo}</small>
						</a>
						<div>
							{eventData[eventNumber].spotsSynced} of {eventData[eventNumber].spotsTaken} Synced
						</div>
						<HorizontalRule></HorizontalRule>
						{matchedChannels.map(channel => {
							const client = clients.find(client => client.id === channel.clientId);
							return (
								<a
									href={`/dashboard/${channel.clientId}/channels/${channel.id}`}
									key={channel.id}
									style={{ color: "#FFFFFF" }}
									className="m-top"
								>
									<small>{client?.name}</small>
									<div>{channel.name}</div>
								</a>
							);
						})}
						<HorizontalRule></HorizontalRule>
						<small>{`${matchedContents.length} ${matchedContents.length !== 1 ? "contents" : "content"} | ${
							contentsWithChat.length
						} with chat | ${contentsWithReactions.length} with reactions`}</small>
					</Well>
				);
			})}
		</PageContainer>
	);
};

export default UpcomingPaywallContent;
