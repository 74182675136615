//@ts-ignore
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DashboardHeader } from "..";
import Footer from "../../../../components/Footer/Dashboard/Footer";
import { NavigatorSection } from "../../../../interfaces/NavigatorSection";
import { TypedUseSelectorHook } from "../../../../redux";
const useTypedSelector: TypedUseSelectorHook = useSelector;

interface SectionedPageContainerProps {
	size?: "sm" | "md" | "lg" | "full";
	showHeader?: boolean;
	sections: NavigatorSection[];
	footerLinks?: FooterLink[];
	defaultSection?: string;
	pageTitle?: string;
	pageSupertitle?: string;
	pageSubtitle?: string;
	htmlPageTitle: string;
	toggleSectionPanel?: any;
}

interface ContextInterface {
	activeSection: string;
	nextSection: string;
	sections: NavigatorSection[];
	setActiveSection: (key: string) => void;
	setNextSection: (key: string) => void;
}

export interface FooterLink {
	label: string;
	icon: string;
	href: string;
	key?: string;
}

export const Context = React.createContext<ContextInterface>({
	activeSection: "",
	nextSection: "",
	setActiveSection: key => {},
	setNextSection: key => {},
	sections: [],
});

const SectionedPageContainer: React.FC<SectionedPageContainerProps> = ({
	children,
	defaultSection = "DETAILS",
	footerLinks,
	size = "lg",
	showHeader = true,
	sections,
	pageTitle,
	pageSupertitle,
	pageSubtitle,
	htmlPageTitle,
	toggleSectionPanel,
}) => {
	const [expanded, setExpanded] = useState(
		localStorage.getItem("sideMenuExpanded") ? localStorage.getItem("sideMenuExpanded") === "true" : true
	);
	const [activeSection, setActiveSection] = useState<string>(defaultSection || "");
	const [activeRoute, setActiveRoute] = useState<string>("");
	const [nextSection, setNextSection] = useState<string>(defaultSection || "");
	const [showSubMenu, setShowSubMenu] = useState(false);
	const user = useTypedSelector(store => store.user);
	const isHostManager = window.location.href.includes("/host");
	const isAnalytics = window.location.href.includes("/analytics");

	const ref = useRef<any>();

	const match = useRouteMatch();
	const toggleSideMenu = () => {
		localStorage.setItem("sideMenuExpanded", expanded ? "false" : "true");
		setExpanded(!expanded);
	};
	useEffect(() => {
		setShowSubMenu(false);
	}, [nextSection]);

	useEffect(() => {
		if (showSubMenu) {
			document.body.classList.add("sub-nav-open");
			disableBodyScroll(ref.current);
		} else {
			document.body.classList.remove("sub-nav-open");
			enableBodyScroll(ref.current);
		}
	}, [showSubMenu]);

	useEffect(() => {
		if (isHostManager) {
			setActiveRoute("host");
		} else if (isAnalytics) {
			setActiveRoute("analytics");
		} else {
			setActiveRoute("edit");
		}
	}, []);

	useEffect(() => {
		if (!sections.some(section => section.key === activeSection) && sections[0]) {
			setActiveSection(sections[0].key);
		}
	}, [sections]);

	let history = useHistory();
	let pathSection = defaultSection || "";
	if (window.location.pathname.includes(match.url)) {
		pathSection = window.location.pathname.replace(match.url, "");
		if (!pathSection || pathSection === "" || pathSection === "/") {
			pathSection = defaultSection || "";
		} else {
			pathSection = pathSection.replace("/", "");
		}
	}

	useEffect(() => {
		if (pathSection !== nextSection) {
			setNextSection(pathSection.toUpperCase());
		}
	}, [pathSection]);

	const product =
		window.location && window.location.hostname.includes("churchx.online") ? "Church X" : "Brushfire Online";

	return (
		<>
			{showHeader && <DashboardHeader expanded={expanded} toggleSideMenu={toggleSideMenu} />}
			{sections && (
				<>
					<div className={`top-subnav ${showSubMenu ? "expanded" : ""}`} ref={ref}>
						<div className="dots" onClick={() => setShowSubMenu(!showSubMenu)}>
							<i className={`far fa-lg fa-${showSubMenu ? "times" : "ellipsis-v"}`}></i>
						</div>
						<div className="top-text">
							{!!pageSupertitle && (
								<h4 className="ellipsis m-top" style={{ marginBottom: "0" }}>
									{pageSupertitle}
								</h4>
							)}
							<h3 className="ellipsis">{pageTitle}</h3>
							{!!pageSubtitle && <h4 className="ellipsis">{pageSubtitle}</h4>}
						</div>
					</div>
					<div className={`top-nav-shim ${showSubMenu ? "active" : ""}`}></div>
					<div className={`navigator-section ${expanded ? "expanded" : ""} ${showSubMenu ? "active" : ""}`}>
						<nav>
							<ul>
								{sections.map((section, index) => (
									<li
										key={`nav_link_${index}`}
										className={activeSection === section.key ? "active" : ""}
										onClick={
											isHostManager || isAnalytics
												? () => {
														toggleSectionPanel(section.key);
														setActiveSection(section.key);
														setShowSubMenu(false);
												  }
												: () => history.push(`${match.url}/${section.key.toLocaleLowerCase()}`)
										}
									>
										{section.label}
									</li>
								))}
							</ul>
						</nav>
						{footerLinks && footerLinks.length > 0 && (
							<div className="navigator-footer">
								<ul>
									{footerLinks.map((footerLink, index) => (
										<li
											key={`nav_footer_${index}`}
											className={activeRoute === footerLink.key ? "active" : ""}
										>
											<a
												href={
													activeRoute === footerLink.key
														? "javascript:void(0);"
														: footerLink.href
												}
												target={footerLink.href.includes("/dashboard") ? "_self" : "_blank"}
											>
												<i className={footerLink.icon}></i> {footerLink.label}
											</a>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</>
			)}
			<Helmet>
				<title>{`${htmlPageTitle} | ${product}`}</title>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<main className={`page-container ${size} ${expanded ? "expanded" : ""} ${sections ? "has-sub-menu" : ""}`}>
				<Context.Provider value={{ activeSection, nextSection, setActiveSection, setNextSection, sections }}>
					{children}
					<Footer />
				</Context.Provider>
			</main>
		</>
	);
};

export default SectionedPageContainer;
