import React from "react";

import { Response } from "../../../interfaces";

import * as Styled from "../InteractionResponse.styled";

interface ShowAnswerProps {
	response?: Response;
}

/**
 * Used only in the Host Manager
 */
export const ShowAnswer: React.FC<ShowAnswerProps> = ({ response }) => {
	let message = "";

	if (response) {
		if (response.actionType === "url" && "url" in response) {
			const { url } = response;
			message = `This would open ${url.url} in ${
				url.target === "newTab" ? "a new tab" : url.target === "sameTab" ? "the same tab" : "a modal"
			}`;
		} else if (response.actionType === "startChat") {
			message = "This would start a direct chat with one of your hosts";
		} else {
			message = "This would remove the interaction from the content page";
		}
	} else {
		message = "This would remove the interaction from the content page";
	}

	return (
		<>
			<Styled.ShowAnswerContainer>
				<Styled.ShowAnswerFooterContainer>
					<small>Only you can see this message</small>
				</Styled.ShowAnswerFooterContainer>
				{message}
			</Styled.ShowAnswerContainer>
		</>
	);
};
