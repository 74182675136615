import { cloneDeep, isEmpty, isEqual, orderBy, xorWith } from "lodash";
import moment from "moment";
import { useEffect, useRef } from "react";
import { Content, ContentType } from "../../interfaces";
import api from "../../services/api";

interface FilteredContentBuckets {
	contentHappeningNow: Content[];
	contentHappeningNowButNotStarted: Content[];
	contentScheduledForFuture: Content[];
	contentHasHappened: Content[];
}
interface SortedContentBuckets extends FilteredContentBuckets {}

export let TIME_DELTA = 0;

export const shouldUpdateContents = (oldContents: Content[], newContents: Content[]) => {
	const newOldContents = orderBy(cloneDeep(oldContents), ["id"], "asc");
	const newNewContents = orderBy(cloneDeep(newContents), ["id"], "asc");

	const arraysAreDifferent = !isEmpty(xorWith(newOldContents, newNewContents, isEqual));

	return arraysAreDifferent;
};

export const sortContentBuckets = (filteredContents: FilteredContentBuckets): SortedContentBuckets => {
	let newContentHappeningNow = orderBy(cloneDeep(filteredContents.contentHappeningNow), ["startsAt", "name"]);
	let newContentHappeningNowButNotStarted = orderBy(cloneDeep(filteredContents.contentHappeningNowButNotStarted), [
		"startsAt",
		"name",
	]);
	let newContentScheduledForFuture = orderBy(cloneDeep(filteredContents.contentScheduledForFuture), [
		"startsAt",
		"name",
	]);
	let newContentHasHappened = orderBy(
		cloneDeep(filteredContents.contentHasHappened),
		["startsAt", "name"],
		["desc", "asc"]
	);

	return {
		contentHappeningNow: newContentHappeningNow,
		contentHappeningNowButNotStarted: newContentHappeningNowButNotStarted,
		contentScheduledForFuture: newContentScheduledForFuture,
		contentHasHappened: newContentHasHappened,
	};
};

const filterContent = (
	now: moment.Moment,
	content: Content,
	contentHappeningNow: Content[],
	contentHappeningNowButNotStarted: Content[],
	contentScheduledForFuture: Content[],
	contentHasHappened: Content[]
) => {
	if (hasHappened(now, content)) {
		contentHasHappened.push(content);
	} else if (isHappeningNow(now, content)) {
		if (isOpenButNotStarted(now, content)) {
			contentHappeningNowButNotStarted.push(content);
		} else {
			contentHappeningNow.push(content);
		}
	} else if (isScheduledForFuture(now, content)) {
		contentScheduledForFuture.push(content);
	}
};

export const filterContents = (now: moment.Moment, contents: Content[]): FilteredContentBuckets => {
	let contentHappeningNow: Content[] = [];
	let contentHappeningNowButNotStarted: Content[] = [];
	let contentScheduledForFuture: Content[] = [];
	let contentHasHappened: Content[] = [];

	contents
		.filter(content => !content.isPrivate && checkAvailability(content.showAt, content.hideAt) === "available")
		.forEach(content => {
			filterContent(
				now,
				content,
				contentHappeningNow,
				contentHappeningNowButNotStarted,
				contentScheduledForFuture,
				contentHasHappened
			);
		});

	return {
		contentHappeningNow,
		contentHappeningNowButNotStarted,
		contentScheduledForFuture,
		contentHasHappened,
	};
};

export const isHappeningNow = (now: moment.Moment, content: Content): boolean => {
	return (
		(now.isSame(moment.utc(content.opensAt)) || now.isAfter(moment.utc(content.opensAt))) &&
		now.isBefore(moment.utc(content.closesAt))
	);
};

export const isScheduledForFuture = (now: moment.Moment, content: Content): boolean => {
	if (!content.opensAt) {
		return false;
	}
	return now.isBefore(moment.utc(content.opensAt));
};

export const isOnDemand = (content: Content): boolean => {
	return content.contentType === ContentType.onDemand;
};

export const isOpenButNotStarted = (now: moment.Moment, content: Content): boolean => {
	return (
		(now.isSame(moment(content.opensAt)) || now.isAfter(moment(content.opensAt))) &&
		now.isBefore(moment(content.startsAt))
	);
};

export const hasHappened = (now: moment.Moment, content: Content): boolean => {
	return now.isAfter(moment.utc(content.closesAt));
};

export const getCurrentTime = async () => {
	const time = await api.getTime();
	if (time.ok) {
		const serverMoment = moment.utc(time.data);
		const systemMoment = moment.utc();
		const delta = systemMoment.diff(serverMoment);

		TIME_DELTA = delta;

		return {
			serverMoment,
			systemMoment,
			delta,
		};
	} else {
		return false;
	}
};

export const checkAvailability = (showAt: any, hideAt: any) => {
	let availability;
	if (!showAt && !hideAt) {
		availability = "available";
	} else if (showAt && !hideAt) {
		if (moment().isBefore(showAt)) {
			availability = "early";
		} else {
			availability = "available";
		}
	} else if (!showAt && hideAt) {
		if (moment().isAfter(hideAt)) {
			availability = "late";
		} else {
			availability = "available";
		}
	} else if (showAt && hideAt) {
		if (moment().isBetween(showAt, hideAt, undefined, "[]")) {
			availability = "available";
		} else if (moment().isAfter(hideAt)) {
			availability = "late";
		} else if (moment().isBefore(showAt)) {
			availability = "early";
		}
	}

	return availability;
};

export const getBrowserTime = (): moment.Moment => {
	// const browserNow = moment.utc().add(TIME_DELTA, "ms");
	const browserNow = moment.utc();
	return browserNow;
};

export const usePrevious = (value: any) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export const sortContent = (a: Content, b: Content) => {
	let aMoment = new Date("2020-3-15");
	let bMoment = new Date("2020-3-15");
	if (isOnDemand(a)) {
		if (!!a.originalAirDate) {
			aMoment = a.originalAirDate;
		}
	} else {
		if (!!a.startsAt) {
			aMoment = a.startsAt;
		}
	}

	if (b.contentType === ContentType.onDemand) {
		if (!!b.originalAirDate) {
			bMoment = b.originalAirDate;
		}
	} else {
		if (!!b.startsAt) {
			bMoment = b.startsAt;
		}
	}

	const diff = moment(bMoment).diff(moment(aMoment));
	return diff === 0
		? a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
			? 1
			: b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
			? -1
			: 0
		: diff;
};
