import {
	ChannelState,
	SET_CHANNEL_DATA,
	SET_CHANNEL_DATA_VALUE,
	ChannelActionTypes,
	UPDATE_PROFILE_IMAGE_TIMESTAMPS,
} from "./types";

const initialState: ChannelState = {
	data: undefined,
	images: {},
};

export function channelReducer(state = initialState, action: ChannelActionTypes): ChannelState {
	switch (action.type) {
		case SET_CHANNEL_DATA:
			return Object.assign({}, state, {
				data: Object.assign({}, action.payload),
			});
		case SET_CHANNEL_DATA_VALUE:
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, action.payload),
			});
		case UPDATE_PROFILE_IMAGE_TIMESTAMPS:
			return Object.assign({}, state, {
				images: Object.assign({}, state.images, action.payload),
			});

		default:
			return state;
	}
}
