import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const style = {
	ChatForgotEmail: styled.input``,
	ChatSendButton: styled.button``,
};

export default class ChatForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.onAction = this.props.onAction;
	}

	componentDidMount() {
		this.emailRef.focus();
	}

	onKeyUp(e) {
		if (e.key === "Enter") {
			this.onClick.bind(this)();
		}
	}

	onClick() {
		const email = this.emailRef.value;
		// you are supposed to send an email here.
		this.onAction("sent recovery email", email);
	}

	render() {
		const emailProps = {
			type: "text",
			onKeyUp: this.onKeyUp.bind(this),
			ref: this.setupEmailRef.bind(this),
		};
		const emailClickProps = {
			onClick: this.onClick.bind(this),
		};
		return (
			<React.Fragment>
				<style.ChatForgotEmail {...emailProps} />
				<style.ChatSendButton {...emailClickProps}>send</style.ChatSendButton>
			</React.Fragment>
		);
	}

	setupEmailRef(element) {
		this.emailRef = element;
	}
}

ChatForgotPassword.propTypes = {
	onAction: PropTypes.func.isRequired,
};
