import { useRef, useEffect } from "react";

export const usePrevious = (value: any) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export * from "./useInterval";
export * from "./usePrevious";
export * from "./useForceUpdate";
export * from "./useLocalStorage";
export * from "./useWindowWidth";
