/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { isEmpty } from "lodash";

export interface IFrameModalProps {
	visible: boolean;
	closeModal: () => void;
	src: string;
	title?: string;
	icon?: string;
}

const IFrameModal = ({ visible, closeModal, src, title, icon }: IFrameModalProps) => {
	const [loading, setLoading] = useState(true);
	const [isVisible, setIsVisible] = useState(visible);

	useEffect(() => {
		if (isVisible !== visible) {
			setIsVisible(visible);
			document.body.style.overflow = visible ? "hidden" : "scroll";
		}
	}, [visible]);

	const onRequestClose = () => {
		closeModal();
		setLoading(true);
	};
	return (
		<Modal
			className="react-modal"
			overlayClassName="react-modal-overlay"
			isOpen={isVisible}
			onRequestClose={onRequestClose}
		>
			<div className="modal-header-wrapper">
				<div className="modal-header-title">
					{!isEmpty(icon) && <i className={icon} style={{ marginRight: 10 }} />}
					{!isEmpty(title) && <span style={{ marginRight: 10 }}>{title}</span>}
				</div>
				<div className="modal-close-button" onClick={onRequestClose}>
					<i className="fal fa-times" />
				</div>
			</div>
			<div className="modal-content-wrapper">
				<iframe
					style={{ width: "100%", height: "100%", border: "none" }}
					title={title || src}
					src={src}
					onLoad={() => setLoading(false)}
				/>
				{loading && (
					<div className="absolute-center ta-center">
						<i className="fal fa-spin fa-spinner-third fa-4x" />
					</div>
				)}
			</div>
		</Modal>
	);
};

export default IFrameModal;
