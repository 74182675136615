import { TypedUseSelectorHook } from "../redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type IntercomOptions = {
	alignment: "right";
	api_base: string;
	app_id: string;
	name?: string;
	email?: string;
	created_at?: string;
	user_hash?: string;
	user_id?: string;
};
declare global {
	interface Window {
		Intercom: (command: "boot" | "update", options?: IntercomOptions) => void;
	}
}

const useTypedSelector: TypedUseSelectorHook = useSelector;
export const useIntercom = () => {
	const [booted, setBooted] = useState(false);
	const user = useTypedSelector(store => store.user);
	const location = useLocation();

	const bootIntercom = () => {
		if (user.appUser && !booted) {
			window.Intercom("boot", {
				alignment: "right",
				api_base: "https://api-iam.intercom.io",
				app_id: "pz3vmhvs",
				name: user.appUser.firstName + " " + user.appUser.lastName,
				email: user.appUser.email,
				created_at: user.user?.metadata.creationTime,
				user_id: user.appUser.id,
				user_hash: user.appUser.user_hash,
			});
			setBooted(true);
		}
	};

	const updateIntercom = () => {
		if (user.appUser) {
			window.Intercom("update");
		}
	};

	useEffect(bootIntercom, [user.appUser]);
	useEffect(updateIntercom, [location.pathname]);
};
