import styled from "styled-components";

export const ContentList = styled.div`
	width: 100%;
	overflow: auto;
	white-space: nowrap;
	text-align: left;
	margin-bottom: 40px;
`;

export const ListTitle = styled.h2`
	color: white;
	font-size: 1.5em;
	font-weight: 100;
	text-align: left;
	margin-top: 20px;
`;

export const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 10px;

	@media screen and (max-width: 992px) {
		flex-direction: column;
		align-items: space-between;
		justify-content: center;
	}
`;

export const ContentOptions = styled.div`
	width: 40%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	@media screen and (max-width: 992px) {
		flex-direction: column;
	}
`;

// only on mobile
export const ListHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

// only on mobile
export const FilterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Title = styled.h1`
	color: #fff;
	font-weight: 100;
`;

export const AddButton = styled.button`
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	border: none;
	font-size: 25px;

	margin-left: 10px;

	@media screen and (max-width: 992px) {
		margin-left: 0;
	}
`;

export const SmallTitle = styled.small`
	color: #fff;
	font-weight: 300;
	display: flex;
	margin-bottom: 0px;
`;
