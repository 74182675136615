import styled from "styled-components";
import { darken, lighten } from "polished";

export const InfoPanel = styled.div`
	display: block;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
`;

export const InfoHeader = styled.div``;

export const InfoTitle = styled.h2`
	margin-bottom: 0.25rem;
	word-wrap: break-word;
	font-weight: 100;
`;

export const InfoTime = styled.span`
	display: block;
	font-weight: 600;
	font-size: 14px;
`;

export const InfoDescription = styled.div`
	margin: 15px 0;
`;

export const InfoValidUntil = styled.p`
	display: block;
	font-weight: 600;
	font-size: 14px;
	opacity: 0.8;

	@media only screen and (min-width: 992px) {
    	display: none; 
	}	
`;

export const IconWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	i {
		padding-right: 20px;
		font-size: 18px;
	}
`;

export const BumperVideoContainer = styled.div`
	width: 100%;
	height: 100%;
`;

export const BumperVideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%;

	.player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const ContentPlaceholder = styled.div`
	width: 100%;
	height: 100%;

	.placeholder-container {
		position: relative;
		padding-bottom: 56.25%;

		.placeholder-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background-color: #24272b;
			color: #fffefd;

			h1 {
				text-transform: uppercase;
			}

			.action-hyperlink {
				color: #fffefd;
				text-decoration: underline;

				&:hover {
					cursor: pointer;
					color: #e7e7e7;
				}
			}
		}
	}
`;

interface CustomPanelProps {
	visible: boolean;
}

export const CustomPanel = styled.div<CustomPanelProps>`
	display: ${props => (props.visible ? "block" : "none")};
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
`;
