import firebase, { firestore } from "firebase";
import { FormikHelpers } from "formik";
import { flattenDepth, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { IconButton } from "../../../components/Buttons/IconButton";
import Form, { FormContext } from "../../../components/Form";
import TextCopyBlock from "../../../components/TextCopyBlock";
import { useToast } from "../../../components/Toast/ToastProvider";
import firebaseApp from "../../../firebase";
import { User } from "../../../interfaces";
import { TypedUseSelectorHook } from "../../../redux";
import { setChannelData } from "../../../redux/Channel/actions";
import { logoutUser } from "../../../redux/User/actions";
import api from "../../../services/api";
import { fetchClient } from "../../../services/dataHelpers";
import userHasRole from "../../../services/userHasRole";
import { PageContainer, PageTitle, Well } from "../UI";

import * as Styled from "../UI/FormSections/ContentFormSections.styled";

const useTypedSelector: TypedUseSelectorHook = useSelector;

// http://localhost:3000/dashboard/:clientId/channels/:channelId

interface UserFormProps {
	location: any;
}

interface StateUser extends User {
	role: "owner" | "admin" | "host" | "superhost";
}
interface UserInput extends User {
	role: "owner" | "admin" | "host" | "superhost";
	password?: string;
	whitelistChannelIds: string[] | null;
	whitelistEnabled: boolean;
}
interface Whitelist {
	[clientId: string]: string[] | null;
}

interface UserPermissionData {
	value: string;
	label: string;
	description: string;
}

const UserForm: React.FC<UserFormProps> = ({ location }) => {
	const db = firebase.firestore();
	let { clientId, userId } = useParams<{ clientId?: string; userId?: string }>();
	clientId = clientId?.toLocaleLowerCase();
	let isEditUser = userId && userId;

	let isProfile = false;

	const { appUser, user } = useTypedSelector(state => state.user);

	if (!userId && location.pathname.split("/").includes("profile")) {
		userId = appUser?.id;
		isEditUser = appUser?.id;
		isProfile = true;
	}

	const clientData = useTypedSelector(store => store.dashboard.clientData);
	const dashboard = useTypedSelector(store => store.dashboard);
	const [isSaving, setIsSaving] = useState(false);
	const [isGeneratingToken, setIsGeneratingToken] = useState(false);
	const [isPasswordSaving, setIsPasswordSaving] = useState(false);
	const [serverError, setServerError] = useState<null | string>(null);
	const [currentUser, setCurrentUser] = useState<Partial<StateUser>>({
		email: "",
		firstName: "",
		lastName: "",
		role: "host",
		whitelist: {} as Whitelist,
		tokens: {},
	});
	const [showPasswordForm, setShowPasswordForm] = useState(false);
	const [userPermissionData, setUserPermissionData] = useState<UserPermissionData[]>([]);
	const { addToast } = useToast();

	const history = useHistory();
	const dispatch = useDispatch();

	const isAdmin = clientId && appUser ? userHasRole(clientId, appUser, ["admin", "owner"]) : false;
	const isGlobalAdmin = appUser?.globalRole === "owner" || appUser?.globalRole === "admin";
	const appUserWhitelist = dashboard.clientId ? appUser?.whitelist?.[dashboard.clientId] : null;

	useEffect(() => {
		if (location.state && location.state.user && isEditUser) {
			let user = location.state.user;
			if (user.globalRole === "owner" || user.globalRole === "admin") {
				user.role = "owner";
				setCurrentUser(user);
			} else {
				setCurrentUser(user);
			}
		} else if (isProfile && appUser && clientId) {
			const user = {
				email: appUser.email,
				firstName: appUser.firstName,
				lastName: appUser.lastName,
				role: appUser.globalRole ? appUser.globalRole : appUser.clients[clientId],
				whitelist: appUser.whitelist ?? {},
				tokens: appUser.tokens,
			};
			setCurrentUser(user as StateUser);
		}
	}, [isEditUser, location, isProfile]);

	useEffect(() => {
		let userPermissionData: UserPermissionData[] = [];
		const ownerRadioData = {
			value: "owner",
			label: "Owner",
			description: "Only one per organization. Full privileges and the primary contact for the account.",
		};
		const adminRadioData = {
			value: "admin",
			label: "Admin",
			description:
				"Full privileges to add/edit/delete client settings, channels, contents, and users. Can view analytics and perform all tasks from the host panel.",
		};
		const superhostRadioData = {
			value: "superhost",
			label: "Superhost",
			description:
				"Can manage hosts, engage with attendees from the host panel, including managing interactions, and view analytics.",
		};
		const hostRadioData = {
			value: "host",
			label: "Host",
			description:
				"Can only access the host panel and engage with attendees, including sending previously created interactions.",
		};

		if (appUser && clientId) {
			if (userHasRole(clientId, appUser, ["owner"]) || isGlobalAdmin) {
				userPermissionData = [ownerRadioData, adminRadioData, superhostRadioData, hostRadioData];
			} else if (userHasRole(clientId, appUser, ["admin"])) {
				userPermissionData = [adminRadioData, superhostRadioData, hostRadioData];
			} else if (userHasRole(clientId, appUser, ["superhost"])) {
				userPermissionData = [superhostRadioData, hostRadioData];
			} else {
				userPermissionData = [hostRadioData];
			}
		}

		setUserPermissionData(userPermissionData);
	}, []);

	const findClientOwner = async () => {
		let clientOwnerId = null;
		const clientOwnerQuery = await db
			.collection("users")
			.where(`clients.${dashboard.clientId}`, "in", ["owner"])
			.get();

		clientOwnerQuery.forEach(async doc => (clientOwnerId = doc.data().id));
		return clientOwnerId;
	};

	const checkExistingEmail = async (email: string) => {
		if (isEditUser) {
			return null;
		} else {
			return Promise.resolve(
				await db
					.collection("users")
					.where(`email`, "==", email)
					.get()
					.then(data => {
						if (data.docs) {
							const existingEmail = data.docs.find(doc =>
								doc.data().clients.hasOwnProperty(dashboard?.clientId)
							);
							if (existingEmail) {
								return "A user with this email address already exists in this client";
							}
						}
					})
			);
		}
	};

	const onSubmit = async (values: UserInput) => {
		const formData = values;
		const clientOwnerId = formData.role === "owner" && isGlobalAdmin ? await findClientOwner() : null;
		if (!isSaving) {
			setIsSaving(true);
			setServerError(null);
			if (isEditUser) {
				api.editUser({
					clientId,
					id: userId,
					email: formData.email.toLowerCase(),
					firstName: formData.firstName,
					lastName: formData.lastName,
					role: formData.role,
					clientOwnerId: clientOwnerId,
					whitelistChannelIds: formData.whitelistEnabled ? formData.whitelistChannelIds : [],
				}).then(res => {
					if (res.data.error === "Invalid Permission Level") {
						console.warn("Error Creating User -", res);
						addToast("error", "You don't have permission to edit this user.");
					} else if (res.problem === "CLIENT_ERROR" || res.problem === "TIMEOUT_ERROR") {
						console.warn("Error Updating User -", res);
						addToast("error", "Error Updating User");
					} else {
						addToast("success", "User Updated");
						history.push(`/dashboard/${clientId}/users`);
						//@ts-ignore
						window.location.reload(true);
					}

					setIsSaving(false);
					setServerError(null);
				});
			} else {
				api.inviteUser({
					clientId,
					email: formData.email.toLowerCase(),
					firstName: formData.firstName,
					lastName: formData.lastName,
					role: formData.role,
					password: formData.password,
					clientOwnerId: clientOwnerId,
					whitelistChannelIds: formData.whitelistEnabled ? formData.whitelistChannelIds : [],
				}).then(res => {
					if (res.data.error === "Invalid Permission Level: Host") {
						console.warn("Error Creating User -", res);
						addToast("error", "You don't have permission to invite users.");
					} else if (res.data.error === "Invalid Permission Level") {
						console.warn("Error Creating User -", res);
						addToast("error", "You don't have permission to invite users of this role.");
					} else if (res.data.error === "User already exists") {
						addToast("error", "A user with this email address already exists in this client.");
					} else if (res.problem === "CLIENT_ERROR" || res.problem === "TIMEOUT_ERROR") {
						console.warn("Error Creating User -", res);
						addToast("error", "Error Creating User");
					} else {
						if (values.password) {
							addToast("success", "User Invited - Please send them their email and password.");
						} else {
							addToast("success", "User Invited");
						}

						if (clientId) {
							fetchClient(clientId, dispatch, appUser);
						}
						history.push(`/dashboard/${clientId}/users`);
						//@ts-ignore
						window.location.reload(true);
					}

					setIsSaving(false);
					setServerError(null);
				});
			}
		}
	};

	const setInitialWhitelistValues = () => {
		if (clientId) {
			if (currentUser.whitelist?.[clientId]) {
				return currentUser.whitelist?.[clientId];
			} else if (!isEditUser && !isProfile && appUserWhitelist) {
				return appUser?.whitelist?.[clientId];
			} else {
				return [];
			}
		}
	};

	const initialValues = {
		firstName: currentUser.firstName,
		lastName: currentUser.lastName,
		avatarImage:
			isEditUser &&
			isProfile &&
			`https://media.online.brushfire.com/profile_images/${
				firebaseApp.auth().currentUser?.uid
			}/profile.jpg?ts=${new Date().getTime()}`,
		email: currentUser.email,
		role: currentUser.role,
		showPassword: false,
		whitelistChannelIds: setInitialWhitelistValues(),
		whitelistEnabled:
			((clientId && currentUser.whitelist?.[clientId]) || (!isEditUser && !isProfile && appUserWhitelist)) ??
			false,
	};

	const generateToken = (values: { name: string }, formikHelpers: FormikHelpers<any>) => {
		if (userId && currentUser) {
			setIsGeneratingToken(true);
			api.generatePersonalAccessToken(userId, values)
				.then(({ data: newToken }) => {
					if (!currentUser.tokens) {
						currentUser.tokens = {};
					}
					currentUser.tokens[newToken] = { ts: firestore.Timestamp.now(), name: values.name };

					setCurrentUser(currentUser);
				})
				.finally(() => {
					setIsGeneratingToken(false);
					formikHelpers.resetForm({ values: { name: "" } });
				});
		}
	};

	const deleteToken = (token: string, timestamp: string) => {
		api.deletePersonalAccessToken(userId, token, timestamp)
			.then(({ data }) => {
				const user = {
					email: data.email,
					firstName: data.firstName,
					lastName: data.lastName,
					role: data.globalRole ? data.globalRole : data.clients[clientId ?? ""],
					whitelist: data.whitelist ?? {},
					tokens: data.tokens,
				};
				setCurrentUser(user);
			})
			.catch(() => {
				addToast("error", "Error Deleting Access Token");
			});
	};

	const userSchema = Yup.object().shape({
		firstName: Yup.string().required("First Name Required"),
		lastName: Yup.string().required("Last Name Required"),
		email: Yup.string()
			.required("Email Required")
			.email("Email Invalid"),
	});

	const passwordSchema = Yup.object().shape({
		password: Yup.string()
			.required("Password Required")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&=~*#])[A-Za-z\d@$!%*?&=~*#]{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@,$,!,%,*,?,&,=,~,*,#)"
			),
		passwordVerification: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords Must Match")
			.required("Password Verification Required"),
	});

	const nameSchema = Yup.object().shape({
		name: Yup.string().required("Name Required"),
	});

	const removeUser = (evt: React.MouseEvent<HTMLElement>) => {
		evt.preventDefault();
		evt.stopPropagation();
		api.deleteUser(clientId, userId)
			.then(() => {
				addToast("success", "User Removed");
				if (clientId) {
					fetchClient(clientId, dispatch, appUser);
				}
				history.push(`/dashboard/${clientId}/users`);
				//@ts-ignore
				window.location.reload(true);
			})
			.catch(error => {
				console.warn("User could not be removed -", error);
				addToast("error", "User could not be removed");
			});
	};

	const linkAccount = (provider: firebase.auth.FacebookAuthProvider | firebase.auth.GoogleAuthProvider) => {
		firebaseApp
			.auth()
			.currentUser?.linkWithPopup(provider)
			.then(function(result) {
				// Accounts successfully linked.
				var credential = result.credential;
				var user = result.user;
				user?.getIdToken(true);
				addToast("success", "Account linked");
			})
			.catch(function(error) {
				// Handle Errors here.
				addToast("error", "We could not link your account at this time");
			});
	};

	const logInWithGoogle = () => {
		var provider = new firebase.auth.GoogleAuthProvider();
		linkAccount(provider);
	};

	const logInWithFacebook = () => {
		var provider = new firebase.auth.FacebookAuthProvider();
		linkAccount(provider);
	};

	const logout = () => {
		dispatch(logoutUser());
	};

	const connectedToGoogle = user?.providerData.find(provider => provider?.providerId === "google.com");
	const connectedToFacebook = user?.providerData.find(provider => provider?.providerId === "facebook.com");
	const connectedToEmail = user?.providerData.find(provider => provider?.providerId === "password");

	const onPasswordSubmit = async (values: any) => {
		if (connectedToEmail) {
			const user = firebaseApp.auth().currentUser;
			let credential = firebase.auth.EmailAuthProvider.credential(currentUser.email ?? "", values.oldPassword);
			if (user) {
				user.reauthenticateWithCredential(credential)
					.then(function() {
						// User re-authenticated.
						firebaseApp
							.auth()
							.currentUser?.updatePassword(values.password)
							.then(() => {
								addToast("success", "Password saved!");
							})
							.catch(() => {
								addToast("error", "There was an issue updating your password");
							});
					})
					.catch(function(error) {
						// An error happened.
						addToast("error", "There was an issue adding your password for ths account");
					});
			}
		} else if (currentUser.email !== "") {
			const user = firebaseApp.auth().currentUser;
			const emailCredential = firebase.auth.EmailAuthProvider.credential(
				currentUser.email ?? "",
				values.password
			);
			if (user) {
				user.linkWithCredential(emailCredential)
					.then(() => {
						addToast("success", "Password added!");
					})
					.catch(() => {
						addToast("error", "There was an issue adding your password for ths account");
					});
			}
		}
	};

	const channelIsDisabled = (channelId: string) => {
		if (appUserWhitelist) {
			return !appUserWhitelist.includes(channelId);
		} else {
			return false;
		}
	};

	const personalAccessTokens = currentUser.tokens ? Object.keys(currentUser.tokens) : [];

	return !isEmpty(clientData) ? (
		<PageContainer size="md" htmlPageTitle="Organization Users">
			<div className="text-center">
				<PageTitle>{isProfile ? "My Profile" : isEditUser ? "EDIT USER" : "ADD USER"}</PageTitle>
			</div>

			{appUser?.globalRole === "owner" && location.state?.user?.verificationCode && (
				<Well>
					<p>Verification Link</p>
					<a
						href={`https://online.brushfire.com/signup/${location.state.user.id}/${location.state.user.verificationCode}`}
					>{`https://online.brushfire.com/signup/${location.state.user.id}/${location.state.user.verificationCode}`}</a>
				</Well>
			)}

			<Form initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
				{({ formikContext, extraData }: FormContext<any>) => {
					const { values } = formikContext;
					return (
						<>
							{extraData.showSummary && formikContext.errors && (
								<Form.Summary errors={formikContext.errors} />
							)}
							<Well>
								<fieldset disabled={isSaving}>
									<Form.Field label="First Name" id="firstName" name="firstName" />
									<Form.Field label="Last Name" id="lastName" name="lastName" />
									<Form.Field
										label="Email"
										id="email"
										name="email"
										validate={(val: string) => checkExistingEmail(val)}
									/>
									{isProfile && isEditUser && (
										<Form.Field
											component={Form.Input.Profile}
											label="Profile Photo"
											id="avatarImage"
											name="avatarImage"
											square
										/>
									)}
									{!isEditUser && !isProfile && (
										<>
											<div className="form-toggle">
												<Form.Field
													component={Form.Input.Checkbox}
													label="Add a password for this User"
													id="showPassword"
													name="showPassword"
													switchToggle={true}
												/>
											</div>
											{values.showPassword && (
												<Form.Field
													label="Password"
													id="password"
													name="password"
													type="password"
													description="* You'll be responsible for providing this password to the user after you create their account"
												/>
											)}
										</>
									)}
									{(initialValues.role === "owner" && isEditUser) || isProfile ? null : (
										<Form.Field
											component={Form.Input.Radio}
											label="Select a User Role"
											id="role"
											name="role"
											options={userPermissionData}
											defaultOption={initialValues.role}
										/>
									)}
								</fieldset>
							</Well>
							{currentUser?.role !== "owner" && !isProfile && (
								<Well>
									<fieldset disabled={isSaving}>
										<Styled.SectionHeader className="text-center form-section-title">
											<PageTitle>Limit Channel Access</PageTitle>
											<Form.Field
												component={Form.Input.Checkbox}
												label=""
												id="whitelistEnabled"
												name="whitelistEnabled"
												switchToggle={true}
											/>
										</Styled.SectionHeader>
										{formikContext.values.whitelistEnabled && (
											<div>
												{dashboard.channelsData?.map(channel => {
													return (
														<div className="channel-checkbox" key={channel.id}>
															<Form.Input.Checkbox
																label={channel.name}
																id={`channels[${channel.id}]`}
																name={`channels[${channel.id}]`}
																disabled={channelIsDisabled(channel.id)}
																value={
																	values.whitelistChannelIds?.includes(channel.id) ||
																	false
																}
																onChecked={() => {
																	let data = values.whitelistChannelIds.concat();
																	data.push(channel.id);
																	formikContext.setFieldValue(
																		"whitelistChannelIds",
																		data
																	);
																}}
																onUnchecked={() => {
																	let data = values.whitelistChannelIds.concat();
																	const index = data.indexOf(channel.id);
																	if (index > -1) {
																		data.splice(index, 1);
																	}
																	formikContext.setFieldValue(
																		"whitelistChannelIds",
																		data
																	);
																}}
															/>
														</div>
													);
												})}
											</div>
										)}
									</fieldset>
								</Well>
							)}
							<Button
								variant={!formikContext.isValid ? `danger` : `primary`}
								className="btn-block text-center mb-2"
								type="submit"
								disabled={!formikContext.isValid || isSaving}
							>
								{isSaving && <Spinner animation="border" className="form-button-spinner" />}{" "}
								{isEditUser ? `Sav${isSaving ? "ing..." : "e"}` : `Add${isSaving ? "ing..." : ""}`}
							</Button>
						</>
					);
				}}
			</Form>

			{isProfile && (
				<>
					<PageTitle>SIGN IN METHODS</PageTitle>
					<Well>
						<h3 className="m-bottom">Connect With</h3>
						<IconButton
							variant="dark"
							className={`btn-block ${connectedToGoogle ? "disabled" : ""}`}
							icon={connectedToGoogle ? "check" : "google"}
							iconWeight={connectedToGoogle ? "fal" : "fab"}
							onClick={connectedToGoogle ? () => {} : logInWithGoogle}
						>
							{connectedToGoogle ? "Connected To Google" : "Connect To Google"}
						</IconButton>
						<IconButton
							variant="dark"
							className={`btn-block ${connectedToFacebook ? "disabled" : ""}`}
							icon={connectedToFacebook ? "check" : "facebook"}
							iconWeight={connectedToFacebook ? "fal" : "fab"}
							onClick={connectedToFacebook ? () => {} : logInWithFacebook}
						>
							{connectedToFacebook ? "Connected To Facebook" : "Connect To Facebook"}
						</IconButton>
						<h3 className="m-bottom">{connectedToEmail ? "Update Your Password" : "Add A Password"}</h3>

						<Form
							initialValues={{ password: "", passwordVerification: "", oldPassword: "" }}
							validationSchema={passwordSchema}
							onSubmit={onPasswordSubmit}
						>
							{({ formikContext, extraData }: FormContext<any>) => {
								const { errors } = formikContext;
								return (
									<>
										{extraData.showSummary && formikContext.errors && (
											<Form.Summary errors={formikContext.errors} />
										)}
										<fieldset disabled={isSaving}>
											{connectedToEmail && (
												<Form.Field
													label="Old Password"
													id="oldPassword"
													name="oldPassword"
													type="password"
												/>
											)}

											<Form.Field
												label="Password"
												id="password"
												name="password"
												type="password"
											/>
											<Form.Field
												label="Password Verification:"
												id="passwordVerification"
												name="passwordVerification"
												type="password"
											/>
										</fieldset>
										<Button
											variant={!formikContext.isValid ? `danger` : `primary`}
											className="btn-block text-center"
											type="submit"
											disabled={!formikContext.isValid || isSaving}
										>
											{isPasswordSaving && (
												<Spinner animation="border" className="form-button-spinner" />
											)}{" "}
											{`Updat${isPasswordSaving ? "ing..." : "e"}`}
										</Button>
									</>
								);
							}}
						</Form>
					</Well>
				</>
			)}

			{isAdmin && ["admin", "owner"].includes(currentUser.role ?? "") && (
				<>
					<PageTitle>Access Tokens</PageTitle>
					<Well
						message="Generate and manage personal access tokens for interacting with the Brushfire Online Public API"
						learnMoreLink="https://online.brushfire.com/api/docs"
					>
						<>
							<div className="access-keys">
								{Object.keys(currentUser.tokens ?? {}).map(token => {
									if (token.length > 4) {
										return (
											<div key={token} className="key">
												<small style={{ fontSize: 10 }}>
													{currentUser?.tokens?.[token].name}
												</small>
												<TextCopyBlock>{token}</TextCopyBlock>
											</div>
										);
									} else {
										return (
											<div key={token} className="key">
												<Row>
													<Col xs={10}>
														<small style={{ fontSize: 10 }}>
															{currentUser?.tokens?.[token].name}
														</small>
														<h4 className="m-0">{token}XXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</h4>

														<small>
															Generated{" "}
															{moment(
																new Date /* @ts-ignore */(
																	/* @ts-ignore */ currentUser?.tokens?.[token].ts
																		._seconds * 1000
																)
															).format("dddd, MMMM D, YYYY h:mm A")}
														</small>
													</Col>
													<Col xs={2}>
														<IconButton
															variant="danger"
															icon="ban"
															onClick={() => {
																console.log("token", currentUser?.tokens?.[token]);
																deleteToken(
																	token,
																	(
																		(
																			currentUser?.tokens?.[
																				token /* @ts-ignore */
																			].ts._seconds ??
																			currentUser?.tokens?.[token].ts.seconds
																		).toString() +
																		(
																			currentUser?.tokens?.[
																				token
																				/* @ts-ignore */
																			].ts._nanoseconds ??
																			currentUser?.tokens?.[token].ts.nanoseconds
																		).toString()
																	).toString() ?? ""
																);
															}}
														/>
													</Col>
												</Row>
											</div>
										);
									}
								})}
							</div>
							<Form initialValues={{ name: "" }} validationSchema={nameSchema} onSubmit={generateToken}>
								{({ formikContext, extraData }: FormContext<any>) => {
									const { errors } = formikContext;
									return (
										<>
											{extraData.showSummary && formikContext.errors && (
												<Form.Summary errors={formikContext.errors} />
											)}
											<fieldset disabled={isGeneratingToken}>
												<Form.Field label="Token Name" id="name" name="name" type="text" />
											</fieldset>
											<Button
												variant={!formikContext.isValid ? "default" : `primary`}
												className="btn-block text-center"
												type="submit"
												disabled={!formikContext.isValid || isGeneratingToken}
											>
												{isGeneratingToken && (
													<Spinner animation="border" className="form-button-spinner" />
												)}{" "}
												{isGeneratingToken ? "Generating Token..." : "Generate Token"}
											</Button>
										</>
									);
								}}
							</Form>
						</>
					</Well>
				</>
			)}

			{isAdmin && userId && !isProfile && (
				<Button variant="danger" className="btn-block text-center" onClick={removeUser}>
					{isSaving && <Spinner animation="border" className="form-button-spinner" />}{" "}
					{isSaving ? "Removing..." : "Remove User"}
				</Button>
			)}
			{isProfile && (
				<Button variant="info" className="btn-block text-center" onClick={logout}>
					Sign Out
				</Button>
			)}
		</PageContainer>
	) : (
		<PageContainer htmlPageTitle="Organization Users">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>User not found.</div>
		</PageContainer>
	);
};

export default UserForm;
