import firebase from "firebase";
import moment from "moment";
import { Dispatch } from "redux";
import { v4 as uuidv4 } from "uuid";
import firebaseAuthApp from "../authFirebase";
import fb from "../firebase";
import {
	AnalyticsUser,
	ChannelData,
	ChatRequest,
	ChatRequestStatus,
	ChatUserData,
	ClientData,
	Content,
	ContentType,
	User,
} from "../interfaces";
import { Tag } from "../interfaces/Tag";
import { showModalAlert } from "../redux/Chat/actions";
import {
	setChannelData,
	setChannelDataLoading,
	setChannelId,
	setChannelsData,
	setChannelsDataLoading,
	setClientData,
	setClientDataLoading,
	setClientId,
	setContentData,
	setContentDataLoading,
	setContentId,
	setContentsData,
	setContentsDataLoading,
	setTagsData,
	setTagsDataLoading,
} from "../redux/Dashboard/actions";
import { initFirebasePresence } from "../services/firebasePresence";
import userHasRole from "./userHasRole";

const db = fb.firestore();

const rtdb = fb.database();

const fetchClient = (clientId: string, dispatch: Dispatch, appUser: User | undefined) => {
	dispatch(setClientDataLoading(true));
	dispatch(setClientId(clientId));
	//fetch clientData
	db.collection("clients")
		.doc(clientId)
		.get()
		.then(doc => {
			if (doc.exists) {
				const data: ClientData | undefined = doc.data() ? (doc.data() as ClientData) : undefined;
				if (appUser && userHasRole(data?.id || "", appUser, ["owner", "admin", "host", "superhost"])) {
					dispatch(setClientData(data));
				} else {
					window.location.href = "/dashboard";
				}
			}
		})
		.finally(() => {
			dispatch(setClientDataLoading(false));
		});
};

const fetchChannel = (channelId: string, dispatch: Dispatch) => {
	dispatch(setChannelDataLoading(true));
	dispatch(setChannelId(channelId));
	//fetch channelData
	db.collection("channels")
		.doc(channelId)
		.get()
		.then(doc => {
			let exists = false;
			if (doc.exists) {
				const data: ChannelData | undefined = doc.data() ? (doc.data() as ChannelData) : undefined;
				if (!data?.versionCode) {
					exists = true;
					dispatch(setChannelData(data));
				}
			}
		})
		.finally(() => {
			dispatch(setChannelDataLoading(false));
		});
};

const fetchChannels = (clientId: string, dispatch: Dispatch) => {
	dispatch(setChannelsDataLoading(true));
	//fetch channelData
	db.collection("channels")
		.where("clientId", "==", clientId)
		.get()
		.then(docs => {
			let exists = false;
			const payload: ChannelData[] = [];
			docs.forEach(doc => {
				const data: ChannelData = doc.data() as ChannelData;
				if (!data?.versionCode) {
					exists = true;
					payload.push(data);
				}
			});
			dispatch(setChannelsData(payload));
		})
		.finally(() => {
			dispatch(setChannelsDataLoading(false));
		});
};

const fetchContent = async (contentId: string, dispatch: Dispatch) => {
	dispatch(setContentDataLoading(true));
	dispatch(setContentId(contentId));
	//fetch videoData

	let contentsDoc;
	let interactionsCollection;

	let data: Record<string, any> = {};

	try {
		contentsDoc = await db
			.collection("contents")
			.doc(contentId)
			.get();
		interactionsCollection = await db
			.collection("interactions")
			.doc("contents")
			.collection(contentId)
			.get();

		if (contentsDoc.exists) {
			if (!data?.versionCode) {
				data = { ...contentsDoc.data() };
			}
		}
		if (interactionsCollection.size > 0) {
			const interactions: any[] = [];
			interactionsCollection.forEach(i => {
				const interaction = i.data();
				if (!interaction.versionCode) {
					interactions.push({ ...i.data() });
				}
			});
			data = { ...data, interactions };
		} else {
			data = { ...data, interactions: [] };
		}

		dispatch(setContentData(data as Content));
	} catch (error) {
		console.warn(`Error getting content`, error);
	} finally {
		dispatch(setContentDataLoading(false));
	}
};

const fetchContents = (clientId: string, dispatch: Dispatch) => {
	dispatch(setContentsDataLoading(true));
	//fetch contentsData
	db.collection("contents")
		.where("clientId", "==", clientId)
		.get()
		.then(docs => {
			let exists = false;
			const payload: Content[] = [];
			docs.forEach(doc => {
				const data: Content = doc.data() as Content;
				if (!data?.versionCode) {
					exists = true;
					payload.push(data);
				}
			});
			dispatch(setContentsData(payload));
		})
		.finally(() => {
			dispatch(setContentsDataLoading(false));
		});
};

const fetchTags = (clientId: string, dispatch: Dispatch) => {
	dispatch(setTagsDataLoading(true));
	//fetch contentsData
	db.collection("tags")
		.doc("clientTags")
		.collection(clientId)
		.get()
		.then(docs => {
			const payload: Tag[] = [];
			docs.forEach(doc => {
				payload.push({ ...(doc.data() as Tag) });
			});
			dispatch(setTagsData(payload));
		})
		.finally(() => {
			dispatch(setTagsDataLoading(false));
		});
};

const createChatRequest = async (
	contentId: string,
	uid: string,
	userName: string,
	message: string,
	image: string | null,
	status: ChatRequestStatus = ChatRequestStatus.request,
	sendMessage: boolean = false
) => {
	const contentCollectionRef = db
		.collection("chat")
		.doc("direct")
		.collection(contentId);

	const docs = await contentCollectionRef
		.where("createdBy", "==", uid)
		.where("status", "in", [0, 1, 6])
		.get();
	let create = docs.empty;

	if (!create) {
		create = true;
		docs.forEach(doc => {
			const data = doc.data() as ChatRequest;
			if (data.userData[uid].status !== ChatRequestStatus.ended) {
				create = false;
			}
		});
	}
	if (create) {
		const newChatRequest: ChatRequest = {
			id: uuidv4(),
			users: [uid],
			createdBy: uid,
			hosts: [],
			userName,
			message,
			status: status,
			userData: {
				[uid]: {
					name: userName,
					image,
					muted: false,
					status: status,
				},
			},
			timestamp: firebase.firestore.Timestamp.now(),
			showRequest: sendMessage,
			updatedAt: moment.utc().valueOf(),
			hostChat: true,
		};
		contentCollectionRef
			.doc(newChatRequest.id)
			.set(newChatRequest)
			.then(() => {
				if (sendMessage) {
					const newMessage = {
						userName,
						uid,
						message,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
						photoURL: image ? `https://media.online.brushfire.com/profile_images/${uid}/profile.jpg` : null,
						//email, // Taking this out because we have it on the userAnalytics doc which isn't publicly readable
						isHost: false,
						flags: [],
						isDeleted: false,
					};
					db.collection("chat")
						.doc("contents")
						.collection(newChatRequest.id)
						.add(newMessage);
				}
			});
		return true;
	} else {
		return false;
	}
};

const createDirectMessageChatRequest = (
	contentId: string,
	uid: string,
	recipientUid: string,
	userName: string,
	recipientUserName: string,
	message: string,
	chatUserData: ChatUserData,
	status: ChatRequestStatus = ChatRequestStatus.request,
	isModerator: boolean = false, //True if the initiator is a moderator,
	dispatch: Dispatch,
	hostChat: boolean,
	setCurrentChat?: (chatRequest: ChatRequest) => void
) => {
	rtdb.ref(`/analytics/user-presence/${contentId}`)
		.orderByChild("firebaseId")
		.equalTo(recipientUid)
		.once("value")
		.then(snap => {
			const data: { state: string }[] = snap.exists() ? Object.values(snap.val() ? snap.val() : {}) : [];
			if (data.length === 1 && data[0].state === "offline") {
				dispatch(
					showModalAlert({
						showModal: true,
						modalData: {
							title: "Unavailable",
							message: `${recipientUserName} is currently offline`,
							closeButtonText: "Okay",
						},
					})
				);
			} else {
				const contentCollectionRef = db
					.collection("chat")
					.doc("direct")
					.collection(contentId);

				contentCollectionRef
					.where("users", "array-contains", uid)
					.where("status", "in", [0, 1, 3, 4, 5])
					.get()
					.then(existingChats => {
						let proceed = true;
						let reopened = false;
						existingChats.forEach(existingChat => {
							const existingRequest = existingChat.data() as ChatRequest;
							if (existingRequest.users.includes(recipientUid)) {
								proceed = false;
								if (
									existingRequest.status === ChatRequestStatus.directMessageEnded ||
									existingRequest.userData[uid].status === ChatRequestStatus.directMessageEnded
								) {
									if (reopened === false) {
										reopened = true;
										existingRequest.status = ChatRequestStatus.directMessageActive;
										existingRequest.userData[uid].status = ChatRequestStatus.directMessageActive;
										existingRequest.updatedAt = moment.utc().valueOf();
										contentCollectionRef.doc(existingChat.id).set(existingRequest);
									}
								} else {
									if (!existingRequest.showRequest && existingRequest.createdBy !== uid) {
										if (reopened === false) {
											reopened = true;
											existingRequest.status = ChatRequestStatus.directMessageActive;
											existingRequest.userData[uid].status =
												ChatRequestStatus.directMessageActive;
											existingRequest.showRequest = true;
											contentCollectionRef.doc(existingChat.id).set(existingRequest);
										}
									} else if (setCurrentChat) {
										if (reopened === false) {
											reopened = true;
											setCurrentChat(existingRequest);
										}
									}
								}
							}
						});
						if (proceed) {
							const id = uuidv4();
							const newChatRequest: ChatRequest = {
								id,
								users: [uid, recipientUid],
								createdBy: uid,
								hosts: isModerator ? [uid] : [],
								userName,
								recipientUserName,
								message,
								status: status,
								userData: chatUserData,
								timestamp: firebase.firestore.Timestamp.now(),
								showRequest: false,
								updatedAt: moment.utc().valueOf(),
								hostChat,
							};
							contentCollectionRef
								.doc(newChatRequest.id)
								.set(newChatRequest)
								.then(() => {
									if (setCurrentChat) {
										setCurrentChat(newChatRequest);
									}
								});
						}
					});
			}
		});
};

const updateChatRequest = (contentId: string, oldUid: string, uid: string, userName: string) => {
	const contentCollectionRef = db
		.collection("chat")
		.doc("direct")
		.collection(contentId);

	contentCollectionRef
		.where("users", "array-contains", oldUid)
		.where("status", "in", [0, 1, 3, 4])
		.get()
		.then(docs => {
			let exists = false;
			if (!docs.empty) {
				const batch = db.batch();
				docs.forEach(doc => {
					const data = doc.data() as ChatRequest;
					if (data.userData) {
						let muted = false;
						if (data.userData[oldUid]) {
							muted = data.userData[oldUid].muted;
							delete data.userData[oldUid];
						}
						const image = firebaseAuthApp.auth().currentUser?.photoURL;
						data.userData[uid] = {
							name: userName,
							image: image || null,
							muted,
							status: data.status,
						};
					}
					data.users = data.users.filter(u => u !== oldUid);
					data.users.push(uid);
					if (data.createdBy === oldUid) {
						batch.set(
							contentCollectionRef.doc(doc.id),
							Object.assign({}, data, { userName, createdBy: uid })
						);
					} else {
						batch.set(
							contentCollectionRef.doc(doc.id),
							Object.assign({}, data, { recipientUserName: userName })
						);
					}
				});
				batch.commit();
			}
		});
};

const toggleChatRequestMute = (contentId: string, requestId: string, uid: string) => {
	const requestRef = db
		.collection("chat")
		.doc("direct")
		.collection(contentId)
		.doc(requestId);

	requestRef.get().then(doc => {
		const data = doc.data() as ChatRequest;
		if (data.userData) {
			data.userData[uid].muted = !data.userData[uid].muted;
		}

		requestRef.set(data);
	});
};

const activateChatRequest = (contentId: string, requestId: string, isHost: boolean) => {
	const requestRef = db
		.collection("chat")
		.doc("direct")
		.collection(contentId)
		.doc(requestId);
	const payload: Partial<ChatRequest> = { showRequest: true };
	if (isHost) {
		payload.status = ChatRequestStatus.directMessageActive;
	}
	requestRef.set(payload, { merge: true });
};

const updateAnalyticsUserDoc = (
	user: firebase.User,
	contentId: string,
	extraData: Partial<AnalyticsUser>,
	contentType: ContentType,
	initFirebase: boolean = true
) => {
	const { analyticsUserId } = extraData;
	const userRef = db
		.collection("analytics")
		.doc("users")
		.collection(contentId)
		.doc(`${analyticsUserId}`);
	userRef.get().then(doc => {
		let data: Partial<AnalyticsUser> = {};
		if (doc.exists) {
			data = doc.data() as AnalyticsUser;
			data.name = user.displayName || data.name || "";
			data.email = user.email || data.email || "";
			data.firebaseId = user.uid;
			data = Object.assign({}, data, extraData);
		} else {
			data.name = user.displayName || "";
			data.email = user.email || "";
			data.firebaseId = user.uid;
			data = Object.assign({}, data, extraData);
		}
		userRef.set(data, { merge: true });
		if (initFirebase) {
			initFirebasePresence(contentId, analyticsUserId || "", user.uid, contentType);
		}
	});
};
export {
	fetchClient,
	fetchChannel,
	fetchChannels,
	fetchContent,
	fetchContents,
	fetchTags,
	createChatRequest,
	createDirectMessageChatRequest,
	updateChatRequest,
	updateAnalyticsUserDoc,
	toggleChatRequestMute,
	activateChatRequest,
};
