import React, { useState, useEffect } from "react";
import moment from "moment";
import { isEmpty, orderBy } from "lodash";

import { InteractionButton } from "./InteractionButton";
import { PreviewPanel } from "./PreviewPanel";
import { CreateInteractionPanel } from "./createInteractionPanel";
import { Content, User, Interaction } from "../../../../../interfaces";
import firebaseApp from "../../../../../firebase";
import { useMediaQuery } from "react-responsive";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Button from "../../../../../components/Button";
import { useSelector } from "react-redux";
import { TypedUseSelectorHook } from "../../../../../redux";
import userHasRole from "../../../../../services/userHasRole";
import { useToast } from "../../../../../components/Toast/ToastProvider";
import { generateRandomString } from "../../../../../utils/generateRandom";

import * as Styled from "./InteractionManager.styled";

const db = firebaseApp.firestore();

interface ChatRequestProps {
	// contentId: string;
	content: Content;
	appUser: User;
	show: boolean;
}

const InteractionManager: React.FC<ChatRequestProps> = ({ content, appUser, show }) => {
	const [interactions, setInteractions] = useState<Interaction[]>([]);
	const [currentPreviewId, setCurrentPreview] = useState<string>();
	const [editPanelOpen, setEditPanelOpen] = useState(false);
	const [deletePanelOpen, setDeletePanelOpen] = useState(false);

	const { id: contentId } = content;
	const isMobileAndTablet = useMediaQuery({ query: "(max-width: 992px)" });
	const useTypedSelector: TypedUseSelectorHook = useSelector;
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const hasInteractionsEditAccess =
		dashboard.clientId && user.appUser
			? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin", "superhost"])
			: false;
	const { addToast } = useToast();

	useEffect(() => {
		const unsubscribe = subscribeToInteractions();
		return () => unsubscribe();
	}, [contentId]);

	const subscribeToInteractions = () => {
		return db
			.collection("interactions")
			.doc("contents")
			.collection(contentId)
			.where("versionCode", "==", null)
			.onSnapshot(snapshot => {
				let interactions: Interaction[] = [];
				snapshot.forEach(doc => {
					const data = doc.data() as Interaction;
					if (!data.versionCode || data.versionCode === "") {
						interactions.push(data);
					}
				});
				interactions = orderBy(interactions, ["showAt", "displayOrder"], ["desc", "asc"]);
				setInteractions(interactions);
			});
	};

	const publishInteraction = async (interaction: Interaction) => {
		try {
			const newInteraction = { ...interaction, showAt: moment.utc().toISOString() };
			const docRef = await db
				.collection("interactions")
				.doc("contents")
				.collection(contentId)
				.doc(interaction.id)
				.set(newInteraction);
		} catch (error) {
			console.warn("Error sending interaction", error);
		}
	};

	const deleteInteraction = async () => {
		if (dashboard.contentId) {
			const interactionVersionCode = generateRandomString(8, false, true);
			try {
				await db
					.collection("interactions")
					.doc("contents")
					.collection(dashboard.contentId)
					.doc(currentPreviewId)
					.update({ versionCode: interactionVersionCode })
					.then(() => {
						setCurrentPreview(undefined);
						setDeletePanelOpen(false);
						addToast("success", "Interaction deleted");
					});
			} catch (error) {
				console.warn("Error deleting interaction", error);
				addToast("error", "Error deleting interaction");
			}
		}
	};

	return (
		<div
			className={`host-panel ${currentPreviewId !== "none" && currentPreviewId !== undefined ? "active" : ""} ${
				show ? "show" : ""
			}`}
		>
			<Styled.Container>
				<Styled.InteractionsList>
					<div className="d-flex justify-content-between">
						<h2>Interactions</h2>
						{hasInteractionsEditAccess && (
							<Button
								onClick={() => {
									setCurrentPreview(undefined);
									setEditPanelOpen(true);
								}}
							>
								<i className="far fa-plus"></i>
							</Button>
						)}
					</div>
					{isEmpty(interactions) ? (
						<div>No Interactions</div>
					) : (
						interactions.map(interaction => {
							return (
								<InteractionButton
									key={interaction.id}
									interaction={interaction}
									active={currentPreviewId === interaction.id}
									onClick={() => {
										setEditPanelOpen(false);
										setCurrentPreview(undefined);
										setTimeout(() => {
											if (currentPreviewId !== interaction.id) {
												setCurrentPreview(interaction.id);
											}
										}, 0);
									}}
								/>
							);
						})
					)}
				</Styled.InteractionsList>
				<ReactCSSTransitionGroup
					component={React.Fragment}
					transitionName="slide"
					transitionEnterTimeout={400}
					transitionLeaveTimeout={300}
					transitionEnter={isMobileAndTablet}
					transitionLeave={isMobileAndTablet}
				>
					{currentPreviewId && !editPanelOpen && (
						<Styled.PreviewPanelContainer className="hide-mobile-border">
							{deletePanelOpen && (
								<Styled.DeletePreviewPanel className="delete-interaction-panel">
									<Styled.ExitButton onClick={() => setDeletePanelOpen(false)}>
										<i className="fal fa-times"></i>
									</Styled.ExitButton>
									<Styled.DeletePanelButtonContainer>
										<Styled.DeletePanelButton variant={"secondary"} onClick={deleteInteraction}>
											Delete Interaction?
										</Styled.DeletePanelButton>
										<Styled.DeletePanelButton
											onClick={() => setDeletePanelOpen(false)}
											variant={"secondary"}
										>
											Cancel
										</Styled.DeletePanelButton>
									</Styled.DeletePanelButtonContainer>
								</Styled.DeletePreviewPanel>
							)}
							<PreviewPanel
								content={content}
								interactionId={currentPreviewId}
								onInteractionSend={publishInteraction}
								setCurrentPreview={setCurrentPreview}
								setEditPanelOpen={setEditPanelOpen}
								setDeletePanelOpen={setDeletePanelOpen}
								hasInteractionsEditAccess={hasInteractionsEditAccess}
							/>
						</Styled.PreviewPanelContainer>
					)}
				</ReactCSSTransitionGroup>
				<ReactCSSTransitionGroup
					component={React.Fragment}
					transitionName="slide"
					transitionEnterTimeout={400}
					transitionLeaveTimeout={300}
					transitionEnter={isMobileAndTablet}
					transitionLeave={isMobileAndTablet}
				>
					{editPanelOpen && (
						<Styled.PreviewPanelContainer className="hide-mobile-border edit-panel">
							<CreateInteractionPanel
								key={currentPreviewId}
								initialInteractions={interactions}
								interactionId={currentPreviewId}
								setCurrentPreview={setCurrentPreview}
								setEditPanelOpen={setEditPanelOpen}
							/>
						</Styled.PreviewPanelContainer>
					)}
				</ReactCSSTransitionGroup>
			</Styled.Container>
		</div>
	);
};

export default InteractionManager;
