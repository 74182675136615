import { ClientData, ChannelData, Content, Tag } from "../../interfaces";

export interface DashboardState {
	clientData?: ClientData;
	clientDataLoading?: boolean;
	clientId?: string;

	channelData?: ChannelData;
	channelDataLoading?: boolean;
	channelId?: string;
	channelsData?: ChannelData[];
	channelsDataLoading?: boolean;

	contentData?: Content;
	contentDataLoading?: boolean;
	contentId?: string;
	contentsData?: Content[];
	contentsDataLoading?: boolean;

	tagsData?: Tag[];
	tagsDataLoading?: boolean;
}

export const SET_CLIENT_DATA: string = "SET_CLIENT_DATA";
export const SET_CLIENT_DATA_LOADING: string = "SET_CLIENT_DATA_LOADING";
export const SET_CLIENT_ID: string = "SET_CLIENT_ID";

export const SET_CHANNEL_DATA: string = "SET_CHANNEL_DATA";
export const SET_CHANNEL_DATA_LOADING: string = "SET_CHANNEL_DATA_LOADING";
export const SET_CHANNEL_ID: string = "SET_CHANNEL_ID";
export const SET_CHANNELS_DATA: string = "SET_CHANNELS_DATA";
export const SET_CHANNELS_DATA_LOADING: string = "SET_CHANNELS_DATA_LOADING";

export const SET_CONTENT_DATA: string = "SET_CONTENT_DATA";
export const SET_CONTENT_DATA_LOADING: string = "SET_CONTENT_DATA_LOADING";
export const SET_CONTENT_ID: string = "SET_CONTENT_ID";
export const SET_CONTENTS_DATA = "SET_CONTENTS_DATA";
export const SET_CONTENTS_DATA_LOADING = "SET_CONTENTS_DATA_LOADING";

export const SET_TAGS_DATA = "SET_TAGS_DATA";
export const SET_TAGS_DATA_LOADING = "SET_TAGS_DATA_LOADING";

interface SetClientData {
	type: typeof SET_CLIENT_DATA;
	payload: ClientData | undefined;
}
interface SetClientDataLoading {
	type: typeof SET_CLIENT_DATA_LOADING;
	payload: boolean;
}
interface SetClientId {
	type: typeof SET_CLIENT_ID;
	payload: string;
}

interface SetChannelData {
	type: typeof SET_CHANNEL_DATA;
	payload: ChannelData | undefined;
}
interface SetChannelDataLoading {
	type: typeof SET_CHANNEL_DATA_LOADING;
	payload: boolean;
}
interface SetChannelId {
	type: typeof SET_CHANNEL_ID;
	payload: string;
}

interface SetContentData {
	type: typeof SET_CONTENT_DATA;
	payload: ClientData | undefined;
}
interface SetContentDataLoading {
	type: typeof SET_CONTENT_DATA_LOADING;
	payload: boolean;
}
interface SetContentId {
	type: typeof SET_CONTENT_ID;
	payload: string;
}

export type DashboardActionTypes =
	| SetClientData
	| SetClientDataLoading
	| SetClientId
	| SetChannelData
	| SetChannelDataLoading
	| SetChannelId
	| SetContentData
	| SetContentDataLoading
	| SetContentId;
