import styled from "styled-components";
import { darken, lighten } from "polished";
import isLightOrDark from "../../utils/isLightOrDark";

interface LinkProps {
	index?: number;
}

export const LinkButton = styled.a<LinkProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	color: ${({ theme }) => theme.buttonTextColor};
	height: 100%;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;

	i,
	p {
		color: ${({ theme }) => theme.buttonTextColor};
		text-decoration: none;
		cursor: pointer;
	}

	&:hover {
		background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
		text-decoration: none;
		cursor: pointer;

		i,
		p {
			text-decoration: none;
			cursor: pointer;
		}
	}

	@media screen and (max-width: 992px) {
		display: flex;
		align-items: center;
		padding: 0.5em 0;
		&:hover {
			background-color: inherit;
		}
		p,
		i {
			color: ${({ theme }) => theme.wellTextColor};
		}
		i {
			font-weight: 300;
			font-size: 20px;
			margin-right: 0.5em !important;
		}
	}
`;
