import { darken, lighten } from "polished";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled, { StyledFunction } from "styled-components";
import { colours, fontSizes, fontWeights, getSpacerSize } from "../../config";
import { ChatMessage, ChatRequest, ChatRequestStatus } from "../../interfaces";
import { createDirectMessageChatRequest } from "../../services/dataHelpers";
import Button from "../Button";
import ChatAvatar from "./ChatAvatar";
import { isMobile } from "react-device-detect";

interface ChatBlockProps {
	convo: ChatMessage;
	toggleConvoLike: Function;
	toggleConvoFlag: Function;
	delConvo: Function;
	pinConvo?: Function;
	banUser: Function;
	unbanUser: Function;
	chatId: string;
	uid: string;
	lastUID: string;
	isModerator: boolean;
	clientId: string;
	bannedUsers: string[];
	isLightBackground?: boolean;
	isHostManager?: boolean;
	chatClosed?: boolean;
	useChatProcessor: boolean;
	userLoggedIn: boolean;
	userName: string;
	photoURL: string | null;
	chatBlacklist?: string[];
	isDirect: boolean;
	enableDirectMessaging?: boolean;
	setCurrentChat?: (chatRequest: ChatRequest) => void;
	shouldUnpin?: boolean;
}

function fadedColorFromHex(hex: string) {
	let c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.7)";
	}
	throw new Error("Bad Hex");
}

const ChatConvoButtonGeneral = styled.button`
	-webkit-appearance: none;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	margin-right: 10px;
	font-size: 11px;
	color: inherit;
`;

const ChatConvoWrapperTS: StyledFunction<any> = styled.div;
const ChatConvoMessageTS: StyledFunction<any> = styled.p;
const ChatHostPillTS: StyledFunction<any> = styled.span;

const style = {
	ChatConvoWrapper: ChatConvoWrapperTS`
		border-color: ${({ theme }) => theme.wellTextColor};
		padding-bottom: 5px;

		@media screen and (max-width: 600px) {
			padding-bottom: 0px;
		}
		&.pinned-chat {
			position: absolute;
			top: -5px;
			left: -5px;
			z-index: 99;
			width: calc(100% + 0px);
			background: ${({ theme }) => theme.wellBackgroundColor};
			padding: 10px !important;
			border-color: ${({ theme, isLightBackground }) => {
				return isLightBackground
					? darken(0.05, theme.wellBackgroundColor)
					: lighten(0.15, theme.wellBackgroundColor);
			}};
			transition: transform 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
			box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.35);
			transform: translate(0, calc(-100% - 30px));
			border-radius: 4px;
			&.active{
				transform: translate(0, 0%);
			}
		}
	`,
	ChatConvo: styled.div`
		width: 100%;
		height: calc(100% - 55px);
		min-height: 55px;
		overflow-x: hidden;
		overflow-y: scroll;
		color: ${({ theme }) => theme.wellTextColor};

		/* for the side scrollbar */
		&::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar {
			width: 5px;
			background-color: ${({ theme }) => theme.wellBackgroundColor};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: ${({ theme }) => theme.wellTextColor};
		}
	`,
	ChatConvoLine: styled.div`
		margin-bottom: 10px;
	`,
	ChatConvoUser: styled.h5`
		font-weight: 600;
		font-size: 11px;
		margin-bottom: ${getSpacerSize(0.2)};
		color: inherit;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 60%;

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin: 0;
			margin-left: 5px;
		}
	`,
	ChatTimeStamp: styled.h6`
	  	${fontWeights.light}
	  	font-size: ${fontSizes.xs};
		margin-top: ${getSpacerSize(0.25)};
		color: inherit;
	`,
	ChatConvoDelButton: styled(ChatConvoButtonGeneral)`
		color: ${colours.error};
	`,
	ChatConvoBanButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoLikeButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoUnlikeButton: styled(ChatConvoButtonGeneral)``,
	ChatConvoFlagButton: styled(ChatConvoButtonGeneral)`
		color: ${colours.error};
	`,
	ChatConvoUnFlagButton: styled(ChatConvoButtonGeneral)`
		color: ${colours.error};
	`,
	ChatDefaultAvatarCircle: styled.div`
		background-color: ${({ theme }) => theme.buttonBackgroundColor};
		color: ${({ theme }) => theme.buttonTextColor};
		text-align: center;
		height: 38px;
		width: 38px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	`,
	ChatHostPill: ChatHostPillTS`
		background-color: ${({ theme }) => theme.wellTextColor};
		color: ${({ theme }) => theme.wellBackgroundColor};
		padding: 0px 2px;
		border-radius: 2px;
		margin-left: 5px;
		font-size: 7px;
		text-transform: uppercase;
		> i {
			font-size: 0.75em;
		}
`,
	ChatConvoMessage: ChatConvoMessageTS`
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 0px;
		color: ${({ isFlagged }) => (isFlagged ? "#ffffff" : "inherit")};
		border-radius: 6px;
		padding: 3px 5px;
		background-color: ${({ theme, isLightBackground, isHost, isFlagged }) => {
			if (isFlagged) {
				return "#e71d36";
			} else {
				return isLightBackground
					? darken(isHost ? 0.15 : 0.05, theme.wellBackgroundColor)
					: lighten(isHost ? 0.45 : 0.2, theme.wellBackgroundColor);
			}
		}};
		word-break: break-word;
		&.pinned-chat-body{
			max-height: 60px;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: max-height 0.4s ease-in-out;
			-webkit-line-clamp: unset;
			&.expanded {
				-webkit-line-clamp: unset !important;
				max-height: 50vh;
				overflow: auto;
			}
			&.line-clamp {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
		}
	a {
		color: ${({ theme }) => theme.wellTextColor};
		text-decoration: underline;
		transition: all ease-in-out 0.3s;
		&:hover {
			color: ${({ theme }) => fadedColorFromHex(theme.wellTextColor)};
		}
	}
`,
	ChatBannedPill: styled.span`
		background-color: #e71d36;
		color: #ffffff;
		padding: 2px 4px;
		border-radius: 5px;
		margin-left: 5px;
		font-size: 8px;
	`,
};

interface ModalData {
	id?: string;
	name?: string;
	comment?: string;
}

interface ModalInfo {
	title: string;
	body: string;
	subBody: string;
	confirmText: string;
	confirm: () => void;
}

const ChatBlock: React.FC<ChatBlockProps> = ({
	convo,
	toggleConvoLike,
	toggleConvoFlag,
	delConvo,
	pinConvo,
	banUser,
	unbanUser,
	chatId,
	clientId,
	uid,
	lastUID,
	isModerator,
	bannedUsers,
	isLightBackground,
	isHostManager,
	chatClosed,
	useChatProcessor,
	userLoggedIn,
	userName,
	photoURL,
	chatBlacklist,
	isDirect,
	enableDirectMessaging = false,
	setCurrentChat,
	shouldUnpin,
}) => {
	const [hoverState, setHoverState] = useState(true);
	const [userPicture, setUserPicture] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [showPinned, setShowPinned] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [transitionHasEnded, setTransitionHasEnded] = useState(true);
	const [modalData, setModalData] = useState<ModalData>();
	const [modalInfo, setModalInfo] = useState<ModalInfo>();

	const dispatch = useDispatch();

	useEffect(() => {
		setModalData({ id: convo.id, name: convo.userName, comment: convo.message });
	}, [convo]);

	useEffect(() => {
		if (shouldUnpin) {
			setShowPinned(false);
		}
	}, [shouldUnpin]);

	useEffect(() => {
		if (convo.isPinned && !showPinned) {
			setTimeout(() => {
				setShowPinned(true);
			}, 200);
		}
	}, []);

	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const handleModalOpen = (info: ModalInfo) => {
		setModalInfo(info);
		handleShowModal();
	};

	const handleBanConfirm = () => {
		banUser({ uid: convo.uid, userName: convo.userName, chatId });
		handleCloseModal();
	};

	const handleUnbanConfirm = () => {
		unbanUser({ uid: convo.uid, userName: convo.userName, chatId });
		handleCloseModal();
	};

	const handleDelConfirm = () => {
		delConvo({
			id: convo.id,
			chatId: chatId,
			useChatProcessor,
			convo: convo,
		});
		handleCloseModal();
	};

	const handlePin = () => {
		if (pinConvo) {
			pinConvo({
				id: convo.id,
				chatId: chatId,
				useChatProcessor,
				pin: !convo.isPinned && !convo.dupePinnedChatId,
				convo: convo,
			});
		}
		handleCloseModal();
	};

	const handleFlagConfirm = (action: string) => {
		toggleConvoFlag({
			id: convo.id,
			chatId: chatId,
			uid: uid,
			action,
			useChatProcessor,
			convo: convo,
		});
		handleCloseModal();
	};

	const initializeChat = (recipientUid: string, recipientUserName: string, recipientPhotoURL: string | null) => {
		createDirectMessageChatRequest(
			chatId,
			uid,
			recipientUid,
			userName,
			recipientUserName,
			"Hello world",
			{
				[uid]: {
					name: userName,
					image: photoURL,
					muted: false,
					status: ChatRequestStatus.directMessageActive,
				},
				[recipientUid]: {
					name: recipientUserName,
					image: recipientPhotoURL,
					muted: false,
					status: ChatRequestStatus.directMessageRequest,
				},
			},
			ChatRequestStatus.directMessageRequest,
			isModerator,
			dispatch,
			isModerator || convo.isHost,
			setCurrentChat
		);
	};

	const renderUserActions = () => {
		const likes = convo.likes && convo.likes.length > 0 && convo.likes;
		const isLikedByCurrentUser = likes && likes.includes(uid);
		const likeNums = convo.likes ? convo.likes.length : 0;

		const flags = convo.flags && convo.flags.length > 0 && convo.flags;
		const isFlaggedByCurrentUser = flags && flags.includes(uid);
		const flagNums = convo.flags ? convo.flags.length : 0;
		return (
			<>
				{uid !== convo.uid && !convo.isHost && !isHostManager && (
					<>
						{isFlaggedByCurrentUser ? (
							<style.ChatConvoUnFlagButton
								onClick={() =>
									toggleConvoFlag({
										id: convo.id,
										chatId: chatId,
										uid: uid,
										action: "unflag",
										useChatProcessor,
										convo: convo,
									})
								}
							>
								<i className="fas fa-flag" /> {isModerator && flagNums > 0 ? flagNums : ""}
							</style.ChatConvoUnFlagButton>
						) : (
							<style.ChatConvoFlagButton
								onClick={() =>
									handleModalOpen({
										title: "Report message?",
										body: `Are you sure you want to report the message: "${modalData?.comment}" as offensive?`,
										subBody: "",
										confirm: () => handleFlagConfirm("flag"),
										confirmText: "Report",
									})
								}
							>
								<i className="fal fa-flag" /> {isModerator && flagNums > 0 ? flagNums : ""}
							</style.ChatConvoFlagButton>
						)}
					</>
				)}

				{isLikedByCurrentUser ? (
					<style.ChatConvoUnlikeButton
						onClick={() =>
							toggleConvoLike({
								id: convo.id,
								chatId: chatId,
								uid: uid,
								action: "unlike",
								useChatProcessor,
								convo: convo,
							})
						}
					>
						<i className="fas fa-thumbs-up" /> {likeNums > 0 ? likeNums : ""}
					</style.ChatConvoUnlikeButton>
				) : (
					<style.ChatConvoLikeButton
						onClick={() =>
							toggleConvoLike({
								id: convo.id,
								originalId: convo.originalId,
								dupeId: convo.dupePinnedChatId,
								chatId: chatId,
								uid: uid,
								action: "like",
								useChatProcessor,
								convo: convo,
							})
						}
					>
						<i className="fal fa-thumbs-up" /> {likeNums > 0 ? likeNums : ""}
					</style.ChatConvoLikeButton>
				)}
			</>
		);
	};

	const renderModerator = () => {
		return (
			<React.Fragment>
				{(uid === convo.uid || isModerator) && (
					<style.ChatConvoDelButton
						onClick={() =>
							handleModalOpen({
								title: "Remove message?",
								body: `Are you sure you want to permanently remove this message: "${modalData?.comment}"?`,
								subBody: "This cannot be undone.",
								confirm: () => handleDelConfirm(),
								confirmText: "Remove",
							})
						}
					>
						<i className="fal fa-trash" />
					</style.ChatConvoDelButton>
				)}

				{isModerator && !convo.isHost && (
					<style.ChatConvoDelButton
						onClick={
							userIsBanned
								? () =>
										handleModalOpen({
											title: "Unban User?",
											body: `Are you sure you want to uban the user: ${modalData?.name}?`,
											subBody:
												"All of their past messages will be shown and they will be able to send messages again for the remainder of the session.",
											confirm: () => handleUnbanConfirm(),
											confirmText: "Unban",
										})
								: () =>
										handleModalOpen({
											title: "Ban User?",
											body: `Are you sure you want to ban the user: ${modalData?.name}?`,
											subBody:
												"All of their past messages will be removed and they will be unable to send new messages for the remainder of the session.",
											confirm: () => handleBanConfirm(),
											confirmText: "Ban",
										})
						}
					>
						<i className="fal fa-ban" />
					</style.ChatConvoDelButton>
				)}
				{isModerator && pinConvo && (
					<style.ChatConvoLikeButton
						onClick={() => {
							handlePin();
						}}
					>
						<i className={`fa${convo.isPinned || convo.dupePinnedChatId ? "s" : "l"} fa-thumbtack`} />
					</style.ChatConvoLikeButton>
				)}
			</React.Fragment>
		);
	};

	const userIsBanned = bannedUsers.includes(convo.uid);
	const currentUserIsBanned = bannedUsers.includes(uid);

	const renderChat = (pinned: boolean, collapse: boolean) => {
		return (
			<style.ChatConvoWrapper
				key={convo.id}
				className={`chat-block-wrapper ${convo.uid !== lastUID ? "new-block" : ""} ${
					isModerator && convo.flags && convo.flags.length > 0 ? "flagged" : ""
				}${pinned ? " pinned-chat" : ""}${showPinned ? " active" : ""}${collapse ? " collapsed" : ""}`}
			>
				<div className="chat-image">
					<ChatAvatar
						userName={convo.userName}
						photoURL={convo.photoURL}
						requestChat={
							!chatBlacklist?.includes(convo.uid) &&
							((!isDirect && enableDirectMessaging) || (!isDirect && isHostManager)) &&
							convo.uid !== uid
						}
						uid={convo.uid}
						initializeChat={initializeChat}
					/>
				</div>
				<div
					className="chat-block"
					onMouseOver={() => setHoverState(true)}
					onMouseOut={() => setHoverState(true)}
				>
					<div className="chat-header-container">
						<style.ChatConvoUser>
							<p>
								{pinned && (
									<>
										<i className="fas fa-thumbtack"></i>{" "}
									</>
								)}
								{convo.userName}
							</p>
							{convo.isHost && (
								<style.ChatHostPill>
									<i className="fas fa-user"></i> Host
								</style.ChatHostPill>
							)}
							{isModerator && userIsBanned && <style.ChatBannedPill>Banned</style.ChatBannedPill>}
						</style.ChatConvoUser>
						{!chatClosed && (
							<div className="chat-actions-container">
								<div
									className={`chat-actions-wrapper animated ${
										hoverState || convo.likesCount > 0 ? "show-actions" : "hide-actions"
									}`}
								>
									{renderModerator()}
									{!currentUserIsBanned && userLoggedIn && renderUserActions()}
								</div>
							</div>
						)}
					</div>
					<style.ChatConvoMessage
						className={`${pinned ? " pinned-chat-body" : ""}${expanded ? " expanded" : ""}${
							transitionHasEnded ? " line-clamp" : ""
						}`}
						isHost={convo.isHost}
						isLightBackground={isLightBackground}
						dangerouslySetInnerHTML={{ __html: convo.message }}
						isFlagged={isModerator && convo.flags && convo.flags.length > 0}
						onClick={() => {
							if (pinned && isMobile) {
								setTransitionHasEnded(false);
								setExpanded(!expanded);
							}
						}}
						onMouseEnter={() => {
							if (pinned && !isMobile) {
								setTransitionHasEnded(false);
								setExpanded(true);
							}
						}}
						onMouseLeave={() => {
							if (pinned && !isMobile) {
								setTransitionHasEnded(false);
								setExpanded(false);
							}
						}}
						onTransitionEnd={() => setTransitionHasEnded(true)}
					/>
				</div>
				{modalData && (
					<Modal show={showModal} onHide={handleCloseModal} className="default-modal">
						<Modal.Header closeButton>
							<Modal.Title>{modalInfo?.title}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{modalInfo?.body && <p>{modalInfo.body}</p>}
							{modalInfo?.subBody && <p>{modalInfo.subBody}</p>}
						</Modal.Body>

						<Modal.Footer>
							<Button
								className="modal-button cancel-button"
								variant="secondary"
								onClick={handleCloseModal}
							>
								Cancel
							</Button>
							<Button
								className="modal-button confirm-button"
								color="#e71d36"
								onClick={modalInfo?.confirm}
							>
								{modalInfo?.confirmText}
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</style.ChatConvoWrapper>
		);
	};
	return (
		<>
			{!convo.isPinned && renderChat(false, false)}
			{convo.isPinned && renderChat(true, false)}
		</>
	);
};

export default ChatBlock;
