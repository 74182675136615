import { createLogic } from "redux-logic";
import { FRONT_END_LOGIN_SUCCESS, FRONT_END_LOGIN_SUCCESS_ACTION } from "../types";
import api from "../../../services/api";
import { User } from "../../../interfaces";
import { frontEndSetUser, loginError, logoutUser } from "../actions";

const frontEndLoginSuccessLogic = createLogic({
	type: FRONT_END_LOGIN_SUCCESS,
	latest: true,
	// eslint-disable-next-line no-unused-vars
	process({ getState, action }, dispatch, done) {
		let data = action as FRONT_END_LOGIN_SUCCESS_ACTION;
		const state: any = getState();
		dispatch(frontEndSetUser(data.payload || undefined));

		done();
	},
});

export default [frontEndLoginSuccessLogic];
