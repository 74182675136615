import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import authFirebaseApp from "../../authFirebase";
import GlobalLoader from "../../components/GlobalLoader";
import GlobalStyle from "../../components/GlobalStyle";
import Paywall from "../../components/Paywall";
import { Theme } from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import api from "../../services/api";
import { initEventPresence } from "../../services/firebasePresence";

interface Params {
	eventNumber: string;
}

interface BrushfireStandalonePaywallProps {}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const parseStyles = (styles: string) =>
	styles
		.split(";")
		.filter(style => style.split(":")[0] && style.split(":")[1])
		.map(style => [
			style
				.split(":")[0]
				.trim()
				.replace(/-./g, c => c.substr(1).toUpperCase()),
			style
				.split(":")
				.slice(1)
				.join(":")
				.trim(),
		])
		.reduce(
			(styleObj, style) => ({
				...styleObj,
				[style[0]]: style[1],
			}),
			{}
		);

const BrushfireStandalonePaywall: React.FC<BrushfireStandalonePaywallProps> = props => {
	let { eventNumber } = useParams<Params>();
	const [isLoading, setIsLoading] = useState(true);
	const [eventData, setEventData] = useState<any>();
	const [creatingAnonymousUser, setCreatingAnonymousUser] = useState(false);
	const [didInitializePresence, setDidInitializePresence] = useState(false);

	const user = useTypedSelector(store => store.user);
	const { analyticsUserId } = useTypedSelector(store => store.analytics);

	useEffect(() => {
		api.getBrushfireStandaloneEventDetails(eventNumber)
			.then(({ data }) => {
				if (data) {
					setEventData(data.data);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		if (
			didInitializePresence === false &&
			user.frontEndIsLoading === false &&
			eventNumber !== "" &&
			!creatingAnonymousUser
		) {
			if (!user.frontEndUser) {
				setCreatingAnonymousUser(true);
				const auth = authFirebaseApp.auth();
				auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
					auth.signInAnonymously().then(res => {
						if (res.user) {
							//Signed in
						}
					});
				});
			}
			setDidInitializePresence(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventNumber, creatingAnonymousUser, user, didInitializePresence]);

	useEffect(() => {
		if (eventNumber && analyticsUserId) {
			initEventPresence(eventNumber, analyticsUserId);
		}
	}, [eventNumber, analyticsUserId, user.frontEndUser]);

	if (!eventData) {
		return <GlobalLoader isActive={isLoading} />;
	}

	//@ts-ignore
	const eventTheme = JSON.parse(eventData.EventTheme);
	const parsedCSS = eventTheme?.backgroundCss ? (parseStyles(eventTheme.backgroundCss) as any) : {};

	const theme: Theme = {
		textColor: "#FFFFFF",
		backgroundColor: parsedCSS?.backgroundColor || "#15171a",
		buttonTextColor: "#FFFFFF",
		buttonBackgroundColor: eventTheme.linkColor,
		buttonHoverBackgroundColor: eventTheme.linkColor,
		wellTextColor: eventTheme.textColor,
		wellBackgroundColor: "#FFFFFF",
		navBackgroundColor: "#C9C49C",
		navLinkColor: "#2D2D2D",
		navLinkHoverColor: "#535248",
		backgroundImage: parsedCSS?.backgroundImage?.split("'")[1] || "",
	};

	if (eventData) {
		return (
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<Paywall
					analyticsUserId={analyticsUserId}
					title={eventData.Title}
					eventNumber={eventNumber}
					headerImage={eventTheme.headerUrlLarge || eventTheme.logoUrl}
				>
					<Helmet>
						<title>{`${eventData.Title}`}</title>
						<meta name="robots" content="noindex,nofollow" />
					</Helmet>
					<iframe
						className="standalone-iframe"
						src={eventData.StreamUrl}
						width="100%"
						height="100%"
						allowFullScreen={true}
					/>
				</Paywall>
			</ThemeProvider>
		);
	}
	return null;
};

export default BrushfireStandalonePaywall;
