import React from "react";
import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";
import * as Styled from "../ContentFormSections.styled";

export interface ChatSectionProps {
	isEnabled: boolean;
}

const ChatSection: React.FC<ChatSectionProps> = ({ isEnabled }) => {
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>CHAT</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="chat.enabled"
					name="chat.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{isEnabled ? (
				<Well
					message="Use chat to engage with viewers and create community around your content."
					learnMoreLink="https://support.brushfire.com/hc/en-us/articles/360012987960-Chat-Overview"
				>
					<>
						<Form.Field
							component={Form.Input.Checkbox}
							label="Require viewer to sign in to view chat"
							id="chat.requireSignin"
							name="chat.requireSignin"
						/>
						<Form.Field
							component={Form.Input.Checkbox}
							label="Require email address as part of sign in"
							id="chat.enableAnonymous"
							name="chat.enableAnonymous"
						/>
						<Form.Field
							component={Form.Input.Checkbox}
							label="Allow users to chat with each other"
							id="chat.enableDirectMessaging"
							name="chat.enableDirectMessaging"
						/>
					</>
				</Well>
			) : (
				<Well>
					<p>
						Use chat to engage with viewers and create community around your content.{" "}
						<span>
							<a
								href="https://get.brushfire.help/hc/en-us/articles/360012987960"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <i className="fal fa-long-arrow-right" />
							</a>
						</span>
					</p>
				</Well>
			)}
		</>
	);
};

export default ChatSection;
