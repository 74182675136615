import React, { useEffect } from "react";
import { usePrevious } from "../../../hooks/usePrevious";
import { isEmpty, isMatch } from "lodash";
import { themes, ThemeProps } from "./helpers";
import { Row } from "react-bootstrap";
import { ThemeButtonWrapper, ThemeButton } from "./ThemePicker.styled";

interface ThemePickerProps {
	selectTheme: (theme: ThemeProps | null) => void;
	selectedTheme: string;
	activeTheme: any;
}

const ThemePicker: React.FC<ThemePickerProps> = ({ selectTheme, selectedTheme, activeTheme }) => {
	const prevActiveTheme: any = usePrevious(activeTheme);
	useEffect(() => {
		selectMatchedTheme();
	}, []);

	useEffect(() => {
		if (!isEmpty(prevActiveTheme) && !isMatch(prevActiveTheme, activeTheme)) {
			selectMatchedTheme();
		}
	}, [prevActiveTheme, activeTheme]);

	const selectMatchedTheme = () => {
		let isMatchedTheme = false;
		themes.forEach(theme => {
			let themeCopy = { ...theme };
			let activeThemeCopy = { ...activeTheme };
			delete activeThemeCopy.backgroundImage;
			if (isMatch(themeCopy.theme, activeThemeCopy)) {
				isMatchedTheme = true;
				selectTheme(theme);
			}
		});
		if (!isMatchedTheme) {
			selectTheme(null);
		}
	};

	return (
		<Row>
			{themes.map(theme => {
				const { id, name } = theme;
				return (
					<ThemeButtonWrapper key={id}>
						<ThemeButton onClick={() => selectTheme(theme)} selected={selectedTheme === id}>
							<img src={`/themes/img/${id}.png`} alt={`${name} Brushfire Theme`} />
							<p>{name}</p>
						</ThemeButton>
					</ThemeButtonWrapper>
				);
			})}
		</Row>
	);
};

export default ThemePicker;
