import styled from "styled-components/macro";

import {
	CustomWell as LinksCustomWell,
	CardContents as LinksCardContents,
	ActionsContainer as LinksActionsContainer,
} from "../Links/LinksSection.styled";
import { PageTitle } from "../../";

interface IsRefreshing {
	isRefreshing: boolean;
}

interface ButtonProps {
	isExpanded: boolean;
}

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin-top: 40px;
`;

export const SectionHeader = styled(PageTitle)`
	text-align: left;
	margin: 0;
`;

export const ButtonContainer = styled.div<ButtonProps>`
	position: absolute;
	right: 0;
	display: flex;
	width: ${({ isExpanded }) => (isExpanded ? "auto" : "50px")};
	max-width: 100%;
	height: 45px;
	border-radius: 5px;
	transition: all 0.5s;
	overflow: hidden;
`;

export const Button = styled.div<ButtonProps>`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	min-width: 120px;
	background-color: ${({ isExpanded }) => (isExpanded ? "#4a525a" : "transparent")};
	margin: 0;
	border-left-width: 2px;
	border-color: #576069;
	cursor: pointer;
	color: white;
	transition: all 0.25s;

	&:hover {
		background-color: #1f2327;
	}
`;

interface ContainerProps {
	isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
	padding: 10px 20px 10px 10px;
	margin-bottom: ${({ isActive }) => (isActive ? "0px" : "10px")};
	${({ isActive }) => {
		if (isActive) {
			return `
				border-radius: 4px 4px 0px 0px;
			`;
		} else {
			return `
				border-radius: 4px;
			`;
		}
	}}
	min-height: auto;

	background-color: ${({ isActive }) => (isActive ? "#dadada" : "#4a525a")};
	color: ${({ isActive }) => {
		if (isActive) {
			return "#24272b";
		} else {
			return "#fff";
		}
	}};

	display: flex;
	align-items: center;
	justify-content: space-between;

	transition: all 0.2s ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: #dadada;
		color: #24272b;
	}
`;

export const BrushfireEventDetails = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	max-width: 100%;
`;

export const BrushfireEventTitle = styled.div``;

export const CustomRow = styled.div`
	display: flex;
	align-items: center;

	.field {
		margin: 0;
		width: 100%;
	}
	button {
		height: 41px;
		margin-left: 10px;
	}
`;

export const CustomWell = styled(LinksCustomWell)`
	border: solid 1px #5a5a5a;
	border-radius: 8px !important;
`;

interface CardContentsProps extends IsRefreshing {}
export const CardContents = styled(LinksCardContents)<CardContentsProps>`
	position: relative;
	padding: 15px;
	color: ${({ isRefreshing }) => (isRefreshing ? "#4a525a" : "#fffefd")};

	${({ isRefreshing }) =>
		isRefreshing
			? `
		.checkbox-checkmark,
		.field {
			border-color: #4a525a;
			opacity: 0.3;
		}
	`
			: ""}
`;

export const ActionsContainer = styled(LinksActionsContainer)`
	width: 56px;
	padding-top: 10px;
`;

interface StyledTextInputProps {
	hasError: boolean;
}
export const TextInput = styled.input<StyledTextInputProps>`
	${({ hasError }) =>
		hasError
			? `
	box-shadow: #e71d36 0px 0px 2px 1px, #e71d36 0px 0px 0px 3px;
	border: 1px solid #e71d36 !important;
	background-color: rgba(231, 29, 54, 0.1) !important;
`
			: ""}
`;

interface RefreshLoadingOverlayProps extends IsRefreshing {}
export const RefreshLoadingOverlay = styled.div<RefreshLoadingOverlayProps>`
	display: ${({ isRefreshing }) => (isRefreshing ? "block" : "none")};
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0px auto;
	z-index: 100;
`;
