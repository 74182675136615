import React from "react";
import { FieldProps } from "formik";
import {sanitizeField} from "../../Field/Field";

type TextInputTypes = "text" | "email" | "number";

export interface TextInputProps {
	field?: FieldProps["field"];
	form?: FieldProps["form"];
	id: string;
	name: string;
	onKeyPress?: (e: React.KeyboardEvent) => void;
	onBlur?: (e: React.FocusEvent) => void;
	ref?: React.RefObject<any>;
	type?: TextInputTypes;
	value?: string;
	[key: string]: any;
}

const TextInput: React.FC<TextInputProps> = ({ type = "text", field, form, onBlur, ...props }) => {
	return (
		<input
			{...props}
			{...field}
			type={type}
			name={(field && field.name) || props.name || ""}
			value={(field && field.value) ?? props.value ?? ""}
			onBlur={e => {
				form?.handleBlur(e);
				if (onBlur) onBlur(e);
			}}
			onChange={e => {
				sanitizeField(e, form, field);
			}}
		/>
	);
};

export default TextInput;
