import styled from "styled-components";

export const SectionHeader = styled.div`
	text-align: left;
`;

export const PlusButton = styled.h2`
	color: #fff;
	cursor: pointer;
`;
