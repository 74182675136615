/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { isEmpty } from "lodash";

Modal.setAppElement("#root");

export interface HTMLModalProps {
	title?: string;
	icon?: string;
	content: string;
	visibility: boolean;
	closeModal: () => void;
	allowScript?: boolean;
	style?: any;
}

const HTMLModal: React.FC<HTMLModalProps> = ({
	title,
	icon,
	content,
	visibility,
	closeModal,
	allowScript,
	style = {},
}) => {
	const [loader, setLoader] = useState(false);
	const [isVisible, setIsVisible] = useState(visibility);

	useEffect(() => {
		if (isVisible !== visibility) {
			setIsVisible(visibility);
			document.body.style.overflow = visibility ? "hidden" : "scroll";
		}
	}, [visibility]);

	useEffect(() => {
		if (content?.startsWith("<iframe")) {
			setLoader(true);
		}
		if (allowScript) {
			const extractscript = /<script>(.+)<\/script>/gi.exec(content);

			if (extractscript && extractscript.length > 0) {
				window.eval(extractscript[1]);
			}
		}
	}, [allowScript, content]);

	const hideLoader = () => {
		setLoader(false);
	};

	const renderContent = () => {
		if (content?.startsWith("<iframe")) {
			let iframeURL = content.match("<iframe.+?src=[\"'](.+?)[\"'].*?>") || [];

			return (
				<iframe
					title={title || iframeURL[1]}
					src={iframeURL[1]}
					style={styles.video}
					//@ts-ignore
					webkitallowfullscreen=""
					//@ts-ignore
					mozallowfullscreen=""
					allowfullscreen=""
					onLoad={hideLoader}
				/>
			);
		} else {
			return <div dangerouslySetInnerHTML={{ __html: content }} />;
		}
	};

	return (
		<Modal
			style={style}
			className="react-modal"
			overlayClassName="react-modal-overlay"
			isOpen={isVisible}
			onRequestClose={closeModal}
		>
			<div className="modal-header-wrapper">
				<div className="modal-header-title">
					{!isEmpty(icon) && <i className={icon} style={{ marginRight: 10 }} />}
					{!isEmpty(title) && <span style={{ marginRight: 10 }}>{title}</span>}
				</div>
				<div className="modal-close-button" onClick={closeModal}>
					<i className="fal fa-times" />
				</div>
			</div>
			<div className="modal-content-wrapper" style={{ padding: 20 }}>
				{renderContent()}
				{loader && (
					<div className="absolute-center ta-center">
						<i className="fal fa-spin fa-spinner-third fa-4x" />
					</div>
				)}
			</div>
		</Modal>
	);
};

const styles = {
	video: {
		width: 500,
		height: 500,
		maxHeight: "100%",
		maxWidth: "100%",
		border: "none",
	},
};

export default HTMLModal;
