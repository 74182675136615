import "firebase/auth";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Form, { FormContext } from "../../../../components/Form";
import StickyTextInput from "../../../../components/Form/StickyTextInput";
import Sticky from "../../../../components/Sticky";
import { useToast } from "../../../../components/Toast/ToastProvider";
import firebase from "../../../../firebase";
import { AvailableCodeProviders, Client } from "../../../../interfaces";
import { NavigatorSection } from "../../../../interfaces/NavigatorSection";
import { TypedUseSelectorHook } from "../../../../redux";
import api from "../../../../services/api";
import { isChurchX } from "../../../../utils/churchx";
import { timeZoneData } from "../../../../utils/timezones";
import Appearance from "../../Appearance";
import { isMatchedTheme } from "../../Theme/helpers";
import { FormSection, PageContainer, PageTitle, SectionedPageContainer, Well } from "../../UI";
import { Sections } from "../../UI/FormSections";
import FormSubmitButton from "../../UI/FormSubmitButton";
import { FooterLink } from "../../UI/SectionedPageContainer/SectionedPageContainer";
import { clientFormInitialValues } from "./helpers";
import { validate } from "./validate";

// Sortable.mount(new MultiDrag());
// http://localhost:3000/dashboard/4e75b661-518a-4ff2-9bbb-92437202d22a

interface ClientFormProps {
	verify: boolean;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;
const db = firebase.firestore();

const formSections = {
	details: "DETAILS",
	theme: "THEME",
	links: "LINKS",
};

const ClientForm: React.FC<ClientFormProps> = ({ verify }) => {
	const [initialFormValues, setInitialFormValues] = useState<any>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [action, setAction] = useState("");
	const [touchedKey, setTouchedKey] = useState(false);

	const history = useHistory();

	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const location = useLocation();
	const { addToast } = useToast();

	const loading = dashboard.clientDataLoading;

	useEffect(() => {
		const { pathname } = location;
		const values = pathname.split("/");
		if (values.includes("add")) {
			setAction("add");
		} else {
			setAction("edit");
		}
	}, [location]);

	useEffect(() => {
		if (action === "edit") {
			if (!dashboard.clientDataLoading && dashboard.clientData) {
				let tempFormValues = Object.assign({}, dashboard.clientData as Client);

				if (!isEmpty(tempFormValues.links)) {
					tempFormValues.links.forEach((link: any) => {
						if (link.url) {
							link.linkType = "url";
						} else if (link.html) {
							link.linkType = "html";
						} else if (link.code) {
							link.linkType = "code";
							link.codeProvider = AvailableCodeProviders[link.codeProvider];
						}
					});
				}
				tempFormValues.showSettings =
					tempFormValues.theme && !isEmpty(tempFormValues.theme) && !isMatchedTheme(tempFormValues.theme);

				if (!tempFormValues.authProviders) {
					tempFormValues.authProviders = {
						facebook: {
							enabled: true,
						},
						google: {
							enabled: true,
						},
						auth0: {
							enabled: false,
							domain: "",
							clientId: "",
							buttonText: "",
							icon: "",
						},
					};
				}

				setInitialFormValues(tempFormValues);
			}
		} else if (action === "add") {
			setInitialFormValues(clientFormInitialValues);
		}
	}, [action, dashboard.clientDataLoading]);

	const onSubmit = (formValues: any, resetForm: any) => {
		addToast("validating", "Client saving...");
		setIsSubmitting(true);
		const values = cloneDeep(formValues);

		let newValues: Client = {
			...values,
		};

		if (action === "add" || action === "copy") {
			newValues.createdAt = moment.utc().toISOString();
		}

		if (action === "edit") {
			if (initialFormValues.name !== values.name && dashboard.clientData) {
				// initiate BF endpoint for all channels on the existing client
				if (dashboard.channelsData) {
					dashboard.channelsData.forEach(channel => {
						db.collection("channels")
							.doc(channel.id)
							.update({ updatedBy: user.appUser?.email });
					});
				}
			}
		}

		if (!isEmpty(newValues.links)) {
			newValues.links?.forEach((link: any) => {
				delete link.linkType;
				if (link.hasOwnProperty("codeProvider") && link.hasOwnProperty("code")) {
					link.codeProvider = AvailableCodeProviders[link.codeProvider];
				}
			});
		}

		newValues.key = newValues.key.toLowerCase();
		delete newValues.showSettings;

		db.collection("clients")
			.doc(newValues.id)
			.set(newValues)
			.then(() => {
				addToast("success", "Client saved!");
				resetForm({ values: formValues });
				if (action === "add") {
					let id;
					const uniqueChannelId = uuidv4();
					id = uniqueChannelId;
					const channelDocRef = db.collection("channels").doc(id);
					const channelFormData = {
						brushfireEventId: "",
						id,
						clientId: newValues.id,
						name: "Online",
						key: "online",
						description: "",
						theme: {},
						headerImage: null,
						thumbImage: newValues.logoImage,
						displayOrder: 0,
						showAt: null,
						hideAt: null,
						versionCode: null,
						timeZoneName: "America/Chicago",
					};
					channelDocRef
						.set(channelFormData)
						.then(() => {
							setIsSubmitting(false);
							history.push(`/dashboard/${newValues.id}/channels`);
							window.location.reload();
						})
						.catch((error: any) => {
							console.warn("Error creating channel", error);
							setIsSubmitting(false);
						});
				} else {
					setIsSubmitting(false);
				}
			})
			.catch(error => {
				addToast("error", "Error submitting form.");
				console.warn("Error Submitting form", error);
				setIsSubmitting(false);
			})
			.finally(() => { });
	};

	if (loading) {
		return <PageContainer htmlPageTitle="Loading..." />;
	}

	const sections: NavigatorSection[] = [
		{
			label: "Details",
			key: formSections.details,
		},
		{
			label: "Theme",
			key: formSections.theme,
		},
		{
			label: "Links",
			key: formSections.links,
		},
	];

	const footerLinks: FooterLink[] = [];
	footerLinks.push({
		label: "Edit",
		href: `//${window.location.host}/dashboard/${dashboard.clientData?.id}`,
		icon: "fal fa-pencil",
		key: "edit",
	});
	footerLinks.push({
		label: "Preview",
		href: `//${window.location.host}/${dashboard.clientData?.key}`,
		icon: "fal fa-globe",
		key: "preview",
	});

	return !isEmpty(initialFormValues) ? (
		<SectionedPageContainer
			size="md"
			sections={sections}
			defaultSection={formSections.details}
			//@ts-ignore
			pageTitle={action !== "add" ? initialFormValues.name : "New Organization"}
			pageSupertitle="Organization"
			footerLinks={footerLinks}
			htmlPageTitle="Organization Settings"
		>
			<Form initialValues={initialFormValues} validate={validate} validateOnChange={false}>
				{({ formikContext, extraData }: FormContext<Client>) => {
					const {
						values: formValues,
						errors,
						setFieldValue,
						handleBlur,
						touched,
						isValid,
						dirty,
						resetForm,
					} = formikContext;

					return (
						<>
							{dirty && <Form.Prompt isSaving={isSubmitting} />}
							{extraData.showSummary && !isEmpty(formikContext.errors) && (
								<Form.Summary errors={formikContext.errors} handleFormSections />
							)}

							<fieldset disabled={isSubmitting}>
								<FormSection sectionKey={formSections.details}>
									<PageTitle>
										{verify === true ? "Verify Organization Information" : "DETAILS"}
									</PageTitle>
									<Well>
										<Form.Field
											label="Name *"
											id="name"
											name="name"
											onChange={(event: any) => {
												setFieldValue("name", event.target.value);
												if (!touchedKey) {
													setFieldValue(
														"key",
														event.target.value.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
													);
												}
											}}
										/>
										<StickyTextInput
											prefix={isChurchX ? "churchx.online/" : "online.brushfire.com/"}
										>
											<Form.Field
												label={`URL *`}
												id="key"
												name="key"
												placeholder=""
												className="client-key field"
												onBlur={(e: any) => {
													setTouchedKey(true);
													handleBlur(e);
												}}
												onChange={(event: any) => {
													let newString = event.target.value.replace(/[^a-zA-Z0-9]/g, "-");
													formikContext.setFieldValue("key", newString.toLowerCase());
												}}
											/>
										</StickyTextInput>
										<Form.Field label="Country" id="country" name="country" placeholder="Country" />
										<Form.Field label="Street" id="street" name="street" placeholder="Street" />

										<Row>
											<Col xs={12} sm={5} className="p-0-sm">
												<Form.Field label="City" id="city" name="city" placeholder="City" />
											</Col>
											<Col xs={12} sm={3} className="p-0-sm">
												<Form.Field
													label="Region"
													id="region"
													name="region"
													placeholder="Region"
												/>
											</Col>
											<Col xs={12} sm={4} className="p-0-sm">
												<Form.Field
													label="Postal Code"
													id="postalCode"
													name="postalCode"
													placeholder="Postal Code"
												/>
											</Col>
										</Row>

										<Form.Field
											component={Form.Input.Select}
											label="Timezone *"
											id="timeZoneName"
											name="timeZoneName"
											data={timeZoneData}
											isSearchable={true}
										/>

										{user?.appUser?.globalRole === "owner" && (
											<StickyTextInput prefix="https://">
												<Form.Field
													label="Custom Domain"
													id="customDomain"
													name="customDomain"
												/>
											</StickyTextInput>
										)}
										<h6>Login Providers</h6>
										<div className="form-toggle">
											<Form.Field
												component={Form.Input.Checkbox}
												label="Google"
												id="authProviders.google.enabled"
												name="authProviders.google.enabled"
												switchToggle={true}
											/>
										</div>
										<div className="form-toggle">
											<Form.Field
												component={Form.Input.Checkbox}
												label="Facebook"
												id="authProviders.facebook.enabled"
												name="authProviders.facebook.enabled"
												switchToggle={true}
											/>
										</div>
									</Well>
								</FormSection>
								<FormSection sectionKey={formSections.theme}>
									<PageTitle>Theme</PageTitle>
									<Well
										message="Choose a base appearance to start with or choose to fully customize your organization’s appearance with the appearance editor."
										learnMoreLink="https://support.brushfire.com/hc/en-us/articles/360012850479-Brand-and-Customize-your-Brushfire-Online-Stream"
									>
										<Appearance
											clientId={formValues.id}
											showNavBarStyles
											formikContext={formikContext}
										/>
									</Well>
								</FormSection>
								<FormSection sectionKey={formSections.links}>
									<Sections.Links
										name="links"
										formikContext={formikContext}
										wellMessage="Links appear in the navigation at the top of all of your pages. You can
												open links in a new tab or a modal to display content on the page."
										learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238049808525-Adding-Links-and-Giving-to-Brushfire-Online"
									/>
								</FormSection>

								<Sticky>
									<FormSubmitButton
										disabled={isSubmitting}
										isSubmitting={isSubmitting}
										isAddForm={action !== "edit"}
										onClick={() => {
											if (isValid && !isEmpty(touched)) {
												onSubmit(formikContext.values, resetForm);
											} else if (!isValid) {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}
										}}
									/>
								</Sticky>
							</fieldset>
						</>
					);
				}}
			</Form>
		</SectionedPageContainer>
	) : (
		<PageContainer htmlPageTitle="Organization Settings">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>Client not found.</div>
		</PageContainer>
	);
};

export default ClientForm;
