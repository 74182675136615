import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from "react-router-dom";
import firebase from "../firebase";
import { ClientData } from "../interfaces";
import AttendeeLandingPage from "../pages/Attendee/AttendeeLandingPage";
import BrushfireStandalonePaywall from "../pages/BrushfireStandalonePaywall";
import ChannelPage from "../pages/Channel/Channel";
import ClientPage from "../pages/Client";
import SplashPage from "../pages/Splash";
import api from "../services/api";

const fba = firebase.analytics();

export const CustomDomainContext = React.createContext<ClientData | undefined>(undefined);

interface ClientRoutesProps extends RouteComponentProps {}

const ClientRoutes: React.FC<ClientRoutesProps> = props => {
	const clientData = useContext(CustomDomainContext);
	fba.setAnalyticsCollectionEnabled(false);
	return (
		<>
			<Switch>
				<Route path="/attend/:eventNumber/:attendeeNumber?" exact component={AttendeeLandingPage} />
				{!clientData && <Route path="/events/:eventNumber" exact component={BrushfireStandalonePaywall} />}
				{clientData ? (
					<Route path="/:channelKey/magic-link" component={ChannelPage} />
				) : (
					<Route path="/:clientKey/:channelKey/magic-link" component={ChannelPage} />
				)}
				{clientData ? (
					<Route path="/:channelKey/:contentKey" component={ChannelPage} />
				) : (
					<Route path="/:clientKey/:channelKey/:contentKey" component={ChannelPage} />
				)}
				{clientData ? (
					<Route path="/:channelKey" component={ChannelPage} />
				) : (
					<Route path="/:clientKey/:channelKey" component={ChannelPage} />
				)}
				{clientData ? (
					<Route path="/" component={ClientPage} />
				) : (
					<Route path="/:clientKey" component={ClientPage} />
				)}
			</Switch>
		</>
	);
};

const domainExceptions = ["online.brushfire.com", "churchx.online", "staging-brushfire-online.web.app"];

const PublicRoutes: React.FC = () => {
	const [clientData, setClientData] = useState<ClientData>();
	const [showRoutes, setShowRoutes] = useState(false);

	useEffect(() => {
		const getCustomDomainClient = async () => {
			const clientDataResponse = await api.fetchClient(window.location.host);

			if (clientDataResponse.ok) {
				const { data: clientData } = clientDataResponse;
				setClientData(clientData);
			}
			setShowRoutes(true);
		};

		const { host } = window.location;
		if (!domainExceptions.includes(host) && !host.includes("localhost")) {
			getCustomDomainClient();
		} else {
			setShowRoutes(true);
		}
	}, []);

	return showRoutes ? (
		<CustomDomainContext.Provider value={clientData}>
			<Router>
				<Switch>
					<Route path="/:clientKey" component={ClientRoutes} />
					<Route
						path="/"
						render={(props: any) => {
							if (clientData) {
								return <ClientRoutes {...props} />;
							} else {
								return <SplashPage {...props} />;
							}
						}}
					/>
				</Switch>
			</Router>
		</CustomDomainContext.Provider>
	) : null;
};

export default PublicRoutes;
