import React, { useState, useEffect } from "react";

interface GlobalLoaderProps {
	classNames?: string;
	isActive?: boolean;
}

export let showLoader: any = () => {};
export let hideLoader: any = () => {};

const GlobalLoader: React.FC<GlobalLoaderProps> = ({ children, classNames = "", isActive = false }) => {
	const [loading, setLoading] = useState(isActive);
	showLoader = () => setLoading(true);
	hideLoader = () => setLoading(false);

	useEffect(() => {
		setLoading(isActive);
	}, [isActive]);

	return (
		<div className={`loader-screen relative blur-bg ${classNames} ${loading ? "active" : ""}`}>
			<div className="absolute-center ta-center">
				<i className="fal fa-spin fa-spinner-third fa-4x" />
			</div>
		</div>
	);
};

export default GlobalLoader;
