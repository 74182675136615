import React from "react";

import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";
import { ReactionIcon, ContentReactions } from "../../../../../interfaces";

import * as Styled from "../ContentFormSections.styled";

export interface ReactionsSectionProps {
	isEnabled: boolean;
	reactions: ContentReactions;
}

const ReactionsSection: React.FC<ReactionsSectionProps> = ({ isEnabled, reactions }) => {
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>Reactions</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="reactions.enabled"
					name="reactions.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{isEnabled ? (
				<Well message="Reactions allow your viewers to share visual feedback and engage with your content in realtime.">
					<div className="reaction-pickers">
						{Object.values(ReactionIcon).map(icon => {
							return (
								<Form.Field
									component={Form.Input.ReactionPicker}
									label=""
									id={`reactions.icons.${icon}`}
									name={`reactions.icons.${icon}`}
									icon={ReactionIcon[icon]}
									disabled={
										!reactions.icons[icon] &&
										Object.keys(reactions.icons).filter(i => reactions.icons[i] === true).length ===
											4
									}
									key={icon}
								/>
							);
						})}
					</div>
				</Well>
			) : (
				<Well>
					<p>
						Reactions allow your viewers to share visual feedback and engage with your content in realtime.
					</p>
				</Well>
			)}
		</>
	);
};

export default ReactionsSection;
