/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { isEmpty } from "lodash";

Modal.setAppElement("#root");

export interface SimpleModalProps {
	title?: string;
	icon?: string;
	visibility: boolean;
	closeModal: () => void;
	style?: any;
	modalClassName?: string;
}

const SimpleModal: React.FC<SimpleModalProps> = ({ title, icon, visibility, closeModal, style = {}, children, modalClassName= "" }) => {
	const [isVisible, setIsVisible] = useState(visibility);

	useEffect(() => {
		if (isVisible !== visibility) {
			setIsVisible(visibility);
			document.body.style.overflow = visibility ? "hidden" : "scroll";
		}
	}, [visibility]);

	return (
		<Modal
			style={style}
			className={`react-modal ${modalClassName}`}
			overlayClassName="react-modal-overlay"
			isOpen={isVisible}
			onRequestClose={closeModal}
		>
			<div className="modal-header-wrapper">
				<div className="modal-header-title">
					{!isEmpty(icon) && <i className={icon} style={{ marginRight: 10 }} />}
					{!isEmpty(title) && <span style={{ marginRight: 10 }}>{title}</span>}
				</div>
				<div className="modal-close-button" onClick={closeModal}>
					<i className="fal fa-times" />
				</div>
			</div>
			<div className="modal-content-wrapper">{children}</div>
		</Modal>
	);
};

export default SimpleModal;
