import React, { useContext, useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import Form from "../../Form";
import { Interaction, Response, ResponseActions } from "../../../interfaces";

import { Context, Dismiss } from "../InteractionResponse";
import * as Styled from "../InteractionResponse.styled";

interface CollectEmailProps {
	response: Response;
	getData: (values: any, response: Response) => void;
}

export const CollectEmail: React.FC<CollectEmailProps> = ({ response, getData }) => {
	const { isPreview, respond, interaction } = useContext(Context);

	const onSubmit = (values: any) => {
		getData(values, response);
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.required("Email is required")
			.email("Must be a valid email"),
	});

	return (
		<>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<p>Thank you! Please provide an email address for us to follow up with.</p>
				<Form
					initialValues={{ email: "" }}
					validationSchema={!isPreview ? schema : null}
					onSubmit={onSubmit}
					style={{ width: "100%" }}
				>
					<Styled.FormField
						className="field"
						isPreview={isPreview}
						label="Email"
						id="email"
						name="email"
						type="email"
					/>
					<Button type="submit" variant="info" size="sm" style={{ width: "100%" }}>
						Submit
					</Button>
				</Form>
			</div>
			<Styled.HR isPreview={isPreview} />
			<Dismiss />
		</>
	);
};
