import React, { useState, useEffect } from "react";
import Form from "../../components/Form";
import { Well, PageTitle } from "./UI";
import { ThemeProps } from "./Theme/helpers";
import ThemePicker from "./Theme/ThemePicker";

interface AppearanceProps {
	clientId?: string;
	showNavBarStyles?: boolean;
	renderOverrideToggle?: () => void;
	showColorStyles?: boolean;
	isChannel?: boolean;
	customAppearance?: boolean;
	isSaving?: boolean;
	formikContext?: any;
}

const Appearance: React.FC<AppearanceProps> = ({
	clientId,
	showNavBarStyles = false,
	renderOverrideToggle,
	showColorStyles = true,
	isChannel,
	customAppearance,
	isSaving,
	formikContext,
}) => {
	const [selectedTheme, setSelectedTheme] = useState("");

	const selectTheme = (themeProps: ThemeProps | null) => {
		const { setValues, values } = formikContext;

		if (!themeProps) {
			setSelectedTheme("");
		} else {
			const { id, theme } = themeProps;

			setValues({
				...values,
				theme: {
					...theme,
					backgroundImage: formikContext?.values?.theme?.backgroundImage || "",
				},
			});
			setSelectedTheme(id);
		}
	};

	const renderForm = () => {
		return (
			<>
				{clientId && (
					<>
						<h3>Page Artwork</h3>
						<Form.Field
							component={Form.Input.Uploader}
							clientId={clientId}
							isSaving={isSaving}
							label={isChannel ? "Logo" : "Logo *"}
							id="logoImage"
							name="logoImage"
							helperText="Logo image should have a transparent background for best results."
						/>
						<Form.Field label="Logo Url" id="homeUrl" name="homeUrl" />
						<Form.Field
							component={Form.Input.Uploader}
							clientId={clientId}
							isSaving={isSaving}
							label="Page Background Image"
							id="theme.backgroundImage"
							name="theme.backgroundImage"
						/>
					</>
				)}
				<h3>Default Themes</h3>
				<ThemePicker
					selectTheme={selectTheme}
					selectedTheme={selectedTheme}
					activeTheme={formikContext && formikContext.values.theme}
				/>
				<Form.Field
					component={Form.Input.Checkbox}
					label="SHOW DETAILED SETTINGS"
					id="showSettings"
					name="showSettings"
				/>
				{formikContext && formikContext.values.showSettings && (
					<>
						<h3>Navigation</h3>
						<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
							<div className="one-third">
								<Form.Color
									label="Background Color"
									id="theme.navBackgroundColor"
									name="theme.navBackgroundColor"
								/>
							</div>
							<div className="one-third">
								<Form.Color label="Link Color" id="theme.navLinkColor" name="theme.navLinkColor" />
							</div>
							<div className="one-third">
								<Form.Color
									label="Link Hover Color"
									id="theme.navLinkHoverColor"
									name="theme.navLinkHoverColor"
								/>
							</div>
						</div>
						<h3>Page</h3>
						{clientId && (
							<div>
								<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
									<div className="one-third">
										<Form.Color label="Text Color" id="theme.textColor" name="theme.textColor" />
									</div>
									<div className="one-third">
										<Form.Color
											label="Background Color"
											id="theme.backgroundColor"
											name="theme.backgroundColor"
										/>
									</div>
								</div>
							</div>
						)}
						{!!renderOverrideToggle && renderOverrideToggle()}
						{showColorStyles && (
							<>
								<h3>Buttons</h3>
								<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
									<div className="one-third">
										<Form.Color
											label="Text Color"
											id="theme.buttonTextColor"
											name="theme.buttonTextColor"
										/>
									</div>
									<div className="one-third">
										<Form.Color
											label="Background Color"
											id="theme.buttonBackgroundColor"
											name="theme.buttonBackgroundColor"
										/>
									</div>
									<div className="one-third">
										<Form.Color
											label="Background Hover Color"
											id="theme.buttonHoverBackgroundColor"
											name="theme.buttonHoverBackgroundColor"
										/>
									</div>
								</div>
								<h3>Well</h3>
								<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
									<div className="one-third">
										<Form.Color
											label="Text Color"
											id="theme.wellTextColor"
											name="theme.wellTextColor"
										/>
									</div>
									<div className="one-third">
										<Form.Color
											label="Background Color"
											id="theme.wellBackgroundColor"
											name="theme.wellBackgroundColor"
										/>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</>
		);
	};
	return (
		<>
			{customAppearance && (
				<Form.Field
					component={Form.Input.Checkbox}
					label="USE CLIENT THEME?"
					id="showAppearance"
					name="showAppearance"
				/>
			)}
			{customAppearance && formikContext && !formikContext.values.showAppearance && renderForm()}
			{!customAppearance && renderForm()}
		</>
	);
};

export default Appearance;
