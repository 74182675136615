import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormikContextType } from "formik";

import { languageData, Books } from "./bibleData";
import { PageTitle, Well } from "../../";
import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";
import Form from "../../../../../components/Form";

import * as Styled from "../ContentFormSections.styled";

export interface BibleSectionProps {
	isEnabled: boolean;
	formikContext: FormikContextType<ContentFormContent>;
}

const BibleSection: React.FC<BibleSectionProps> = ({ isEnabled, formikContext }) => {
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>BIBLE</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="bible.enabled"
					name="bible.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{isEnabled ? (
				<Well
					message="Allow viewers to follow along in a passage of scripture."
					learnMoreLink="https://support.brushfire.com/hc/en-us/articles/360013222999-Enabling-the-Bible-in-Brushfire-Online"
				>
					<>
						<h5>Passage</h5>
						<Form.Field
							component={Form.Input.Select}
							label="Translation *"
							id="bible.passage.translation"
							name="bible.passage.translation"
							groupedData={languageData}
							onChange={(translationData: any) => {
								formikContext.setFieldValue("bible.passage.translation", translationData.value);
								formikContext.setFieldValue("bible.passage.language", translationData.language);
							}}
							isSearchable={false}
						/>

						<Row>
							<Col>
								<Form.Field
									component={Form.Input.Select}
									label="Book *"
									id="bible.passage.book"
									name="bible.passage.book"
									data={Books}
									isSearchable={false}
								/>{" "}
							</Col>
							<Col>
								<Form.Field
									label="Chapter *"
									placeholder="1"
									id="bible.passage.chapter"
									name="bible.passage.chapter"
								/>
							</Col>
						</Row>
						<small>Preset the Bible Passage for your service.</small>
					</>
				</Well>
			) : (
				<Well>
					<p>
						Allow viewers to follow along in a passage of scripture.{" "}
						<span>
							<a
								href="https://support.brushfire.com/hc/en-us/articles/360013222999-Enabling-the-Bible-in-Brushfire-Online"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <i className="fal fa-long-arrow-right" />
							</a>
						</span>
					</p>
				</Well>
			)}
		</>
	);
};

export default BibleSection;
