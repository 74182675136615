import styled from "styled-components";

interface ThemeButtonProps {
	selected: boolean;
}

const ThemeButtonWrapper = styled.div`
	width: 33.33%;
	padding: 10px;

	@media screen and (max-width: 992px) {
		width: 50%;
	}
`;

const ThemeButton = styled.div<ThemeButtonProps>`
	background-color: ${({ selected }) => (selected ? "#4a525a" : "transparent")};
	border: none;
	width: 100%;
	transition: all 0.25s;
	padding: 10px;
	border-radius: 6px;

	img {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}

	p {
		padding-left: 10px;
		margin-top: 2px;
		margin-bottom: 0px;
		font-size: 12px;
		text-align: left;
		width: 100%;
		color: #ffffff;
	}

	&:hover {
		background-color: #4a525a;
		transform: scale(1.02);
		cursor: pointer;
	}
`;

export { ThemeButtonWrapper, ThemeButton };
