import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { isTablet } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import authFirebase from "../../authFirebase";
import {
	AuthProviders,
	ChannelData,
	Client,
	ClientData,
	Content,
	ContentType,
	Link,
	CustomPanel,
	Theme,
} from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import {
	setIsLoggingOut,
	showLoginModal as showLoginModalAction,
	showLogoutModal as showLogoutModalAction,
} from "../../redux/User/actions";
import { logEvent } from "../../services/analytics";
import { updateAnalyticsUserDoc } from "../../services/dataHelpers";
import Button from "../Button";
import Drawer from "../Drawer/Drawer";
import FrontEndLogin from "../FrontEndLogin/FrontEndLogin";
import LiveBadge from "../LiveBadge";
import HTMLModal from "../Modals/HTMLModal";
import IFrameModal from "../Modals/IFrameModal";
import { DrawerLinkButton, LinkButton } from "./Links.styled";
import { codeProviderHelper, elementRender, LinkElement, whichProvider } from "./linksHelper";

interface LinkStyleProps {
	theme: Theme;
}

interface HeaderProps {
	client?: ClientData | Client;
	content?: Content;
	channel?: ChannelData;
	authProviders?: AuthProviders;
	customPanel?: CustomPanel;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const Header = ({ client, content, channel, authProviders, customPanel }: HeaderProps) => {
	let { channelKey, contentKey } = useParams<{ channelKey: string; contentKey: string }>();
	channelKey = channelKey?.toLocaleLowerCase();
	contentKey = contentKey?.toLocaleLowerCase();

	const dispatch = useDispatch();

	const [drawerVisibility, setDrawerVisibility] = useState(false);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	useEffect(() => {
		document.body.classList.toggle("menuOpen", drawerVisibility);
	}, [drawerVisibility]);

	const toggleDrawer = () => {
		setDrawerVisibility(!drawerVisibility);
	};

	const [modalObject, setModalObject] = useState({
		visible: false,
		html: "",
		allowScript: false,
		title: "",
		icon: "",
	});
	const [iframeModalObject, setIframeModalObject] = useState({ visible: false, src: "", title: "", icon: "" });

	const { analyticsUserId } = useTypedSelector(store => store.analytics);
	const { showLoginModal, showLogoutModal } = useTypedSelector(store => store.user);

	const onClick = (link: Link, index: number) => {
		const { url, html, code, text, icon } = link;
		setDrawerVisibility(false);

		logEvent("click_client_link", text);

		if (url) {
			switch (url?.target) {
				case "newTab": {
					window.open(url.url, "_blank");
					break;
				}
				case "sameTab": {
					window.open(url.url, "_self");
					break;
				}
				case "iframeModal": {
					setIframeModalObject({
						visible: true,
						src: url.url,
						title: text,
						icon,
					});
					break;
				}
				case "sideBar": {
					customPanel?.open({
						key: `custom-panel-${index}`,
						icon: icon || "fa-star",
						content: <iframe style={styles.iframe} title={text} src={url.url} />,
						title: text,
					});
					break;
				}
				default: {
					return null;
				}
			}
		} else if (html) {
			setModalObject(prevModalObject => ({ ...prevModalObject, visible: true, html }));
		} else if (code) {
			const provider = whichProvider(link);
			switch (provider) {
				case "onlineGiving": {
					setIframeModalObject({
						visible: true,
						src: `https://${code}.onlinegiving.org/donate/guest_donate?embed=1`,
						title: text,
						icon,
					});
					break;
				}
				case "paperlessTransactions": {
					setIframeModalObject({
						visible: true,
						src: `https://virtuous.paperlesstrans.com/${code}`,
						title: text,
						icon,
					});
					break;
				}
				case "givelify": {
					setIframeModalObject({
						visible: true,
						src: code,
						title: text,
						icon,
					});
					break;
				}
				default: {
					setModalObject({
						visible: true,
						html: code,
						allowScript: true,
						title: text,
						icon,
					});
					break;
				}
			}
		}
	};

	const closeModal = () => {
		setModalObject({
			visible: false,
			html: "",
			allowScript: false,
			title: "",
			icon: "",
		});
		setIframeModalObject({
			visible: false,
			src: "",
			title: "",
			icon: "",
		});
	};

	const renderLinks = (codeLinkElements: LinkElement[], isDrawerItem: boolean = false) => {
		let links = client?.links || [];
		if (channel?.links && channel?.links.length > 0) {
			links = channel.links;
		}
		const res = links.map((link, index) => {
			const { icon, text } = link;

			if (link.hasOwnProperty("code") && link.hasOwnProperty("codeProvider")) {
				const elementInfo = codeLinkElements.find(l => l.index === index);
				const provider = whichProvider(link);
				if (elementInfo && provider) {
					return elementRender(provider, elementInfo, { index, icon, isTablet, isDrawerItem });
				}
			}
			if (link.enabled === false) {
				return null;
			} else {
				return isDrawerItem ? (
					<DrawerLinkButton onClick={() => onClick(link, index)} key={index}>
						<h2>{text}</h2>
					</DrawerLinkButton>
				) : (
					<LinkButton key={`link-${index + 1}`} onClick={() => onClick(link, index)}>
						{icon ? <i className={icon} style={styles.iconStyle} /> : null}
						{text ? <span>{text}</span> : null}
					</LinkButton>
				);
			}
		});
		return res;
	};

	const renderHomeUrl = () => {
		if (channel?.homeUrl) {
			return channel.homeUrl;
		} else if (client?.homeUrl) {
			return client.homeUrl;
		}
		return `/${client ? client.key : channel?.clientKey}`;
	};

	const { codeLinkHeaders, codeLinkElements } = codeProviderHelper(
		channel?.links && channel.links.length > 0 ? channel.links : client?.links || []
	);

	const theme = client ? client?.theme : (channel?.theme as Theme);

	return (
		<div
			className="nav-container"
			style={{
				backgroundColor: theme.navBackgroundColor,
			}}
		>
			<Helmet>
				{channel &&
					channel.chatWidget?.enabled &&
					channel.chatWidget?.provider === "zendesk" &&
					isMobile &&
					drawerVisibility && (
						<script
							id="ze-snippet"
							src={`https://static.zdassets.com/ekr/snippet.js?key=${channel.chatWidget?.embedId}`}
						>
							{" "}
						</script>
					)}
				{channel &&
					channel.chatWidget?.enabled &&
					channel.chatWidget?.provider === "zendesk" &&
					isMobile &&
					(window as any).$zopim && (
						<script type="text/javascript">
							{drawerVisibility
								? (window as any).$zopim.livechat?.button.show()
								: (window as any).$zopim.livechat?.button.hide()}
						</script>
					)}
			</Helmet>
			{!isEmpty(codeLinkHeaders) && <Helmet>{codeLinkHeaders.map(h => h)};</Helmet>}
			{content && (
				<Modal
					show={showLoginModal}
					centered
					onHide={() => {
						dispatch(showLoginModalAction(false));
					}}
					className="default-modal"
				>
					<ModalHeader
						onHide={() => {
							dispatch(showLoginModalAction(false));
						}}
					>
						<h4>Sign In</h4>
						<div
							className="close"
							onClick={() => {
								dispatch(showLoginModalAction(false));
							}}
						>
							<i className="fal fa-times"></i>
						</div>
					</ModalHeader>
					<ModalBody>
						<FrontEndLogin
							enableAnonymous={content?.chat.enableAnonymous ?? false}
							client={client}
							content={content}
							contentId={content.id}
							analyticsUserId={analyticsUserId}
							authProviders={authProviders}
							onSignIn={user => {
								dispatch(showLoginModalAction(false));
								if (client) {
									localStorage.setItem(`auth-${client.id}`, "1");
								} else if (content) {
									localStorage.setItem(`auth-${content.clientId}`, "1");
								}
								if (content && user) {
									updateAnalyticsUserDoc(
										user,
										content.id,
										{ authorized: true, analyticsUserId },
										content.contentType || ContentType.simulatedLive,
										false
									);
								}
							}}
						/>
					</ModalBody>
				</Modal>
			)}
			{content && (
				<Modal
					show={showLogoutModal}
					centered
					onHide={() => {
						dispatch(showLogoutModalAction(false));
					}}
					className="default-modal"
				>
					<ModalHeader
						onHide={() => {
							dispatch(showLogoutModalAction(false));
						}}
					>
						<h4>Sign Out</h4>
						<div
							className="close"
							onClick={() => {
								dispatch(showLogoutModalAction(false));
							}}
						>
							<i className="fal fa-times"></i>
						</div>
					</ModalHeader>
					<ModalBody>Are you sure you want to sign out?</ModalBody>

					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								dispatch(showLogoutModalAction(false));
							}}
							className="modal-button cancel-button"
						>
							Cancel
						</Button>
						<Button
							color="#e71d36"
							onClick={() => {
								const auth = authFirebase.auth();

								dispatch(setIsLoggingOut(true));

								auth.signOut().then(() => {
									dispatch(showLogoutModalAction(false));
									setTimeout(() => {
										auth.signInAnonymously()
											.then(user => {})
											.finally(() => {
												dispatch(setIsLoggingOut(false));
											});
									}, 2000);
								});
							}}
							className="modal-button confirm-button"
						>
							Leave
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<ThemeProvider theme={theme}>
				<a className="logo" href={renderHomeUrl()}>
					<img alt="Logo" src={channel && channel.logoImage ? channel.logoImage : client?.logoImage} />
				</a>
				{(client || channel) && (
					<>
						<div className="menu">{renderLinks(codeLinkElements)}</div>
						<div className="mobile-menu">
							<LinkButton onClick={() => toggleDrawer()} style={{ marginRight: 0 }}>
								<i className="fal fa-bars drawer-menu-icon" />
							</LinkButton>
						</div>
						<Drawer
							toggleDrawer={() => toggleDrawer()}
							isOpen={drawerVisibility}
							renderLinks={() => renderLinks(codeLinkElements, true)}
							clientKey={client ? client.key : channel?.clientKey}
							channelKey={channelKey}
							contentKey={contentKey}
							logoUrl={renderHomeUrl()}
							theme={theme}
							logoImage={channel && channel.logoImage ? channel.logoImage : client?.logoImage || ""}
						/>
						<HTMLModal
							content={modalObject.html}
							visibility={modalObject.visible}
							closeModal={closeModal}
							allowScript={modalObject.allowScript}
							title={modalObject.title}
							icon={modalObject.icon}
						/>
						<IFrameModal
							visible={iframeModalObject.visible}
							closeModal={closeModal}
							src={iframeModalObject.src}
							title={modalObject.title}
							icon={modalObject.icon}
						/>
						{content && (
							<div className="nav-live-badge">
								<LiveBadge
									contentData={content}
									invertable
									showLiveNumber={content.showLiveStats}
									isFrontEnd
								/>
							</div>
						)}
					</>
				)}
			</ThemeProvider>
		</div>
	);
};

const styles = {
	container: {
		width: "100%",
		height: "100%",
	},
	iconStyle: {
		marginRight: 5,
	},
	iframe: {
		width: "100%",
		height: "100%",
		border: 0,
		position: "absolute" as "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
};

export default Header;
