import { createLogic } from "redux-logic";
import { SET_CHANNEL_DATA_VALUE, CANCEL_SET_CHANNEL_DATA_VALUE } from "../types";
import _ from "lodash";

const SetChannelDataLogic = createLogic({
	type: SET_CHANNEL_DATA_VALUE, // only apply this logic to this type
	cancelType: CANCEL_SET_CHANNEL_DATA_VALUE, // cancel on this type
	latest: true,
	process(payload, dispatch, done) {
		done();
	}
});

export default [SetChannelDataLogic];
