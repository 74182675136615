import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { emit, CHAT_SIGNINPASSWORD } from "./Chat.service";

const style = {
	ChatEmail: styled.input``,
	ChatPassword: styled.input``,
	ChatLoginButton: styled.button``,
	ChatForgotPasswordButton: styled.button``,
	ChatRegisterButton: styled.button``,
	ChatBackButton: styled.button``,
	ChatMsgBlock: styled.div``,
	ChatMsgRow: styled.div``,
};

export default class ChatEmailLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loginStatus: "",
			processingStatus: "",
		};
		this.chatId = this.props.chatId;
		this.onAction = this.props.onAction;
		this.setEmailRef = this.setEmailRef.bind(this);
		this.setPasswordRef = this.setPasswordRef.bind(this);
	}

	componentDidMount() {
		this.emailRef.focus();
	}

	onLogin() {
		// trigger login
		const data = {
			email: this.emailRef.value,
			password: this.passwordRef.value,
			error: {},
			success: true,
			chatId: this.chatId,
		};
		// clear text fields before proceeding.
		this.emailRef.value = "";
		this.passwordRef.value = "";
		// clear error message because we are logging in again.
		this.setState({
			loginStatus: "",
			processingStatus: "logging in please wait",
		});
		emit(CHAT_SIGNINPASSWORD, data).then(() => {
			if (data.success === false) {
				console.log(`Login failed ${data.error.code} ${data.error.message}`);
				this.setState({
					loginStatus: data.error.message,
					processingStatus: "",
				});
				return;
			}
			this.onAction({
				success: data.success,
				user: {
					uid: data.user.uid,
					userName: data.user.email,
				},
			});
		}, this.chatId);
	}

	onForgotPassword() {
		this.onAction("forgot");
	}

	onRegister() {
		this.onAction("register");
	}

	onBack() {
		this.onAction("back");
	}

	onKeyUp(e, whichField) {
		if (whichField === "password") {
			if (e.key === "Enter") {
				this.onLogin();
			}
		}
	}

	render() {
		const chatEmailProps = {
			type: "text",
			onKeyUp: e => this.onKeyUp.bind(this)(e, "email"),
			ref: this.setEmailRef,
		};
		const chatPasswordProps = {
			type: "password",
			onKeyUp: e => this.onKeyUp.bind(this)(e, "password"),
			ref: this.setPasswordRef,
		};
		const chatLoginButtonProps = {
			onClick: this.onLogin.bind(this),
		};
		const chatForgotPasswordButtonProps = {
			onClick: this.onForgotPassword.bind(this),
		};
		const chatRegisterButtonProps = {
			onClick: this.onRegister.bind(this),
		};
		const chatBackButtonProps = {
			onClick: this.onBack.bind(this),
		};
		return (
			<React.Fragment>
				{this.renderMessageIfAny.bind(this)()}
				<style.ChatEmail {...chatEmailProps} />
				<style.ChatPassword {...chatPasswordProps} />
				<style.ChatLoginButton {...chatLoginButtonProps}>login</style.ChatLoginButton>
				<style.ChatForgotPasswordButton {...chatForgotPasswordButtonProps}>
					forgot password
				</style.ChatForgotPasswordButton>
				<style.ChatRegisterButton {...chatRegisterButtonProps}>register</style.ChatRegisterButton>
				<style.ChatBackButton {...chatBackButtonProps}>back</style.ChatBackButton>
			</React.Fragment>
		);
	}

	renderMessageIfAny() {
		const message = [];
		if (this.state.loginStatus.length > 0) {
			message.push(this.state.loginStatus);
		}
		if (this.state.processingStatus.length > 0) {
			message.push(this.state.processingStatus);
		}
		return (
			<style.ChatMsgBlock>
				{message.map((m, i) => (
					<style.ChatMsgRow key={i}>{m}</style.ChatMsgRow>
				))}
			</style.ChatMsgBlock>
		);
	}

	setEmailRef(element) {
		this.emailRef = element;
	}

	setPasswordRef(element) {
		this.passwordRef = element;
	}
}

ChatEmailLogin.propTypes = {
	chatId: PropTypes.string.isRequired,
	onAction: PropTypes.func.isRequired,
};
