import { orderBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RouteComponentProps, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "../components/Footer/Dashboard/Footer";
import { hideLoader, showLoader } from "../components/GlobalLoader";
import GlobalStyle from "../components/GlobalStyle";
import Header from "../components/Header";
import { ClientData, GoogleAnalytics, PaywallGlobalAccess } from "../interfaces";
import { CustomDomainContext } from "../navigation/PublicRoutes";
import { TypedUseSelectorHook } from "../redux";
import { Dimension, setAnalytics, setDimensions } from "../services/analytics";
import api from "../services/api";
import userHasRole from "../services/userHasRole";
import { isChurchX } from "../utils/churchx";
import { checkAvailability } from "../pages/Channel/utils";
import firebaseApp from "../firebase";

const useTypedSelector: TypedUseSelectorHook = useSelector;
interface ClientProps extends RouteComponentProps { }

const Client: React.FC<ClientProps> = ({ history, ...props }) => {
	const user = useTypedSelector(store => store.user);
	const customDomainData = useContext(CustomDomainContext);
	let { clientKey } = useParams<{ clientKey: string }>();
	clientKey = clientKey?.toLocaleLowerCase();
	const [loading, setLoading] = useState(true);
	if (customDomainData) {
		customDomainData.channels = customDomainData?.channels.filter(channel => !channel.isPrivate);
		clientKey = customDomainData.key;
	}
	const [client, setClient] = useState<ClientData | undefined>(customDomainData);
	const [attendeeNumber, setAttendeeNumber] = useState<string>("");
	const [attendeeData, setAttendeeData] = useState<PaywallGlobalAccess["attendeeData"] | null>(null);
	const db = firebaseApp.firestore();

	useEffect(() => {
		const fetchChannelData = async () => {
			showLoader();
			const clientResponse = await api.fetchClient(clientKey);

			if (clientResponse.ok) {
				const clientData: ClientData = clientResponse.data;

				if (clientData && clientData.channels.filter(chan => !chan.isPrivate).length === 1) {
					const channelData = clientData.channels.filter(chan => !chan.isPrivate)[0];
					history.push(`/${clientData.key}/${channelData.key}`);
				}

				const filteredChannels = clientData.channels.filter(channel => !channel.isPrivate);
				clientData.channels = filteredChannels;
				setClient(clientData);
			}
			setLoading(false);
			hideLoader();
		};

		if (!customDomainData) {
			fetchChannelData();
		} else {
			if (customDomainData.channels.filter(chan => !chan.isPrivate).length === 1) {
				const channelData = customDomainData.channels.filter(chan => !chan.isPrivate)[0];
				history.push(`/${channelData.key}`);
			}
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientKey, history]);

	useEffect(() => {
		if (client && user.isLoadingAppUser === false) {
			const dimensions: { [key: string]: string | null } = {
				[Dimension.clientId]: client.id,
				[Dimension.clientKey]: client.key,
				[Dimension.channelId]: null,
				[Dimension.channelKey]: null,
				[Dimension.contentId]: null,
				[Dimension.contentKey]: null,
			};
			const isAdmin =
				!!user.appUser && userHasRole(client.id || "", user.appUser, ["owner", "admin", "host", "superhost"]);
			if (isAdmin) {
				dimensions[Dimension.isGlobalUser] = "true";
				localStorage.setItem(`gaAdmin-${client.id}`, moment.utc().toISOString());
			} else {
				const gaAdmin = localStorage.getItem(`gaAdmin-${client.id}`);
				if (gaAdmin && moment().diff(moment(gaAdmin), "minutes") < 30) {
					dimensions[Dimension.isGlobalUser] = "true";
				} else {
					dimensions[Dimension.isGlobalUser] = "(not set)";
				}
			}
			setDimensions(dimensions);

			const googleAnalytics: Array<GoogleAnalytics> = [];
			client.channels.forEach(channel => {
				if (channel.analytics?.google) {
					googleAnalytics.push(...channel.analytics.google);
				}
			});
			setAnalytics(googleAnalytics);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client?.id, user.isLoadingAppUser]);

	useEffect(() => {
		if (client) {
			for (let i = 0; i < client.channels.length; i++) {
				const channel = client.channels[i];
				const paywallKey = `paywall-attendee-number-${channel.clientId}`;
				const attendeeNumber = localStorage.getItem(paywallKey);

				if (!!attendeeNumber) {
					setAttendeeNumber(attendeeNumber);
					db.collection("paywall_access")
						.doc(attendeeNumber)
						.onSnapshot(snap => {
							if (snap.exists) {
								const snapData = snap.data() as PaywallGlobalAccess;
								setAttendeeData(snapData.attendeeData);
							}
						})
					break;
				}
			}
		}
	}, [client])

	if (loading) {
		return null;
	}

	if (client) {
		const { channels, theme } = client;

		const sortedChannels = orderBy(channels, ["displayOrder"], "asc");

		return (
			<div className="content-wrapper client-page">
				<Helmet>
					<title>{`${client.name} | Brushfire Online`}</title>
					<meta property="og:title" content={`${client?.name}`} />
					<meta property="og:type" content="website" />
					<meta property="og:image" content={client.logoImage} />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:site_name" content={isChurchX ? "Church X" : "Brushfire Online"} />
				</Helmet>
				<Header client={client} authProviders={client.authProviders} />
				<ThemeProvider theme={client.theme}>
					<GlobalStyle />
					<div className="cards">
						<h1
							style={{
								fontSize: isMobileOnly ? "1.5rem" : "2.5rem",
								padding: isMobileOnly ? "20px 10px 5px 10px" : "10px 0px",
							}}
						>
							{client.name}
						</h1>
						<div className="card-list">
							{sortedChannels.map(channel => {
								const contentRoute = customDomainData
									? `/${channel.key}`
									: `/${clientKey}/${channel.key}`;
								let validMsg = "";

								if (attendeeData) {
									const orderRelativeDays = channel.brushfireEventIds?.find((bfEvt) => bfEvt.eventId === attendeeData?.EventId)?.attendeeTypes[attendeeData?.TypeId ?? ""]?.orderRelativeDays;
									let validUntil = "";
									if (!!orderRelativeDays && attendeeData?.OrderedAt) {
										validUntil = moment(attendeeData?.OrderedAt).add(orderRelativeDays, "days").local().format("LL");
									}

									if (validUntil) {
										validMsg = `Content available until ${validUntil}.`
									}
								}

								return checkAvailability(channel.showAt, channel.hideAt) === "available" ? (
									<>
										<a
											href={contentRoute}
											key={channel.id}
											className="card"
											style={{
												backgroundColor: theme.wellBackgroundColor,
												color: theme.wellTextColor,
											}}
										>
											<div
												className="card-image-mobile"
												style={{
													backgroundImage: `url('${channel.thumbImage ||
														"/content/img/channel-default@2x.png"}')`,
												}}
											/>
											<div className="card-info">
												<h2>{channel.name}</h2>
												<span>{validMsg}</span>
											</div>
										</a>
									</>
								) : null;
							})}
						</div>
					</div>
					<Footer appUser={user?.appUser} client={client} />
				</ThemeProvider>
			</div>
		);
	}

	history.push("/");
	return null;
};

export default Client;
