import React from "react";
import { Button, Col, Row } from "react-bootstrap";

interface IconButtonProps {
	className?: string;
	variant?:
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "dark"
		| "light"
		| "link"
		| "outline-primary"
		| "outline-secondary"
		| "outline-success"
		| "outline-danger"
		| "outline-warning"
		| "outline-info"
		| "outline-dark"
		| "outline-light";
	icon: string;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	iconWeight?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
	children,
	className,
	icon,
	iconWeight = "fas",
	onClick,
	variant,
}) => {
	return (
		<Button className={className} onClick={onClick} variant={variant}>
			<Row>
				<Col xs={2} className="ta-center">
					<i className={`${iconWeight} fa-${icon}`} />
				</Col>
				<Col xs={10} className="ta-center">
					{children}
				</Col>
			</Row>
		</Button>
	);
};
