import { darken, lighten } from "polished";
import styled, { StyledFunction } from "styled-components";

const ChatCornerTS: StyledFunction<any> = styled.div;

export const lightTheme = {
	backgroundColor: "#ffffff",
	color: "#25272b",
	borderColor: "#25272b",
};

export const darkTheme = {
	backgroundColor: "#25272b",
	color: "#ffffff",
	borderColor: "#ffffff",
};

export const style = {
	// Chat.tsx
	ChatWrapper: styled.div`
		display: flex;
		flex-direction: column;
		position: relative;
		height: 100%;
		transition: all ease-in-out 0.4s;
		&.has-pinned-chats {
			padding-top: 70px;
		}
	`,
	ChatContainer: styled.div`
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		color: ${({ theme }) => theme.wellTextColor};
		padding: 15px;
		padding-right: 7.5px;

		@media screen and (max-width: 600px) {
			padding: 0px 7.5px 0px 10px;
		}
	`,
	// ChatLogin.js
	ChatLoginBlock: styled.div`
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		padding-right: 5px;
		display: flex;
		height: 100%;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	`,
	ChatLoginInput: styled.input`
		color: ${({ theme }) => theme.wellTextColor};
	`,
	ChatLoginButton: styled.button`
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		color: ${({ theme }) => theme.wellTextColor};
		border-color: ${({ theme }) => theme.wellTextColor};
	`,
	// ChatInput.js
	ChatMsgBlock: styled.div`
		background-color: ${({ theme }) => theme.wellBackgroundColor};
		color: ${({ theme }) => theme.wellTextColor};
	`,
	ChatMsg: styled.input`
		background-color: ${({ theme }) => theme.wellTextColor};
		color: ${({ theme }) => theme.wellBackgroundColor};
	`,
	OldChatCorner: ChatCornerTS`
	position: absolute;
	width: 10px;
	height: 10px;
	display: inline-block;
	width: 0;
	height: 0;
	bottom: -15px;
	left: -15px;
	border-bottom: 10px solid ${({ theme, isLightBackground }) => {
		return isLightBackground ? darken(0.05, theme.wellBackgroundColor) : lighten(0.2, theme.wellBackgroundColor);
	}};
	border-right: 10px solid transparent;
	`,
};
