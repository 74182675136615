// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAjkO6YnChD7YMdsLirMFXIhZbeyPBxHpw",
	authDomain: "brushfire-online.firebaseapp.com",
	databaseURL: "https://brushfire-online.firebaseio.com",
	projectId: "brushfire-online",
	storageBucket: "brushfire-online.appspot.com",
	messagingSenderId: "197876363395",
	appId: "1:197876363395:web:975b592b93461b26526bb6",
	measurementId: "G-VNPTCT0KQX",
};

const fb = firebase.initializeApp(firebaseConfig);

fb.firestore().settings({ experimentalAutoDetectLongPolling: true });

export default fb;
