import firebase from "firebase";
import getMilliseconds from "../../utils/getMilliseconds";
import {
	ChatActionTypes,
	ChatState,
	DELETE_CHAT_MESSAGE,
	FLAG_CHAT_MESSAGE,
	HIDE_MODAL_ALERT,
	IDeleteChat,
	ILikeChat,
	ILimitMessages,
	IPinChat,
	ISetChat,
	IShowModalAlert,
	IUserStatusChanged,
	LIKE_CHAT_MESSAGE,
	PIN_CHAT_MESSAGE,
	SET_CHAT_DATA,
	SET_LIMIT_MESSAGES,
	SET_SCROLLED_UP,
	SHOW_MODAL_ALERT,
	USER_STATUS_CHANGED,
} from "./types";

const initialState: ChatState = {
	messages: {},
	chatId: undefined,
	latestMessage: undefined,
	limitMessages: true,
	newMessages: false,
	scrolledUp: false,
	userStatus: {},
	showModal: false,
	modalData: {
		title: "",
		message: "",
		closeButtonText: "Close",
	},
};

const messagesToKeepInMemory = 100;

export function chatReducer(state = initialState, action: ChatActionTypes): ChatState {
	switch (action.type) {
		case SET_CHAT_DATA:
			const setPayload = action.payload as ISetChat;
			if (setPayload.chatId === state.chatId) {
				const messages = setPayload.messages;
				Object.keys(messages).forEach(key => {
					if (messages[key].timestamp === undefined) {
						messages[key].timestamp = state.latestMessage
							? firebase.firestore.Timestamp.fromMillis(state.latestMessage.toMillis() + 1)
							: firebase.firestore.Timestamp.fromDate(new Date());
					}
					if (state.messages[key]) {
						messages[key].timestamp = state.messages[key].timestamp;
					} else if (
						setPayload.removeOldMessages &&
						getMilliseconds(messages[key].timestamp) !== getMilliseconds(messages[key].updatedAt)
					) {
						// Not a new message or existing message
						if (!messages[key].isPinned) {
							delete messages[key];
						}
					}
				});
				const newMessages = Object.assign({}, state.messages, { ...messages });
				const messageArray = Object.values(newMessages);
				messageArray.sort((a, b) => {
					const at = getMilliseconds(a.timestamp);
					const bt = getMilliseconds(b.timestamp);
					return at > bt ? -1 : at === bt ? 0 : 1;
				});
				if (state.limitMessages && messageArray.length > messagesToKeepInMemory) {
					for (let i = messagesToKeepInMemory; i < messageArray.length; i++) {
						if (!newMessages[messageArray[i].id].isPinned) {
							delete newMessages[messageArray[i].id];
						}
					}
				}
				let showNewMessages = state.newMessages;
				if (
					state.scrolledUp &&
					state.latestMessage &&
					getMilliseconds(messageArray[0].timestamp) > getMilliseconds(state.latestMessage)
				) {
					showNewMessages = true;
				}
				return Object.assign({}, state, {
					messages: newMessages,
					chatId: setPayload.chatId,
					latestMessage: setPayload.latestMessage || state.latestMessage,
					newMessages: showNewMessages,
				});
			} else {
				return Object.assign({}, state, {
					...setPayload,
				});
			}
		case LIKE_CHAT_MESSAGE:
			const likePayload = action.payload as ILikeChat;
			const likeMessages = Object.assign({}, state.messages);
			if (!likeMessages[likePayload.messageId].likes) {
				likeMessages[likePayload.messageId].likes = [];
			}
			if (likeMessages[likePayload.messageId].likes.includes(likePayload.uid)) {
				likeMessages[likePayload.messageId].likes = likeMessages[likePayload.messageId].likes.filter(
					x => x !== likePayload.uid
				);
			} else {
				likeMessages[likePayload.messageId].likes.push(likePayload.uid);
			}

			likeMessages[likePayload.messageId].likesCount = likeMessages[likePayload.messageId].likes.length;

			return Object.assign({}, state, {
				likeMessages,
			});
		case FLAG_CHAT_MESSAGE:
			const flagPayload = action.payload as ILikeChat;
			const flagMessages = Object.assign({}, state.messages);
			if (!flagMessages[flagPayload.messageId].likes) {
				flagMessages[flagPayload.messageId].likes = [];
			}
			if (flagMessages[flagPayload.messageId].flags.includes(flagPayload.uid)) {
				flagMessages[flagPayload.messageId].flags = flagMessages[flagPayload.messageId].flags.filter(
					x => x !== flagPayload.uid
				);
			} else {
				flagMessages[flagPayload.messageId].flags.push(flagPayload.uid);
			}

			return Object.assign({}, state, {
				messages: flagMessages,
			});
		case DELETE_CHAT_MESSAGE:
			const deletePayload = action.payload as IDeleteChat;
			const deleteMessages = Object.assign({}, state.messages);
			deleteMessages[deletePayload.messageId].isDeleted = true;

			return Object.assign({}, state, {
				messages: deleteMessages,
			});
		case PIN_CHAT_MESSAGE:
			const pinPayload = action.payload as IPinChat;
			let pinMessages = Object.assign({}, state.messages);
			Object.values(pinMessages)
				.filter(x => x.isPinned === true)
				.forEach(chat => {
					pinMessages[chat.id].isPinned = false;
					pinMessages[chat.id].isDeleted = true;
				});
			Object.values(pinMessages)
				.filter(x => x.dupePinnedChatId)
				.forEach(chat => {
					pinMessages[chat.id].dupePinnedChatId = null;
				});
			if (pinPayload.newDupeChatId) {
				pinMessages[pinPayload.newDupeChatId] = {
					...pinMessages[pinPayload.messageId],
					...{ isPinned: true, originalId: pinPayload.messageId, id: pinPayload.newDupeChatId },
				};
				pinMessages[pinPayload.messageId].dupePinnedChatId = pinPayload.newDupeChatId;
			}
			return Object.assign({}, state, {
				messages: pinMessages,
			});
		case SET_LIMIT_MESSAGES:
			const limitPayload = action.payload as ILimitMessages;
			return Object.assign({}, state, {
				limitMessages: limitPayload.limitMessages,
				newMessages: limitPayload.limitMessages ? false : state.newMessages,
			});
		case SET_SCROLLED_UP:
			const scrolledUp = action.payload as boolean;
			return Object.assign({}, state, {
				scrolledUp,
				newMessages: !scrolledUp ? false : state.newMessages,
			});
		case USER_STATUS_CHANGED:
			const userStatus = action.payload as IUserStatusChanged;
			return Object.assign({}, state, {
				userStatus: Object.assign({}, state.userStatus, { [userStatus.uid]: userStatus.status }),
			});
		case SHOW_MODAL_ALERT:
			const { modalData, showModal } = action.payload as IShowModalAlert;
			return Object.assign({}, state, {
				showModal,
				modalData,
			});
		case HIDE_MODAL_ALERT:
			const clearModalData = action.payload;
			return Object.assign({}, state, {
				showModal: false,
				modalData: clearModalData
					? {
							showModal,
							modalData,
					  }
					: state.modalData,
			});
		default:
			return state;
	}
}
