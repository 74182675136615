import { useState, useMemo } from "react";
import { CustomPanel, Panel } from "../interfaces";

export const useCustomPanel = () => {
	const [customPanel, setCustomPanel] = useState<Panel | null>(null);
	const [shouldOpen, setShouldOpen] = useState<boolean>(false);

	return useMemo(
		(): CustomPanel => ({
			panel: customPanel,
			open: (panel: Panel) => {
				if (panel.key !== customPanel?.key) {
					setCustomPanel(panel);
				}
				setShouldOpen(true);
			},
			close: () => {
				setCustomPanel(null);
			},
			shouldOpen: shouldOpen,
			resetShouldOpen: () => {
				setShouldOpen(false);
			},
		}),
		[customPanel, shouldOpen]
	);
};
