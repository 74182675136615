import styled from "styled-components";

const ReactionInput = styled.input`
	&:checked + .slider {
		background-color: #004bed;
	}

	&:focus + .slider {
		box-shadow: 0 0 1px #ffffff;
	}

	&:checked + .slider:before {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	}
`;

export { ReactionInput };
