import { createLogic } from "redux-logic";
import { AUTH_STATUS_CHANGED, AUTH_STATUS_CHANGED_ACTION } from "../types";
import { loginSucess } from "../actions";

const authStatusChangedLogic = createLogic({
	type: AUTH_STATUS_CHANGED,
	// eslint-disable-next-line no-unused-vars
	process({ getState, action }, dispatch, done) {
		let data = action as AUTH_STATUS_CHANGED_ACTION;
		if (data.payload.user) {
			dispatch(loginSucess(data.payload.user));
		}
		done();
	},
});

export default [authStatusChangedLogic];
