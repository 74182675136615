import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Content } from "../../interfaces";
import { FormikContextType } from "formik";
import Video from "./Video";
import { isEmpty } from "lodash";

interface VideoPreviewProps {
	content: Content;
	formikContext?: FormikContextType<Content>;
	previewShouldUpdate?: boolean;
}

const VideoWrapper = styled.div`
	display: block;
	position: relative;
	overflow: hidden;
	margin: auto;
	margin-bottom: 0.5em;
	&.video-preview {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5em;
	}
	.aspect-ratio {
		padding-top: 56.25%;
	}
	.hide {
		display: none;
	}
`;

const Placeholder = styled.div`
	border: 1px solid;
	border-radius: 0.5em;
	position: absolute;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	width: 100%;
	.placeholder-text {
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.fa-play {
		font-size: 3em;
		@media (max-width: 768px) {
			font-size: 2em;
		}
	}
`;

export const VideoPreview: React.FC<VideoPreviewProps> = ({ content, formikContext, previewShouldUpdate }) => {
	const [showPlaceholder, setShowPlaceholder] = useState(true);
	const [placeholderIsError, setPlaceholderIsError] = useState(false);
	const [updatedContent, setUpdatedContent] = useState<Content>(content);
	useEffect(() => {
		if (formikContext?.values.src) {
			if (!isEmpty(formikContext?.errors.src)) {
				setPlaceholderIsError(true);
				setShowPlaceholder(true);
			} else {
				setShowPlaceholder(false);
			}
		}
	}, [formikContext?.values.src, formikContext?.errors.src]);

	useEffect(() => {
		if (formikContext?.values.src) {
			setShowPlaceholder(false);
		}
	}, []);

	useEffect(() => {
		if (previewShouldUpdate) {
			setUpdatedContent(content);
		}
	}, [previewShouldUpdate]);

	const updatePlaceholderStatus = (shouldShow: boolean) => {
		setShowPlaceholder(shouldShow);
		setPlaceholderIsError(true);
	};

	return (
		<VideoWrapper>
			{showPlaceholder && (
				<div className="aspect-ratio">
					<Placeholder className={`placeholder ${placeholderIsError ? " error" : ""}`}>
						<div className="placeholder-text">
							{showPlaceholder && <i className="fas fa-play"></i>}
							{placeholderIsError && <h3>Invalid Video</h3>}
						</div>
					</Placeholder>
				</div>
			)}
			<div className={`video-preview-wrapper ${showPlaceholder ? "hide" : ""}`}>
				<Video
					content={updatedContent}
					formikContext={formikContext}
					isPreview
					updatePlaceholderStatus={updatePlaceholderStatus}
				/>
			</div>
		</VideoWrapper>
	);
};
