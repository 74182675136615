import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormikContextType } from "formik";

import { PageTitle, Well } from "../../";
import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";
import Form from "../../../../../components/Form";

import * as Styled from "../ContentFormSections.styled";

export interface IntegrationsSectionProps {
	isEnabled: boolean;
	formikContext: FormikContextType<ContentFormContent>;
}

const providers = [
	{ value: "zendesk", label: "Zendesk" },
	{ value: "helpscout", label: "HelpScout" },
];

const Integrations: React.FC<IntegrationsSectionProps> = ({ isEnabled, formikContext }) => {
	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>Chat Widget</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="chatWidget.enabled"
					name="chatWidget.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			{isEnabled ? (
				<Well
					message="Embed a chat widget to provide technical support or help docs via third party support tools."
					learnMoreLink="https://get.brushfire.help/hc/en-us/articles/360015875199"
				>
					<>
						<Form.Field
							component={Form.Input.Select}
							label="Provider *"
							id="chatWidget.provider"
							name="chatWidget.provider"
							data={providers}
							isSearchable={false}
						/>
						<Form.Field label="Key *" id="chatWidget.embedId" name="chatWidget.embedId" />
					</>
				</Well>
			) : (
				<Well>
					<p>
						Embed a chat widget to provide technical support or help docs via third party support tools.{" "}
						<span>
							<a
								href="https://get.brushfire.help/hc/en-us/articles/360015875199"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <i className="fal fa-long-arrow-right" />
							</a>
						</span>
					</p>
				</Well>
			)}
		</>
	);
};

export default Integrations;
