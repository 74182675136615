import React from "react";
import { PageTitle, Well } from "../..";
import Form from "../../../../../components/Form";
import { Panels } from "../../../../../interfaces";

import * as Styled from "../ContentFormSections.styled";

const DefaultPanelSection: React.FC = () => {
	const panels = [
		{ value: Panels.chat, label: "Chat" },
		{ value: Panels.bible, label: "Bible" },
		{ value: Panels.notes, label: "Notes" },
		{ value: Panels.schedule, label: "Schedule" },
		{ value: Panels.info, label: "Info" },
	];

	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>Default Panel</PageTitle>
			</Styled.SectionHeader>
			<Well>
				<Form.Field
					component={Form.Input.Select}
					label="Default Panel"
					id={"initialSelectedPanel"}
					name={"initialSelectedPanel"}
					data={panels}
					isSearchable={false}
				/>
			</Well>
		</>
	);
};

export default DefaultPanelSection;
