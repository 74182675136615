import styled from "styled-components";

const SwitchMainWrapper = styled.label`
	display: flex;
	align-items: center;
`;

const SwitchWrapper = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 26px;
	margin: 0;

	> input {
		opacity: 0;
		width: 0;
		height: 0;
	}
`;

const SwitchSlider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(204, 204, 204, 0.6);

	-webkit-transition: all 0.25s;
	transition: all 0.25s;

	&:before {
		position: absolute;
		content: "";
		height: 18px;
		width: 18px;
		left: 4px;
		bottom: 4px;
		background-color: #ffffff;
		-webkit-transition: all 0.25s;
		transition: all 0.25s;
	}

	&.round {
		border-radius: 34px;
	}

	&.round:before {
		border-radius: 50%;
	}
`;

const SwitchInput = styled.input`
	&:checked + .slider {
		background-color: #004bed;
	}

	&:focus + .slider {
		box-shadow: 0 0 1px #ffffff;
	}

	&:checked + .slider:before {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	}
`;

const SwitchLabel = styled.span`
	font-size: 24px;
	margin-left: 8px;
`;

export { SwitchMainWrapper, SwitchWrapper, SwitchInput, SwitchSlider, SwitchLabel };
