import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactMomentCountdown from "react-moment-countdown";
import firebaseApp from "../../firebase";
import { Content } from "../../interfaces";
import {
	getBrowserTime,
	getCurrentTime,
	isHappeningNow,
	isOpenButNotStarted,
	isScheduledForFuture,
} from "../../pages/Channel/utils";
import * as Styled from "./LiveBadge.styled";

interface LiveBadgeProps {
	contentData: Content;
	showLiveNumber?: boolean;
	invertable?: boolean;
	onVideoEnd?: () => void;
	setAsLive?: () => void;
	isFrontEnd?: boolean;
}

const LiveBadge: React.FC<LiveBadgeProps> = ({
	contentData,
	invertable = false,
	onVideoEnd,
	setAsLive,
	showLiveNumber = false,
	isFrontEnd = false,
}) => {
	const [endUpdate, setEndUpdate] = useState(0);
	const [tickUpdate, setTickUpdate] = useState(0);
	const [time, setTime] = useState(moment().toISOString());
	const [currentLive, setCurrentLive] = useState(0);
	const [toggleCountdown, setToggleCountdown] = useState(true);
	const [hideTimer, setHideTimer] = useState(false);

	useEffect(() => {
		const getTime = async () => {
			const time = await getCurrentTime();
			if (time) {
				const browserMoment = getBrowserTime();
				setTime(browserMoment.toISOString());
			}
		};
		getTime();
	}, []);

	useEffect(() => {
		setHideTimer(true);
		setTimeout(() => {
			setHideTimer(false);
		}, 50);
	}, [setAsLive]);

	useEffect(() => {
		const browserMoment = getBrowserTime();
		setTime(browserMoment.toISOString());
	}, [tickUpdate]);

	useEffect(() => {
		const browserMoment = getBrowserTime();
		setTime(browserMoment.toISOString());
	}, [endUpdate]);

	useEffect(() => {
		if (showLiveNumber) {
			const db = firebaseApp.firestore();
			const unsubscribe = db
				.collection("analytics")
				.doc("contents")
				.collection("content-analytics-live-numbers")
				.doc(`clive_${contentData.id}`)
				.onSnapshot(async snapshot => {
					const contentAnalytics = snapshot.data();
					setCurrentLive(contentAnalytics?.currentLive || 0);
				});

			return () => unsubscribe();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showLiveNumber]);

	const unitOfTimeChanged = (msUntilOver: number) => {
		const previousMsUntilOver = msUntilOver + 1000;

		const currentDuration = moment.duration(msUntilOver);
		const previousDuration = moment.duration(previousMsUntilOver);

		const currentWeeks = Math.floor(currentDuration.asWeeks());
		const currentDays = Math.floor(currentDuration.asDays());
		const currentHours = Math.floor(currentDuration.asHours());
		const currentMinutes = Math.floor(currentDuration.asMinutes());

		const previousWeeks = Math.floor(previousDuration.asWeeks());
		const previousDays = Math.floor(previousDuration.asDays());
		const previousHours = Math.floor(previousDuration.asHours());
		const previousMinutes = Math.floor(previousDuration.asMinutes());

		if (currentWeeks !== previousWeeks || currentDays !== previousDays || currentHours !== previousHours) {
			return true;
		} else {
			return false;
		}
	};

	const now = moment(time);

	const isOpen = isHappeningNow(now, contentData);
	const isInFuture = isScheduledForFuture(now, contentData);
	const hasntStartedYet = isOpenButNotStarted(now, contentData);

	const showLive = isOpen && !hasntStartedYet;
	const showCountdown = isInFuture || (isOpen && hasntStartedYet);

	const showLiveCount = showLiveNumber && currentLive >= 1;

	let countdownMask = "";
	if (showCountdown) {
		const diff = moment(contentData.startsAt).diff(now);
		const duration = moment.duration(diff);

		const weeks = Math.round(duration.asWeeks());
		const days = Math.round(duration.asDays());
		const hours = Math.round(duration.asHours());

		if (weeks > 0) {
			countdownMask = `[Starts in ]${weeks}[w]`;
		} else if (days > 0) {
			countdownMask = `[Starts in ]${days}[d]`;
		} else if (hours > 0) {
			countdownMask = `[Starts in ]H[h]m[m]`;
		} else {
			countdownMask = "[Starts in ] mm:ss";
		}
	}

	return (
		<Styled.LiveContainer>
			{showLive && (
				<>
					<div style={{ display: "none" }}>
						<ReactMomentCountdown
							key={moment(contentData.closesAt).toISOString()}
							toDate={moment(contentData.closesAt)}
							onCountdownEnd={() => {
								onVideoEnd && onVideoEnd();
								setTickUpdate(v => {
									return v + 1;
								});
							}}
						/>
					</div>
					<Styled.LiveLabel key={moment(contentData.startsAt).toISOString()}>
						<Styled.Live showLiveCount={showLiveCount}>Live</Styled.Live>
						{showLiveCount && (
							<Styled.LiveCount>
								<i className="fas fa-eye" /> <span>{currentLive}</span>
							</Styled.LiveCount>
						)}
					</Styled.LiveLabel>
				</>
			)}
			{showCountdown && toggleCountdown && !hideTimer && (
				<Styled.CountdownLabel
					className={`${invertable ? "invertable" : ""} ${
						contentData.disableCountdown && isFrontEnd ? "hidden" : ""
					}`}
				>
					<Styled.CountdownText
						key={moment(contentData.startsAt).toISOString()}
						toDate={moment(contentData.startsAt)}
						targetFormatMask={countdownMask}
						onTick={(countdown: number) => {
							const browserTime = getBrowserTime();
							if (unitOfTimeChanged(countdown)) {
								setTickUpdate(v => {
									return v + 1;
								});
								setToggleCountdown(false);
								setTimeout(() => {
									setToggleCountdown(true);
								}, 0);
							}
							if (
								browserTime.isAfter(moment.utc(contentData.opensAt)) &&
								browserTime.isBefore(moment.utc(contentData.startsAt))
							) {
								setTickUpdate(v => {
									return v + 1;
								});
							}
						}}
						onCountdownEnd={() => {
							setEndUpdate(v => {
								return v + 1;
							});
							if (setAsLive) {
								setAsLive();
							}
						}}
						className={`count-down-timer`}
					/>
				</Styled.CountdownLabel>
			)}
		</Styled.LiveContainer>
	);
};

export default LiveBadge;
