import React from "react";
import { FieldProps } from "formik";
import { ReactionInput } from "./ReactionPicker.styled";
import { ReactionIcon } from "../../../../interfaces";

export interface ReactionPickerProps {
	defaultChecked?: boolean;
	field?: FieldProps["field"];
	form?: FieldProps["form"];
	id: string;
	label: string | React.ReactNode;
	name?: string;
	value?: boolean;
	onChecked?: (e: any) => void;
	onUnchecked?: (e: any) => void;
	className?: string;
	labelClassName?: string;
	icon: ReactionIcon;
}

const ReactionPicker: React.FC<ReactionPickerProps> = ({
	defaultChecked,
	field,
	form,
	id,
	label,
	value = false,
	onChecked,
	onUnchecked,
	labelClassName,
	icon,
	...props
}) => {
	const checkboxChange = (e: any) => {
		if (form && field) {
			form.setFieldValue(field.name, !field.value);
		}
		const { checked } = e.target;
		if (checked && onChecked) {
			onChecked(e);
		} else if (!checked && onUnchecked) {
			onUnchecked(e);
		}
	};

	return (
		<label
			className={`reaction-picker reaction-picker -container ${labelClassName ? labelClassName : ""} ${
				field && field.value ? "active" : ""
			}`}
			htmlFor={id}
		>
			<ReactionInput
				type="checkbox"
				defaultChecked={defaultChecked}
				id={id}
				{...field}
				name={field && field.name}
				onChange={checkboxChange}
				checked={(field && field.value) || value}
				{...props}
			/>
			<img src={`/content/img/reactions/bfo-${ReactionIcon[icon]}.svg`} />
		</label>
	);
};

ReactionPicker.displayName = "ReactionPicker";

export default ReactionPicker;
