import authStatusChangedLogic from "./authStatusChangedLogic";
import loginSuccessLogic from "./loginSuccessLogic";
import logoutUserLogic from "./logoutUserLogic";
import trackAuthStatusLogic from "./trackAuthStatusLogic";
import frontEndLoginSuccessLogic from "./frontEndLoginSuccessLogic";

export default [
	...authStatusChangedLogic,
	...loginSuccessLogic,
	...logoutUserLogic,
	...trackAuthStatusLogic,
	...frontEndLoginSuccessLogic,
];
