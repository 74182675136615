import React from "react";
import PropTypes from "prop-types";
import { emit, CHAT_SENDMESSAGE } from "./Chat.service";
import styled, { ThemeConsumer } from "styled-components";
import firebase from "../../firebase";
import isLightOrDark from "../../utils/isLightOrDark";
import ChatAvatar from "./ChatAvatar";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { darken, lighten } from "polished";
import { IconButton } from "../Buttons/IconButton";
import { showLoginModal, showLogoutModal } from "../../redux/User/actions";
import { activateChatRequest } from "../../services/dataHelpers";

function fadedColorFromHex(hex) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.7)";
	}
	throw new Error("Bad Hex");
}

const style = {
	ChatMsgBlock: styled.div`
		width: 100%;
		height: ${props => props.inputHeight + 30}px;
		background-color: ${({ theme }) => theme.wellBackgroundColor};

		border-top: solid 2px
			${({ theme, isHostManager }) => {
				return isHostManager
					? "#2d2f32"
					: isLightOrDark(theme.wellBackgroundColor) === "light"
					? darken(0.05, theme.wellBackgroundColor)
					: lighten(0.2, theme.wellBackgroundColor);
			}};
		padding-top: 15px;

		.chat-image {
			margin-top: 3px;
		}

		@media screen and (max-width: 600px) {
			padding-top: 3px;
			height: none;
		}
	`,
	ChatMsg: styled.textarea`
		pointer-events: all;
		resize: none;
		-webkit-appearance: none;
		color: ${({ theme, isHostManager }) => {
			return isHostManager ? "#FFFFFF" : theme.wellBackgroundColor;
		}};
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0px;
		background: ${({ theme, isHostManager }) => {
			return isHostManager ? "#4a525a" : theme.wellTextColor;
		}};
		border: none;
		border-radius: 12px;
		display: inline-block;
		position: absolute;
		width: calc(100% - 65px);
		padding: 5px;
		right: 15px;
		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${({ theme, isHostManager }) =>
				fadedColorFromHex(isHostManager ? "#FFFFFF" : theme.wellBackgroundColor)};
			opacity: 1; /* Firefox */
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: ${({ theme, isHostManager }) =>
				fadedColorFromHex(isHostManager ? "#FFFFFF" : theme.wellBackgroundColor)};
		}

		::-ms-input-placeholder {
			/* Microsoft Edge */
			color: ${({ theme, isHostManager }) =>
				fadedColorFromHex(isHostManager ? "#FFFFFF" : theme.wellBackgroundColor)};
		}
		@media screen and (max-width: 992px) {
			padding-right: 24px;
		}
	`,
	ChatSend: styled.button`
		-webkit-appearance: none;
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;
		@media screen and (max-width: 992px) {
			width: 18px;
			height: 18px;
			background-color: #20bf55;
			color: #ffffff;
			opacity: 1;
			z-index: 9;
			position: absolute;
			right: 18px;
			outline: none;
			border: 0;
			border-radius: 20px;
			bottom: -21px;
			display: flex;
			justify-items: center;
			align-items: center;
			pointer-events: all;
			padding: 0;
			margin: 0;
			i {
				font-size: 10px;
				line-height: 18px;
				width: 18px;
			}
		}
	`,
	ChatLogoutButton: styled(Tooltip)`
		&.show {
			opacity: 1;
		}
		div {
			color: ${({ theme }) => theme.wellBackgroundColor};
			border-bottom: solid 1px ${({ theme }) => theme.wellBackgroundColor};
			padding: 5px;
		}
		.tooltip-inner {
			background-color: ${({ theme }) => theme.wellTextColor};
		}
		.btn {
			background: none;
			border: none;
			color: ${({ theme }) => theme.wellBackgroundColor};
			:hover {
				background: none;
				border: none;
				color: ${({ theme }) => theme.wellBackgroundColor};
			}
		}
		.arrow {
			display: none;
		}
	`,
	ChatInputWrapper: styled.div`
		padding-right: 15px;
		position: relative;
		.chat-input-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			p {
				width: 100%;
				margin: 0;
				font-size: 12px;
				font-weight: 600;
				color: ${({ theme }) => theme.wellTextColor};
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0;
				margin-left: 5px;
			}

			div {
				width: 40%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				color: ${({ theme }) => theme.wellTextColor};
				font-size: 10px;
				font-weight: 400;
				:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	`,
};

export default class ChatInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chatId: props.chatId,
			isModerator: props.isModerator,
			userName: props.userName,
			uid: props.uid,
			photoURL: props.photoURL,
			user: props.user,
			showModal: false,
			showEndModal: false,
			request: props.request,
		};
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onClick = this.onClick.bind(this);
		this.setupMsgRef = this.setupMsgRef.bind(this);
	}

	componentDidMount() {
		if (this.msgRef) {
			this.msgRef.focus();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.chatId !== this.state.chatId) {
			this.setState({ chatId: this.props.chatId });
		}
	}

	onKeyUp(event) {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			this.onClick();
		} else {
			let height = Math.ceil((event.target.scrollHeight - 10) / 14) * 14 + 10;
			if (event.key === "Enter" && event.shiftKey) {
				height += 14;
			}
			if (height <= 94 && height !== this.props.inputHeight) {
				this.props.setInputHeight(height > 94 ? 94 : height);
			}
		}
	}

	handleShowModal = () => {
		this.setState({
			showModal: true,
		});
	};

	handleCloseModal = () => {
		this.setState({
			showModal: false,
		});
	};

	handleShowEndModal = () => {
		this.setState({
			showEndModal: true,
		});
	};

	handleCloseEndModal = () => {
		this.setState({
			showEndModal: false,
		});
	};

	end = () => {
		this.setState({ showEndModal: false }, () => {
			this.sendLeavingMessage();
			setTimeout(() => {
				this.props.endChat();
			}, 5000);
		});
	};

	onClick() {
		if (this.msgRef.value !== "" && this.msgRef.value.length > 0) {
			// send message
			const chatId = this.props.chatId;
			const userName = this.props.userName || this.props.user?.displayName;
			const uid = this.props.user?.uid;
			const photoURL = this.props.user?.photoURL;
			const message = this.msgRef.value;
			const user = this.props.user;
			// clear input
			this.msgRef.value = "";
			// send message
			emit(
				CHAT_SENDMESSAGE,
				{
					chatId,
					uid,
					userName,
					message,
					photoURL,
					email: user?.email ? user.email : this.props.email,
					isHost: this.props.isModerator,
					useChatProcessor: this.props.useChatProcessor,
					request: this.props.request,
					contentId: this.props.contentId,
				},
				this.props.chatId
			);
			if (this.props.request?.showRequest === false) {
				activateChatRequest(this.props.contentId, chatId, this.props.isModerator);
			}
			this.props.setInputHeight(24);
			this.msgRef.focus();
		}
	}

	sendLeavingMessage = () => {
		// send message
		const chatId = this.props.chatId;
		const userName = this.props.userName || this.props.user?.displayName;
		const uid = this.props.user?.uid;
		const photoURL = this.props.user?.photoURL;
		const message = `***** ${userName} Closed The Chat *****`;
		const user = this.props.user;
		this.msgRef.value = "";
		// send message
		emit(
			CHAT_SENDMESSAGE,
			{
				chatId,
				uid,
				userName,
				message,
				photoURL,
				email: user?.email ? user.email : this.props.email,
				isHost: this.props.isModerator,
				useChatProcessor: this.props.useChatProcessor,
				request: this.props.request,
				contentId: this.props.contentId,
			},
			this.props.chatId
		);
		this.msgRef.focus();
	};

	renderInput = () => {
		const { request } = this.props;
		const userIsBanned = this.props.bannedUsers.includes(this.props.user?.uid);
		const recipientUid = request?.users.find(x => x !== this.props.uid) ?? "";
		const isOnline = request && this.props.userStatus ? this.props.userStatus[recipientUid ?? ""] !== false : true;
		return (
			<style.ChatInputWrapper>
				<div className="chat-input-header">
					<p>{this.props.userName}</p>
				</div>
				<div className="relative">
					<style.ChatMsg
						type="text"
						placeholder={`${
							this.props.chatClosed || userIsBanned
								? "Chat is not available at this time"
								: isOnline
								? "Send message"
								: `${request.userData[recipientUid]?.name} is offline`
						}`}
						ref={this.setupMsgRef}
						onKeyPress={this.onKeyUp}
						disabled={this.props.chatClosed || userIsBanned}
						isHostManager={this.props.isHostManager}
						style={{ height: this.props.inputHeight }}
						className={isOnline ? "online" : ""}
						inputmode="search"
					/>
					<style.ChatSend onClick={this.onClick} style={{ bottom: `${this.props.inputHeight * -1 + 3}px` }}>
						<i className="fas fa-arrow-up"></i>
					</style.ChatSend>
				</div>
			</style.ChatInputWrapper>
		);
	};
	startChat = () => {
		if (this.props.startChat) {
			this.props.startChat(this.props.request);
		}
	};

	render() {
		const { showModal, showEndModal, request } = this.state;
		const displayName = this.props.user?.displayName;

		if (!displayName && this.props.enablePreview && !this.props.isHostManager) {
			return (
				<IconButton
					variant={"dark"}
					className="btn-block"
					icon={"sign-in-alt"}
					iconWeight="fas"
					onClick={() => {
						this.props.dispatch(showLoginModal(true));
					}}
				>
					Join The Conversation
				</IconButton>
			);
		}
		if (
			request &&
			(request.status === 0 ||
				(!!request.userData[this.props.user?.uid || ""] &&
					request.userData[this.props.user?.uid || ""].status === 2)) &&
			this.props.isHostManager
		) {
			return (
				<style.ChatMsgBlock isHostManager={this.props.isHostManager}>
					<div className="btn btn-primary chat-accept btn-block btn-sm" onClick={this.startChat}>
						{!!request.userData[this.props.user?.uid || ""] &&
						request.userData[this.props.user?.uid || ""].status === 2
							? "Reopen"
							: "Accept"}
					</div>
				</style.ChatMsgBlock>
			);
		}
		return (
			<>
				<style.ChatMsgBlock inputHeight={this.props.inputHeight} isHostManager={this.props.isHostManager}>
					<div className="chat-image">
						<Button variant="link" style={{ padding: 0, border: "none" }}>
							<ChatAvatar
								uid={this.props.user?.uid}
								userName={this.props.userName}
								photoURL={
									this.props.user?.photoURL
										? `https://media.online.brushfire.com/profile_images/${this.props.user?.uid}/profile.jpg`
										: null
								}
							/>
						</Button>
					</div>
					{this.renderInput()}
				</style.ChatMsgBlock>
				<Modal show={showEndModal} onHide={this.handleCloseEndModal} className="default-modal">
					<Modal.Header closeButton>
						<Modal.Title>End Chat?</Modal.Title>
					</Modal.Header>
					<Modal.Body>Are you sure you want to end the chat?</Modal.Body>

					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={this.handleCloseEndModal}
							className="modal-button cancel-button"
						>
							Cancel
						</Button>
						<Button color="#e71d36" onClick={this.end} className="modal-button confirm-button">
							End
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	setupMsgRef(element) {
		this.msgRef = element;
	}
}

ChatInput.propTypes = {
	chatId: PropTypes.string.isRequired,
	isModerator: PropTypes.bool.isRequired,
	userName: PropTypes.string.isRequired,
	uid: PropTypes.string.isRequired,
};
