import React, { useState, useEffect } from "react";
import { FieldProps } from "formik";
//@ts-ignore
import FileUploader from "react-firebase-file-uploader";
import fb from "../../../../firebase";
import api from "../../../../services/api";
import addOrReplaceProfileImg from "../../../Chat/services/addOrReplaceProfileImg";

const MAX_FILE_SIZE = 5242880; // 5MB

export interface ProfileProps {
	field: FieldProps["field"];
	form: FieldProps["form"];
	id: string;
	ref?: React.RefObject<any>;
	[key: string]: any;
	helperText?: string;
	error?: boolean;
	isSaving?: boolean;
	square?: boolean;
}

export interface FileData {
	fileName: string;
	url: string;
	cdnUrl: string;
	uses: number;
	createdAt: number;
}

export interface FileRecords {
	data: FileData[];
}

interface ImageSize {
	width: number;
	height: number;
}

const UploadInput: React.FC<ProfileProps> = ({ field, form, helperText, error, isSaving, square, ...props }) => {
	const { value } = field;

	const [progress, setProgress] = useState<number>(0);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [localError, setLocalError] = useState<string | null>(null);

	useEffect(() => {
		if ((form.isSubmitting || isSaving) && !!localError) {
			setLocalError(null);
		}
	}, [form.isSubmitting, localError, isSaving])

	let displayName = value?.split("/")[value?.split("/").length - 1].split("?")[0];

	const auth = fb.auth();
	const userId = auth.currentUser?.uid;

	if (!userId) {
		return null;
	}

	const handleUploadStart = async (file: File, task: firebase.storage.UploadTask) => {
		setLocalError(null);
		if (file.size > MAX_FILE_SIZE) {
			task.cancel();
			setLocalError("File is too big. Max file size is 5MB.")
		} else if (square && !(await isSquare(file))) {
			task.cancel();
			setLocalError("File has to be square");
		} else {
			setIsUploading(true);
			setProgress(0);
		}
	};

	const isSquare = async (file: File) => {
		const {width, height}: ImageSize = await getImageSize(file);
		return (width - height) === 0;
	}

	const getImageSize = (file: File): Promise<ImageSize> => new Promise(resolve => {
		const img = new Image;
		const url = URL.createObjectURL(file);
		img.onload = () => {
			resolve({width: img.width, height: img.height});
		}
		img.src = url;
	});

	const handleProgress = (currentProgress: number) => {
		setProgress(currentProgress);
	};

	const handleUploadError = (error: string) => {
		setIsUploading(false);
		if (props.uploadError) {
			props.uploadError(error);
		}
	};

	const handleUploadSuccess = (fileName: string) => {
		setProgress(100);
		setIsUploading(false);
		fb.storage()
			.ref(userId)
			.child(fileName)
			.getDownloadURL()
			.then(async (url) => {
				const timestamp = new Date().getTime();
				let cdnUrl = `https://media.online.brushfire.com/profile_images/${userId}/profile.jpg?ts=${timestamp}`;
				try {
					await setPhotoUrl(url, timestamp);
				} catch {
					console.warn("File not added to Firebase records:", error);
				}
				form.setFieldValue(field.name, cdnUrl);
				form.validateForm({ ...form.values, [field.name]: cdnUrl });
				setTimeout(() => {
					setProgress(0);
				}, 2000);
			});
	};

	const setPhotoUrl = (image: string, timestamp: number) => {
		return new Promise(async resolve => {
			const imgSrc = await addOrReplaceProfileImg(auth, image);
			if (imgSrc && userId) {
				const img = document.createElement("img");
				img.onload = async () => {
					await api.setUserImageTimestamps(userId, timestamp);
					resolve(null);
				};
				img.src = `${imgSrc}?ts=${timestamp}`;
			}
		});
	};

	let imagePreviewStyle = {};

	if (value) {
		imagePreviewStyle = { backgroundImage: `url('${value.replace(/\(/g, "%28").replace(/\)/g, "%29")}')` };
	}

	return (
		<div className="relative">
			<label className="upload-label">
				<div className={`input-wrap upload-wrap ${error ? "upload-errors" : ""}`}>
					<div className="row m-0">
						<div className="col-sm-3 p-0">
							<div
								className={`upload-image-preview m-right ${value ? "on" : ""}`}
								style={imagePreviewStyle}
							>
								<div className="upload-icon">
									<i className="fal fa-file-upload"></i>
								</div>
								<div
									className="image-remove"
									onClick={event => {
										event.preventDefault();
										event.stopPropagation();
										form.setFieldValue(field.name, null);
									}}
								>
									<i className="fal fa-times-circle"></i>
								</div>
							</div>
						</div>
						<div className="col-sm-9 p-0">
							<div className={`btn btn-primary btn-block btn-sm ${isUploading ? "disabled" : ""}`}>
								Upload
							</div>
						</div>
					</div>
					<FileUploader
						hidden
						accept="image/*"
						name={field.name}
						filename={"profile.jpg"}
						storageRef={fb.storage().ref(userId)}
						onUploadStart={handleUploadStart}
						onUploadError={handleUploadError}
						onUploadSuccess={handleUploadSuccess}
						onProgress={handleProgress}
						onBlur={field.onBlur}
						{...props}
					/>
					<div className={`progress ${progress !== 0 ? "active" : ""}`}>
						<div className="progress-bar" style={{ width: `${progress}%` }}></div>
					</div>
				</div>
			</label>

			<small>{helperText ? helperText : displayName}</small>

			{localError && <div className="error">{localError}</div>}
		</div>
	);
};

export default UploadInput;
