import React from "react";
import { FieldProps } from "formik";
import { SwitchMainWrapper, SwitchWrapper, SwitchSlider, SwitchInput, SwitchLabel } from "./CheckboxToggle.styled";

export interface CheckboxProps {
	defaultChecked?: boolean;
	field?: FieldProps["field"];
	form?: FieldProps["form"];
	id: string;
	label: string | React.ReactNode;
	name?: string;
	value?: boolean;
	onChecked?: (e: any) => void;
	onUnchecked?: (e: any) => void;
	className?: string;
	labelClassName?: string;
	switchToggle?: boolean;
	disabled?: boolean;
}

const CheckboxInput: React.FC<CheckboxProps> = ({
	defaultChecked,
	field,
	form,
	id,
	label,
	value = false,
	onChecked,
	onUnchecked,
	labelClassName,
	switchToggle = false,
	disabled,
	...props
}) => {
	const checkboxChange = (e: any) => {
		if (disabled) return;
		if (form && field) {
			form.setFieldValue(field.name, !field.value);
		}
		const { checked } = e.target;
		if (checked && onChecked) {
			onChecked(e);
		} else if (!checked && onUnchecked) {
			onUnchecked(e);
		}
	};

	const checkboxInput = (
		<input
			type="checkbox"
			defaultChecked={defaultChecked}
			id={id}
			{...field}
			name={field && field.name}
			onChange={checkboxChange}
			checked={(field && field.value) || value}
			{...props}
		/>
	);

	const switchToggleInput = (
		<SwitchMainWrapper>
			<SwitchWrapper>
				<SwitchInput
					type="checkbox"
					defaultChecked={defaultChecked}
					id={id}
					{...field}
					name={field && field.name}
					onChange={checkboxChange}
					checked={(field && field.value) || value}
					{...props}
				/>
				<SwitchSlider className="slider round" />
			</SwitchWrapper>
			<SwitchLabel>{label}</SwitchLabel>
		</SwitchMainWrapper>
	);

	return (
		<label
			className={`checkbox checkbox-container ${labelClassName ? labelClassName : ""} ${
				field && field.value ? "active" : ""
			} ${disabled ? "disabled" : ""}`}
			htmlFor={id}
		>
			{!switchToggle && label}
			{switchToggle ? switchToggleInput : checkboxInput}
			{!switchToggle && <span className="checkbox-checkmark"></span>}
		</label>
	);
};

CheckboxInput.displayName = "CheckboxInput";

export default CheckboxInput;
