import React, { useState, useEffect } from "react";

import { style } from "./styles";
import ReactionIcons from "./ReactionIcons";
import ReactionFlow from "./ReactionFlow";
import { Reaction } from "../../interfaces";
import firebaseApp from "../../firebase";
import moment from "moment";

const db = firebaseApp.firestore();

// http://localhost:3000/b1234/b4321/pizza <- preview
interface ReactionsProps {
	id: string;
	uid: string;
	icons: { [key: string]: boolean };
	numberOfReactions: number;
}

const Reactions: React.FC<ReactionsProps> = ({ id, icons, uid, numberOfReactions }) => {
	const [reactions, setReactions] = useState<Reaction[]>([]);
	const [myReactions, setMyReactions] = useState<Reaction[]>([]);
	const [muted, setMuted] = useState(false);
	const [loadedDate] = useState(moment());

	useEffect(() => {
		let unsubscribe = () => {};
		if (!muted) {
			unsubscribe = db
				.collection("reactions")
				.doc("contents")
				.collection(id)
				.where("display", "==", true)
				.onSnapshot(snapshot => {
					const newReactions: Reaction[] = [];
					snapshot.docChanges().forEach(change => {
						if (change.type === "added") {
							const data = change.doc.data() as Reaction;
							//@ts-ignore
							if (data.uid !== uid && moment.unix(data.timestamp.seconds).isSameOrAfter(loadedDate)) {
								newReactions.push(data);
							}
						}
					});
					addReactions(newReactions);
				});
		}
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [muted]);

	const addReactions = (reactionBatch: Reaction[]) => {
		setReactions(reactions.concat(reactionBatch));
	};

	const addMyReactions = (reactionBatch: Reaction[]) => {
		setMyReactions(myReactions.concat(reactionBatch));
	};

	return (
		<style.ReactionsWrapper>
			{/* ReactionFlow shows reactions as little "bubbles" over the video */}
			<ReactionFlow reactions={reactions} myReactions={myReactions} numberOfReactions={numberOfReactions} />

			{/* ReactionIcons shows the available icons to click */}
			<ReactionIcons
				addMyReactions={addMyReactions}
				id={id}
				uid={uid}
				icons={icons}
				muted={muted}
				setMuted={setMuted}
			/>
		</style.ReactionsWrapper>
	);
};

export default Reactions;
