import firebase from "firebase";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import firebaseApp from "../../firebase";
import { ChatMessage, ChatRequest } from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import { setChatData, setLimitMessages, setScrolledUp } from "../../redux/Chat/actions";
import getMilliseconds from "../../utils/getMilliseconds";
import Button from "../Button";
import {
	CHAT_BANUSER,
	CHAT_CONVMOUNTED,
	CHAT_DELCONVO,
	CHAT_PINCONVO,
	CHAT_TOGGLECOMMENTFLAG,
	CHAT_TOGGLECOMMENTLIKE,
	CHAT_UNBANUSER,
	emit,
	PinConvoParams,
} from "./Chat.service";
import ChatBlock from "./ChatBlock";
import { style } from "./ChatConversation.styled";

const db = firebaseApp.firestore();

interface ProcessedChatConversationProps {
	bannedUsers: string[];
	chatClosed: boolean;
	chatId: string;
	clientId: string;
	isDirect: boolean;
	isLightBackground: boolean;
	isHostManager: boolean;
	isModerator: boolean;
	uid: string;
	useChatProcessor: boolean;
	inputHeight: number;
	userLoggedIn: boolean;
	userName: string;
	photoURL: string | null;
	chatBlacklist?: string[];
	setHasPinnedChat: Function;
	enableDirectMessaging: boolean;
	setCurrentChat?: (chatRequest: ChatRequest) => void;
}

interface PinState extends PinConvoParams {
	existingPin?: boolean;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const ProcessedChatConversation: React.FC<ProcessedChatConversationProps> = ({
	bannedUsers,
	chatClosed,
	chatId,
	clientId,
	isDirect,
	isLightBackground,
	isHostManager,
	isModerator,
	uid,
	useChatProcessor,
	inputHeight,
	userLoggedIn,
	userName,
	photoURL,
	chatBlacklist,
	setHasPinnedChat,
	enableDirectMessaging,
	setCurrentChat,
}) => {
	const [convo, setConvo] = useState<ChatMessage[]>([]);
	const [loading, setLoading] = useState(true);
	const [scrolled, setIsScrolled] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [scrollHeight, setScrollHeight] = useState(0);
	const [distanceFromBottom, setDistanceFromBottom] = useState(0);
	const [myLastMessage, setMyLastMessage] = useState<string>();
	const [unpinModalData, setUnpinModalData] = useState<PinState | undefined>();
	const [shouldUnpin, setShouldUnpin] = useState(false);

	const chat = useTypedSelector(store => store.chat);

	const chatContainer = useRef<HTMLDivElement>(null);

	const dispatch = useDispatch();

	const scrollToBottom = (bypass = false, skipAnimation = false) => {
		const shouldScroll = distanceFromBottom < 250;
		if (shouldScroll || bypass) {
			setTimeout(() => {
				const objDiv = document.getElementById(`chatContainer-${chatId}`);
				if (objDiv) {
					objDiv.scrollTop = objDiv?.scrollHeight;
				}
			}, 100);

			if (!scrolled) {
				setTimeout(() => {
					setIsScrolled(true);
				}, 1000);
			}
		}
	};

	const delConvo = (data: any) => {
		emit(CHAT_DELCONVO, data, chatId);
	};

	const pinConvo = (data: PinState) => {
		const existingPin = convo.some(x => x.isPinned);
		if (existingPin) {
			data.existingPin = existingPin;
			setUnpinModalData(data);
		} else {
			emit(CHAT_PINCONVO, data, chatId);
		}
	};

	useEffect(() => {
		if (shouldUnpin) {
			setTimeout(() => {
				const pinData = Object.assign({}, unpinModalData);
				delete pinData.existingPin;
				emit(CHAT_PINCONVO, pinData, chatId);
			}, 300);

			handleClosePinModal();
		}
	}, [shouldUnpin]);

	const handleClosePinModal = () => {
		setShouldUnpin(false);
		setUnpinModalData(undefined);
	};

	const banUser = (data: any) => {
		emit(CHAT_BANUSER, data, chatId);
	};

	const unbanUser = (data: any) => {
		emit(CHAT_UNBANUSER, data, chatId);
	};

	const toggleCommentLike = (data: any) => {
		emit(CHAT_TOGGLECOMMENTLIKE, data, chatId);
	};

	const toggleCommentFlag = (data: any) => {
		emit(CHAT_TOGGLECOMMENTFLAG, data, chatId);
	};

	const pinnedChats = Object.values(chat.messages).filter(x => x.isPinned === true).length > 0;

	useEffect(() => {
		setHasPinnedChat(pinnedChats);
	}, [pinnedChats]);

	useEffect(() => {
		emit(CHAT_CONVMOUNTED, { chatId, useChatProcessor }, chatId);
		scrollToBottom(true, true);
		setShowLoader(true);
	}, [chatId]);

	const processWave = (messages: ChatMessage[], time: number) => {
		setTimeout(() => {
			setConvo(messages);
		}, time);
	};

	useEffect(() => {
		if (chatContainer.current) {
			setDistanceFromBottom(
				chatContainer.current.scrollHeight -
					chatContainer.current.offsetHeight -
					chatContainer.current.scrollTop
			);
		}
		const sortedMessages =
			chat.chatId === chatId
				? Object.values(chat.messages).sort((a, b) => {
						return getMilliseconds(a.timestamp) > getMilliseconds(b.timestamp)
							? 1
							: getMilliseconds(a.timestamp) < getMilliseconds(b.timestamp)
							? -1
							: 0;
				  })
				: [];
		const lastCurrent = convo[convo.length - 1];
		const startIndex = lastCurrent ? sortedMessages.findIndex(mes => mes.id === lastCurrent.id) : -1;
		if (startIndex >= 0 && startIndex < sortedMessages.length - 3) {
			const numberOfNewMessages = sortedMessages.length - 1 - startIndex;
			const number = numberOfNewMessages > 5 ? Math.ceil(numberOfNewMessages / 5) : 1;
			let x = 0;
			for (let i = 1; i <= sortedMessages.length - startIndex + number; i = i + number) {
				const messages = sortedMessages.slice(0, startIndex + i);
				processWave(messages, x * 300);
				x++;
			}
		} else {
			setConvo(sortedMessages);
		}
		if (loading) {
			setLoading(false);
		}
	}, [chat.messages]);

	useEffect(() => {
		if (isFetching) {
			setTimeout(() => {
				if (chatContainer?.current) {
					chatContainer.current.scrollTop = chatContainer.current.scrollHeight - scrollHeight;
				}
			}, 5);
		} else {
			if (convo && convo[convo.length - 1]?.uid === uid && myLastMessage !== convo[convo.length - 1].id) {
				setMyLastMessage(convo[convo.length - 1].id);
				scrollToBottom(true);
			} else {
				scrollToBottom();
			}
		}
	}, [convo]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 50);
	}, [inputHeight]);

	const onScroll = (e: any) => {
		if (scrolled && e.target.scrollTop === 0 && convo.filter(x => !x.isPinned).length !== 0) {
			dispatch(setLimitMessages({ limitMessages: false }));
			setScrollHeight(chatContainer.current?.scrollHeight || 0);
			setIsFetching(true);
			db.collection("chat")
				.doc("processed-contents")
				.collection(chatId)
				.where(
					"timestamp",
					"<=",
					firebase.firestore.Timestamp.fromMillis(
						getMilliseconds(convo.filter(x => !x.isPinned)[0]?.timestamp)
					)
				)
				.orderBy("timestamp", "desc")
				.limit(25)
				.get()
				.then(messageDocs => {
					if (messageDocs.size > 0) {
						const messages: { [key: string]: ChatMessage } = {};
						messageDocs.forEach(message => {
							const md = message.data() as ChatMessage;
							md.id = message.id;

							messages[message.id] = md;
						});
						dispatch(
							setChatData({
								messages,
								chatId,
								removeOldMessages: false,
							})
						);
					} else {
						setShowLoader(false);
					}
				})
				.finally(() => {
					setTimeout(() => {
						setIsFetching(false);
					}, 20);
				});
		} else if (
			(!chat.limitMessages || chat.scrolledUp) &&
			chatContainer.current &&
			chatContainer.current.scrollHeight - chatContainer.current.offsetHeight - chatContainer.current.scrollTop <
				250
		) {
			if (!chat.limitMessages) {
				dispatch(setLimitMessages({ limitMessages: true }));
			}
			if (chat.scrolledUp) {
				dispatch(setScrolledUp(false));
			}
		} else if (
			!chat.scrolledUp &&
			chatContainer.current &&
			chatContainer.current.scrollHeight - chatContainer.current.offsetHeight - chatContainer.current.scrollTop >=
				250
		) {
			dispatch(setScrolledUp(true));
		}
	};

	let lastUID: string = "";

	return (
		<style.ChatConvo
			id={`chatContainer-${chatId}`}
			ref={chatContainer}
			inputHeight={inputHeight}
			onScroll={onScroll}
		>
			{showLoader && convo.filter(m => !m.isDeleted).length >= 100 && (
				<div className="ta-center m-top m-bottom">
					<i className={`fa fa-sync ${isFetching ? "fa-spin" : ""}`}></i>
				</div>
			)}

			{convo.map((c, i) => {
				let ret = null;
				if (!bannedUsers.includes(c.uid) && !c.isDeleted) {
					ret = (
						<>
							<ChatBlock
								convo={c}
								toggleConvoLike={toggleCommentLike}
								toggleConvoFlag={toggleCommentFlag}
								delConvo={delConvo}
								banUser={banUser}
								unbanUser={unbanUser}
								chatId={chatId}
								clientId={clientId}
								uid={uid}
								lastUID={lastUID}
								isModerator={isModerator}
								bannedUsers={bannedUsers}
								isLightBackground={isLightBackground}
								isHostManager={isHostManager}
								chatClosed={chatClosed}
								useChatProcessor={useChatProcessor}
								userLoggedIn={userLoggedIn}
								userName={userName}
								photoURL={photoURL}
								chatBlacklist={chatBlacklist}
								isDirect={isDirect}
								pinConvo={pinConvo}
								enableDirectMessaging={enableDirectMessaging}
								setCurrentChat={setCurrentChat}
								shouldUnpin={shouldUnpin}
							/>
						</>
					);
				}

				lastUID = c.uid;
				return ret ? (
					<style.ChatConvoLine
						className={c.isPinned ? "mb-0" : ""}
						key={`${c.id}-${c.originalId ? "" : c.updatedAt.nanoseconds}`}
					>
						{ret}
					</style.ChatConvoLine>
				) : null;
			})}
			{!loading && convo.length === 0 && (
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						fontSize: 20,
					}}
				>
					{isDirect && !isHostManager ? (
						<span>Connecting to a host. Send a message to get started!</span>
					) : (
						<span>
							<span role="img" aria-label="Hand wave emoji">
								👋{" "}
							</span>
							{isHostManager && isDirect
								? "Accept the chat request to get started!"
								: "Welcome! Send a message to get started!"}
						</span>
					)}
				</div>
			)}
			<style.ChatConvoNewMessages
				inputHeight={inputHeight}
				className={`chat-new-messages ${chat.newMessages && distanceFromBottom > 250 ? "active" : ""}`}
				onClick={() => scrollToBottom(true)}
			>
				<i className="fal fa-arrow-down"></i> New Messages
			</style.ChatConvoNewMessages>
			<Modal show={!!unpinModalData} onHide={handleClosePinModal} className="default-modal">
				<Modal.Header closeButton>
					<Modal.Title>
						{unpinModalData?.existingPin && unpinModalData?.pin && !unpinModalData?.convo.dupePinnedChatId
							? "Replace pinned"
							: "Unpin"}{" "}
						message?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{unpinModalData?.existingPin && unpinModalData?.pin && !unpinModalData?.convo.dupePinnedChatId
						? "This will remove the existing pinned message and replace it with the new one."
						: "Are you sure you want to unpin this message?"}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePinModal} className="modal-button cancel-button">
						Cancel
					</Button>
					<Button
						color="#e71d36"
						onClick={() => setShouldUnpin(true)}
						className="modal-button confirm-button"
					>
						{unpinModalData?.existingPin && unpinModalData?.pin && !unpinModalData?.convo.dupePinnedChatId
							? "Replace"
							: "Unpin"}
					</Button>
				</Modal.Footer>
			</Modal>
		</style.ChatConvo>
	);
};

export default ProcessedChatConversation;
