import { Client } from "../../../../interfaces";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

export const clientFormInitialValues: Partial<Client> = {
	id: uuidv4(),
	name: "",
	key: "",
	logoImage: "",
	homeUrl: "",
	theme: {
		textColor: "#000000",
		buttonTextColor: "#000000",
		buttonBackgroundColor: "#FFFFFF",
		buttonHoverBackgroundColor: "#ECECEC",
		backgroundColor: "#FFFFFF",
		backgroundImage: "",
		wellTextColor: "#000000",
		wellBackgroundColor: "#FFFFFF",
		navBackgroundColor: "#000000",
		navLinkColor: "#FFFFFF",
		navLinkHoverColor: "#ECECEC",
	},
	stripeCustomerId: "",
	isActivated: false,
	links: [],
	users: {},
	showSettings: false,
	timeZoneName: moment.tz.guess(),
};
