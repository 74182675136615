import moment from "moment";
import React, { useEffect, useState } from "react";
import authFirebaseApp from "../../authFirebase";
import firebaseApp from "../../firebase";
import { Content, Reaction, Channel } from "../../interfaces";
import { timeZoneAbbreviation } from "../../utils/timezones";
import LiveBadge from "../LiveBadge";
import ReactionFlow from "../Reactions/ReactionFlow";
import ReactionIcons from "../Reactions/ReactionIcons";
import * as Styled from "./VideoInfoHeader.styled";
import ShareButton from "../ShareButton";

// import { useSelector } from "react-redux";
// import { TypedUseSelectorHook } from "../../redux";

interface VideoInfoHeaderProps {
	name: string;
	showLive: boolean;
	isOpen: boolean;
	// showLiveNumber?: boolean;
	startsAt?: string;
	duration?: number;
	content: Content;
	channel?: Channel;
	contentValidUntil?: string;
}

const db = firebaseApp.firestore();

// const useTypedSelector: TypedUseSelectorHook = useSelector;

const VideoInfoHeader: React.FC<VideoInfoHeaderProps> = ({
	name,
	showLive,
	startsAt,
	content,
	duration,
	isOpen,
	channel,
	contentValidUntil
}) => {
	// TODO: i think needs stronger checking for old content records that don't have content.reactions... maybe
	const numberOfReactions = content.reactions
		? Object.values(content.reactions?.icons || {}).filter(i => i).length + 1
		: 0;
	const timeContent = () => {
		if (startsAt) {
			return (
				<>
					{moment(startsAt).format("dddd, MMMM D, YYYY h:mm A")} ({timeZoneAbbreviation(startsAt)})
				</>
			);
		} else if (duration) {
			return (
				<>
					<i className="fal fa-clock"></i> {duration} {duration === 1 ? "minute" : "minutes"}
				</>
			);
		}
	};

	const shareUrl = `${document.location.origin}/share${document.location.pathname}`;

	// const analyticsUserId = useTypedSelector(store => store.analytics.analyticsUserId);
	// const uid = firebaseApp?.auth()?.currentUser?.uid;

	const [reactions, setReactions] = useState<Reaction[]>([]);
	const [myReactions, setMyReactions] = useState<Reaction[]>([]);
	const [muted, setMuted] = useState(false);
	const [loadedDate, setLoadedDate] = useState(moment());

	useEffect(() => {
		setLoadedDate(moment());
	}, [content.id, muted, authFirebaseApp.auth().currentUser?.uid]);

	useEffect(() => {
		setMyReactions([]);
	}, [content.id]);

	// useEffect(() => {
	// 	if (analyticsUserId && uid && content.contentType && (content.contentType === ContentType.onDemand || isOpen)) {
	// 		initFirebasePresence(content.id, analyticsUserId, uid, content.contentType);
	// 	}
	// }, [content.id, uid, isOpen]);

	useEffect(() => {
		if (!muted) {
			const unsubscribe = db
				.collection("reactions")
				.doc("contents")
				.collection(content.id)
				.where("timestamp", ">", loadedDate.toDate())
				.onSnapshot(snapshot => {
					const newReactions: Reaction[] = [];
					snapshot.docChanges().forEach(change => {
						if (change.type === "added") {
							const data = change.doc.data() as Reaction;
							if (
								data.uid !== authFirebaseApp.auth().currentUser?.uid &&
								//@ts-ignore
								moment.unix(data.timestamp.seconds).isSameOrAfter(loadedDate)
							) {
								newReactions.push(data);
							}
						}
					});
					addReactions(newReactions);
				});
			return () => {
				if (unsubscribe) {
					unsubscribe();
				}
			};
		}
	}, [loadedDate]);

	const addReactions = (reactionBatch: Reaction[]) => {
		setReactions(reactions.concat(reactionBatch));
	};

	const addMyReactions = (reactionBatch: Reaction[]) => {
		setMyReactions(myReactions.concat(reactionBatch));
	};

	const setMutedLogic = (val: boolean) => {
		setLoadedDate(moment());
		setMuted(val);
	};

	return (
		<Styled.VideoInfo>
			{showLive && (
				<div className="relative">
					<ReactionFlow
						reactions={reactions}
						myReactions={myReactions}
						numberOfReactions={numberOfReactions}
					/>
				</div>
			)}

			<Styled.TopRowContainer>
				<Styled.TopRowSwiper numberOfReactions={showLive ? numberOfReactions : 0}>
					<Styled.TopRow>
						<Styled.Title>{name}</Styled.Title>
						<Styled.ReactionsContainer numberOfReactions={showLive ? numberOfReactions : 0}>
							{authFirebaseApp.auth().currentUser &&
								authFirebaseApp.auth().currentUser?.uid &&
								content.reactions &&
								content.reactions.enabled &&
								showLive && (
									<ReactionIcons
										addMyReactions={addMyReactions}
										id={content.id}
										uid={authFirebaseApp.auth().currentUser?.uid || ""}
										icons={content.reactions.icons}
										muted={muted}
										setMuted={setMutedLogic}
									/>
								)}
						</Styled.ReactionsContainer>
					</Styled.TopRow>
				</Styled.TopRowSwiper>
			</Styled.TopRowContainer>

			<Styled.BottomRow>
				<Styled.TimeLabel>{timeContent()}</Styled.TimeLabel>
				<Styled.Row>
					{!channel?.isPrivate &&
						(channel?.brushfireEventIds?.length ?? 0) === 0 &&
						content.share?.enabled && (
							<ShareButton url={shareUrl} defaultMessage={content.share.defaultMessage} />
						)}
					<div className="current-live-badge">
						<LiveBadge contentData={content} invertable showLiveNumber={content.showLiveStats} isFrontEnd />
					</div>
				</Styled.Row>
			</Styled.BottomRow>
			{!!contentValidUntil && <Styled.ValidUntilLabel>{contentValidUntil}</Styled.ValidUntilLabel>}
		</Styled.VideoInfo>
	);
};

export default VideoInfoHeader;
