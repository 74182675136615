import { isEmpty, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ContentFilter, { OptionType } from "../../../components/ContentFilter";
import ContentCard from "../../../components/Dashboard/Contents/ContentCard";
import { useToast } from "../../../components/Toast/ToastProvider";
import firebase from "../../../firebase";
import { useWindowWidth } from "../../../hooks";
import { Content, ContentType, Interaction, Tag } from "../../../interfaces";
import { TypedUseSelectorHook } from "../../../redux";
import userHasRole from "../../../services/userHasRole";
import { generateRandomString } from "../../../utils/generateRandom";
import { PageContainer, Well } from "../UI";
import * as Styled from "./ContentsList.styled";

const useTypedSelector: TypedUseSelectorHook = useSelector;
interface ModalData {
	id: string;
	name: string;
}

interface LocationState {
	channelId: string;
}

// Toggle button to reveal 2 buttons for single or recurring events
const ContentsList: React.FC = () => {
	const { clientId } = useParams<{ clientId: string }>();
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const clientKey = dashboard?.clientData?.key;
	const db = firebase.firestore();
	const [contentList, setContentList] = useState<Content[]>([]);
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const location = useLocation();
	const { addToast } = useToast();

	const [showModal, setShowModal] = useState(false);
	const [inputValidation, setInputValidation] = useState(false);
	const [inputModalVal, setInputModalVal] = useState("");
	const [modalData, setModalData] = useState<ModalData>();
	const [showAddContentModal, setShowAddContentModal] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [tags, setTags] = useState<Tag[]>([]);
	const [filteredOutChannelIds, setFilteredOutChannelIds] = useState<string[]>([]);
	const [filteredTagIds, setFilteredTagIds] = useState<string[]>([]);
	const windowWidth = useWindowWidth();
	const appUserWhitelist = clientId ? user.appUser?.whitelist?.[clientId] : null;

	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;

	const getContentsAndTags = async () => {
		try {
			const contentsDocRef = await db
				.collection("contents")
				.where("clientId", "==", clientId)
				.get();
			const tagsDocRef = await db
				.collection("tags")
				.doc("clientTags")
				.collection(clientId!)
				.get();

			const tempContents: Content[] = [];
			const tempTags: Tag[] = [];

			contentsDocRef.forEach(content => {
				const tempContent = { ...(content.data() as Content) };
				if (tempContent.versionCode === null) {
					tempContents.push(tempContent);
				}
			});

			tagsDocRef.forEach(tag => {
				tempTags.push({ ...(tag.data() as Tag) });
			});

			setContentList(tempContents);
			setTags(tempTags);
			setLoading(false);
		} catch (error) {
			console.warn("Error getting content and tags", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		const { state } = location;
		const possibleState = state as LocationState | undefined;
		if (possibleState?.channelId && dashboard.channelsData) {
			const filteredOutChannels = dashboard.channelsData.filter(
				channel => channel.id !== possibleState.channelId
			);
			setFilteredOutChannelIds(filteredOutChannels.map(c => c.id));
		}

		// remove this if you want to keep state when moving from channel list to content list when page refreshes
		if (!isEmpty(history.location.state)) {
			(history as any).replace({ ...history, location: { ...history.location, state: {} } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let tempIsMobile = false;
		if (windowWidth <= 992) {
			tempIsMobile = true;
		}

		if (tempIsMobile !== isMobile) {
			setIsMobile(tempIsMobile);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowWidth]);

	useEffect(() => {
		getContentsAndTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, clientId]);

	const handleCloseModal = () => {
		setShowModal(false);
		setInputModalVal("");
	};

	const handleShowModal = () => setShowModal(true);

	const deleteContent = (id: string, name: string) => {
		handleShowModal();
		setModalData({
			id,
			name,
		});
	};

	const updateModalInputVal = (e: any) => {
		setInputModalVal(e.target.value);
	};

	const handleConfirmDelete = (id: string, name: string, confirmedName: string) => {
		if (name.toLowerCase() === confirmedName.toLowerCase()) {
			setInputValidation(false);
			const content = db.collection("contents").doc(id);
			const versionCode = generateRandomString(8, false, true);
			content
				.get()
				.then(doc => {
					if (doc.exists) {
						content
							.update({ versionCode })
							.then(async res => {
								// also delete interactions if they exist
								try {
									const qs = await db
										.collection("interactions")
										.doc("contents")
										.collection(id)
										.get();
									const interactions: Interaction[] = [];
									qs.forEach(i => {
										const interaction = i.data() as Interaction;
										if (!interaction.versionCode) {
											interactions.push({ ...interaction });
										}
									});
									if (!isEmpty(interactions)) {
										for (const interaction of interactions) {
											const interactionVersionCode = generateRandomString(8, false, true);
											db.collection("interactions")
												.doc("contents")
												.collection(id)
												.doc(interaction.id)
												.set({ ...interaction, versionCode: interactionVersionCode })
												.then(() => null)
												.catch(error => console.warn("Error deleting interaction", error));
										}
									}
								} catch (error) {
									console.warn("Error deleting interactions", error);
								}

								const newContentList = contentList.filter(content => content.id !== id);
								setContentList(newContentList);
								setInputModalVal("");
								handleCloseModal();
							})
							.catch(error => {
								console.warn("Error deleting content -", error);
								addToast("error", "Error deleting content");
							});
					}
				})
				.catch(error => {
					console.warn("Error deleting content -", error);
					addToast("error", "Error deleting content");
				});
		} else {
			setInputValidation(true);
		}
	};

	const onFilter = (thingsToFilter: OptionType[], which: "channels" | "tags") => {
		const filteredIds = thingsToFilter.map(option => option.value);
		// we want to considered these id's filtered out if its channels
		if (which === "channels") {
			setFilteredOutChannelIds(filteredIds);
		} else {
			setFilteredTagIds(filteredIds);
		}
	};

	const filterContentList = (contents: Content[]) => {
		let filteredContentList: Content[] = [...contents];

		if (filteredOutChannelIds && !isEmpty(filteredOutChannelIds)) {
			filteredContentList = contents.filter(content => {
				if (content.channelIds && content.channelIds.length > 0) {
					return !content.channelIds.every(id => filteredOutChannelIds.includes(id));
				} else {
					return !filteredOutChannelIds.includes(content.channelId);
				}
			});
		}
		if (filteredTagIds && !isEmpty(filteredTagIds)) {
			filteredContentList = filteredContentList.filter(content => {
				return content.tags?.some((t: any) => {
					return filteredTagIds.includes(t);
				});
			});
		}
		if (appUserWhitelist) {
			filteredContentList = filteredContentList.filter(content =>
				appUserWhitelist.some(x => content.channelIds?.includes(x))
			);
		}

		return filteredContentList;
	};

	let channelFilterList = dashboard.channelsData
		? dashboard.channelsData
			.map(channel => ({ value: channel.id, label: channel.name }))
			.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
		: [];

	if (clientId && appUserWhitelist) {
		channelFilterList = channelFilterList.filter(channel => appUserWhitelist.includes(channel.value));
	}

	let tagFilterList = !isEmpty(tags)
		? tags
			.map(tag => ({ value: tag.id, label: tag.name }))
			.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
		: [];

	const filteredContentList = filterContentList(contentList);

	let upcomingContents = filteredContentList?.filter(
		(content: Content) => !!content.closesAt && new Date(content.closesAt).getTime() > Date.now()
	);

	upcomingContents = orderBy(upcomingContents, ["startsAt", "name"]);

	let pastContents = filteredContentList?.filter(
		(content: Content) => !!content.closesAt && new Date(content.closesAt).getTime() <= Date.now()
	);

	pastContents = orderBy(pastContents, ["closesAt", "name"], ["desc", "asc"]);

	const onDemandContents = filteredContentList
		?.filter(content => !content.closesAt)
		.sort((a: Content, b: Content) => {
			if (!a.originalAirDate) {
				a.originalAirDate = new Date("2020-3-15");
			}
			if (!b.originalAirDate) {
				b.originalAirDate = new Date("2020-3-15");
			}
			const diff = moment(b.originalAirDate).diff(moment(a.originalAirDate));
			return diff === 0
				? a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
					? 1
					: b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
						? -1
						: 0
				: diff;
		});

	const renderContent = (content: Content) => {
		const channelIds = content.channelIds?.length > 0 ? content.channelIds : [content.channelId];
		const tagIds = content.tags || [];
		const channels = dashboard.channelsData || [];
		const filteredChannels = channels.filter(channel => channelIds.includes(channel.id));
		const filteredTags = tags.filter(tag => tagIds.includes(tag.id));

		if (filteredChannels.length > 0) {
			return (
				<ContentCard
					key={content.id}
					content={content}
					clientKey={clientKey || ""}
					channels={filteredChannels}
					tags={filteredTags}
					onDelete={deleteContent}
					onVideoEnd={getContentsAndTags}
				/>
			);
		}
	};

	if (loading || dashboard.clientDataLoading) {
		return <PageContainer htmlPageTitle="Loading..." />;
	}

	return dashboard.clientData ? (
		<PageContainer size="full" htmlPageTitle="Channel Content">
			<Styled.SmallTitle>{dashboard.channelData?.name}</Styled.SmallTitle>
			{isMobile ? (
				<Styled.HeaderRow>
					<Styled.ListHeader>
						<Styled.Title>CONTENT</Styled.Title>
						{isAdmin && (
							<Styled.AddButton className="fal fa-plus" onClick={() => setShowAddContentModal(true)} />
						)}
					</Styled.ListHeader>
					<Styled.FilterContainer>
						<ContentFilter
							tags={tagFilterList}
							channels={channelFilterList}
							channelsPicked={filteredOutChannelIds}
							tagsPicked={filteredTagIds}
							onChannelFilter={arr => onFilter(arr, "channels")}
							onTagFilter={arr => onFilter(arr, "tags")}
							isMobile={isMobile}
						/>
					</Styled.FilterContainer>
				</Styled.HeaderRow>
			) : (
				<Styled.HeaderRow>
					<Styled.Title>CONTENT</Styled.Title>
					<Styled.ContentOptions>
						<ContentFilter
							tags={tagFilterList}
							channels={channelFilterList}
							channelsPicked={filteredOutChannelIds}
							tagsPicked={filteredTagIds}
							onChannelFilter={arr => onFilter(arr, "channels")}
							onTagFilter={arr => onFilter(arr, "tags")}
						/>
						{isAdmin && (
							<Styled.AddButton className="fal fa-plus" onClick={() => setShowAddContentModal(true)} />
						)}
					</Styled.ContentOptions>
				</Styled.HeaderRow>
			)}
			<Well>
				<p className="py-2">
					Create and schedule content from various video providers for your services, sessions, webinars, or
					any type of event.{" "}
					<span>
						<a
							href="https://support.brushfire.com/hc/en-us/articles/360013032819-Adding-Content-in-Brushfire-Online"
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn more <i className="fal fa-long-arrow-right" />
						</a>
					</span>
				</p>
			</Well>
			{!isEmpty(upcomingContents) && dashboard?.channelsData && (
				<>
					<Styled.ListTitle>UPCOMING</Styled.ListTitle>
					<Styled.ContentList>{upcomingContents?.map(content => renderContent(content))}</Styled.ContentList>
				</>
			)}
			{!isEmpty(onDemandContents) && dashboard?.channelsData && (
				<>
					<Styled.ListTitle>ON-DEMAND</Styled.ListTitle>
					<Styled.ContentList>{onDemandContents?.map(content => renderContent(content))}</Styled.ContentList>
				</>
			)}
			{!isEmpty(pastContents) && dashboard?.channelsData && (
				<>
					<Styled.ListTitle>PAST</Styled.ListTitle>
					<Styled.ContentList>{pastContents?.map(content => renderContent(content))}</Styled.ContentList>
				</>
			)}

			{showAddContentModal && (
				<Modal
					show={showAddContentModal}
					onHide={() => setShowAddContentModal(false)}
					centered
					autoFocus={true}
					backdrop={true}
					size="lg"
					className="dark-modal"
				>
					{dashboard.channelsData?.length === 0 ? (
						<>
							<Modal.Header closeButton>
								<Modal.Title>No Channels</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Card body onClick={() => history.push("channels/add")}>
									<h2>+ Create a Channel</h2>
									<div className="description">
										Channels provide a place to organize and display your content
									</div>
								</Card>
							</Modal.Body>
						</>
					) : (
						<>
							<Modal.Header closeButton>
								<Modal.Title>Content Type</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Card
									body
									onClick={() => history.push("contents/add", { contentType: ContentType.live })}
								>
									<h2>Live</h2>
									<div className="description">
										Live content requires an Embed Code from your streaming provider to display
										video happening in real-time.
									</div>
								</Card>
								<Card
									body
									onClick={() =>
										history.push("contents/add", { contentType: ContentType.simulatedLive })
									}
								>
									<h2>Simulated Live</h2>
									<div className="description">
										Simulated Live broadcasts allow you to use a pre-recorded video and schedule it
										to play at a specific time as a live online event.
									</div>
								</Card>
								<Card
									body
									onClick={() => history.push("contents/add", { contentType: ContentType.onDemand })}
								>
									<h2>On-Demand</h2>
									<div className="description">
										On-Demand content can be viewed at any time. All you need is the URL of your
										video from your video provider.
									</div>
								</Card>
							</Modal.Body>
						</>
					)}
				</Modal>
			)}
			{modalData && (
				<Modal show={showModal} onHide={handleCloseModal} className="default-modal">
					<Modal.Header closeButton>
						<Modal.Title>Delete Content?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						This action cannot be undone.
						<br />
						To permanently delete this content please type <strong>{modalData.name}</strong> below
					</Modal.Body>
					<Col>
						<Form.Control
							value={inputModalVal}
							onChange={updateModalInputVal}
							size="sm"
							type="text"
							placeholder={`Enter "${modalData.name}" to confirm`}
							isInvalid={inputValidation}
						/>
					</Col>

					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal} className="modal-button cancel-button">
							Close
						</Button>
						<Button
							variant="danger"
							disabled={modalData.name.toLowerCase() !== inputModalVal.toLowerCase()}
							onClick={() => handleConfirmDelete(modalData?.id, modalData?.name, inputModalVal)}
							className="modal-button confirm-button"
						>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<div style={{ marginBottom: 100 }} />
		</PageContainer>
	) : (
		<PageContainer htmlPageTitle="Channels">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>Channel not found.</div>
		</PageContainer>
	);
};

export default ContentsList;
