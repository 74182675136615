import React, { useRef, useState } from "react";

export interface TextCopyBlockProps {
	className?: string;
	children: string;
	confirmText?: string;
	isCode?: boolean;
}

const TextCopyBlock: React.FC<TextCopyBlockProps> = ({ className = "", children, confirmText, isCode = false }) => {
	const [copyText, setCopyText] = useState<string>(children);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const copyToClipboard = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		let proceed = true;
		if (confirmText) {
			proceed = window.confirm(confirmText);
		}
		if (textAreaRef && textAreaRef.current && proceed) {
			try {
				navigator.clipboard.writeText(textAreaRef.current?.value || "");
			} catch {
				textAreaRef.current?.select();
				document.execCommand("copy");
			}
			// This is just personal preference.
			// I prefer to not show the whole text area selected.
			e.target.focus();
			setCopyText("Copied!");
			setTimeout(() => {
				setCopyText(children);
			}, 3000);
		}
	};
	const kids =
		copyText === "Copied!" ? "Copied!" : children.split("\n").map((child, index) => <div key={index}>{child}</div>);

	return (
		<div className={`text-copy ${className} ${isCode ? "code-copy" : ""}`}>
			<div className="text-copy-text">
				{isCode ? (
					<code className="block m-bottom">
						<pre>{kids}</pre>
					</code>
				) : (
					kids
				)}
			</div>
			<button onClick={copyToClipboard} title="Copy Text" className="btn">
				<i className="far fa-lg fa-copy" />
			</button>

			<textarea ref={textAreaRef} value={children} className="hidden" />
		</div>
	);
};

export default TextCopyBlock;
