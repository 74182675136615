import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, isEqual, difference } from "lodash";

import firebase from "../../firebase";
import { TypedUseSelectorHook } from "../../redux";
import { PageContainer } from "./UI";
import { User, UserPermissions } from "../../interfaces";
import { chunk } from "../../utils/chunk";
import { Helmet } from "react-helmet-async";
import userHasRole from "../../services/userHasRole";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const Title = styled.h1`
	color: #fff;
`;

const AddButton = styled.i`
	cursor: pointer;
	color: #fff;
	font-size: 25px;
`;

const ClientItem = styled.div`
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;

const ClientUserList: React.FC = () => {
	const [userList, setUserList] = useState<User[]>([]);
	const history = useHistory();
	const { clientId } = useParams<{ clientId: string }>();
	const db = firebase.firestore();
	const dashboard = useTypedSelector(store => store.dashboard);
	const { appUser, user } = useTypedSelector(state => state.user);
	const isGlobalAdmin = appUser?.globalRole === "owner" || appUser?.globalRole === "admin";

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getUsers();
	}, [dashboard.clientData]);

	const hasInvitePermission =
		appUser && dashboard.clientId
			? userHasRole(dashboard.clientId, appUser, ["owner", "admin", "superhost"], dashboard.channelId)
			: false;

	const getUsers = async () => {
		if (dashboard.clientData && loading) {
			if (!isEmpty(dashboard.clientData) && isEmpty(dashboard.clientData?.users)) {
				setLoading(false);
			} else {
				try {
					let allUsers: any[] = [];
					const querySnapshot = await db
						.collection("users")
						.where(`clients.${dashboard.clientId}`, "in", ["owner", "admin", "host", "superhost"])
						.get();
					querySnapshot.forEach(async doc => await allUsers.push({ ...doc.data() }));
					allUsers.sort((a: any, b: any) => {
						return a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0;
					});
					setUserList(allUsers);
				} catch (error) {
					console.warn(`Error getting users`, error);
				}

				setLoading(false);
			}
		}
	};

	const onClientClick = (user: any) => {
		if (!userHasEditPermission(user)) {
			return;
		}
		const { id, email, firstName, lastName, clients, verificationCode, whitelist, tokens } = user;
		history.push({
			pathname: `/dashboard/${clientId}/users/${id}`,
			state: {
				user: {
					id,
					email,
					firstName,
					lastName,
					role: dashboard.clientData ? clients[dashboard.clientData.id] : "host",
					verificationCode,
					whitelist: whitelist,
					tokens,
				},
			},
		});
	};

	if (dashboard.clientDataLoading || loading) {
		return <PageContainer htmlPageTitle="Loading..." />;
	}

	const userHasEditPermission = (currentUser: User) => {
		if (appUser && currentUser && clientId) {
			let permissions: UserPermissions = {
				owner: ["owner", "admin", "superhost", "host"],
				admin: ["admin", "superhost", "host"],
				superhost: ["superhost", "host"],
			};
			const currentUserWhitelist = currentUser.whitelist?.[clientId];
			const appUserWhitelist = appUser.whitelist?.[clientId];

			const whitelistCheckPassed = () => {
				if (!appUserWhitelist) {
					return true;
				} else {
					if (
						isEqual(currentUserWhitelist, appUserWhitelist) ||
						difference(currentUserWhitelist, appUserWhitelist).length === 0
					) {
						return true;
					} else {
						return false;
					}
				}
			};

			if (
				appUser.id !== currentUser.id &&
				(isGlobalAdmin ||
					(permissions[appUser.clients[clientId]].includes(currentUser.clients[clientId]) &&
						whitelistCheckPassed()))
			) {
				return true;
			} else {
				return false;
			}
		}
	};

	return !isEmpty(dashboard.clientData) ? (
		<PageContainer size="md" className="m-top-large" htmlPageTitle="Organization Users">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Title>YOUR USERS</Title>
				{hasInvitePermission && (
					<AddButton
						className="fal fa-plus"
						onClick={() => history.push(`/dashboard/${clientId}/users/add`)}
					/>
				)}
			</div>
			<div style={{ marginTop: 20 }}>
				{userList.map(user => {
					const { id, firstName, lastName, email } = user;
					return (
						<ClientItem
							className={userHasEditPermission(user) ? "btn-row" : "btn-row disabled"}
							key={id}
							onClick={() => onClientClick(user)}
						>
							<i className="fal fa-chevron-right" style={{ color: "white" }} />
							<h2 className="text-left" style={{ color: "white" }}>
								{`${firstName} ${lastName}`}
								<span className="user-pill">
									<i className="fas fa-user"></i>{" "}
									{user.globalRole ? user.globalRole : user.clients[clientId]}
								</span>
							</h2>
							<p className="text-left" style={{ color: "white" }}>
								{email}
							</p>
						</ClientItem>
					);
				})}
			</div>
		</PageContainer>
	) : (
		<PageContainer htmlPageTitle="Organization Users">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>Users not found.</div>
		</PageContainer>
	);
};

export default ClientUserList;
