import React, { useState, useContext, useEffect, useRef } from "react";
import { withTheme } from "styled-components";
import { isEmpty } from "lodash";

import { Interaction, Response } from "../../interfaces";
import { UserState } from "../../redux/User/types";
import Modal from "../Modals";
import { stripHtml } from "../../utils/stripHtml";

import * as Screen from "./Screens";

import * as Styled from "./InteractionResponse.styled";

interface InteractionResponseProps {
	interaction: Interaction;
	respondToInteraction: (
		interaction: Interaction,
		response: Response | null,
		data: Record<string, any> | null,
		hasAnswered?: boolean
	) => void;
	user?: UserState;
	isModal?: boolean;
	modalVisible?: boolean;
	closeModal?: (interaction: Interaction, hasAnswered: boolean, showThanks: boolean) => void;
	isPreview?: boolean;
}

interface ResponseModalProps extends InteractionResponseProps {
	visible: boolean;
	closeModal: (interaction: Interaction) => void;
}

interface DismissProps {
	forceDismissAnswer?: boolean;
}

interface CloseProps {
	forceDismissAnswer?: boolean;
	padded?: boolean;
}

interface ContextInterface {
	interaction: any;
	respond: (
		interaction: Interaction,
		response: Response | null,
		data: Record<string, any> | null,
		isDismiss?: boolean
	) => void;
	hasAnswered: boolean;
	isPreview: boolean;
	isModal: boolean;
	user?: UserState;
}

export const Context = React.createContext<ContextInterface>({
	interaction: null,
	respond: (interaction, response, data, isDismiss) => null,
	hasAnswered: false,
	isPreview: false,
	isModal: false,
});

export const Dismiss: React.FC<DismissProps> = ({ forceDismissAnswer = false }) => {
	const { interaction, respond, isPreview } = useContext(Context);
	return (
		<Styled.DismissLink
			isPreview={isPreview}
			onClick={() => {
				respond(interaction, null, null, forceDismissAnswer || true);
			}}
		>
			Dismiss
		</Styled.DismissLink>
	);
};

export const CloseLink: React.FC<CloseProps> = ({ padded }) => {
	const { interaction, respond, isPreview } = useContext(Context);
	const response = { actionType: "close" } as Response;
	return (
		<Styled.DismissLink
			isPreview={isPreview}
			padded={padded}
			onClick={() => {
				respond(interaction, response, null, false);
			}}
		>
			Close
		</Styled.DismissLink>
	);
};

const InteractionResponse: React.FC<InteractionResponseProps & { theme: any }> = ({
	interaction,
	respondToInteraction,
	user,
	isModal = false,
	modalVisible = false,
	closeModal,
	isPreview = false,
	theme,
}) => {
	const [response, setResponse] = useState<Response>();
	const [showThanks, setShowThanks] = useState(false);
	const [hasAnswered, setHasAnswered] = useState(false);
	const [showAnswer, setShowAnswer] = useState(false);

	const getData = (values: any, response: Response) => {
		respond(interaction, response, values);
		setShowThanks(true);
	};

	const respond = (
		interaction: Interaction,
		response: Response | null,
		data: Record<string, any> | null,
		isDismiss = false
	) => {
		if (!isPreview) {
			if (isDismiss) {
				respondToInteraction(interaction, response, data, hasAnswered && showThanks);
			} else {
				if (
					response?.actionType === "collectEmail" &&
					(isEmpty(response.actionText) || isEmpty(stripHtml(response?.actionText || "")))
				) {
					respondToInteraction(interaction, response, data, false);
				} else {
					respondToInteraction(interaction, response, data);
				}
			}
		} else {
			if (!response && !showThanks) {
				setShowAnswer(true);
			}
		}
	};

	const responseClick = (response: Response) => {
		const { frontEndUser } = user ?? {};
		if (response.actionType === "dismiss") {
			respond(interaction, response, null, true);
		} else {
			if (response.actionType === "collectEmail") {
				let tempDisplayName: string | null = user?.frontEndUser?.displayName || null;
				if (frontEndUser?.email) {
					if (isEmpty(response.actionText)) {
						respond(
							interaction,
							response,
							{ email: frontEndUser.email, displayName: tempDisplayName, uid: user?.frontEndUser?.uid },
							true
						);
					} else {
						respond(interaction, response, {
							email: frontEndUser?.email,
							displayName: tempDisplayName,
							uid: user?.frontEndUser?.uid,
						});
					}
					setShowThanks(true);
				}
			} else if (response.actionType === "startChat") {
				respond(
					interaction,
					response,
					{ email: frontEndUser?.email, displayName: frontEndUser?.displayName, uid: frontEndUser?.uid },
					true
				);
			} else if (response.actionType === "textOnly") {
				if (frontEndUser?.email) {
					respond(interaction, response, {
						email: frontEndUser.email,
						displayName: frontEndUser.displayName,
					});
				} else {
					respond(interaction, response, null);
				}
				setShowThanks(true);
			} else if (response.actionType === "url") {
				if (frontEndUser?.email) {
					respond(
						interaction,
						response,
						{ email: frontEndUser.email, displayName: frontEndUser.displayName },
						true
					);
				} else {
					respond(interaction, response, null, true);
				}
			} else if (response.actionType === "customContent") {
				respond(interaction, response, null);
			}

			setResponse(response);
			setHasAnswered(true);
		}
	};

	const pickScreen = (response?: Response) => {
		switch (response?.actionType) {
			case "textOnly":
				return <Screen.TextOnly response={response} />;
			case "collectEmail":
				if (showThanks) {
					return <Screen.TextOnly response={response} />;
				} else {
					return <Screen.CollectEmail response={response} getData={getData} />;
				}
			case "startChat":
			case "dismiss":
			case "url":
				if (isPreview) {
					return <Screen.ShowAnswer response={response} />;
				}
				return null;
			case "customContent":
				return <Screen.CustomContent response={response} />;
			default:
				return <Screen.AskQuestion interaction={interaction} responseClick={responseClick} />;
		}
	};

	const isFullContent = response?.actionType === "customContent";

	const Content: React.FC = () => (
		<Styled.PanelContainer isModal={isModal ?? false} isPreview={isPreview} isFullContent={isFullContent}>
			<Styled.ContentContainer isPreview={isPreview}>
				{showAnswer ? <Screen.ShowAnswer response={response} /> : pickScreen(response)}
			</Styled.ContentContainer>
		</Styled.PanelContainer>
	);

	return (
		<Context.Provider value={{ interaction, respond, hasAnswered, isPreview, isModal, user }}>
			{isModal && !isPreview ? (
				<Modal.Simple
					style={{ backgroundColor: theme.wellBackgroundColor }}
					visibility={modalVisible}
					closeModal={() => isModal && closeModal && closeModal(interaction, hasAnswered, showThanks)}
				>
					<Content />
				</Modal.Simple>
			) : (
				<Content />
			)}
		</Context.Provider>
	);
};

// export default withTheme(InteractionResponse);
export default withTheme(InteractionResponse);
