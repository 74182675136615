import React, { Fragment, useEffect, useState } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import { createBrowserHistory, History } from "history";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AppRoutes from "./navigation/AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/.CSS/main.css";
import configureStore from "./redux";
import { trackAuthStatus } from "./redux/User/actions";
import fb from "./firebase";
import moment from "moment";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
	apiKey: "c77d8f4079444678a19f9437aca82aef",
	plugins: [new BugsnagPluginReact()],
});

export const history: History = createBrowserHistory();
const { store, persistor } = configureStore(history);
const db = fb.firestore();

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
	// We execute the same script as before
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const App: React.FC = () => {
	const [dateLoaded] = useState(moment());

	//@ts-ignore
	const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

	useEffect(() => {
		// code to run on component mount
		store.dispatch(trackAuthStatus());
	}, []);

	useEffect(() => {
		let unsubscribe = () => {};
		const pubsub = db.collection("product-pubsub").doc("product");
		unsubscribe = pubsub.onSnapshot(
			{ includeMetadataChanges: true },
			snapshot => {
				if (snapshot.data()) {
					let refreshDate = moment(snapshot.data()?.refreshAt.toDate());
					if (refreshDate.isAfter(dateLoaded) && !snapshot.metadata.hasPendingWrites) {
						window.location.reload(true);
					}
				}
			},
			error => {
				console.log(`Encountered error: ${error}`);
			}
		);
		return () => {
			unsubscribe();
		};
	}, []);

	fb.analytics();

	return (
		<ErrorBoundary>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConnectedRouter history={history}>
						<HelmetProvider>
							<AppRoutes />
						</HelmetProvider>
					</ConnectedRouter>
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	);
};

export default App;
