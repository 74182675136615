import React from "react";
import { PageTitle, Well } from "../../";
import Form from "../../../../../components/Form";

import * as Styled from "../ContentFormSections.styled";

const ShareSection: React.FC = () => {
    return (
        <>
            <Styled.SectionHeader className="text-center form-section-title">
                <PageTitle>SHARE</PageTitle>
                <Form.Field
                    component={Form.Input.Checkbox}
                    label=""
                    id="share.enabled"
                    name="share.enabled"
                    switchToggle={true}
                />
            </Styled.SectionHeader>
            <Well>
                <Form.Field
                    component={Form.Input.TextArea}
                    label="Default Message"
                    id="share.defaultMessage"
                    name="share.defaultMessage"
                />
                <p>Gives viewers a shareable link to the content. Add a message that will be included in the share for supported platforms.</p>
            </Well>
        </>
    );
};

export default ShareSection;
