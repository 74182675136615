import React, { useState } from "react";
import { SketchPicker } from "react-color";
import _ from "lodash";
import { Field as FormikField, ErrorMessage, getIn, connect } from "formik";

import Error from "../Error";
import Description from "../Description";
import Input from "../Input";

import styled, { css } from "styled-components";

export interface FieldProps<T = any> {
	className?: string;
	component?: React.FC<T> | React.ComponentClass<T>;
	defaultValue?: string;
	description?: string | React.ReactNode;
	error?: string;
	fieldRef?: React.Ref<T>;
	hiddenLabel?: boolean;
	id: string;
	inline?: boolean;
	isFieldRequired?: boolean;
	isLoading?: boolean;
	label: string | React.ReactNode | null;
	name: string;
	placeholder?: string;
	type?: string;
	value?: string;
	[key: string]: any;
}

const colorBoxStyles = {
	height: 44,
	width: 44,
	borderRadius: 5,
	backgroundColor: "#000000",
	marginLeft: 10,
	borderColor: "#4a525a",
	borderWidth: 3,
	borderStyle: "solid",
	cursor: "pointer",
};

export const StyledFormikField = styled(FormikField)`
	${({ error }) => {
		return (
			error &&
			css`
				box-shadow: #e71d36 0px 0px 2px 1px, #e71d36 0px 0px 0px 3px;
				border: 1px solid #e71d36 !important;
				background-color: rgba(231, 29, 54, 0.1) !important;
			`
		);
	}}
`;

const checkIfFieldIsRequired = ({ validationSchema }: { validationSchema: any }, name: any) => {
	const fieldValidationData = validationSchema ? (getIn as any)(validationSchema.describe().fields, name) : null;
	if (fieldValidationData) {
		const { tests: fieldTests } = fieldValidationData;
		return !!fieldTests.find((t: { name: string; params: any }) => t.name === "required");
	} else {
		return false;
	}
};

const InlineField = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const {
		className,
		component = Input.Checkbox,
		description,
		hiddenLabel,
		error,
		fieldRef,
		id,
		isFieldRequired,
		isLoading,
		label,
		name,
		setValue,
		...otherProps
	} = props;
	return (
		<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<StyledFormikField
					onFocus={() => setDisplayColorPicker(true)}
					component={component}
					id={id}
					name={name}
					label={label}
					error={error}
					{...otherProps}
					fieldref={fieldRef || ref}
					style={{ width: "calc(100% - 54px)" }}
				/>
				<div
					style={{ ...colorBoxStyles, backgroundColor: props.value }}
					onClick={() => setDisplayColorPicker(true)}
				/>
			</div>
			<ErrorMessage component={Error} name={name} />
			{displayColorPicker ? (
				<div
					style={{
						position: "absolute",
						zIndex: 2,
					}}
				>
					<div
						style={{
							position: "fixed",
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						}}
						onClick={() => setDisplayColorPicker(false)}
					/>
					<SketchPicker color={props.value} onChange={({ hex }) => setValue(hex)} disableAlpha />
				</div>
			) : null}
		</div>
	);
});

const StackedField = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const {
		className,
		component = Input.Text,
		description,
		hiddenLabel,
		error,
		fieldRef,
		id,
		isFieldRequired,
		isLoading,
		label,
		name,
		setValue,
		...otherProps
	} = props;
	return (
		<div className={className || "field"}>
			<>
				{hiddenLabel ? (
					<div className="sr-only">
						<label htmlFor={id}>
							{label} {isFieldRequired && <span>*</span>}
						</label>
					</div>
				) : (
					<label htmlFor={id}>
						{label} {isFieldRequired && <span>*</span>}
					</label>
				)}
				{isLoading ? (
					<div>Loading...</div>
				) : (
					<div style={{ display: "flex", flexDirection: "row" }}>
						<StyledFormikField
							onFocus={() => setDisplayColorPicker(true)}
							component={component}
							id={id}
							name={name}
							label={label}
							fieldref={fieldRef || ref}
							error={error}
							{...otherProps}
							style={{ width: "calc(100% - 54px)" }}
						/>
						<div
							style={{ ...colorBoxStyles, backgroundColor: props.value }}
							onClick={() => setDisplayColorPicker(true)}
						/>
					</div>
				)}

				{description && <Description>{description}</Description>}
				<ErrorMessage component={Error} name={name} />
			</>
			{displayColorPicker ? (
				<div
					style={{
						position: "absolute",
						zIndex: 2,
					}}
				>
					<div
						style={{
							position: "fixed",
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						}}
						onClick={() => setDisplayColorPicker(false)}
					/>
					<SketchPicker color={props.value} onChange={({ hex }) => setValue(hex)} disableAlpha />
				</div>
			) : null}
		</div>
	);
});

const Field = React.forwardRef<HTMLDivElement, FieldProps>(
	({ inline = false, formik, isFieldRequired, ...props }, ref) => {
		if (props.component?.displayName === "CheckboxInput") {
			inline = true;
		}
		if (isFieldRequired === undefined || isFieldRequired === null) {
			isFieldRequired = checkIfFieldIsRequired(formik, props.name);
		}
		const value = _.get(formik.values, props.name);
		return inline ? (
			<InlineField
				isFieldRequired={isFieldRequired}
				{...props}
				value={value}
				setValue={(value: string) => formik.setFieldValue(props.name, value)}
			/>
		) : (
			<StackedField
				isFieldRequired={isFieldRequired}
				{...props}
				value={value}
				setValue={(value: string) => formik.setFieldValue(props.name, value)}
			/>
		);
	}
);

export default connect(Field) as React.FC<FieldProps>;
