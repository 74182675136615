import React, { useState, useContext } from "react";
import Select, { components, Styles } from "react-select";
import { isEmpty } from "lodash";

export interface OptionType {
	value: string;
	label: string;
}

interface ContentFilterProps {
	tags: any[];
	channels: any[];
	isMobile?: boolean;
	channelsPicked: string[];
	tagsPicked: string[];
	onChannelFilter: (arrayOfChannels: OptionType[]) => void;
	onTagFilter: (arrayOfTags: OptionType[]) => void;
}

interface FilterListProps {
	data: OptionType[];
	isMobile: boolean;
	hasRightMargin?: boolean;
	label: string;
	inputId: string;
	hasSelectAll?: boolean;
	invertCheckboxes?: boolean;
	values?: string[];
	onChange: (data: OptionType[]) => void;
}

interface FilterContextInterface {
	selectedOptions: OptionType[];
	inputId: string;
}

const FilterContext = React.createContext<FilterContextInterface>({
	selectedOptions: [],
	inputId: "",
});

const IndicatorSeparator = () => null;

const DropdownIndicator = ({ isOpen, ...props }: any) => {
	return (
		<components.DropdownIndicator {...props}>
			{isOpen ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
		</components.DropdownIndicator>
	);
};

const Option = ({
	isSelected,
	label,
	selectAllIsChecked,
	justClickedSelectAll,
	invertCheckboxes,
	...innerProps
}: any) => {
	const { value, setValue, options } = innerProps;

	const onSelectAllChange = () => {
		const optionsWithoutSelectAll = options.filter((o: OptionType) => o.value !== "select_all");
		if (selectAllIsChecked) {
			// we want to hide everything, so send all of the options
			setValue(optionsWithoutSelectAll, "select-option", { value, label });
		} else {
			// we want to show everything, so sent an empty array
			setValue([], "deselect-option", { value, label });
		}
	};

	return value === "select_all" ? (
		<>
			<div style={{ textAlign: "left", cursor: "pointer" }} onClick={onSelectAllChange}>
				<components.Option {...innerProps}>
					<input type="checkbox" defaultChecked={selectAllIsChecked} />{" "}
					<label style={{ margin: 0, cursor: "pointer" }}>{label}</label>
				</components.Option>
			</div>
			<hr style={{ margin: "2px", borderTop: "1px solid rgba(255,254,253,0.2)" }} />
		</>
	) : (
		<div style={{ textAlign: "left", cursor: "pointer" }}>
			<components.Option {...innerProps}>
				<input
					type="checkbox"
					defaultChecked={
						justClickedSelectAll ? selectAllIsChecked : invertCheckboxes ? isSelected : !isSelected
					}
				/>{" "}
				<label style={{ margin: 0, cursor: "pointer" }}>{label}</label>
			</components.Option>
		</div>
	);
};

const MultiValue = (props: any) => {
	return (
		<components.MultiValue {...props}>
			<span>{props.data.label}</span>
		</components.MultiValue>
	);
};

const ValueContainer = ({ label, children, onClick, ...props }: any) => {
	const { inputId } = useContext(FilterContext);

	return (
		<components.ValueContainer {...props}>
			<div onClick={onClick} style={{ width: "calc(100% - 10px)", textAlign: "left" }}>
				{label}
			</div>
			{React.Children.map(children, child => {
				if (child?.props?.id === inputId) {
					const { props: oldProps } = child;
					const newChild = { ...child, props: { ...oldProps, disabled: true } };
					return newChild;
				} else {
					return null;
				}
			})}
		</components.ValueContainer>
	);
};

const FilterList: React.FC<FilterListProps> = ({
	data,
	label,
	inputId,
	isMobile = false,
	hasRightMargin = false,
	hasSelectAll = true,
	invertCheckboxes = false,
	values = [],
	onChange,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [justClickedSelectAll, setJustClickedSelectAll] = useState(false);
	const [selectAllIsChecked, setSelectAllIsChecked] = useState(!isEmpty(values) ? false : true);

	// think of "selectedOptions" as "notSelectedOptions", since we invert the checkbox in the multi-select
	const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

	const newData = hasSelectAll ? [{ value: "select_all", label: "Select All" }, ...data] : data;
	// we will set the value of the select list based off of the default values, which is what should be passed
	// in through multiple re-renders
	const newValues = !isEmpty(values) ? newData.filter(d => values.includes(d.value)) : [];

	const customStyles: Partial<Styles<OptionType, false>> = {
		control: provided => {
			const radius = isOpen ? "0px" : "4px";
			return {
				...provided,
				border: "none",
				borderColor: "transparent",
				borderBottomLeftRadius: radius,
				borderBottomRightRadius: radius,
				backgroundColor: "#24272B",
				boxShadow: "none",
				color: "#fff",
			};
		},
		clearIndicator: provided => {
			return {
				...provided,
				paddingRight: 0,
			};
		},
		menu: provided => {
			return {
				...provided,
				borderTopLeftRadius: "0px",
				borderTopRightRadius: "0px",
				marginTop: "0px",
				boxShadow: "none",
				backgroundColor: "#24272B !important",
				color: "#fff",
			};
		},
		option: provided => {
			return {
				...provided,
				maxWidth: "100%",
				whiteSpace: "nowrap",
				overflow: "hidden",
				padding: "8px",
				backgroundColor: "#24272B !important",
			};
		},
	};

	return data ? (
		<FilterContext.Provider value={{ selectedOptions, inputId }}>
			<div className={`coverall${isOpen ? " active" : ""}`} onClick={() => setIsOpen(false)}></div>
			<div
				style={{
					width: isMobile ? "100%" : "50%",
					margin: isMobile ? "" : "5px",
					marginBottom: isMobile ? "10px" : "",
					marginRight: hasRightMargin ? "5px" : "",
					position: "relative",
					zIndex: isOpen ? 1000 : 50,
				}}
			>
				<Select
					// menuIsOpen	// for debugging
					options={newData}
					value={newValues}
					inputId={inputId}
					isMulti
					styles={customStyles}
					noOptionsMessage={() => `No ${label}`}
					closeMenuOnSelect={false}
					menuIsOpen={isOpen}
					onMenuOpen={() => setIsOpen(true)}
					onMenuClose={() => setIsOpen(false)}
					onChange={(arrayOfSelectedOptions, actionMeta) => {
						// arrayOfSelectedOptions are things to hide (opposite, since we show checked by default)
						let selectedOptionsWithoutSelectAll =
							(arrayOfSelectedOptions as OptionType[])?.filter(o => o.value !== "select_all") || [];

						const { action, option } = actionMeta as any; // the ActionMeta type doesn't have these...

						// we're keeping track of if select-all was picked, since we need to know this inside of onChange (here)
						// and we can't wait for a render cycle to happen
						let tempJustClickedSelectAll = justClickedSelectAll;
						if (tempJustClickedSelectAll) {
							setJustClickedSelectAll(false);
							tempJustClickedSelectAll = false;
						}

						if (action === "clear") {
							// nothing is selected
							onChange([]);
							setSelectAllIsChecked(true);
							setSelectedOptions([]);
						} else if (option.value === "select_all") {
							if (action === "select-option") {
								// hide all if inverted, else show all
								onChange(data);
								setSelectAllIsChecked(false);
								setSelectedOptions(data);
							} else if (action === "deselect-option") {
								// show all if inverted, else show all
								onChange([]);
								setSelectAllIsChecked(true);
								setSelectedOptions([]);
							}

							if (!tempJustClickedSelectAll) {
								setJustClickedSelectAll(true);
							}
						} else {
							if (action === "select-option") {
								// if we're selecting an option, we will make Select All unchecked
								setSelectAllIsChecked(false);
							}
							if (action === "deselect-option" && selectedOptionsWithoutSelectAll.length === 0) {
								// if we're deselecting an option and we have nothing else deselected,
								// we will make Select All checked
								setSelectAllIsChecked(true);
							}
							onChange(selectedOptionsWithoutSelectAll);
							setSelectedOptions(selectedOptionsWithoutSelectAll);
						}
					}}
					hideSelectedOptions={false}
					components={{
						DropdownIndicator: props => {
							return <DropdownIndicator isOpen={isOpen} {...props} />;
						},
						Option: props => {
							return (
								<Option
									selectAllIsChecked={hasSelectAll && isEmpty(newValues)}
									justClickedSelectAll={hasSelectAll ? justClickedSelectAll : false}
									invertCheckboxes={invertCheckboxes}
									{...props}
								/>
							);
						},
						MultiValue,
						IndicatorSeparator,
						ValueContainer: props => {
							return (
								<ValueContainer
									label={label}
									{...props}
									onClick={() => {
										setIsOpen(!isOpen);
									}}
								/>
							);
						},
					}}
				/>
			</div>
		</FilterContext.Provider>
	) : null;
};

const ContentFilter: React.FC<ContentFilterProps> = ({
	channels,
	tags,
	isMobile = false,
	channelsPicked,
	tagsPicked,
	onChannelFilter,
	onTagFilter,
}) => {
	return (
		<>
			<FilterList
				data={channels}
				label="Channels"
				inputId="channelsInput"
				values={channelsPicked}
				onChange={onChannelFilter}
				isMobile={isMobile}
				hasRightMargin
			/>
			<FilterList
				data={tags}
				label="Tags"
				inputId="tagsInput"
				values={tagsPicked}
				onChange={onTagFilter}
				isMobile={isMobile}
				hasSelectAll={false}
				invertCheckboxes={true}
			/>
		</>
	);
};

export default ContentFilter;
