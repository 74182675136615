import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/auth";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useToast } from "../../components/Toast/ToastProvider";

import { HorizontalRule, PageContainer, PageTitle, Well } from "./UI";
import Form from "../../components/Form";
import { FormikContextType, setNestedObjectValues } from "formik";
import { Button, Alert, Spinner } from "react-bootstrap";
import { IconButton } from "../../components/Buttons/IconButton";
import fb from "../../firebase";
import { ClientData, User, Client, Channel } from "../../interfaces";

import { v4 as uuidv4 } from "uuid";

import api from "../../services/api";
import { setClientData } from "../../redux/Dashboard/actions";

interface LoginData {
	email: string;
	password: string;
	passwordVerification: string;
}

interface MatchParams {
	userId: string;
	verificationCode: string;
	clientId?: string;
}

interface Error {
	code: string;
	message: string;
}

const db = fb.firestore();

const UserSignup: React.FC = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [email, setEmail] = useState<string | null>(null);
	const [signInError, setSignInError] = useState<Error>();
	const { addToast } = useToast();

	const [validCode, setValidCode] = useState<boolean | null>(null);

	const { userId, verificationCode, clientId } = useRouteMatch<MatchParams>().params;

	const history = useHistory();

	const userLoginSchema = Yup.object().shape({
		email: Yup.string().required("Email Required"),
		password: Yup.string()
			.required("Password Required")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&=~*#])[A-Za-z\d@$!%*?&=~*#]{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@,$,!,%,*,?,&,=,~,*,#)"
			),
		passwordVerification: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords Must Match")
			.required("Password Verification Required"),
	});

	const createUser = (user: firebase.User | null) => {
		if (user) {
			setIsCreating(true);
			const firebaseId = user?.uid;

			const payload = {
				firebaseId,
				email: user?.email,
				userId,
				verificationCode,
			};
			api.activateUser(payload)
				.then(data => {
					if (data.ok) {
						const { push } = history;
						user.getIdToken(true).then(() => {
							setTimeout(() => {
								if (clientId) {
									window.location.href = `/dashboard/${clientId}/verify`;
								} else {
									window.location.href = "/dashboard";
								}
							}, 1000);
						});
					} else {
						addToast("error", "Couldn't verify your user account at this time. Please contact support.");
						setIsCreating(false);
					}
				})
				.catch(error => {
					console.warn("Couldn't verify your user account -", error);
					addToast("error", "Couldn't verify your user account at this time. Please contact support.");
					setIsCreating(false);
				});
		}
	};

	useEffect(() => {
		db.collection("users")
			.where("id", "==", userId)
			.where("verificationCode", "==", verificationCode)
			.get()
			.then(docs => {
				if (docs.size === 1) {
					const doc = docs.docs[0];
					const data: User | undefined = doc.data() ? (doc.data() as User) : undefined;
					if (data && data.verificationCode !== null && data.verificationCode === verificationCode) {
						setEmail(data.email);
						if (
							fb.auth().currentUser &&
							fb.auth().currentUser?.email === data.email &&
							!fb.auth().currentUser?.isAnonymous
						) {
							createUser(fb.auth().currentUser);
						}
						setValidCode(true);
					} else {
						setValidCode(false);
					}
				} else {
					setValidCode(false);
				}
			})
			.catch(err => {
				setValidCode(false);
			});
	}, [userId, setValidCode]);

	const onSubmit = (values: LoginData) => {
		setIsSubmitting(true);
		setSignInError(undefined);
		logInWithEmail(values);
	};

	const logInWithGoogle = () => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(user => {
				createUser(user?.user);
			})
			.catch(reason => {
				setSignInError(reason);
			});
	};

	const logInWithFacebook = () => {
		var provider = new firebase.auth.FacebookAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(user => {
				createUser(user?.user);
			})
			.catch(reason => {
				setSignInError(reason);
			});
	};

	const logInWithEmail = (values: LoginData) => {
		firebase
			.auth()
			.createUserWithEmailAndPassword(values.email, values.password)
			.then(user => {
				createUser(user?.user);
			})
			.catch(error => {
				console.warn(error);
				let err = error;
				firebase
					.auth()
					.fetchSignInMethodsForEmail(values.email)
					.then(data => {
						err.message = `${err.message} Please sign in with ${data.join(", ")}`;
					})
					.finally(() => {
						setSignInError(err);
					});
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	if (validCode === null) {
		return (
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Loading...">
				<PageTitle>Loading</PageTitle>
			</PageContainer>
		);
	} else if (validCode === false) {
		return (
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Oops">
				<PageTitle>Oops!</PageTitle>
				<Well>
					<h2>Looks like that link is no longer valid</h2>
				</Well>
			</PageContainer>
		);
	}

	if (isCreating) {
		return (
			<div className="ta-center">
				<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Create Your Account">
					<PageTitle>Creating Account...</PageTitle>
				</PageContainer>
			</div>
		);
	}

	return (
		<div className="ta-center">
			<PageContainer size="sm" showHeader={false} className="no-sidebar" htmlPageTitle="Create Account">
				<PageTitle>Create Account</PageTitle>
				<Well>
					<Form
						initialValues={{ email: email, password: "" }}
						validationSchema={userLoginSchema}
						onSubmit={onSubmit}
					>
						{({ formikContext }: { formikContext: FormikContextType<LoginData> }) => {
							const { errors, values, touched } = formikContext;
							return (
								<fieldset disabled={isSubmitting}>
									{signInError && <Alert variant="danger">{signInError.message}</Alert>}

									<Form.Field label="Email" id="email" name="email" />
									<Form.Field label="Password" id="password" name="password" type="password" />
									<Form.Field
										label="Verify Password"
										id="passwordVerification"
										name="passwordVerification"
										type="password"
										className="field m-bottom"
									/>
									<Button variant="primary" className="btn-block" type="submit">
										{isSubmitting && <Spinner animation="border" className="form-button-spinner" />}{" "}
										Creat{isSubmitting ? "ing" : "e"} Account
									</Button>
									<HorizontalRule>or</HorizontalRule>
									<IconButton
										variant="dark"
										className="btn-block"
										icon="google"
										iconWeight="fab"
										onClick={logInWithGoogle}
									>
										Sign Up With Google
									</IconButton>
									<IconButton
										variant="dark"
										className="btn-block"
										icon="facebook"
										iconWeight="fab"
										onClick={logInWithFacebook}
									>
										Sign Up With Facebook
									</IconButton>
								</fieldset>
							);
						}}
					</Form>
				</Well>
			</PageContainer>
		</div>
	);
};

export default UserSignup;
