import { createLogic } from "redux-logic";
import { TRACK_AUTH_STATUS } from "../types";
import { authStatusChanged, setAppUser, frontEndLoginSucess, frontEndSetUser } from "../actions";
import firebaseApp from "../../../firebase";
import firebaseAuthApp from "../../../authFirebase";
import "firebase/auth";

const trackAuthStatusLogic = createLogic({
	type: TRACK_AUTH_STATUS,
	latest: true,
	warnTimeout: 0,
	// eslint-disable-next-line no-unused-vars
	process({ action }, dispatch, done) {
		firebaseApp.auth().onAuthStateChanged(user => {
			dispatch(authStatusChanged({ user }));
			if (user === null) {
				dispatch(setAppUser(undefined));
			}
		});
		firebaseAuthApp.auth().onAuthStateChanged(user => {
			if (user) {
				dispatch(frontEndLoginSucess(user));
			} else {
				dispatch(frontEndSetUser(undefined));
			}
		});
	},
});

export default [trackAuthStatusLogic];
