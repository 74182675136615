import React from "react";
import { FieldProps } from "formik";
import ReactSelect, { Props as ReactSelectProps, ValueType } from "react-select";

import styled, { css } from "styled-components";

// tried value: string | number; but can't figure out why it's not working
export interface OptionType {
	value: string;
	label: string;
}
export interface OptionGroupedType {
	label: string;
	options: OptionType[];
}

export interface SelectProps extends ReactSelectProps {
	data?: OptionType[];
	groupedData?: OptionGroupedType[];
	field?: FieldProps["field"];
	form?: FieldProps["form"];
	name: string;
	onChange?: (e: any) => void;
	selectValue?: string | number;
	value?: OptionType;
}

export const StyledReactSelect = styled(ReactSelect)`
	${({ error }) => {
		return (
			error &&
			css`
				box-shadow: #e71d36 0px 0px 2px 1px, #e71d36 0px 0px 0px 3px;
				background-color: rgba(231, 29, 54, 0.1) !important;

				.react-select__control {
					background-color: rgba(231, 29, 54, 0.1) !important;
				}
			`
		);
	}}
`;

const Select: React.FC<SelectProps> = ({
	data = [],
	groupedData,
	field,
	form,
	id,
	name,
	onChange,
	selectValue,
	value,
	error,
	...props
}) => {
	let realValue: OptionType | (OptionType | undefined)[] | undefined = [];

	const v = field?.value ?? value;

	if (Array.isArray(v)) {
		realValue = v.map(v =>
			groupedData
				? groupedData
						.find((group: OptionGroupedType) => {
							var blah = group.options.find((o: OptionType) => {
								return o.value === v;
							});
							if (blah) {
								return blah;
							}
						})
						?.options.find((o: OptionType) => {
							return o.value === v;
						})
				: data.find((o: OptionType) => {
						return o.value === v;
				  })
		);
	} else {
		realValue = groupedData
			? groupedData
					.find((group: OptionGroupedType) => {
						var blah = group.options.find((o: OptionType) => {
							return o.value === v;
						});
						if (blah) {
							return blah;
						}
					})
					?.options.find((o: OptionType) => {
						return o.value === v;
					})
			: data.find((o: OptionType) => {
					return o.value === v;
			  });
	}

	const realName = field?.name || name;

	return (
		<>
			<StyledReactSelect
				options={groupedData || data}
				{...field}
				name={realName}
				value={realValue || null}
				onBlur={() => {
					form?.setFieldTouched(realName, true);
				}}
				onChange={(option: OptionType | OptionType[]) => {
					if (Array.isArray(option)) {
						const values = option?.map(opt => opt.value);
						form?.setFieldValue(realName, values);
					} else {
						form?.setFieldValue(realName, option?.value);
					}
					if (onChange) {
						onChange(option);
					}
				}}
				inputId={id}
				{...props}
				className={`react-select-container ${error}`}
				classNamePrefix="react-select"
				error={error}
			/>
		</>
	);
};

export default Select;
