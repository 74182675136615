export const Books = [
	{ value: "GEN", label: "Genesis" },
	{ value: "EXO", label: "Exodus" },
	{ value: "LEV", label: "Leviticus" },
	{ value: "NUM", label: "Numbers" },
	{ value: "DEU", label: "Deuteronomy" },
	{ value: "JOS", label: "Joshua" },
	{ value: "JDG", label: "Judges" },
	{ value: "RUT", label: "Ruth" },
	{ value: "1SA", label: "1 Samuel" },
	{ value: "2SA", label: "2 Samuel" },
	{ value: "1KI", label: "1 Kings" },
	{ value: "2KI", label: "2 Kings" },
	{ value: "1CH", label: "1 Chronicles" },
	{ value: "2CH", label: "2 Chronicles" },
	{ value: "EZR", label: "Ezra" },
	{ value: "NEH", label: "Nehemiah" },
	{ value: "EST", label: "Esther" },
	{ value: "JOB", label: "Job" },
	{ value: "PSA", label: "Psalms" },
	{ value: "PRO", label: "Proverbs" },
	{ value: "ECC", label: "Ecclesiastes" },
	{ value: "SNG", label: "Song of Solomon" },
	{ value: "ISA", label: "Isaiah" },
	{ value: "JER", label: "Jeremiah" },
	{ value: "LAM", label: "Lamentations" },
	{ value: "EZK", label: "Ezekiel" },
	{ value: "DAN", label: "Daniel" },
	{ value: "HOS", label: "Hosea" },
	{ value: "JOL", label: "Joel" },
	{ value: "AMO", label: "Amos" },
	{ value: "OBA", label: "Obadiah" },
	{ value: "JON", label: "Jonah" },
	{ value: "MIC", label: "Micah" },
	{ value: "NAM", label: "Nahum" },
	{ value: "HAB", label: "Habakkuk" },
	{ value: "ZEP", label: "Zephaniah" },
	{ value: "HAG", label: "Haggai" },
	{ value: "ZEC", label: "Zechariah" },
	{ value: "MAL", label: "Malachi" },
	{ value: "MAT", label: "Matthew" },
	{ value: "MRK", label: "Mark" },
	{ value: "LUK", label: "Luke" },
	{ value: "JHN", label: "John" },
	{ value: "ACT", label: "Acts" },
	{ value: "ROM", label: "Romans" },
	{ value: "1CO", label: "1 Corinthians" },
	{ value: "2CO", label: "2 Corinthians" },
	{ value: "GAL", label: "Galatians" },
	{ value: "EPH", label: "Ephesians" },
	{ value: "PHP", label: "Philippians" },
	{ value: "COL", label: "Colossians" },
	{ value: "1TH", label: "1 Thessalonians" },
	{ value: "2TH", label: "2 Thessalonians" },
	{ value: "1TI", label: "1 Timothy" },
	{ value: "2TI", label: "2 Timothy" },
	{ value: "TIT", label: "Titus" },
	{ value: "PHM", label: "Philemon" },
	{ value: "HEB", label: "Hebrews" },
	{ value: "JAS", label: "James" },
	{ value: "1PE", label: "1 Peter" },
	{ value: "2PE", label: "2 Peter" },
	{ value: "1JN", label: "1 John" },
	{ value: "2JN", label: "2 John" },
	{ value: "3JN", label: "3 John" },
	{ value: "JUD", label: "Jude" },
	{ value: "REV", label: "Revelation" },
];
