import React from "react";
import WellHeader from "../WellHeader";

interface PageContainerProps {
	size?: "sm" | "md" | "lg";
	style?: any;
	message?: string | React.ReactElement;
	learnMoreLink?: string;
	className?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({
	children,
	size = "lg",
	message,
	learnMoreLink,
	className = "",
	...props
}) => {
	return (
		<section className={`well ${size} ${className}`} {...props}>
			{message && <WellHeader message={message} learnMoreLink={learnMoreLink} />}
			<div className="well-content">{children}</div>
		</section>
	);
};

export default PageContainer;
