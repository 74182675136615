import styled from "styled-components";

export const CardContainer = styled.div`
	display: inline-block;
	width: 325px;
	background-color: #fff;
	margin-right: 15px;

	@media screen and (max-width: 600px) {
		width: 300px;
	}
`;

export const ThumbImage = styled.div`
	display: block;
	width: 100%;
	height: 180px;
	background-size: cover;
	background-position: center center;

	transition: 0.5s ease;
	backface-visibility: hidden;
	opacity: 1;
`;

export const CardInfoBlock = styled.div`
	flex: 1;
	display: flex;
	width: 100%;
`;

export const CardInfoContainer = styled.div`
	padding: 12px;
	text-align: left;
	width: 100%;
`;

export const CardTitleWrapper = styled.div`
	width: 100%;
`;

export const CardTitle = styled.h5`
	display: flex;
	align-items: center;
	font-weight: 100;
	margin-bottom: 0px;
	font-size: 16px;

	.content-private-pill {
		align: center;
		background-color: #e7e7e7;
		color: #9b9b9b;
		border-radius: 3px;
		font-size: 11px;
		padding: 0px 5px;
		margin-left: 7px;

		i {
			margin-right: 3px;
			font-size: 9px;
		}
	}
`;

export const CardSubTitle = styled.div`
	clear: both;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 12px;
`;

export const IconContainer = styled.div`
	align-self: flex-end;
`;

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	border-radius: 4px;
	width: 30px;
	height: 30px;

	i {
		font-size: 25px;
		color: #303030;
	}

	&:hover {
		cursor: pointer;
		background-color: #e3e3e3;
	}
`;

export const ChannelList = styled.div`
	width: 100%;
	overflow: auto;
	white-space: nowrap;
	text-align: left;
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
`;

export const ChannelIconWrapper = styled.div`
	background: #4b525a;
	min-height: 18px;
	min-width: 18px;
	border-radius: 50%;
	color: #ffffff;
	font-size: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ChannelPill = styled.div`
	background: #4b525a;
	height: 18px;
	width: auto;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 9px;
	color: #ffffff;
	font-size: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
`;

export const TagsList = styled.div`
	width: 100%;
	overflow: auto;
	white-space: nowrap;
	text-align: left;
	display: flex;
`;

export const TagsIconWrapper = styled.div`
	background: #8897a6;
	min-height: 18px;
	min-width: 18px;
	border-radius: 50%;
	color: #ffffff;
	font-size: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TagPill = styled.div`
	background: #8897a6;
	height: 18px;
	width: auto;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 9px;
	color: #ffffff;
	font-size: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	// cursor: pointer;		// not currently clickable, so this property shouldn't be applied?
`;

export const NoTagsWrapper = styled.div`
	color: #8897a6;
	height: 18px;
	width: auto;
	padding: 0px 8px;
	font-size: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const GradientOverlay = styled.div`
	background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50px;
	pointer-events: none;
`;
