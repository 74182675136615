import { isFunction } from "lodash";
import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { Context } from "../SectionedPageContainer/SectionedPageContainer";

interface FormSectionProps {
	sectionKey: string;
}

const FormSection: React.FC<FormSectionProps> = ({ sectionKey, children }) => {
	const { activeSection, nextSection, setActiveSection, setNextSection } = useContext(Context);
	return (
		<>
			<CSSTransition
				in={sectionKey === activeSection && sectionKey === nextSection}
				timeout={300}
				className={sectionKey === activeSection && sectionKey === nextSection ? "" : "form-section"}
				classNames="form-section"
				onExited={() => {
					if (setActiveSection) {
						setActiveSection(nextSection);
					}
				}}
			>
				<div>
					<div data-key={sectionKey} className="form-section-wrapper">
						{isFunction(children) ? children({ setNextSection }) : children}
					</div>
				</div>
			</CSSTransition>
		</>
	);
};

export default FormSection;
