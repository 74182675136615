import React, { useState, useContext } from "react";
import ToastContainer from "../ToastContainer";

const initValues = {
	addToast: (type: string, text: string) => {},
	removeToast: () => {},
};

const ToastContext = React.createContext(initValues);

interface ToastMessage {
	type?: string;
	text?: string;
}

const ToastProvider: React.FC = ({ children }) => {
	const [toast, setToast] = useState<ToastMessage>();
	const [showToast, setShowToast] = useState(false);

	const addToast = (type: string, text: string) => {
		setToast({
			type,
			text,
		});
		setShowToast(true);
	};

	const removeToast = () => setShowToast(false);

	return (
		<ToastContext.Provider
			value={{
				addToast,
				removeToast,
			}}
		>
			<ToastContainer toast={toast} showToast={showToast} removeToast={removeToast} />
			{children}
		</ToastContext.Provider>
	);
};

const useToast = () => {
	const toastHelpers = useContext(ToastContext);
	return toastHelpers;
};

export { ToastContext, useToast };

export default ToastProvider;
