import React from "react";
import { Field as FormikField, ErrorMessage, getIn, connect } from "formik";
import styled, { css } from "styled-components";
import { get } from "lodash";

import Error from "../Error";
import Description from "../Description";
import Input from "../Input";
import {REGEX} from "../../../constants";

export interface FieldProps<T = any> {
	className?: string;
	component?: React.FC<T> | React.ComponentClass<T>;
	defaultValue?: string;
	description?: string | React.ReactNode;
	error?: boolean;
	fieldRef?: React.Ref<T>;
	hiddenLabel?: boolean;
	hidden?: boolean;
	id: string;
	inline?: boolean;
	isFieldRequired?: boolean;
	isLoading?: boolean;
	label: string | React.ReactNode | null;
	name: string;
	placeholder?: string;
	type?: string;
	value?: string;
	[key: string]: any;
}

export const StyledFormikField = styled(FormikField)`
	${({ error }) => {
		return (
			error &&
			css`
				box-shadow: #e71d36 0px 0px 2px 1px, #e71d36 0px 0px 0px 3px;
				border: 1px solid #e71d36 !important;
				background-color: rgba(231, 29, 54, 0.1) !important;
			`
		);
	}}
`;

export const sanitizeValue = (value: string, newLine = true) => {
	let sanitizedValue = value
		.replace(REGEX.DOUBLE_QUOTE, '"')
		.replace(REGEX.SINGLE_QUOTE, "'");
	if (newLine) {
		sanitizedValue = sanitizedValue.replace(REGEX.NEW_LINES, "");
	}
	return sanitizedValue;
}

export const sanitizeField = (e: React.ChangeEvent, form: any, field: any) => {
	if (!form || !field) {
		return;
	}
	const target = e.target as HTMLInputElement;
	const sanitizedValue = sanitizeValue(target.value);
	form.setFieldValue(field.name, sanitizedValue);
}

const checkIfFieldIsRequired = ({ validationSchema }: { validationSchema: any }, name: any) => {
	const fieldValidationData = validationSchema ? (getIn as any)(validationSchema.describe().fields, name) : null;
	if (fieldValidationData) {
		const { tests: fieldTests } = fieldValidationData;
		return !!fieldTests.find((t: { name: string; params: any }) => t.name === "required");
	} else {
		return false;
	}
};

const InlineField = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
	const {
		className,
		component = Input.Checkbox,
		description,
		hiddenLabel,
		error,
		fieldRef,
		hasError,
		id,
		isFieldRequired,
		isLoading,
		label,
		name,
		...otherProps
	} = props;
	return (
		<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
			<StyledFormikField
				component={component}
				id={id}
				name={name}
				label={label}
				error={hasError}
				{...otherProps}
				fieldref={fieldRef || ref}
			/>
			<ErrorMessage component={Error} name={name} />
		</div>
	);
});

const StackedField = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
	const {
		className,
		component = Input.Text,
		description,
		hiddenLabel,
		error,
		fieldRef,
		hasError,
		id,
		isFieldRequired,
		isLoading,
		label,
		name,
		...otherProps
	} = props;

	return (
		<div className={className || "field"}>
			<>
				{hiddenLabel ? (
					<div className="sr-only">
						<label htmlFor={id}>
							{label} {isFieldRequired && <span>*</span>}
						</label>
					</div>
				) : (
					<label htmlFor={id}>
						{label} {isFieldRequired && <span>*</span>}
					</label>
				)}
				{isLoading ? (
					<div>Loading...</div>
				) : (
					<StyledFormikField
						component={component}
						id={id}
						name={name}
						label={label}
						error={hasError}
						fieldref={fieldRef || ref}
						{...otherProps}
					/>
				)}

				{description && <Description>{description}</Description>}
				<ErrorMessage component={Error} name={name} />
			</>
		</div>
	);
});

const HiddenField = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
	const {
		className,
		component = Input.Text,
		description,
		hiddenLabel,
		error,
		fieldRef,
		id,
		isFieldRequired,
		isLoading,
		label,
		name,
		...otherProps
	} = props;
	return (
		<div className={className || "field"} data-tabindex="-1" id={id}>
			<ErrorMessage component={Error} name={name} />
		</div>
	);
});

const Field = React.forwardRef<HTMLDivElement, FieldProps>(
	({ inline = false, hidden = false, formik, isFieldRequired, ...props }, ref) => {
		if (props.component?.displayName === "CheckboxInput") {
			inline = true;
		}
		if (isFieldRequired == undefined || isFieldRequired == null) {
			isFieldRequired = checkIfFieldIsRequired(formik, props.name);
		}
		const errorValue = get(formik.errors, props.name);
		const touchedValue = get(formik.touched, props.name);

		const fieldHasError = errorValue && touchedValue;

		return inline ? (
			<InlineField isFieldRequired={isFieldRequired} {...props} hasError={fieldHasError} />
		) : (
			<StackedField isFieldRequired={isFieldRequired} {...props} hasError={fieldHasError} />
		);
	}
);

export default connect(Field) as React.FC<FieldProps>;
