import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import fb from "../../firebase";
import { ChatRequest } from "../../interfaces";
import { userStatusChanged } from "../../redux/Chat/actions";
import { style as chatStyle } from "./ChatConversation.styled";
import { TypedUseSelectorHook } from "../../redux";
import { useSelector } from "react-redux";

interface ContactChatAvatarProps {
	image?: string | null;
	initials: string[];
	uid?: string;
	userName?: string;
	contentId?: string;
	request: ChatRequest;
}

const rtdb = fb.database();

const ContactChatAvatar: React.FC<ContactChatAvatarProps> = ({
	image,
	initials,
	uid,
	userName,
	contentId,
	request,
}) => {
	const [isOnline, setIsOnline] = useState<boolean>(!uid);

	const dispatch = useDispatch();

	const isMuted = request.userData[request.users.filter(x => x !== uid)[0]].muted;

	useEffect(() => {
		if (contentId && uid) {
			const ref = rtdb
				.ref(`/analytics/user-presence/${contentId}`)
				.orderByChild("firebaseId")
				.equalTo(uid);

			const callback = (snap: firebase.database.DataSnapshot) => {
				if (snap.exists()) {
					const data = Object.values(snap.val()) as { state: string }[];
					const status = data.length === 0 || data.some(x => x.state === "online");
					setIsOnline(status);
					dispatch(userStatusChanged({ uid, status }));
				}
			};

			ref.on("value", callback);

			return () => {
				ref.off("value", callback);
			};
		}
	}, [uid, contentId]);

	const useTypedSelector: TypedUseSelectorHook = useSelector;
	const { images: userImageTimestamps } = useTypedSelector(store => store.channel);
	const timestamp = uid ? userImageTimestamps[uid] : null;

	return (
		<>
			<div className={`chat-avatar`} title={isOnline ? userName : `${userName} is offline`}>
				{initials[0] === "" ? <i className="fal fa-user"></i> : <>{initials}</>}
				{image && (
					<img
						src={
							image.includes("googleusercontent")
								? image
								: `https://media.online.brushfire.com/profile_images/${uid}/profile.jpg${
										timestamp ? `?ts=${timestamp}` : ""
								  }`
						}
						alt={`${userName}'s User Image`}
					/>
				)}
			</div>
			<chatStyle.ChatAvatarStatus
				className={`${request.hostChat || isOnline ? "online" : ""}${isMuted ? " muted" : ""}`}
			>
				<i className={`fas fa-${request.hostChat ? "user-circle" : isMuted ? "moon" : "circle"}`}></i>
			</chatStyle.ChatAvatarStatus>
		</>
	);
};

export default ContactChatAvatar;
