import { Field, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import firebase from "../../firebase";
import { Client, Content } from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import { fetchChannels, fetchClient, fetchContents, fetchTags } from "../../services/dataHelpers";
import { PageContainer, Well } from "./UI";

const Title = styled.h1`
	color: #fff;
`;

const ClientItem = styled.div`
	cursor: pointer;
`;

const useTypedSelector: TypedUseSelectorHook = useSelector;

const DashboardPickClient: React.FC = () => {
	const [clientList, setClientList] = useState<Client[]>([]);
	const [isGlobalUser, setIsGlobalUser] = useState<boolean>(false);
	const [loading, setLoading] = useState(true);
	const [query, setQuery] = useState("");

	const appUser = useTypedSelector(store => store.user.appUser);
	const dispatch = useDispatch();
	const history = useHistory();

	const db = firebase.firestore();

	const fieldRef = useRef();

	useEffect(() => {
		if (isGlobalUser) {
			setTimeout(() => {
				//@ts-ignore
				fieldRef.current.focus();
			}, 200);
			// Just some analytics analysis - Jared - remove this after May 15th
			// db.collection("analytics")
			// 	.doc("users-test")
			// 	.collection("c0bf47a3-82b0-498d-b9ff-58e3ec18de78")
			// 	.get()
			// 	.then(snap => {
			// 		snap.forEach(doc => {
			// 			let num = 0;
			// 			const data = doc.data() as AnalyticsUser;
			// 			data.presence.forEach(pres => {
			// 				//@ts-ignore
			// 				if (pres.activity === "enter") {
			// 					num++;
			// 				} else {
			// 					num--;
			// 				}
			// 			});
			// 			if (num !== 0) {
			// 				console.log(doc.id, num);
			// 			}
			// 		});
			// 	});

			// const clientTimezones: any = {};

			// let c = 0;
			// let m = 0;
			// db.collection("channels")
			// 	.get()
			// 	.then(channels => {
			// 		channels.forEach(channel => {
			// 			const data = channel.data() as Channel;
			// 			if (!clientTimezones[data.clientId]) {
			// 				clientTimezones[data.clientId] = {};
			// 			}
			// 			if (data.name.toLocaleLowerCase() !== "online" && data.timeZoneName !== "America/Chicago") {
			// 				clientTimezones[data.clientId][data.timeZoneName || "undefined"] = data.timeZoneName;
			// 			}
			// 		});
			// 		Object.keys(clientTimezones).forEach(key => {
			// 			const d = Object.keys(clientTimezones[key]);
			// 			if (d.length !== 1) {
			// 				console.log(key, d);
			// 				if (d.length > 1) {
			// 					c++;
			// 				}
			// 				if (d.length < 1) {
			// 					m++;
			// 				}
			// 			}
			// 		});
			// 		console.log("Clients with multiple timezones", c);
			// 		console.log("Clients with no timezones", m);
			// 	});
			// console.log("Here");
			// db.collection("analytics")
			// 	.doc("users")
			// 	.collection("faf24aaf-3f7d-42a8-a305-20fdda83fc55")
			// 	.get()
			// 	.then(snap => {
			// 		snap.forEach(doc => {
			// 			const data = doc.data() as AnalyticsUser;
			// 			console.log(data.analyticsUserId);
			// 			db.collection("analytics")
			// 				.doc("users")
			// 				.collection("faf24aaf-3f7d-42a8-a305-20fdda83fc55")
			// 				.doc(data.analyticsUserId)
			// 				.collection("user-presence")
			// 				.get()
			// 				.then(upsnap => {
			// 					let num = 0;
			// 					upsnap.forEach(updoc => {
			// 						const pres = updoc.data();
			// 						//@ts-ignore
			// 						if (pres.activity === "enter") {
			// 							num++;
			// 						} else {
			// 							num--;
			// 						}
			// 					});
			// 					if (num !== 0) {
			// 						console.log(doc.id, num);
			// 						console.count("bad");
			// 					} else {
			// 						console.log("all good :)");
			// 						console.count("good");
			// 					}
			// 				});
			// 		});
			// 	});

			//This makes sure all the channel's event Numbers are up-to-date
			// db.collection("channels")
			// 	.get()
			// 	.then(channels => {
			// 		channels.forEach(channel => {
			// 			const channelData = channel.data() as Channel;
			// 			if (channelData.brushfireEventIds) {
			// 				db.collection("channel_events")
			// 					.doc(channelData.createdAt ? moment(channelData.createdAt).format("YYYYMM") : "older")
			// 					.set(
			// 						{ [channelData.id]: channelData.brushfireEventIds.map(bfEvt => bfEvt.eventNumber) },
			// 						{ merge: true }
			// 					)
			// 					.then(() => {
			// 						console.count("Added");
			// 					})
			// 					.catch(() => {
			// 						console.count("Error");
			// 					});
			// 			}
			// 		});
			// 	});

			// db.collection("contents")
			// 	.where("createdAt", ">=", "2023-06-12T00:00:00.000Z")
			// 	.get()
			// 	.then(docs => {
			// 		const clients: string[] = [];
			// 		docs.forEach(doc => {
			// 			const data = doc.data() as Content;
			// 			if (!clients.includes(data.clientId)) {
			// 				clients.push(data.clientId);
			// 			}
			// 		});
			// 		console.log(clients);
			// 	});
		}
	}, [isGlobalUser]);

	useEffect(() => {
		if (clientList.length === 1) {
			const client = clientList[0];
			let url = "//" + window.location.host + `/dashboard/${client.id}/channels`;
			window.open(url, "_self");
		}
		//This updates the most recent content for every client in BFO
		// if (clientList.length > 1) {
		// 	clientList.forEach((client, index) => {
		// 		if (!client.mostRecentContent && client.id) {
		// 			db.collection("contents")
		// 				.where("clientId", "==", client.id)
		// 				.orderBy("createdAt", "desc")
		// 				.limit(1)
		// 				.get()
		// 				.then(docs => {
		// 					if (docs.size === 1) {
		// 						const content = docs.docs[0].data() as Content;
		// 						if (content.createdAt) {
		// 							db.collection("clients")
		// 								.doc(client.id)
		// 								.update({ mostRecentContent: content.createdAt })
		// 								.then(() => {
		// 									console.log(`${index + 1}/${clientList.length} Updated:`, client.name);
		// 								});
		// 						}
		// 					}
		// 				});
		// 		}
		// 	});
		// }
	}, [clientList]);

	if (loading && appUser) {
		if (appUser.globalRole === "owner" || appUser.globalRole === "admin") {
			setIsGlobalUser(true);
			const contentsDocRef = db.collection("clients");
			contentsDocRef.get().then(querySnapshot => {
				let tempClientsList: Client[] = [];

				querySnapshot.forEach(doc => {
					const client = doc.data() as Client;
					if (!client.versionCode) {
						tempClientsList.push(client);
					}
				});
				setClientList(tempClientsList);
			});
			setLoading(false);
		} else {
			const contentsDocRef = db.collection("clients");
			contentsDocRef
				.where("id", "in", Object.keys(appUser.clients))
				.get()
				.then(querySnapshot => {
					let tempClientsList: Client[] = [];
					querySnapshot.forEach(doc => tempClientsList.push(doc.data() as Client));
					setClientList(tempClientsList);
				});
			setLoading(false);
		}

		return null;
	}

	const onClientClick = (id: string) => {
		fetchClient(id, dispatch, appUser);
		fetchChannels(id, dispatch);
		fetchTags(id, dispatch);
		fetchContents(id, dispatch);
		history.push(`/dashboard/${id}/channels`);
	};

	let filteredList = clientList.concat();

	if (filteredList && query && query !== "") {
		let q = query.toLowerCase();
		filteredList = filteredList.filter(
			client =>
				client.name.toLowerCase().includes(q) ||
				client.city?.toLowerCase().includes(q) ||
				client.region?.toLowerCase().includes(q) ||
				client.key.toLowerCase().includes(q)
		);
	}

	filteredList.sort((a, b) => (a.name > b.name ? 1 : -1));
	const showItems = !isGlobalUser || (isGlobalUser && !isEmpty(query));

	return (
		<PageContainer size="md" className="no-sidebar m-top-large" htmlPageTitle="Choose an Organization">
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div>
					<Title>CHOOSE AN ORGANIZATION</Title>
				</div>
				<Well>
					{isGlobalUser && (
						<Formik initialValues={{ query: "" }} onSubmit={() => Promise.resolve(true)}>
							{({ handleSubmit, values }) => {
								setQuery(values.query);
								return (
									<form onSubmit={handleSubmit}>
										<div className="field">
											<Field
												type="text"
												name="query"
												placeholder="Type To Search"
												className="field margin-bottom"
												innerRef={fieldRef}
											/>
										</div>
									</form>
								);
							}}
						</Formik>
					)}
					{showItems && (
						<div className="mt-4">
							{filteredList.map(({ id, name, city, country }) => {
								return (
									<ClientItem className="btn-row" key={id} onClick={() => onClientClick(id)}>
										<i className="fal fa-chevron-right" />
										<h2 style={{ color: "white" }}>{name}</h2>
										{city && country && <p style={{ color: "white" }}>{`${city} ${country}`}</p>}
									</ClientItem>
								);
							})}
						</div>
					)}
				</Well>
			</div>
		</PageContainer>
	);
};

export default DashboardPickClient;
