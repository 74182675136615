// more plugins can be found here https://www.tiny.cloud/docs/plugins/
export const PLUGIN_LIST = [
	"advlist",
	"anchor",
	// "autolink",
	// "charmap",
	"code",
	"fullscreen",
	"help",
	"hr",
	"image",
	// "insertdatetime",
	"link",
	"lists",
	// "media",
	// "paste",
	"preview",
	// "print",
	"searchreplace",
	// "spellchecker",
	// "table",
	// "visualblocks",
	// "wordcount",
];

// more toolbar controls can be found here https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols
export const TOOLBAR = "formatselect alignleft aligncenter alignright bold italic link";

export const defaultConfig = {
	menubar: false,
	plugins: PLUGIN_LIST,
	toolbar: TOOLBAR,
	skin: "",
	relative_urls: false,
	remove_script_host: false,
};
