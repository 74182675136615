import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
//@ts-ignore
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import moment from "moment";

import { TypedUseSelectorHook } from "../../../../redux";
import { timeZoneAbbreviation } from "../../../../utils/timezones";
import { isChurchX } from "../../../../utils/churchx";
import { ContentType } from "../../../../interfaces";

import BFOBadge from "./bfBadge.svg";
import BFOnline from "./bfOnline.svg";
import ChurchXBadge from "./churchxBadge.svg";
import ChurchXLogo from "./churchxLogo.svg";
import BFOLogoMobile from "./bfOnlineMobile.svg";
import ChurchXMobile from "./church-x.svg";
import userHasRole from "../../../../services/userHasRole";

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
	add: any;
}

interface DashboardHeaderProps {
	expanded: boolean;
	toggleSideMenu: () => void;
}

const Dashboard: React.FC<DashboardHeaderProps> = ({ expanded, toggleSideMenu }) => {
	const user = useTypedSelector(store => store.user);
	const dashboard = useTypedSelector(store => store.dashboard);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const ref = useRef<any>();

	const history = useHistory();

	useEffect(() => {
		if (drawerVisible) {
			document.body.classList.add("nav-open");
			disableBodyScroll(ref.current);
		} else {
			document.body.classList.remove("nav-open");
			enableBodyScroll(ref.current);
		}
	}, [drawerVisible]);

	const { params, path } = useRouteMatch<MatchParams>();

	const isClientPage = params.clientId ? true : false;
	//const isChannelPage = params.clientId && params.channelId ? true : false;
	//const isContentPage = params.clientId && params.channelId && params.contentId ? true : false;
	const isChannelAddPage = path.includes("add") && path.includes("channels") ? true : false;
	const isContentAddPage = path.includes("add") && path.includes("contents") ? true : false;
	const isChannelCopyPage = path.includes("copy") && path.includes("channels") ? true : false;
	const isContentCopyPage = path.includes("copy") && path.includes("contents") ? true : false;
	//const isHostManagerPage = path.includes("host") && isContentPage;

	const uid = user.user?.uid;
	const timestamp = (new Date).getTime();

	const isChannelPage = path.includes("channels");
	const isContentPage = path.includes("contents");
	const isUserPage = path.includes("users");

	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;
	const isHost = dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["host"]) : false;

	const showSide = path !== "/dashboard" && path !== "/dashboard/upcoming" && path !== "/dashboard/report";

	const buildSubtitle = (content: any) => {
		const { contentType, startsAt } = content;
		let startsAtString = "";

		if (startsAt) {
			startsAtString = `${moment(startsAt).format("dddd, MMMM D, YYYY h:mm A")} (${timeZoneAbbreviation(
				startsAt
			)})`;
		}

		if (contentType === ContentType.onDemand) {
			return "On-Demand";
		} else if (contentType === ContentType.live) {
			return `${startsAtString} (Live)`;
		} else {
			return `${startsAtString} (Sim-live)`;
		}
	};

	let clientId = "";
	if (dashboard.clientId) {
		clientId = dashboard.clientId;
	} else if (user.appUser?.clients && Object.keys(user.appUser?.clients).length > 0) {
		clientId = Object.keys(user.appUser?.clients)[0];
	} else if (user.appUser?.globalRole) {
		clientId = "9f3efc05-47df-4120-902d-163222f50ced";
	}

	return (
		<>
			<header
				className={`dashboard-header ${drawerVisible ? "active" : ""} ${expanded ? "expanded" : ""}`}
				ref={ref}
			>
				<Row>
					<Col xs={7} style={{ paddingLeft: "5px" }}>
						<div className={showSide ? "show-mobile" : ""}>
							{isChurchX ? (
								<div className="logo">
									<a href="/dashboard">
										<img src={ChurchXMobile} alt="Church X Logo" />
									</a>
								</div>
							) : (
								<>
									<div className="logo">
										<a href="/dashboard">
											<img src={BFOLogoMobile} alt="Brushfire Online Logo Mobile" />
										</a>
									</div>
								</>
							)}
						</div>
						<div
							className={`hide-mobile page-header ellipsis ${isContentPage ? "content-page-header" : ""}`}
						>
							{isChannelPage && dashboard?.channelData && params.channelId && !isChannelCopyPage && (
								<span>{dashboard.channelData.name || "Channels"}</span>
							)}
							{isChannelPage && dashboard?.channelData && params.channelId && isChannelCopyPage && (
								<span>{`Duplicate ${dashboard.channelData.name}`}</span>
							)}
							{isChannelAddPage && <span>Add A Channel</span>}
							{isContentPage && dashboard?.contentData && params.contentId && !isContentCopyPage && (
								<>
									<span>{dashboard.contentData.name || "Contents"}</span>
									<p className="dashboard-header-subtitle">{buildSubtitle(dashboard.contentData)}</p>
								</>
							)}
							{isContentPage && dashboard?.contentData && params.contentId && isContentCopyPage && (
								<>
									<span>{`Duplicate ${dashboard.contentData.name}`}</span>
									<p className="dashboard-header-subtitle">{buildSubtitle(dashboard.contentData)}</p>
								</>
							)}
							{isContentAddPage && <span>Add Content</span>}
						</div>
					</Col>
					<Col xs={5} className="ta-right">
						<span className="org-title ellipsis hide-mobile">{dashboard?.clientData?.name}</span>
						{user?.user && !user?.user.isAnonymous && (
							<>
								<div
									className="user-photo"
									style={{ backgroundImage: `url("https://media.online.brushfire.com/profile_images/${uid}/profile.jpg?timestamp=${timestamp})` }}
									onClick={() => {
										window.location.href = `/dashboard/${clientId}/profile`;
									}}
								></div>
							</>
						)}
						<div className={`hamburger`} onClick={() => setDrawerVisible(!drawerVisible)}>
							<i className={`far fa-lg fa-${drawerVisible ? "times" : "bars"}`}></i>
						</div>
					</Col>
				</Row>
			</header>
			{showSide && (
				<aside className={`side-menu ${expanded ? "expanded" : ""} ${drawerVisible ? "open" : ""}`}>
					<div className="logo-wrapper">
						{isChurchX ? (
							<>
								<div className="logo churchx hide-mobile">
									<a href="/dashboard">
										<img src={ChurchXLogo} alt="Church X Logo" />
									</a>
								</div>
								<div className="logo-badge hide-mobile">
									<a href="/dashboard">
										<img src={ChurchXBadge} alt="Church X Logo Badge" />
									</a>
								</div>
							</>
						) : (
							<>
								<div className="logo-badge hide-mobile">
									<a href="/dashboard">
										<img src={BFOBadge} alt="Brushfire Online Logo Badge" />
									</a>
								</div>
								<div className="logo hide-mobile">
									<a href="/dashboard">
										<img src={BFOnline} alt="Brushfire Online Logo" />
									</a>
								</div>
							</>
						)}
					</div>
					<div className={`top ${isAdmin ? "admin" : ""}`}>
						<a
							className={`side-menu-button ${expanded ? "expanded" : ""} ${
								isChannelPage ? "active" : ""
							}`}
							href={`/dashboard/${dashboard.clientId}/channels`}
						>
							<div className="side-menu-icon">
								<i className="far fa-list 2x"></i>
							</div>
							<div className="side-menu-text">Channels</div>
						</a>
						<br />
						<a
							className={`side-menu-button ${expanded ? "expanded" : ""} ${
								isContentPage ? "active" : ""
							}`}
							href={`/dashboard/${dashboard.clientId}/contents`}
						>
							<div className="side-menu-icon">
								<i className="fal fa-tv-alt 2x"></i>
							</div>
							<div className="side-menu-text">Content</div>
						</a>
						<br />
						{!isHost && (
							<a
								className={`side-menu-button ${expanded ? "expanded" : ""} ${
									isUserPage ? "active" : ""
								}`}
								href={`/dashboard/${dashboard.clientId}/users`}
							>
								<div className="side-menu-icon">
									<i className={`fal fa-users 2x`}></i>
								</div>
								<div className="side-menu-text">Users</div>
							</a>
						)}
					</div>
					<div className={`bottom`}>
						{isAdmin && (
							<a
								className={`side-menu-button ${expanded ? "expanded" : ""}`}
								href={`/dashboard/${dashboard.clientId}`}
							>
								<div className="side-menu-icon">
									<i className={`fal fa-cog 2x`}></i>
								</div>
								<div className="side-menu-text">Settings</div>
							</a>
						)}

						<div
							className={`side-menu-button hide-mobile ${expanded ? "expanded" : ""}`}
							onClick={() => toggleSideMenu()}
						>
							<div className="side-menu-icon">
								<i className={`fal fa-caret-square-${expanded ? "left" : "right"} 2x`}></i>
							</div>
							<div className="side-menu-text">Minimize</div>
						</div>
					</div>
					<div className="side-menu-mobile-footer show-mobile">
						<a className={`side-menu-button`} href={`/dashboard`}>
							<div className="side-menu-text">{dashboard.clientData?.name || ""}</div>
						</a>
						<a className={`show-mobile`} href={`/dashboard/${clientId}/profile`}>
							{user?.user && !user?.user.isAnonymous && (
								<>
									<div
										className="user-photo"
										style={{ backgroundImage: `url("${user.user?.photoURL}")` }}
										onClick={() => {
											window.location.href = `/dashboard/${clientId}/profile`;
										}}
									></div>
								</>
							)}
						</a>
					</div>
				</aside>
			)}
		</>
	);
};

const styles = {
	iconStyles: {
		marginRight: 20,
		color: "white",
	},
};
export default Dashboard;
