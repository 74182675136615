import styled from "styled-components";

const CardContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	background-color: #ffffff;
	margin-bottom: 15px;
	height: 71px;
`;

const ThumbImageContainer = styled.div`
	height: 100%;
	min-width: 125px;
	max-width: 125px;

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}

	@media screen and (max-width: 992px) {
		min-width: 110px;
		max-width: 120px;
	}

	@media screen and (max-width: 600px) {
		min-width: 71px;
		max-width: 71px;
	}
`;

const ThumbImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
`;

const CardInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: calc(100% - 125px);
`;

const CardPrivatePill = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e7e7e7;
	color: #9b9b9b;
	border-radius: 3px;
	font-size: 11px;
	padding: 0px 5px;
	margin-left: 10px;

	i {
		margin-right: 3px;
		font-size: 9px;
	}
`;

const NameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: calc(100% - 130px);
`;

const Name = styled.h2`
	margin-left: 14px;
	margin-bottom: 0px;
	font-weight: 100;
	font-size: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	opacity: 1;

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`;

const IconContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	border-radius: 4px;
	padding: 5px 15px;
	height: 42px;
	width: 42px;
	margin-right: 14px;

	i {
		font-size: 22px;
		color: #303030;
	}

	.view-content-icon {
		font-size: 19px;
	}

	&:hover {
		cursor: pointer;
		background-color: #e3e3e3;
	}
`;

export {
	CardContainer,
	ThumbImageContainer,
	ThumbImage,
	CardInnerContainer,
	CardPrivatePill,
	NameWrapper,
	Name,
	IconContainer,
	IconWrapper,
};
