import React from "react";
import PropTypes from "prop-types";
import ChatLoginStep0 from "./ChatLoginStep0";
import ChatEmailLogin from "./ChatEmailLogin";
import ChatForgotPassword from "./ChatForgotPassword";
import ChatRegister from "./ChatRegister";
import FrontEndLogin from "../FrontEndLogin/FrontEndLogin";
import { style } from "./styles";
import { IconButton } from "../Buttons/IconButton";
import { showLoginModal } from "../../redux/User/actions";

// const style = {
// 	ChatLoginBlock: styled.div``,
// 	ChatLoginInput: styled.input``,
// 	ChatLoginButton: styled.button``
// };

export default class ChatLogin extends React.Component {
	constructor(props) {
		super(props);
		this.onLogin = this.props.onLogin;
		this.renderState = {
			step0: this.renderStep0.bind(this),
			emailLogin: this.renderEmailLogin.bind(this),
			forgotPassword: this.renderForgotPassword.bind(this),
			register: this.renderRegister.bind(this),
		};
		this.state = {
			chatId: this.props.chatId,
			isModerator: this.props.isModerator,
			currRenderState: this.renderState.step0,
			dispatch: props.dispatch,
			setUserName: props.setUserName,
			contentId: props.contentId,
		};
	}

	async onStep0(providerName, extraData) {
		if (providerName === "email") {
			this.setState({ currRenderState: this.renderState.emailLogin });
			return;
		}

		const data = {
			providerName,
			...extraData,
			chatId: this.state.chatId,
			analyticsUserId: this.props.analyticsUserId,
			contentId: this.state.contentId,
			content: this.props.content,
		};
		// await emit(
		// 	CHAT_SIGNIN,
		// 	{ data, dispatch: this.state.dispatch, setUserName: this.state.setUserName },
		// 	this.state.chatId
		// );
		// if (data["success"]) {
		// 	this.onLogin({
		// 		userName: data.user,
		// 		email: data.email,
		// 	});
		// }
	}

	async onStepEmailLogin(status) {
		if (status === "back") {
			this.setState({ currRenderState: this.renderState.step0 });
			return;
		}
		if (status === "forgot") {
			this.setState({ currRenderState: this.renderState.forgotPassword });
			return;
		}
		if (status === "register") {
			this.setState({ currRenderState: this.renderState.register });
			return;
		}
		// i think this can be done better, but it's a big change.
		if (typeof status === "object" && status.user) {
			this.onLogin(status.user);
		}
	}

	async onForgotPassword(status) {
		console.log(status);
		if (status === "sent recovery email") {
			this.setState({ currRenderState: this.renderState.emailLogin });
			return;
		}
	}

	async onRegister(status) {
		console.log(status);
		if (status === "success") {
			this.setState({ currRenderState: this.renderState.emailLogin });
			return;
		}
	}

	render() {
		return this.state.currRenderState();
	}

	renderRegister() {
		const props = { onAction: this.onRegister.bind(this) };
		return (
			<style.ChatLoginBlock>
				<ChatRegister {...props} />
			</style.ChatLoginBlock>
		);
	}

	renderForgotPassword() {
		const props = { onAction: this.onForgotPassword.bind(this) };
		return (
			<style.ChatLoginBlock>
				<ChatForgotPassword {...props} />
			</style.ChatLoginBlock>
		);
	}

	renderEmailLogin() {
		const props = {
			chatId: this.state.chatId,
			onAction: this.onStepEmailLogin.bind(this),
		};
		return (
			<style.ChatLoginBlock>
				<ChatEmailLogin {...props} />
			</style.ChatLoginBlock>
		);
	}

	renderStep0() {
		const props = {
			onAction: this.onStep0.bind(this),
			isDirect: this.props.isDirect,
			enableAnonymous: this.props.enableAnonymous,
		};
		return (
			<style.ChatLoginBlock>
				<i className="fal fa-comments fa-4x"></i>
				<br />
				<IconButton
					variant={"dark"}
					className="btn-block"
					icon={"sign-in-alt"}
					iconWeight="fas"
					onClick={() => {
						this.props.dispatch(showLoginModal(true));
					}}
				>
					Join The Conversation
				</IconButton>
			</style.ChatLoginBlock>
		);
	}
}

ChatLogin.propTypes = {
	chatId: PropTypes.string.isRequired,
	isModerator: PropTypes.bool.isRequired,
	onLogin: PropTypes.func.isRequired,
};
