import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactMomentCountDown from "react-moment-countdown";
import styled from "styled-components";
import { Channel, Content, CustomPanel } from "../../interfaces";
import { getBrowserTime, isHappeningNow, isOpenButNotStarted, isScheduledForFuture } from "../../pages/Channel/utils";
import Links from "../Links";
import Video from "./Video";
import VideoInfoHeader from "./VideoInfoHeader";
import { useMediaQuery } from "react-responsive";

interface VideoContainerProps {
	contentData: Content;
	channelData?: Channel;
	currentTime: string;
	isRightPanelExpanded: boolean;
	onVideoEnd: () => void;
	onChatClickWhenChatIsClosed?: () => void;
	onChatClick?: () => void;
	selectExistingHostChat: () => void;
	customPanel: CustomPanel | undefined;
	contentValidUntil?: string;
}

const ImageContainer = styled.div`
	background-color: #000;
	position: relative;

	&:after {
		display: block;
		content: "";
		padding-bottom: 56.25%;
	}
`;

const ContentImage = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;

export const VideoContainer: React.FC<VideoContainerProps> = ({
	contentData,
	channelData,
	currentTime,
	onVideoEnd,
	onChatClickWhenChatIsClosed,
	onChatClick,
	selectExistingHostChat,
	customPanel,
	contentValidUntil,
}) => {
	const [endUpdate, setEndUpdate] = useState(0);
	const [closesUpdate, setClosesUpdate] = useState(0);
	const [tickUpdate, setTickUpdate] = useState(0);
	const [time, setTime] = useState(currentTime);
	const { name, links } = contentData;

	useEffect(() => {
		const browserMoment = getBrowserTime();
		setTime(browserMoment.toISOString());
	}, [tickUpdate]);

	useEffect(() => {
		const browserMoment = getBrowserTime();
		setTime(browserMoment.toISOString());
	}, [endUpdate]);

	useEffect(() => {
		const browserMoment = getBrowserTime();
		setTime(browserMoment.toISOString());
	}, [closesUpdate]);

	const now = moment(time);
	const isOpen = isHappeningNow(now, contentData);
	const isInFuture = isScheduledForFuture(now, contentData);
	const hasntStartedYet = isOpenButNotStarted(now, contentData);
	const isMobileAndTablet = useMediaQuery({ query: "(max-width: 992px)" });

	const showLive = isOpen && !hasntStartedYet;

	return (
		<div style={styles.videoContainer}>
			{isInFuture ? (
				<ImageContainer>
					<ContentImage src={contentData.thumbImage} />
				</ImageContainer>
			) : (
				<>
					{contentData.closesAt && (
						<div style={{ display: "none" }}>
							video closes in{" "}
							<ReactMomentCountDown
								key={moment(contentData.closesAt).toISOString()}
								toDate={moment(contentData.closesAt)}
								onTick={(countdown: number) => { }}
								onCountdownEnd={onVideoEnd}
							/>
						</div>
					)}
					<Video content={contentData} />
				</>
			)}
			{contentData.startsAt && (
				<>
					<ReactMomentCountDown
						key={contentData.id}
						toDate={moment(contentData.startsAt)}
						onTick={(countdown: number) => {
							const browserTime = getBrowserTime();
							if (
								browserTime.isAfter(moment.utc(contentData.opensAt)) &&
								browserTime.isBefore(moment.utc(contentData.startsAt))
							) {
								setTickUpdate(v => {
									return v + 1;
								});
							}
						}}
						onCountdownEnd={() => {
							setEndUpdate(v => {
								return v + 1;
							});
						}}
						className="d-none"
					/>
					<ReactMomentCountDown
						key={moment(contentData.closesAt).toISOString()}
						toDate={moment(contentData.closesAt)}
						onTick={(countdown: number) => {
							const browserTime = getBrowserTime();
							if (
								browserTime.isAfter(moment.utc(contentData.startsAt)) &&
								browserTime.isBefore(moment.utc(contentData.closesAt))
							) {
								setTickUpdate(v => {
									return v + 1;
								});
							}
						}}
						onCountdownEnd={() => {
							setClosesUpdate(v => {
								return v + 1;
							});
						}}
						className="d-none"
					/>
				</>
			)}
			{!isMobileAndTablet && (
				<VideoInfoHeader
					name={name}
					showLive={showLive}
					startsAt={moment(contentData.startsAt).toISOString()}
					duration={contentData.duration}
					content={contentData}
					channel={channelData}
					isOpen={isOpen}
					contentValidUntil={contentValidUntil}
				/>
			)}
			{!isEmpty(links) && (
				<Links
					mobile={false}
					content={contentData}
					onChatClickWhenChatIsClosed={onChatClickWhenChatIsClosed}
					onChatClick={onChatClick}
					selectExistingHostChat={selectExistingHostChat}
					customPanel={customPanel}
				/>
			)}
		</div>
	);
};

const styles = {
	videoContainer: {
		backgroundColor: "#000",
		display: "block",
	},
};
