import React from "react";

import * as Styled from "./InteractionsSection.styled";
import * as LinksStyled from "../Links/LinksSection.styled";

interface HeaderProps {
	addInteractionClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({ addInteractionClick }) => {
	return (
		<Styled.Header>
			<Styled.SectionHeader>INTERACTIONS</Styled.SectionHeader>
			<LinksStyled.ButtonContainer isExpanded={false}>
				<LinksStyled.Button
					isExpanded={false}
					onClick={addInteractionClick}
					style={{ minWidth: 50, width: 50 }}
				>
					<i className="far fa-plus"></i>
				</LinksStyled.Button>
			</LinksStyled.ButtonContainer>
		</Styled.Header>
	);
};
