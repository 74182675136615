import styled from "styled-components";

export const StyledChannelContainer = styled.div`
	width 100%;
	margin-top: 20px;
`;

export const Title = styled.h1`
	color: #fff;
	font-weight: 100;
`;

export const AddButton = styled.i`
	cursor: pointer;
	color: #fff;
	font-size: 25px;
`;

export const SmallTitle = styled.small`
	color: #fff;
	font-weight: 300;
	display: flex;
	margin-bottom: 0px;
`;
