import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GlobalLoader from "../components/GlobalLoader";
import ChannelForm from "../pages/Dashboard/Channels/ChannelForm";
import ChannelsList from "../pages/Dashboard/Channels/ChannelsList";
import ClientForm from "../pages/Dashboard/Clients/ClientForm";
import DashboardClientUserList from "../pages/Dashboard/ClientUserList";
import ContentAnalytics from "../pages/Dashboard/Contents/Analytics";
import ChatExport from "../pages/Dashboard/Contents/ChatExport";
import ClientReport from "../pages/Dashboard/Contents/ClientReport";
import ContentsList from "../pages/Dashboard/Contents/ContentsList";
import ContentForm from "../pages/Dashboard/Contents/Form/Form";
import HostManager from "../pages/Dashboard/Contents/HostManager";
import UpcomingContent from "../pages/Dashboard/Contents/Upcoming";
import UpcomingPaywallContent from "../pages/Dashboard/Contents/UpcomingPaywall";
import DashboardLogic from "../pages/Dashboard/DashboardLogic";
import DashboardLogin from "../pages/Dashboard/Login";
import DashboardRedirect from "../pages/Dashboard/DashboardRedirect";
import PasswordReset from "../pages/Dashboard/PasswordReset";
import DashboardPickClient from "../pages/Dashboard/PickClient";
import GlobalUsers from "../pages/Dashboard/Users/GlobalUsers";
import NukeUser from "../pages/Dashboard/Users/NukeUser";
import UserForm from "../pages/Dashboard/Users/UserForm";
import Unauthorized from "../pages/Unauthorized";
import ForcedRefresh from "../pages/Dashboard/ForcedRefresh";
import { TypedUseSelectorHook } from "../redux";
import userHasRole from "../services/userHasRole";
import ProcessBigAnalytics from "../pages/Dashboard/Contents/Analytics/ProcessBigAnalytics";
import { useIntercom } from "../hooks/useIntercom";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const DashboardRoutes: React.FC = props => {
	const user = useTypedSelector(store => store.user);
	const dashboard = useTypedSelector(store => store.dashboard);
	useIntercom();

	document.body.className = "dashboard";

	if (process.env.NODE_ENV === "production") {
		const domainExceptions = ["online.brushfire.com", "churchx.online", "staging-brushfire-online.web.app"];
		if (!domainExceptions.includes(window.location.host)) {
			let url = window.location.href.replace(window.location.host, "online.brushfire.com/dashboard");
			window.open(url, "_self");
		}
	}

	if (user && (user.user || user.isLoading) && user.isLoadingAppUser) {
		return <GlobalLoader isActive />;
	} else if (user.appUser === undefined) {
		if (window.location.href.includes("/forgot")) {
			return <PasswordReset />;
		} else {
			return <DashboardLogin />;
		}
	}

	const isHost = dashboard.clientId ? userHasRole(dashboard.clientId, user.appUser, ["host"]) : false;
	const isAdmin = dashboard.clientId
		? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"], dashboard.channelId)
		: false;
	const appUserWhitelist = dashboard.clientId ? user.appUser?.whitelist?.[dashboard.clientId] : null;
	const hasContentAccess =
		appUserWhitelist && dashboard.contentData
			? dashboard.contentData.channelIds.some(x => appUserWhitelist.includes(x))
			: true;

	if (process.env.NODE_ENV === "production") {
		const domainExceptions = ["online.brushfire.com", "churchx.online", "staging-brushfire-online.web.app"];
		if (!domainExceptions.includes(window.location.host)) {
			let url = window.location.href.replace(window.location.host, "online.brushfire.com");
			let redirect = window.open(url, "_self");

			if (!redirect) {
				//fallback if redirect is blocked by browser.
				return <DashboardRedirect url={url} />;
			}
		}
	}

	return (
		<>
			<GlobalLoader
				isActive={
					dashboard.clientDataLoading ||
					false ||
					dashboard.channelDataLoading ||
					false ||
					dashboard.contentDataLoading ||
					false
				}
			/>
			<div className="dashboard-container">
				<Router>
					<Switch>
						<Route path="/dashboard/:clientId/contents/copy/:contentId" component={DashboardLogic} />
						<Route path="/dashboard/:clientId/contents/:contentId/host" component={DashboardLogic} />
						<Route path="/dashboard/:clientId/contents/:contentId/chatExport" component={DashboardLogic} />
						<Route path="/dashboard/:clientId/contents/:contentId" component={DashboardLogic} />

						<Route path="/dashboard/:clientId/channels/copy/:channelId" component={DashboardLogic} />
						<Route path="/dashboard/:clientId/channels/:channelId" component={DashboardLogic} />
						<Route path="/dashboard/:clientId" component={DashboardLogic} />
					</Switch>
				</Router>
				<Router>
					<Switch>
						{user.appUser.globalRole === "owner" && (
							<Route path="/dashboard/global" component={GlobalUsers} />
						)}
						{(user.appUser.globalRole === "owner" || user.appUser.globalRole === "admin") && (
							<Route path="/dashboard/add" component={ClientForm} />
						)}
						{(user.appUser.globalRole === "owner" || user.appUser.globalRole === "admin") && (
							<Route path="/dashboard/upcoming" component={UpcomingContent} />
						)}
						{(user.appUser.globalRole === "owner" || user.appUser.globalRole === "admin") && (
							<Route path="/dashboard/upcomingPaywall" component={UpcomingPaywallContent} />
						)}
						{user.appUser.globalRole === "owner" && (
							<Route path="/dashboard/report" component={ClientReport} />
						)}
						{user.appUser.globalRole === "owner" && <Route path="/dashboard/nuke" component={NukeUser} />}
						{user.appUser.globalRole === "owner" && (
							<Route path="/dashboard/forcedRefresh" component={ForcedRefresh} />
						)}
						<Route
							path="/dashboard/:clientId/contents/copy/:contentId"
							component={isAdmin && hasContentAccess ? ContentForm : Unauthorized}
						/>

						<Route
							path="/dashboard/:clientId/contents/add"
							component={isAdmin ? ContentForm : Unauthorized}
						/>
						<Route
							path="/dashboard/:clientId/contents/:contentId/host"
							component={hasContentAccess ? HostManager : Unauthorized}
						/>
						<Route
							path="/dashboard/:clientId/contents/:contentId/chatExport"
							component={hasContentAccess ? ChatExport : Unauthorized}
						/>
						{user.appUser.globalRole === "owner" && (
							<Route
								path="/dashboard/:clientId/contents/:contentId/biganalytics"
								component={ProcessBigAnalytics}
							/>
						)}
						<Route
							path="/dashboard/:clientId/contents/:contentId/analytics"
							component={hasContentAccess ? ContentAnalytics : Unauthorized}
						/>
						<Route
							path="/dashboard/:clientId/contents/:contentId"
							component={isAdmin && hasContentAccess ? ContentForm : Unauthorized}
						/>
						<Route path="/dashboard/:clientId/contents" component={ContentsList} />

						<Route
							path="/dashboard/:clientId/channels/copy/:channelId"
							component={isAdmin ? ChannelForm : Unauthorized}
						/>
						<Route
							path="/dashboard/:clientId/channels/add"
							component={isAdmin ? ChannelForm : Unauthorized}
						/>
						<Route
							path="/dashboard/:clientId/channels/:channelId"
							component={isAdmin ? ChannelForm : Unauthorized}
						/>
						<Route path="/dashboard/:clientId/channels" component={ChannelsList} />
						<Route path="/dashboard/:clientId/users/add" component={UserForm} />
						<Route path="/dashboard/:clientId/profile" component={UserForm} />
						<Route path="/dashboard/profile" component={UserForm} />
						<Route path="/dashboard/:clientId/users/:userId" component={isHost ? Unauthorized : UserForm} />
						<Route
							path="/dashboard/:clientId/users"
							component={isHost ? Unauthorized : DashboardClientUserList}
						/>

						<Route path="/dashboard/:clientId/verify" render={props => <ClientForm {...props} verify />} />
						<Route path="/dashboard/:clientId" component={isAdmin ? ClientForm : Unauthorized} />
						<Route path="/dashboard" component={DashboardPickClient} />
					</Switch>
				</Router>
			</div>
		</>
	);
};

export default DashboardRoutes;
