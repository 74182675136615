import React, { useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import Form, { FormContext } from "../../../components/Form";
import api from "../../../services/api";
import { PageContainer, PageTitle, Well } from "../UI";

// http://localhost:3000/dashboard/:clientId/channels/:channelId

interface NukeUserProps {
	location: any;
}

const NukeUser: React.FC<NukeUserProps> = ({ location }) => {
	const [isSaving, setIsSaving] = useState(false);
	const [serverError, setServerError] = useState<null | string>(null);
	const [success, setSuccess] = useState<null | string>(null);

	const onSubmit = async (values: any) => {
		if (!isSaving) {
			setIsSaving(true);
			setServerError(null);
			api.nukeUser(values.email)
				.then(res => {
					if (res.status === 200) {
						setSuccess("User Nuked!");
					} else {
						setServerError("Failed");
					}
				})
				.catch(() => {
					setServerError("Failed");
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	const initialValues = {
		email: "",
	};

	const userSchema = Yup.object().shape({
		email: Yup.string()
			.required("Email Required")
			.email("Email Invalid"),
	});

	return (
		<PageContainer size="md" htmlPageTitle="Organization Users">
			<div className="text-center">
				<PageTitle>Nuke User</PageTitle>
			</div>

			<Form initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
				{({ formikContext, extraData }: FormContext<any>) => {
					const { values } = formikContext;
					return (
						<>
							{success && <Alert variant="success">{success}</Alert>}
							{serverError && <Alert variant="danger">{serverError}</Alert>}
							{extraData.showSummary && formikContext.errors && (
								<Form.Summary errors={formikContext.errors} />
							)}
							<Well>
								<fieldset disabled={isSaving}>
									<Form.Field label="Email" id="email" name="email" />
								</fieldset>
								<Button
									variant={!formikContext.isValid ? `danger` : `primary`}
									className="btn-block text-center"
									type="submit"
									disabled={!formikContext.isValid || isSaving}
								>
									{isSaving && <Spinner animation="border" className="form-button-spinner" />} Nuke
									User (Can't be undone)
								</Button>
							</Well>
						</>
					);
				}}
			</Form>
		</PageContainer>
	);
};

export default NukeUser;
