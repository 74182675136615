import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import * as Styled from "./LinksSection.styled";

interface LinkSectionHeaderProps {
	length: number;
	addUrlClick: () => void;
	addHtmlClick: () => void;
	addCodeClick: () => void;
	isContentForm?: boolean;
}

interface SocialLinkSectionHeaderProps {
	addSocialLinkClick: () => void;
}

export const LinkSectionHeader: React.FC<LinkSectionHeaderProps> = ({
	length,
	addUrlClick,
	addHtmlClick,
	addCodeClick,
	isContentForm = false,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	return (
		<Row className="justify-content-between align-items-center" style={{ position: "relative" }}>
			<Col>
				<Styled.SectionHeader>LINKS</Styled.SectionHeader>
			</Col>
			<Styled.ButtonContainer isExpanded={isExpanded}>
				<div
					style={{
						height: 45,
						maxWidth: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<div
						style={{
							height: 45,
							display: "flex",
							flexDirection: "row",
							overflowX: "scroll",
							overflowY: "hidden",
							maxWidth: "100%",
							position: "relative",
						}}
					>
						<div
							style={{
								height: 45,
								display: "flex",
								flexDirection: "row",
								width: isContentForm ? 486 : 364,
							}}
						>
							<Styled.Button isExpanded={isExpanded} onClick={addUrlClick} style={{ borderLeftWidth: 0 }}>
								<div>
									<i className="far fa-link" style={{ marginRight: 5 }}></i>
									URL
								</div>
							</Styled.Button>
							<div style={{ height: "100%", minWidth: 2, backgroundColor: "#576069" }}></div>
							<Styled.Button isExpanded={isExpanded} onClick={addHtmlClick}>
								<div>
									<i className="far fa-code" style={{ marginRight: 5 }}></i>
									HTML
								</div>
							</Styled.Button>
							<div style={{ height: "100%", minWidth: 2, backgroundColor: "#576069" }}></div>
							<Styled.Button isExpanded={isExpanded} onClick={addCodeClick}>
								<div>
									<i className="far fa-brackets" style={{ marginRight: 5 }}></i>
									INTEGRATION
								</div>
							</Styled.Button>
						</div>
					</div>
					<div style={{ height: "100%", minWidth: 2, backgroundColor: "#576069" }}></div>
					<Styled.Button
						isExpanded={isExpanded}
						onClick={() => setIsExpanded(!isExpanded)}
						style={{ minWidth: 50, width: 50 }}
					>
						<i className="far fa-plus"></i>
					</Styled.Button>
				</div>
			</Styled.ButtonContainer>
		</Row>
	);
};

export const SocialLinkSectionHeader: React.FC<SocialLinkSectionHeaderProps> = ({ addSocialLinkClick }) => {
	return (
		<Row className="justify-content-between align-items-center" style={{ position: "relative" }}>
			<Col>
				<Styled.SectionHeader>SOCIAL LINKS</Styled.SectionHeader>
			</Col>
			<Styled.ButtonContainer isExpanded={false}>
				<Styled.Button isExpanded={false} onClick={addSocialLinkClick} style={{ minWidth: 50, width: 50 }}>
					<i className="far fa-plus"></i>
				</Styled.Button>
			</Styled.ButtonContainer>
		</Row>
	);
};
