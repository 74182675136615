import React, {useState, useEffect} from "react";
import * as Styled from "./ShareButton.styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import SimpleModal from "../Modals/Modal";
import TextCopyBlock from "../TextCopyBlock";

interface ShareButtonProps {
	url: string;
	defaultMessage?: string | undefined;
}

const FIVE_SECONDS = 5000;

const ShareButton: React.FC<ShareButtonProps> = (props) => {
	const { url, defaultMessage } = props;
	const [copied, setCopied] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (copied) {
			timer = setTimeout(() => {
				setCopied(false);
			}, FIVE_SECONDS);
		}

		return () => {
			clearTimeout(timer);
		}
	}, [copied]);

	const trigger = props.children || (
		<Styled.ShareButton>
			Share
			<i className="fas fa-share"/>
		</Styled.ShareButton>
	);

	const content = (
		<>
			<div>
				<div className="share-title">
					Share
				</div>
			</div>
			<div className="share-list">
				<FacebookShareButton url={url} quote={defaultMessage} className="share-item">
					<FacebookIcon size={52} round={true} />
					<p>Facebook</p>
				</FacebookShareButton>
				<TwitterShareButton url={url} title={defaultMessage} className="share-item">
					<TwitterIcon size={52} round={true} />
					<p>Twitter</p>
				</TwitterShareButton>
			</div>

			<div className="share-input-container">
				<TextCopyBlock>{url}</TextCopyBlock>
			</div>
		</>
	)

	const toggleModal = (visible= !isVisible) => {
		setIsVisible(visible);
	}

	return (
		<>
			<SimpleModal visibility={isVisible} closeModal={() => toggleModal(false)} modalClassName="react-modal-share">
				{content}
			</SimpleModal>
			<Styled.Trigger onClick={() => toggleModal()}>
				{trigger}
			</Styled.Trigger>
		</>
	)
}

export default ShareButton;
