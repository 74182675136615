import React, { useState, useEffect } from "react";
import { FieldProps } from "formik";
import { Form, Col } from "react-bootstrap";
import styled from "styled-components";

export interface OptionType {
	value: string;
	label: string;
	description: string;
}

export interface RadioProps {
	id: string;
	options?: OptionType[];
	field: FieldProps["field"];
	form: FieldProps["form"];
	name: string;
	onChange?: (e: any) => void;
	selectValue?: string | number;
	value?: OptionType;
	defaultOption: string;
}

const Radio: React.FC<RadioProps> = ({
	options,
	field,
	form,
	id,
	name,
	onChange,
	selectValue,
	value,
	defaultOption,
	...props
}) => {
	const [selectedOption, setSelectedOption] = useState(defaultOption);
	const setValue = (value: string) => {
		setSelectedOption(value);
		form.setFieldValue(field.name, value);
	};
	useEffect(() => {
		setSelectedOption(defaultOption);
	}, [defaultOption]);
	return (
		<div id="radio-input-wrapper">
			<fieldset>
				<Form.Group as={Col}>
					{options?.map(option => {
						const { label, description, value } = option;
						return (
							<label className="container" onClick={() => setValue(value)} key={value}>
								{label}
								<p>{description}</p>
								<input type="radio" checked={selectedOption === value} readOnly />
								<span className="checkmark"></span>
							</label>
						);
					})}
				</Form.Group>
			</fieldset>
		</div>
	);
};

export default Radio;
