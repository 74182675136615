import { FieldArray, FieldArrayRenderProps, FormikContextType } from "formik";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Well } from "../../";
import Form from "../../../../../components/Form";
import firebaseApp from "../../../../../firebase";
import { BrushfireAttendeeType, BrushfireEvent, BrushfireEventCondensed } from "../../../../../interfaces";
import api from "../../../../../services/api";
import * as LinksStyled from "../Links/LinksSection.styled";
import * as Styled from "./BrushfireEventSection.styled";

const db = firebaseApp.firestore();

interface ContextInterface {
	name: string;
	disabled: boolean;
	formikContext?: FormikContextType<any>;
}

export interface BrushfireEventSectionProps {
	name: string;
	disabled: boolean;
	formikContext: FormikContextType<any>;
	wellMessage: React.ReactElement;
	learnMoreLink?: string;
	initialFormValues: BrushfireEventCondensed[];
}

interface BrushfireEventCardProps {
	arrayHelpers: FieldArrayRenderProps;
	brushfireEventIndex: number;
	brushfireEvent: BrushfireEventCondensed;
	onRefresh: (id: string) => void;
	isRefreshing: boolean;
	initialFormValues: BrushfireEventCondensed[];
}

export const Context = React.createContext<ContextInterface>({
	name: "",
	disabled: false,
	formikContext: undefined,
});

interface ModalData {
	id?: string;
}

interface ModalInfo {
	title: string;
	body: string;
	subBody: string;
	confirmText: string;
	confirm: () => void;
}

const BrushfireEventCard: React.FC<BrushfireEventCardProps> = ({
	arrayHelpers,
	brushfireEventIndex,
	brushfireEvent,
	onRefresh,
	isRefreshing,
	initialFormValues,
}) => {
	const { formikContext } = useContext(Context);

	const [showModal, setShowModal] = useState(false);
	const [modalInfo, setModalInfo] = useState<ModalInfo>();
	const [hasRelativeDays, setHasRelativeDays] = useState<boolean>(false);
	const [relativeDays, setRelativeDays] = useState<number | null>(null);

	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const handleModalOpen = (info: ModalInfo) => {
		setModalInfo(info);
		handleShowModal();
	};

	const numberOfScreensChange = (value: string, attendeeTypeId: string) => {
		if (formikContext) {
			const newValues = { ...formikContext.values };
			const newBrushfireEvent = { ...newValues.brushfireEventIds[brushfireEventIndex] };

			if (parseInt(value) <= 100) {
				newBrushfireEvent.attendeeTypes[attendeeTypeId].numberOfScreens = value;
				formikContext.setValues(newValues);
				formikContext.setFieldTouched("brushfireEventIds");
			}
		}
	};

	const onAttendeeTypeCheck = (checked: boolean, attendeeTypeId: string) => {
		if (formikContext) {
			const newValues = { ...formikContext.values };
			const newBrushfireEvent = { ...newValues.brushfireEventIds[brushfireEventIndex] };

			if (checked) {
				newBrushfireEvent.attendeeTypes[attendeeTypeId].numberOfScreens = 1;
			} else {
				newBrushfireEvent.attendeeTypes[attendeeTypeId].numberOfScreens = null;
			}

			formikContext.setValues(newValues);
			formikContext.setFieldTouched("brushfireEventIds");
		}
	};

	const checkActiveAttendeeTypes = (event: any) => {
		let hasActiveAttendeeTypes = false;

		Object.keys(brushfireEvent.attendeeTypes).map((attendeeTypeId, index) => {
			const attendeeType = brushfireEvent.attendeeTypes[attendeeTypeId];

			if (attendeeType.numberOfScreens !== null) {
				hasActiveAttendeeTypes = true;
			}
		});

		return hasActiveAttendeeTypes;
	};

	const onRelativeDaysCheck = (checked: boolean) => {
		// if they uncheck the relative days checkbox, we're going to set the value to null
		if (formikContext) {
			const newValues = { ...formikContext.values };
			const newBrushfireEvent = { ...newValues.brushfireEventIds[brushfireEventIndex] };
			if (checked) {
				for (const attType in newBrushfireEvent.attendeeTypes) {
					const current = newBrushfireEvent.attendeeTypes[attType];
					current.orderRelativeDays = current?.orderRelativeDays ?? 30;
				}
				setHasRelativeDays(true);
				setRelativeDays(30);
			} else {
				for (const attType in newBrushfireEvent.attendeeTypes) {
					const current = newBrushfireEvent.attendeeTypes[attType];
					current.orderRelativeDays = null;
				}
				setHasRelativeDays(false);
				setRelativeDays(null);
			}

			formikContext.setValues(newValues);
			formikContext.setFieldTouched("brushfireEventIds");
		}
	};

	const setRelativeDaysValue = (val: number) => {
		// we're going to set the value on each attendee type for this event config
		if (formikContext) {
			const newValues = { ...formikContext.values };
			const newBrushfireEvent = { ...newValues.brushfireEventIds[brushfireEventIndex] };
			for (const attType in newBrushfireEvent.attendeeTypes) {
				const current = newBrushfireEvent.attendeeTypes[attType];
				current.orderRelativeDays = val;
			}

			formikContext.setValues(newValues);
			formikContext.setFieldTouched("brushfireEventIds");
		}
	}

	const savedPreviewLink = initialFormValues?.find(bf => bf.eventNumber === brushfireEvent.eventNumber)
		? `/attend/${brushfireEvent.eventNumber}`
		: null;

	useEffect(() => {
		// grab the first non-null orderRelativeDays value from the brushfire event attendee types
		const values = Object.values(brushfireEvent.attendeeTypes);
		const found = values.some(current => !!current.orderRelativeDays);
		if (found) {
			const value = values.find(current => current.orderRelativeDays !== null);

			if (!!value) {
				setHasRelativeDays(true);
				setRelativeDays(value.orderRelativeDays);
			}
		}
	}, [brushfireEvent.attendeeTypes])

	return (
		<Styled.CustomWell>
			<Styled.CardContents isRefreshing={isRefreshing}>
				<Styled.RefreshLoadingOverlay isRefreshing={isRefreshing}>
					<Spinner animation="border" role="status" style={{ color: "#fff", height: "2rem", width: "2rem" }}>
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Styled.RefreshLoadingOverlay>
				<div style={{ marginBottom: "20px" }}>
					<h3>
						{brushfireEvent.eventName} ({brushfireEvent.eventNumber})
					</h3>
					<p>
						<small>{brushfireEvent.eventDate}</small>
					</p>
					{savedPreviewLink && (
						<a href={savedPreviewLink} target="_blank">
							View Event Access Page
						</a>
					)}
					<div className="row" style={{ borderBottom: "1px solid grey", display: "flex", justifyContent: "space-between" }}>
						<div style={{ display: "flex", alignItems: "end" }}>
							<Form.Input.Checkbox
								id={`${brushfireEvent.eventId}-relative-days-checkbox`}
								label={"Limit access after order date"}
								value={hasRelativeDays}
								onChecked={() => onRelativeDaysCheck(true)}
								onUnchecked={() => onRelativeDaysCheck(false)}
							/>
						</div>
						<div className="field" style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
							<div style={{ display: "flex", flexDirection: "column", visibility: hasRelativeDays ? 'unset' : 'hidden' }}>
								<small style={{ textAlign: "center" }}>Day(s)</small>
								<Styled.TextInput
									id={brushfireEvent.eventId + "-orderRelativeDays"}
									name={brushfireEvent.eventId + "-orderRelativeDays"}
									placeholder={"30"}
									value={relativeDays ?? ""}
									hasError={false}
									onChange={(event: any) => {
										setRelativeDaysValue(parseInt(event.target.value));
										setRelativeDays(parseInt(event.target.value));
									}}
									min="1"
									type="number"
									style={{ width: "80px", display: "inline-block" }}
									disabled={!hasRelativeDays}
								/>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<small>Attendee Types</small>
						{checkActiveAttendeeTypes(brushfireEvent) && <small>Max Screens</small>}
					</div>
					{Object.keys(brushfireEvent.attendeeTypes).map((attendeeTypeId, index) => {
						const attendeeType = brushfireEvent.attendeeTypes[attendeeTypeId];
						return (
							<div style={{ display: "flex", justifyContent: "space-between" }} key={attendeeTypeId}>
								<Form.Input.Checkbox
									id={`${attendeeTypeId}-checkbox`}
									label={attendeeType.name}
									value={attendeeType.numberOfScreens !== null}
									onChecked={() => onAttendeeTypeCheck(true, attendeeTypeId)}
									onUnchecked={() => onAttendeeTypeCheck(false, attendeeTypeId)}
								/>
								{attendeeType.numberOfScreens !== null && (
									<div className="field">
										<input
											id={`${attendeeTypeId}-screens`}
											name={`${attendeeTypeId}-screens`}
											type="number"
											min="1"
											max="100"
											disabled={attendeeType.numberOfScreens === null}
											value={attendeeType.numberOfScreens || 1}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												numberOfScreensChange(event.target.value, attendeeTypeId);
											}}
											style={{ width: "80px" }}
										/>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</Styled.CardContents>
			<Styled.ActionsContainer>
				<LinksStyled.ActionButton
					type="button"
					onClick={() => {
						handleModalOpen({
							title: "Remove this event?",
							body: `Any contents with rules from this event's data may be affected`,
							subBody: "",
							confirm: () => arrayHelpers.remove(brushfireEventIndex),
							confirmText: "Remove",
						});
					}}
				>
					<i className="fal fa-trash" />
				</LinksStyled.ActionButton>
				<LinksStyled.ActionButton
					type="button"
					onClick={evt => {
						evt.preventDefault();
						evt.stopPropagation();
						onRefresh(brushfireEvent.eventId);
					}}
					disabled={isRefreshing}
				>
					<i className="fas fa-sync-alt" />
				</LinksStyled.ActionButton>
			</Styled.ActionsContainer>

			<Modal show={showModal} onHide={handleCloseModal} className="default-modal">
				<Modal.Header closeButton>
					<Modal.Title>{modalInfo?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modalInfo?.body && <p>{modalInfo.body}</p>}
					{modalInfo?.subBody && <p>{modalInfo.subBody}</p>}
				</Modal.Body>

				<Modal.Footer>
					<Button className="modal-button cancel-button" variant="secondary" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button className="modal-button confirm-button" color="#e71d36" onClick={modalInfo?.confirm}>
						{modalInfo?.confirmText}
					</Button>
				</Modal.Footer>
			</Modal>
		</Styled.CustomWell>
	);
};

const BrushfireEventSection: React.FC<BrushfireEventSectionProps> = ({
	name,
	disabled,
	formikContext,
	wellMessage,
	learnMoreLink,
	initialFormValues,
}) => {
	const [eventId, setEventId] = useState("");
	const [error, setError] = useState<string>();
	const [canSearch, setCanSearch] = useState(false);
	const [searching, setSearching] = useState(false);
	const [eventsRefreshing, setEventsRefreshing] = useState<string[]>([]);

	const searchForEvent = async (id: string) => {
		const { brushfireEventIds } = formikContext.values;
		const isAlreadySetup =
			brushfireEventIds?.length > 0 &&
			brushfireEventIds.some((e: BrushfireEventCondensed) => e.eventNumber === id);

		if (isAlreadySetup) {
			setError("Event ID is already set up");
		} else if (/^\d{6}$/.test(id)) {
			setSearching(true);
			setError(undefined);
			api.getEventData(id, true)
				.then(data => {
					if (data.ok) {
						const eventData = { ...(data.data.event as BrushfireEvent) };
						const eventTypes = [...(data.data.types as BrushfireAttendeeType[])];

						const newIndex = brushfireEventIds?.length || 0;
						const newValues = { ...formikContext.values };

						const newEventObject: BrushfireEventCondensed = {
							eventId: eventData.Id,
							eventNumber: eventData.EventNumber.toString(),
							eventName: eventData.Title,
							eventDate: null,
							attendeeTypes: {},
						};

						if (eventData.StartsAt) {
							newEventObject.eventDate = eventData.DateInfo;
						}

						eventTypes.forEach(type => {
							newEventObject.attendeeTypes[type.Id] = {
								name: type.Name,
								numberOfScreens: null,
								orderRelativeDays: null
							};
						});

						if (!newValues[name]) {
							newValues[name] = [];
						}

						newValues[name][newIndex] = newEventObject;

						formikContext.setValues(newValues);
						formikContext.setFieldTouched("brushfireEventIds");
						setEventId("");
						setCanSearch(false);
					} else {
						setError("Unable to find event");
					}
				})
				.catch(error => {
					console.warn("Error getting event", error);
					setError("Unknown error");
				})
				.finally(() => {
					setSearching(false);
				});
		} else {
			setError("Event ID's can only contain numbers and be 6 digits long");
		}
	};

	const refreshEvent = (id: string) => {
		setEventsRefreshing([...eventsRefreshing, id]);
		api.getEventData(id, true)
			.then(data => {
				if (data.ok) {
					const eventData = { ...(data.data.event as BrushfireEvent) };
					const eventTypes = [...(data.data.types as BrushfireAttendeeType[])];

					const newValues = { ...formikContext.values };

					let existingEventObject = newValues.brushfireEventIds.find((e: any) => e.eventId === id);
					if (existingEventObject) {
						const existingEventObjectIndex = newValues.brushfireEventIds.findIndex(
							(e: any) => e.eventId === id
						);

						existingEventObject.eventName = eventData.Title;

						if (eventData.StartsAt) {
							existingEventObject.eventDate = eventData.DateInfo;
						}

						eventTypes.forEach(type => {
							if (!Object.keys(existingEventObject.attendeeTypes).includes(type.Id)) {
								existingEventObject.attendeeTypes[type.Id] = {
									name: type.Name,
									numberOfScreens: null,
								};
							}
						});

						newValues[name][existingEventObjectIndex] = existingEventObject;

						formikContext.setValues(newValues);
					} else {
						console.warn("Error getting event", data);
						setError("Unknown Error (data fetched)");
					}
				} else {
					setError("Unable to find event");
				}
			})
			.catch(error => {
				console.warn("Error getting event", error);
				setError("Unknown error");
			})
			.finally(() => {
				setEventsRefreshing(eventsRefreshing.filter(e => e !== id));
			});
	};

	return (
		<Context.Provider value={{ name, disabled, formikContext }}>
			<FieldArray
				name={name}
				render={arrayHelpers => {
					return (
						<>
							<Styled.Header>
								<Styled.SectionHeader>BRUSHFIRE EVENT</Styled.SectionHeader>
							</Styled.Header>
							<Well message={wellMessage} learnMoreLink={learnMoreLink}>
								<Styled.CustomRow>
									<div className="field">
										<div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
											<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
												<label htmlFor="eventId">Event Number</label>
												<Styled.TextInput
													id="eventId"
													name="eventId"
													max="6"
													placeholder="6 digit number, e.g. 424242"
													hasError={!!error}
													value={eventId}
													onChange={(event: any) => {
														const { value } = event.target;
														if (
															value.length <= 6 &&
															(/^\d+$/.test(value) || value === "")
														) {
															setEventId(value);

															if (value.length === 6) {
																setCanSearch(true);
															}
														}
														setError(undefined);
													}}
												/>
											</div>
											<Button
												disabled={!canSearch || searching}
												onClick={() => searchForEvent(eventId)}
												style={{ width: "100px", textAlign: "center" }}
											>
												{searching ? (
													<Spinner
														animation="border"
														role="status"
														style={{ height: "1.5rem", width: "1.5rem" }}
													>
														<span className="sr-only">Loading...</span>
													</Spinner>
												) : (
													"Add"
												)}
											</Button>
										</div>
									</div>
								</Styled.CustomRow>
								{error && (
									<div style={{ display: "block" }}>
										<small className="error">{error}</small>
									</div>
								)}
								{!isEmpty(formikContext.values[name]) &&
									formikContext.values[name]
										.sort((a: any, b: any) => {
											let eventA = a.eventName.toUpperCase();
											let eventB = b.eventName.toUpperCase();
											return eventA < eventB ? -1 : eventA > eventB ? 1 : 0;
										})
										.map((brushfireEvent: any, brushfireEventIndex: number) => {
											return (
												<BrushfireEventCard
													key={brushfireEvent.eventId}
													arrayHelpers={arrayHelpers}
													brushfireEvent={brushfireEvent}
													brushfireEventIndex={brushfireEventIndex}
													onRefresh={refreshEvent}
													isRefreshing={eventsRefreshing.includes(brushfireEvent.eventId)}
													initialFormValues={initialFormValues}
												/>
											);
										})}
							</Well>
						</>
					);
				}}
			/>
		</Context.Provider>
	);
};

export default BrushfireEventSection;
