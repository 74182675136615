import React from "react";

interface WellHeaderProps {
	message: string | React.ReactElement;
	learnMoreLink?: string;
}

const WellHeader: React.FC<WellHeaderProps> = ({ message, learnMoreLink }) => {
	return (
		<div className="well-header">
			<p>
				{message}{" "}
				{learnMoreLink && (
					<span>
						<a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
							Learn more <i className="fal fa-long-arrow-right" />
						</a>
					</span>
				)}
			</p>
		</div>
	);
};

export default WellHeader;
