import styled from "styled-components";

interface StickyInputProps {
	prefixSize: number;
}

export const StickyInput = styled.div<StickyInputProps>`
	position: relative;
	p {
		position: absolute;
		left: 10px;
		top: 31px;
		color: #9b9b9b;
	}

	input {
		padding-left: ${({ prefixSize }) => `${prefixSize + 10}px`};
	}
`;
