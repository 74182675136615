import styled, { css } from "styled-components";

interface ContainerProps {
	hasBeenSent: boolean;
	isActive: boolean;
	isDashboard: boolean;
}

interface InteractionDetailsProps {
	hasBeenSent: boolean;
	isDashboard: boolean;
}

export const Container = styled.div<ContainerProps>`
	padding: 10px 20px 10px 10px;
	margin-bottom: ${({ isDashboard, isActive }) => (isDashboard && isActive ? "0px" : "10px")};
	${({ isDashboard, isActive }) => {
		if (isDashboard && isActive) {
			return `
				border-radius: 4px 4px 0px 0px;
			`;
		} else {
			return `
				border-radius: 4px;
			`;
		}
	}}
	min-height: ${({ isDashboard }) => (isDashboard ? "68px" : "auto")};

	background-color: ${({ isActive }) => (isActive ? "#dadada" : "#4a525a")};
	color: ${({ hasBeenSent, isActive, isDashboard }) => {
		if (isActive) {
			return "#24272b";
		} else if (isDashboard) {
			return "#fff";
		} else {
			return hasBeenSent ? "#676e75" : "#fff";
		}
	}};

	display: flex;
	align-items: center;
	justify-content: space-between;

	transition: all 0.2s ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: #dadada;
		color: #24272b;
	}
`;

export const InteractionDetails = styled.div<InteractionDetailsProps>`
	display: flex;
	flex-direction: column;
	text-align: left;
	max-width: ${({ hasBeenSent, isDashboard }) => {
		if (hasBeenSent && isDashboard) {
			return "70%";
		} else {
			return hasBeenSent ? "85%" : "100%";
		}
	}};
`;

export const InteractionTitle = styled.div``;

export const InteractionQuestion = styled.small`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
`;

export const InteractionSent = styled.div``;
