import "firebase/auth";
import { cloneDeep, isEmpty, orderBy, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Form, { FormContext } from "../../../../components/Form";
import Editor from "../../../../components/Form/Editor";
import Sticky from "../../../../components/Sticky";
import { useToast } from "../../../../components/Toast/ToastProvider";
import { REACTION_SHARD_COUNT } from "../../../../constants";
import firebase from "../../../../firebase";
import {
	AnalyticsProcessed,
	AvailableCodeProviders,
	Channel,
	Content,
	ContentType,
	Interaction,
	SocialLinkType,
	TagInput,
	VideoProvider,
} from "../../../../interfaces";
import { NavigatorSection } from "../../../../interfaces/NavigatorSection";
import { TypedUseSelectorHook } from "../../../../redux";
import api from "../../../../services/api";
import { addPreText } from "../../../../utils/addPreText";
import { generateRandomString } from "../../../../utils/generateRandom";
import { PageContainer, PageTitle, Well } from "../../UI";
import FormSection from "../../UI/FormSection";
import { Sections } from "../../UI/FormSections";
import FormSubmitButton from "../../UI/FormSubmitButton";
import SectionedPageContainer from "../../UI/SectionedPageContainer";
import { FooterLink } from "../../UI/SectionedPageContainer/SectionedPageContainer";
import * as helpers from "./helpers";
import { validate } from "./validate";

const useTypedSelector: TypedUseSelectorHook = useSelector;

const db = firebase.firestore();

interface MatchParams {
	clientId: string;
	contentId: string;
}

interface LocationState {
	contentType: ContentType;
}

export type FormContent = Content;

async function asyncForEach(array: string[], callback: Function) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

const formSections = {
	details: "DETAILS",
	video: "VIDEO",
	engagement: "ENGAGEMENT",
	links: "LINKS",
	interactions: "INTERACTIONS",
	availability: "AVAILABILITY",
};

const ContentForm: React.FC = props => {
	const [formValues, setFormValues] = useState<any>({});
	const [action, setAction] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deletedInteractions, setDeletedInteractions] = useState<string[]>([]);
	const [formContentType, setFormContentType] = useState<1 | 2 | 3>();
	const [tags, setTags] = useState<TagInput[]>([]);
	const [relatedContents, setRelatedContents] = useState<TagInput[]>([]);
	const [addedRelatedContentIds, setAddedRelatedContentIds] = useState<string[]>([]);
	const [deletedRelatedContentIds, setDeletedRelatedContentIds] = useState<string[]>([]);
	const [scheduleOption, setScheduleOption] = useState("");

	const { addToast } = useToast();
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const location = useLocation();
	const match = useRouteMatch<MatchParams>();
	const history = useHistory();

	const loading = dashboard.clientDataLoading || dashboard.channelDataLoading || dashboard.contentDataLoading;

	const isFormLocked = action === "edit" && formValues.closesAt && moment(formValues.closesAt).isBefore(moment());

	const appUserWhitelist = dashboard.clientId ? user.appUser?.whitelist?.[dashboard.clientId] : null;

	useEffect(() => {
		let { pathname, state } = location;
		const values = pathname.split("/");
		let tempAction = "";
		if (values.includes("add")) {
			tempAction = "add";
		} else {
			if (values.includes("copy")) {
				tempAction = "copy";
			} else {
				tempAction = "edit";
			}
		}

		setAction(tempAction);

		// for testing only
		// state = { contentType: 1 };

		if (tempAction === "add") {
			// state should have content type on /add route.
			// if it doesn't, kick them back to the content list
			const possibleState = state as LocationState | undefined;
			if (possibleState && [1, 2, 3].includes(possibleState.contentType)) {
				setFormContentType(possibleState.contentType);
			} else if (!formContentType) {
				const { params } = match;
				history.push(`/dashboard/${params.clientId}/contents`);
			}
		}
	}, [location]);

	useEffect(() => {
		if (action === "edit" || action === "copy") {
			if (
				!dashboard.contentDataLoading &&
				dashboard.contentData &&
				!dashboard.contentsDataLoading &&
				dashboard.contentsData &&
				!dashboard.tagsDataLoading &&
				dashboard.tagsData
			) {
				const { contentData, contentsData, tagsData } = dashboard;
				let prePlayDuration =
					contentData.opensAt && contentData.startsAt
						? moment.duration(moment(contentData.startsAt).diff(contentData.opensAt)).asMinutes()
						: null;

				const contentTags: TagInput[] = [];
				tagsData.forEach(tag => {
					if (contentData.tags?.some(tagId => tagId === tag.id)) {
						contentTags.push({ id: tag.id, name: tag.name });
					}
				});
				setTags(contentTags);

				const contentRelatedContents: TagInput[] = [];
				contentsData.forEach(content => {
					if (content.id !== dashboard.contentId) {
						if (contentData.relatedContentIds?.some(contentId => contentId === content.id)) {
							contentRelatedContents.push({ id: content.id, name: content.name });
						}
					}
				});
				setRelatedContents(contentRelatedContents);
				const tempFormValues = {
					// TODO: We should be spreading helpers.initialValues here just to get defaults
					// but this will take some testing for old data I think, to make sure data isn't
					// getting screwed up at all
					...contentData,
					name: addPreText(contentData.name, action),
					opensAt: contentData.opensAt ? new Date(contentData.opensAt) : null,
					closesAt: contentData.closesAt ? new Date(contentData.closesAt) : null,
					startsAt: contentData.startsAt ? new Date(contentData.startsAt) : null,
					showAt: contentData.showAt ? new Date(contentData.showAt) : null,
					hideAt: contentData.hideAt ? new Date(contentData.hideAt) : null,
					originalAirDate: contentData.originalAirDate ? new Date(contentData.originalAirDate) : null,
					reactions: {
						enabled: contentData.reactions?.enabled || false,
						icons: contentData.reactions?.icons || {},
					},
					chat: {
						enabled: contentData.chat.enabled,
						startsAt: contentData.chat.startsAt ? new Date(contentData.chat.startsAt) : null,
						endsAt: contentData.chat.endsAt ? new Date(contentData.chat.endsAt) : null,
						enableAnonymous: !contentData.chat.enableAnonymous,
						enablePreview: contentData.chat.enablePreview ?? false,
						requireSignin: !contentData.chat.enablePreview ?? true,
						enableDirectMessaging: contentData.chat.enableDirectMessaging ?? false,
					},
					bible: {
						enabled: contentData.bible.enabled,
						passage: helpers.splitPassageUrl(contentData.bible.url),
					},
					schedule: {
						enabled: contentData.schedule?.enabled ?? false,
					},
					share: {
						enabled: contentData.share?.enabled ?? true,
						defaultMessage: contentData.share?.defaultMessage ?? "",
					},
					initialSelectedPanel: contentData.initialSelectedPanel ?? 0,
					videoProvider: contentData.videoProvider,
					contentType: contentData.contentType,
					duration: contentData.duration ?? 0,
					prePlayDuration: prePlayDuration ?? 0,
					relatedContentIds: contentRelatedContents,
					tags: contentTags,
					channelId: contentData.channelIds && contentData.channelIds.length > 0 ? "" : contentData.channelId,
					rules: [
						{
							attendeeTypeIds: contentData.rules ? contentData.rules[0]?.attendeeTypeIds ?? [] : [],
							fields: contentData.rules ? contentData.rules[0]?.fields ?? {} : {},
						},
					],
				};

				if (contentData.showAt && !contentData.hideAt) {
					setScheduleOption("1");
				} else if (!contentData.showAt && contentData.hideAt) {
					setScheduleOption("3");
				} else if (contentData.showAt && contentData.hideAt) {
					setScheduleOption("2");
				} else if (!contentData.showAt && !contentData.hideAt) {
					setScheduleOption("0");
				}

				if (!isEmpty(contentData.links)) {
					contentData.links.forEach((link: any) => {
						if (link.url) {
							link.linkType = "url";
						} else if (link.html) {
							link.linkType = "html";
						} else if (link.code) {
							link.linkType = "code";
							link.codeProvider = AvailableCodeProviders[link.codeProvider];
						}
					});
				}

				if (!isEmpty(contentData.socialLinks)) {
					tempFormValues.socialLinks.forEach((socialLink: any) => {
						socialLink.type = SocialLinkType[socialLink.type];
					});
				}

				if (tempFormValues.interactions && !isEmpty(contentData.interactions)) {
					tempFormValues.interactions = orderBy(tempFormValues.interactions, ["displayOrder"], "asc");
				}

				if (contentData.contentType === undefined) {
					if (tempFormValues.startsAt) {
						if (
							[VideoProvider.youtube, VideoProvider.vimeo, VideoProvider.facebook].includes(
								tempFormValues.videoProvider
							)
						) {
							tempFormValues.contentType = ContentType.simulatedLive;
						} else {
							tempFormValues.contentType = ContentType.live;
						}
					} else {
						tempFormValues.contentType = ContentType.onDemand;
					}
				}

				if (action === "copy") {
					tempFormValues.id = uuidv4();
					if (tempFormValues.interactions && !isEmpty(tempFormValues.interactions)) {
						tempFormValues.interactions.forEach(interaction => {
							interaction.id = uuidv4();
							if (interaction.responses && !isEmpty(interaction.responses)) {
								interaction.responses.forEach(response => {
									response.id = uuidv4();
								});
							}
						});
					}
					tempFormValues.analyticsProcessed = AnalyticsProcessed.unprocessed;
				}

				tempFormValues.channelIds = tempFormValues.channelIds || [tempFormValues.channelId];

				setFormValues(tempFormValues);
			}
		} else if (action === "add") {
			const tempFormValues = helpers.initialFormValues;
			if (formContentType) {
				tempFormValues.contentType = formContentType;
			}
			if (dashboard.channelsData?.length === 1) {
				tempFormValues.channelIds = [dashboard.channelsData[0].id];
			}
			setFormValues(tempFormValues);
		}
	}, [action, dashboard.contentDataLoading, dashboard.contentsDataLoading, dashboard.tagsDataLoading]);

	const onSubmit = async (formValues: any, resetForm: any) => {
		if (!isFormLocked || user.appUser?.globalRole === "owner") {
			addToast("validating", "Content saving...");
			const { push } = history;
			const { params } = match;
			const values = cloneDeep(formValues);

			setIsSubmitting(true);
			let existingContentsLength = 0;
			try {
				const ref = await db
					.collection("contents")
					.where("clientId", "==", dashboard.clientId)
					.get();
				// length is not 0-based, so if 1 exists, length === 1
				existingContentsLength = ref.size;
			} catch (error) {
				addToast("error", "Error getting current contents");
				console.warn("Error getting current contents", error);
			}

			let tempDeletedIds = [...deletedInteractions];
			if (values.contentType === ContentType.onDemand) {
				values.startsAt = null;
				delete values.showLiveStats;

				values.chat = {
					enabled: false,
					startsAt: null,
					endsAt: null,
					enableAnonymous: true,
					enableDirectMessaging: true,
				};

				if (!isEmpty(values.interactions)) {
					values.interactions.forEach((i: Interaction) => {
						tempDeletedIds.push(i.id);
					});

					tempDeletedIds = uniq(tempDeletedIds);
				}

				values.interactions = [];
			}

			if (values.contentType !== ContentType.onDemand) {
				if (!values.prePlayDuration) {
					values.prePlayDuration = 0;
				}
			}

			let opensAt =
				values.startsAt && values.prePlayDuration >= 0
					? moment(values.startsAt)
						.subtract(parseInt(values.prePlayDuration), "minutes")
						.toDate()
					: null;
			let closesAt =
				values.startsAt && values.duration
					? moment(values.startsAt)
						.add(parseInt(values.duration), "minutes")
						.toDate()
					: null;

			delete values.prePlayDuration;

			let convertToOnDemand = values.convertToOnDemand?.enabled ? true : false;

			if (convertToOnDemand && !values.hasConvertedToOnDemand) {
				values.hasConvertedToOnDemand = false;
			}

			values.convertToOnDemand = {
				enabled: convertToOnDemand,
				channelIds: values.convertToOnDemand?.channelIds || [],
				name: values.convertToOnDemand?.name || "",
				description: values.convertToOnDemand?.description || "",
			};

			let newValues: Content = {
				...values,
				clientId: dashboard.clientId,
				channelIds: values.channelIds,
				opensAt: opensAt ? opensAt.toISOString() : null,
				closesAt: closesAt ? closesAt.toISOString() : null,
				startsAt: values.startsAt ? values.startsAt.toISOString() : null,
				originalAirDate: values.originalAirDate ? values.originalAirDate.toISOString() : null,
				chat: {
					enabled: values.chat.enabled,
					startsAt: opensAt ? opensAt.toISOString() : null,
					endsAt: closesAt ? closesAt.toISOString() : null,
					enableAnonymous: !values.chat.enableAnonymous,
					enablePreview: !values.chat.requireSignin,
					enableDirectMessaging: values.chat.enableDirectMessaging,
				},
				bible: {
					enabled: values.bible.enabled,
					url: values.bible.enabled ? helpers.buildPassageUrl(values.bible.passage) : null,
				},
				notes: {
					enabled: values.notes.enabled,
					defaultContent: values.notes.enabled ? values.notes.defaultContent : "",
				},
				schedule: {
					enabled: values.schedule.enabled,
				},
				channelId: values.channelId ? values.channelId : "",
				videoProvider: values.videoProvider,
				versionCode: null,
				tags: values.tags.map((tag: TagInput) => tag.id),
				relatedContentIds: values.relatedContentIds.map((contentTag: TagInput) => contentTag.id),
				hideAt: values.hideAt ? values.hideAt.toISOString() : null,
				showAt: values.showAt ? values.showAt.toISOString() : null,
			};

			if (action === "add" || action === "copy") {
				newValues.id = uuidv4();
				newValues.key = generateRandomString(10);
				newValues.createdAt = moment.utc().toISOString();
				newValues.useChatProcessor = true;
				newValues.runAnalytics = false;
			} else {
				newValues.id = values.id;
				newValues.key = values.key;
			}

			if (action === "copy") {
				delete newValues.convertedFromId;
				delete newValues.convertedToOnDemandId;
				newValues.hasConvertedToOnDemand = false;
			}

			if (!isEmpty(newValues.links)) {
				newValues.links.forEach((link: any) => {
					delete link.linkType;
					if (link.hasOwnProperty("codeProvider") && link.hasOwnProperty("code")) {
						link.codeProvider = AvailableCodeProviders[link.codeProvider];
						if (link.codeProvider === AvailableCodeProviders.chat) {
							link.code = "chat";
						}
					}
				});
			}

			if (!isEmpty(newValues.socialLinks)) {
				newValues.socialLinks.forEach((socialLink: any) => {
					socialLink.type = SocialLinkType[socialLink.type];
				});
			}

			if (newValues.contentType === ContentType.onDemand) {
				newValues.reactions.enabled = false;
			}

			if (
				newValues.reactions &&
				newValues.reactions.icons &&
				Object.keys(newValues.reactions.icons).filter(key => newValues.reactions.icons[key] === true).length ===
				0
			) {
				newValues.reactions.enabled = false;
			}

			if (newValues.reactions.enabled) {
				const batch = db.batch();
				await asyncForEach(Object.keys(newValues.reactions.icons), async (key: string) => {
					if (newValues.reactions.icons[key]) {
						const contentCol = await db
							.collection("reactions")
							.doc("counts")
							.collection(newValues.id);
						const doc = await contentCol.doc(`${key}_0`).get();
						if (!doc.exists) {
							for (let i = 0; i < REACTION_SHARD_COUNT; i++) {
								const d = contentCol.doc(`${key}_${i}`);
								batch.set(d, { count: 0 });
							}
						}
					}
				});
				batch.commit();
			}

			let interactions: Interaction[] | undefined = [];
			if (!isEmpty(newValues.interactions)) {
				interactions = cloneDeep(newValues.interactions);
				delete newValues.interactions;

				interactions?.forEach((interaction: any, index: number) => {
					interaction.versionCode = null;
					interaction.clientId = dashboard.clientId;
					interaction.contentId = newValues.id;

					interaction.displayOrder = index;

					if (isEmpty(interaction.id) || action === "copy") {
						interaction.id = uuidv4();
					}
					if (action === "copy") {
						interaction.showAt = null;
					}
					if (!isEmpty(interaction.responses)) {
						interaction.responses.forEach((response: any) => {
							if (isEmpty(response.id) || action === "copy") {
								response.id = uuidv4();
							}
						});
					}
				});
			}

			// checking to see if any interactions were deleted
			if (!isEmpty(dashboard.contentData?.interactions) && !isEmpty(tempDeletedIds)) {
				dashboard.contentData?.interactions?.forEach((interaction: any) => {
					let newInteraction = cloneDeep(interaction);
					if (tempDeletedIds.includes(interaction.id)) {
						newInteraction.versionCode = generateRandomString(8, false, true);
						newInteraction.clientId = dashboard.clientId;
						newInteraction.contentId = dashboard.contentId;
						interactions?.push(newInteraction);
					} else {
						console.log("Unknown interaction state", interaction);
					}
				});
			}

			try {
				await db
					.collection("contents")
					.doc(newValues.id)
					.set(newValues);

				newValues.channelIds.forEach(channelId => {
					const channelKey = dashboard.channelsData?.find(channel => channel.id === channelId)?.key;
					const clientKey = dashboard.clientData?.key;
					if (channelKey && clientKey) {
						setTimeout(() => {
							api.fetchChannel(clientKey, channelKey);
						}, 1000);
					}
				});

				if (!isEmpty(interactions)) {
					interactions?.forEach(async (interaction: Interaction) => {
						await db
							.collection("interactions")
							.doc("contents")
							.collection(newValues.id)
							.doc(interaction.id)
							.set(interaction);
					});
				}

				// unlink content
				deletedRelatedContentIds.forEach(async contentId => {
					const content = dashboard.contentsData?.find(c => c.id === contentId);
					if (content) {
						let { relatedContentIds } = content;
						if (relatedContentIds) {
							if (relatedContentIds.includes(newValues.id)) {
								relatedContentIds = relatedContentIds.filter(id => id !== newValues.id);
							}
						} else {
							relatedContentIds = [];
						}

						const newContent = {
							...content,
							relatedContentIds,
						};

						await db
							.collection("contents")
							.doc(newContent.id)
							.set(newContent);
					}
				});

				// link content together
				newValues.relatedContentIds.forEach(async contentId => {
					const content = dashboard.contentsData?.find(c => c.id === contentId);
					if (content) {
						let { relatedContentIds } = content;
						if (relatedContentIds) {
							if (!relatedContentIds.includes(newValues.id)) {
								relatedContentIds = [...relatedContentIds, newValues.id];
							}
						} else {
							relatedContentIds = [newValues.id];
						}

						const newContent = {
							...content,
							relatedContentIds,
						};
						await db
							.collection("contents")
							.doc(newContent.id)
							.set(newContent);
					}
				});

				addToast("success", "Content saved!");
				resetForm({ values: formValues });
				if (action !== "edit") {
					push(`/dashboard/${params.clientId}/contents`);
				}
			} catch (error) {
				addToast("error", "Error submitting form.");
				console.warn("Error submitting form", error);
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	const compareRules = async (channel: any, formikContext: any) => {
		let fieldOptions: any = [];
		let attendeeTypes: any = [];
		setIsLoading(true);

		if (channel?.brushfireEventIds?.length > 0) {
			await channel?.brushfireEventIds?.forEach((bfEvent: any) => {
				api.getEventData(bfEvent.eventId, true, true)
					.then(async (data: any) => {
						if (data.ok) {
							const fieldData = [...data.data.fields];
							const typesData = [...data.data.types];

							if (fieldData.length > 0) {
								let fields = fieldData.filter(f => f.Options.length > 0);
								fields?.forEach(f => {
									let optionsArr = f.Options.map((field: any) => field.Id);
									fieldOptions = fieldOptions.concat(optionsArr);
								});
							}

							if (typesData.length > 0) {
								let typesArr = typesData.map(t => t.Id);
								attendeeTypes = attendeeTypes.concat(typesArr);
							}
						}
					})
					.catch(async (error: any) => {
						console.warn("Error getting event", error);
						setIsLoading(false);
					})
					.finally(async () => {
						let contentRules = formikContext.values.rules[0];
						let fieldsArr =
							!isEmpty(contentRules?.fields) && contentRules ? Object.keys(contentRules.fields) : [];
						let typesArr =
							contentRules && contentRules.attendeeTypeIds.length > 0 ? contentRules.attendeeTypeIds : [];

						let intersectedTypes = typesArr.filter((value: any) => attendeeTypes.includes(value));
						let intersectedOptions = fieldsArr.filter((value: any) => fieldOptions.includes(value));

						setContentRules(intersectedTypes, intersectedOptions, typesArr, fieldsArr, formikContext);

						if (intersectedTypes.length > 0 || intersectedOptions.length > 0) {
							window.alert("Deleting rules that exist on this content from the unselected channel");
						}
						setIsLoading(false);
					});
			});
		} else {
			setIsLoading(false);
		}
	};

	const setContentRules = (
		intersectedTypes: any,
		intersectedOptions: any,
		attendeeTypes: any,
		fieldOptions: any,
		formikContext: any
	) => {
		let newAttendeeTypeIdsArr = attendeeTypes.filter((type: any) => {
			return intersectedTypes.indexOf(type) < 0;
		});

		let newFieldOptionsArr = fieldOptions.filter((type: any) => {
			return intersectedOptions.indexOf(type) < 0;
		});

		let optionsData: any = {};

		if (newFieldOptionsArr.length > 0) {
			newFieldOptionsArr.forEach((fieldOptionId: any) => {
				optionsData[fieldOptionId] = true;
			});
		}

		let contentRules = [
			{
				attendeeTypeIds: newAttendeeTypeIdsArr,
				fields: optionsData,
			},
		];

		formikContext.setFieldValue("rules", contentRules);
	};

	const onInteractionDelete = (id: string) => {
		if (!isEmpty(id)) {
			let newDeletedInteractions = [...deletedInteractions];
			newDeletedInteractions.push(id);
			setDeletedInteractions(newDeletedInteractions);
		}
	};

	if (loading) {
		return <PageContainer htmlPageTitle="Loading..." />;
	}

	const sections: NavigatorSection[] = [
		{
			label: "Details",
			key: formSections.details,
		},
		{
			label: "Access",
			key: formSections.availability,
		},
		{
			label: "Video",
			key: formSections.video,
		},
		{
			label: "Engagement",
			key: formSections.engagement,
		},

		{
			label: "Links",
			key: formSections.links,
		},
	];

	//@ts-ignore
	if (formValues?.contentType !== ContentType.onDemand) {
		sections.push({
			label: "Interactions",
			key: formSections.interactions,
		});
	}

	const footerLinks: FooterLink[] = [];

	if (action === "edit") {
		footerLinks.push({
			label: "Edit",
			href: `//${window.location.host}/dashboard/${formValues.clientId}/contents/${formValues.id}`,
			icon: "fal fa-pencil",
			key: "edit",
		});
		footerLinks.push({
			label: "Analytics",
			href: `//${window.location.host}/dashboard/${formValues.clientId}/contents/${formValues.id}/analytics`,
			icon: "fal fa-analytics",
			key: "analytics",
		});
		footerLinks.push({
			label: "Host Panel",
			href: `//${window.location.host}/dashboard/${formValues.clientId}/contents/${formValues.id}/host`,
			icon: "fal fa-comments",
			key: "host",
		});
		const channel = formValues.channelIds
			? dashboard.channelsData?.find(chan => chan.id === formValues.channelIds[0])
			: null;
		if (channel) {
			footerLinks.push({
				label: "Preview",
				href: `//${window.location.host}/${dashboard.clientData?.key}/${channel.key}/${dashboard.contentData?.key}`,
				icon: "fal fa-globe",
				key: "preview",
			});
		}
	}

	let accessibleChannelsList = dashboard.channelsData;
	if ((action === "add" || action === "copy") && dashboard.channelsData && appUserWhitelist) {
		accessibleChannelsList = dashboard.channelsData.filter(channel => appUserWhitelist.includes(channel.id));
	}

	const channelIsDisabled = (channelId: string) => {
		if (action === "edit" && appUserWhitelist) {
			return !appUserWhitelist.includes(channelId);
		} else {
			return false;
		}
	};

	return !isEmpty(formValues) ? (
		<SectionedPageContainer
			size="md"
			sections={sections}
			defaultSection={formSections.details}
			pageTitle={action !== "add" ? formValues.name : "New Content"}
			pageSubtitle={
				formValues?.contentType === ContentType.onDemand
					? "On-Demand"
					: formValues?.contentType === ContentType.live
						? "Live Content"
						: "Simulated Live"
			}
			footerLinks={footerLinks}
			htmlPageTitle="Content Configuration"
		>
			{isFormLocked && (
				<div className="alert alert-warning">
					This content has passed and can no longer be edited.{" "}
					{formValues.convertedToOnDemandId && (
						<span>
							It has been converted to on-demand content{" "}
							<a href={`/dashboard/${dashboard.clientId}/contents/${formValues.convertedToOnDemandId}`}>
								here
							</a>
							.
						</span>
					)}
				</div>
			)}

			<Form
				initialValues={formValues}
				validate={validate}
				onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
			>
				{({ formikContext, extraData }: FormContext<FormContent>) => {
					const { values: formValues, dirty, setFieldValue } = formikContext;
					const { contentType, videoProvider, channelIds } = formValues;
					let isEmbed =
						(contentType === ContentType.live &&
							videoProvider !== VideoProvider.livingAsOne &&
							videoProvider !== VideoProvider.livingAsOneSingle) ||
						(contentType === ContentType.onDemand && videoProvider === VideoProvider.other);

					return (
						<>
							{dirty && <Form.Prompt isSaving={isSubmitting} />}
							{extraData.showSummary && !isEmpty(formikContext.errors) && (
								<Form.Summary errors={formikContext.errors} handleFormSections />
							)}

							<fieldset
								disabled={
									isSubmitting || (isFormLocked && user.appUser?.globalRole !== "owner") || isLoading
								}
							>
								<FormSection sectionKey={formSections.details}>
									<PageTitle>DETAILS</PageTitle>
									<Well>
										<Form.Field label="Name *" id="name" name="name" />
										<Form.Field
											component={Editor}
											label="Description *"
											id="description"
											name="description"
											darkMode={true}
											hasImageMenu
											hasHtmlMenu
											disabled={isSubmitting || isLoading}
											initialValue={formValues.description}
											onEditorChange={(content: string) => {
												formikContext.setFieldValue("description", content);
											}}
											className="darkmode-field"
										/>
										<div id="channelIds"></div>
										<h5 className="m-top p-top">CHANNELS</h5>
										{accessibleChannelsList?.map(channel => {
											return (
												<div className="channel-checkbox" key={channel.id}>
													<Form.Input.Checkbox
														label={channel.name}
														id={`channels[${channel.id}]`}
														name={`channels[${channel.id}]`}
														value={channelIds?.includes(channel.id) || false}
														disabled={channelIsDisabled(channel.id)}
														onChecked={() => {
															let data = channelIds.concat();
															data.push(channel.id);
															formikContext.setFieldValue("channelIds", data);
														}}
														onUnchecked={() => {
															let data = channelIds.concat();
															const index = data.indexOf(channel.id);

															if (index > -1) {
																data.splice(index, 1);
															}

															compareRules(channel, formikContext);
															formikContext.setFieldValue("channelIds", data);
														}}
													/>
												</div>
											);
										})}
									</Well>
								</FormSection>
								<FormSection sectionKey={formSections.availability}>
									<div className="text-center form-section-title">
										<PageTitle>Visibility</PageTitle>
									</div>
									<Well message="Private content is only accessible via a direct link and will not be indexed by search engines.">
										<>
											<Form.Field
												component={Form.Input.Checkbox}
												label="MAKE THIS CONTENT PRIVATE"
												id="isPrivate"
												name="isPrivate"
											/>
										</>
									</Well>
									<div className="text-center form-section-title">
										<PageTitle>Availability</PageTitle>
									</div>
									<Well message="Control how and when your content is available to the public. Private content is only accessible via a direct link and will not be indexed by search engines.">
										<>
											<Form.Field
												component={Form.Input.Select}
												label="Make my content available"
												id="scheduleSetting"
												name="scheduleSetting"
												value={scheduleOption}
												onChange={(option: any) => {
													setScheduleOption(option.value);
													if (option.value === "0") {
														setFieldValue("showAt", null);
														setFieldValue("hideAt", null);
													} else if (option.value === "1") {
														setFieldValue("hideAt", null);
													} else if (option.value === "3") {
														setFieldValue("showAt", null);
													}
												}}
												data={[
													{ value: "0", label: "Always" },
													{ value: "1", label: "After a specified date" },
													{ value: "2", label: "Between two dates" },
													{ value: "3", label: "Until a specified date" },
												]}
												isSearchable={false}
											/>
											{scheduleOption !== "0" && (
												<Row>
													<Col xs={12} sm={6}>
														{scheduleOption === "1" || scheduleOption === "2" ? (
															<Form.Field
																type="date"
																component={Form.Input.DateTime}
																label="After"
																id={`showAt`}
																name={`showAt`}
																displayTimeZone={
																	formValues.timeZoneName ||
																	dashboard?.clientData?.timeZoneName
																}
															/>
														) : null}
													</Col>
													<Col xs={12} sm={6}>
														{scheduleOption === "2" || scheduleOption === "3" ? (
															<Form.Field
																type="date"
																component={Form.Input.DateTime}
																label="Until"
																id={`hideAt`}
																name={`hideAt`}
																displayTimeZone={
																	formValues.timeZoneName ||
																	dashboard?.clientData?.timeZoneName
																}
															/>
														) : null}
													</Col>
												</Row>
											)}
										</>
									</Well>
									<Sections.ViewerAccess
										isEnabled={formikContext.values.videoAccessRuleEnabled}
										formikContext={formikContext}
										rules={formValues.rules}
										channels={
											formValues.channelIds
												? dashboard.channelsData?.filter(chan =>
													formValues.channelIds.includes(chan.id)
												)
												: null
										}
									/>
								</FormSection>
								<FormSection sectionKey={formSections.video}>
									{contentType === ContentType.onDemand && (
										<Sections.Video.OnDemand
											formikContext={formikContext}
											isEmbed={isEmbed}
											timeZoneName={
												formValues.timeZoneName || dashboard?.clientData?.timeZoneName
											}
											clientId={dashboard.clientId}
										/>
									)}
									{contentType === ContentType.live && (
										<Sections.Video.Live
											formikContext={formikContext}
											isEmbed={isEmbed}
											timeZoneName={
												formValues.timeZoneName || dashboard?.clientData?.timeZoneName
											}
											clientId={dashboard.clientId}
										/>
									)}
									{contentType === ContentType.simulatedLive && (
										<Sections.Video.Simlive
											formikContext={formikContext}
											isEmbed={isEmbed}
											timeZoneName={
												formValues.timeZoneName || dashboard?.clientData?.timeZoneName
											}
											clientId={dashboard.clientId}
										/>
									)}

									<Sections.Relations
										formikContext={formikContext}
										clientId={dashboard.clientId!}
										tags={tags}
										allTags={dashboard.tagsData!}
										relatedContents={relatedContents}
										allContents={dashboard.contentsData!}
										onRelatedContentAdd={addedTag => {
											if (addedTag) {
												const newAddedRelatedContentIds = [...addedRelatedContentIds];
												!newAddedRelatedContentIds.includes(addedTag.id) &&
													newAddedRelatedContentIds.push(addedTag.id);
												setAddedRelatedContentIds(newAddedRelatedContentIds);
											}
										}}
										onRelatedContentDelete={(index, deletedTag) => {
											if (deletedTag) {
												const newDeletedRelatedContentIds = [...deletedRelatedContentIds];
												!newDeletedRelatedContentIds.includes(deletedTag.id) &&
													newDeletedRelatedContentIds.push(deletedTag.id);
												setDeletedRelatedContentIds(newDeletedRelatedContentIds);
											}
										}}
									/>
									{formValues.convertedFromId && (
										<div className="alert alert-warning">
											This on-demand content was converted from{" "}
											<a
												href={`/dashboard/${dashboard.clientId}/contents/${formValues.convertedFromId}`}
											>
												this original simulated live content
											</a>
											.
										</div>
									)}
									{contentType === ContentType.simulatedLive && dashboard.channelsData && (
										<Sections.OnDemandAvailability
											convertToOnDemand={formValues.convertToOnDemand}
											formikContext={formikContext}
											channelsData={dashboard.channelsData}
											isSubmitting={isSubmitting || isLoading}
											formValues={formValues}
										/>
									)}
								</FormSection>

								<FormSection sectionKey={formSections.engagement}>
									<Sections.DefaultPanel />

									{contentType !== ContentType.onDemand && (
										<Sections.Reactions
											isEnabled={formValues.reactions.enabled}
											reactions={formValues.reactions}
										/>
									)}

									<Sections.Chat isEnabled={formValues.chat.enabled} />

									<Sections.Bible
										isEnabled={formValues.bible.enabled}
										formikContext={formikContext}
									/>

									<Sections.Notes
										isEnabled={formValues.notes.enabled}
										isSubmitting={isSubmitting || isLoading}
										formikContext={formikContext}
									/>
									<Sections.Schedule isEnabled={formValues.schedule.enabled} />
									{!dashboard.channelsData
										?.filter(chan => formValues.channelIds.includes(chan.id))
										.every(chan => chan.isPrivate) && <Sections.Share />}
								</FormSection>
								<FormSection sectionKey={formSections.links}>
									<Sections.Links
										name="links"
										disabled={isSubmitting || isLoading}
										formikContext={formikContext}
										isContentForm
										learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238049808525-Adding-Links-and-Giving-to-Brushfire-Online"
										wellMessage="Links appear below your content. Create calls-to-action that encourage engagement, next steps, or promote related content."
									/>

									<Sections.Links
										name="socialLinks"
										formikContext={formikContext}
										wellMessage="Connect viewers to social networks for your organization or speakers."
										learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238049808525-Adding-Links-and-Giving-to-Brushfire-Online"
									/>
								</FormSection>
								<FormSection sectionKey={formSections.interactions}>
									{contentType !== ContentType.onDemand && (
										<Sections.Interactions
											name="interactions"
											disabled={isSubmitting || isLoading}
											formikContext={formikContext}
											timeZone={formValues.timeZoneName || dashboard.clientData?.timeZoneName}
											onInteractionDelete={(id: string) => onInteractionDelete(id)}
											wellMessage="Provide opportunities for viewers to make a decision, answer questions, and more."
											learnMoreLink="https://brushfire.zendesk.com/hc/en-us/articles/360013148520"
										/>
									)}
								</FormSection>

								<Sticky>
									<FormSubmitButton
										disabled={
											isSubmitting ||
											(isFormLocked && user.appUser?.globalRole !== "owner") ||
											isLoading
										}
										isSubmitting={isSubmitting}
										isAddForm={action !== "edit"}
									/>
								</Sticky>
							</fieldset>
						</>
					);
				}}
			</Form>
		</SectionedPageContainer>
	) : (
		<PageContainer htmlPageTitle="Content Configuration">
			<div style={{ color: "white", fontSize: 100 }}>404</div>
			<div style={{ color: "white" }}>Content not found.</div>
		</PageContainer>
	);
};

export default ContentForm;
