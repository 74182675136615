import { createLogic } from "redux-logic";
import api from "../../../services/api";
import { logoutUser, setAppUser } from "../actions";
import { LOGIN_SUCCESS, LOGIN_SUCCESS_ACTION } from "../types";

const loginSuccessLogic = createLogic({
	type: LOGIN_SUCCESS,
	latest: true,
	// eslint-disable-next-line no-unused-vars
	process({ getState, action }, dispatch, done) {
		let data = action as LOGIN_SUCCESS_ACTION;
		const state: any = getState();
		if (!data.payload?.isAnonymous) {
			api.fetchUser(data.payload?.uid, window.location?.pathname?.includes("/dashboard"))
				.then(data => {
					if (data.status === 200) {
						dispatch(setAppUser(data.data));
					} else {
						console.warn("Status not 200", data);
						if (window.location?.pathname?.includes("/dashboard")) {
							//@ts-ignore
							dispatch(
								//@ts-ignore
								logoutUser(
									`No account Found. Please <a href="https://www.brushfire.com/features/online-experience" target="_blank" rel="noopener noreferrer">sign up</a>`
								)
							);
						}
					}
				})
				.catch(err => { })
				.finally(() => {
					done();
				});
		} else {
			dispatch(setAppUser(undefined));
			done();
		}
	},
});

export default [loginSuccessLogic];
