import React from "react";
import { FormikContextType } from "formik";

import { PageTitle, Well } from "../../";
import { FormContent as ContentFormContent } from "../../../Contents/Form/Form";
import Form from "../../../../../components/Form";
import Editor from "../../../../../components/Form/Editor";

import * as Styled from "../ContentFormSections.styled";

export interface NotesSectionProps {
	isEnabled: boolean;
	isSubmitting: boolean;
	formikContext: FormikContextType<ContentFormContent>;
}

const NotesSection: React.FC<NotesSectionProps> = ({ isEnabled, isSubmitting, formikContext }) => {
	const {
		values: { notes },
	} = formikContext;

	return (
		<>
			<Styled.SectionHeader className="text-center form-section-title">
				<PageTitle>NOTES</PageTitle>
				<Form.Field
					component={Form.Input.Checkbox}
					label=""
					id="notes.enabled"
					name="notes.enabled"
					switchToggle={true}
				/>
			</Styled.SectionHeader>
			<div style={{ display: isEnabled ? "block" : "none" }}>
				<Well
					message="Give viewers the opportunity to take notes that can include pre-filled templates and follow up information or actions."
					learnMoreLink="https://support.brushfire.com/hc/en-us/articles/360013074419-How-to-use-the-Notes-Feature"
				>
					<Form.Field
						component={Editor}
						darkMode={true}
						hasHtmlMenu
						label="Notes Content"
						id="notes.defaultContent"
						name="notes.defaultContent"
						disabled={isSubmitting}
						initialValue={notes.defaultContent}
						onEditorChange={(content: string) => {
							formikContext.setFieldValue("notes.defaultContent", content);
						}}
						className="darkmode-field"
					/>
				</Well>
			</div>
			<div style={{ display: !isEnabled ? "block" : "none" }}>
				<Well>
					<p>
						Give viewers the opportunity to take notes that can include pre-filled templates and follow up
						information or actions.{" "}
						<span>
							<a
								href="https://support.brushfire.com/hc/en-us/articles/360013074419-How-to-use-the-Notes-Feature"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <i className="fal fa-long-arrow-right" />
							</a>
						</span>
					</p>
				</Well>
			</div>
		</>
	);
};

export default NotesSection;
