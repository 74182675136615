import {
	SET_CHANNEL_DATA,
	SET_CHANNEL_DATA_VALUE,
	SAVE_CHANNEL_DATA,
	ChannelActionTypes,
	UPDATE_PROFILE_IMAGE_TIMESTAMPS,
	ProfileImages,
} from "./types";
import { PayloadAction } from "typesafe-actions";
import { ChannelData } from "../../interfaces";

export function setChannelData(data: ChannelData): PayloadAction<string, ChannelData> {
	return {
		type: SET_CHANNEL_DATA,
		payload: data,
	};
}

export function setChannelDataValue(data: Partial<ChannelData>): ChannelActionTypes {
	return {
		type: SET_CHANNEL_DATA_VALUE,
		payload: data,
	};
}

export function saveChannelData(data: ChannelData): PayloadAction<string, ChannelData> {
	return {
		type: SAVE_CHANNEL_DATA,
		payload: data,
	};
}

export function updateChannelProfileImages(images: ProfileImages): PayloadAction<string, ProfileImages> {
	return {
		type: UPDATE_PROFILE_IMAGE_TIMESTAMPS,
		payload: images,
	};
}
