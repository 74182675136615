import React from "react";
import ScheduleItem from "./ScheduleItem";
import { Content } from "../../interfaces";
import * as Styled from "./ScheduleItem.styled";
import _ from "lodash";
import moment from "moment";

interface ScheduleProps {
	scheduledContent: Content[];
	clientKey?: string;
	channelKey?: string;
}

const Schedule: React.FC<ScheduleProps> = ({ scheduledContent, channelKey, clientKey }) => {
	const sortUpcomingContent = () => {
		const groupByDate = (item: any) => moment(item.startsAt).format("dddd, MMMM D, YYYY");
		const groupedObj = _.groupBy(scheduledContent, groupByDate);

		//sort by timestamp in ascending order
		Object.keys(groupedObj).map(key => {
			groupedObj[key] = groupedObj[key].sort((a, b) => {
				return moment.utc((a.startsAt, "hh:mm")).diff(moment.utc(b.startsAt, "hh:mm"));
			});
		});

		return groupedObj;
	};

	const sortedContent = sortUpcomingContent();

	return (
		<div>
			{Object.keys(sortedContent).map((groupDate, i) => {
				return (
					<div key={groupDate} className="schedule-items-group m-2">
						<Styled.ScheduleItemsGroupDate>
							<p>{moment(groupDate).format("dddd")}</p>
							<p>{moment(groupDate).format("MMMM D, YYYY")}</p>
						</Styled.ScheduleItemsGroupDate>
						{sortedContent[groupDate].map((content: Content) => {
							return (
								<ScheduleItem
									key={content.id}
									content={content}
									channelKey={channelKey}
									clientKey={clientKey}
								/>
							);
						})}
					</div>
				);
			})}
			{!scheduledContent?.length && (
				<Styled.NoScheduledContent>
					<i className={`fal fa-calendar-alt`} style={{ fontSize: 50 }} />
					<p className="mt-2">No Scheduled Content</p>
				</Styled.NoScheduledContent>
			)}
		</div>
	);
};

export default Schedule;
