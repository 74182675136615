// Feel free to rename this function, not the best
export const addPreText = (value: string, action: string) => {
	let text;
	if (action === "copy") {
		text = `Copy of ${value}`;
	} else {
		text = value;
	}
	return text;
};
