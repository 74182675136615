import { FormikContextType } from "formik";
import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import * as Yup from "yup";
import { REGEX } from "../../constants";
import { Theme } from "../../interfaces";
import { IconButton } from "../Buttons/IconButton";
import Form from "../Form";

interface ChatLoginStep0Props {
	onAction: (providerName: string, values?: AnonValues) => void;
	theme: Theme;
	enableAnonymous: boolean;
	isDirect: boolean;
}

interface AnonValues {
	email?: string;
	name: string;
}

function fadedColorFromHex(hex: string) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		//@ts-ignore
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.7)";
	}
	throw new Error("Bad Hex");
}

const style = {
	ChatSignIn: styled.div`
		label {
			color: ${({ theme }) => theme.wellTextColor};
		}
		.field {
			input {
				pointer-events: all;
				-webkit-appearance: none;
				color: ${({ theme }) => theme.wellTextColor} !important;
				background-color: ${({ theme }) => theme.wellBackgroundColor} !important;

				::placeholder {
					/* Chrome, Firefox, Opera, Safari 10.1+ */
					color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
					opacity: 1; /* Firefox */
				}

				:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
				}

				::-ms-input-placeholder {
					/* Microsoft Edge */
					color: ${({ theme }) => fadedColorFromHex(theme.wellBackgroundColor)};
				}
			}
		}
	`,
};

const ChatLoginStep0: React.FC<ChatLoginStep0Props> = ({ onAction, theme, enableAnonymous, isDirect }) => {
	const [showGuest, setShowGuest] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onClick = (providerName: string) => {
		onAction(providerName);
	};

	const onSubmit = (values: AnonValues) => {
		onAction("anonymous", values);
	};

	let anonymousSchema = Yup.object().shape({
		email: Yup.string().matches(REGEX.EMAIL, "Invalid Email"),
		name: Yup.string().required("Name Required"),
	});

	if (!enableAnonymous) {
		anonymousSchema = Yup.object().shape({
			email: Yup.string()
				.required("Email Required")
				.matches(REGEX.EMAIL, "Invalid Email"),
			name: Yup.string().required("Name Required"),
		});
	}

	return (
		<div className="chat-login">
			<div className={`area-reveal md ${showGuest ? "" : "active m-bottom"}`}>
				<h2 style={{ textAlign: "center", margin: "10px 0" }}>
					{isDirect ? "Starting One-on-One Chat" : "Chat with us!"}
				</h2>
				<IconButton
					variant={"dark"}
					className="btn-block"
					icon="google"
					iconWeight="fab"
					onClick={() => onClick("google")}
				>
					Join With Google
				</IconButton>

				<IconButton
					variant={"dark"}
					className="btn-block"
					icon="facebook"
					iconWeight="fab"
					onClick={() => onClick("facebook")}
				>
					Join With Facebook
				</IconButton>
			</div>

			<IconButton
				variant={"dark"}
				className="btn-block"
				icon={showGuest ? "chevron-left" : "comment-lines"}
				iconWeight="fas"
				onClick={() => setShowGuest(!showGuest)}
			>
				{showGuest ? "Join Another Way" : "Join As Guest"}
			</IconButton>
			<style.ChatSignIn className={`area-reveal md ${showGuest ? "active" : ""}`}>
				<Form
					initialValues={{ email: "", password: "" }}
					validationSchema={anonymousSchema}
					onSubmit={onSubmit}
				>
					{({ formikContext }: { formikContext: FormikContextType<AnonValues> }) => {
						return (
							<fieldset className="m-1" disabled={isSubmitting}>
								<Form.Field label="Email" id="email" name="email" />
								<Form.Field label="Name" id="name" name="name" />
								<button type="submit" className={`btn btn-block btn-${"dark"}`}>
									Join
								</button>
							</fieldset>
						);
					}}
				</Form>
			</style.ChatSignIn>
		</div>
	);
};

export default withTheme(ChatLoginStep0);
