import { ChannelData } from "../../interfaces";

export interface ProfileImages {
	[uid: string]: string;
}

export interface ChannelState {
	data?: ChannelData;
	images: ProfileImages;
}

export const SET_CHANNEL_DATA: string = "SET_CHANNEL_DATA";
export const SET_CHANNEL_DATA_VALUE: string = "SET_CHANNEL_DATA_VALUE";
export const CANCEL_SET_CHANNEL_DATA_VALUE: string = "CANCEL_SET_CHANNEL_DATA_VALUE";
export const SAVE_CHANNEL_DATA: string = "SAVE_CHANNEL_DATA";
export const CANCEL_SAVE_CHANNEL_DATA: string = "CANCEL_SAVE_CHANNEL_DATA";
export const UPDATE_PROFILE_IMAGE_TIMESTAMPS: string = "UPDATE_PROFILE_IMAGE_TIMESTAMPS";

interface SetChannelData {
	type: typeof SET_CHANNEL_DATA;
	payload: ChannelData;
}

interface SetChannelDataValue {
	type: typeof SET_CHANNEL_DATA_VALUE;
	step: string;
	payload: Partial<ChannelData>;
	saveData: boolean;
}

interface SaveChannelData {
	type: typeof SAVE_CHANNEL_DATA;
	payload: Partial<ChannelData>;
}

export type SetChannelDataValueAction = SetChannelDataValue;

export type ChannelActionTypes = SetChannelData | SetChannelDataValue | SaveChannelData;
