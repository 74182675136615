import React from "react";

interface HorizontalRuleProps {
	classNames?: string;
}

const HorizontalRule: React.FC<HorizontalRuleProps> = ({ children, classNames = "" }) => {
	return <div className={`horizontal-rule ${classNames}`}>{children && <div className="horizontal-rule-label">{children}</div>}</div>;
};

export default HorizontalRule;
