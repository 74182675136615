import React, { useContext, useState } from "react";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { isEmpty, cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

import Form from "../../../../../components/Form";
import Editor from "../../../../../components/Form/Editor";
import { Interaction, Response } from "../../../../../interfaces";

import * as Styled from "./Responses.styled";
import * as InteractionsStyled from "./InteractionsSection.styled";
import * as LinksStyled from "../Links/LinksSection.styled";
import { CodeProvider } from "../Links/CodeProvider";

interface ResponseHeaderProps {
	addResponseClick: () => void;
	isDisabled: boolean;
}

interface ResponseSectionProps {
	interactionIndex: number;
	isHostManager?: boolean;
	formikContext: any;
	name: string;
}

interface ResponseCardProps {
	arrayHelpers: FieldArrayRenderProps;
	interaction: Interaction;
	responseIndex: number;
	response: Response;
	responseLength: number;
	arrayName: string;
	formikContext: any;
}

const responseActionValues = [
	{ value: "textOnly", label: "Text Only" },
	{ value: "collectEmail", label: "Collect Email" },
	{ value: "startChat", label: "Start Chat" },
	// { value: "runCode", label: "Run Code" },
	{ value: "url", label: "URL" },
	{ value: "dismiss", label: "Dismiss" },
	{ value: "customContent", label: "Custom Content" },
];

const ResponseHeader: React.FC<ResponseHeaderProps> = ({ addResponseClick, isDisabled = false }) => {
	return (
		<InteractionsStyled.Header>
			<Styled.SectionHeader>Responses</Styled.SectionHeader>
			{!isDisabled && (
				<LinksStyled.ButtonContainer isExpanded={false} style={{ width: "40px", height: "35px" }}>
					<LinksStyled.Button
						isExpanded={false}
						onClick={addResponseClick}
						style={{ minWidth: "40px", width: "40px" }}
					>
						<i className="far fa-plus"></i>
					</LinksStyled.Button>
				</LinksStyled.ButtonContainer>
			)}
		</InteractionsStyled.Header>
	);
};

export const ResponseCard: React.FC<ResponseCardProps> = ({
	arrayHelpers,
	interaction,
	responseIndex,
	response,
	responseLength,
	arrayName,
	formikContext,
}) => {
	const responseArrayName = `${arrayName}[${responseIndex}]`;

	return (
		<>
			<Styled.CustomWell>
				<Styled.CardContents>
					<Form.Field
						label="Response Text"
						id={`${responseArrayName}.responseText`}
						name={`${responseArrayName}.responseText`}
					/>
					<Form.Field
						component={Form.Input.Select}
						label="Type of Action"
						id={`${responseArrayName}.actionType`}
						name={`${responseArrayName}.actionType`}
						data={responseActionValues}
						onChange={(value: any) => {
							if (value.value === "url") {
								formikContext.setFieldValue(`${responseArrayName}.url`, { url: "", target: "newTab" });
							}
						}}
					/>

					{!["dismiss", "startChat", "runCode", "url", "customContent"].includes(response.actionType) && (
						<Form.Field
							component={Editor}
							label={`After Response Text${response.actionType !== "collectEmail" ? " *" : ""}`}
							id={`${responseArrayName}.actionText`}
							name={`${responseArrayName}.actionText`}
							init={{ toolbar: "bold italic link" }}
							darkMode={true}
							hasHtmlMenu
							disabled={!!interaction.showAt}
							initialValue={response.actionText}
							onEditorChange={(content: string) => {
								formikContext.setFieldValue(`${responseArrayName}.actionText`, content);
							}}
							className="darkmode-field"
						/>
					)}
					{/* {response.actionType === "runCode" && (
						<CodeProvider name={responseArrayName} codeProvider={response.codeProvider} />
					)} */}
					{response.actionType === "customContent" && (
						<Form.Field
							component={Editor}
							label={`After Response Text`}
							id={`${responseArrayName}.actionText`}
							name={`${responseArrayName}.actionText`}
							init={{ toolbar: "bold italic link code", plugins: "code" }}
							darkMode={true}
							disabled={!!interaction.showAt}
							initialValue={response.actionText}
							onEditorChange={(content: string) => {
								formikContext.setFieldValue(`${responseArrayName}.actionText`, content);
							}}
							className="darkmode-field"
						/>
					)}
					{response.actionType === "url" && (
						<>
							<Form.Field
								label="External Link *"
								placeholder="Please enter a URL"
								id={`${responseArrayName}.url.url`}
								name={`${responseArrayName}.url.url`}
							/>
							<Form.Field
								component={Form.Input.Select}
								label="Target *"
								id={`${responseArrayName}.url.target`}
								name={`${responseArrayName}.url.target`}
								data={[
									{ value: "newTab", label: "Open in a new tab" },
									{ value: "iframeModal", label: "Open in a modal" },
								]}
								isSearchable={false}
							/>
						</>
					)}
				</Styled.CardContents>
				<Styled.ActionsContainer>
					{responseIndex > 0 && (
						<LinksStyled.ActionButton
							type="button"
							onClick={() => arrayHelpers.move(responseIndex, responseIndex - 1)}
						>
							<i className="fal fa-arrow-up" />
						</LinksStyled.ActionButton>
					)}
					{responseIndex < responseLength && (
						<LinksStyled.ActionButton
							type="button"
							onClick={() => arrayHelpers.move(responseIndex, responseIndex + 1)}
						>
							<i className="fal fa-arrow-down" />
						</LinksStyled.ActionButton>
					)}
					<LinksStyled.ActionButton
						type="button"
						onClick={() => {
							const newResponse = cloneDeep(response);
							newResponse.id = uuidv4();
							arrayHelpers.push(newResponse);
						}}
					>
						<i className="fal fa-copy" />
					</LinksStyled.ActionButton>
					<LinksStyled.ActionButton
						type="button"
						onClick={() => {
							arrayHelpers.remove(responseIndex);
						}}
					>
						<i className="fal fa-trash" />
					</LinksStyled.ActionButton>
				</Styled.ActionsContainer>
			</Styled.CustomWell>
		</>
	);
};

export const ResponseSection: React.FC<ResponseSectionProps> = ({
	interactionIndex,
	isHostManager,
	formikContext,
	name,
}) => {
	const [openResponse, setOpenResponse] = useState<string>();
	const arrayName = `${name}[${interactionIndex}].responses`;
	const interaction = formikContext.values[name][interactionIndex];
	const { responses } = interaction;

	return (
		<FieldArray
			name={arrayName}
			render={responseArrayHelpers => {
				return (
					<>
						<ResponseHeader
							isDisabled={!!interaction.showAt}
							addResponseClick={() => {
								const newId = uuidv4();
								responseArrayHelpers.push({
									id: newId,
									responseText: "",
									actionType: "textOnly",
									actionText: "",
								});
								setOpenResponse(newId);
							}}
						/>
						{!isEmpty(responses) &&
							responses.map((response: any, responseIndex: number) => {
								const actionTypeValue = responseActionValues.find(t => t.value === response.actionType);
								let actionType = "";
								if (actionTypeValue) {
									actionType = actionTypeValue.label;
								}
								return (
									<>
										{!isHostManager && (
											<Styled.ResponseButton
												key={response.id}
												isActive={openResponse === response.id}
												onClick={() => {
													if (openResponse === response.id) {
														setOpenResponse(undefined);
													} else {
														setOpenResponse(response.id);
													}
												}}
											>
												<Styled.ResponseTitle>
													{response.responseText || "New Response"}
												</Styled.ResponseTitle>
												<Styled.ResponseType>{actionType}</Styled.ResponseType>
											</Styled.ResponseButton>
										)}
										{(isHostManager || response.id === openResponse) && (
											<ResponseCard
												arrayHelpers={responseArrayHelpers}
												interaction={interaction}
												responseIndex={responseIndex}
												response={response}
												responseLength={responses.length}
												arrayName={arrayName}
												formikContext={formikContext}
											/>
										)}
									</>
								);
							})}
					</>
				);
			}}
		/>
	);
};
