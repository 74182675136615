import { isEmpty } from "lodash";
import moment from "moment";
import React, { FC, useState, useContext } from "react";
import { isTablet } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { withTheme } from "styled-components";
import authFirebaseApp from "../../authFirebase";
import { Content, CustomPanel, Link, Theme } from "../../interfaces";
import { logEvent } from "../../services/analytics";
import { createChatRequest } from "../../services/dataHelpers";
import HTMLModal from "../Modals/HTMLModal";
import IFrameModal from "../Modals/IFrameModal";
import { LinkButton } from "./Links.styled";
import { codeProviderHelper, elementRender, LinkElement, whichProvider } from "./linksHelper";
import { useMediaQuery } from "react-responsive";
import { MobilePanelsContext } from "../MobilePanels/MobilePanelBar";

interface LinksProps {
	theme: Theme;
	mobile?: boolean;
	content: Content;
	onChatClickWhenChatIsClosed?: () => void;
	onChatClick?: () => void;
	selectExistingHostChat: () => void;
	customPanel: CustomPanel | undefined;
}

const Links: FC<LinksProps> = ({
	theme,
	mobile = false,
	content,
	onChatClickWhenChatIsClosed,
	onChatClick,
	selectExistingHostChat,
	customPanel,
}) => {
	const { setMenuOpen } = useContext(MobilePanelsContext);
	const { links, id: contentId, chat } = content;
	const [modalObject, setModalObject] = useState({
		visible: false,
		html: "",
		allowScript: false,
		title: "",
		icon: "",
	});
	const [iframeModalObject, setIframeModalObject] = useState({ visible: false, src: "", title: "", icon: "" });
	const isMobileAndTablet = useMediaQuery({ query: "(max-width: 992px)" });

	const now = moment.utc();
	const isChatOpen =
		chat.startsAt && chat.endsAt ? now.isBetween(chat.startsAt, chat.endsAt, undefined, "[]") : false;

	const onClick = async (link: Link, index: number) => {
		setMenuOpen(false);
		const { url, html, code, text, icon } = link;

		logEvent("click_content_link", text);

		if (url) {
			switch (url?.target) {
				case "newTab": {
					window.open(url.url, "_blank");
					break;
				}
				case "sameTab": {
					window.open(url.url, "_self");
					break;
				}
				case "iframeModal": {
					setIframeModalObject({
						visible: true,
						src: url.url,
						title: text,
						icon,
					});
					break;
				}
				case "sideBar": {
					customPanel?.open({
						key: `custom-panel-${index}`,
						icon: icon || "fa-star",
						content: <iframe style={styles.iframe} title={text} src={url.url} />,
						title: text,
					});
					break;
				}
				default: {
					return null;
				}
			}
		} else if (html) {
			setModalObject(prevModalObject => ({ ...prevModalObject, visible: true, html }));
		} else if (code) {
			if (code === "chat") {
				const user = authFirebaseApp.auth().currentUser;
				if (!isChatOpen && onChatClickWhenChatIsClosed) {
					onChatClickWhenChatIsClosed();
				} else if (user && contentId) {
					if (onChatClick && isChatOpen) {
						onChatClick();
					}

					const result = await createChatRequest(
						contentId,
						user.uid,
						user.displayName || "Anonymous",
						`Clicked "${link.text}"`,
						user.photoURL
					);
					if (result === false) {
						selectExistingHostChat();
					}
				}
			} else {
				const provider = whichProvider(link);
				switch (provider) {
					case "onlineGiving": {
						setIframeModalObject({
							visible: true,
							src: `https://${code}.onlinegiving.org/donate/guest_donate?embed=1`,
							title: text,
							icon,
						});
						break;
					}
					case "paperlessTransactions": {
						setIframeModalObject({
							visible: true,
							src: `https://virtuous.paperlesstrans.com/${code}`,
							title: text,
							icon,
						});
						break;
					}
					case "givelify": {
						setIframeModalObject({
							visible: true,
							src: code,
							title: text,
							icon,
						});
						break;
					}
					default: {
						setModalObject({
							visible: true,
							html: code,
							allowScript: true,
							title: text,
							icon,
						});
						break;
					}
				}
			}
		}
	};

	const closeModal = () => {
		setModalObject({
			visible: false,
			html: "",
			allowScript: false,
			title: "",
			icon: "",
		});
		setIframeModalObject({
			visible: false,
			src: "",
			title: "",
			icon: "",
		});
	};

	const renderLinks = (codeLinkElements: LinkElement[]) => {
		return links.map((link, index) => {
			const { icon, text } = link;

			if (link.hasOwnProperty("code") && link.hasOwnProperty("codeProvider")) {
				const elementInfo = codeLinkElements.find(l => l.index === index);
				const provider = whichProvider(link);
				if (elementInfo && provider) {
					return elementRender(provider, elementInfo, { index, icon, isTablet });
				}
			}

			if (link.enabled === false) {
				return null;
			} else {
				return (
					<LinkButton key={`link-${index + 1}`} index={index} onClick={() => onClick(link, index)}>
						{icon ? <i className={`${icon} link-icon`} style={{ marginRight: 5 }} /> : null}
						{text ? (
							<p className={`${isTablet ? "isTablet" : ""}`} style={{ marginTop: 0, marginBottom: 0 }}>
								{isMobileAndTablet ? text : text?.toUpperCase()}
							</p>
						) : null}
					</LinkButton>
				);
			}
		});
	};

	const { codeLinkHeaders, codeLinkElements } = codeProviderHelper(links);

	return (
		<>
			{!isEmpty(codeLinkHeaders) && <Helmet>{codeLinkHeaders.map(h => h)};</Helmet>}
			{mobile ? (
				<div className="video-links-mobile">{renderLinks(codeLinkElements)}</div>
			) : (
				<div
					className="video-links-desktop"
					style={{
						backgroundColor: theme.buttonBackgroundColor,
					}}
				>
					{renderLinks(codeLinkElements)}
				</div>
			)}
			<HTMLModal
				content={modalObject.html}
				visibility={modalObject.visible}
				closeModal={closeModal}
				allowScript={modalObject.allowScript}
				title={modalObject.title}
				icon={modalObject.icon}
			/>
			<IFrameModal
				visible={iframeModalObject.visible}
				closeModal={closeModal}
				src={iframeModalObject.src}
				title={iframeModalObject.title}
				icon={iframeModalObject.icon}
			/>
		</>
	);
};

const styles = {
	iframe: {
		width: "100%",
		height: "100%",
		border: 0,
		position: "absolute" as "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
};

export default withTheme(Links);
