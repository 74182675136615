import React, { useState, useEffect } from "react";
import { usePrevious } from "../../../../hooks";
import { FieldProps } from "formik";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import Datetime, { DatetimepickerProps } from "react-datetime";
import moment from "moment-timezone";

import "react-datepicker/dist/react-datepicker.css";

export interface DateTimePickerProps extends DatetimepickerProps {
	dateRef?: React.RefObject<any>;
	field: FieldProps["field"];
	form: FieldProps["form"];
	id: string;
	name: string;
	timeCaption?: string;
	timeRef?: React.RefObject<any>;
	[key: string]: any;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
	dateRef,
	field,
	form,
	id,
	name,
	timeCaption,
	timeRef,
	className,
	displayTimeZone = "America/Chicago",
	...props
}) => {
	const prevField = usePrevious(field.value);
	const [dateFocused, setDateFocused] = useState(false);
	const [timeFocused, setTimeFocused] = useState(false);
	const [date, setDate] = useState("");

	useEffect(() => {
		if (prevField !== field.value) {
			setDate(field.value);
		}
	}, [prevField, field.value]);

	const onChange = (dateTime: any) => {
		if (!dateTime) {
			setDate("");
		} else {
			setDate(dateTime);
		}

		setField(dateTime);
	};

	const setField = (date: moment.Moment | string) => {
		if (!date) {
			form.setFieldValue(field.name, null);
		} else {
			if (moment.isMoment(date)) {
				form.setFieldValue(field.name, date.toDate());
			} else {
				form.setFieldValue(field.name, moment(date).toDate());
			}
		}
	};

	return (
		<div
			className={`datetime-picker ${className ? className : ""} ${dateFocused ? "date-active" : ""} ${
				timeFocused ? "time-active" : ""
			}`}
		>
			<div className="date-picker-container">
				<Datetime
					defaultValue={date ? date : ""}
					key={!date ? "dateNotLoadedYet" : "dateLoaded"}
					onChange={onChange}
					ref={dateRef}
					className={`date-picker${dateFocused ? " active" : ""}`}
					onFocus={() => setDateFocused(true)}
					onBlur={() => setDateFocused(false)}
					displayTimeZone={displayTimeZone}
					// onChangeRaw={(e: any) => e.preventDefault()}
					// isClearable
				/>
			</div>
		</div>
	);
};

export default DateTimePicker;
