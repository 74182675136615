import { Content, AttendeeData } from "../interfaces";

const attendeeHasContentAccess = (content: Content, attendeeData: AttendeeData) => {
	if (content.rules) {
		return content.rules.every(rule => {
			const ruleFields = Object.keys(rule.fields);
			if (rule.attendeeTypeIds.length === 0 && Object.keys(rule.fields).length === 0) {
				return true;
			} else if (
				rule.attendeeTypeIds.includes(attendeeData.TypeId) ||
				attendeeData.Fields.some(field => field.OptionIds.some(option => ruleFields.includes(option)))
			) {
				return true;
			}
			return false;
		});
	} else {
		return true;
	}
};

export default attendeeHasContentAccess;
