import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchChannel, fetchContents } from "../../../services/dataHelpers";
import { Channel } from "../../../interfaces";

import {
	CardContainer,
	ThumbImageContainer,
	ThumbImage,
	CardInnerContainer,
	NameWrapper,
	Name,
	CardPrivatePill,
	IconContainer,
	IconWrapper,
} from "./ChannelCard.styled";
import { TypedUseSelectorHook } from "../../../redux";
import userHasRole from "../../../services/userHasRole";

interface ChannelCardProps {
	clientId?: string;
	clientKey?: string;
	channel: Channel;
	onDelete: any;
	reOrder: any;
	channelCount: number;
	index: number;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;

const ChannelCard: React.FC<ChannelCardProps> = ({
	clientId,
	clientKey,
	onDelete,
	channel,
	reOrder,
	channelCount,
	index,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { id, key, name, isPrivate, thumbImage, displayOrder } = channel;
	const isLastChannel = channelCount - 1 === index;

	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);

	const isAdmin =
		dashboard.clientId && user.appUser
			? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"], id)
			: false;

	const onClick = (type: string) => {
		switch (type) {
			case "edit":
				fetchChannel(id, dispatch);
				history.push(`channels/${id}`);
				break;
			case "copy":
				fetchChannel(id, dispatch);
				history.push(`channels/copy/${id}`);
				break;
			case "delete":
				onDelete(id, name);
				break;
			case "view":
				fetchChannel(id, dispatch);
				if (clientId) {
					fetchContents(clientId, dispatch);
				}
				history.push(`/dashboard/${clientId}/contents`, { channelId: id });
				break;
			case "preview":
				openPreviewWindow();
				break;

			default:
				return null;
		}
	};

	const hasAllChannelAccess = () => {
		if (dashboard.clientId && user.appUser?.whitelist) {
			return (
				user.appUser?.whitelist[dashboard.clientId] === null ||
				user.appUser?.whitelist[dashboard.clientId]?.length === channelCount
			);
		} else {
			return true;
		}
	};

	const openPreviewWindow = () => {
		let url = "//" + window.location.host;
		window.open(`${url}/${clientKey}/${key}`, "_blank");
	};

	const popover = (
		<Popover id={`channel-popover-${channel.id}`} className="channel-popover">
			<Popover.Content>
				<div className="popover-item" onClick={() => onClick("edit")}>
					<div className="icon-wrapper">
						<i className="fal fa-pencil" />
					</div>
					<p>Edit</p>
				</div>
				<div className="popover-item" onClick={() => onClick("copy")}>
					<div className="icon-wrapper">
						<i className="fal fa-copy" />
					</div>
					<p>Copy</p>
				</div>
				<div className="popover-item" onClick={() => onClick("delete")}>
					<div className="icon-wrapper">
						<i className="fal fa-trash" />
					</div>
					<p>Delete</p>
				</div>
				<div className="border" />
				<div className="popover-item" onClick={() => onClick("preview")}>
					<div className="icon-wrapper">
						<i className="fal fa-globe" />
					</div>
					<p>Preview</p>
				</div>
				{channelCount > 1 && hasAllChannelAccess() && (
					<>
						<div className="border" />
						{index > 0 && (
							<div className="popover-item" onClick={() => reOrder("up", index)}>
								<div className="icon-wrapper">
									<i className="fal fa-arrow-up" />
								</div>
								<p>Move Up</p>
							</div>
						)}
						{!isLastChannel && (
							<div className="popover-item" onClick={() => reOrder("down", index)}>
								<div className="icon-wrapper">
									<i className="fal fa-arrow-down" />
								</div>
								<p>Move Down</p>
							</div>
						)}
					</>
				)}
			</Popover.Content>
		</Popover>
	);

	const viewContentPopover = (
		<Popover id={`channel-popover-${channel.id}-hover`} className="channel-popover channel-popover-hover">
			<Popover.Content>
				<div className="popover-item">
					<p>View Contents in this Channel</p>
				</div>
			</Popover.Content>
		</Popover>
	);

	const viewPreviewPopover = (
		<Popover id={`channel-popover-${channel.id}`} className="channel-popover channel-popover-hover">
			<Popover.Content>
				<div className="popover-item">
					<p>Preview Channel</p>
				</div>
			</Popover.Content>
		</Popover>
	);

	return (
		<CardContainer>
			<ThumbImageContainer onClick={() => onClick("view")}>
				<ThumbImage src={thumbImage || "/content/img/channel-default@2x.png"} alt={name} />
			</ThumbImageContainer>
			<CardInnerContainer>
				<NameWrapper>
					<Name onClick={() => onClick("view")}>{name}</Name>
					{isPrivate && (
						<CardPrivatePill>
							<i className="fal fa-lock" />
							Private
						</CardPrivatePill>
					)}
				</NameWrapper>

				<IconContainer>
					<OverlayTrigger
						key={`channel-popover-${channel.id}-hover`}
						trigger="hover"
						placement="left"
						overlay={viewContentPopover}
						rootClose
					>
						<IconWrapper onClick={() => onClick("view")}>
							<i className="fal fa-tv-alt view-content-icon" />
						</IconWrapper>
					</OverlayTrigger>
					{isAdmin ? (
						<OverlayTrigger
							key={`channel-popover-${channel.id}`}
							trigger="click"
							placement="left"
							overlay={popover}
							rootClose
						>
							<IconWrapper>
								<i className="fal fa-ellipsis-v" />
							</IconWrapper>
						</OverlayTrigger>
					) : (
						<OverlayTrigger
							key={`channel-popover-${channel.id}`}
							trigger="hover"
							placement="left"
							overlay={viewPreviewPopover}
							rootClose
						>
							<IconWrapper onClick={() => onClick("preview")}>
								<i className="fal fa-globe view-content-icon" />
							</IconWrapper>
						</OverlayTrigger>
					)}
				</IconContainer>
			</CardInnerContainer>
		</CardContainer>
	);
};

export default ChannelCard;
