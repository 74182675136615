import React, { useState, useRef, useEffect } from "react";
import Reactions from "../Reactions";
import authFirebaseApp from "../../authFirebase";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Content, CustomPanel, Panels } from "../../interfaces";
import MobilePanelsMenu from "./MobilePanelsMenu";
import * as Styled from "./MobilePanelBar.styled";

interface MobilePanelBarProps {
	content: Content;
	setSelectedPanel: (panel: Panels) => void;
	selectedPanel: Panels;
	customPanel: CustomPanel | undefined;
	openPanel: () => void;
	isLive: boolean;
	showVideoList: boolean;
	newInteraction: boolean;
	directChats: boolean;
	selectExistingHostChat: () => void;
	newDirectChats: boolean;
	newChats: boolean;
}

interface ContextInterface {
	menuOpen: boolean;
	setMenuOpen: (key: boolean) => void;
	interactionViewed: boolean;
	setInteractionViewed: (key: boolean) => void;
	panelBarHeight: number;
}

export const MobilePanelsContext = React.createContext<ContextInterface>({
	menuOpen: false,
	setMenuOpen: key => false,
	interactionViewed: false,
	setInteractionViewed: key => false,
	panelBarHeight: 0,
});

const MobilePanelBar: React.FC<MobilePanelBarProps> = ({
	content,
	setSelectedPanel,
	selectedPanel,
	customPanel,
	openPanel,
	isLive,
	showVideoList,
	newInteraction,
	directChats,
	selectExistingHostChat,
	newDirectChats,
	newChats,
}) => {
	const numberOfReactions = content.reactions
		? Object.values(content.reactions?.icons || {}).filter(i => i).length + 1
		: 0;
	const [reactionsActive, setReactionsActive] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [interactionViewed, setInteractionViewed] = useState<boolean>(false);
	const [panelBarHeight, setPanelBarHeight] = useState(0);

	const panelBarRef = useRef<any>();

	useEffect(() => {
		setPanelBarHeight(panelBarRef.current.clientHeight);
	}, [selectedPanel]);

	enum PanelIcons {
		"fal fa-comments" = 0,
		"fal fa-sticky-note" = 1,
		"fal fa-bible" = 2,
		"fal fa-film" = 3,
		"fal fa-info-circle" = 4,
		"fal fa-comment-lines" = 5,
		"fal fa-comment-alt-exclamation" = 6,
		"fal fa-calendar-alt" = 7,
	}

	const processPanelTitle = (title: string) => {
		title = title.charAt(0).toUpperCase() + title.slice(1);
		if (title === "DirectChat") {
			title = "Direct Chat";
		}
		if (title === "Info") {
			title = content.name;
		}
		return title;
	};

	return (
		<>
			<Styled.PanelBar className="mobile-panel-bar" ref={panelBarRef}>
				<Styled.CurrentPanelTitle>
					<i className={PanelIcons[selectedPanel] || customPanel?.panel?.icon} />
					{customPanel?.panel?.title || processPanelTitle(Panels[selectedPanel])}
				</Styled.CurrentPanelTitle>

				<Styled.LeftButtonsWrapper className="leftButtonsWrapper">
					{isLive && (
						<>
							<Styled.CloseReactionsButton
								onClick={() => setReactionsActive(!reactionsActive)}
								className={reactionsActive ? "active" : ""}
							>
								<i className="fal fa-times" />
							</Styled.CloseReactionsButton>
							<ReactCSSTransitionGroup
								transitionName="slide"
								transitionEnterTimeout={500}
								transitionLeaveTimeout={300}
							>
								{reactionsActive && (
									<Styled.ReactionsContainer
										numberOfReactions={isLive ? numberOfReactions : 0}
										className={reactionsActive ? "active" : ""}
									>
										<Reactions
											numberOfReactions={isLive ? numberOfReactions : 0}
											id={content.id}
											uid={authFirebaseApp.auth().currentUser?.uid || ""}
											icons={content.reactions.icons}
										/>
									</Styled.ReactionsContainer>
								)}
							</ReactCSSTransitionGroup>
						</>
					)}
					<Styled.LeftButtonsWrapperInner>
						<ReactCSSTransitionGroup
							transitionName="fade"
							transitionEnterTimeout={500}
							transitionLeaveTimeout={300}
						>
							{!reactionsActive && isLive && (
								<Styled.ReactionsThumbButton
									key={content.id}
									onClick={() => setReactionsActive(!reactionsActive)}
								>
									<i className="fal fa-thumbs-up" />
								</Styled.ReactionsThumbButton>
							)}
						</ReactCSSTransitionGroup>
						<Styled.MenuButton
							onClick={() => setMenuOpen(!menuOpen)}
							className={
								newDirectChats || newChats || (newInteraction && !interactionViewed)
									? "has-activity"
									: ""
							}
						>
							<i className="fal fa-ellipsis-v" />
						</Styled.MenuButton>
					</Styled.LeftButtonsWrapperInner>
				</Styled.LeftButtonsWrapper>
			</Styled.PanelBar>
			<MobilePanelsContext.Provider
				value={{
					menuOpen,
					setMenuOpen,
					interactionViewed,
					setInteractionViewed,
					panelBarHeight,
				}}
			>
				<MobilePanelsMenu
					content={content}
					showVideoList={showVideoList}
					openPanel={openPanel}
					setSelectedPanel={setSelectedPanel}
					newInteraction={newInteraction}
					directChats={directChats}
					selectExistingHostChat={selectExistingHostChat}
					newDirectChats={newDirectChats}
					newChats={newChats}
					customPanel={customPanel}
				/>
			</MobilePanelsContext.Provider>
		</>
	);
};

export default MobilePanelBar;
