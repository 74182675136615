import englishTranslation from "./translations/english.json";
import spanishTranslation from "./translations/spanish.json";

interface Translation {
	id: string;
	lang: string;
	name: string;
}

interface Options {
	value: string;
	label: string;
	language: string;
}

const languageOptions = (language: Translation[]): Options[] =>
	language.map(e => ({
		value: e.id,
		label: e.name,
		language: e.lang,
	}));

export const languageData = [
	{ label: "English", options: languageOptions(englishTranslation) },
	{ label: "Español (Latinoamérica)", options: languageOptions(spanishTranslation) },
];

export * from "./books";
export { englishTranslation, spanishTranslation };
