import { AnalyticsState, AnalyticsActionTypes, SET_ANALYTICS_USER_ID } from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: AnalyticsState = {
	analyticsUserId: uuidv4(),
};

export function analyticsReducer(state = initialState, action: AnalyticsActionTypes): AnalyticsState {
	switch (action.type) {
		case SET_ANALYTICS_USER_ID:
			return Object.assign({}, state, {
				analyticsUserId: action.payload,
			});
		default:
			return state;
	}
}
