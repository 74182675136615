import { ContentType, VideoProvider } from "../../../../../interfaces";

export const videoProviderData = {
	[ContentType[ContentType.onDemand]]: [
		{ value: VideoProvider.youtube, label: "Youtube" },
		{ value: VideoProvider.vimeo, label: "Vimeo" },
		{ value: VideoProvider.facebook, label: "Facebook" },
		{ value: VideoProvider.livingAsOneSingle, label: "Resi (Living as One) (Single Event)" },
		{ value: VideoProvider.other, label: "Other Provider" },
	],
	[ContentType[ContentType.simulatedLive]]: [
		{ value: VideoProvider.youtube, label: "Youtube" },
		{ value: VideoProvider.vimeo, label: "Vimeo" },
		{ value: VideoProvider.facebook, label: "Facebook" },
	],
	[ContentType[ContentType.live]]: [
		{ value: VideoProvider.livingAsOne, label: "Resi (Living as One)" },
		//{ value: "livingAsOneSingle", label: "Resi (Living as One) (Single Event)" },
		{ value: VideoProvider.youtube, label: "Youtube Live" },
		{ value: VideoProvider.vimeo, label: "Vimeo Live" },
		{ value: VideoProvider.facebook, label: "Facebook Live" },
		//{ value: "livestream", label: "Livestream" },

		{ value: VideoProvider.other, label: "Other Provider" },
	],
};

export const contentTypeData = [
	{ value: ContentType.simulatedLive, label: "Simulated Live" },
	{ value: ContentType.live, label: "Live" },
	{ value: ContentType.onDemand, label: "On Demand" },
];
