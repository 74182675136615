import styled from "styled-components";

const EventSection = styled.div`
	border: 2px solid #2d3033;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 15px;

	h5 {
		font-size: 16px;
		margin: 0;
	}

	h6 {
		font-weight: 100;
		font-size: 14px;
	}

	p {
		font-size: 14px;
	}

	.section-header-wrapper {
		margin-bottom: 20px;
	}

	.section-container {
		padding: 0;
		margin-bottom: 30px;
	}

	.loader-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export { EventSection };
