import React from "react";
import { FieldProps } from "formik";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { v4 as uuidv4 } from "uuid";

import { TagInput } from "../../../../interfaces";

export interface TagsInputProps {
	allowNew?: boolean;
	field?: FieldProps["field"];
	form?: FieldProps["form"];
	minQueryLength?: number;
	placeholder?: string;
	suggestions?: Tag[];
	onDelete?: (index: number, deletedTag: TagInput) => void;
	onAddition?: (tag: TagInput, isNew: boolean) => void;
}

const TagsInput: React.FC<TagsInputProps> = ({
	placeholder,
	minQueryLength,
	suggestions,
	allowNew = true,
	field,
	form,
	onDelete,
	onAddition,
}) => {
	const tags: Tag[] = field?.value || [];
	const filteredSuggestions = suggestions?.filter(suggestion => !tags.some(tag => tag.id === suggestion.id));

	const handleDelete = (index: number) => {
		const deletedTag = tags[index] as TagInput;
		if (form && field) {
			const tags = field.value || [];
			tags.splice(index, 1);
			form.setFieldValue(field.name, tags);
		}
		if (onDelete) {
			onDelete(index, deletedTag);
		}
	};

	const handleAddition = (tag: Tag) => {
		let isNew = false;
		let newTag = tag;
		if (!tag.id) {
			isNew = true;
			newTag.id = uuidv4();
		}

		if (form && field) {
			const tags = field.value || [];
			tags.push(newTag);
			form.setFieldValue(field.name, tags);
		}
		if (onAddition) {
			onAddition(newTag as TagInput, isNew);
		}
	};

	return (
		<ReactTags
			autofocus={false}
			tags={field?.value || []}
			placeholder={placeholder}
			suggestions={filteredSuggestions}
			handleDelete={handleDelete}
			handleAddition={handleAddition}
			allowNew={allowNew}
			minQueryLength={minQueryLength || 2}
			handleInputChange={() => {}}
		/>
	);
};

export default TagsInput;
