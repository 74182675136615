import React from "react";
import { Button } from "react-bootstrap";
import { Well, PageTitle, PageContainer } from "./UI";

interface DashBoardRedirectProps {
	url: string;
}

const DashboardRedirect: React.FC<DashBoardRedirectProps> = ({ url }) => {
	return (
		<PageContainer className="no-sidebar" size="sm" htmlPageTitle="Open Dashboard">
			<PageTitle>Continue in the Online Experience Dashboard</PageTitle>
			<Well>
				<Button href={url} className="btn-block">
					Open Dashboard
				</Button>
			</Well>
		</PageContainer>
	);
};

export default DashboardRedirect;
