import styled from "styled-components";

export const UrlInput = styled.input`
	width: 100%;
	border: 1px solid ${props => props.theme.wellTextColor};
	border-radius: 4px;
`;

interface ButtonStyleProps {
	primary?: boolean;
}

export const Button = styled.button<ButtonStyleProps>`
	// Reset browser styles
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	font: inherit;
	line-height: normal;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;

	padding-left: 6px;
	padding-right: 6px;
	color: ${props => props.primary ? props.theme.buttonBackgroundColor : props.theme.wellTextColor};
	&:hover {
		color: ${props => props.primary ? props.theme.buttonHoverBackgroundColor : props.theme.wellTextColor}; 
	}
`;

export const Trigger = styled.div`
	display: flex;
`;

export const ShareButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  padding-left: 6px;
  padding-right: 6px;
	padding-top: 2px;
  padding-bottom: 2px;
	font-size: 14px;
  border-radius: 4px;
	background-color: ${props => props.theme.wellTextColor};
	color: ${props => props.theme.wellBackgroundColor};
	
	i {
		padding-left: 8px;
	}
`;
