import React, { ReactNode, useRef, useEffect, useState } from "react";
import usePosition from "../../hooks/usePosition";
import styled from "styled-components";

const StyledStickyContainer = styled.div`
	transition: all 0.25s;
	background: transparent;
	width: 100%;

	.fixed-sticky {
		position: fixed;
		bottom: 0px;
		height: 120px;

		button {
			margin-top: 40px;
		}
	}
`;

interface StickyProps {
	children: ReactNode;
}

const Sticky: React.FC<StickyProps> = ({ children }) => {
	const stickyContainer: any = useRef(null);
	const width: any = stickyContainer.current ? stickyContainer.current.offsetWidth : 0;
	const [isFixed, setIsFixed] = useState(false);
	const [buttonWidth, setButtonWidth] = useState("");
	let { top } = usePosition(stickyContainer);

	window.onscroll = function() {
		let totalPageHeight = document.body.scrollHeight;

		let scrollPoint = window.scrollY + window.innerHeight;

		if (scrollPoint + 200 >= totalPageHeight) {
			setIsFixed(false);
		} else {
			setIsFixed(true);
		}
	};

	useEffect(() => {
		setButtonWidth(`${width}px`);
	}, [stickyContainer, top, width]);

	return (
		<StyledStickyContainer className="sticky-container" ref={stickyContainer}>
			<div className={`sticky-inner-container ${isFixed ? "fixed-sticky" : ""}`} style={{ width: buttonWidth }}>
				{children}
			</div>
		</StyledStickyContainer>
	);
};

export default Sticky;
