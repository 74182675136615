import firebase from "../../../firebase";
import axios from "axios";

const setOrReplaceImg = async (auth: firebase.auth.Auth, imageUrl?: string) => {
	if (auth.currentUser) {
		const { uid, photoURL } = auth.currentUser;
		if (photoURL || imageUrl) {
			await setImage(uid, imageUrl || (photoURL as string));
			return getCdnLink(uid);
		}
	}
	return null;
};

const setImage = async (uid: string, photoURL: string) => {
	const storage = firebase.storage();
	const storageRef = storage.ref();
	const base64Img = await getBase64Img(photoURL);
	const img = storageRef.child(`profile_images/${uid}/profile.jpg`);
	const response = await img.putString(base64Img, "data_url", {
		contentType: "image/jpg",
	});
	return response;
};

export const getCdnLink = (uid: string) => `https://media.online.brushfire.com/profile_images/${uid}/profile.jpg`;

const getBase64Img = (url: string) => {
	return new Promise<string>(async (resolve, reject) => {
		const response = await axios({
			method: "get",
			url: url,
			responseType: "blob",
		}).catch(e => {
			console.log(">>>> e, ", JSON.stringify(e));
		});
		if (response && response.data) {
			const reader = new FileReader();
			reader.readAsDataURL(response.data);
			reader.onloadend = () => {
				const base64data = reader.result as string;
				resolve(base64data);
			};
		} else {
			reject("");
		}
	});
};

export default setOrReplaceImg;
