import { PayloadAction } from "typesafe-actions";
import { User } from "../../interfaces";
import {
	AUTH_STATUS_CHANGED,
	FRONT_END_AUTH_STATUS_CHANGED,
	FRONT_END_LOGIN_ERROR,
	FRONT_END_LOGIN_SUCCESS,
	FRONT_END_LOGOUT_USER,
	FRONT_END_SET_USER,
	ITRACK_AUTH_STATUS,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	LOGOUT_USER,
	SET_APP_USER,
	SET_IS_LOGGING_OUT,
	SHOW_LOGIN_MODAL,
	SHOW_LOGOUT_MODAL,
	TRACK_AUTH_STATUS,
} from "./types";

export function trackAuthStatus(): ITRACK_AUTH_STATUS {
	return { type: TRACK_AUTH_STATUS };
}

export function authStatusChanged(user: {
	user: firebase.User | null;
}): PayloadAction<string, { user: firebase.User | null }> {
	return { type: AUTH_STATUS_CHANGED, payload: user };
}

export function setAppUser(user: User | undefined): PayloadAction<string, User | undefined> {
	return { type: SET_APP_USER, payload: user };
}

export function logoutUser(message = "Logged Out") {
	return { type: LOGOUT_USER, payload: message };
}

export function loginSucess(user: firebase.User): PayloadAction<string, firebase.User> {
	return { type: LOGIN_SUCCESS, payload: user };
}

export function loginError(message: string): PayloadAction<string, string> {
	return { type: LOGIN_ERROR, payload: message };
}

export function showLoginModal(show: boolean): PayloadAction<string, boolean> {
	return { type: SHOW_LOGIN_MODAL, payload: show };
}

export function showLogoutModal(show: boolean): PayloadAction<string, boolean> {
	return { type: SHOW_LOGOUT_MODAL, payload: show };
}

export function setIsLoggingOut(loggingOut: boolean): PayloadAction<string, boolean> {
	return { type: SET_IS_LOGGING_OUT, payload: loggingOut };
}

//Front End Auth
export function frontEndAuthStatusChanged(user: {
	user: firebase.User | null;
}): PayloadAction<string, { user: firebase.User | null }> {
	return { type: FRONT_END_AUTH_STATUS_CHANGED, payload: user };
}

export function frontEndSetUser(user: firebase.User | undefined): PayloadAction<string, firebase.User | undefined> {
	return { type: FRONT_END_SET_USER, payload: user };
}

export function frontEndLogoutUser(message = "Logged Out") {
	return { type: FRONT_END_LOGOUT_USER, payload: message };
}

export function frontEndLoginSucess(user: firebase.User): PayloadAction<string, firebase.User> {
	return { type: FRONT_END_LOGIN_SUCCESS, payload: user };
}

export function frontEndLoginError(message: string): PayloadAction<string, string> {
	return { type: FRONT_END_LOGIN_ERROR, payload: message };
}
