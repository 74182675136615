import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Client, User } from "../../../interfaces";
import { TypedUseSelectorHook } from "../../../redux";
import { AttendeeDataContext } from "../../Paywall";
import FooterNavigation from "../FooterNavigation";

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface FooterProps {
	client?: Client;
	isLightBackground?: boolean;
	appUser?: User;
}

const Footer: React.FC<FooterProps> = ({ client, appUser, isLightBackground }) => {
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	// const [divHeight, setDivHeight] = useState<number>();
	const location = useLocation();
	const { attendeeData, paywallEnabled, logout } = useContext(AttendeeDataContext);
	const color = isLightBackground ? "#202020" : "white";

	let clientKey;
	if (!dashboard?.clientData) {
		clientKey = "";
	} else {
		clientKey = dashboard.clientData?.key;
	}

	const powerByLink = `https://www.brushfire.com/?utm_source=BFO&utm_campaign=Dashboard&utm_content=${
		client ? client.key : clientKey
	}`;

	if (location.pathname.includes("/host")) {
		return null;
	}

	// TODO: dynamically get height and set absolute vs relative
	// <div style={divHeight && divHeight > 2000 ? styles.footerRelative : styles.footerAbsolute}></div>
	return (
		<div style={styles.footerAbsolute} className="footer-container">
			<a
				href={powerByLink}
				target="_blank"
				rel="noopener noreferrer"
				style={{ textDecoration: "none" }}
				className="footer-image-container"
			>
				<div className="footer-logo-wrapper" style={styles.footerContainer}>
					<h6
						style={{
							...styles.footerTextStyle,
							color,
						}}
					>
						A Product of
					</h6>
					<img
						alt="Brushfire Logo"
						src={
							isLightBackground
								? `/content/img/bf-logo-full-dark-1400.png`
								: `/content/img/bf-logo-full-light-1400.png`
						}
					/>
				</div>
			</a>
			{paywallEnabled && attendeeData && (
				<>
					{" "}
					<a
						href="#"
						style={{ color }}
						className="footer-attendee-number"
						onClick={evt => {
							evt.preventDefault();
							evt.stopPropagation();
							if (window.confirm("Do you want to switch Attendee Numbers?")) {
								logout();
							}
						}}
					>
						Attendee {attendeeData.AttendeeNumber}
					</a>
				</>
			)}
			<FooterNavigation
				client={dashboard?.clientData || client}
				appUser={user?.appUser || appUser}
				isLightBackground={isLightBackground}
			/>
		</div>
	);
};
const styles = {
	footerAbsolute: {
		position: "absolute" as "absolute",
		marginLeft: "auto",
		marginRight: "auto",
		left: 0,
		right: 0,
		bottom: 0,
	},
	footerRelative: {
		position: "relative" as "relative",
	},
	footerContainer: {
		display: "flex",
		flexDirection: "row" as "row",
		width: "100%",
		alignItems: "center",
	},
	footerTextStyle: {
		marginRight: "10px",
		marginBottom: "0px",
		fontWeight: 500,
	},
};

export default Footer;
