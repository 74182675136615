import React, { useContext } from "react";

import { Response } from "../../../interfaces";

import { Context, Dismiss } from "../InteractionResponse";
import * as Styled from "../InteractionResponse.styled";

interface TextOnlyProps {
	response: Response;
}

/**
 * For "textOnly" responses AND the thank you screen
 */
export const TextOnly: React.FC<TextOnlyProps> = ({ response }) => {
	const { isPreview, isModal } = useContext(Context);
	return (
		<>
			<Styled.InnerContentContainer>
				<Styled.RealContent responsesHeight={0} isModal={isModal}>
					<div id="content" dangerouslySetInnerHTML={{ __html: response.actionText }} />
				</Styled.RealContent>
			</Styled.InnerContentContainer>
			<Styled.InteractionContainer isPreview={isPreview}>
				<Styled.HR isPreview={isPreview} />
				<Dismiss />
			</Styled.InteractionContainer>
		</>
	);
};
