import React from "react";
import { Button } from "react-bootstrap";
import { Well, PageTitle, PageContainer } from "./UI";
import firebase from "../../firebase";

const db = firebase.firestore();

const ForcedRefresh: React.FC = () => {
	const handleClick = () => {
		const pubsub = db.collection("product-pubsub").doc("product");
		let refreshTimestamp = new Date();
		pubsub.set({ refreshAt: refreshTimestamp });
	};

	return (
		<PageContainer size="md" htmlPageTitle="Force Refresh">
			<PageTitle>Force Refresh</PageTitle>
			<Well>
				<p className="pb-2">Do you want to refresh the app for all users?</p>
				<Button variant="danger" className="btn-block text-center" onClick={handleClick}>
					Force Refresh
				</Button>
			</Well>
		</PageContainer>
	);
};

export default ForcedRefresh;
