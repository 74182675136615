import React from "react";
import styled from "styled-components";

interface ButtonComponentProps {
	color: string;
	backgroundColor: string;
	hoverColor: string;
	hoverBackgroundColor: string;
}
const isTouch = !!("ontouchstart" in window) || window.navigator.msMaxTouchPoints > 0;

const ButtonComponent = styled.div<ButtonComponentProps>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ color }) => color};
	transition: all 0.25s;
	cursor: pointer;

	&:hover {
		background-color: ${({ hoverBackgroundColor }) => (!isTouch ? hoverBackgroundColor : "")};
		color: ${({ hoverColor }) => (!isTouch ? hoverColor : "")};
	}
`;

const Button = (props: any) => <ButtonComponent {...props}>{props.children}</ButtonComponent>;

export default Button;
